.root {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: -60px;
  height: 125px;
  left: 0px;
  right: 0px;
  padding-bottom: 60px;
  z-index: 10;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
}

@supports (margin-bottom: env(safe-area-inset-bottom)) {
  .root {
    margin-bottom: env(safe-area-inset-bottom) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
}
