export const mpcColors = {
  // tailwind gray
  gray: ['#f9fafb', '#f3f4f6', '#e5e7eb', '#d1d5db', '#9ca3af', '#6b7280', '#4b5563', '#374151', '#1f2937', '#111827'],
  // tailwind red
  error: ['#fef2f2', '#fee2e2', '#fecaca', '#fca5a5', '#f87171', '#ef4444', '#dc2626', '#b91c1c', '#991b1b', '#7f1d1d'],
  // tailwind yellow
  warning: ['#fefce8', '#fef9c3', '#fef08a', '#fde047', '#facc15', '#eab308', '#ca8a04', '#a16207', '#854d0e', '#713f12'],
  // tailwind green
  success: ['#f0fdf4', '#dcfce7', '#bbf7d0', '#86efac', '#4ade80', '#22c55e', '#16a34a', '#15803d', '#166534', '#14532d'],
};
const mpcColorsCopy = JSON.parse(JSON.stringify(mpcColors));
// prettier-ignore
export const defaultColors = {
  bg: '#f9f9f9',
  white: '#ffffff',
  ...mpcColorsCopy,
};
export const defaultDarkColors = {
  bg: '#131925',
  white: '#172035',
  gray: JSON.parse(JSON.stringify(mpcColorsCopy.gray)).reverse(),
};
