import get from 'lodash/get';

import { actions } from './actions';
import { track as segmentTrack } from './segment';

export const track = (name, properties, options) => {
  const actionName = get(actions, name);
  if (!actionName) return;

  segmentTrack(actionName, properties, options);
};
