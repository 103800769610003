/**
 * Create a new store with the given initial state. Adds Redux
 * middleware and enhancers.
 */
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import * as analytics from './analytics/analytics';

import createReducer from './reducers';

export default function configureStore(initialState = {}, sdk = null, analyticsHandlers: any = []) {
  const middlewares = [thunk.withExtraArgument(sdk), analytics.createMiddleware(analyticsHandlers)];
  const enhancer = compose(applyMiddleware(...middlewares));
  const store = createStore(createReducer(), initialState, enhancer);
  return store;
}
