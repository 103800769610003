import { useEffect, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js/pure';

import AuthModal from '@/components/AuthModal/AuthModal';
import ButtonLight from '@/components/Button/ButtonLight';
import IconSpinner from '@/components/IconSpinner/IconSpinner';
import Modal from '@/components/Modal/Modal';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

import { auth, products, transactions } from '@/clients';

import { formatPriceString } from '@/util/currency';
import {
  getProSubscriptionPlanId,
  getProSubscriptionTrialDays,
  isProAccount,
  isProEnabledMarketplace,
  isProOnlyRoute,
  label,
  uuidv4,
} from '@/util/helpers';
import { CheckIcon, ExclamationTriangleIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const PricingCard = props => {
  const {
    title,
    price,
    pricingInterval = 'mo',
    pricingFeatures,
    description,
    onBuySubscriptionClicked,
    inProgress,
    active,
    isProSettings = false,
    isTrialSupported = false,
    trialDays,
    proSubscriptionActive,
  } = props;
  const buttonTheme = active ? 'white' : 'primary';
  let buttonText = active ? 'Subscribed' : <>Subscribe to {title}</>;
  let buttonDisabled = active;
  const isFree = +price === 0;

  if (isFree && !active) {
    buttonText = isProSettings ? 'Change Subscription' : 'Subscription Settings';
  } else if (+price !== 0 && !active && proSubscriptionActive) {
    // Dont allow changing plans for now...
    buttonDisabled = true;
  }

  // Trial support
  if (isTrialSupported && !isFree && !proSubscriptionActive) {
    buttonText = `Start a free ${trialDays} day trial`;
  }

  return (
    <div className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
      <div className="p-6">
        <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
        <p className="mt-4 text-sm leading-5 text-gray-500">{description}</p>

        <p className="mt-8">
          <span className="text-4xl font-semibold leading-10 text-gray-900">${formatPriceString(price)}</span>
          <span className="text-base font-medium leading-6 text-gray-500">/{pricingInterval}</span>
        </p>

        <ButtonLight
          onClick={() => {
            onBuySubscriptionClicked();
          }}
          disabled={buttonDisabled}
          theme={buttonTheme}
          className="mt-8"
          fullWidthCenterText
          inProgress={inProgress}
        >
          {buttonText}
        </ButtonLight>
      </div>
      <div className="px-6 pt-6 pb-8">
        <h3 className="text-xs font-medium uppercase leading-4 tracking-wide text-gray-900">What's included</h3>
        <ul className="mt-6 space-y-4">
          {pricingFeatures.map((f, i) => (
            <li key={`${i}_${title}`} className="flex space-x-3 capitalize">
              <CheckIcon className="text-primary-500 h-5 w-5 flex-shrink-0" />
              <span className="text-sm leading-5 text-gray-500">{f}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MarketplaceSubscriptionDetails = props => {
  const {
    className = '',
    intl,
    marketplace,
    currentUser,
    title = 'Pricing Plans',
    description = '',
    isProSettings = false,
    smText = false,
    routeToPortal,
    history,
    titleClassNames = {},
  } = props;
  const { servicesCredentials } = marketplace || {};
  const { stripe: stripeServiceCredentials } = servicesCredentials || {};
  const { publishableKey } = stripeServiceCredentials || {};
  const isProMarketplace = isProEnabledMarketplace(marketplace);

  const [subscriptionErrorModalOpened, setSubscriptionErrorModalOpened] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [subscriptionInProgress, setSubscriptionInProgress] = useState(false);

  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const [authModalOpened, setAuthModalOpened] = useState(false);

  const proSubscriptionActive = isProAccount(currentUser);
  const proSubscriptionPlanId = getProSubscriptionPlanId(currentUser);
  const trialDays = getProSubscriptionTrialDays();
  const isTrialSupported = trialDays > 0;

  let userId = currentUser && currentUser.id;
  if (currentUser && userId && typeof userId === 'object') userId = currentUser.id.uuid;
  if (!userId) userId = auth.getAccountId();

  const upgradeToPro = ({ product, variant }) => {
    const handleError = (err = 'Unknown Error') => {
      console.log(err);
      setSubscriptionError(err);
      setSubscriptionErrorModalOpened(true);
      setSubscriptionInProgress(false);
    };

    if (!userId) userId = auth.getAccountId();

    setSubscriptionInProgress(true);
    const currentUrl = `${window.location.origin}/account/pro`;
    // Create transaction
    const body = {
      idempotencyKey: uuidv4(),
      type: 'subscriptionPurchase',
      product,
      lineItems: [
        {
          product,
          variant,
          customers: [userId],
        },
      ],
      paymentGateway: {
        provider: 'stripe',
        method: 'checkout',
        successUrl: `${currentUrl}/success`,
        cancelUrl: `${currentUrl}/cancel`,
      },
    };
    transactions
      .create(body)
      .then(response => {
        const sessionId = response.data.paymentGateway.checkout.id;

        // Load stripe
        loadStripe(publishableKey).then(stripe => {
          // Redirect to checkout
          stripe.redirectToCheckout({ sessionId }).then(result => {
            setSubscriptionInProgress(false);

            if (result.error) {
              handleError(result.error);
              return;
            }
          });
        });
      })
      .catch(err => {
        let error = err;
        if (typeof err === 'object') {
          error = err.error && err.error.userMessage;
        }
        handleError(error);
      });
  };

  const [product, setProduct] = useState(null);
  useEffect(() => {
    setLoadingSubscription(true);
    products
      .list({
        'metadata.stripeData.metadata.sync-on-playkit': true,
        status: 'active',
        sort: 'desc:createdAt',
      })
      .then(response => {
        if (!response.data || !response.data.length) {
          setLoadingSubscription(false);
          return;
        }
        setProduct(response.data[0]);
      })
      .catch(() => {
        setLoadingSubscription(false);
      });
  }, []);

  const [productVariants, setProductVariants] = useState(null);
  const productId = product && product.id;
  useEffect(() => {
    if (!productId) return;

    products
      .listVariants(productId)
      .then(response => {
        if (!response.data || !response.data.length) {
          setLoadingSubscription(false);
        }
        setLoadingSubscription(false);
        setProductVariants(response.data);
      })
      .catch(() => {
        setLoadingSubscription(false);
      });
  }, [productId]);

  const [pricingInterval, setPricingInterval] = useState('mo');
  const hasProductVariants = productVariants && productVariants.length;

  const shouldNotRender = !isProMarketplace || !hasProductVariants;
  if (shouldNotRender) return null;

  let monthlyProductVariant, yearlyProductVariant;
  if (hasProductVariants) {
    try {
      monthlyProductVariant = productVariants.find(v => v.metadata.stripeData.recurring.interval === 'month');
      yearlyProductVariant = productVariants.find(v => v.metadata.stripeData.recurring.interval === 'year');
    } catch {}
  }

  const activePriceVariant = (pricingInterval === 'yr' ? yearlyProductVariant : monthlyProductVariant) || {};
  const PricingIntervalToggle = () => {
    if (!monthlyProductVariant || !yearlyProductVariant) return null;

    const intervalButtonClassName =
      'relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8';
    const intervalSelectedButtonClassName = `${intervalButtonClassName} bg-white border-gray-200 shadow-sm`;
    const intervalNonSelectedButtonClassName = `${intervalButtonClassName} border border-transparent`;
    const intervalMoButtonClassName =
      pricingInterval === 'mo' ? intervalSelectedButtonClassName : intervalNonSelectedButtonClassName;
    const intervalYrButtonClassName =
      pricingInterval === 'yr' ? intervalSelectedButtonClassName : intervalNonSelectedButtonClassName;

    return (
      <div
        className={`relative ${
          !isProSettings && !smText ? 'self-center' : 'self-start'
        } mt-6 flex rounded-lg bg-gray-100 p-0.5 sm:mt-8`}
      >
        <button
          onClick={() => {
            setPricingInterval('mo');
          }}
          type="button"
          className={intervalMoButtonClassName}
        >
          Monthly billing
        </button>
        <button
          onClick={() => {
            setPricingInterval('yr');
          }}
          type="button"
          className={intervalYrButtonClassName}
        >
          Yearly billing
        </button>
      </div>
    );
  };

  const freeFeatures = [`Create ${label('listings')}`, 'Collect payments', 'Manage Policies'];
  const proFeatures = [...freeFeatures, 'Forms'];
  const potentialProFeatures = ['contacts', 'calendar', 'tasks'];
  if (isProMarketplace) {
    for (const feature of potentialProFeatures) {
      const featureToCheck = feature === 'tasks' ? 'contacts' : feature;
      if (isProOnlyRoute(featureToCheck)) {
        proFeatures.push(feature);
      } else {
        proFeatures.push(feature);
        freeFeatures.push(feature);
      }
    }
  }

  const contentWrapperClassName = isProSettings || smText ? '' : 'max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8';
  const toolbarWrapperClassName = isProSettings || smText ? 'sm:flex sm:flex-col' : 'sm:flex sm:flex-col sm:align-center';
  const cardWrapperClassName = isProSettings || smText ? 'mt-6' : 'sm:mx-auto sm:mt-16 mt-12';

  const defaultTitleClassNames = {
    className: 'sm:text-center',
    fontWeight: 'font-bold',
    fontSize: 'text-5xl',
  };

  if (isProSettings || smText) {
    defaultTitleClassNames.className = '';
    defaultTitleClassNames.fontWeight = 'font-semibold';
    defaultTitleClassNames.fontSize = 'text-lg';
  }

  const descriptionClassName =
    isProSettings || smText
      ? 'mt-1 text-sm leading-5 text-gray-500'
      : 'mt-5 max-w-3xl mx-auto text-xl leading-7 text-gray-500 sm:text-center';

  return (
    <>
      <div className={`bg-white ${className}`}>
        {loadingSubscription ? (
          <div className="my-8 flex flex-row items-center justify-center">
            <IconSpinner />
          </div>
        ) : null}

        <div className={contentWrapperClassName}>
          <div className={toolbarWrapperClassName}>
            <UiTextRenderer
              text={title}
              className={`${defaultTitleClassNames.className} whitespace-pre-wrap`}
              classNames={titleClassNames}
              defaultFontWeightClassName={defaultTitleClassNames.fontWeight}
              defaultFontSizeClassName={defaultTitleClassNames.fontSize}
              defaultFontFamilyClassName="font-header"
              defaultFontColorClassName="text-header"
            />

            {description ? <p className={descriptionClassName}>{description}</p> : null}
            <PricingIntervalToggle />
          </div>

          <div className={`max-w-xl space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 ${cardWrapperClassName}`}>
            <PricingCard
              title="Free"
              description="Start building and upgrade to pro for additional features"
              price="0"
              pricingInterval={pricingInterval}
              pricingFeatures={freeFeatures}
              active={!proSubscriptionActive}
              isProSettings={isProSettings}
              onBuySubscriptionClicked={() => {
                if (!isProSettings) {
                  return history.push('/account/pro');
                }
                // User can cancel on the portal link
                if (routeToPortal) routeToPortal();
              }}
            />

            <PricingCard
              title="Pro"
              description="All the basics for starting a new business"
              price={activePriceVariant.price}
              pricingInterval={pricingInterval}
              pricingFeatures={proFeatures}
              proSubscriptionActive={proSubscriptionActive}
              active={proSubscriptionPlanId === activePriceVariant.id}
              inProgress={subscriptionInProgress}
              trialDays={trialDays}
              isTrialSupported={isTrialSupported}
              onBuySubscriptionClicked={() => {
                if (!userId) {
                  setAuthModalOpened(true);
                  return;
                }
                upgradeToPro({
                  product: activePriceVariant.product,
                  variant: activePriceVariant.id,
                });
              }}
            />
          </div>
        </div>
      </div>

      <AuthModal
        onSuccess={account => {
          const proSubscriptionActive = isProAccount(account);
          if (proSubscriptionActive) {
            setSubscriptionError('You are already a pro subscriber');
            setSubscriptionErrorModalOpened(true);
            setSubscriptionInProgress(false);
          } else {
            upgradeToPro({
              product: activePriceVariant.product,
              variant: activePriceVariant.id,
            });
          }
        }}
        intl={intl}
        isOpen={authModalOpened}
        onClose={() => setAuthModalOpened(false)}
      ></AuthModal>

      <Modal
        id="errorModal"
        theme="tailwind"
        extraContainerClassName="max-w-2xl"
        tailwindCloseButton
        isOpen={subscriptionErrorModalOpened}
        onClose={() => setSubscriptionErrorModalOpened(false)}
      >
        <div className="py-8">
          <div
            className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom transition-all sm:w-full sm:max-w-lg sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
                  Subscription Error
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">{subscriptionError || 'An unknown error occurred'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MarketplaceSubscriptionDetails;
