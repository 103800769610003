import { useState } from 'react';

import { FormattedMessage } from '@/util/reactIntl';

import ListingSectionTitle from './ListingSectionTitle';

const SectionDescriptionMaybe = props => {
  const {
    className,
    description,
    title,
    noTitle = false,
    textClassName = 'text-base font-normal leading-7 text-gray-900',
    previewLength = 450,
  } = props;
  const [expanded, setExpanded] = useState(false);

  const shortDescription = description.length > previewLength ? description.substring(0, previewLength) : null;

  return description ? (
    <div className={className}>
      {!noTitle ? (
        <ListingSectionTitle title={title || <FormattedMessage id="ListingPage.descriptionTitle" />}></ListingSectionTitle>
      ) : null}

      <div className={`${textClassName} whitespace-pre-line`}>
        {!expanded && shortDescription ? (
          <div>
            {shortDescription}...
            <button
              className="ml-2 font-semibold text-gray-800 underline outline-none hover:text-gray-900 focus:text-gray-600"
              onClick={() => {
                setExpanded(true);
              }}
            >
              read more
            </button>
          </div>
        ) : (
          description
        )}
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
