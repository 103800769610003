import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const create = body => {
  return client('campaigns', { body });
};

const update = (id, body) => {
  return client(`campaigns/${id}`, { body, method: 'PUT' });
};

const list = (params = { limit: 50 }) => {
  return client(`campaigns?${stringify(params)}`);
};

export { create, list, update };
