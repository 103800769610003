import { useEffect, useState } from 'react';

import { Button } from '@tmpc/ui';
import debounce from 'lodash/debounce';
import moment from 'moment';

import IconSpinner from '@/components/IconSpinner/IconSpinner';
import ImageCarouselFullscreen from '@/components/ImageCarouselFullscreen/ImageCarouselFullscreen';
import Img from '@/components/Img/Img';
import Modal from '@/components/Modal/Modal';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

import { generateImageKitUrl, getMarketplace } from '@/util/helpers';
import { FormattedMessage } from '@/util/reactIntl';
import useMediaQuery from '@/util/useMediaQuery';
import { ExclamationTriangleIcon, LockClosedIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import * as css from './SectionImages.module.css';

function ListingVideoPlayer(params) {
  const { className, autoplay = true, fluid = true, firstImage, url, options, onError, liveui = false, muted } = params;

  const videoJsOptions = {
    controls: true,
    autoplay,
    liveui,
    fluid,
    muted,
    poster: generateImageKitUrl({ id: firstImage?.metadata?.name, transformation: 'lg' }),
    sources: [
      {
        src: url,
        type: 'application/x-mpegURL',
      },
    ],
    ...options,
  };

  return (
    <VideoPlayer
      className={`${className} ${firstImage ? css.firstImage : ''} ${css.video}`}
      alertAction={null}
      alertOnAction={null}
      loadWithFluid
      alertTitle="There was an issue playing this video"
      alertDescription="Please try again"
      onError={onError}
      {...videoJsOptions}
    />
  );
}

const LiveStreamCountDown = params => {
  const { timeslots, liveStreamStatus, checkForNewStream, liveStreamError, hasAccessToVideoContent } = params;
  const isStreamActive = liveStreamStatus === 'ready';
  const isStreamClosed = liveStreamStatus === 'closed';
  const isStreamInterrupted = liveStreamStatus === 'interrupted';

  const slot = timeslots[0];
  const { startsAt, endsAt, timezone } = slot;
  const startsAtMoment = moment.utc(startsAt).tz(timezone);
  const endsAtMoment = moment.utc(endsAt).tz(timezone);
  const isEventInPast = moment().isAfter(endsAtMoment);

  const dur = moment.duration(startsAtMoment.diff(moment()));
  const pastDur = moment.duration(endsAtMoment.diff(moment()));
  const pastDurString = pastDur.humanize(true);

  const seconds = dur.asSeconds();

  const shouldStreamHaveStarted = seconds < 0;
  const shouldStreamHaveStartedAndItHasNotEver = shouldStreamHaveStarted && !isStreamActive && !isStreamClosed && !isEventInPast;
  const shouldStreamBeInProgress = shouldStreamHaveStarted && !isEventInPast;
  const startsLabel = seconds > 0 ? 'Starts' : 'Started';

  const [counter, setCounter] = useState(seconds || 0);
  const [durString, setDurString] = useState('');

  useEffect(() => {
    const humanized = dur.humanize(true);
    setDurString(humanized);

    if (counter > 0 || shouldStreamHaveStartedAndItHasNotEver || shouldStreamBeInProgress) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }

    // We need to check the status of the stram during the stream so the state updates when it finishes
    if (shouldStreamHaveStartedAndItHasNotEver || isStreamActive || shouldStreamBeInProgress) {
      checkForNewStream();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  // Active stream label
  let label = `${startsLabel} ${durString}`;

  // Stream Should be started or is in the past
  if (shouldStreamHaveStarted && !isStreamActive) {
    // Event is in the past and there is no active stream
    if (isEventInPast) {
      label = `This stream ended ${pastDurString}`;
    } else {
      if (isStreamClosed || isStreamInterrupted) {
        label = 'This stream has been paused';
      } else {
        // Event should be started and there is no active stream
        label = (
          <>
            <IconSpinner className="mr-2 h-5 w-5" white />
            This stream should start any moment
          </>
        );
      }
    }
  }

  // Show error message if applicable
  if (liveStreamError) {
    label = (
      <>
        <ExclamationTriangleIcon className="mr-2 h-5 w-5" />
        There was an error starting the stream, refresh to try again
      </>
    );
  }

  // Closed stream status
  if (isEventInPast && shouldStreamHaveStarted && isStreamClosed) {
    label = `This stream concluded ${pastDurString}`;
  }

  return (
    <div className="absolute top-0 right-0 rounded bg-black bg-opacity-50 p-2 font-medium text-white">
      <div className="flex flex-row items-center">{label}</div>

      {!hasAccessToVideoContent && !isEventInPast ? (
        <div className="mt-1 flex flex-row items-center">
          <LockClosedIcon className="mr-2 h-5 w-5 text-white" />
          <div>Book to gain access</div>
        </div>
      ) : null}
    </div>
  );
};

const checkforNewLiveStreamDebounceTime = 7000;
const debouncedCheckForNewStream = debounce(refresh => refresh(), checkforNewLiveStreamDebounceTime, {
  maxWait: checkforNewLiveStreamDebounceTime,
});

const SectionImages = props => {
  const {
    title,
    listing,
    onManageDisableScrolling,
    type,
    className,
    subType,
    liveStream,
    headerType,
    autoplayVideoTrailer,
    timeslots,
    checkForNewLiveStream,
    hasAccessToVideoContent,
    assets: initialAssets,
    rootForImageClassName = '',
    hidePhotosButton = false,
    initialVideoTrailer = null,
  } = props;
  const desktop = useMediaQuery('(min-width:768px)');
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [modalSelectedImageIndex, setModalSelectedImageIndex] = useState(null);
  const [liveStreamError, setLiveStreamError] = useState(null);
  const [videoTrailerError, setVideoTrailerError] = useState(null);
  let { assets = [] } = listing || {};

  if (initialAssets && initialAssets.length) {
    assets = initialAssets;
  }

  const rootForImage = `${css.rootForImage} ${rootForImageClassName}`;

  const { publishingStatus: liveStreamStatus } = liveStream || {};
  const hasTimeSlots = timeslots && timeslots.length;
  const isLiveStreamListingType = type === 'video' && subType === 'live';

  const handleViewPhotosClick = index => {
    setModalSelectedImageIndex(index);
    setImageCarouselOpen(true);
  };

  if (!assets) return null;

  const images = assets.filter(img => img.type === 'image');
  const videoTrailer =
    initialVideoTrailer ||
    assets.find(item => item.type === 'video-stream' && item.metadata && item.metadata.videoType === 'preview');
  const hasImages = images && images.length > 0;
  const firstImage = hasImages ? images[0] : null;

  const hasVideoTrailer = !!videoTrailer;
  const videoTrailerUrl = hasVideoTrailer ? `https://stream.mux.com/${videoTrailer.metadata.playback.id}.m3u8` : null;

  let streamUrl = '';
  try {
    if (isLiveStreamListingType && liveStream) {
      streamUrl = `https://stream.mux.com/${liveStream.assets[0].metadata.playback.id}.m3u8`;
    }
  } catch (error) {
    console.error('Unable to set stream', error);
  }

  const shouldRenderLiveVideoPlayer = isLiveStreamListingType && streamUrl && !liveStreamError && liveStreamStatus === 'ready';
  const liveVideoPlayerMaybe = shouldRenderLiveVideoPlayer ? (
    <ListingVideoPlayer
      options={{
        playbackPolicy: liveStream.assets[0].metadata.playback.policy,
        contentId: liveStream.id,
        listingId: listing.id,
      }}
      firstImage={firstImage}
      liveui={true}
      url={streamUrl}
      autoplay={true}
      muted={true}
      onError={setLiveStreamError}
    />
  ) : null;

  const viewMoreText = <FormattedMessage id="ListingPage.viewImagesButton" values={{ count: images.length }} />;

  const singleImage = (props = {}) => (
    <Img
      className={rootForImage}
      {...props}
      key={firstImage.metadata.name}
      alt={title}
      id={firstImage.metadata.name}
      src={!firstImage.metadata.name ? firstImage.metadata.src : ''}
      transformationType={`${props.transformationType || 'ar-2:1-lg'}`}
    />
  );

  const videoTailerPlayer =
    videoTrailerUrl && !videoTrailerError
      ? props => (
          <ListingVideoPlayer
            {...props}
            firstImage={firstImage}
            url={videoTrailerUrl}
            autoplay={autoplayVideoTrailer || false}
            onError={setVideoTrailerError}
            options={{
              loop: true,
              muted: autoplayVideoTrailer || false,
              controlBar: {
                pictureInPictureToggle: false,
              },
              ...props.options,
            }}
          />
        )
      : null;

  const firstImageOrTrailer = (props = {}) => {
    if (!desktop && !props.onlyOnMobile) return null;
    if (desktop && props.onlyOnMobile) return null;

    if (videoTailerPlayer) return videoTailerPlayer(props);
    return singleImage(props);
  };

  let imgContent = singleImage();

  const generateImgContent = (type = 'default') => {
    const secondImage = images[1];
    const thirdImage = images[2];
    const fourthImage = images[3];
    const fifthImage = images[4];
    const singleImageOnMobile = firstImageOrTrailer({
      onClick: () => (!videoTailerPlayer ? handleViewPhotosClick(0) : null),
      onlyOnMobile: true,
      className: `md:hidden ${rootForImage} cursor-pointer rounded-none`,
      options: {
        noRounded: true,
      },
    });

    switch (type) {
      case '3:1x2-1':
        return (
          <>
            <div className="hidden grid-cols-3 gap-2 md:grid">
              <div
                className="relative col-span-2 cursor-pointer"
                onClick={() => (!videoTailerPlayer ? handleViewPhotosClick(0) : null)}
              >
                {firstImageOrTrailer({
                  className: `rounded-r-none ${rootForImage}`,
                  transformationType: 'hero-2/3-col-1-row',
                  fluid: false,
                  options: {
                    fill: true,
                    objectFit: 'cover',
                  },
                })}
              </div>

              <div className="relative cursor-pointer" onClick={() => handleViewPhotosClick(1)}>
                <Img
                  key={secondImage.metadata.name}
                  transformationType="hero-1/3-col-1-row"
                  alt={title}
                  id={secondImage.metadata.name}
                  src={!secondImage.metadata.name ? secondImage.metadata.src : null}
                />
              </div>
            </div>
            {singleImageOnMobile}
          </>
        );
      case '3:1x2-2':
        return (
          <>
            <div className="hidden grid-cols-3 gap-2 md:grid">
              <div
                onClick={() => (!videoTailerPlayer ? handleViewPhotosClick(0) : null)}
                className="relative col-span-2 cursor-pointer"
              >
                {firstImageOrTrailer({
                  className: `rounded-r-none ${rootForImage}`,
                  transformationType: 'hero-2/3-col-1-row',
                  fluid: false,
                  options: {
                    fill: true,
                    objectFit: 'cover',
                  },
                })}
              </div>

              <div className="grid grid-rows-2 gap-2">
                <div onClick={() => handleViewPhotosClick(1)} className="relative cursor-pointer">
                  <Img
                    id={secondImage.metadata.name}
                    key={secondImage.metadata.name}
                    src={!secondImage.metadata.name ? secondImage.metadata.src : null}
                    transformationType="hero-1/3-col-1/2-row"
                    alt={title}
                  />
                </div>
                <div onClick={() => handleViewPhotosClick(2)} className="relative cursor-pointer">
                  <Img
                    key={thirdImage.metadata.name}
                    alt={title}
                    id={thirdImage.metadata.name}
                    src={!thirdImage.metadata.name ? thirdImage.metadata.src : null}
                    transformationType="hero-1/3-col-1/2-row"
                  />
                </div>
              </div>
            </div>
            {singleImageOnMobile}
          </>
        );
      case '4:1-1-2-1':
        return (
          <>
            <div className="hidden grid-cols-4 gap-2 md:grid">
              <div onClick={() => (!videoTailerPlayer ? handleViewPhotosClick(0) : null)} className="relative cursor-pointer">
                {firstImageOrTrailer({
                  className: `rounded-r-none ${rootForImage}`,
                  transformationType: 'hero-1/4-col-1-row',
                  fluid: false,
                  options: {
                    fill: true,
                    objectFit: 'cover',
                  },
                })}
              </div>

              <div onClick={() => handleViewPhotosClick(1)} className="relative cursor-pointer">
                <Img
                  key={secondImage.metadata.name}
                  id={secondImage.metadata.name}
                  src={!secondImage.metadata.name ? secondImage.metadata.src : null}
                  transformationType="hero-1/4-col-1-row"
                  alt={title}
                  className={rootForImage}
                />
              </div>
              <div className="grid grid-rows-2 gap-2">
                <div onClick={() => handleViewPhotosClick(2)} className="relative cursor-pointer">
                  <Img
                    key={thirdImage.metadata.name}
                    id={thirdImage.metadata.name}
                    src={!thirdImage.metadata.name ? thirdImage.metadata.src : null}
                    transformationType="hero-1/4-col-1/2-row"
                    alt={title}
                  />
                </div>
                <div onClick={() => handleViewPhotosClick(3)} className="relative cursor-pointer">
                  <Img
                    key={fourthImage.metadata.name}
                    id={fourthImage.metadata.name}
                    src={!fourthImage.metadata.name ? fourthImage.metadata.src : null}
                    transformationType="hero-1/4-col-1/2-row"
                    alt={title}
                  />
                </div>
              </div>
              <div onClick={() => handleViewPhotosClick(4)} className="relative cursor-pointer">
                <Img
                  key={fifthImage.metadata.name}
                  id={fifthImage.metadata.name}
                  src={!fifthImage.metadata.name ? fifthImage.metadata.src : null}
                  transformationType="hero-1/4-col-1-row"
                  alt={title}
                  className={rootForImage}
                />
              </div>
            </div>
            {singleImageOnMobile}
          </>
        );
      case 'default':
      case '4:1x2-2-2': {
        let galleryImages = [];
        try {
          galleryImages = JSON.parse(JSON.stringify(images)).splice(1, 4);
        } catch {
          console.log('There was an error');
        }
        return (
          <>
            <div className="hidden grid-cols-2 gap-2 md:grid">
              {/* First Img */}
              <div onClick={() => (!videoTailerPlayer ? handleViewPhotosClick(0) : null)} className="relative cursor-pointer">
                {firstImageOrTrailer({
                  className: `rounded-r-none ${rootForImage} cursor-pointer`,
                  transformationType: 'hero-2/4-col-1-row',
                  fluid: false,
                  options: {
                    fill: true,
                    objectFit: 'cover',
                  },
                })}
              </div>

              <div className="grid grid-cols-2 gap-2">
                {galleryImages.map((image, index) => {
                  return (
                    <div onClick={() => handleViewPhotosClick(index + 1)} key={index} className="relative cursor-pointer">
                      <Img
                        key={image.metadata.name}
                        id={image.metadata.name}
                        src={!image.metadata.name ? image.metadata.src : null}
                        transformationType="hero-1/4-col-1/2-row"
                        alt={title}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {singleImageOnMobile}
          </>
        );
      }
      default:
        return (
          <>
            <div></div>
            {firstImageOrTrailer({
              onClick: () => (!videoTailerPlayer ? handleViewPhotosClick(0) : null),
              className: `${rootForImage} cursor-pointer`,
              fluid: false,
              options: {
                fill: true,
              },
            })}
            {singleImageOnMobile}
          </>
        );
    }
  };
  if (headerType && images.length > 0) {
    imgContent = generateImgContent(headerType);
  }

  const ImgMaybe = !shouldRenderLiveVideoPlayer ? imgContent : null;
  const marketplace = getMarketplace();
  const viewPhotosButton =
    !hidePhotosButton && hasImages && images.length > 1 && !shouldRenderLiveVideoPlayer ? (
      <Button
        mpcTheme={marketplace?.branding?.theme}
        theme="white"
        size="sm"
        className={css.viewPhotos}
        onClick={() => handleViewPhotosClick(0)}
      >
        {viewMoreText}
      </Button>
    ) : null;

  return (
    <>
      <div className={`${className} relative`}>
        <div className={css.threeToTwoWrapper}>
          <div
            className={hasVideoTrailer ? '' : css.aspectWrapper}
            // onClick={e => {
            //   // if (isLiveStreamListingType) return;
            //   // handleViewPhotosClick(e);
            // }}
          >
            {ImgMaybe}
            {liveVideoPlayerMaybe}

            {isLiveStreamListingType && hasTimeSlots ? (
              <LiveStreamCountDown
                hasAccessToVideoContent={hasAccessToVideoContent}
                liveStreamError={liveStreamError}
                checkForNewStream={() => debouncedCheckForNewStream(checkForNewLiveStream)}
                liveStreamStatus={liveStreamStatus}
                timeslots={timeslots}
              />
            ) : null}

            {viewPhotosButton}
          </div>
        </div>

        <Modal
          id="ListingPage.ImageCarouselFullscreen"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={() => setImageCarouselOpen(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {imageCarouselOpen ? <ImageCarouselFullscreen selectedImageIndex={modalSelectedImageIndex} images={images} /> : null}
        </Modal>
      </div>
    </>
  );
};

export default SectionImages;
