import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@marketplace-co/react-utils-hooks/useMediaQuery";
import { imageBuilder } from "@marketplace-co/helpers/imagekit";

const qualityLevels = [
  "low-sm:medium",
  "low-sm:high",
  "medium-sm:high",
  "low",
  "medium",
  "high",
];
const defaultClassNames = {
  objectFit: "object-cover",
  objectPosition: "object-center",
};

const getPosterImage = (image, sm) => {
  const name = image?.metadata?.name;
  if (!name) return null;
  return imageBuilder({ name, type: sm ? "lg" : "md" });
};

const getVideoQuality = ({ backgroundVideoQuality, sm }) => {
  switch (backgroundVideoQuality) {
    case "low-sm:medium":
      return !sm ? "low" : "medium";
    case "low-sm:high":
      return !sm ? "low" : "high";
    case "medium-sm:high":
      return !sm ? "medium" : "high";
    case "low":
    case "medium":
    case "high":
      return backgroundVideoQuality;
    default:
      return "medium";
  }
};

const muxBaseUrl = "https://stream.mux.com";
const getMuxUrl = (video, quality = "medium") => {
  // If the video is a string, assume it is a mux src
  if (typeof video === "string") return video;

  const playbackId = video?.metadata?.playback?.id;
  if (!playbackId) return null;
  return `${muxBaseUrl}/${playbackId}/${quality}.mp4`;
};

const getSrc = ({ video, src, videoQuality }) => {
  const muxUrl = getMuxUrl(video, videoQuality);
  return muxUrl || src || null;
};

const ApplicationElementVideo = ({
  video,
  playsinline,
  src,
  autoplay,
  muted,
  loop,
  image,
  controls,
  onError,
  layout,
  className,
  classNames: _classNames,
  hidePausePlayButton = false,
  backgroundVideoQuality,
  ...rest
}) => {
  const sm = useMediaQuery("(min-width: 640px)");
  const videoQuality = getVideoQuality({
    backgroundVideoQuality,
    sm,
  });

  const videoRef = useRef(null);
  const videoEl = videoRef?.current;
  // https://docs.mux.com/guides/video/web-autoplay-your-videos
  useEffect(() => {
    if (!videoEl || !autoplay) return;

    videoEl
      .play()
      .then(() => {})
      .catch((error) => onError(error));
  }, [autoplay, videoEl]);

  const [isPlaying, setIsPlaying] = useState(false);
  const onPlay = () => setIsPlaying(true);
  const onPause = () => setIsPlaying(false);
  const play = () => videoEl?.play();
  const pause = () => videoEl?.pause();
  const poster = getPosterImage(image, sm) || null;

  const videoSrc = getSrc({ video, src, videoQuality });
  if (!videoSrc) return null;

  const videoClassName =
    layout === "fill" ? "absolute inset-0 w-full h-full" : "";

  const classNames = {
    ...defaultClassNames,
    ..._classNames,
  };

  const {
    objectFit: objectFitClassName,
    objectPosition: objectPositionClassName,
  } = classNames || {};
  return (
    <>
      <video
        className={`${className} ${videoClassName} ${objectFitClassName} ${objectPositionClassName}`}
        ref={videoRef}
        src={videoSrc}
        autoPlay={autoplay}
        controls={controls}
        muted={muted}
        loop={loop}
        onError={onError}
        onPause={onPause}
        onPlay={onPlay}
        playsInline={playsinline}
        poster={poster}
        {...rest}
      />

      {!hidePausePlayButton ? (
        <button
          onClick={() => (isPlaying ? pause() : play())}
          type="button"
          className="absolute top-auto left-auto !bottom-6 !right-6 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-transparent bg-black bg-opacity-25 p-1.5 text-white transition hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-0"
        >
          <svg
            className="h-4 w-4 flex-shrink-0"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d={
                isPlaying ? "M6 19h4V5H6v14zm8-14v14h4V5h-4z" : "M8 5v14l11-7z"
              }
            />
          </svg>
        </button>
      ) : null}
    </>
  );
};

ApplicationElementVideo.propTypes = {
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  src: PropTypes.string,
  controls: PropTypes.bool,
  mpcVideo: PropTypes.object,
  onError: PropTypes.func,
  layout: PropTypes.string,
  className: PropTypes.string,
  hidePausePlayButton: PropTypes.bool,
  playsinline: PropTypes.bool,
  image: PropTypes.object,
  video: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classNames: PropTypes.shape({
    objectFit: PropTypes.string,
    objectPosition: PropTypes.string,
  }),
  backgroundVideoQuality: PropTypes.oneOf(qualityLevels),
};
ApplicationElementVideo.defaultProps = {
  autoplay: true,
  muted: true,
  controls: false,
  loop: true,
  layout: "fill",
  className: "",
  hidePausePlayButton: false,
  playsinline: false,
  classNames: defaultClassNames,
  backgroundVideoQuality: "medium-sm:high",
  onError: () => {},
};
export default ApplicationElementVideo;
