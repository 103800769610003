import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { array, bool, func, number, object, shape, string } from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { compose } from 'redux';

import StartupContainer from '@/components/StartupContainer/StartupContainer';

import { authenticationInProgress, logout } from '@/ducks/Auth.duck';
import { getMarketplaceInfo } from '@/ducks/Marketplace.duck';
import { isScrollingDisabled, manageDisableScrolling, setAuthModalOpened } from '@/ducks/UI.duck';
import { hasCurrentUserErrors, sendVerificationEmail } from '@/ducks/user.duck';

import { injectIntl } from '@/util/reactIntl';
import { propTypes } from '@/util/types/propTypes';

export const StartupComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSetAuthModalOpened,
    children,
    scrollingDisabled,
    marketplace,
    ...rest
  } = props;
  const noNavPaths = ['/login', '/signup', '/recover-password'];

  let content = (
    <StartupContainer
      scrollingDisabled={scrollingDisabled}
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserListing={currentUserListing}
      currentUserListingFetched={currentUserListingFetched}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      marketplace={marketplace}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      onSetAuthModalOpened={onSetAuthModalOpened}
      showGenericError={hasGenericError}
      {...rest}
    >
      {children}
    </StartupContainer>
  );

  if (location && noNavPaths.indexOf(location.pathname) > -1) {
    content = children;
  }

  const contextClass = {
    success: 'bg-primary-500 text-white',
    error: 'bg-error-600 text-white',
    info: 'bg-gray-600 text-white',
    warning: 'bg-warning-400 text-white',
    default: 'bg-white text-gray-900 b border-gray-200',
    dark: 'bg-white-600 text-gray-300',
  };

  return (
    <>
      {content}

      {!rest?.preview ? (
        <ToastContainer
          toastClassName={({ type }) =>
            contextClass[type || 'default'] +
            ' relative flex max-w-sm w-full p-1 min-h-10 rounded-lg justify-between overflow-hidden cursor-pointer shadow-lg'
          }
          bodyClassName="text-sm font-medium block p-3"
          position="top-right"
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      ) : null}
    </>
  );
};

StartupComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  authScopes: null,
};

StartupComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  const marketplace = state.Marketplace;
  // LayoutDrawer needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  const { authModalOpened = false, authModalSuccessAction } = state.UI;
  // LayoutDrawer needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    marketplace,
    authModalOpened,
    authModalSuccessAction,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getMarketplace: () => dispatch(getMarketplaceInfo()),
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) => dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSetAuthModalOpened: () => dispatch(setAuthModalOpened()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const Startup = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(StartupComponent);

export default Startup;
