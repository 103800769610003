import { useState } from 'react';

import BizHours from '@/components/BizHours/BizHours';
import MenuButton from '@/components/MenuButton/MenuButton';

import UiTextRenderer from './UiTextRenderer';

function LocationDropdown({ locations, activeIndex, setActiveIndex }) {
  return (
    <MenuButton
      onSelect={data => {
        const { key } = data || {};
        setActiveIndex(key);
      }}
      options={locations.map((i, index) => ({
        label: i.title,
        key: index,
      }))}
      initialValue={activeIndex}
      filter
      offset={[0, 0]}
      noFilterLabelWrapper
      label={locations?.[activeIndex]?.title}
      className="mb-3 -mt-1 flex flex-col items-start"
      extraButtonClassName="!border-none !ring-0 !p-0 !bg-transparent"
    />
  );
}

const UiBizHours = ({
  className = 'overflow-hidden',
  metadata,
  hours: initialHours,
  preview,
  type,
  contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
}) => {
  const { title = 'About', titleClassNames = {} } = metadata || {};
  const [activeIndex, setActiveIndex] = useState(0);

  let hours = initialHours;
  if (!hours?.length && preview) {
    hours = metadata?.locations;
  }
  if (!hours?.length) return null;

  const hasMultipleHours = hours.length > 1;
  const activeHours = hours?.[activeIndex]?.hours;

  return (
    <section className={className}>
      <div className={contentClassName}>
        <UiTextRenderer
          classNames={titleClassNames}
          className="mb-4"
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {title}
        </UiTextRenderer>

        {hasMultipleHours ? (
          <LocationDropdown activeIndex={activeIndex} setActiveIndex={setActiveIndex} locations={hours} />
        ) : null}
        <div className="space-y-1 text-sm text-gray-500">
          <BizHours hours={activeHours} />
        </div>
      </div>
    </section>
  );
};

export default UiBizHours;
