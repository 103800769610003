import { paymentAccount, paymentService } from '@/clients';

import * as log from '@/util/log';
import { storableError } from '@/util/errors';
import { getStripeConnectAccountType } from '@/util/helpers';
import { toast } from '@/util/toast';

import config from '@/config';

// ================ Action types ================ //

export const STRIPE_ACCOUNT_CREATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_CREATE_REQUEST';
export const STRIPE_ACCOUNT_CREATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_CREATE_SUCCESS';
export const STRIPE_ACCOUNT_CREATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_CREATE_ERROR';

export const STRIPE_ACCOUNT_UPDATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_UPDATE_REQUEST';
export const STRIPE_ACCOUNT_UPDATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_UPDATE_SUCCESS';
export const STRIPE_ACCOUNT_UPDATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_UPDATE_ERROR';

export const STRIPE_ACCOUNT_PROCESS_REQUEST = 'app/stripe/STRIPE_ACCOUNT_PROCESS_REQUEST';
export const STRIPE_ACCOUNT_PROCESS_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_PROCESS_SUCCESS';
export const STRIPE_ACCOUNT_PROCESS_ERROR = 'app/stripe/STRIPE_ACCOUNT_PROCESS_ERROR';

export const STRIPE_ACCOUNT_FETCH_REQUEST = 'app/stripe/STRIPE_ACCOUNT_FETCH_REQUEST';
export const STRIPE_ACCOUNT_FETCH_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_FETCH_SUCCESS';
export const STRIPE_ACCOUNT_FETCH_ERROR = 'app/stripe/STRIPE_ACCOUNT_FETCH_ERROR';

export const STRIPE_ACCOUNT_CLEAR_ERROR = 'app/stripe/STRIPE_ACCOUNT_CLEAR_ERROR';

export const GET_ACCOUNT_LINK_REQUEST = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_REQUEST';
export const GET_ACCOUNT_LINK_SUCCESS = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_SUCCESS';
export const GET_ACCOUNT_LINK_ERROR = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_ERROR';

// ================ Reducer ================ //

const initialState = {
  createStripeAccountInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountInProgress: false,
  updateStripeAccountError: null,
  fetchStripeAccountInProgress: false,
  fetchStripeAccountError: null,
  getAccountLinkInProgress: false,
  getAccountLinkError: null,
  stripeAccount: null,
  stripeAccountFetched: false,
  processStripeConnectInProgress: false,
  processStripeConnectError: null,
  processStripeConnectSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_ACCOUNT_CREATE_REQUEST:
      return { ...state, createStripeAccountError: null, createStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        createStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_CREATE_ERROR:
      console.error(payload);
      return { ...state, createStripeAccountError: payload, createStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_UPDATE_REQUEST:
      return { ...state, updateStripeAccountError: null, updateStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        updateStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_UPDATE_ERROR:
      console.error(payload);
      return { ...state, updateStripeAccountError: payload, createStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_PROCESS_REQUEST:
      return { ...state, processStripeConnectError: null, processStripeConnectInProgress: true };
    case STRIPE_ACCOUNT_PROCESS_SUCCESS:
      return {
        ...state,
        processStripeConnectInProgress: false,
        processStripeConnectSuccess: true,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_PROCESS_ERROR:
      console.error(payload);
      toast.error(payload.error.userMessage);
      return { ...state, processStripeConnectError: payload, processStripeConnectInProgress: false };

    case STRIPE_ACCOUNT_FETCH_REQUEST:
      return { ...state, fetchStripeAccountError: null, fetchStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        fetchStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_FETCH_ERROR:
      console.error(payload);
      return { ...state, fetchStripeAccountError: payload, fetchStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_CLEAR_ERROR:
      return { ...initialState };

    case GET_ACCOUNT_LINK_REQUEST:
      return { ...state, getAccountLinkError: null, getAccountLinkInProgress: true };
    case GET_ACCOUNT_LINK_ERROR:
      console.error(payload);
      return { ...state, getAccountLinkInProgress: false, getAccountLinkError: payload };
    case GET_ACCOUNT_LINK_SUCCESS:
      return { ...state, getAccountLinkInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const stripeAccountCreateRequest = () => ({ type: STRIPE_ACCOUNT_CREATE_REQUEST });

export const stripeAccountCreateSuccess = stripeAccount => ({
  type: STRIPE_ACCOUNT_CREATE_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountCreateError = e => ({
  type: STRIPE_ACCOUNT_CREATE_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountUpdateRequest = () => ({ type: STRIPE_ACCOUNT_UPDATE_REQUEST });

export const stripeAccountUpdateSuccess = stripeAccount => ({
  type: STRIPE_ACCOUNT_UPDATE_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountUpdateError = e => ({
  type: STRIPE_ACCOUNT_UPDATE_ERROR,
  payload: e,
  error: true,
});

export const processStripeConnectRequest = () => ({ type: STRIPE_ACCOUNT_PROCESS_REQUEST });

export const processStripeConnectSuccess = stripeAccount => ({
  type: STRIPE_ACCOUNT_PROCESS_SUCCESS,
  payload: stripeAccount,
});

export const processStripeConnectError = e => ({
  type: STRIPE_ACCOUNT_PROCESS_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountFetchRequest = () => ({ type: STRIPE_ACCOUNT_FETCH_REQUEST });

export const stripeAccountFetchSuccess = stripeAccount => ({
  type: STRIPE_ACCOUNT_FETCH_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountFetchError = e => ({
  type: STRIPE_ACCOUNT_FETCH_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountClearError = () => ({
  type: STRIPE_ACCOUNT_CLEAR_ERROR,
});

export const getAccountLinkRequest = () => ({
  type: GET_ACCOUNT_LINK_REQUEST,
});
export const getAccountLinkError = e => ({
  type: GET_ACCOUNT_LINK_ERROR,
  payload: e,
  error: true,
});

// ================ Thunks ================ //

export const createStripeAccount = params => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined' || !window.Stripe) {
    throw new Error('Stripe must be loaded for submitting PayoutPreferences');
  }
  const stripe = window.Stripe(config.publishableKey);

  const { country, accountType, bankAccountToken, businessProfileMCC, businessProfileURL } = params;

  // Capabilities are a collection of settings that can be requested for each provider.
  // What Capabilities are required determines what information Stripe requires to be
  // collected from the providers.
  // You can read more from here: https://stripe.com/docs/connect/capabilities-overview
  // In Flex both 'card_payments' and 'transfers' are required.
  const requestedCapabilities = ['card_payments', 'transfers'];

  const accountInfo = {
    business_type: accountType,
    tos_shown_and_accepted: true,
  };

  dispatch(stripeAccountCreateRequest());

  return stripe
    .createToken('account', accountInfo)
    .then(response => {
      const accountToken = response.token.id;
      return sdk.stripeAccount.create(
        {
          country,
          accountToken,
          bankAccountToken,
          requestedCapabilities,
          businessProfileMCC,
          businessProfileURL,
        },
        { expand: true }
      );
    })
    .then(response => {
      const stripeAccount = response.data.data;
      dispatch(stripeAccountCreateSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(stripeAccountCreateError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta ? e.apiErrors[0].meta.stripeMessage : null;
      log.error(err, 'create-stripe-account-failed', { stripeMessage });
      throw e;
    });
};

// This function is used for updating the bank account token
// but could be expanded to other information as well.
//
// If the Stripe account has been created with account token,
// you need to use account token also to update the account.
// By default the account token will not be used.
// See API reference for more information:
// https://www.sharetribe.com/api-reference/?javascript#update-stripe-account
export const updateStripeAccount = params => (dispatch, getState, sdk) => {
  const bankAccountToken = params.bankAccountToken;

  dispatch(stripeAccountUpdateRequest());
  return sdk.stripeAccount
    .update({ bankAccountToken, requestedCapabilities: ['card_payments', 'transfers'] }, { expand: true })
    .then(response => {
      const stripeAccount = response.data.data;
      dispatch(stripeAccountUpdateSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(stripeAccountUpdateError(e));
      const stripeMessage =
        e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta ? e.apiErrors[0].meta.stripeMessage : null;
      log.error(err, 'update-stripe-account-failed', { stripeMessage });
      throw e;
    });
};

export const fetchStripeAccount = () => dispatch => {
  dispatch(stripeAccountFetchRequest());

  return paymentAccount
    .read()
    .then(response => {
      const paymentAccounts = response.data;
      const stripeAccount = paymentAccounts && paymentAccounts.length ? paymentAccounts[0] : {};
      dispatch(stripeAccountFetchSuccess(stripeAccount));
      return stripeAccount;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(stripeAccountFetchError(e));
      throw e;
    });
};

export const getStripeConnectAccountLink = params => (dispatch, getState) => {
  const {
    stripeConnected,
    successURL,
    stripeAccountId,
    hasStripeAccount,
    // stripePending,
    noDispatch = false,
    accessGrantMethod,
  } = params;
  if (!noDispatch) {
    dispatch(getAccountLinkRequest());
  }

  const marketplace = getState().Marketplace;
  const type = getStripeConnectAccountType(marketplace);

  // Get dashboard link and open in new tab
  if (stripeConnected && type === 'express' && stripeAccountId) {
    return paymentService
      .getDashboardUrl(stripeAccountId)
      .then(response => {
        const url = response.data.url;
        return window.open(url);
      })
      .catch(err => {
        const e = storableError(err);
        dispatch(getAccountLinkError(e));
        throw e;
      });
  }

  // login
  if (hasStripeAccount === true || accessGrantMethod === 'OAuth') {
    // get onboarding url
    return paymentService
      .getOnboardingUrl(successURL, hasStripeAccount, 'OAuth', type)
      .then(response => {
        const url = response.data.onboardingUri;
        return (window.location.href = url);
      })
      .catch(err => {
        const e = storableError(err);
        dispatch(getAccountLinkError(e));
        throw e;
      });
  }

  return paymentService
    .getOnboardingUrl(successURL, null, 'connectOnboarding', type)
    .then(response => {
      const url = response.data.onboardingUri;
      return (window.location.href = url);
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(getAccountLinkError(e));
      throw e;
    });
};

export const processStripeConnect = params => dispatch => {
  dispatch(processStripeConnectRequest());

  const { code, state } = params;
  const body = { code, state };

  return paymentService
    .processOnboardingResponse(body)
    .then(response => {
      dispatch(processStripeConnectSuccess(response.data));
      dispatch(fetchStripeAccount());
      return response.data;
    })
    .catch(err => {
      dispatch(processStripeConnectError(err));
      throw err;
    });
};
