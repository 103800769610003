import mergeDeep from "./mergeDeep";

// `classNames` are classes passed to the component from an author
// `defaultClassNames` are classes that are added by the component by default, they are not passed by the author and serve as a fallback
// `requiredClassNames` are classes that are required due to some config on the component, but not passed by the author
export const getClassNames = ({
  classNames,
  defaultClassNames,
  requiredClassNames,
  flatten = false,
}) => {
  let _classNames = classNames;

  // If requiredClassNames is not empty, merge them with classNames
  if (requiredClassNames) {
    _classNames = mergeDeep(classNames, requiredClassNames);
  }

  const classes = mergeDeep(defaultClassNames, _classNames);

  if (flatten) {
    for (const key in classes) {
      if (typeof classes[key] === "object") {
        classes[key] = getClassName(key, classes);
      }
    }
  }

  return classes;
};

export const getClassName = (key, classNames) => {
  const names = classNames[key];
  if (!names) return "";

  if (typeof names === "object") {
    return Object.keys(names)
      .map((key) => names[key])
      .join(" ");
  } else if (typeof names === "string") {
    return names;
  }

  return "";
};

export default {
  getClassNames,
  getClassName,
};
