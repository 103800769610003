/* eslint-disable react/prop-types */
import React from "react";
import NextImage from "./NextImage";
import PropTypes from "prop-types";
import { blurHashToDataURL } from "./helpers/blurHashToDataURL";

const getImageSrc = ({ image, src }) => {
  // If the image is a string, assume it is a src
  if (typeof image === "string") return image;

  const name = image?.metadata?.name;
  const srcOrName = name || src;
  return srcOrName || null;
};

// https://nextjs.org/docs/api-reference/next/image
const ApplicationElementImage = ({
  image,
  alt,
  src,
  transformation = "fo-auto",
  className = "",
  layout = "fill",
  objectFit = "cover",
  objectPosition = "center",
  loading,
  priority = false,
  blurDataURL,
  quality,
  width,
  height,
  style,
  onLoadingComplete,
  placeholder,
  sizes,
  unoptimized = false,
  lazyRoot = null,
  lazyBoundary,
  classNames,
}) => {
  // Get image src
  const imageSrc = getImageSrc({ image, src });
  if (!imageSrc) return null;

  // Attempt to get the image data
  width = width || image?.metadata?.width;
  height = height || image?.metadata?.height;

  // Classes and object fit/position
  const {
    objectFit: objectFitClassName,
    objectPosition: objectPositionClassName,
  } = classNames || {};
  if (objectFitClassName) {
    className = `${className} ${objectFitClassName}`;
    objectFit = objectFitClassName.replace("object-", "");
  }
  if (objectPositionClassName) {
    className = `${className} ${objectPositionClassName}`;
    objectPosition = objectPositionClassName
      .replace("object-", "")
      .replaceAll("-", " ");
  }

  // Check for a blurhash, and convert to a data URL if found
  const blurhash = image?.metadata?.blurhash;
  if (blurhash) {
    blurDataURL = blurHashToDataURL(blurhash);
  }

  return (
    <NextImage
      alt={alt}
      className={className}
      priority={priority}
      loading={loading}
      transformation={transformation}
      objectPosition={objectPosition}
      objectFit={objectFit}
      layout={layout}
      src={imageSrc}
      blurDataURL={blurDataURL}
      style={style}
      width={width}
      height={height}
      onLoadingComplete={onLoadingComplete}
      placeholder={placeholder}
      quality={quality}
      sizes={sizes}
      unoptimized={unoptimized}
      lazyRoot={lazyRoot}
      lazyBoundary={lazyBoundary}
      draggable="false"
    />
  );
};

ApplicationElementImage.propTypes = {
  /**
   * The layout of the image to render
   */
  layout: PropTypes.oneOf(["intrinsic", "fixed", "responsive", "fill"]),

  sizes: PropTypes.string,

  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      metadata: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        fileId: PropTypes.string,
        filePath: PropTypes.string,
        fileType: PropTypes.string,
        thumbnailUrl: PropTypes.string,
      }),
      provider: PropTypes.string,
    }),
  ]),

  /**
   * A placeholder to use while the image is loading. [More info](https://nextjs.org/docs/api-reference/next/image#placeholder)
   */
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(["empty", "blur"]),
  ]),

  /**
   * Class names to apply to the image
   */
  classNames: PropTypes.object,
};

ApplicationElementImage.defaultProps = {
  layout: "fill",
  quality: 100,
  placeholder: "blur",
  loader: "imagekit",
  blurDataURL:
    "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
  classNames: {},
};

export default ApplicationElementImage;
