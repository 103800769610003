import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Media from "@marketplace-co/react-application-element-media";

const defaultClassNames = {
  titleTextClasses:
    "font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl",
  descriptionTextClasses: "text-xl tracking-tight",
  featureTitleTextClasses: "font-display text-lg",
  text100: "text-primary-100",
  text600: "text-primary-600",
  "shadow900/20": "shadow-primary-900/20",
  mediaAspectRatio: "aspect-[1085/730]",
};

// These class names work with a dark bg on dark mode and a light bg on light mode.
const nativeClassNames = {
  "lg:text-white": "lg:text-black dark:lg:text-white",
  "hover:text-white": "hover:text-black dark:hover:text-white",
  "text-white": "text-black dark:text-white",
  "bg-white/10": "bg-black/5 dark:bg-white/10",
  "ring-white/10": "ring-black/5 dark:ring-white/10",
  "lg:bg-white/10": "lg:bg-black/5 dark:lg:bg-white/10",
  "lg:ring-white/10": "lg:ring-black/5 dark:lg:ring-white/10",
  "hover:bg-white/10 lg:hover:bg-white/5":
    "hover:bg-black/5 dark:hover:bg-white/10 lg:hover:bg-black/5 dark:lg:hover:bg-white/5",
  "group-hover:text-white":
    "group-hover:text-black dark:group-hover:text-white",
};

export default function MarketingSectionFeatureSelectorList({
  className,
  classNames: _classNames,
  title,
  description,
  features,
  colorTheme,
}) {
  let [tabOrientation, setTabOrientation] = useState("horizontal");

  const isNativeColorTheme = colorTheme === "native";

  // This component is rendered in various colorThemes, one of them being native.
  // When this is the case, we need to use dynamic white colors so contrast is maintained.
  const whiteClsx = (className) => {
    if (!isNativeColorTheme) return className;
    return nativeClassNames[className] || className;
  };

  // Ensure we have a full classnames object to work with
  const classNames = {
    ...defaultClassNames,
    ..._classNames,
  };

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section className={className}>
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className={classNames.titleTextClasses}>{title}</h2>
          <p
            className={`mt-5 ${classNames.descriptionTextClasses} ${classNames.text100}`}
          >
            {description}
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex space-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:space-y-1 lg:space-x-0 lg:whitespace-normal">
                  {features?.length
                    ? features.map((feature, featureIndex) => (
                        <div
                          key={feature.title}
                          className={clsx(
                            "group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6",
                            {
                              [`bg-white-background ${whiteClsx(
                                "lg:bg-white/10"
                              )} lg:ring-1 lg:ring-inset ${whiteClsx(
                                "lg:ring-white/10"
                              )}`]: selectedIndex === featureIndex,
                              [whiteClsx(
                                "hover:bg-white/10 lg:hover:bg-white/5"
                              )]: selectedIndex !== featureIndex,
                            }
                          )}
                        >
                          <h3>
                            <Tab
                              className={`${
                                classNames.featureTitleTextClasses
                              } outline-none ${
                                selectedIndex === featureIndex
                                  ? `${classNames.text600} ${whiteClsx(
                                      "lg:text-white"
                                    )}`
                                  : `${classNames.text100} ${whiteClsx(
                                      "hover:text-white"
                                    )} ${whiteClsx("lg:text-white")}`
                              }`}
                            >
                              <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                              {feature.title}
                            </Tab>
                          </h3>
                          <p
                            className={`mt-2 hidden text-sm lg:block ${
                              selectedIndex === featureIndex
                                ? whiteClsx("text-white")
                                : `${classNames.text100} ${whiteClsx(
                                    "group-hover:text-white"
                                  )}`
                            }`}
                          >
                            {feature.description}
                          </p>
                        </div>
                      ))
                    : null}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={true}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div
                        className={`absolute -inset-x-4 -top-[6.5rem] -bottom-[4.25rem] ${whiteClsx(
                          "bg-white/10"
                        )} ring-1 ring-inset ${whiteClsx(
                          "ring-white/10"
                        )} sm:inset-x-0 sm:rounded-t-xl`}
                      />
                      <p
                        className={`relative mx-auto max-w-2xl text-base ${whiteClsx(
                          "text-white"
                        )} sm:text-center`}
                      >
                        {feature.description}
                      </p>
                    </div>
                    <div
                      className={`relative mt-10 ${classNames.mediaAspectRatio} w-[45rem] overflow-hidden rounded-xl bg-gray-50 dark:bg-gray-900 shadow-xl ${classNames["shadow900/20"]} sm:w-auto lg:mt-0 lg:w-[67.8125rem]`}
                    >
                      <Media
                        image={feature.image} // deprecated, use feature.media instead
                        {...feature.media}
                        imageProps={{
                          layout: "fill",
                          objectPosition: "top",
                          priority: true,
                          sizes:
                            "(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem",
                        }}
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </div>
    </section>
  );
}

MarketingSectionFeatureSelectorList.propTypes = {
  /**
   * class applied to the wrapper, this will overwrite the default classes
   */
  className: PropTypes.string,
  /**
   * Title of the section
   */
  title: PropTypes.string.isRequired,
  /**
   * Description of the section
   */
  description: PropTypes.string.isRequired,

  /**
   * Color theme of the section, when dark the section expexts a dark theme to be present
   * If native, the section expects a light bg when :dark is not active, and a dark bg when :dark is active
   */
  colorTheme: PropTypes.oneOf(["dark", "native"]),

  /**
   *  A list of features to display.
   */
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      media: PropTypes.shape({
        image: PropTypes.object.isRequired,
        video: PropTypes.object,
        backgroundVideo: PropTypes.object,
      }).isRequired,
    })
  ).isRequired,

  /**
   * Control the class names of the markup in various states
   */
  classNames: PropTypes.shape({
    titleTextClasses: PropTypes.string,
    descriptionTextClasses: PropTypes.string,
    featureTitleTextClasses: PropTypes.string,
    text100: PropTypes.string,
    text600: PropTypes.string,
    "shadow900/20": PropTypes.string,
  }),
};

MarketingSectionFeatureSelectorList.defaultProps = {
  className: "relative overflow-hidden bg-primary-600 pt-20 pb-28 sm:py-32",
  classNames: defaultClassNames,
  colorTheme: "dark",
};
