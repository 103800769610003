import { auth, calendar, tasks } from '@/clients';

import { storableError } from '@/util/errors';

// ================ Action types ================ //

export const CALENDAR_REQUEST = 'app/Calendar/CALENDAR_REQUEST';
export const CALENDAR_SUCCESS = 'app/Calendar/CALENDAR_SUCCESS';
export const CALENDAR_ERROR = 'app/Calendar/CALENDAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  calendarError: null,
  calendarInProgress: false,
  calendarData: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CALENDAR_REQUEST:
      return {
        ...state,
        calendarInProgress: true,
        calendarError: null,
      };
    case CALENDAR_SUCCESS:
      return { ...state, calendarInProgress: false, calendarData: payload };
    case CALENDAR_ERROR:
      return { ...state, calendarInProgress: false, calendarError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const calendarRequest = () => ({ type: CALENDAR_REQUEST });
export const calendarSuccess = payload => ({ type: CALENDAR_SUCCESS, payload });
export const calendarError = error => ({ type: CALENDAR_ERROR, payload: error, error: true });

// ================ Thunks ================ //

export const getCalendarOrders =
  ({ contactId }) =>
  dispatch => {
    const queryParams = { limit: 500 };
    if (contactId) {
      queryParams.assignedTo = contactId;
    } else {
      queryParams.assignedToAccount = auth.getAccountId();
    }
    return tasks
      .list(queryParams)
      .then(response => {
        const { data } = response;
        return dispatch(calendarSuccess(data));
      })
      .catch(e => {
        const err = storableError(e);
        dispatch(calendarError(err));
      });
  };

export const getCalendarSales =
  ({ listings }) =>
  dispatch => {
    const queryParams = { limit: 500 };
    if (listings) queryParams.listing = `containsAny:${listings}`;

    return calendar
      .rostering(queryParams)
      .then(response => {
        const { data } = response;
        return dispatch(calendarSuccess(data));
      })
      .catch(e => {
        const err = storableError(e);
        dispatch(calendarError(err));
      });
  };

export const getCalendarData =
  ({ tab, contactId, ...rest }) =>
  dispatch => {
    dispatch(calendarRequest());

    if (tab === 'orders') {
      return dispatch(getCalendarOrders({ ...rest, contactId }));
    } else {
      return dispatch(getCalendarSales(rest));
    }
  };
