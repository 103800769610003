import UiTextRenderer from './UiTextRenderer';

const LinkMaybe = ({ children, data }) => {
  const { value, featureType } = data || {};
  const isTextFeature = featureType === 'text';
  const textType = data?._feature?.textType;
  const isLinkSupportedTextType = textType === 'url' || textType === 'phone';
  if (isTextFeature && isLinkSupportedTextType) {
    const isPhone = textType === 'phone';
    const urlPrefix = isPhone ? 'tel:' : '';
    const target = !isPhone ? '_blank' : '';
    return (
      <a className="font-semibold" target={target} rel="noreferrer" href={`${urlPrefix}${value}`}>
        {children}
      </a>
    );
  }
  return <div>{children}</div>;
};

const UiList = params => {
  let { items, metadata = {}, contentClassName, preview, onlyShowWhenNoListings, hasListings = false, type } = params || {};

  if (!items?.length) return null;
  if (onlyShowWhenNoListings && hasListings) return null;

  const itemsWithValues = items.filter(i => !!i.value);
  if (!itemsWithValues?.length && !preview) return null;

  return (
    <section className={metadata?.className || ''}>
      <div className="mb-6">
        <UiTextRenderer
          classNames={metadata?.titleClassNames}
          className={contentClassName}
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {metadata?.title}
        </UiTextRenderer>

        {metadata?.description ? <div className="mt-1 text-base leading-5 text-gray-500">{metadata?.description}</div> : null}
      </div>

      <div className={contentClassName}>
        <div className="space-y-4 sm:space-y-3">
          {items.map(item => {
            let label = item.featureLabel || item.value;
            if (preview && !label) label = '*Preview';

            if (!preview && !item.value) return null;
            return (
              <div key={item.value} className="items-wrap flex-row sm:flex">
                <div className="mb-1 sm:mb-0 sm:min-w-[175px]">{item.title}</div> <LinkMaybe data={item}>{label}</LinkMaybe>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default UiList;
