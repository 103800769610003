import { string } from 'prop-types';

import Img from '@/components/Img/Img';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

const ThreeColImg = ({ image, imageSizeClassName, imageBackgroundSize = 'cover' }) => {
  if (!image || !image.metadata) return null;
  const { url: src, name: imageId } = image.metadata || {};

  if (!imageSizeClassName) {
    return (
      <div className="flex items-center">
        <img style={{ height: 45 }} loading="lazy" alt="Icon" height="45" src={src} />
      </div>
    );
  }

  return (
    <div className="relative flex w-full flex-shrink-0 flex-row items-center justify-center overflow-hidden">
      <div className={imageSizeClassName}></div>
      <Img
        loading="lazy"
        draggable="false"
        className="absolute h-full w-full rounded object-cover"
        src={src}
        key={imageId}
        innerStyle={{
          backgroundSize: imageBackgroundSize,
        }}
        bgColor="white"
        transformationType="md"
        id={imageId}
      />
    </div>
  );
};

const ThreeCol = ({ title, description, className, settings, features, titleClassNames = {} }) => {
  const {
    gridTextAlignmentClassName = '',
    containerMaxWidth = 'max-w-xl lg:max-w-screen-xl',
    imageSizeClassName,
    gridGapClassName = 'lg:gap-8',
    gridTextPaddingClassName = '',
    descriptionMaxWidthClassName = 'max-w-3xl',
    imageBackgroundSize = 'cover',
  } = settings || {};

  return (
    <div className={`${className} bg-white-background py-12`}>
      <div className={`${containerMaxWidth} mx-auto`}>
        <div className="mx-auto mb-12 max-w-3xl md:text-center">
          <UiTextRenderer
            text={title}
            classNames={titleClassNames}
            defaultFontWeightClassName="font-bold"
            defaultFontSizeClassName="text-2xl md:text-3xl lg:text-4xl"
            defaultFontFamilyClassName="font-header"
            defaultFontColorClassName="text-header"
          />
          <p className={`mt-4 ${descriptionMaxWidthClassName} text-xl text-gray-500 md:mx-auto`}>{description}</p>
        </div>

        <div className={`md:grid md:grid-cols-2 md:gap-8 lg:grid-cols-3 ${gridGapClassName} ${gridTextAlignmentClassName}`}>
          {features.map((feature, index) => {
            const wrapperClassName = index === 0 ? '' : 'mt-10 md:mt-0';
            return (
              <div key={`feature_${index}_icon`} className={wrapperClassName}>
                <ThreeColImg
                  image={feature.image}
                  imageBackgroundSize={imageBackgroundSize}
                  imageSizeClassName={imageSizeClassName}
                />
                <div className={`mt-5 ${gridTextPaddingClassName}`}>
                  <div className="text-lg font-medium leading-6 text-gray-900">{feature.title}</div>
                  <p className="mt-2 text-base leading-6 text-gray-500">{feature.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SectionLandingFeatures = props => {
  const { type, title, features = [], description, category, titleClassNames } = props;

  const firstFeature = features && features.length ? features[0] : {};
  const secondFeature = features && features.length > 1 ? features[1] : {};

  const getIconForId = id => {
    return <SvgRenderer className={'h-6 w-6'} id={id} defaultIcon={'ShieldCheck'} />;
  };

  const sideBySideActive = type === 'sideBySide';
  const isHeaderHidden = titleClassNames?.hidden;
  const sideBySideMaybe = sideBySideActive ? (
    <div className="overflow-hidden pb-16 lg:pb-24">
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        {!isHeaderHidden ? (
          <div className="relative">
            <UiTextRenderer
              text={title}
              classNames={titleClassNames}
              defaultFontWeightClassName="font-bold"
              defaultFontSizeClassName="text-3xl sm:text-4xl"
              defaultFontFamilyClassName="font-header"
              defaultFontColorClassName="text-header"
              defaultAlignClassName="text-center"
            />

            <p className="mx-auto mt-4 max-w-3xl text-center text-xl leading-7 text-gray-500">{description}</p>
          </div>
        ) : null}

        <div
          className={`relative ${!isHeaderHidden ? 'mt-12 lg:mt-24' : 'pt-16'} lg:grid lg:grid-cols-2 lg:items-center lg:gap-8`}
        >
          <div className="relative">
            <h4>
              <UiTextRenderer
                text={firstFeature.title}
                classNames={firstFeature?.titleClassNames}
                defaultFontWeightClassName="font-bold"
                defaultFontSizeClassName="text-2xl sm:text-3xl"
                defaultFontFamilyClassName="font-header"
                defaultFontColorClassName="text-header text-gray-900"
                defaultLineHeightClassName="leading-8 sm:leading-9"
              />
            </h4>

            {firstFeature.description ? <p className="mt-3 text-lg leading-7 text-gray-500">{firstFeature.description}</p> : null}

            <ul className="mt-10">
              {firstFeature.features &&
                firstFeature.features.map((item, index) => {
                  return (
                    <li key={`${index}_feature${item.title}`} className={index !== 0 ? 'mt-10' : null}>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="bg-primary-500 flex h-12 w-12 items-center justify-center rounded-md text-white">
                            {getIconForId(item?.icon)}
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="text-lg font-medium leading-6 text-gray-900">{item.title}</div>
                          <p className="mt-2 text-base leading-6 text-gray-500">{item.description}</p>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img className="relative mx-auto block" width="490" src={firstFeature?.image?.metadata?.url} alt="" />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h4>
                <UiTextRenderer
                  text={secondFeature.title}
                  classNames={secondFeature?.titleClassNames}
                  defaultFontWeightClassName="font-bold"
                  defaultFontSizeClassName="text-2xl sm:text-3xl"
                  defaultFontFamilyClassName="font-header"
                  defaultFontColorClassName="text-header text-gray-900"
                  defaultLineHeightClassName="leading-8 sm:leading-9"
                />
              </h4>

              {secondFeature.description ? (
                <p className="mt-3 text-lg leading-7 text-gray-500">{secondFeature.description}</p>
              ) : null}

              <ul className="mt-10">
                {secondFeature.features &&
                  secondFeature.features.map((item, index) => {
                    return (
                      <li key={`feature_2_${index}`} className={index !== 0 ? 'mt-10' : null}>
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="bg-primary-500 flex h-12 w-12 items-center justify-center rounded-md text-white">
                              {getIconForId(item?.icon)}
                            </div>
                          </div>
                          <div className="ml-4">
                            <div className="text-lg font-medium leading-6 text-gray-900">{item.title}</div>
                            <p className="mt-2 text-base leading-6 text-gray-500">{item.description}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img className="relative mx-auto block" width="490" src={secondFeature?.image?.metadata?.url} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const gridIconsActive = type === 'gridIcons';
  const gridIconsMaybe = gridIconsActive ? (
    <div className="relative py-12">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        {category ? <h2 className="text-primary-600 text-base font-semibold uppercase tracking-wider">{category}</h2> : null}
        <UiTextRenderer
          className="mt-2"
          text={title}
          classNames={titleClassNames}
          defaultFontWeightClassName="font-bold"
          defaultFontSizeClassName="text-3xl sm:text-4xl"
          defaultFontFamilyClassName="font-header"
          defaultFontColorClassName="text-header"
        />

        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">{description}</p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(i => {
              return (
                <div key={i.title} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="bg-primary-500 inline-flex items-center justify-center rounded-md p-3 text-white shadow-lg">
                          {getIconForId(i.icon)}
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{i.title}</h3>
                      <p className="mt-5 text-base text-gray-500">{i.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const threeColActive = !sideBySideActive && !gridIconsActive;
  const threeColMaybe = threeColActive ? <ThreeCol {...props} /> : null;
  return (
    <>
      {sideBySideMaybe}
      {threeColMaybe}
      {gridIconsMaybe}
    </>
  );
};

SectionLandingFeatures.defaultProps = { className: null };

SectionLandingFeatures.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingFeatures;
