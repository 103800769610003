import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const read = id => {
  return client(`subscriptions/${id}`);
};

const list = (params = { limit: 25 }) => {
  return client(`subscriptions?${stringify(params)}`);
};

const portal = id => {
  const returnUrl = `${window.location.origin}/account/pro`;
  return client(`subscriptions/${id}/stripe/customer-portal?returnUrl=${returnUrl}`);
};

export { read, list, portal };
