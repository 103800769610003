import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/accounts' : 'accounts';
};

const create = body => {
  return client('accounts', { body: body });
};

const read = (slug = 'me', params = {}) => {
  const baseUrl = computeBaseUrl(params);
  let url = `${baseUrl}/${slug}`;
  const stringifiedParams = stringify(params);
  if (stringifiedParams) {
    url = `${url}?${stringifiedParams}`;
  }
  return client(url);
};

const list = (params = { limit: 20 }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}?${stringify(params)}`);
};

const update = (body, slug = 'me') => {
  return client(`accounts/${slug}`, { method: 'PUT', body });
};

export { create, read, update, list };
