.card {
  max-width: 855px;
  /* overflow: hidden; */
}

.overlay {
  position: absolute;
  transition: opacity 0.3s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(var(--mpc-color-primary-300));
  opacity: 0;
}

.overlayContent {
  transition: opacity 0.3s;
  opacity: 0;
}

.card:hover .overlay {
  opacity: 0.9;
}
.card:hover .overlayContent,
.overlyContentPersistent {
  opacity: 1;
}

.overlyContentPersistent {
  background-image: linear-gradient(-170deg, transparent 50%, rgba(0, 0, 0, 0.6) 100%);
}

@media (max-width: 600px) {
  .mobileCarouselWidth {
    width: calc(100% - var(--find-play-container-padding)) !important;
  }
}
