import * as auth from '@/clients/auth.js';
import { create as createAccount } from '@/clients/accounts.js';

import * as log from '@/util/log';
import { identify, reset as resetAnalytics, track } from '@/analytics';
import { storableError } from '@/util/errors';
import { getIpGeolocation, getIpGeolocationAdressMaybe } from '@/util/helpers';
import { toast } from '@/util/toast';

import { clearCurrentUser, fetchCurrentUser, sendVerificationEmail } from './user.duck';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const OTP_REQUEST = 'app/Auth/OTP_REQUEST';
export const OTP_ERROR = 'app/Auth/OTP_ERROR';
export const OTP_SUCCESS = 'app/Auth/OTP_SUCCESS';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  requestId: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case OTP_REQUEST:
      return { ...state };
    case OTP_SUCCESS:
      return { ...state };
    case OTP_ERROR:
      toast.error(payload.errorMessage);
      return { ...state };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const otpRequest = () => ({ type: OTP_REQUEST });
export const otpSuccess = () => ({ type: OTP_SUCCESS });
export const otpError = error => ({
  type: OTP_ERROR,
  payload: { errorMessage: error.error.userMessage },
  error: true,
});

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => dispatch => {
  dispatch(authInfoRequest());

  return auth.isAuthenticated().then(isAuthenticated => {
    const info = {
      isAnonymous: !isAuthenticated,
      scopes: [],
    };
    return dispatch(authInfoSuccess(info));
  });
};

export const login = (username, password, throwError) => (dispatch, getState) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  return auth
    .login({ input: username, password })
    .then(response => {
      dispatch(loginSuccess());
      identify({ ...response.data.account });
      track.account.signedIn({ username });
      return dispatch(fetchCurrentUser(response));
    })
    .catch(e => {
      const err = storableError(e);
      const error = {
        ...err,
        loginEmail: username,
      };
      dispatch(loginError(error));
      // log.error(e, 'login-failed', { input: username });
      if (throwError) throw error;
    });
};

export const logout = () => (dispatch, getState) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return auth
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
      track.account.signedOut();
      resetAnalytics();
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, name, firstName, lastName, type } = params;

  const hasFirstAndLastName = firstName && lastName;
  const nameParams = hasFirstAndLastName ? { firstName, lastName } : { name };

  const address = getIpGeolocationAdressMaybe();

  const createUserParams = { email, password, type: 'individual', ...nameParams };
  if (address) {
    createUserParams.address = address;
  }
  if (type) {
    createUserParams.type = type;
  }

  const ipGeolocation = getIpGeolocation();
  if (ipGeolocation) {
    const { currency } = ipGeolocation || {};
    const { currency_code } = currency || {};
    let locale =
      navigator.userLanguage ||
      (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      'en';
    if (locale === 'en-US') locale = 'en';
    createUserParams.metadata = {
      localization: {
        currency: currency_code,
        locale,
      },
    };
  }

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return createAccount(createUserParams)
    .then(response => {
      const data = response.data;
      track.account.signedUp(data);
      track.account.created(data);
      return dispatch(signupSuccess());
    })
    .then(() => dispatch(sendVerificationEmail('', email)))
    .catch(e => {
      const err = storableError(e);
      dispatch(signupError(err));
      const existingAccountMessage = 'Account already exists with given credentials. Please try to login';
      if (err.userMessage !== existingAccountMessage) {
        log.error(e, 'signup-failed', {
          email: params.email,
          name: params.name,
        });
      }
      throw err;
    });
};

export const submitOtp = (input, otp, requestId) => dispatch => {
  dispatch(otpRequest());

  const body = {
    input,
    otp,
    requestId,
  };
  return auth
    .login(body)
    .then(response => {
      dispatch(otpSuccess());
      dispatch(loginSuccess());
      return dispatch(fetchCurrentUser(response));
    })
    .catch(err => {
      const error = storableError(err);
      dispatch(otpError(err));
      throw error;
    });
};
