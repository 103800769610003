import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const messenger = {
  authenticate: (refresh = false) => {
    if (refresh) return client('communications/messenger/reauthenticate');
    return client('communications/messenger/authenticate');
  },
  channel: body => {
    return client('communications/messenger/channel', { body });
  },
  users: queryParams => {
    return client(`communications/messenger/users?${stringify(queryParams)}`);
  },
};

const email = {
  send: body => {
    return client('communications/email', { body });
  },
};

export { messenger, email };
