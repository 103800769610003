// Stuff required to boot the @/app.tsx component
// Used in @/index.tsx
import { LoggingAnalyticsHandler, SegmentAnalyticsHandler } from '@/analytics/handlers';
import '@/util/array';
import { updateColorModeState } from '@/util/helpers';
import { setup as setupLogger } from '@/util/log';
import '@/util/string';

import config from '@/config';
import configureStore from '@/store';

const setupAnalyticsHandlers = () => {
  const handlers = [];
  // Log analytics page views and events in dev mode
  if (config.dev) handlers.push(new LoggingAnalyticsHandler());
  // Add Segment handler - It will not fire if segment credentials are not loaded
  handlers.push(new SegmentAnalyticsHandler());
  return handlers;
};

const setupColorModeHandlers = () => {
  const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
  if (!supportMatchMedia) return;
  // Watch for system color theme changes
  window.matchMedia('(prefers-color-scheme: dark)').addListener(() => updateColorModeState());
};

const setupStoreAndAnalytics = () => {
  return configureStore({}, null, setupAnalyticsHandlers());
};

const setupApp = () => {
  // 🪤 Setup logger to catch errors via Sentry
  setupLogger();

  // 💾 Boot redux store & 📡 setup analytics via segment
  const store = setupStoreAndAnalytics();

  // ℹ️ Expose stuff for the browser REPL
  window.app = {
    config,
    store,
  };

  // 🎨 Eagarly setup color handlers before app boots
  setupColorModeHandlers();

  // 💿 Get the currnt redux state
  const state = store.getState();

  return {
    state,
    store,
  };
};

// TODO: find less hacky fix by removing old polymer dependencies
// https://github.com/videojs/video.js/issues/5624
document.body.addEventListener('click', function (evt) {
  if (!evt || !evt.target) return;
  const target = evt.target as HTMLElement;
  if (target.closest('.vjs-button') || target.closest('.vjs-poster')) {
    evt.stopPropagation();
  }
});

export default setupApp;
