import { Link } from 'react-router-dom';

const NotFoundMessage = ({ title = 'Page Not Found', message = 'Please check the URL in the address bar and try again.' }) => {
  return (
    <div>
      <div className="px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-primary-600 text-4xl font-extrabold sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
                <p className="mt-1 text-base text-gray-500">{message}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/"
                  className="bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Go back home
                </Link>
                <Link
                  to="/help"
                  className="bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Help Center
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default NotFoundMessage;
