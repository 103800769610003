{
  "AboutPage.schemaDescription": "About {siteTitle}",
  "AboutPage.schemaTitle": "About | {siteTitle}",
  "AuthenticationPage.fixEmail": "Whoops, typo in your email? {fixEmailLink}.",
  "AuthenticationPage.resendEmail": "Didn't get the email? {resendEmailLink}.",
  "AuthenticationPage.resendEmailLinkText": "Resend it",
  "AuthenticationPage.resendFailed": "Resending verification email failed. Please try again.",
  "AuthenticationPage.resendFailedTooManyRequests": "The maximum of 5 verification emails has been reached. Please try again tomorrow.",
  "AuthenticationPage.schemaTitleLogin": "Log in | {siteTitle}",
  "AuthenticationPage.schemaTitleSignup": "Sign up | {siteTitle}",
  "AuthenticationPage.sendingEmail": "Sending verification email…",
  "AuthenticationPage.verifyEmailText": "Thanks for signing up! There's one quick step left. To be able to contact you, we need you to verify your email address. Please click the link we sent to {email}.",
  "AuthenticationPage.verifyEmailTitle": "Check your inbox to verify your email",
  "BecomeProviderPage.schemaDescription": "Become a Provider | {siteTitle}",
  "BecomeProviderPage.schemaTitle": "Become a Provider | {siteTitle}",
  "BookingDateRangeFilter.labelPlain": "Dates",
  "BookingDateRangeFilter.labelPopup": "Dates",
  "BookingDateRangeFilter.labelSelectedPlain": "{dates}",
  "BookingDateRangeFilter.labelSelectedPopup": "{dates}",
  "BookingDateRangeLengthFilter.labelPlain": "Dates",
  "BookingDateRangeLengthFilter.labelPopup": "Dates",
  "BookingDateRangeLengthFilter.labelSelectedPlain": "{dates}",
  "BookingDateRangeLengthFilter.labelSelectedPopup": "{dates}",
  "BookingDateRangeLengthFilter.minDurationLabel": "Minimum length",
  "BookingsPage.schemaDescription": "View your sales",
  "BookingsPage.schemaTitle": "Sales | {siteTitle}",
  "CalendarPage.schemaTitle": "Calendar | {siteTitle}",
  "Cms.schemaDescription": "Marketplace Admin",
  "Cms.schemaTitle": "Marketplace Admin",
  "CmsPlatformPage.schemaTitle": "Platform Console | {siteTitle}",
  "ComponentsPage.schemaDescription": "Components | {siteTitle}",
  "ComponentsPage.schemaTitle": "Components | {siteTitle}",
  "ContactDetailsForm.emailInvalid": "A valid email address is required",
  "ContactDetailsForm.emailLabel": "Email address",
  "ContactDetailsForm.emailRequired": "An email address is required",
  "ContactDetailsForm.emailSent": "Verification email sent!",
  "ContactDetailsForm.emailTakenError": "This email address is already in use.",
  "ContactDetailsForm.emailUnverified": "You haven't verified your email address yet. {resendEmailMessage}",
  "ContactDetailsForm.emailVerified": "Your email address is verified.",
  "ContactDetailsForm.pendingEmailCheckInbox": "Check your inbox to verify {pendingEmail} now.",
  "ContactDetailsForm.pendingEmailUnverified": "You have not verified your new address yet. {pendingEmailCheckInbox} {resendEmailMessage}",
  "ContactDetailsForm.phoneLabel": "Phone number",
  "ContactDetailsForm.phonePlaceholder": "Enter your phone number",
  "ContactDetailsForm.resendEmailVerificationText": "Resend verification email.",
  "ContactDetailsForm.saveChanges": "Save changes",
  "ContactDetailsForm.tooManyVerificationRequests": "Too many email verification requests sent.",
  "ContactDetailsPage.accountDetailsDescription": "Manage your account settings.",
  "ContactDetailsPage.accountDetailsTitle": "Account Settings",
  "ContactPage.schemaTitle": "{name} - Contact | {siteTitle}",
  "ContactsPage.schemaTitle": "Contacts | {siteTitle}",
  "CookieConsent.continue": "Continue",
  "CookieConsent.cookieLink": "cookies",
  "CookieConsent.message": "We use {cookieLink} to enhance your browsing experience.",
  "DashboardPage.schemaDescription": "Dashboard {siteTitle}",
  "DashboardPage.schemaTitle": "Dashboard | {siteTitle}",
  "DateInput.clearDate": "Clear Date",
  "DateInput.closeDatePicker": "Close",
  "DateInput.defaultPlaceholder": "Date input",
  "DateInput.screenReaderInputMessage": "Date input",
  "EditListingAdvancedForm.FormDescription": "Manage the forms that are displayed before or after checkout",
  "EditListingAdvancedForm.FormTitle": "Forms",
  "EditListingAdvancedForm.policyDescription": "Insert specific policies or re-use from others you have created.",
  "EditListingAdvancedForm.policyTitle": "Policies",
  "EditListingDescriptionForm.description": "Describe your listing",
  "EditListingDescriptionForm.descriptionPlaceholder": "Enter a description",
  "EditListingDescriptionForm.descriptionRequired": "A description is required.",
  "EditListingDescriptionForm.maxLength": "Must be {maxLength} characters or less",
  "EditListingDescriptionForm.showListingFailed": "Fetching listing profile data failed",
  "EditListingDescriptionForm.title": "Listing Title",
  "EditListingDescriptionForm.titlePlaceholder": "What's your listings name?",
  "EditListingDescriptionForm.titleRequired": "You need to add a name.",
  "EditListingDescriptionForm.updateFailed": "There was an error updating your listing.",
  "EditListingDescriptionPanel.createListingTitle": "Create listing profile",
  "EditListingDescriptionPanel.listingTitle": "your listing profile",
  "EditListingDescriptionPanel.title": "Edit {listingTitle}",
  "EditListingFeaturesForm.showListingFailed": "Fetching listing profile data failed",
  "EditListingFeaturesForm.updateFailed": "There was an error updating your listing.",
  "EditListingFeaturesPanel.createListingTitle": "What features does your listing include?",
  "EditListingFeaturesPanel.listingTitle": "your sessions",
  "EditListingFeaturesPanel.title": "Edit the features of {listingTitle}",
  "EditListingLocationForm.address": "Address",
  "EditListingLocationForm.addressNotRecognized": "We didn't recognize this location. Please try another location.",
  "EditListingLocationForm.addressPlaceholder": "123 Example Street",
  "EditListingLocationForm.addressRequired": "You need to provide a location",
  "EditListingLocationForm.building": "Apt, suite, building",
  "EditListingLocationForm.buildingPlaceholder": "A 42",
  "EditListingLocationForm.optionalText": "• optional",
  "EditListingLocationForm.showListingFailed": "Fetching listing profile data failed",
  "EditListingLocationForm.updateFailed": "There was an error updating your listing.",
  "EditListingLocationPanel.createListingTitle": "Where do you teach?",
  "EditListingLocationPanel.listingTitle": "your sessions",
  "EditListingLocationPanel.title": "Edit the location of {listingTitle}",
  "EditListingPage.loadingListingData": "Loading listing data…",
  "EditListingPage.titleCreateListing": "Create a listing",
  "EditListingPage.titleEditListing": "Edit listing",
  "EditListingPhotosForm.addImagesTip": "Choose the top 2-3 photos of your listing so customers know what it is about.",
  "EditListingPhotosForm.bankAccountNumberRequired": "You need to add a bank account number.",
  "EditListingPhotosForm.chooseImage": "+ Choose an image…",
  "EditListingPhotosForm.imageRequired": "You need to add at least one image.",
  "EditListingPhotosForm.imageTypes": ".JPG, .GIF or .PNG. Max. 20 MB",
  "EditListingPhotosForm.imageUploadFailed.uploadFailed": "Failed to upload image. Please check that the image wasn't too big and try again.",
  "EditListingPhotosForm.imageUploadFailed.uploadOverLimit": "The maximum file size is 20 MB. Please try again.",
  "EditListingPhotosForm.publishListingFailed": "Publishing the listing profile failed",
  "EditListingPhotosForm.savedImageAltText": "Image saved",
  "EditListingPhotosForm.showListingFailed": "Fetching listing profile data failed",
  "EditListingPhotosForm.updateFailed": "There was an error updating your listing.",
  "EditListingPhotosPanel.createListingTitle": "Add a few photos",
  "EditListingPhotosPanel.listingTitle": "your listing profile",
  "EditListingPhotosPanel.title": "Edit the photos of {listingTitle}",
  "EditListingPricingForm.priceInputPlaceholder": "Choose your price…",
  "EditListingPricingForm.pricePerDay": "Price per day in euros",
  "EditListingPricingForm.pricePerNight": "Price per night in euros",
  "EditListingPricingForm.pricePerUnit": "Price per hour in dollars",
  "EditListingPricingForm.priceRequired": "You need to add a valid price.",
  "EditListingPricingForm.priceTooLow": "Price should be at least {minPrice}.",
  "EditListingPricingForm.showListingFailed": "Fetching listing data failed",
  "EditListingPricingForm.updateFailed": "Failed to update listing. Please try again.",
  "EditListingPricingPanel.createListingTitle": "How much do your sessions cost?",
  "EditListingPricingPanel.listingPriceCurrencyInvalid": "Currency in listing profile is different from the marketplace currency. You cannot edit the price.",
  "EditListingPricingPanel.listingTitle": "your sessions",
  "EditListingPricingPanel.title": "Edit the pricing of {listingTitle}",
  "EditListingWizard.payoutModalInfo": "Almost done! In order to send you money, we need to know a bit more about you. Next, we will guide you through verifying your account. The details can also be edited from Account Setting page on Payout details tab. ",
  "EditListingWizard.payoutModalTitleOneMoreThing": "One more thing:",
  "EditListingWizard.payoutModalTitlePayoutPreferences": "Payout preferences",
  "EditListingWizard.redirectingToStripe": "You returned early from Stripe. Redirecting you back to Stripe…",
  "EditListingWizard.saveEdit": "Save",
  "EditListingWizard.saveNew": "Next",
  "EditListingWizard.tabLabelAdvanced": "Extras",
  "EditListingWizard.tabLabelAvailability": "Pricing & Availability",
  "EditListingWizard.tabLabelAvailability.fundraiser": "Funding Options",
  "EditListingWizard.tabLabelAvailability.video.conference": "Sessions & Pricing",
  "EditListingWizard.tabLabelAvailability.video.live": "Sessions & Pricing",
  "EditListingWizard.tabLabelAvailability.video.recorded": "Pricing",
  "EditListingWizard.tabLabelDescription": "General",
  "EditListingWizard.tabLabelFeatures": "Features",
  "EditListingWizard.tabLabelLocation": "Location",
  "EditListingWizard.tabLabelLocation.video": "Audience",
  "EditListingWizard.tabLabelLocations": "Locations",
  "EditListingWizard.tabLabelPhotos": "Media",
  "EditListingWizard.tabLabelPolicy": "Policies",
  "EditListingWizard.tabLabelPricing": "Bookings & Payments",
  "EditListingWizard.tabLabelPricing.fundraiser": "Donations & Payments",
  "EditListingWizard.tabLabelPricing.video.recorded": "Purchases & Payments",
  "EditProductPage.titleCreateProduct": "Create a {product} | {siteTitle}",
  "EditProductPage.titleEditProduct": "Edit {product} | {siteTitle}",
  "FieldBoolean.no": "No",
  "FieldBoolean.yes": "Yes",
  "FieldDateInput.clearDate": "Clear Date",
  "FieldDateInput.closeDatePicker": "Close",
  "FieldDateInput.invalidDate": "The start date is not valid",
  "FieldDateInput.placeholderText": "Add start date",
  "FieldDateInput.screenReaderInputMessage": "Date input",
  "FieldDateRangeInput.clearDate": "Clear Date",
  "FieldDateRangeInput.closeDatePicker": "Close",
  "FieldDateRangeInput.endDatePlaceholderText": "Add end date",
  "FieldDateRangeInput.invalidEndDate": "The end date is not valid",
  "FieldDateRangeInput.invalidStartDate": "The start date is not valid",
  "FieldDateRangeInput.screenReaderInputMessage": "Date input",
  "FieldDateRangeInput.startDatePlaceholderText": "Add start date",
  "FieldDateTimeInput.endTime": "End time",
  "FieldDateTimeInput.startTime": "Start time",
  "FilterForm.cancel": "Cancel",
  "FilterForm.clear": "Clear",
  "FilterForm.submit": "Apply",
  "FilterPlain.clear": "Clear",
  "FormPage.schemaDescription": "About {siteTitle}",
  "FormPage.schemaTitle": "{formTitle} | {siteTitle}",
  "FormResponsePage.schemaDescription": "About {siteTitle}",
  "FormResponsePage.schemaTitle": "{formTitle} | {siteTitle}",
  "FormsPage.schemaDescription": "About {siteTitle}",
  "FormsPage.schemaTitle": "Forms | {siteTitle}",
  "HelpPage.schemaTitle": "Help Center | {siteTitle}",
  "HelpPage.supportArticlePageTitle": "{title} | {siteTitle}",
  "HelpPage.supportTicketsTabTitle": "Raise a Support Ticket | {siteTitle}",
  "HelpPage.supportTopicsTabTitle": "Help Center Topics | {siteTitle}",
  "ImageCarouselFullscreen.imageAltText": "Listing image {index}/{count}",
  "ImageFromFile.couldNotReadFile": "Could not read file",
  "KeywordFilter.filterText": "Filter results by",
  "KeywordFilter.labelSelected": "\"{labelText}\"",
  "KeywordFilter.placeholder": "Enter a keyword…",
  "KeywordFilterPlainForm.labelSelected": "Keyword",
  "KeywordFilterPlainForm.placeholder": "Enter a keyword…",
  "LandingPage.schemaDescription": "Find and play sports everywhere",
  "LandingPage.schemaTitle": "Find and play sports everywhere | {siteTitle}",
  "ListingPage.beneficiaryTitle": "Beneficiary",
  "ListingPage.bookingTitle": "Book a session with {title}",
  "ListingPage.closedListing": "This listing profile has been closed and sessions can't be booked.",
  "ListingPage.contactUser": "Contact",
  "ListingPage.descriptionTitle": "About",
  "ListingPage.editListing": "Edit listing",
  "ListingPage.errorLoadingListingMessage": "Could not load listing profile. Please try again.",
  "ListingPage.errorLoadingListingTitle": "Error in loading listing profile",
  "ListingPage.featuresEquipmentSuppliedTitle": "Equipment Supplied",
  "ListingPage.featuresReasonsToPlayTitle": "Reasons To Play",
  "ListingPage.featuresTitle": "Features",
  "ListingPage.finishListing": "Finish listing profile",
  "ListingPage.hostedBy": "Hosted by {name}",
  "ListingPage.loadingListingTitle": "Loading listing profile...",
  "ListingPage.locationTitle": "Location",
  "ListingPage.ownClosedListing": "Your listing profile has been closed and sessions can't be booked.",
  "ListingPage.ownListing": "This is your own listing profile",
  "ListingPage.ownListingDraft": "This listing profile is a draft.",
  "ListingPage.ownListingPendingApproval": "This listing profile is pending approval.",
  "ListingPage.perDay": "per day",
  "ListingPage.perNight": "per night",
  "ListingPage.perUnit": "per hour",
  "ListingPage.reviewsError": "Loading reviews failed.",
  "ListingPage.reviewsHeading": "Reviews ({count})",
  "ListingPage.rulesTitle": "Studio policies",
  "ListingPage.schemaTitle": "{title} | {siteTitle}",
  "ListingPage.viewImagesButton": "View all {count} photos",
  "ListingPage.yourHostHeading": "Your host",
  "LocationAutocompleteInput.currentLocation": "Current location",
  "LoginForm.emailInvalid": "A valid email address is required",
  "LoginForm.emailLabel": "Email",
  "LoginForm.emailRequired": "An email is required",
  "LoginForm.forgotPassword": "Reset password.",
  "LoginForm.forgotPasswordInfo": "Forgot your password? {passwordRecoveryLink}",
  "LoginForm.logIn": "Log in",
  "LoginForm.passwordLabel": "Password",
  "LoginForm.passwordPlaceholder": "Enter your password…",
  "LoginForm.passwordRequired": "A password is required",
  "ManageListingsPage.noResults": "No listings",
  "ManageListingsPage.title": "My {listings} | {siteTitle}",
  "ManageProductsPage.title": "My {products} | {siteTitle}",
  "MarketplacePolicyPage.schemaDynamicTitle": "{policyTitle} | {siteTitle}",
  "MarketplacePolicyPage.schemaTitle": "Policy | {siteTitle}",
  "MessagesPage.schemaDescription": "Messages {siteTitle}",
  "MessagesPage.schemaTitle": "Messages | {siteTitle}",
  "Modal.close": "CLOSE",
  "Modal.closeModal": "Close modal",
  "MyAccountPage.schemaDynamicTitle": "{pageTitle} - My Account | {siteTitle}",
  "MyAccountPage.schemaTitle": "My Account | {siteTitle}",
  "NotFoundPage.description": "We can't find the page or listing profile you're looking for. Make sure you've typed in the URL correctly or try searching below.",
  "NotFoundPage.heading": "Sorry, we couldn't find that page.",
  "NotFoundPage.title": "Page not found",
  "organization": "organization",
  "OtpForm.verificationFailed": "Could not verify the email address.",
  "OtpForm.verify": "Verify my email address",
  "OtpForm.verifying": "Verifying…",
  "Page.schemaDescription": "Find and play sports everywhere or earn some income listing your expierneces, facilities, fundraisers and videos",
  "Page.schemaTitle": "Find and play sports everywhere | {siteTitle}",
  "PaginationLinks.next": "Next page",
  "PaginationLinks.previous": "Previous page",
  "PaginationLinks.toPage": "Go to page {page}",
  "PasswordChangeForm.confirmChangesInfo": "To change your password, please enter your current password.",
  "PasswordChangeForm.confirmChangesTitle": "Confirm your changes",
  "PasswordChangeForm.genericFailure": "Whoops, something went wrong. Please refresh the page and try again.",
  "PasswordChangeForm.newPasswordLabel": "New password",
  "PasswordChangeForm.newPasswordPlaceholder": "Enter your new password",
  "PasswordChangeForm.newPasswordRequired": "A new password is required",
  "PasswordChangeForm.passwordFailed": "Please double-check your current password",
  "PasswordChangeForm.passwordLabel": "Current password",
  "PasswordChangeForm.passwordPlaceholder": "Enter your current password…",
  "PasswordChangeForm.passwordRequired": "Current password is required",
  "PasswordChangeForm.passwordTooLong": "The password should be at most {maxLength} characters",
  "PasswordChangeForm.passwordTooShort": "The password should be at least {minLength} characters",
  "PasswordChangeForm.saveChanges": "Save changes",
  "PasswordChangePage.heading": "Password settings",
  "PasswordChangePage.title": "Password",
  "PasswordRecoveryForm.emailInvalid": "A valid email address is required",
  "PasswordRecoveryForm.emailLabel": "Email",
  "PasswordRecoveryForm.emailNotFound": "Hmm. We didn't find an account with that email address. Please double-check the address and try again.",
  "PasswordRecoveryForm.emailPlaceholder": "john.doe@example.com",
  "PasswordRecoveryForm.emailRequired": "An email is required",
  "PasswordRecoveryForm.loginLinkInfo": "Suddenly remembered your password? {loginLink}",
  "PasswordRecoveryForm.loginLinkText": "Log in.",
  "PasswordRecoveryForm.sendInstructions": "Send instructions",
  "PasswordRecoveryPage.actionFailedMessage": "Something went wrong. Please refresh the page and try again.",
  "PasswordRecoveryPage.actionFailedTitle": "Whoops!",
  "PasswordRecoveryPage.emailSubmittedMessage": "The instructions for resetting your password have been sent to {submittedEmailText}.",
  "PasswordRecoveryPage.emailSubmittedTitle": "Check your inbox",
  "PasswordRecoveryPage.fixEmailInfo": "Whoops, typo in your email? {fixEmailLink}",
  "PasswordRecoveryPage.fixEmailLinkText": "Fix it.",
  "PasswordRecoveryPage.forgotPasswordMessage": "No worries! Please enter the email address you used when signing up and we'll send you instructions on how to set a new password.",
  "PasswordRecoveryPage.forgotPasswordTitle": "Forgot your password?",
  "PasswordRecoveryPage.resendEmailInfo": "Didn't get the email? {resendEmailLink}",
  "PasswordRecoveryPage.resendEmailLinkText": "Send another email.",
  "PasswordRecoveryPage.resendingEmailInfo": "Resending instructions…",
  "PasswordRecoveryPage.title": "Request a new password",
  "PoliciesPage.actionButtonText": "New Policy",
  "PoliciesPage.breadcrumbLabelExistingPolicy": "Existing Policy",
  "PoliciesPage.breadcrumbLabelNewPolicy": "New Policy",
  "PoliciesPage.description": "Manage the policies in use across your listings",
  "PoliciesPage.schemaDescription": "Manage the policies in use across your listings",
  "PoliciesPage.schemaTitle": "Policies | {siteTitle}",
  "PoliciesPage.tableCreatedLabel": "Created",
  "PoliciesPage.tableStatusLabel": "Status",
  "PoliciesPage.tableTitleLabel": "Title",
  "PoliciesPage.tableTypeLabel": "Type",
  "PoliciesPage.title": "Policies",
  "PoliciesPageForm.fieldTextEditor": "Policy Content",
  "PoliciesPageForm.fieldTitle": "Policy Title",
  "PoliciesPageForm.fieldType": "Policy Type",
  "PoliciesPageForm.fieldTypeOptionCancellationRefund": "Cancellation & Refund",
  "PoliciesPageForm.fieldTypeOptionPlaceholder": "Select a policy type",
  "PoliciesPageForm.fieldTypeOptionPrivacy": "Privacy",
  "PoliciesPageForm.fieldTypeOptionTerms": "Terms and Conditions",
  "PoliciesPageForm.saveChanges": "Save changes",
  "PoliciesPageForm.saveNew": "Create Policy",
  "PoliciesPageForm.titleNew": "Add Policy",
  "PriceFilter.clear": "Clear",
  "PriceFilter.label": "Price",
  "PriceFilter.labelSelectedButton": "{minPrice} - {maxPrice}",
  "PriceFilter.labelSelectedPlain": "Price per hour: {minPrice} - {maxPrice}",
  "PriceFilterForm.cancel": "Cancel",
  "PriceFilterForm.clear": "Clear",
  "PriceFilterForm.label": "Base Price:",
  "PriceFilterForm.submit": "Apply",
  "ProfilePage.schemaTitle": "{name} | {siteTitle}",
  "ProfileSettingsForm.addYourProfilePicture": "+ Add your profile picture…",
  "ProfileSettingsForm.addYourProfilePictureMobile": "+ Add",
  "ProfileSettingsForm.bioHeading": "Your profile bio",
  "ProfileSettingsForm.bioInfo": "PlayKit is built on relationships. Help other people get to know you.",
  "ProfileSettingsForm.bioLabel": "Bio",
  "ProfileSettingsForm.bioPlaceholder": "Tell us a little bit about yourself…",
  "ProfileSettingsForm.changeAvatar": "Change",
  "ProfileSettingsForm.fileInfo": ".JPG, .GIF or .PNG. Max. 20 MB",
  "ProfileSettingsForm.firstNameLabel": "First name",
  "ProfileSettingsForm.firstNamePlaceholder": "John",
  "ProfileSettingsForm.firstNameRequired": "This field is required",
  "ProfileSettingsForm.imageUploadFailed": "Whoops, something went wrong. Please check that the image wasn't too big and try again.",
  "ProfileSettingsForm.imageUploadFailedFileTooLarge": "Image was too big. Maximum size is 20 MB.",
  "ProfileSettingsForm.lastNameLabel": "Last name",
  "ProfileSettingsForm.lastNamePlaceholder": "Doe",
  "ProfileSettingsForm.lastNameRequired": "This field is required",
  "ProfileSettingsForm.saveChanges": "Save changes",
  "ProfileSettingsForm.tip": "Tip: Choose an image where your face is recognizable.",
  "ProfileSettingsForm.updateProfileFailed": "Whoops, something went wrong. Please try again.",
  "ProfileSettingsForm.yourName": "Your name",
  "ProfileSettingsForm.yourProfilePicture": "Your user profile picture",
  "ProfileSettingsPage.heading": "User profile",
  "ProfileSettingsPage.title": "User profile",
  "ProfileSettingsPage.viewProfileLink": "View your user profile",
  "ResourcePage.schemaDynamicTitle": "{resourceTitle} | {siteTitle}",
  "ResourcePage.schemaTitle": "Resources | {siteTitle}",
  "NewsPage.schemaDynamicTitle": "{resourceTitle} | {siteTitle}",
  "NewsPage.schemaTitle": "News | {siteTitle}",
  "SavedPage.schemaDescription": "View all of your saved listings",
  "SavedPage.schemaTitle": "Saved Listings {siteTitle}",
  "SearchFilters.filtersButtonLabel": "Filters",
  "SearchFilters.foundResults": "{count, number} {count, plural, one {result} other {results}}",
  "SearchFilters.keywordLabel": "Keyword",
  "SearchFilters.loadingResults": "Loading search results…",
  "SearchFilters.loadingResultsMobile": "Loading…",
  "SearchFilters.moreFiltersButton": "{count, plural, =0 {More filters} other {More filters • #}}",
  "SearchFilters.noResults": "Could not find any listings.",
  "SearchFilters.noResultsMobile": "No results.",
  "SearchFilters.openMapView": "Map",
  "SearchFiltersMobile.cancel": "CANCEL",
  "SearchFiltersMobile.certificateLabel": "Teacher certificate",
  "SearchFiltersMobile.heading": "Filter",
  "SearchFiltersMobile.keywordLabel": "Keyword",
  "SearchFiltersMobile.resetAll": "Reset all",
  "SearchFiltersMobile.showListings": "Show {count, number} {count, plural, one {result} other {results}}",
  "SearchFiltersMobile.yogaStylesLabel": "Listing styles",
  "SearchFiltersPanel.apply": "Apply",
  "SearchFiltersPanel.cancel": "Cancel",
  "SearchFiltersPanel.categoryLabel": "Category",
  "SearchFiltersPanel.certificateLabel": "Teacher certificate",
  "SearchFiltersPanel.resetAll": "Reset all",
  "SearchFiltersPanel.yogaStylesLabel": "Listing styles",
  "SearchMapInfoCard.noImage": "No image",
  "SearchPage.schemaDescription": "Showing search results",
  "SearchPage.schemaMapSearch": "map search",
  "SearchPage.schemaTitle": "Search results for {searchAddress} | {siteTitle}",
  "SearchPage.searchError": "Search failed. Please try again.",
  "SearchResultsPanel.nextPage": "Next page",
  "SearchResultsPanel.previousPage": "Previous page",
  "SectionHero.browseButton": "Browse listings",
  "SectionHero.subTitle": "The largest online community of Play providers.",
  "SectionHero.title": "Live to play near you",
  "SelectMultipleFilter.labelSelected": "{labelText} • {count}",
  "SelectMultipleFilterPlainForm.labelSelected": "{labelText} • {count}",
  "SelectSingleFilter.plainClear": "Clear",
  "SelectSingleFilter.popupClear": "Clear",
  "SignupForm.emailInvalid": "A valid email address is required",
  "SignupForm.emailLabel": "Email",
  "SignupForm.emailPlaceholder": "john.doe@example.com",
  "SignupForm.emailRequired": "You need to add an email.",
  "SignupForm.firstNameLabel": "First name",
  "SignupForm.firstNamePlaceholder": "John",
  "SignupForm.firstNameRequired": "You need to add a first name.",
  "SignupForm.lastNameLabel": "Last name",
  "SignupForm.lastNamePlaceholder": "Doe",
  "SignupForm.lastNameRequired": "You need to add a last name.",
  "SignupForm.passwordLabel": "Password",
  "SignupForm.passwordPlaceholder": "Enter your password…",
  "SignupForm.passwordRequired": "You need to add a password.",
  "SignupForm.passwordTooLong": "The password should be at most {maxLength} characters.",
  "SignupForm.passwordTooShort": "The password should be at least {minLength} characters.",
  "SignupForm.signUp": "Sign Up",
  "SignupForm.termsAndConditionsAcceptText": "By signing up you accept the {termsLink}",
  "SignupForm.termsAndConditionsLinkText": "terms and conditions",
  "SortBy.heading": "Sort by",
  "StripeConnectAccountStatusBox.editAccountButton": "Edit Stripe account",
  "StripeConnectAccountStatusBox.getVerifiedButton": "Get verified",
  "StripeConnectAccountStatusBox.verificationFailedText": "In order for you to receive payments, you need to add your banking details and verify your account.",
  "StripeConnectAccountStatusBox.verificationFailedTitle": "Something went wrong - please try again",
  "StripeConnectAccountStatusBox.verificationNeededText": "In order for you to receive payments you need to add few more details to your Stripe account to verify your account.",
  "StripeConnectAccountStatusBox.verificationNeededTitle": "Stripe needs more information",
  "StripeConnectAccountStatusBox.verificationSuccessTitle": "Your Stripe account is up to date!",
  "StripePayoutPage.heading": "Payout details",
  "StripePayoutPage.loadingData": "Loading data…",
  "StripePayoutPage.redirectingToStripe": "You returned early from Stripe. Redirecting you back to Stripe…",
  "StripePayoutPage.stripeNotConnected": "Payment information not saved. Please fill in the form to accept payments from your listings.",
  "StripePayoutPage.submitButtonText": "Save details",
  "StripePayoutPage.title": "Payout details",
  "Topbar.genericError": "Oh no, something went wrong. Please check your network connection and try again.",
  "Topbar.logoIcon": "Go to homepage",
  "Topbar.menuIcon": "Open menu",
  "Topbar.searchIcon": "Open search",
  "TopbarSearchForm.placeholder": "Find play in your location...",
  "TransactionsPage.schemaDescription": "View your transactions",
  "TransactionsPage.schemaTitle": "Transactions | {siteTitle}",
  "WebsitePage.schemaDescription": "Website {siteTitle}",
  "WebsitePage.schemaTitle": "Website | {siteTitle}",

  "CmsSetupPage.marketplace.label": "Create your marketplace",
  "CmsSetupPage.marketplace.description": "Create your marketplace with a name, url and logo.",
  "CmsSetupPage.marketplace.button": "Discover Settings",

  "CmsSetupPage.homepage.label": "Customize your homepage",
  "CmsSetupPage.homepage.description": "The page that will be shown when someone visits your marketplace.",
  "CmsSetupPage.homepage.button": "Discover Settings",

  "CmsSetupPage.logo.label": "Add a Logo",
  "CmsSetupPage.logo.description": "Add a logo to your marketplace.",
  "CmsSetupPage.logo.button": "Logo Settings"
}
