/* Small Avatar */

.root {
  --Avatar_size: 40px;
  --Avatar_sizeMedium: 60px;
  --Avatar_sizeLarge: 125px;

  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  @apply __Avatar_base;

  /* Layout */
  width: var(--Avatar_size);
  height: var(--Avatar_size);
}

.initials {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Medium Avatar */

.mediumAvatar {
  @apply __Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeMedium);
  height: var(--Avatar_sizeMedium);
}

.mediumAvatar .initials {
  font-size: 26px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.largeAvatar {
  @apply __Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeLarge, 125px);
  height: var(--Avatar_sizeLarge, 125px);
}

.largeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
