import React from 'react';
import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

const PageHeaderMeta = ({
  meta,
  className = '',
  itemClassName = '',
  buttonActionText = '',
  buttonActionHref = '',
}: {
  className?: string;
  itemClassName?: string;
  buttonActionText?: string | null;
  buttonActionHref?: string;
  meta: {
    icon: React.ElementType;
    label: string;
    href?: string;
  }[];
}) => {
  if (!meta) return null;

  return (
    <div className={className}>
      <div className="c-gap sm:c-gap-x-6 mt-1 flex flex-col sm:items-center sm:flex-row sm:flex-wrap">
        {meta.map(item => (
          <div key={item.label} className={twMerge('relative mt-2 flex items-center text-sm text-gray-500', itemClassName)}>
            {item.href ? (
              <a rel="noreferrer" target="_blank" href={item.href} className="absolute inset-0">
                &nbsp;
              </a>
            ) : null}
            <item.icon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />
            {item.label}
          </div>
        ))}

        {buttonActionText ? (
          <div className="flex mt-2 items-center text-sm font-medium capitalize text-gray-500">
            <Link to={buttonActionHref} className="text-accent-600 hover:text-accent-500 whitespace-nowrap text-sm font-medium">
              {buttonActionText}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PageHeaderMeta;
