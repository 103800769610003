@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply __marketplaceButtonStylesSecondary;
  @apply __marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: rgb(var(--mpc-color-white-bg));
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply __marketplaceButtonStyles;
  @apply __marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  @apply __marketplaceH4FontStyles;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  @apply __marketplaceH4FontStyles;
  margin: 0;
  padding: 16px 0 30px 0;
  border: none;
}
