import get from 'lodash/get';

import BizHours from '@/components/BizHours/BizHours';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import { StaticMap } from '@/components/Map/GoogleMap';

import { getMarketplace, openLatLng } from '@/util/helpers';
import { LatLng } from '@/util/types/sdkTypes';
import { ArrowTopRightOnSquareIcon, ClockIcon, EnvelopeIcon, MapPinIcon, PhoneIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import UiTextRenderer from './UiTextRenderer';

const ProviderLocation = ({ data, shouldHideOpeningHours }) => {
  const { title, address, email, description, phone, website, hours, disableHours, images } = data || {};
  const formattedAddress = get(address, 'selectedPlace.formattedAddress');
  const lat = get(address, 'selectedPlace.origin.lat');
  const lng = get(address, 'selectedPlace.origin.lng');

  let geolocation;
  if (lat && lng) {
    geolocation = new LatLng(lat, lng);
  }
  return (
    <div>
      {images?.length ? (
        <ImageCarousel className="mb-4" roundedClassName="rounded-md" images={images} />
      ) : geolocation ? (
        <button
          onClick={e => {
            e.preventDefault();
            openLatLng(lat, lng);
          }}
          className="aspect-w-3 aspect-h-2 relative mb-4 block w-full overflow-hidden rounded-md"
        >
          <StaticMap center={geolocation} />
        </button>
      ) : null}

      {title ? <h2 className="text-base font-medium text-gray-900">{title}</h2> : null}

      {description ? (
        <div className="mt-2">
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      ) : null}

      <div className="mt-4 space-y-4">
        {formattedAddress ? (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-sm text-gray-500">
              <button
                className="hover:text-accent text-left focus:outline-none"
                onClick={e => {
                  e.preventDefault();
                  openLatLng(lat, lng);
                }}
              >
                {formattedAddress}
              </button>
            </div>
          </div>
        ) : null}

        {phone ? (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-sm text-gray-500">
              <a className="hover:text-accent" target="_blank" rel="noreferrer" href={`tel:${phone}`}>
                {phone}
              </a>
            </div>
          </div>
        ) : null}

        {email ? (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-sm text-gray-500">
              <a className="hover:text-accent" target="_blank" rel="noreferrer" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </div>
        ) : null}

        {website ? (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <ArrowTopRightOnSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-sm text-gray-500">
              <a className="hover:text-accent" target="_blank" rel="noreferrer" href={website}>
                {website}
              </a>
            </div>
          </div>
        ) : null}

        {hours && !disableHours && !shouldHideOpeningHours ? (
          <div className="flex">
            <div className="flex-shrink-0">
              <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 space-y-1 text-sm text-gray-500">
              <BizHours hours={hours} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const UiLocations = ({
  className = 'overflow-hidden',
  metadata,
  contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
}) => {
  const { hasLocations, gridClassName, locations, title = 'Locations', titleClassNames = {} } = metadata || {};

  if (!hasLocations) return null;
  const shouldHideOpeningHours = getMarketplace()?.pageConfig?.accounts?.locations?.disableOpeningHours;

  return (
    <section className={className}>
      <div className={contentClassName}>
        <div className="col-span-2">
          <UiTextRenderer
            classNames={titleClassNames}
            className="mb-4"
            defaultFontWeightClassName="font-semibold"
            defaultFontSizeClassName="text-lg"
            defaultFontFamilyClassName=""
            defaultFontColorClassName="text-gray-900"
          >
            {title}
          </UiTextRenderer>

          <div className={gridClassName}>
            {locations.map(l => (
              <ProviderLocation key={l.title} data={l} shouldHideOpeningHours={shouldHideOpeningHours} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UiLocations;
