import { Component } from 'react';

import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { FormattedMessage } from '@/util/reactIntl';

import * as css from './SelectSingleFilterPlain.module.css';

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { urlParam, onSelect } = this.props;
    onSelect(urlParam, option);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { rootClassName, className, label, options, initialValue } = this.props;

    const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={labelClass}>
          <div className="mb-4 flex flex-row items-center justify-between">
            <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
              <span className={labelClass}>{label}</span>
            </button>
            <button type="button" className={css.clearButton} onClick={e => this.selectOption(null, e)}>
              <FormattedMessage id={'FilterPlain.clear'} />
            </button>
          </div>
        </div>

        <div className="mt-4 space-y-4 pb-[15px]">
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            return (
              <div key={option.key} className="flex items-center" onClick={() => this.selectOption(option.key)}>
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  defaultChecked={selected}
                  className="text-primary-600 focus:ring-primary-500 h-4 w-4 border-gray-300"
                />
                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValue: null,
  twoColumns: false,
  useBullets: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  twoColumns: bool,
  useBullets: bool,
};

export default SelectSingleFilterPlain;
