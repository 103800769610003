@import '../../styles/customMediaQueries.css';

.root {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: rgb(var(--mpc-color-white-bg));
  /* border-top: 1px solid var(--matterColorNegative); */
  box-shadow: var(--boxShadowPopup);
  border-radius: 0 0 2px 2px;
  transition: var(--transitionStyleButton);

  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: slideInUp;
  @media (--viewportSmall) {
    animation-name: none;
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;

  @media (--viewportSmall) {
    display: block;
  }
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0;
}

/* Styles for arrow (if arrowPosition is defined) */
.arrowTop,
.arrowBelow {
  content: ' ';
  position: absolute;
  bottom: 100%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
}

.arrowTop {
  border-bottom-color: rgb(var(--mpc-color-white));
  border-width: 7px;
  margin-left: -7px;
}

.arrowBelow {
  border-bottom-color: var(--matterColorNegative);
  border-width: 9px;
  margin-left: -9px;
}
