import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import useSWR from 'swr';

import PlayBlockPage from '@/containers/PlayBlockPage/PlayBlockPage';

import IconSpinner from '@/components/IconSpinner/IconSpinner';
import Page from '@/components/Page/Page';

import { playBlocks } from '@/clients';
import { isScrollingDisabled } from '@/ducks/UI.duck';

import { injectIntl, intlShape } from '@/util/reactIntl';

export const NotFoundPageComponent = props => {
  const history = useHistory();
  const { scrollingDisabled } = props;

  const path = history?.location?.pathname?.split('/')?.[1];
  const { data, error } = useSWR(path ? `/api/playblocks/${path}` : null, () => playBlocks.view(path));
  const loading = !data && !error;
  const playblockData = data?.data && !error ? data?.data : null;

  if (loading) {
    return (
      <div className="flex h-full min-h-[60vh] w-full flex-col items-center justify-center">
        <IconSpinner type="tailwind" />
      </div>
    );
  }

  if (playblockData) {
    return <PlayBlockPage {...props} params={{ id: path }} />;
  }

  return (
    <Page className="min-h-0" title="Page Not Found" scrollingDisabled={scrollingDisabled}>
      <div className="px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-primary-600 text-4xl font-extrabold sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/"
                  className="bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Go back home
                </Link>
                <Link
                  to="/help"
                  className="bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Help Center
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Page>
  );
};

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, func, object, shape } = PropTypes;

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(withRouter, connect(mapStateToProps), injectIntl)(NotFoundPageComponent);

export default NotFoundPage;
