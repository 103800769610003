import { Link } from 'react-router-dom';

import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

const Icon = ({ icons, icon, ...props }) => {
  if (!icons || icons === 'hidden') return null;

  return <SvgRenderer id={icon} defaultIcon="Check" {...props} />;
};

const LinkOrSpan = ({ link, children, layout, type, id, ...rest }) => {
  const Wrapper = ({ children }) => {
    if (layout === 'column') {
      return <div>{children}</div>;
    }
    return children;
  };

  if (link) {
    return (
      <Wrapper>
        <Link to={`/s/providers?${type}=${id}`} {...rest}>
          {children}
        </Link>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <span {...rest}>{children}</span>
    </Wrapper>
  );
};

const CategoryTypeLinks = ({
  data,
  layout,
  style = 'plain',
  linkToSearch = false,
  icons = 'hidden',
  iconsSizeClassName = 'h-5 w-5',
}) => {
  const flexDirection = layout === 'column' ? 'flex-col' : 'flex-row';
  const alignItems = layout === 'column' ? '' : 'items-center';
  const textDecoration = style === 'underline' ? 'underline' : '';
  let padding = style === 'badge' ? 'px-2.5 py-0.5' : '';
  let hoverText = linkToSearch ? 'hover:text-accent' : '';
  const color = style === 'badge' ? 'text-gray-800' : '';
  let backgroundColor = style === 'badge' ? 'bg-gray-100' : '';
  let borderRadius = style === 'badge' ? 'rounded-full' : '';
  const display = layout === 'column' ? 'flex sm:grid sm:grid-cols-2' : 'flex';
  let itemDisplay = 'flex';
  let border = '';

  if (style === 'button') {
    padding = 'px-3 py-2';
    itemDisplay = 'inline-flex';
    border = 'border-2 border-gray-700 hover:border-gray-900';
    backgroundColor = 'bg-white-background hover:bg-gray-900 transition';
    hoverText = linkToSearch ? 'hover:text-gray-50' : '';
    borderRadius = 'rounded-[3px]';
  }

  return (
    <div>
      <div className={`${display} ${flexDirection} ${alignItems} c-gap c-gap-6 flex-wrap`}>
        {data.map((i, index) => {
          return (
            <LinkOrSpan
              layout={layout}
              link={linkToSearch}
              type={i.type}
              id={i.id}
              className={`${itemDisplay} items-center self-start text-sm font-medium sm:text-base ${border} ${borderRadius} ${backgroundColor} ${color} ${padding} ${textDecoration} ${hoverText} transition-color`}
              key={`${i.title}_${index}`}
            >
              <Icon className={`${iconsSizeClassName} mr-2`} icon={i?.metadata?.icon} icons={icons} />
              {i.title}
            </LinkOrSpan>
          );
        })}
      </div>
    </div>
  );
};

const CategoryList = ({ ...props }) => {
  return <CategoryTypeLinks {...props} />;
};
export default CategoryList;
