import debounce from 'lodash/debounce';
import { matchPath } from 'react-router';

import routeConfiguration from '@/routeConfiguration';

import { analytics, pageProperties, trackPage } from './segment';

const routes = routeConfiguration();

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

const getPageNameForPath = path => {
  const route = routes.find(route => matchPath(path, { path: route.path, exact: true }));
  if (!route) return '';
  return route.name;
};

const debouncedSegmentAnalyticsHandler = debounce((pageName, { path }) => {
  trackPage(pageName, { path }, pageProperties());
}, 100);
export class SegmentAnalyticsHandler {
  trackPageView(path) {
    if (!analytics) return;
    const pageName = getPageNameForPath(path);
    debouncedSegmentAnalyticsHandler(pageName, { path });
  }
}
