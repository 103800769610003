import { string } from 'prop-types';

import DynamicLink from '@/components/DynamicLink/DynamicLink';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import UiTextSection from './UiTextSection';

// bg-primary-500, bg-accent-500, bg-third-500
const UiFeatureItem = ({ item, colorTheme = 'primary' }) => {
  const { icon, title, description } = item || {};
  const bgClassName = `bg-${colorTheme}-500`;
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        <div className={`flex h-12 w-12 items-center justify-center rounded-md ${bgClassName} text-white`}>
          <SvgRenderer className="h-6 w-6" id={icon} defaultIcon="ShieldCheck" />
        </div>
      </div>

      <div className="ml-4">
        <h5 className="text-lg font-medium leading-6 text-gray-900">{title}</h5>
        <p className="mt-2 text-base text-gray-500">{description}</p>
      </div>
    </div>
  );
};

const UiFeatureContent = props => {
  const {
    title,
    features = [],
    description,
    category,
    titleClassNames,
    descriptionClassNames,
    categoryClassNames,
    colorTheme = ' primary',
    buttonText,
    buttonHref,
  } = props;
  return (
    <div className="space-y-8">
      <UiTextSection
        category={category}
        categoryClassNames={categoryClassNames}
        title={title}
        titleClassNames={titleClassNames}
        description={description}
        descriptionClassNames={descriptionClassNames}
        // buttonText={buttonText}
        // buttonHref={buttonHref}
        colorTheme={colorTheme}
      />

      {features?.length ? (
        <ul className="space-y-8">
          {features.map((item, index) => {
            return (
              <li key={`${index}_feature${item?.title}`}>
                <UiFeatureItem item={item} colorTheme={colorTheme} />
              </li>
            );
          })}
        </ul>
      ) : null}

      {buttonText ? (
        <div>
          <DynamicLink
            as="button"
            colorTheme={colorTheme}
            link={buttonHref}
            shadowClassName="shadow"
            className="inline-flex"
            size="xl"
          >
            {buttonText}
          </DynamicLink>
        </div>
      ) : null}
    </div>
  );
};

UiFeatureContent.propTypes = {
  className: string,
};
UiFeatureContent.defaultProps = { className: '' };
export default UiFeatureContent;
