import { Link } from 'react-router-dom';

import propTypes from 'prop-types';

import ButtonLight from '@/components/Button/ButtonLight';
import ExternalLink from '@/components/ExternalLink/ExternalLink';

import { computeDynamicHref } from '@/util/helpers';

const DynamicLink = ({ children, link, as, colorTheme, ...rest }) => {
  const href = computeDynamicHref(link);

  if (as === 'button') {
    return (
      <ButtonLight externalLink={link?.linkType === 'external'} {...rest} href={href} theme={colorTheme}>
        {children}
      </ButtonLight>
    );
  }

  if (link?.linkType === 'external') {
    return (
      <ExternalLink {...rest} href={href}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link {...rest} to={href}>
      {children}
    </Link>
  );
};

DynamicLink.propTypes = {
  as: propTypes.oneOf(['button', 'a']),
  link: propTypes.object.isRequired,
};
DynamicLink.defaultProps = {
  as: 'a',
};

export default DynamicLink;
