/**
 * Modal creates popup which on mobile layout fills the entire visible page.
 *
 * Example:
 * <Parent>
 *   <Modal id="UniqueIdForThisModal" isOpen={this.state.modalIsOpen} onClose={handleClose}>
 *     <FormX />
 *   </Modal>
 * </Parent>
 */
import * as React from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@/components/Button/Button';
import IconClose from '@/components/IconClose/IconClose';

import { injectIntl, intlShape } from '@/util/reactIntl';
import { XMarkIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

import * as css from './Modal.module.css';

const KEY_CODE_ESCAPE = 27;

const disableBodyScrolling = () => {
  const scrollY = `${window.scrollY}px`;
  const body = document.body;
  body.style.position = 'fixed';
  body.style.top = `-${scrollY}`;
  body.style.right = 0;
  body.style.left = 0;
};

const unDisableBodyScrolling = () => {
  const body = document.body;
  if (body.style.position === '') return;
  const scrollY = body.style.top;
  body.style.position = '';
  body.style.top = '';
  body.style.right = '';
  body.style.left = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const manageDisableScrolling = (componentId, disableScrolling, dontResetScrollOnClose) => {
  if (disableScrolling) {
    disableBodyScrolling();
  } else if (!dontResetScrollOnClose) {
    unDisableBodyScrolling();
  }
};

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.props.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.portalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.handleBodyKeyUp = this.handleBodyKeyUp.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.refDiv = React.createRef();

    this.state = {
      portalRoot: null,
    };
  }

  onManageDisableScrolling(id, isOpen) {
    const { autoManageScroll, onManageDisableScrolling, dontResetScrollOnClose } = this.props;
    const fn = autoManageScroll || !onManageDisableScrolling ? manageDisableScrolling : onManageDisableScrolling;
    fn(id, isOpen, dontResetScrollOnClose);
  }

  componentDidMount() {
    const { id, isOpen } = this.props;
    this.onManageDisableScrolling(id, isOpen);
    document.body.addEventListener('keyup', this.handleBodyKeyUp);
    this.setState({
      portalRoot: document.getElementById('portal-root'),
    });
  }

  componentDidUpdate(prevProps) {
    const { id, isOpen } = prevProps;
    if (this.props.isOpen !== isOpen) {
      this.onManageDisableScrolling(id, this.props.isOpen);

      // Because we are using portal,
      // we need to set the focus inside Modal manually
      if (this.props.usePortal && this.props.isOpen) {
        this.refDiv.current.focus();
      }
    }
  }

  componentWillUnmount() {
    const { id } = this.props;
    document.body.removeEventListener('keyup', this.handleBodyKeyUp);
    this.onManageDisableScrolling(id, false);
  }

  handleBodyKeyUp(event) {
    const { isOpen, noCloseOnEsc } = this.props;
    if (event.keyCode === KEY_CODE_ESCAPE && isOpen && !noCloseOnEsc) {
      this.handleClose(event);
    }
  }

  handleClose(event) {
    const { id, onClose } = this.props;
    this.onManageDisableScrolling(id, false);
    onClose(event);
  }

  render() {
    const {
      children,
      className,
      scrollLayerClassName,
      extraContainerClassName,
      containerClassName,
      contentClassName,
      lightCloseButton,
      intl,
      isClosedClassName,
      isOpen,
      usePortal,
      theme,
      tailwindCloseButton = false,
      hideCloseButton = false,
      fullScreen = false,
      type = 'normal',
      slideOverMaxWidthClassName = 'max-w-md',
      closeOnOutslideClick = false,
    } = this.props;
    let contentClasses = contentClassName;
    const isSlideOver = type === 'slideOver';

    const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
    const closeButtonClasses = classNames(css.close, {
      [css.closeLight]: lightCloseButton,
    });
    const closeBtn =
      isOpen && !hideCloseButton && !isSlideOver ? (
        !tailwindCloseButton ? (
          <Button onClick={this.handleClose} rootClassName={closeButtonClasses} title={closeModalMessage}>
            {/* <span className={css.closeText}>{closeButtonMessage || <FormattedMessage id="Modal.close" />}</span> */}
            <IconClose rootClassName={css.closeIcon} />
          </Button>
        ) : (
          <div className="absolute top-0 right-0 block pt-4 pr-4">
            <button
              onClick={this.handleClose}
              type="button"
              className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        )
      ) : null;

    const isTailwindTheme = theme === 'tailwind';

    // Modal uses given styles to wrap child components.
    // If props doesn't contain isClosedClassName, styles default to css.isClosed
    // This makes it possible to create ModalInMobile on top of Modal where style modes are:
    // visible, hidden, or none (ModalInMobile's children are always visible on desktop layout.)
    const modalClass = isOpen ? css.isOpen : isClosedClassName;
    const classes = classNames(modalClass, className);

    const scrollLayerJustifyClassName = isSlideOver ? 'justify-end' : 'md:justify-center';

    const scrollLayerClasses = scrollLayerClassName || `${css.scrollLayer} sm:items-start ${scrollLayerJustifyClassName}`;
    let containerClasses =
      containerClassName ||
      `${
        isTailwindTheme ? `${css.containerTailwind} px-4 pt-12 pb-4 sm:px-6 sm:pb-6` : css.container
      } shadow-xl ${extraContainerClassName}`;
    if (fullScreen) {
      containerClasses = `min-w-screen min-h-screen bg-white ${extraContainerClassName}`;
    }

    if (isSlideOver) {
      containerClasses = `${slideOverMaxWidthClassName} w-full min-h-screen bg-white shadow-xl flex flex-col`;
      contentClasses = 'flex flex-1 bg-white';
    }

    const portalRoot = this.state.portalRoot;

    // If you want to use Portal https://reactjs.org/docs/portals.html
    // you need to use 'userPortal' flag.
    // ModalInMobile component needs to use the old Modal without the portal
    // because it's relying that the content is rendered inside
    // the DOM hierarchy of the parent component unlike Modal inside Portal.

    return !usePortal ? (
      <div className={classes}>
        <div
          onClick={e => {
            const target = e.target;
            const scrollLayerClicked = target.id === 'modalScrollLayer';
            if (scrollLayerClicked && closeOnOutslideClick) this.handleClose();
          }}
          id="modalScrollLayer"
          className={scrollLayerClasses}
        >
          <div className={containerClasses}>
            {closeBtn}
            <div className={classNames(contentClasses, 'rounded-lg')}>{children}</div>
          </div>
        </div>
      </div>
    ) : portalRoot ? (
      <Portal portalRoot={portalRoot}>
        <div className={classes}>
          <div className={scrollLayerClasses}>
            <div className={classNames(containerClasses, css.focusedDiv, 'rounded-lg')} ref={this.refDiv} tabIndex="-1">
              {closeBtn}
              <div className={classNames(contentClasses)}>{children}</div>
            </div>
          </div>
        </div>
      </Portal>
    ) : null;
  }
}

ModalComponent.defaultProps = {
  children: null,
  className: null,
  scrollLayerClassName: null,
  closeButtonMessage: null,
  containerClassName: null,
  contentClassName: null,
  lightCloseButton: false,
  isClosedClassName: css.isClosed,
  isOpen: false,
  onClose: null,
  usePortal: false,
};

const { bool, func, node, string } = PropTypes;

ModalComponent.propTypes = {
  children: node,
  className: string,
  scrollLayerClassName: string,
  closeButtonMessage: node,
  containerClassName: string,
  contentClassName: string,
  lightCloseButton: bool,
  id: string.isRequired,
  intl: intlShape.isRequired,
  isClosedClassName: string,
  isOpen: bool,
  onClose: func.isRequired,
  usePortal: bool,

  // eslint-disable-next-line react/no-unused-prop-types
  onManageDisableScrolling: func,
};

export default injectIntl(ModalComponent);
