import { getMicroMarketplaceId } from '@/util/helpers.jsx';

import config from '@/config';

import { getToken, refreshSession, removeToken, setToken } from './auth.js';

const { baseUrl, marketplaceId, apiKey } = config.sdk;

const client = (path, { body, method, prefix = '/api', platformRequest, responseType = 'json', ...customConfig } = {}) => {
  // Get token for localstorage
  const token = getToken();
  const microMarketplaceId = getMicroMarketplaceId();
  let XMarketplaceId = marketplaceId;
  let XApiKey = apiKey;

  // Look for dynamic marketplace ID and api keys
  // returned from the marketplace response & stored in a redux store
  try {
    const marketplace = window?.app?.store?.getState()?.Marketplace;
    if (marketplace?.id) {
      XMarketplaceId = marketplace.id;
      XApiKey = marketplace.apiKeys[0];
    }
  } catch (error) {
    console.warn('Unable to set dynamic marketplace ID - falling back to defaults');
  }

  // Setup default headers
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': XApiKey,
    'X-Marketplace-Id': XMarketplaceId,
  };
  if (microMarketplaceId) headers['x-micro-marketplace-Id'] = microMarketplaceId;
  if (platformRequest) {
    headers['x-platform-request'] = true;
  }

  // Add Auth header if token is present
  if (token) headers.Authorization = token;

  // Fetch config
  const defaultMethod = body ? 'POST' : 'GET';
  const config = {
    credentials: 'same-origin',
    method: method ? method : defaultMethod,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  // Stringify body and add to config if present
  if (body) config.body = JSON.stringify(body);

  // URL
  const url = `${baseUrl}${prefix}/${path}`;

  // Fetch call
  return fetch(url, config).then(async response => {
    // Parse response
    let data = {};
    if (responseType === 'blob') {
      data = await response.blob();
    } else {
      data = await response.json();
    }

    // Handle unauthorized
    if (response.status === 401) {
      const callbackParams = { body, method, ...customConfig };
      return handle401Unauthorized({ token, path, data, url, callbackParams });
    }

    if (response.ok) {
      return data;
    } else {
      const _data = { error: { data }, ...data };
      return Promise.reject(_data);
    }
  });
};

async function handle401Unauthorized({ token, path, data, callbackParams }) {
  if (window.handling401Unauthorized) {
    // check every 250ms for 5 seconds
    for (let count = 1; count < 20; count++) {
      await new Promise(resolve => setTimeout(resolve, 250));
      if (!window.handling401Unauthorized) {
        return client(path, callbackParams);
      }
    }

    return Promise.reject();
  } else {
    const allowedPath = path !== 'auth/refresh' && path !== 'auth/logout';

    // Try and refresh the token
    if (token && allowedPath) {
      window.handling401Unauthorized = true;
      return refreshSession()
        .then(response => {
          window.handling401Unauthorized = false;
          setToken(response.data.authData.token);
          setToken(response.data.authData.refreshToken, true);
          return client(path, callbackParams);
        })
        .catch(() => {
          removeTokenAndRefresh();
          return Promise.reject();
        });
    } else if (token && !allowedPath) {
      removeTokenAndRefresh();
    }

    return Promise.reject(data);
  }
}

function removeTokenAndRefresh() {
  window.handling401Unauthorized = false;
  // toast.error('Your login has expired', { limit: 1 });
  removeToken();
  removeToken(true);
  setTimeout(() => window.location.assign(window.location), 0);
}

export { client };
