import Grid from '@marketplace-co/react-marketing-section-feature-selector-grid';
import List from '@marketplace-co/react-marketing-section-feature-selector-list';

import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';
import { getTextClasses } from '@/components/UiRenderer/UiTextRenderer';

import { getMediaProps } from '@/util/helpers';

const UiMarketingFeatureSelectors = ({ config }) => {
  const {
    ui,
    description,
    title,
    features,
    titleClassNames = {},
    descriptionClassNames = {},
    aspectRatioClassName,
  } = config || {};
  const { type, colorTheme = 'primary' } = ui?.feature || {};
  const props = {
    title,
    description,
  };

  const classNames = {};
  classNames.descriptionTextClasses = getTextClasses({
    classNames: descriptionClassNames,
    type: 'marketing-description',
  });

  if (aspectRatioClassName) {
    classNames.mediaAspectRatio = aspectRatioClassName;
  }

  if (type === 'selectorGrid') {
    classNames.titleTextClasses = getTextClasses({ classNames: titleClassNames });

    if (['accent', 'third'].includes(colorTheme)) {
      classNames.bg600 = `bg-${colorTheme}-600`; // bg-primary-600, bg-accent-600, bg-third-600
      classNames.text600 = `text-${colorTheme}-600`; // text-primary-600, text-accent-600, text-third-600
    }

    return (
      <Grid
        {...props}
        className="p-0"
        classNames={classNames}
        features={features.map(feature => {
          const { title, description, image, icon, summary, media = {} } = feature || {};
          return {
            name: title,
            summary: summary || ' ',
            icon: props => <SvgRenderer {...props} className={`${props.className} p-1.5 text-white`} id={icon} />,
            description,
            media: getMediaProps({
              ...media,
              image,
            }),
          };
        })}
      />
    );
  }

  if (type === 'selectorList') {
    let className = 'relative overflow-hidden pt-20 pb-28 sm:py-32';
    let bgColorClassName = 'bg-primary-600';
    let listColorTheme = 'dark';

    const isNativeColorTheme = colorTheme === 'native';
    classNames.titleTextClasses = getTextClasses({
      defaultFontColorClassName: isNativeColorTheme ? 'text-header' : 'text-white',
      classNames: titleClassNames,
    });

    // Apply color theme
    if (['accent', 'third'].includes(colorTheme)) {
      bgColorClassName = `bg-${colorTheme}-600`; // bg-primary-600, bg-accent-600, bg-third-600
      classNames.text100 = `text-${colorTheme}-100`; // text-primary-100, text-accent-100, text-third-100
      classNames.text600 = `text-${colorTheme}-600`; // text-primary-600, text-accent-600, text-third-600
      classNames['shadow900/20'] = `shadow-${colorTheme}-900/20`; // shadow-primary-900/20, text-accent-900/20, text-third-900/20
    }

    if (isNativeColorTheme) {
      // When native bg color, let the UIRenderer control spacing between sections
      // Ensure the shadow is not cuttoff with a bottom padding
      className = 'relative overflow-hidden pb-10';
      bgColorClassName = 'bg-background-white';
      listColorTheme = 'native';
      classNames.text100 = 'text-gray-500';
      classNames.text600 = 'text-gray-700';
      classNames['shadow900/20'] = 'shadow-gray-900/20 dark:shadow-gray-300/20';
    }

    return (
      <List
        {...props}
        colorTheme={listColorTheme}
        className={`${className} ${bgColorClassName}`}
        classNames={classNames}
        features={features.map(feature => {
          const { title, description, image, media = {} } = feature || {};
          return {
            title,
            description,
            media: getMediaProps({
              ...media,
              image,
            }),
          };
        })}
      />
    );
  }

  return null;
};

export default UiMarketingFeatureSelectors;
