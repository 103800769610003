/*
 * Marketplace specific configuration.
 */
export const currencies = [
  { label: 'Australian dollar', key: 'AUD' },
  { label: 'Brazilian real', key: 'BRL' },
  { label: 'Bulgarian lev', key: 'BGN' },
  { label: 'Canadian dollar', key: 'CAD' },
  { label: 'Chilean peso', key: 'CLP' },
  { label: 'Chinese yuan', key: 'CNY' },
  { label: 'Colombian peso', key: 'COP' },
  { label: 'Costa Rican colon', key: 'CRC' },
  { label: 'Croatian kuna', key: 'HRK' },
  { label: 'Czech koruna', key: 'CZK' },
  { label: 'Danish krone', key: 'DKK' },
  { label: 'Emirati dirham', key: 'AED' },
  { label: 'Euro', key: 'EUR' },
  { label: 'Hong Kong dollar', key: 'HKD' },
  { label: 'Hungarian forint', key: 'HUF' },
  { label: 'Indian rupee', key: 'INR' },
  { label: 'Israeli new shekel', key: 'ILS' },
  { label: 'Japanese yen', key: 'JPY' },
  { label: 'Malaysian ringgit', key: 'MYR' },
  { label: 'Mexican peso', key: 'MXN' },
  { label: 'Moroccan dirham', key: 'MAD' },
  { label: 'New Taiwan dollar', key: 'TWD' },
  { label: 'New Zealand dollar', key: 'NZD' },
  { label: 'Norwegian krone', key: 'NOK' },
  { label: 'Peruvian sol', key: 'PEN' },
  { label: 'Philippine peso', key: 'PHP' },
  { label: 'Polish zloty', key: 'PLN' },
  { label: 'Pound sterling', key: 'GBP' },
  { label: 'Romanian leu', key: 'RON' },
  { label: 'Russian ruble', key: 'RUB' },
  { label: 'Saudi Arabian riyal', key: 'SAR' },
  { label: 'Singapore dollar', key: 'SGD' },
  { label: 'South African rand', key: 'ZAR' },
  { label: 'South Korean won', key: 'KRW' },
  { label: 'Swedish krona', key: 'SEK' },
  { label: 'Swiss franc', key: 'CHF' },
  { label: 'Thai baht', key: 'THB' },
  { label: 'Turkish lira', key: 'TRY' },
  { label: 'United States dollar', key: 'USD' },
  { label: 'Uruguayan peso', key: 'UYU' },
];

export const locales = [
  { label: 'English', key: 'en' },
  { label: 'English (Australia)', key: 'en-AU' },
  { label: 'English (Canada)', key: 'en-CA' },
  { label: 'English (UK)', key: 'en-GB' },
  { label: 'Deutsch', key: 'de' },
  { label: 'Español', key: 'es' },
  { label: 'Français', key: 'fr' },
];

const customFonts = [
  { key: 'Canada Sanders', label: 'Canada Sanders' },
  { key: 'Druk Wide', label: 'Druk Wide' },
  { key: 'Suisse Intl', label: 'Suisse Intl' },
];
export const fonts = [
  { key: '', label: 'System Fonts' },
  { key: 'Inter', label: 'Inter' },
  { key: 'DM Sans', label: 'DM Sans' },
  { key: 'Work Sans', label: 'Work Sans' },
  { key: 'Space Mono', label: 'Space Mono' },
  { key: 'Space Grotesk', label: 'Space Grotesk' },
  { key: 'Barlow', label: 'Barlow' },
  { key: 'Rubik', label: 'Rubik' },
  { key: 'Libre Franklin', label: 'Libre Franklin' },
  { key: 'Cormorant', label: 'Cormorant' },
  { key: 'Fira Sans', label: 'Fira Sans' },
  { key: 'Chivo', label: 'Chivo' },
  { key: 'Eczar', label: 'Eczar' },
  { key: 'Alegreya Sans', label: 'Alegreya Sans' },
  { key: 'Alegreya', label: 'Alegreya' },
  { key: 'Source Sans Pro', label: 'Source Sans Pro' },
  { key: 'Source Serif Pro', label: 'Source Serif Pro' },
  { key: 'Roboto', label: 'Roboto' },
  { key: 'Roboto Slab', label: 'Roboto Slab' },
  { key: 'BioRhyme', label: 'BioRhyme' },
  { key: 'Inknut Antiqua', label: 'Inknut Antiqua' },
  { key: 'Poppins', label: 'Poppins' },
  { key: 'Archivo Narrow', label: 'Archivo Narrow' },
  { key: 'Libre Baskerville', label: 'Libre Baskerville' },
  { key: 'Playfair Display', label: 'Playfair Display' },
  { key: 'Karla', label: 'Karla' },
  { key: 'Lora', label: 'Lora' },
  { key: 'Proza Libre', label: 'Proza Libre' },
  { key: 'Spectral', label: 'Spectral' },
  { key: 'IBM Plex Sans', label: 'IBM Plex Sans' },
  { key: 'Crimson Text', label: 'Crimson Text' },
  { key: 'Montserrat', label: 'Montserrat' },
  { key: 'Lato', label: 'Lato' },
  { key: 'PT Sans', label: 'PT Sans' },
  { key: 'PT Serif', label: 'PT Serif' },
  { key: 'Cardo', label: 'Cardo' },
  { key: 'Neuton', label: 'Neuton' },
  { key: 'Open Sans', label: 'Open Sans' },
  { key: 'Inconsolata', label: 'Inconsolata' },
  { key: 'Cabin', label: 'Cabin' },
  { key: 'Raleway', label: 'Raleway' },
  { key: 'Anonymous Pro', label: 'Anonymous Pro' },
  { key: 'Merriweather', label: 'Merriweather' },
  { key: 'Noto Sans', label: 'Noto Sans' },
  { key: 'Nanum Gothic', label: 'Nanum Gothic' },
  { key: 'Oxygen', label: 'Oxygen' },
  { key: 'Hind', label: 'Hind' },
  { key: 'Red Hat Display', label: 'Red Hat Display' },
  { key: 'Public Sans', label: 'Public Sans' },
  { key: 'Big Shoulders Display', label: 'Big Shoulders Display' },
  { key: 'Manrope', label: 'Manrope' },
  ...customFonts,
];

export const languages = [
  { key: 'en', label: 'English' },
  { key: 'de', label: 'Deutsch' },
  { key: 'ru', label: 'Русский' },
  { key: 'pt', label: 'Português' },
  { key: 'ko', label: '한국어' },
  { key: 'it', label: 'Italiano' },
  { key: 'fr', label: 'Français' },
  { key: 'ca', label: 'Català' },
  { key: 'es', label: 'Español' },
  { key: 'zh', label: '中文 (简体)' },
];

export const listingTypes = [
  {
    key: 'experience',
    title: 'Experiences',
    label: 'Experience, service, class or event',
    description: 'Great for in person events, trainings, and much more',
  },
  {
    key: 'video',
    title: 'Video & Live',
    label: 'Video, live stream or online conference',
    description: 'Sell videos on demand and host live streams or conferences',
  },
  {
    key: 'fundraiser',
    title: 'Fundraising',
    label: 'Fundraising campaign',
    description: 'Raise money with a fundraiser and allow guests to donate to your cause',
  },
  {
    key: 'facility',
    title: 'Lease & Hire',
    label: 'Lease or hire anything',
    description: 'Sell facilities and sub facilities of your choosing',
  },
];

export const listingVideoTypes = [
  {
    key: 'live',
    label: 'Live',
    description: 'Live stream videos or events to private or public audiences',
  },
  {
    key: 'conference',
    label: 'Conference',
    description: 'Host a conference call on zoom, google hangouts or any service you like',
  },
  {
    key: 'recorded',
    label: 'Prerecorded',
    description: 'Upload a prerecorded videos for an audience to purchase',
  },
];

export const certificate = [];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // LISTINGS
  options: [
    // This parameter isn't sent to the API. The key is purely for handling the internal state of the sorting dropdown.
    { key: '', label: 'Newest' },
    { key: 'asc:_date', label: 'Next Available' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'desc:updatedAt', label: 'Recently Updated' },
    { key: 'asc:title', label: 'Title (A-Z)' },
    { key: 'desc:title', label: 'Title (Z-A)' },
    { key: 'asc:basePrice', label: 'Lowest price' },
    { key: 'desc:basePrice', label: 'Highest price' },
  ],
  optionsAccountsSearch: [
    { key: '', label: 'Relevance', longLabel: 'Relevance' },
    { key: 'asc:displayName', label: 'Name (A-Z)' },
    { key: 'desc:displayName', label: 'Name (Z-A)' },
  ],
  optionsAccounts: [
    { key: '', label: 'Relevance', longLabel: 'Relevance' },
    { key: 'desc:createdAt', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'desc:updatedAt', label: 'Last Updated' },
    { key: 'asc:name', label: 'Name (A-Z)' },
    { key: 'desc:name', label: 'Name (Z-A)' },
  ],
  optionsTransactions: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:billedAmount', label: 'Lowest Amount' },
    { key: 'desc:billedAmount', label: 'Highest Amount' },
  ],
  optionsBookings: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'desc:startsAt', label: 'Timeslot' },
    { key: 'asc:startsAt', label: 'Oldest Timeslot' },
  ],
  optionsBookingRequests: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
  ],
  optionsContacts: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:firstName', label: 'Name (A-Z)' },
    { key: 'desc:firstName', label: 'Name (Z-A)' },
    { key: 'asc:dateOfBirth', label: 'Oldest' },
    { key: 'desc:dateOfBirth', label: 'Youngest' },
    { key: 'asc:email', label: 'Email' },
  ],
  optionsGroups: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:name', label: 'Name (A-Z)' },
    { key: 'desc:name', label: 'Name (Z-A)' },
  ],
  optionsTasks: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:title', label: 'Title (A-Z)' },
    { key: 'desc:title', label: 'Title (Z-A)' },
  ],
  optionsForms: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:title', label: 'Title (A-Z)' },
    { key: 'desc:title', label: 'Title (Z-A)' },
  ],
  optionsDefault: [
    { key: '', label: 'Newest' },
    { key: 'asc:createdAt', label: 'Oldest' },
    { key: 'asc:title', label: 'Title (A-Z)' },
    { key: 'desc:title', label: 'Title (Z-A)' },
  ],
  optionsNews: [
    { key: '', label: 'Newest' },
    { key: 'asc:publishedDate', label: 'Oldest' },
    { key: 'asc:title', label: 'Title (A-Z)' },
    { key: 'desc:title', label: 'Title (Z-A)' },
  ],
};

export const footerSupportedSocialIcons = ['facebook', 'instagram', 'twitter', 'linkedin', 'github'];
export const footerConfigDefault = {
  copyrightText: 'Marketplace Co, All rights reserved.',
  social: [
    {
      key: 'facebook',
      hyperLink: 'https://facebook.com',
    },
    {
      key: 'instagram',
      hyperLink: 'https://instagram.com',
    },
    {
      key: 'twitter',
      hyperLink: 'https://twitter.com',
    },
    {
      key: 'linkedin',
      hyperLink: 'https://linkedin.com',
    },
    {
      key: 'github',
      hyperLink: 'https://github.com',
    },
  ],
  links: [
    {
      label: 'About',
      linkType: 'internal',
      hyperLink: '/about',
    },
    {
      label: 'Discover',
      linkType: 'internal',
      hyperLink: '/',
    },
    {
      label: 'Press',
      linkType: 'internal',
      hyperLink: '/',
    },
    {
      label: 'Blog',
      linkType: 'external',
      hyperLink: 'https://google.com',
    },
    {
      label: 'Help',
      linkType: 'external',
      hyperLink: 'https://www.notion.so/Help-Center-d0da1c2de09f434d91d749ea008e6e6a',
    },
  ],
};
