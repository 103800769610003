import { ComponentType, useEffect, useState } from 'react';

const useIcons = () => {
  const [icons, setIcons]: [icons: { [key: string]: ComponentType }, setIcons: any] = useState({});
  useEffect(() => {
    import('@tmpc/ui/dist/utils/icons/index').then(result => setIcons(result));
  }, []);
  return icons;
};

export default useIcons;
