import moment from 'moment';

const BizHours = ({ hours }) => {
  const DayHours = ({ label, data }) => {
    let timeLabel = 'Closed';
    if (!data.closed) {
      const format = d => moment(d, 'HH:mm').format('h:mma');
      timeLabel = `${format(data.times[0])} - ${format(data.times[1])}`;
    }

    return (
      <div className="flex flex-row text-xs">
        <div className="w-12">{label}</div>
        <div>{timeLabel}</div>
      </div>
    );
  };
  return (
    <>
      <DayHours label="Mon" data={hours.monday} />
      <DayHours label="Tue" data={hours.tuesday} />
      <DayHours label="Wed" data={hours.wednesday} />
      <DayHours label="Thur" data={hours.thursday} />
      <DayHours label="Fri" data={hours.friday} />
      <DayHours label="Sat" data={hours.saturday} />
      <DayHours label="Sun" data={hours.sunday} />
    </>
  );
};

export default BizHours;
