import { lazy, Suspense } from 'react';

const VideoPlayerJs = lazy(() => import('../VideoJs/VideoJs'));

const VideoPlayer = props => {
  const { loadWithFluid = false, bgColor, className = '', video } = props;

  let sources = props?.sources;
  if (video?.metadata?.playback) {
    sources = [
      {
        src: `https://stream.mux.com/${video.metadata.playback.id}.m3u8`,
        type: 'application/x-mpegURL',
      },
    ];
    const publicUrl = video?.metadata?.publicUrl;
    if (publicUrl) {
      sources.push({
        src: publicUrl,
        type: 'video/mp4',
      });
    }
  }

  return (
    <Suspense
      fallback={
        <div
          style={{ backgroundColor: bgColor, minHeight: '100px' }}
          className={loadWithFluid ? 'aspect-w-16 aspect-h-9' : className}
        ></div>
      }
    >
      <VideoPlayerJs sources={sources} {...props} />
    </Suspense>
  );
};

export default VideoPlayer;
