import { bookings, transactions } from '@/clients';

import { storableError } from '@/util/errors';
import { getSetPaginationParams } from '@/util/helpers';

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/TransactionsPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/TransactionsPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/TransactionsPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_TRANSACTION_DETAILS_REQUEST = 'app/TransactionsPage/FETCH_TRANSACTION_DETAILS_REQUEST';
export const FETCH_TRANSACTION_DETAILS_SUCCESS = 'app/TransactionsPage/FETCH_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_TRANSACTION_DETAILS_ERROR = 'app/TransactionsPage/FETCH_TRANSACTION_DETAILS_ERROR';

// ================ Reducer ================ //

const transactionsPerPage = 15;
const initialState = {
  transactionDetailData: {},
  transactionsDetailLoading: false,
  transactionsDetailError: null,
  transactions: [],
  pagination: {
    page: 1,
    perPage: transactionsPerPage,
    totalItems: null,
    totalPages: null,
  },
  transactionsLoading: true,
  isLoaded: false,
  error: null,
  lastRequestPath: null,
};

const transactionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        error: null,
        isLoaded: false,
        transactions: payload.clearExisting ? [] : state.transactions || [],
        lastRequestPath: payload.lastRequestPath,
        transactionsLoading: true,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        transactionsLoading: false,
        pagination: payload.pagination,
        transactions: payload.response,
      };
    case FETCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, isLoaded: true, transactionsLoading: false, error: payload };

    case FETCH_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state,
        transactionsDetailError: null,
        transactionsDetailLoading: true,
        transactionDetailData: {},
      };
    case FETCH_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        transactionsDetailLoading: false,
        transactionDetailData: payload,
      };
    case FETCH_TRANSACTION_DETAILS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, transactionsDetailLoading: false, transactionsDetailError: payload };

    default:
      return state;
  }
};

export default transactionsPageReducer;

// ================ Action creators ================ //

export const queryTransactionsRequest = (payload, clearExisting) => ({
  type: FETCH_TRANSACTIONS_REQUEST,
  payload: { lastRequestPath: payload, clearExisting },
});

export const queryTransactionsSuccess = ({ response, pagination }) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { response, pagination },
});

export const queryTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const queryTransactionDetailsRequest = () => ({
  type: FETCH_TRANSACTION_DETAILS_REQUEST,
  payload: {},
});

export const queryTransactionDetailsSuccess = response => ({
  type: FETCH_TRANSACTION_DETAILS_SUCCESS,
  payload: response.data,
});

export const queryTransactionDetailsError = e => ({
  type: FETCH_TRANSACTION_DETAILS_ERROR,
  error: true,
  payload: e,
});

export const getTransactions =
  (queryParams, { perPage = transactionsPerPage, page = 1, uiType, clearExisting, requestsOnly } = {}) =>
  dispatch => {
    const path = window.location && window.location.pathname.slice(1).split('/');
    const [currentPath] = path;
    dispatch(queryTransactionsRequest(uiType || currentPath, clearExisting));

    if (uiType === 'bookings' && !requestsOnly) {
      return bookings
        .list(queryParams)
        .then(response => {
          dispatch(
            queryTransactionsSuccess({
              response: response.data,
              pagination: getSetPaginationParams({ perPage, totalItems: response.metadata.total, page }),
            })
          );
          return response.data;
        })
        .catch(err => {
          const error = storableError(err);
          const errMessage = error && error.message;
          // ignore api error arround uuid
          if (errMessage.includes('invalid input syntax for type uuid')) {
            dispatch(
              queryTransactionsSuccess({
                response: [],
                pagination: getSetPaginationParams({ perPage, totalItems: 0, page }),
              })
            );
            return [];
          } else {
            dispatch(queryTransactionsError(error));
          }
        });
    } else {
      return transactions
        .list(queryParams)
        .then(response => {
          dispatch(
            queryTransactionsSuccess({
              response: response.data,
              pagination: getSetPaginationParams({ perPage, totalItems: response.metadata.total, page }),
            })
          );
          return response.data;
        })
        .catch(err => {
          console.log(err);
          dispatch(queryTransactionsError(storableError(err)));
        });
    }
  };

export const getTransactionDetails = id => dispatch => {
  dispatch(queryTransactionDetailsRequest());

  return transactions
    .read(id)
    .then(response => {
      dispatch(queryTransactionDetailsSuccess(response));
      return response.data;
    })
    .catch(err => {
      dispatch(queryTransactionDetailsError(storableError(err)));
    });
};
