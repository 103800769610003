import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const path = 'availability-plans'; //'availability-plan';

const update = (id, planId, body) => {
  if (body.requestModifier && body.requestModifier.name) {
    delete body.id;
    return create(id, body);
  }
  return client(`listings/${id}/${path}/${planId}`, { body, method: 'PUT' });
};

const create = (id, body) => {
  if (body.id) {
    const planId = body.id;
    delete body.id;
    return update(id, planId, body);
  }
  return client(`listings/${id}/${path}`, { body });
};

const read = (id, planId) => {
  return client(`listings/${id}/${path}/${planId}`);
};

const list = (id, queryParams = { limit: 1000 }) => {
  return client(`listings/${id}/${path}?${stringify(queryParams)}`);
};

const remove = (id, planId) => {
  return client(`listings/${id}/${path}/${planId}`, { method: 'DELETE' });
};

const findSlots = id => {
  return client(`listings/${id}/findSlots?${stringify({ type: 'recurrence,slot' })}`);
};

export { create, update, list, findSlots, remove, read };
