import { differenceInYears } from 'date-fns';

import { format } from '@/util/dates';
import { generateImageKitUrl, getMarketplace } from '@/util/helpers';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { Callback, EventProperties, Options } from '@segment/analytics-next';

import config from '@/config';

export const analytics = new AnalyticsBrowser();
export const microMarketplaceAnalytics = new AnalyticsBrowser();

const alias = true;

export const identify = ({
  id,
  name,
  email,
  createdAt,
  firstName,
  lastName,
  gender,
  avatar,
  dateOfBirth,
  phone,
  description,
  metadata,
  force,
  microMarketplace,
}: any) => {
  if (window.segmentAccountIdentified && !force) return;
  const { contactEmail } = metadata || {};

  const marketplace = getMarketplace() as Mpc.marketplace;
  if (!analytics) return;

  const props = {
    name,
    email,
    created_at: createdAt,
    id,
    plan: 'none',
    playkitWebVersion: config.version,
  } as any;

  if (marketplace?.id) props.marketplace_id = marketplace.id;
  if (firstName) props.firstName = firstName;
  if (lastName) props.lastName = lastName;
  if (gender) props.gender = gender;
  if (phone) props.phone = phone;
  if (contactEmail) props.contact_email = contactEmail;
  if (avatar?.metadata) {
    // @ts-ignore
    props.avatar = generateImageKitUrl({ id: avatar.metadata.name, transformationType: 'ar-1:1-sm' });
  }
  if (description) props.description = description;
  if (dateOfBirth) {
    const dobDate = new Date(dateOfBirth);
    props.birthday = format(dobDate, 'yyyy-MM-dd');
    props.age = differenceInYears(new Date(), dobDate);
  }

  if (microMarketplace) props.micro_marketplae;

  if (alias) {
    analytics.alias(id);
    microMarketplaceAnalytics.alias(id);
  }

  analytics.identify(id, props);
  microMarketplaceAnalytics.identify(id, props);

  window.segmentAccountIdentified = true;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  // @ts-ignore
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const getAnonymousId = () => getCookie('guestId');

const setAnonymousIdMaybe = () => {
  const anonymousId = getAnonymousId();

  if (anonymousId) {
    analytics.setAnonymousId(anonymousId);
    microMarketplaceAnalytics.setAnonymousId(anonymousId);
  }
};

export const reset = () => {
  if (!analytics) return;

  analytics.reset();
  microMarketplaceAnalytics.reset();
  setAnonymousIdMaybe();
};

const getMarketplaceProperties = (props: EventProperties) => {
  const marketplace = getMarketplace() as Mpc.marketplace;
  const properties = { ...props, marketplace_id: marketplace?.id } as EventProperties;
  // Micro marketplace ID maybe
  if (marketplace?.microMarketplace?.id) properties.micro_marketplace_id = marketplace?.microMarketplace?.id;

  return properties;
};

// https://www.notion.so/marketplacecompany/Segment-Page-Tracking-Spec-1e51d40efffa4b0fbb3269b6a2c17a1b
export const pageProperties = (props?: EventProperties) => getMarketplaceProperties(props || {});
export const getTrackProperties = (props: EventProperties) => getMarketplaceProperties(props);

export const track = (name: string, properties?: EventProperties, options?: Options) => {
  if (!analytics) return;
  properties = getTrackProperties(properties || {});
  if (config.dev) console.log('Analytics track:', name, properties);

  const trackResponse = analytics.track(name, properties, options);
  if (window.microMarketplaceSegmentImported) {
    microMarketplaceAnalytics.track(name, properties, options);
  }
  return trackResponse;
};

export const trackPage = (
  category?: string | object | undefined,
  name?: string | undefined,
  properties?: EventProperties | Options | Callback | null | undefined
) => {
  const props = pageProperties(properties || undefined);
  analytics.page(category, name, props);

  if (window.microMarketplaceSegmentImported) {
    microMarketplaceAnalytics.page(category, name, props);
  }
};

export const importSegment = (writeKey: string, microMarketplaceWriteKey?: string) => {
  analytics.load({ writeKey });

  if (microMarketplaceWriteKey) {
    microMarketplaceAnalytics.load({ writeKey: microMarketplaceWriteKey });
    window.microMarketplaceSegmentImported = true;
  }

  window.segmentImported = true;
  trackPage();
  setAnonymousIdMaybe();
};
