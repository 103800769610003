import isEqual from 'lodash/isEqual';

import * as accounts from '@/clients/accounts.js';
import * as auth from '@/clients/auth.js';

import * as log from '@/util/log';
import { identify } from '@/analytics';
import { storableError } from '@/util/errors';
import { cacheLocal, fetchIpGeolocation, getLocalCache } from '@/util/helpers';
import { types as sdkTypes } from '@/util/sdkLoader';
import { toast } from '@/util/toast';

import config from '@/config';

import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST = 'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS = 'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR = 'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const SET_CURRENT_USER_BOOKMARKED_LISTINGS = 'app/user/SET_CURRENT_USER_BOOKMARKED_LISTINGS';
export const SET_CURRENT_USER_BOOKMARKED_ACCOUNTS = 'app/user/SET_CURRENT_USER_BOOKMARKED_ACCOUNTS';

// TODO: remove, this structure is to meet existing bindings and assumptions from the share tribe API
export const computeCurrentUser = currentUser => {
  if (!currentUser || !currentUser.id) return {};

  const emailVerified = currentUser.verificationTypes.includes('email');
  currentUser = {
    ...currentUser,
    id: new UUID(currentUser.id),
    emailVerified,
  };
  currentUser.attributes = {
    firstName: currentUser.name,
    lastName: '',
    banned: false,
    stripeConnected: false,
    emailVerified,
    profile: {
      firstName: currentUser.name,
    },
    ...currentUser,
  };
  return currentUser;
};

// ================ Reducer ================ //

const initialState = {
  currentUser: null,
  currentUserBookmarkedListings: [],
  currentUserBookmarkedAccounts: [],
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  requestId: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: computeCurrentUser(payload),
        currentUserBookmarkedListings: (payload && payload.bookmarkedListings) || [],
        currentUserBookmarkedAccounts: (payload && payload.bookmarkedAccounts) || [],
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserBookmarkedListings: [],
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      if (payload.message) toast.success(payload.message);
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        requestId: payload.id,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case SET_CURRENT_USER_BOOKMARKED_LISTINGS:
      return {
        ...state,
        currentUserBookmarkedListings: payload,
      };

    case SET_CURRENT_USER_BOOKMARKED_ACCOUNTS:
      return {
        ...state,
        currentUserBookmarkedAccounts: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const refreshCurrentUserRedux = async () =>
  accounts.read().then(user => window.app?.store?.dispatch(currentUserShowSuccess(user.data)));
export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = (id, message) => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
  payload: { id, message },
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const setCurrentUserBookmarkedListings = (bookmarkedListings = []) => ({
  type: SET_CURRENT_USER_BOOKMARKED_LISTINGS,
  payload: [...bookmarkedListings] || [],
});

export const setCurrentUserBookmarkedAccounts = (bookmarkedAccounts = []) => ({
  type: SET_CURRENT_USER_BOOKMARKED_ACCOUNTS,
  payload: [...bookmarkedAccounts] || [],
});

// ================ Thunks ================ //

export const handleCurrentUserSuccess = (currentUser, dispatch) => {
  // Save stripeAccount to store.stripe.stripeAccount if it exists
  if (currentUser.stripeAccount) {
    dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
  }

  identify({ ...currentUser });

  // set current user id to the logger
  log.setUserId(currentUser.id);
  dispatch(currentUserShowSuccess(currentUser));

  return currentUser;
};

const shouldFetchCurrentUserIp = () => {
  const ipGeolocation = localStorage.getItem('ipGeolocation');
  if (!ipGeolocation && !config.test) fetchIpGeolocation();
};

export const currentUserChacheKey = '__playkit__currentUser';
export const getChachedCurrentUserLocalStorageResponse = () => {
  const accountId = auth.getAccountId();
  if (!accountId) return null;

  let data = getLocalCache(currentUserChacheKey);
  // ensure token account ID matches the cached id
  // if the accountId of the current users token does not match our cached data, we should return null;
  if (data && data.id !== accountId) data = null;
  return data;
};

export const fetchCurrentUser =
  (params = null) =>
  (dispatch, getState) => {
    dispatch(currentUserShowRequest());
    const { isAuthenticated } = getState().Auth;

    shouldFetchCurrentUserIp();

    if (!isAuthenticated) {
      // Make sure current user is null
      dispatch(currentUserShowSuccess(null));
      return Promise.resolve({});
    }

    // If data is passed do not make API call and handle data accordingly
    if (params && params.data && params.data.account) {
      const currentUser = params.data.account;
      return handleCurrentUserSuccess(currentUser, dispatch);
    }

    const cachedData = getChachedCurrentUserLocalStorageResponse();
    if (cachedData) {
      const shouldIgnoreCacheRequest = window[`${currentUserChacheKey}__ignoreNextCacheSet`] === true;
      if (!shouldIgnoreCacheRequest) {
        handleCurrentUserSuccess(cachedData, dispatch);
      }
    }

    // Get account data from API
    return accounts
      .read()
      .then(response => {
        const currentUser = response.data;
        const shouldSet = !cachedData || !isEqual(cachedData, currentUser);
        cacheLocal(currentUser, currentUserChacheKey);

        if (shouldSet) return handleCurrentUserSuccess(currentUser, dispatch);
        return currentUser;
      })
      .catch(e => {
        log.error(e, 'fetch-current-user-failed');
        return dispatch(currentUserShowError(storableError(e)));
      });
  };

export const sendVerificationEmail = (message, email) => (dispatch, getState) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  const { currentUser } = getState().user;
  let _email = currentUser && currentUser.email;
  if (!_email && email) _email = email;

  return auth
    .requestOtp(_email)
    .then(response => {
      dispatch(sendVerificationEmailSuccess(response.data.id, message));
      return response.data;
    })
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
