import { useEffect, useState } from 'react';

import get from 'lodash/get';

import ButtonLight from '@/components/Button/ButtonLight';
import IconSpinner from '@/components/IconSpinner/IconSpinner';
import UiRenderer from '@/components/UiRenderer/UiRenderer';

import { playBlocks } from '@/clients';

import undraw from '@/assets/undraw.json';
import { generateImageKitUrl } from '@/util/helpers';

const messages = {
  forms: {
    title: 'Form Builder',
    description: (
      <>
        Form builder is a <span className="text-primary font-bold">pro</span> feature that allows accounts to attach custom forms
        to registration or create one off polls.
      </>
    ),
  },
  calendar: {
    title: 'Calendar',
    description: (
      <>
        Calendar is a <span className="text-primary font-bold">pro</span> feature.
      </>
    ),
  },
  contacts: {
    title: 'Contacts',
    description: (
      <>
        Contacts is a <span className="text-primary font-bold">pro</span> feature.
      </>
    ),
  },
};

const spinner = (
  <div className="flex flex-1 flex-row items-center justify-center sm:rounded-md">
    <IconSpinner className="mt-12" />
  </div>
);

const DefaultAlert = ({ className, type, labels }) => {
  return (
    <div className={className}>
      <div className="mx-auto w-full max-w-xl py-6">
        <img
          className="mb-6"
          draggable={false}
          src={generateImageKitUrl({
            id: undraw['pro-locked'],
            transformationType: 'lg',
          })}
        />
        <h2 className="mb-6 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9">{labels.title}</h2>
        <p className="mb-6 text-base leading-6 text-gray-500">{labels.description}</p>

        {type !== 'platform-access' ? (
          type !== 'role' ? (
            <ButtonLight size="lg" href="/account/pro">
              Upgrade to Pro
            </ButtonLight>
          ) : (
            <ButtonLight size="lg" href="/">
              Back to Discover
            </ButtonLight>
          )
        ) : null}
      </div>
    </div>
  );
};

const PlayBlockAlert = ({ className, currentUser, pageData, marketplace, history, loading }) => {
  return (
    <div className={className}>
      {loading ? (
        spinner
      ) : (
        <UiRenderer
          currentUser={currentUser}
          bgColorClassName="bg-background"
          data={pageData}
          marketplace={marketplace}
          history={history}
          sectionsWrapperClassName="mt-4 sm:mt-8"
        />
      )}
    </div>
  );
};

const ProFeatureAlert = props => {
  const { className = 'max-w-container mx-auto', proType, currentUser, hidden = false, type, marketplace, history } = props;
  const playBlockId = get(marketplace, `pageConfig.${proType}.proMarketingPlayBlock`);
  const [pageData, setPageData] = useState(null);
  const [loadingPageData, setLoadingPageData] = useState(!!playBlockId);

  useEffect(() => {
    if (!playBlockId || hidden) return;
    setLoadingPageData(true);
    playBlocks
      .read(playBlockId)
      .then(respone => {
        setLoadingPageData(false);
        const pageConfig = get(respone, 'data.pageConfig');
        if (!pageConfig) return;
        setPageData(pageConfig);
      })
      .catch(() => {
        setLoadingPageData(false);
      });
  }, [playBlockId, hidden]);
  const shouldRenderPlayBlockAlert = loadingPageData || !!pageData;

  if (hidden) return null;

  let labels = messages[proType] || {};
  if (type === 'role') {
    labels = {
      title: 'You do not have access to this page',
      description: 'Your account does not have the correct access rights to view this page.',
    };
  }

  if (type === 'platform-access') {
    labels = {
      title: 'Your marketplace is not configured for this feature',
      description: 'Please reach out to your platform admin if you are interested in adding this capability to your marketplace.',
    };
  }

  const content = shouldRenderPlayBlockAlert ? (
    <PlayBlockAlert
      loading={loadingPageData}
      className={className}
      currentUser={currentUser}
      pageData={pageData}
      marketplace={marketplace}
      history={history}
    />
  ) : (
    <DefaultAlert className={className} labels={labels} type={type} />
  );

  return content;
};

export default ProFeatureAlert;
