declare global {
  interface String {
    getInitials(glue?: boolean): string | null | RegExpMatchArray;
    capitalize(): string;
  }
}

String.prototype.getInitials = function (glue?: boolean) {
  if (typeof glue === 'undefined') glue = true;
  const initials = this.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  if (glue && initials) {
    return initials.join('');
  }
  return initials;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export {};
