import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { number, object, shape, string } from 'prop-types';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';

import Circle from 'react-google-maps/lib/components/Circle';
import DirectionsRenderer from 'react-google-maps/lib/components/DirectionsRenderer';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import Marker from 'react-google-maps/lib/components/Marker';

import { getMarketplace } from '@/util/helpers';

import config from '@/config';

import googleMapConfig from './googleMapConfig';

/**
 * DynamicGoogleMap uses withGoogleMap HOC.
 * It handles some of the google map initialization states.
 */
const DynamicGoogleMap = withGoogleMap(props => {
  const {
    center,
    zoom,
    address,
    mapsConfig,
    draggable = false,
    onDragEnd = () => {},
    markers = [],
    withDirections = false,
    directionsColor = getMarketplace()?.branding?.colors?.primary?.[500] || '#000000',
  } = props;
  const [dragPosition, setDragPosition] = useState(undefined);
  const [directions, setDirections] = useState(null);
  const colorMode = useSelector(state => state?.UI?.colorMode);

  useEffect(() => {
    if (!withDirections || !markers?.length) return undefined;

    const DirectionsService = new window.google.maps.DirectionsService();

    const getMarkerLatLng = marker => new window.google.maps.LatLng(marker?.center?.lat, marker?.center?.lng);

    const originMarker = markers.find(m => m.pickup === true) || markers[0];
    const origin = getMarkerLatLng(originMarker);

    const destinationMarker = markers.find(m => m.dropoff === true) || markers[markers.length - 1];
    const destination = getMarkerLatLng(destinationMarker);

    let waypoints = markers
      .filter(marker => {
        const getLatLngString = m => `${m?.center?.lat},${m?.center?.lng}`;
        const latLng = getLatLngString(marker);
        const isOriginMarker = latLng === getLatLngString(originMarker);
        const isDestinationMarker = latLng === getLatLngString(destinationMarker);
        return !isOriginMarker && !isDestinationMarker;
      })
      .map(marker => ({
        location: getMarkerLatLng(marker),
        stopover: true,
      }));

    DirectionsService.route(
      {
        origin,
        destination,
        waypoints,
        // optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withDirections]);

  const { enabled, url, anchorX, anchorY, width, height } = mapsConfig.customMarker;
  const markerIcon = enabled
    ? {
        icon: {
          url,

          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          size: new window.google.maps.Size(width, height),
          anchor: new window.google.maps.Point(anchorX, anchorY),
        },
      }
    : {};

  const markerPosition = draggable && dragPosition ? dragPosition : center;
  let Markers = null;
  const marker = !markers?.length ? (
    <Marker
      draggable={draggable}
      onDragEnd={e => {
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        setDragPosition({ lat, lng });
        onDragEnd({ lat, lng });
      }}
      position={markerPosition}
      {...markerIcon}
      title={address}
    />
  ) : null;

  if (markers?.length && !withDirections) {
    Markers = markers.map(marker => {
      const { center, address } = marker;
      return <Marker key={address} position={center} {...markerIcon} title={address} />;
    });
  }

  const circleProps = {
    options: {
      fillColor: mapsConfig.fuzzy.circleColor,
      fillOpacity: 0.2,
      strokeColor: mapsConfig.fuzzy.circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 1,
      clickable: false,
    },
    radius: mapsConfig.fuzzy.offset,
    center,
  };

  const circle = <Circle {...circleProps} />;

  const controlPosition =
    typeof window !== 'undefined' && typeof window.google !== 'undefined' ? window.google.maps.ControlPosition.LEFT_TOP : 5;

  return (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={center}
      center={!withDirections ? center : null}
      options={{
        // Disable map type (ie. Satellite etc.)
        mapTypeControl: false,
        // Disable zooming by scrolling
        scrollwheel: false,
        // Fullscreen control toggle
        fullscreenControl: false,
        // Street View control
        streetViewControl: false,
        styles: colorMode === 'dark' ? googleMapConfig.stylesDark : googleMapConfig.styles,
        // Zoom control position
        zoomControlOptions: {
          position: controlPosition,
        },
      }}
    >
      {mapsConfig.fuzzy.enabled ? circle : marker}
      {Markers}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: directionsColor,
            },
          }}
        />
      )}
    </GoogleMap>
  );
});

DynamicGoogleMap.defaultProps = {
  address: '',
  center: null,
  zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 11,
  mapsConfig: config.maps,
};

DynamicGoogleMap.propTypes = {
  address: string,
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number,
  mapsConfig: object,
};

export default DynamicGoogleMap;
