import { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';

import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';
import Form from '@/components/Form/Form';
import LocationAutocompleteInput from '@/components/LocationAutocompleteInput/LocationAutocompleteInput';

import { injectIntl, intlShape } from '@/util/reactIntl';
import { MagnifyingGlassIcon, XCircleIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

import * as css from './TopbarSearchForm.module.css';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    } else if (!location.search && !location.value) {
      this.props.onSubmit({ location });
    }
  }

  searchKeyword(keywords, clear) {
    if (!keywords && !clear) return;
    this.props.onSubmit({ keywords });
  }

  render() {
    const setIsMobileSearchFieldFocused = this.props.setIsMobileSearchFieldFocused || (() => {});
    return (
      <FinalForm
        {...this.props}
        mutators={{
          setValue: (args, state, utils) => {
            utils.changeValue(state, args[0], () => args[1]);
          },
        }}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            transparentActive,
            placeholder,
            searchType,
            values,
            form,
            maxWidth = '320px',
          } = formRenderProps;

          const classes = classNames(rootClassName, className);

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => {
            e.preventDefault();
            if (searchType !== 'keyword') return;
            this.searchKeyword(values.keywords);
          };

          const locationSearch = (
            <Field
              name="location"
              format={identity}
              render={({ input, meta }) => {
                const { onChange, ...restInput } = input;

                // Merge the standard onChange function with custom behaviur. A better solution would
                // be to use the FormSpy component from Final Form and pass this.onChange to the
                // onChange prop but that breaks due to insufficient subscription handling.
                // See: https://github.com/final-form/react-final-form/issues/159
                const searchOnChange = value => {
                  onChange(value);
                  this.onChange(value);
                };

                const searchInput = {
                  ...restInput,
                  onChange: searchOnChange,
                  onFocus: () => setIsMobileSearchFieldFocused(true),
                  onBlur: () => setIsMobileSearchFieldFocused(false),
                };
                return (
                  <LocationAutocompleteInput
                    checkPlayblockEstablishments
                    showClearIcon
                    searchOnFocus
                    className={`${desktopInputRoot} ${css.desktopInputRoot} ${bgColorClassName} rounded-md ${this.props.inputClassName}`}
                    iconClassName={`text-primary dark:text-gray-900 pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center`}
                    inputStyles={{ maxWidth }}
                    noShadow
                    inputClassName={
                      isMobile
                        ? css.mobileInput
                        : `pl-10 ${
                            transparentActive ? 'border-transparent-important' : ''
                          } ${bgColorClassName} block w-full sm:leading-5`
                    }
                    predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
                    predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
                    placeholder={placeholder ? placeholder : intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                    closeOnBlur={!isMobile}
                    inputRef={node => {
                      this.searchInput = node;
                    }}
                    input={searchInput}
                    meta={meta}
                  />
                );
              }}
            />
          );

          const keywordSearch = (
            <div className="relative flex max-w-sm flex-1 flex-row">
              <FieldTextInput
                name="keywords"
                type="search"
                className="flex-1"
                placeholder={placeholder ? placeholder : 'Search'}
                // roundedClassName="rounded-none rounded-l-md"
                inputClassName="pr-14"
                hideErrorMessage
              >
                {values.keywords ? (
                  <div className="absolute inset-y-0 right-11 flex items-center">
                    <XCircleIcon
                      onClick={() => {
                        form.mutators.setValue('keywords', '');
                        this.searchKeyword('', true);
                      }}
                      className="hover:text-primary h-5 w-5 cursor-pointer text-gray-500"
                    />
                  </div>
                ) : null}

                <div className="z-1 absolute inset-y-0 right-0 flex items-center pr-3">
                  <button
                    type="submit"
                    className="z-1 bg-accent-600 hover:bg-accent-700 focus:ring-accent-500 inline-flex cursor-pointer items-center rounded-full border border-transparent p-1 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              </FieldTextInput>
            </div>
          );

          const bgColorClassName = transparentActive ? 'bg-white' : 'bg-white focus:bg-white';
          return (
            <Form className={`${classes} flex flex-row items-center`} onSubmit={preventFormSubmit}>
              {searchType !== 'keyword' ? locationSearch : keywordSearch}
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
