import { useState } from 'react';

import useSWRInfinite from 'swr/infinite';

import ButtonLight from '@/components/Button/ButtonLight';
import Card from '@/components/Card/Card';
import Filters, { areFiltersActive } from '@/components/Filters/Filters';
import IconSpinner from '@/components/IconSpinner/IconSpinner';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

import { playBlocks } from '@/clients';

import { getFetchPaginaionParams } from '@/util/helpers';
import { parse, stringify } from '@/util/urlHelpers';

const Header = ({ className = '', title, titleClassNames, description }) => {
  return (
    <div className={className}>
      <UiTextRenderer
        text={title}
        classNames={titleClassNames}
        defaultFontWeightClassName="font-bold"
        defaultFontSizeClassName="text-3xl sm:text-4xl md:text-5xl"
        defaultFontFamilyClassName="font-header"
        defaultFontColorClassName="text-gray-900"
      />

      {description ? <p className="mt-4 text-lg leading-7 text-gray-500 sm:text-2xl sm:leading-9">{description}</p> : null}
    </div>
  );
};

const Spinner = ({ loading }) => {
  if (!loading) return null;

  return loading ? (
    <div className="flex flex-row items-center justify-center">
      <IconSpinner type="tailwind" />
    </div>
  ) : null;
};

const EmptyState = ({ filtersActive, history }) => {
  return (
    <div className="flex h-64 flex-col items-center justify-center p-6">
      <div className="mx-auto max-w-sm text-center">
        <div className="text-lg font-medium leading-6 text-gray-900">No results found</div>
        {filtersActive ? (
          <div className="mt-2 text-sm text-gray-500">
            We couldn’t find any results that match your filters. Try a different filter or{' '}
            <button
              onClick={() => {
                history.push(window.location.pathname);
              }}
              className="focus:ring-primary-500 rounded text-gray-800 underline transition hover:text-gray-900 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              reset all filters
            </button>
            .
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LoadMoreButton = ({ loading, hasMore, onLoadMoreClicked = () => {}, loadingMore }) => {
  if (loading || !hasMore) return null;

  return (
    <div className="mt-12 flex flex-row justify-center">
      <ButtonLight inProgress={loadingMore} onClick={onLoadMoreClicked} size="xl" theme="dark">
        Load more
      </ButtonLight>
    </div>
  );
};

const Grid = ({ data, loading, className = '' }) => {
  if (!data?.length || loading) return null;

  return (
    <div className={`grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 ${className}`}>
      {data.map(i => (
        <Card cardType="detailed" size="auto" key={i.id} useImageCarousel {...i} />
      ))}
    </div>
  );
};

export const UiSearchRenderer = params => {
  const {
    metadata,
    className = '',
    limit = 9,
    filters,
    filtersEnabled = false,
    sortEnabled = false,
    history,
    playBlockId,
  } = params;
  const { title, description, titleClassNames } = metadata;
  const location = history?.location;
  const filtersActive = areFiltersActive(location);
  const { sort, type, keywords } = parse(location?.search);

  const [total, setTotal] = useState(false);
  let pagination = {
    page: 1,
    perPage: limit,
    totalItems: null,
    totalPages: null,
  };
  const getQueryParams = pageIndex => {
    const index = pageIndex || 1;
    return {
      keyword: keywords || undefined,
      type: type ? `in:${type}` : undefined,
      sort,
      ...getFetchPaginaionParams({ pagination, page: index }),
    };
  };

  // https://swr.vercel.app/docs/pagination#useswrinfinite
  const getKey = (pageIndex = 0, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null; // reached the end

    pageIndex = pageIndex + 1;
    const queryParams = stringify(getQueryParams(pageIndex));
    return `/api/playblocks/${playBlockId}/recommendations?${queryParams}&SIZE=${pageIndex}`; // SWR key
  };

  const fetcher = key => {
    const size = key.split('SIZE=')?.[1];
    return playBlocks.recommendations(playBlockId, getQueryParams(size)).then(response => {
      setTotal(response?.metadata?.total);
      return response?.data;
    });
  };
  const { data, size, setSize, error, isValidating } = useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
    revalidateAll: true,
  });

  const items = data ? [].concat(...data) : [];
  const isLoadingInitialData = title && !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || items?.length === total;
  const isRefreshing = isValidating && data && data.length === size;

  const emptyStateMaybe = isEmpty ? <EmptyState history={history} filtersActive={filtersActive} /> : null;

  return (
    <div className={className}>
      <Header description={description} title={title} titleClassNames={titleClassNames} />

      {filtersEnabled ? (
        <Filters
          // inlineKeywords
          // searchPlaceholder="Search..."
          location={history?.location}
          history={history}
          sortDisabled={!sortEnabled}
          className="mt-4"
          filterType="dynamic"
          dynamicConfig={filters}
        />
      ) : null}

      <Grid className="mt-4" loading={isLoadingInitialData} data={items} />
      <Spinner loading={isLoadingInitialData} />
      <LoadMoreButton
        onLoadMoreClicked={() => setSize(size + 1)}
        hasMore={!isReachingEnd}
        loading={isLoadingInitialData}
        loadingMore={isRefreshing || isLoadingMore}
      />

      {emptyStateMaybe}
    </div>
  );
};

export default UiSearchRenderer;
