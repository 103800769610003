/**
 * Import reducers from shared ducks modules (default export)
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */
import Auth from './Auth.duck';
import Calendar from './Calendar.duck';
import Marketplace from './Marketplace.duck';
import Routing from './Routing.duck';
import stripe from './stripe.duck';
import stripeConnectAccount from './stripeConnectAccount.duck';
import UI from './UI.duck';
import user from './user.duck';

export { Auth, Routing, UI, stripe, stripeConnectAccount, user, Marketplace, Calendar };
