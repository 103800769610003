import { bool, string } from 'prop-types';

import { injectIntl, intlShape } from '@/util/reactIntl';

import config from '@/config';

import SortByPlain from './SortByPlain';
import SortByPopup from './SortByPopup';

const SortBy = props => {
  const { sort, showAsPopup, intl, filterType = 'listings', ...rest } = props;

  let options = config.custom.sortConfig.options;
  switch (filterType) {
    case 'accounts':
      options = config.custom.sortConfig.optionsAccounts;
      break;
    case 'providers':
      options = config.custom.sortConfig.optionsAccountsSearch;
      break;
    case 'forms':
      options = config.custom.sortConfig.optionsForms;
      break;
    case 'transactions':
      options = config.custom.sortConfig.optionsTransactions;
      break;
    case 'bookings':
      options = config.custom.sortConfig.optionsBookings;
      break;
    case 'bookingRequests':
      options = config.custom.sortConfig.optionsBookingRequests;
      break;
    case 'contacts':
      options = config.custom.sortConfig.optionsContacts;
      break;
    case 'tasks':
      options = config.custom.sortConfig.optionsTasks;
      break;
    case 'groups':
      options = config.custom.sortConfig.optionsGroups;
      break;
    case 'listings':
      options = config.custom.sortConfig.options;
      break;
    case 'news':
    case 'resources':
      options = config.custom.sortConfig.optionsNews;
      break;
    default:
      options = config.custom.sortConfig.optionsDefault;
      break;
  }

  const defaultValue = '';
  const componentProps = {
    urlParam: 'sort',
    label: intl.formatMessage({ id: 'SortBy.heading' }),
    options,
    initialValue: sort || defaultValue,
    ...rest,
  };
  return showAsPopup ? <SortByPopup {...componentProps} /> : <SortByPlain {...componentProps} />;
};

SortBy.defaultProps = {
  sort: null,
  showAsPopup: false,
};

SortBy.propTypes = {
  sort: string,
  showAsPopup: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(SortBy);
