import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import set from 'lodash/set';

import { defaultColors, defaultDarkColors, mpcColors } from '@/forms/EditMarketplaceThemeForm/colors';

import * as marketplace from '@/clients/marketplace.js';
import { setColorMode } from '@/ducks/UI.duck';
import { currentUserChacheKey, getChachedCurrentUserLocalStorageResponse, handleCurrentUserSuccess } from '@/ducks/user.duck.js';

import { importSegment } from '@/analytics/segment';
import { storableError } from '@/util/errors';
import { cacheLocal, generateImageKitUrl, getLocalCache, hexToRgb, loadMarketplaceFonts } from '@/util/helpers';
import { types as sdkTypes } from '@/util/sdkLoader';

import config from '@/config';

const { LatLng, LatLngBounds } = sdkTypes;

// ================ Action types ================ //

export const MARKETPLACE_REQUEST = 'app/Marketplace/MARKETPLACE_REQUEST';
export const MARKETPLACE_SUCCESS = 'app/Marketplace/MARKETPLACE_SUCCESS';
export const MARKETPLACE_ERROR = 'app/Marketplace/MARKETPLACE_ERROR';

export const MARKETPLACE_UPDATE_REQUEST = 'app/Marketplace/MARKETPLACE_UPDATE_REQUEST';
export const MARKETPLACE_UPDATE_SUCCESS = 'app/Marketplace/MARKETPLACE_UPDATE_SUCCESS';
export const MARKETPLACE_UPDATE_ERROR = 'app/Marketplace/MARKETPLACE_UPDATE_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  title: null,
  tokenValidityInSeconds: 24 * 60 * 60,
  sessionValidityInSeconds: 7 * 24 * 60 * 60,
  applicationConfig: {
    account: {
      verification: {
        minimum: null,
        mandatory: [],
        optional: [],
      },
    },
    payment: {
      default: null,
      options: [],
    },
    settings: {
      maxTagsAllowed: null,
      maxCategoriesAllowed: null,
      tags: [],
      categoryTypes: [],
    },
  },
  authExcludedRoutes: [],
  apiKeys: [],

  marketplaceUpdateProgress: false,
  marketplaceInProgress: false,
  marketplaceError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case MARKETPLACE_REQUEST:
      return {
        ...state,
        marketplaceInProgress: true,
        marketplaceError: null,
      };
    case MARKETPLACE_SUCCESS:
      return { ...state, marketplaceInProgress: false, ...payload };
    case MARKETPLACE_ERROR:
      return { ...state, marketplaceInProgress: false, marketplaceError: payload };

    case MARKETPLACE_UPDATE_REQUEST:
      return {
        ...state,
        marketplaceUpdateProgress: true,
        marketplaceError: null,
      };
    case MARKETPLACE_UPDATE_SUCCESS:
      return { ...state, marketplaceUpdateProgress: false, ...payload };
    case MARKETPLACE_UPDATE_ERROR:
      return { ...state, marketplaceUpdateProgress: false, marketplaceError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const marketplaceRequest = () => ({ type: MARKETPLACE_REQUEST });
export const marketplaceSuccess = payload => ({ type: MARKETPLACE_SUCCESS, payload });
export const marketplaceError = error => ({ type: MARKETPLACE_ERROR, payload: error, error: true });

export const marketplaceUpdateRequest = () => ({ type: MARKETPLACE_UPDATE_REQUEST });
export const marketplaceUpdateSuccess = payload => ({ type: MARKETPLACE_UPDATE_SUCCESS, payload });
export const marketplaceUpdateError = error => ({ type: MARKETPLACE_UPDATE_ERROR, payload: error, error: true });

// ================ Thunks ================ //

export const updateMarketplace = (id, body) => dispatch => {
  dispatch(marketplaceUpdateRequest());

  return marketplace
    .update(id, body)
    .then(response => {
      const { data } = response;

      dispatch(getMarketplaceInfo());
      return dispatch(marketplaceUpdateSuccess(data));
    })
    .catch(e => {
      const err = storableError(e);
      dispatch(marketplaceUpdateError(err));
    });
};

export const setProperty = ({ hex, key, numberKey, root = document.documentElement.style }) => {
  if (!hex || !key) return;
  const { r, g, b } = hexToRgb(hex) || {};
  const value = `${r} ${g} ${b}`;
  const prop = `--mpc-custom-color-${key}${numberKey ? `-${numberKey}` : ''}`;
  root.setProperty(prop, value);
};

export const setMpcColorPalette = ({ key, colors, root }) => {
  if (!colors || !colors?.length || !colors[0]) return;

  const defaultColors = mpcColors[key];
  const shouldSet = !defaultColors || (defaultColors && !isEqual(defaultColors, colors));

  if (!shouldSet) {
    return;
  }

  colors.map((hex, index) => {
    const numberKey = index ? index * 100 : 50;
    setProperty({
      numberKey,
      key,
      hex,
      root,
    });
    return true;
  });
};

const parseForMicroMarketplaceMaybe = data => {
  if (!data?.microMarketplace) return data;

  let { title, branding = {}, pageConfig } = data.microMarketplace;
  const { about, authentication, support } = pageConfig || {};

  data.parentMarketplace = pick(data, ['title', 'branding', 'pageConfig']);
  data.title = title;

  // GENERAL SETTINGS
  // Labels
  data.pageConfig.general = merge(data.pageConfig.general, pick(pageConfig?.general, ['ogImage', 'labels.searchPlaceholder']));

  // PAGE SETTINGS
  // Authentication
  if (authentication?.useCustomAuthenticationSettings) set(data, 'pageConfig.authentication', pageConfig.authentication);
  if (support?.useCustomSupportSettings) set(data, 'pageConfig.support', pageConfig.support);

  // Search
  if (pageConfig?.search?.useCustomSearchSettings) {
    if (pageConfig?.general?.locations?.length > 0) {
      set(data, 'pageConfig.general.locations', pageConfig?.general?.locations);
    }
    set(data, 'pageConfig.search', pageConfig.search);
  }
  // Footer
  if (pageConfig?.general?.footer?.useCustomFooterSettings) {
    set(data, 'pageConfig.general.footer', pageConfig.general.footer);
  }

  // PLAYBLOCKS
  if (about?.playBlock) set(data, 'pageConfig.about.playBlock', about.playBlock);

  // Navigation
  if (pageConfig?.advanced?.navConfig?.useCustomNavigationSettings) {
    set(data, 'pageConfig.advanced.navConfig', pageConfig.advanced.navConfig);
    set(data, 'pageConfig.advanced.navigationType', pageConfig.advanced.navigationType);
    set(data, 'pageConfig.advanced.customNavActive', pageConfig.advanced.customNavActive);
    set(data, 'pageConfig.advanced.customNavConfig', pageConfig.advanced.customNavConfig);
  }

  const omitBrandingKeys = [];
  // THEME SETTINGS
  // Typography
  if (!branding?.typography?.useCustomTypographySettings) {
    omitBrandingKeys.push('typography');
  }
  // Colors
  if (!branding?.colors?.useCustomColorSettings) {
    omitBrandingKeys.push('colors');
    omitBrandingKeys.push('colorModes');
  }
  // Layout
  if (!branding?.layout?.useCustomLayoutSettings) {
    omitBrandingKeys.push('layout');
  }
  // Components
  if (!branding?.components?.useCustomComponentSettings) {
    omitBrandingKeys.push('theme');
    omitBrandingKeys.push('components');
  }
  // Branding
  data.branding = merge(data.branding, omit(branding, omitBrandingKeys));

  return data;
};

export const setMarketplaceConfig = (initialData, colorsOnly) => {
  let data = { ...initialData };

  const { title, branding = {}, pageConfig } = data;
  const { colors, logo = {}, font, colorModes } = branding || {};
  // eslint-disable-next-line no-unused-vars
  const { primary: fontPrimary, header: fontHeader, third: fontThird } = font || {};
  const { dark } = colorModes || {};
  const { disabled: darkDisabled, force: darkForced } = dark || {};

  const root = document.documentElement.style;
  const rootEl = document.documentElement;

  if (darkDisabled) {
    rootEl.classList.add('dark-disabled');
    rootEl.classList.remove('dark');
    try {
      window.app.store.dispatch(setColorMode('light'));
    } catch (error) {
      console.error('There was an error syncing color mode to the redux store', error);
    }
  }

  if (darkForced && !rootEl.classList.contains('dark-disabled')) {
    rootEl.classList.add('dark');
    rootEl.classList.add('dark-forced');
    try {
      window.app.store.dispatch(setColorMode('dark'));
    } catch (error) {
      console.error('There was an error syncing color mode to the redux store', error);
    }
  }

  if (colors) {
    const { primary, accent, third, headerTextColor, bg, white } = colors || {};
    const { primary: primaryDark, accent: accentDark, third: thirdDark } = dark || {};

    /*
      ---------------------------------------
      System Colors
      ---------------------------------------
    */
    const customColorPaletteKeys = ['gray', 'success', 'warning', 'error'];
    for (const key of customColorPaletteKeys) {
      setMpcColorPalette({ key, colors: colors[key] });
    }
    let headerTextColorValue = headerTextColor === 'accent' && accent ? accent['500'] : primary['500'];
    if (headerTextColor === 'third' && third?.['500']) {
      headerTextColorValue = third['500'];
    }
    if (headerTextColor && headerTextColorValue) {
      root.setProperty('--mpc-color-header-text', `rgb(var(--mpc-color-${headerTextColor}-500))` || '');
    }

    /*
      ---------------------------------------
      Light Mode
      ---------------------------------------
    */
    if (bg && bg !== defaultColors.bg) {
      setProperty({ key: 'bg', hex: bg });
    }
    if (white && white !== defaultColors.white) {
      setProperty({ key: 'white', hex: white });
    }

    if (!primary?.length) {
      for (const key in primary) {
        setProperty({ key: `primary-${key}`, hex: primary[key] });
      }
      if (accent) {
        for (const key in accent) {
          setProperty({ key: `accent-${key}`, hex: accent[key] });
        }
      }
      if (third) {
        for (const key in third) {
          setProperty({ key: `third-${key}`, hex: third[key] });
        }
      }
    } else {
      setMpcColorPalette({ key: 'primary', colors: primary });
      setMpcColorPalette({ key: 'accent', colors: accent });
      setMpcColorPalette({ key: 'third', colors: third });
    }

    /*
      ---------------------------------------
      Dark Mode
      ---------------------------------------
    */
    if (dark) {
      const { bg, white, gray } = dark || {};
      if (bg && bg !== defaultDarkColors.bg) {
        setProperty({ key: 'dark-bg', hex: bg });
      }
      if (white && white !== defaultDarkColors.white) {
        setProperty({ key: 'dark-white', hex: white });
      }
      if (gray) {
        setMpcColorPalette({ key: 'dark-gray', colors: gray });
      }
      if (primaryDark) {
        setMpcColorPalette({ key: 'dark-primary', colors: primaryDark });
      }
      if (accentDark) {
        setMpcColorPalette({ key: 'dark-accent', colors: accentDark });
      }
      if (thirdDark) {
        setMpcColorPalette({ key: 'dark-third', colors: thirdDark });
      }
    }
  }

  if (colorsOnly) return;

  const { favicon, webclip } = logo || {};
  // Set favicon
  if (favicon) {
    document.getElementById('favicon').href = favicon.default;
    document.getElementById('favicon16').href = favicon[16];
    document.getElementById('favicon32').href = favicon[32];
  }

  try {
    const primaryColor = colors?.primary?.[500];
    let icons = [];
    if (webclip) {
      const id = get(webclip, 'metadata.name');
      if (id) {
        document.getElementById('apple-touch-icon').href = generateImageKitUrl({ id, transformationType: 'webclip' });
        icons = [
          {
            src: generateImageKitUrl({ id, transformationType: '192x192' }),
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: generateImageKitUrl({ id, transformationType: '512x512' }),
            sizes: '512x512',
            type: 'image/png',
          },
        ];
      }
    }

    const manifest = JSON.stringify({
      name: title,
      id: `${data.domain}-${config.version}`,
      short_name: title,
      display: data?.branding?.manifest?.display || 'minimal-ui',
      description: data?.pageConfig?.general?.labels?.seoDescription,
      start_url: window.location.origin,
      background_color: '#FFFFFF',
      theme_color: primaryColor || '#000000',
      icons,
    });
    const blob = new Blob([manifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
  } catch {
    console.error('There was an error dynamically generating the manifest');
  }

  loadMarketplaceFonts(data);

  // Turn all default locations into real latLng data types
  if (pageConfig && pageConfig.general && pageConfig.general.locations) {
    try {
      pageConfig.general.locations = pageConfig.general.locations.map(locaiton => ({
        ...locaiton,
        predictionPlace: {
          ...locaiton.predictionPlace,
          bounds: new LatLngBounds(
            new LatLng(locaiton.predictionPlace.bounds.ne.lat, locaiton.predictionPlace.bounds.ne.lng),
            new LatLng(locaiton.predictionPlace.bounds.sw.lat, locaiton.predictionPlace.bounds.sw.lng)
          ),
        },
      }));
    } catch (error) {
      console.error('There was an error parsing default locations', error);
    }
  }
};

const shouldImportSegment = data => {
  const segmentImported = window.segmentImported === true;
  const writeKey = data?.servicesCredentials?.segment?.writeKey;
  const microMarketplaceWriteKey = data?.microMarketplace?.servicesCredentials?.segment?.writeKey;
  if (segmentImported || !writeKey) return;
  importSegment(writeKey, microMarketplaceWriteKey);
};

const marketplaceCacheKey = '__playkit__marketplace';

const shouldSetCurrentUserFromCache = dispatch => {
  const cachedCurrentUserData = getChachedCurrentUserLocalStorageResponse();
  if (cachedCurrentUserData) {
    window[`${currentUserChacheKey}__ignoreNextCacheSet`] = true;
    handleCurrentUserSuccess(cachedCurrentUserData, dispatch);
  }
};

export const getMarketplaceInfo =
  (returnOnCacheHit = false) =>
  (dispatch, getState) => {
    dispatch(marketplaceRequest());

    const fetchMarketplaceInfo = () => {
      return marketplace
        .read()
        .then(response => {
          let { data } = response;
          cacheLocal(data, marketplaceCacheKey);
          const shouldSet = !cachedData || !isEqual(JSON.stringify(cachedData), JSON.stringify(data));
          if (shouldSet) {
            data = parseForMicroMarketplaceMaybe(data);
            setMarketplaceConfig(data);
            shouldImportSegment(data);
            return dispatch(
              marketplaceSuccess({
                ...data,
                key: +new Date(),
              })
            );
          }
          return data;
        })
        .catch(e => {
          const err = storableError(e);
          dispatch(marketplaceError(err));
        });
    };

    let cachedData = getLocalCache(marketplaceCacheKey);
    if (cachedData) {
      cachedData = parseForMicroMarketplaceMaybe(cachedData);
      setMarketplaceConfig(cachedData);

      // Check if we should set user data from cache
      // if are setting the marketplace from cache we also need to set any user cache at the same time to prevent flash of content
      const { isAuthenticated } = getState().Auth;
      if (isAuthenticated) {
        shouldSetCurrentUserFromCache(dispatch);
      }

      dispatch(marketplaceSuccess(cachedData));
      shouldImportSegment(cachedData);

      // Return right away, but still check for newer data in the meantime
      if (returnOnCacheHit) {
        fetchMarketplaceInfo();
        return dispatch(
          marketplaceSuccess({
            ...cachedData,
            key: +new Date(),
          })
        );
      }
    }

    return fetchMarketplaceInfo();
  };
