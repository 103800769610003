import { useState } from 'react';

import { useForm } from 'react-hook-form';

import IconSpinner from '@/components/IconSpinner/IconSpinner';

import { communications } from '@/clients';

import { parse } from '@/util/urlHelpers';
import { CheckIcon, EnvelopeIcon, PhoneIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

import UiTextSection from './UiTextSection';

const Svgs = () => {
  return (
    <>
      <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
        <svg
          className="absolute inset-0 h-full w-full"
          width={343}
          height={388}
          viewBox="0 0 343 388"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fillOpacity=".1" />
          <defs>
            <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden" aria-hidden="true">
        <svg
          className="absolute inset-0 h-full w-full"
          width={359}
          height={339}
          viewBox="0 0 359 339"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
            fill="url(#linear2)"
            fillOpacity=".1"
          />
          <defs>
            <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block" aria-hidden="true">
        <svg
          className="absolute inset-0 h-full w-full"
          width={160}
          height={678}
          viewBox="0 0 160 678"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
            fill="url(#linear3)"
            fillOpacity=".1"
          />
          <defs>
            <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
};

const ContactInformation = ({ description, title, phone, email }) => {
  return (
    <div className="bg-accent-700 relative overflow-hidden py-10 px-6 sm:px-10 xl:p-12">
      <Svgs />
      <h3 className="text-lg font-medium text-white">{title}</h3>
      <p className="text-accent-50 mt-6 max-w-3xl text-base">{description}</p>
      <dl className="mt-8 space-y-6">
        {phone ? (
          <a href={`tel:${phone}`} className="text-accent-50 flex text-base">
            <PhoneIcon className="text-accent-200 h-6 w-6 flex-shrink-0" aria-hidden="true" />
            <span className="ml-3">{phone}</span>
          </a>
        ) : null}

        {email ? (
          <dd className="text-accent-50 flex text-base">
            <EnvelopeIcon className="text-accent-200 h-6 w-6 flex-shrink-0" aria-hidden="true" />
            <span className="ml-3">{email}</span>
          </dd>
        ) : null}
      </dl>
    </div>
  );
};

const ContactForm = ({
  title,
  successMessage,
  successLongMessage,
  emailBodyPrefix,
  emailSubject,
  recipient,
  initialValues = {},
  autoFocus = false,
  className = 'py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12',
  buttonClassName = '',
  type = 'splitBrandPanel',
}) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');

  const onSubmit = data => {
    if (data.key) {
      console.error('Key must be empty to submit this form, you are a bot');
      return;
    }
    setLoading(true);

    const { firstName, lastName, subject, message, phone, email } = data;
    const name = `${firstName} ${lastName}`;
    const nameLi = `<li>Name: <b>${name}</b></li>`;
    const emailLi = `<li>Email: <b>${email}</b></li>`;
    const messageLi = `<li>Message: <b>${message}</b></li>`;
    const bodyPrefix = emailBodyPrefix ? `${emailBodyPrefix} <br/><br/>` : '';
    const subjectMaybe = subject ? `<li>Subject: <b>${subject}</b></li>` : '';
    const phoneMaybe = phone ? `<li>Phone: <b>${phone}</b></li>` : '';
    const bodyContent = `<ul>${nameLi}${subjectMaybe}${phoneMaybe}${emailLi}${messageLi}</ul>`;
    const body = {
      recipient,
      replyTo: data.email,
      subject: emailSubject || data.subject,
      body: `${bodyPrefix}${bodyContent}`,
    };

    communications.email
      .send(body)
      .then(() => {
        setLoading(false);
        setTransactionStatus('success');
      })
      .catch(() => {
        setLoading(false);
        setTransactionStatus('error');
      });
  };

  if (transactionStatus === 'success') {
    return (
      <div className={className}>
        <div>
          <div className="bg-success-100 mx-auto flex h-12 w-12 items-center justify-center rounded-full">
            <CheckIcon className="text-success-600 h-6 w-6" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{successMessage || 'Thank you for your submission.'}</h3>
            {successLongMessage ? (
              <div className="mt-2">
                <p className="text-sm text-gray-500">{successLongMessage}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  if (transactionStatus === 'error') {
    return (
      <div className={className}>
        <h3 className="text-lg font-medium text-gray-900">There was an error submitting your message.</h3>
      </div>
    );
  }

  return (
    <div className={`relative ${className}`}>
      {loading ? (
        <div className="z-1 absolute inset-0 flex flex-col items-center justify-center bg-gray-100 bg-opacity-60">
          <IconSpinner type="tailwind" />
        </div>
      ) : null}

      {type === 'splitBrandPanel' ? <h3 className="text-lg font-medium text-gray-900">{title || 'Send us a message'}</h3> : null}

      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
            First name
          </label>
          <div className="mt-1">
            <input
              {...register('firstName', { required: true })}
              type="text"
              name="firstName"
              id="firstName"
              required
              autoFocus={autoFocus}
              autoComplete="given-name"
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
            Last name
          </label>
          <div className="mt-1">
            <input
              {...register('lastName', { required: true })}
              type="text"
              name="lastName"
              id="lastName"
              required
              autoComplete="family-name"
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-900">
            Email
          </label>
          <div className="mt-1">
            <input
              {...register('email', { value: initialValues?.email, required: true })}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
              Phone
            </label>
            <span id="phone-optional" className="text-sm text-gray-500">
              Optional
            </span>
          </div>
          <div className="mt-1">
            <input
              {...register('phone', { value: initialValues?.phone })}
              type="text"
              name="phone"
              id="phone"
              autoComplete="tel"
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
              aria-describedby="phone-optional"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
            Subject
          </label>
          <div className="mt-1">
            <input
              {...register('subject', { value: initialValues?.subject })}
              type="text"
              name="subject"
              id="subject"
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label htmlFor="message" className="block text-sm font-medium text-gray-900">
              Message
            </label>
            <span id="message-max" className="text-sm text-gray-500">
              Max. 500 characters
            </span>
          </div>
          <div className="mt-1">
            <textarea
              {...register('message', { value: initialValues?.message })}
              id="message"
              name="message"
              rows={4}
              className="focus:border-accent-500 focus:ring-accent-500 block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
              aria-describedby="message-max"
              defaultValue={''}
            />
          </div>
        </div>

        <input {...register('key')} id="key" name="key" type="text" className="hidden" />

        <div className="mt-2 sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className={`${buttonClassName} bg-accent-600 hover:bg-accent-700 focus:ring-accent-500 inline-flex w-full items-center justify-center rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const UiContactForm = params => {
  const { history, autoFocus, hideSidebar, contactUiType: type = 'splitBrandPanel', metadata } = params || {};
  const { location } = history || {};
  const { search } = location || {};
  const { email, message, subject, phone } = parse(search || {});
  const initialValues = {
    email,
    message,
    subject,
    phone,
  };

  const shouldRenderSidebar =
    type === 'splitBrandPanel' && (params.email || params.phone || params?.metadata?.description) && !hideSidebar;
  const shouldTextHeader = type === 'centered';

  console.log(metadata);
  const defaultClassNames = {
    container: 'mx-auto max-w-7xl px-container',
  };
  const classNamesByType = {
    splitBrandPanel: {
      formContainer: 'bg-white shadow-xl',
      formWrapper: shouldRenderSidebar ? 'grid grid-cols-1 lg:grid-cols-3' : '',
      formContent: 'py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12',
    },
    centered: {
      formContainer: 'max-w-xl mx-auto',
      formWrapper: '',
      formContent: '',
      formButton: 'flex-1',
    },
  };
  const classNamesForType = classNamesByType[type] || classNamesByType['splitBrandPanel'];
  const cn = {
    ...defaultClassNames,
    ...classNamesForType,
  };

  return (
    <div>
      <div className={cn.container}>
        <div className={`relative ${cn.formContainer}`}>
          <div className={cn.formWrapper}>
            {shouldRenderSidebar ? <ContactInformation email={params.email} phone={params.phone} {...params.metadata} /> : null}
            {shouldTextHeader ? <UiTextSection {...metadata} className="mb-12" defaultAlignClassName="text-center" /> : null}

            <ContactForm
              {...params.form}
              className={cn.formContent}
              buttonClassName={cn.formButton}
              type={type}
              autoFocus={autoFocus}
              initialValues={initialValues}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiContactForm;
