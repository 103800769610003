/**
 * Error logging
 *
 * Can be used to log errors to console or and eternal
 * error logging system, like Sentry for example.
 *
 */
import config from '@/config';

/**
 * Set up error handling. If a Sentry DSN is
 * provided a Sentry client will be installed.
 */
export const setup = () => {
  if (config.sentryDsn && config.sentryDsn !== 'disabled' && window.Sentry) {
    window.Sentry.init({
      release: config.version,
      environment: config.env,
    });
  }
};

/**
 * Set user ID for the logger so that it
 * can be attached to Sentry issues.
 *
 * @param {String} userId ID of current user
 */
export const setUserId = userId => {
  const Sentry = window.Sentry;
  if (!Sentry) return;

  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });
  });
};

/**
 * Clears the user ID.
 */

export const clearUserId = () => {
  const Sentry = window.Sentry;
  if (!Sentry) return;

  Sentry.configureScope(scope => {
    scope.setUser(null);
  });
};

/**
 * Logs an execption. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} code Error code
 * @param {Object} data Additional data to be sent to Sentry
 */
export const error = (e, code, data = {}) => {
  const Sentry = window.Sentry;
  if (!Sentry) return;

  if (!window.haveForcedSentryToLoad) {
    window.haveForcedSentryToLoad = true;
    Sentry.forceLoad();
  }

  if (config.sentryDsn && config.sentryDsn !== 'disabled') {
    let err = e;
    const extra = { ...data };

    const isError = err instanceof Error;
    if (!isError && e && e.error && e.error.message) {
      err = new Error(e.error.message);
    }
    Sentry.withScope(scope => {
      scope.setTag('code', code);
      Object.keys(extra).forEach(key => {
        scope.setExtra(key, extra[key]);
      });
      Sentry.captureException(err);
    });
  } else {
    console.error(e);
    console.error('Error code:', code, 'data:', data);
  }
};
