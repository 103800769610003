import { stringify } from '@/util/urlHelpers';

import { transactions } from './';
import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/listings' : 'listings';
};

const create = body => {
  return client('listings', { body });
};

const update = body => {
  const id = body.id;
  delete body.id;
  return client(`listings/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`listings/${id}`, { method: 'DELETE' });
};

const read = (id, params = { expand: 'provider' }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}/${id}?${stringify(params)}`);
};

const hasPurchased = ({ customer, listing }) => {
  return new Promise(function (resolve, reject) {
    transactions
      .list({
        customer,
        listing,
        status: 'in:approved,completed,success',
        paymentStatus: 'in:success,notApplicable',
      })
      .then(response => {
        const transactions = response.data || [];
        resolve(transactions.length > 0);
      })
      .catch(() => reject(false));
  });
};

const listContent = id => {
  return client(`listings/${id}/contents`);
};

const createContent = (id, body) => {
  return client(`listings/${id}/contents`, { body });
};

const updateContent = (id, contentId, body) => {
  return client(`listings/${id}/contents/${contentId}`, { body, method: 'PUT' });
};

const deleteContent = (id, contentId) => {
  return client(`listings/${id}/contents/${contentId}`, { method: 'DELETE' });
};

const readContentAccess = ({ listingId, contentId, queryParams = { type: 'video' } }) => {
  return client(`listings/${listingId}/contents/${contentId}/playback-access?${stringify(queryParams)}`);
};

const getContnetThumbnail = ({
  playbackPolicy = 'signed',
  listingId,
  playbackId,
  contentId,
  width = 200,
  height = 200,
  smart_crop = true,
  time = 1,
}) => {
  const urlQueryParams = {
    width,
    height,
    smart_crop,
    time,
  };
  if (playbackPolicy === 'public' && playbackId) {
    return Promise.resolve(`https://image.mux.com/${playbackId}/thumbnail.jpg?${stringify(urlQueryParams)}`);
  }

  return readContentAccess({
    listingId,
    contentId,
    queryParams: {
      type: 'thumbnail',
      ...urlQueryParams,
    },
  }).then(response => {
    const { token, playbackId } = response.data;
    return `https://image.mux.com/${playbackId}/thumbnail.jpg?token=${token}`;
  });
};

const list = (params = { limit: 20 }, { search = false } = {}) => {
  let baseUrl = computeBaseUrl(params);
  if (search) baseUrl = `${baseUrl}/search`;
  return client(`${baseUrl}?${stringify(params)}`);
};

export {
  create,
  read,
  list,
  update,
  remove,
  listContent,
  createContent,
  updateContent,
  deleteContent,
  readContentAccess,
  getContnetThumbnail,
  hasPurchased,
};
