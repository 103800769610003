import { track } from '@/analytics/track';

export const signedIn = ({ username }) => {
  track('account.signedIn', { username });
};

export const signedOut = () => {
  track('account.signedOut');
  window.segmentAccountIdentified = false;
};

export const signedUp = ({ firstName, lastName, email, type, id }) => {
  track('account.signedUp', {
    first_name: firstName,
    last_name: lastName,
    email: email,
    username: email,
    type: 'organic',
    account_type: type,
    account_id: id,
  });
};

export const created = ({ name }) => {
  track('account.created', {
    account_name: name,
  });
};
