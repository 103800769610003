import get from 'lodash/get';
import unionWith from 'lodash/unionWith';
import moment from 'moment';

import { accounts, listings } from '@/clients';

import { track } from '@/analytics';
import { convertUnitToSubUnit, unitDivisor } from '@/util/currency';
import { storableError } from '@/util/errors';
import { getFetchPaginaionParams, getMarketplace, getSearchDataType, getSetPaginationParams } from '@/util/helpers';

import config from '@/config';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';
export const SEARCH_MAP_CLEAR_LISTINGS = 'app/SearchPage/SEARCH_MAP_CLEAR_LISTINGS';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  listings: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: payload.hits,
        pagination: payload.pagination,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.warn(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_CLEAR_LISTINGS:
      return { ...state, listings: [], searchInProgress: true };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: response,
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchClearListings = () => ({
  type: SEARCH_MAP_CLEAR_LISTINGS,
});

const trackSearch = (hits, filters, sorts, pagination) => {
  const shouldTrackFilters = (filters && filters.length) || (sorts && sorts.length);
  const paginationParams = {
    total_result_count: pagination.totalItems,
    total_items_pages: pagination.totalPages,
    items_per_page: pagination.perPage,
    current_item_page: pagination.page,
  };

  if (shouldTrackFilters) {
    track.product.listFiltered({ hits, filters, sorts, ...paginationParams });
  } else {
    track.product.listViewed({ hits, ...paginationParams });
  }
};

const parseSearchResponse = (hits, dataType, queryParams) => {
  hits = hits.map(hit => {
    return {
      ...hit,
      geolocation: get(hit, 'address.location') || get(hit, 'metadata.locations[0].address.selectedPlace.origin'),
    };
  });

  // Clean up the data and move any items without bg images or avatars to the bottom
  // this is easier than ranking on the backend for now
  if (dataType === 'providers' && hits.length > 1 && !queryParams.keyword && !queryParams.sort) {
    const items = [...hits];
    for (let index = 0; index < items.length; index++) {
      const hit = items[index];
      const hasCoverImage = get(hit, 'metadata.coverImages.length') > 0;
      const hasAvatar = !!get(hit, 'avatar');
      const hasLogo = !!get(hit, 'metadata.logo');
      const missingWantedData = !hasCoverImage || (!hasAvatar && !hasLogo);
      if (missingWantedData) {
        hits.push(hits.splice(index, 1)[0]);
      }
    }
  }

  return hits;
};

const searchElastic = params => {
  const {
    keywords,
    categories,
    dispatch,
    sort,
    price,
    geoLocation,
    fields,
    type,
    gender,
    maxAge,
    minAge,
    page,
    perPage,
    verifiedListingsOnly,
    searchDataType,
    days,
    timeOfDay,
    dates,
  } = params;

  const paginationParams = getFetchPaginaionParams({ perPage, page });
  const queryParams = {
    status: 'active',
    visibility: 'public',
    dataSource: 'elastic-search',
    fields,
    ...paginationParams,
  };
  const providerQueryParams = {
    status: 'active',
    dataSource: 'elastic-search',
    fields: [
      'id',
      'status',
      'type',
      'title',
      'name',
      'description',
      'slug',
      'avatar',
      'visibility',
      'address',
      'metadata',
      'geoLocations',
      'displayName',
      'categories',
    ].join(','),
    visibility: 'public',
    verificationTypes: 'containsAny:email',
    type: 'provider',
    ...paginationParams,
  };

  // Sent to segment
  const analyticsFilters = [];
  const analyticsSorts = [];

  const searchSettings = getMarketplace()?.pageConfig?.search;
  const { availabilityNotApplicableFilterBehavior, availabilityDefault } = searchSettings || {};

  let hasAvailabilityQuery = days || dates || timeOfDay;
  if (days) queryParams._daysOfWeek = days;
  if (dates) queryParams._dates = dates;
  if (timeOfDay) queryParams._timeOfDay = timeOfDay;

  if (hasAvailabilityQuery && availabilityNotApplicableFilterBehavior !== 'hidden') {
    queryParams._showNotApplicableAvailability = true;
  }

  if (!hasAvailabilityQuery && availabilityDefault === 'availableOnly') {
    queryParams._dates = moment().utc().format('YYYYMMDDHHmm');
    queryParams._showNotApplicableAvailability = true;
    hasAvailabilityQuery = true;
  }

  // categories
  if (categories && categories.length) {
    queryParams.categories = `containsAny:${categories.join(',')}`;
    providerQueryParams.categories = `containsAny:${categories.join(',')}`;

    analyticsFilters.push({
      type: 'categories',
      value: categories,
    });
  }

  // Gender
  if (gender) {
    const value = `containsAny:${gender}`;
    queryParams['suitability.gender'] = value;
    analyticsFilters.push({
      type: 'gender',
      value: gender,
    });
  }

  // Sorting
  if (sort) {
    queryParams.sort = sort;
    providerQueryParams.sort = sort;

    let value = 'desc';
    let type;
    if (sort.includes('asc')) {
      value = 'asc';
      type = sort.substring(4);
    } else {
      value = 'desc';
      type = sort.substring(5);
    }
    analyticsSorts.push({ type, value });
  }

  // Price
  if (price) {
    queryParams.basePrice = `between:${price}`;
    analyticsFilters.push({ type: 'price', value: price });
  }

  // Bounds
  if (geoLocation) {
    queryParams.geoLocation = geoLocation;
    providerQueryParams.geoLocations = geoLocation;
    analyticsFilters.push({ type: 'geoLocation', value: geoLocation });
  }

  // Keywords
  if (keywords) {
    queryParams.keyword = keywords;
    providerQueryParams.keyword = keywords;
    analyticsFilters.push({ type: 'keyword', value: keywords });
  }

  // Type
  if (type) {
    queryParams.type = `containsAny:${type}`;
    analyticsFilters.push({ type: 'type', value: type });
  }

  // verifiedListingsOnly
  if (verifiedListingsOnly) queryParams.verificationTypes = `containsAll:verified`;

  // Age
  if (maxAge && minAge) {
    if (minAge !== 'lessThan') {
      queryParams['suitability.minAge'] = `between:${minAge},${maxAge}`;
      analyticsFilters.push({ type: 'minAge', value: `${minAge},${maxAge}` });
    }
    if (minAge !== 'greaterThan') {
      queryParams['suitability.maxAge'] = `between:${minAge},${maxAge}`;
      analyticsFilters.push({ type: 'maxAge', value: `${minAge},${maxAge}` });
    }
  }

  if (searchDataType === 'providers') {
    return accounts
      .list(providerQueryParams)
      .then(response => {
        let { data: hits, metadata } = response;
        const pagination = getSetPaginationParams({ perPage, totalItems: metadata.total, page });
        if (hits && hits.length) {
          hits = hits.map(hit => {
            return {
              ...hit,
              geolocation: get(hit, 'address.location') || get(hit, 'metadata.locations[0].address.selectedPlace.origin'),
            };
          });
          hits = parseSearchResponse(hits, searchDataType, providerQueryParams);
          trackSearch(hits, analyticsFilters, analyticsSorts, pagination);
        }
        dispatch(searchListingsSuccess({ hits, pagination }));
        return hits;
      })
      .catch(err => {
        dispatch(searchListingsError(storableError(err)));
        throw err;
      });
  }

  return listings
    .list(queryParams, {
      search: true,
    })
    .then(response => {
      let { data: hits, metadata } = response;
      const pagination = getSetPaginationParams({ perPage, totalItems: metadata.total, page });
      if (hits && hits.length) {
        hits = parseSearchResponse(hits, searchDataType, queryParams);
        trackSearch(hits, analyticsFilters, analyticsSorts, pagination);
      }
      dispatch(searchListingsSuccess({ hits, pagination }));
      return hits;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const searchListingFields = [
  'id',
  'status',
  'type',
  'subType',
  'title',
  'description',
  'slug',
  'avatar',
  'assets',
  'visibility',
  'address',
  'categories',
  'tags',
  'basePrice',
  'baseCurrency',
  'targetAmount',
  'suitability',
  'metadata',
];

export const searchListings = searchParams => (dispatch, getState) => {
  dispatch(searchListingsRequest(searchParams));

  const searchDataType = getSearchDataType();

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const availabilityParams = datesParam => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;

    // const minDurationMaybe =
    //   minDurationParam && Number.isInteger(minDurationParam) && hasDateValues ? { minDuration: minDurationParam } : {};
    if (!hasDateValues) {
      return {};
    }
    const endMoment = moment(endDate).utc();
    // What the user really wants is the end of the
    const start = moment(startDate).utc().format('YYYYMMDDHHmm');
    let end = endMoment.add(24, 'hours').format('YYYYMMDDHHmm');
    const dates = `${start},${end}`;
    return hasDateValues ? { dates } : {};
  };

  const { perPage, price, dates, minDuration, sort, type, gender, maxAge, minAge, page, days, timeOfDay, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = availabilityParams(dates, minDuration);

  const categories = [];
  const staticFilterKeys = [
    'perPage',
    'page',
    'bounds',
    'mapSearch',
    'sort',
    'price',
    'keywords',
    'type',
    'gender',
    'maxAge',
    'minAge',
    'days',
    'timeOfDay',
    'dates',
    'address',
  ];
  const searchParamEntries = Object.entries(searchParams);
  for (const searchParam of searchParamEntries) {
    const key = searchParam[0];
    const value = searchParam[1];
    if (staticFilterKeys.indexOf(key) === -1) {
      categories.push(value);
    }
  }

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
  };

  const { bounds, keywords = '' } = params;

  const fields = searchListingFields;

  const algoliaParams = {};
  const hasBounds = bounds && bounds.ne && bounds.sw;
  if (hasBounds) {
    algoliaParams.insideBoundingBox = [[bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng]];
  }

  // verifiedListingsOnly
  const { applicationConfig } = getState().Marketplace || {};
  let verifiedListingsOnly = false;
  try {
    verifiedListingsOnly = applicationConfig.listing.displayCondition.verificationTypes.includes('verified');
  } catch (error) {
    console.warn('Unable to lookup verifiedListingsOnly', error);
  }

  return searchElastic({
    searchDataType,
    keywords,
    sort,
    categories,
    price,
    dispatch,
    type,
    fields: fields.join(','),
    gender,
    maxAge,
    minAge,
    geoLocation: hasBounds ? `withInBounds:${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}` : null,
    page,
    perPage,
    verifiedListingsOnly,
    days,
    timeOfDay,
    ...datesMaybe,
  });

  // only query string
  // return searchAlgolia({
  //   keywords,
  //   algoliaParams,
  //   dispatch,
  // });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
