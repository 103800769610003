import { useSelector } from 'react-redux';

import SplitMedia from '@marketplace-co/react-marketing-section-feature-split-media';

import { getMediaProps } from '@/util/helpers';

import UiFeatureContent from './UiFeatureContent';

const UiMarketingFeatureSplitMedia = ({ config }) => {
  const {
    ui,
    description,
    title,
    features: initialFeatures,
    titleClassNames = {},
    descriptionClassNames = {},
    aspectRatioClassName,
    useImageAspectRatio = false,
    media,
    category,
    categoryClassNames,
    buttonActive,
    buttonText,
    buttonHref,
    reverseDesktop,
    reverseMobile,
    splitRatio,
    gapXClassName,
    gapYClassName,
    classNames,
    contentJustifyClassName,
    mediaJustifyClassName,
  } = config || {};
  const colorMode = useSelector(state => state?.UI?.colorMode);
  const { colorTheme = 'primary' } = ui?.feature || {};
  const { borderRadius: borderRadiusClassName, shadow: shadowClassName, bgColor: bgColorClassName } = classNames || {};

  const mediaContentClassNames = {
    bgColor: 'bg-white-background',
  };

  if (bgColorClassName) {
    mediaContentClassNames.bgColor = bgColorClassName;
  }

  let mediaAspectRatio = '16/9';
  if (useImageAspectRatio) {
    mediaAspectRatio = 'image';
  } else if (aspectRatioClassName) {
    mediaAspectRatio = '';
    mediaContentClassNames.aspectRatio = aspectRatioClassName;
  }

  if (borderRadiusClassName) {
    mediaContentClassNames.borderRadius = borderRadiusClassName;
  }
  if (shadowClassName) {
    mediaContentClassNames.shadow = shadowClassName;
  }

  const gridClassNames = {};
  if (gapXClassName) {
    gridClassNames.gapX = gapXClassName;
  }
  if (gapYClassName) {
    gridClassNames.gapY = gapYClassName;
  }

  const contentClassNames = {};
  if (contentJustifyClassName) {
    contentClassNames.justify = contentJustifyClassName;
  }

  const mediaClassNames = {};
  if (mediaJustifyClassName) {
    mediaClassNames.general = 'flex flex-col';
    mediaClassNames.justify = mediaJustifyClassName;
  }

  const splitMediaProps = {
    splitRatio,
    reverseMobile,
    reverseDesktop,
    media: getMediaProps(media, { colorMode }),
    mediaAspectRatio,
    classNames: {
      mediaContent: mediaContentClassNames,
      grid: gridClassNames,
      content: contentClassNames,
      media: mediaClassNames,
    },
  };

  // The existing CMS structure had a nested array of features.
  // The UiFeatureContent component expects a single array of features.
  // We assume that the first feature is the main feature.
  const features = initialFeatures?.[0]?.features || [];
  const uiFeatureContentProps = {
    title,
    features,
    description,
    category,
    categoryClassNames,
    titleClassNames,
    descriptionClassNames,
    buttonText: buttonActive ? buttonText : null,
    buttonHref,
    colorTheme,
  };

  // Allow the CMS to override this is the future
  const className = 'relative mx-auto max-w-container px-container';
  return (
    <SplitMedia {...splitMediaProps} className={className}>
      <UiFeatureContent {...uiFeatureContentProps} />
    </SplitMedia>
  );
};

export default UiMarketingFeatureSplitMedia;
