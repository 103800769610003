import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { XMarkIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

export default function Modal({
  rootClassName = '',
  className,
  children,
  open = false,
  setOpen,
  hideOverlay,
  closeButtonClassName = 'absolute z-10 top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8',
  wrapperClassName = 'flex min-h-screen text-center md:block md:px-2 lg:px-4',
  maxWidthClassName = 'md:max-w-2xl lg:max-w-4xl',
  contentClassName,
  extraContentClassName = '',
}) {
  className = className || 'flex text-base text-left transform transition w-full md:inline-block md:px-4 md:my-8 md:align-middle';
  contentClassName =
    contentClassName ||
    `${extraContentClassName} w-full relative min-h-[250px] block items-center bg-white md:rounded-xl px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8`;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={`fixed inset-0 z-[2000] overflow-y-auto ${rootClassName}`} onClose={setOpen}>
        <div className={wrapperClassName} style={{ fontSize: 0 }}>
          {!hideOverlay ? (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-200 dark:bg-opacity-75 md:block" />
            </Transition.Child>
          ) : null}

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className={`${className} ${maxWidthClassName}`}>
              <div className={contentClassName}>
                <button type="button" className={closeButtonClassName} onClick={() => setOpen(false)}>
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
