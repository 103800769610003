import Badge from '@/components/Badge/Badge';
import ButtonLight from '@/components/Button/ButtonLight';
import MenuButton from '@/components/MenuButton/MenuButton';

import { checkProAccess } from '@/util/helpers';
import { ChevronDownIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

const FormHeader = props => {
  const {
    className,
    buttonSize,
    learnMore,
    buttonTheme,
    noDefaultMargin = false,
    size,
    title,
    description,
    buttonText,
    buttonAction,
    proType = null,
    currentUser,
    dropdownOptions,
    history,
    noLineBreak = false,
    titleClassName = '',
    buttonProps,
  } = props;

  const isPro = proType !== null;
  const hasAccessToPro = isPro ? checkProAccess({ type: proType, currentUser }) : false;
  const renderDropdown = dropdownOptions && dropdownOptions.length;
  const wrapperClassName = !noLineBreak ? 'sm:flex sm:items-center sm:justify-between' : 'flex items-center justify-between';
  const buttonWrapperClassName = !noLineBreak ? 'mt-4 flex sm:mt-0 sm:ml-4' : 'flex ml-4';

  const defaultTitleClasses =
    size === 'sm' ? 'text-sm font-medium leading-5 text-gray-700' : 'text-lg leading-6 text-gray-900 font-semibold';
  const titleClasses = titleClassName || defaultTitleClasses;
  return (
    <div className={`${className} ${noDefaultMargin ? '' : 'mb-4'}`}>
      <div className={wrapperClassName}>
        <div className="flex-1">
          <div className={titleClasses}>
            {title} {isPro ? <Badge color="primary" label="pro" /> : null}
          </div>

          {description ? (
            <div className={`${size === 'sm' ? 'text-xs' : 'mt-1 text-sm'}  leading-5 text-gray-500`}>
              {description}
              {learnMore ? (
                <a
                  href={learnMore}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary ml-2 text-gray-600 underline hover:underline"
                >
                  Learn More
                </a>
              ) : null}
            </div>
          ) : null}
        </div>

        {renderDropdown || buttonText || (isPro && !hasAccessToPro) ? (
          <div className={`${buttonWrapperClassName} space-x-3`}>
            {renderDropdown ? (
              <MenuButton
                widthClassName="w-64"
                onSelect={data => {
                  const { href, onClick } = data || {};
                  if (onClick) return onClick();
                  if (!href || !history) return;
                  history.push(href);
                }}
                noCheckmark
                options={dropdownOptions}
                label={<ChevronDownIcon className="h-5 w-5" />}
                buttonPaddingClassName="p-2"
              />
            ) : null}

            {buttonText ? (
              <ButtonLight theme={buttonTheme} size={buttonSize} onClick={buttonAction} {...buttonProps}>
                {buttonText}
              </ButtonLight>
            ) : null}

            {isPro && !hasAccessToPro ? (
              <ButtonLight
                onClick={() => {
                  window.open('/account/pro');
                }}
                theme="secondary"
              >
                Upgrade to Pro
              </ButtonLight>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormHeader;
