import { useState } from 'react';

import { string } from 'prop-types';

import ButtonLight from '@/components/Button/ButtonLight';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

import { computeDynamicHref, generateImageKitUrl } from '@/util/helpers';
import { PlayIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

const SectionLandingFeature = props => {
  const {
    title,
    description,
    className,
    buttonText,
    buttonHref,
    buttonHrefMetadata = {},
    src,
    category,
    vertical,
    videoIcon,
    videoId,
    imageId,
    // titleSize,
    reverse = false,
    noMaxWidth = true,
    titleClassNames = {},
    fullWidthMedia,
  } = props;
  const [videoActive, setVideoActive] = useState(false);

  const transformationType = vertical ? 'lg' : 'md';
  const imageSrc = imageId ? generateImageKitUrl({ id: imageId, transformationType }) : src;

  let maxWidthWrapperClassName = vertical ? 'max-w-screen-xl mx-auto' : 'mx-auto sm:max-w-3xl lg:max-w-7xl';
  let pxWrapperClassName = 'px-container';
  let mediaRoundedClassName = 'rounded-lg';
  let textPxClassName = '';

  if (fullWidthMedia) {
    maxWidthWrapperClassName = '';
    pxWrapperClassName = '';
    mediaRoundedClassName = '';
    textPxClassName = 'px-container';
  }

  const displayClassName = !vertical ? 'lg:grid' : 'block';
  const textAlignClassName = vertical ? 'sm:text-center' : '';
  const titleMaxWidthClassName = !vertical ? (!noMaxWidth ? 'max-w-xl' : '') : 'max-w-3xl mx-auto';
  const descriptionMaxWidthClassName = !vertical ? (!noMaxWidth ? 'max-w-md' : '') : 'max-w-xl mx-auto';
  const videoIconMaybe = videoIcon ? (
    <PlayIcon className="absolute top-0 left-0 bottom-0 right-0 z-10 m-auto h-14 w-14 text-white sm:h-28 sm:w-28" />
  ) : null;

  const handleClick = () => {
    // There is no video so we can ignore the click
    if (!videoIcon) return;
    setVideoActive(true);
  };

  const textContent = (
    <div className={`relative ${textAlignClassName} ${textPxClassName}`}>
      {category ? (
        <p className="mb-2 text-base font-semibold uppercase leading-6 tracking-wide text-gray-500">{category}</p>
      ) : null}
      <UiTextRenderer
        text={title}
        className={titleMaxWidthClassName}
        classNames={titleClassNames}
        defaultFontWeightClassName="font-bold"
        defaultFontSizeClassName="text-3xl sm:text-4xl md:text-5xl"
        defaultFontFamilyClassName="font-header"
        defaultFontColorClassName="text-header"
      />

      <p className={`mt-4 text-lg sm:text-2xl sm:leading-9 ${descriptionMaxWidthClassName} leading-7 text-gray-500`}>
        {description}
      </p>

      {buttonText ? (
        <ButtonLight
          href={computeDynamicHref({
            hyperlink: buttonHref,
            ...buttonHrefMetadata,
          })}
          externalLink={buttonHrefMetadata?.linkType === 'external'}
          className="mt-6"
          size="xl"
        >
          {buttonText}
        </ButtonLight>
      ) : null}
    </div>
  );

  const imgContent = (
    <div
      onClick={handleClick}
      className={`relative overflow-hidden ${mediaRoundedClassName} ${videoIcon ? 'cursor-pointer' : ''} ${
        vertical ? '' : '-mx-4 lg:mt-0'
      }`}
    >
      <svg
        className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
        width="784"
        height="404"
        fill="none"
        viewBox="0 0 784 404"
      >
        <defs>
          <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
      </svg>

      {videoActive ? (
        <div className="aspect-h-9 aspect-w-16 relative bg-black">
          <iframe
            title="Video"
            className={`absolute top-0 bottom-0 right-0 left-0 mx-auto block h-full ${mediaRoundedClassName} bg-black`}
            width={vertical ? '100%' : 490}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&loop=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <>
          {videoIconMaybe}
          <img
            style={{
              maxWidth: vertical ? '100%' : 'calc(100% - 2rem)',
            }}
            draggable="false"
            loading="lazy"
            className={`relative mx-auto block ${mediaRoundedClassName}`}
            width="100%"
            src={imageSrc}
            alt=""
          />
        </>
      )}
    </div>
  );

  return (
    <div className={`${className} ${maxWidthWrapperClassName} overflow-hidden ${pxWrapperClassName}`}>
      <div
        className={`relative mt-2 ${displayClassName} space-y-10 lg:grid-cols-2 lg:items-center lg:gap-14 ${
          !vertical ? 'lg:space-y-0' : ''
        }`}
      >
        {reverse ? imgContent : textContent}
        {reverse ? textContent : imgContent}
      </div>
    </div>
  );
};

SectionLandingFeature.defaultProps = { className: null };

SectionLandingFeature.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingFeature;
