import SectionDescriptionMaybe from '@/containers/ListingPage/SectionDescriptionMaybe';

import UiTextRenderer from './UiTextRenderer';

const UiParagraph = ({
  className = 'overflow-hidden',
  metadata,
  value,
  feature,
  preview = false,
  type,
  textSizeClassName = 'text-base',
  contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
}) => {
  const { title = 'About', titleClassNames = {}, bio } = metadata || {};

  let text = feature ? value : bio;
  if (!text && preview && bio) text = bio;
  if (!text) return null;

  return (
    <section className={className}>
      <div className={contentClassName}>
        <UiTextRenderer
          classNames={titleClassNames}
          className="mb-4"
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {title}
        </UiTextRenderer>

        <SectionDescriptionMaybe
          noTitle
          title=""
          textClassName={`mt-1 ${textSizeClassName} leading-7 text-gray-900`}
          description={text}
        />
      </div>
    </section>
  );
};

export default UiParagraph;
