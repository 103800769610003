import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';

import Topbar from '@/components/Topbar/Topbar';
import { TopbarMarketing } from '@/components/TopbarMarketing/TopbarMarketing';

import { authenticationInProgress, logout } from '@/ducks/Auth.duck';
import { manageDisableScrolling } from '@/ducks/UI.duck';
import { hasCurrentUserErrors, sendVerificationEmail } from '@/ducks/user.duck';

import { propTypes } from '@/util/types/propTypes';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentPath4,
    currentPath,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onMobileMenuOpen,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    marketplace: initialMarketplace = {},
    transparentHeader,
    largeDiscoverSearchActive,
    desktop,
    listing,
    isUnknownPath,
    colorMode,
    darkThemeForcedForRoute,
    darkThemeDisabledForRoute,
    ...rest
  } = props;

  let marketplace = initialMarketplace;
  const dark = marketplace?.branding?.colorModes?.dark;
  const darkDisabled = dark?.disabled;
  const darkForced = dark?.force;
  // TODO: Beind this to <Topbar />
  const hideThemeToggle = darkDisabled || darkForced || darkThemeForcedForRoute || darkThemeDisabledForRoute;

  const navigationType = marketplace?.pageConfig?.advanced?.navigationType;
  if (navigationType === 'customMarketingStacked') {
    const marketplaceTheme = marketplace?.branding?.theme || 'simple';
    const customMarketingStackedConfig = marketplace?.pageConfig?.advanced?.navConfig?.customMarketingStackedConfig || {};
    return (
      <TopbarMarketing
        transparent={transparentHeader && isUnknownPath}
        config={customMarketingStackedConfig}
        marketplaceTheme={marketplaceTheme}
        colorMode={colorMode}
        hideThemeToggle={hideThemeToggle}
      />
    );
  }

  return (
    <Topbar
      desktop={desktop}
      listing={listing}
      transparent={transparentHeader}
      largeDiscoverSearchActive={largeDiscoverSearchActive}
      marketplace={marketplace}
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentPath4={currentPath4}
      currentPath={currentPath}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserListing={currentUserListing}
      currentUserListingFetched={currentUserListingFetched}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      onMobileMenuOpen={onMobileMenuOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      isUnknownPath={isUnknownPath}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onMobileMenuOpen: func,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  const { transparentHeader, largeDiscoverSearchActive, colorMode, darkThemeForcedForRoute, darkThemeDisabledForRoute } =
    state.UI;
  const marketplace = state.Marketplace;

  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    marketplace,
    transparentHeader,
    largeDiscoverSearchActive,
    colorMode,
    darkThemeForcedForRoute,
    darkThemeDisabledForRoute,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) => dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TopbarContainerComponent);

export default TopbarContainer;
