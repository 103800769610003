import React from "react";
import PropTypes from "prop-types";

const defaultClassNames = {
  titleTextClasses: "text-3xl font-extrabold tracking-tight sm:text-4xl",
  descriptionTextClasses: "text-xl text-gray-500",
};

// https://tailwindui.com/components/marketing/sections/team-sections#component-47734c5175a01916ce046c9ad77b3aaf
function FullWidthWithVerticalImages({ people }) {
  if (!people?.length) return null;

  return (
    <div className="space-y-12">
      <ul
        role="list"
        className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
      >
        {people.map((person) => (
          <li key={person.name}>
            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
              <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                <img
                  className="object-cover shadow-lg rounded-lg"
                  src={person.image}
                  alt=""
                />
              </div>
              <div className="sm:col-span-2">
                <div className="space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>{person.title}</h3>
                    <p className="text-primary-600">{person.subTitle}</p>
                  </div>
                  <div className="text-lg">
                    <p className="text-gray-500">{person.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function WithImages({ title, description, people, type, classNames }) {
  let config = {
    listClassName:
      "space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0",
    liClassName: "sm:py-8",
    itemWrapperClassName:
      "space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0",
    itemAspectRatioClassName:
      "aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4",
    itemContentWrapperClassName: "sm:col-span-2",
  };

  if (type === "withImageAndShortParagraph") {
    config = {
      listClassName:
        "space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8",
      itemWrapperClassName: "space-y-4",
      itemAspectRatioClassName: "aspect-w-3 aspect-h-2",
    };
  }

  return (
    <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
      <div className="space-y-5 sm:space-y-4">
        <h2 className={classNames.titleTextClasses}>{title}</h2>
        <p className={classNames.descriptionTextClasses}>{description}</p>
      </div>
      <div className="lg:col-span-2">
        <ul role="list" className={config.listClassName}>
          {people.map((person) => (
            <li key={person.name} className={config.liClassName}>
              <div className={config.itemWrapperClassName}>
                <div className={config.itemAspectRatioClassName}>
                  <img
                    className="object-cover shadow-lg rounded-lg"
                    src={person.image}
                    alt=""
                  />
                </div>
                <div className={config.itemContentWrapperClassName}>
                  <div className="space-y-4">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.title}</h3>
                      <p className="text-primary-600">{person.subTitle}</p>
                    </div>
                    <div className="text-lg">
                      <p className="text-gray-500">{person.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default function MarketingSectionTeam({
  className,
  classNames: _classNames,
  people,
  title,
  description,
  type,
}) {
  const classNames = {
    ...defaultClassNames,
    ..._classNames,
  };

  let section = null;
  let renderDefaultHeader = true;
  switch (type) {
    case "withVerticalImages":
    case "withImageAndShortParagraph":
      renderDefaultHeader = false;
      section = (
        <WithImages
          classNames={classNames}
          title={title}
          description={description}
          people={people}
          type={type}
        />
      );
      break;
    case "fullWidthWithVerticalImages":
    default:
      section = <FullWidthWithVerticalImages people={people} />;
      break;
  }

  return (
    <div className={className}>
      {renderDefaultHeader ? (
        <div className="space-y-5 sm:space-y-4 mb-12">
          <h2 className={classNames.titleTextClasses}>{title}</h2>
          {description ? (
            <p className={classNames.descriptionTextClasses}>{description}</p>
          ) : null}
        </div>
      ) : null}

      {section}
    </div>
  );
}

MarketingSectionTeam.propTypes = {
  /**
   * The type of team section
   */
  type: PropTypes.oneOf([
    "fullWidthWithVerticalImages",
    "withVerticalImages",
    "withImageAndShortParagraph",
  ]),

  /**
   * The title of the section
   */
  title: PropTypes.string,

  /**
   * The description of the section
   */
  description: PropTypes.string,

  /**
   * The people to render
   */
  people: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,

  /**
   * The class name to apply to the component
   */
  className: PropTypes.string,

  /**
   * Control the class names of the markup in various states
   */
  classNames: PropTypes.shape({
    titleTextClasses: PropTypes.string,
    descriptionTextClasses: PropTypes.string,
  }),
};

MarketingSectionTeam.defaultProps = {
  className: "mx-auto py-12 max-w-container px-container lg:py-24",
  type: "fullWidthWithVerticalImages",
  items: [],
  classNames: defaultClassNames,
};

FullWidthWithVerticalImages.propTypes = {
  people: MarketingSectionTeam.propTypes.people,
};
WithImages.propTypes = {
  title: MarketingSectionTeam.propTypes.title,
  description: MarketingSectionTeam.propTypes.description,
  people: MarketingSectionTeam.propTypes.people,
  type: MarketingSectionTeam.propTypes.type,
  classNames: MarketingSectionTeam.propTypes.classNames,
};
