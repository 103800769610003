import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/playblocks' : 'playblocks';
};

const create = body => {
  return client('playblocks', { body });
};

const update = (id, body) => {
  return client(`playblocks/${id}`, { body, method: 'PUT' });
};

const list = (params = { limit: 50 }) => {
  return client(`playblocks?${stringify(params)}`);
};

const fields = [
  'id',
  'status',
  'type',
  'subType',
  'title',
  'description',
  'slug',
  'avatar',
  'assets',
  'visibility',
  'address',
  'tags',
  'basePrice',
  'baseCurrency',
  'targetAmount',
  'suitability',
  'metadata',
  'hyperLink',
].join(',');

const placeRecommendations = (params = {}) => {
  return client(`playblocks/place-recommendations?${stringify({ ...params, fields })}`);
};
const recommendations = (id, params = { limit: 50 }) => {
  return client(`playblocks/${id}/recommendations?${stringify({ ...params, fields })}`);
};

const read = (id, queryParams = { expand: 'contentFilter.categories.values' }) => {
  const baseUrl = computeBaseUrl(queryParams);
  return client(`${baseUrl}/${id}?${stringify(queryParams)}`);
};

const view = (id, queryParams = { fields }) => {
  return client(`playblocks/${id}/page-view?${stringify(queryParams)}`);
};

export { create, update, list, read, view, recommendations, placeRecommendations };
