import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/transactions' : 'transactions';
};

const list = (queryParams = { limit: 20, expand: 'provider' }) => {
  const baseUrl = computeBaseUrl(queryParams);
  return client(`${baseUrl}?${stringify(queryParams)}`);
};

const invoice = body => {
  return client('transactions/invoice/pdf', { body, responseType: 'blob' });
};

const read = (id, queryParams = { expand: 'provider,customer,listing' }) => {
  const baseUrl = computeBaseUrl(queryParams);
  return client(`${baseUrl}/${id}?${stringify(queryParams)}`);
};

const readGuest = token => {
  return client(`transactions/guest/${token}`);
};

const update = (id, body) => {
  return client(`transactions/${id}`, { body, method: 'PUT' });
};

const create = body => {
  return client('transactions', { body });
};

export { list, read, update, create, readGuest, invoice };
