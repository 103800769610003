import { Component } from 'react';

import * as log from '@/util/log';
import { ExclamationTriangleIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    log.error(error, 'ErrorBoundary-error', info);
  }

  render() {
    if (this.state.hasError && !this.props.ignoreErrors) {
      return (
        this.props.fallbackUi || (
          <div className="mx-auto flex h-screen w-screen max-w-2xl flex-col items-center justify-center">
            <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
              <div className="mb-6 flex flex-1 flex-row items-center justify-center">
                <div className="bg-primary-100 flex h-24 w-24 flex-shrink-0 items-center justify-center rounded-full">
                  <ExclamationTriangleIcon className="text-primary-600 h-12 w-12" />
                </div>
              </div>

              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">An Error Occurred</h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                Our support team has been notified of this issue, if you need to contact support you can do so{' '}
                <a target="_blank" href="/help" className="hover:text-primary text-gray-700 underline">
                  here
                </a>
                .
              </p>
              <div className="mt-8 flex justify-center">
                <div className="inline-flex rounded-md shadow">
                  <a
                    href="/"
                    className="bg-primary-600 hover:bg-primary-700 inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium text-white"
                  >
                    Home
                  </a>
                </div>
                <div className="ml-3 inline-flex">
                  <button
                    onClick={() => window.location.reload()}
                    className="bg-primary-100 text-primary-700 hover:bg-primary-200 inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium focus:outline-none"
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      );
    }

    return this.props.children;
  }
}
