import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { array, bool, object } from 'prop-types';
import { compose } from 'redux';
import useSWR from 'swr';

import PlayBlockPage from '@/containers/PlayBlockPage/PlayBlockPage';

import IconSpinner from '@/components/IconSpinner/IconSpinner';
import Page from '@/components/Page/Page';
import UiRenderer from '@/components/UiRenderer/UiRenderer';

import { playBlocks } from '@/clients';
import { isScrollingDisabled, setLargeSearchBar, setTransparentHeader } from '@/ducks/UI.duck';

import { injectIntl, intlShape } from '@/util/reactIntl';
import { propTypes } from '@/util/types/propTypes';

const fetchData = () => playBlocks.view('landingPage');
const useLandingPage = (marketplace, rootPlayBlock) => {
  const { data: response, error } = useSWR(!rootPlayBlock ? '/api/playblocks/landingPage' : null, fetchData);
  const data = response?.data;
  let sections = data?.sections;
  const errorMessage = error ? error?.error?.userMessage || 'An error occurred' : null;
  let isLoading = !error && !data;

  // When data is still loading, there is a possibility that the hero section is available in marketplace settings
  // and can be displayed right away. The hero is always static
  if (!data && isLoading && marketplace) {
    const heroSection = marketplace?.pageConfig?.landingPage?.sections.find(section => section?.metadata?.ui?.type === 'hero');
    if (heroSection) sections = [heroSection];
  }

  return {
    data: sections,
    isLoading: isLoading,
    error: errorMessage,
  };
};

const LandingPageComponent = props => {
  const {
    history,
    intl,
    scrollingDisabled,
    marketplace = {},
    onManageTransparentHeader,
    onManageLargeSearchBar,
    currentUser,
    currentUserBookmarkedListings,
  } = props;
  const microMarketplace = marketplace?.microMarketplace;
  const playblockId = microMarketplace?.rootPlayBlock || marketplace?.pageConfig?.landingPage?.rootPlayBlock;
  const { data, isLoading, error } = useLandingPage(marketplace, playblockId);
  if (playblockId) return <PlayBlockPage {...props} params={{ id: playblockId }} />;

  // SEO
  const { title: marketplaceTitle, pageConfig = {} } = marketplace || {};
  const { general = {} } = pageConfig || {};
  const { labels = {} } = general || {};
  const { seoTitle, seoDescription } = labels || {};
  const siteTitle = marketplaceTitle;
  const schemaTitleDeafult = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaTitle = seoTitle ? `${seoTitle} | ${siteTitle}` : schemaTitleDeafult;
  const schemaDescriptionDeafult = intl.formatMessage({ id: 'LandingPage.schemaDescription' }, { siteTitle });
  const schemaDescription = seoDescription || schemaDescriptionDeafult;

  // Page
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <UiRenderer
        error={error}
        data={data}
        // loading={isLoading}
        marketplace={marketplace}
        currentUser={currentUser}
        history={history}
        currentUserBookmarkedListings={currentUserBookmarkedListings}
        onManageLargeSearchBar={onManageLargeSearchBar}
        onManageTransparentHeader={onManageTransparentHeader}
        intl={intl}
      >
        {isLoading ? (
          <div className="flex flex-row items-center justify-center">
            <IconSpinner type="tailwind" />
          </div>
        ) : null}
      </UiRenderer>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUserBookmarkedListings: array,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser, currentUserBookmarkedListings } = state.user;
  const marketplace = state.Marketplace;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    marketplace,
    currentUser,
    currentUserBookmarkedListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageTransparentHeader: (params, options) => dispatch(setTransparentHeader(params, options)),
  onManageLargeSearchBar: params => dispatch(setLargeSearchBar(params)),
});

const LandingPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
