import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';

import { injectIntl, intlShape } from '@/util/reactIntl';
import { composeValidators, socialUsernameValid } from '@/util/validators';

export const labelPrefixLookup = {
  twitter: 'twitter.com/',
  facebook: 'facebook.com/',
  instagram: 'instagram.com/',
  linkedin: 'linkedin.com/',
  youtube: 'youtube.com/',
};

const FieldSocialInput = props => {
  const { className, name, type = 'twitter', label } = props;

  return (
    <FieldTextInput
      className={className}
      id={type}
      name={name}
      label={label}
      type="text"
      inputWrapperClassName="flex"
      roundedClassName="rounded-none rounded-r-md"
      childrenBeforeInput
      validate={composeValidators(socialUsernameValid(`Please enter a valid ${label} username`, true))}
    >
      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
        {labelPrefixLookup[type]}
      </span>
    </FieldTextInput>
  );
};

FieldSocialInput.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(FieldSocialInput);
