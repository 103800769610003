import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import startCase from 'lodash/startCase';

import PageHeader from '@/components/PageHeader/PageHeader';
import SearchFilterInput from '@/components/SearchFilterInput/SearchFilterInput';

import { label, placeResultToPlacePage } from '@/util/helpers';

import config from '@/config';

import PlaceListNearby from './PlaceListNearby';

interface Place {
  type: Mpc.place['type'] | undefined;
  country?: string;
  state?: string;
  city?: string;
  county?: string;
}

const SearchFilterInputComponent = ({
  placeholder = '',
  type = '',
  className = 'max-w-2xl',
  // @ts-ignore
  history,
  inputClassName = 'rounded-lg shadow-sm focus:ring-2 focus:ring-accent ring-2 ring-black dark:ring-gray-200 dark:hover:ring-gray-300 dark:focus:ring-accent ring-opacity-5 text-lg sm:text-lg transition',
}) => (
  <SearchFilterInput
    className={className}
    inputClassName={inputClassName}
    buttonTitle=""
    types={!type ? ['establishment'] : [type]}
    hidePredictions
    placeholder={placeholder}
    onSubmit={(values: any) => {
      console.log(values);
      const href = placeResultToPlacePage(values?.location?.selectedPlace, true);
      console.log(href);
      if (href) {
        history.push(href);
      }
    }}
  />
);

const usePlace = ({ type, country, state, city, googleMapsLoaded, county }: Place & { googleMapsLoaded?: boolean | any }) => {
  const [data, setData] = useState([{ href: '', label: '' }]);
  const [title, setTitle] = useState('Places');
  const [loading, setLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState<
    | {
        href: string;
        title: string;
      }[]
    | null
  >([]);

  const isCountryPage = type && !country;
  const isStatePage = country && !state;
  const isCountyPage = state && !county;
  const isCityPage = county && !city;
  const isCityResultsPage = city;

  const countries = [
    {
      key: 'us',
      label: 'United States',
      href: `/places/${type}/us`,
    },
  ];
  const states = [
    {
      label: 'California',
      href: `/places/${type}/us/ca`,
    },
  ];
  const counties = [
    {
      key: 'santa-barbara',
      label: 'Santa Barbara County',
      href: `/places/${type}/us/ca/santa-barbara-county`,
    },
  ];
  const cities = [
    {
      key: 'santa-barbara',
      label: 'Santa Barbara',
      href: `/places/${type}/us/ca/santa-barbara-county/santa-barbara`,
    },
  ];
  const places = [
    {
      key: 'laguna-blanca',
      label: 'Laguna Blanca High School',
      href: `/places/school/us/ca/santa-barbara-county/santa-barbara/laguna-blanca-high-school`,
    },
    {
      key: 'laguna-blanca-elementary',
      label: 'Laguna Blanca Elementary',
      href: `/places/school/us/ca/santa-barbara-county/montecito/laguna-blanca-elementary`,
    },
  ];

  const titles = { type: startCase(type), city: startCase(city), county: startCase(county) };
  // @ts-ignore
  const typeLabel = (key: Mpc.place['type'] | any) => config.places.types[key];
  const placeBreadcrumb = { title: 'Places', href: `/places` };
  const typeBreadcrumb = { title: typeLabel(type), href: `/places/${type}` };
  const countryBreadcrumb = { title: startCase(country).toUpperCase(), href: `/places/${type}/${country}` };
  const stateBreadcrumb = { title: startCase(state).toUpperCase(), href: `/places/${type}/${country}/${state}` };
  const countyBreadcrumb = { title: titles.county, href: `/places/${type}/${country}/${state}/${county}` };
  const cityBreadcrumb = { title: titles.city, href: `/places/${type}/${country}/${state}/${county}/${city}` };

  useEffect(() => {
    if (!isCountryPage && !isStatePage && !isCountyPage && !isCityPage && !isCityResultsPage) {
      setTitle('Select a place');
      setLoading(false);
      // @ts-ignore
      setData(Object.keys(config.places.types).map(key => ({ label: typeLabel(key), href: `/places/${key}` })));
      setBreadcrumbs([placeBreadcrumb]);
      return;
    }

    setLoading(true);
    if (!googleMapsLoaded) return;

    if (isCountryPage) {
      // @ts-ignore
      setTitle('Countries');
      setData(countries);
      setBreadcrumbs([placeBreadcrumb, typeBreadcrumb]);
    } else if (isStatePage) {
      setTitle('States');
      setData(states);
      setBreadcrumbs([placeBreadcrumb, typeBreadcrumb, countryBreadcrumb]);
    } else if (isCountyPage) {
      setTitle('Counties');
      setData(counties);
      setBreadcrumbs([placeBreadcrumb, typeBreadcrumb, countryBreadcrumb, stateBreadcrumb]);
    } else if (isCityPage) {
      setTitle('Cities');
      setData(cities);
      setBreadcrumbs([placeBreadcrumb, typeBreadcrumb, countryBreadcrumb, stateBreadcrumb, countyBreadcrumb]);
    } else {
      setTitle(`${typeLabel(type)} in ${titles.city}`);
      setData(places);
      setBreadcrumbs([placeBreadcrumb, typeBreadcrumb, countryBreadcrumb, stateBreadcrumb, countyBreadcrumb, cityBreadcrumb]);
    }

    setLoading(false);
  }, [isCountryPage, isStatePage, isCityPage, type, googleMapsLoaded]);

  return {
    title,
    data,
    loading,
    breadcrumbs,
  };
};

const tagClassName =
  'py-2 border border-gray-200 shadow-sm px-4 rounded-lg bg-white hover:bg-primary-50 text-gray-900 hover:text-primary-500';
const PlaceListPage = ({
  type,
  country,
  state,
  city,
  county,
  googleMapsLoaded,
}: Place & {
  googleMapsLoaded: boolean;
}) => {
  const {
    data,
    loading = false,
    breadcrumbs,
  } = usePlace({
    type,
    country,
    state,
    city,
    county,
    googleMapsLoaded,
  });

  const history = useHistory();

  return (
    <div className="px-container max-w-container mt-4 min-h-screen">
      <div className="mb-8 space-y-4">
        <PageHeader
          title={type ? `Find ${config.places.types[type]}` : 'Find Places'}
          description={type ? '' : `Find ${label('listings')} and ${label('providers')} near any place.`}
          breadcrumbsShowHome
          breadcrumbClassName="mb-3"
          breadcrumbs={breadcrumbs}
        />
        <SearchFilterInputComponent
          type={type}
          history={history}
          placeholder={`${type ? `Search for ${config.places.types[type]}...` : 'Search...'}`}
        />
      </div>

      {type && !country ? <PlaceListNearby type={type} googleMapsLoaded={googleMapsLoaded} className="mb-8" /> : null}

      {!type ? (
        <div className="flex flex-wrap gap-3">
          {loading ? (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(i => (
                <span key={i} className={tagClassName}>
                  <span className="block animate-pulse h-2 w-[80px] bg-gray-300 rounded"></span>
                </span>
              ))}
            </>
          ) : (
            data.map(item => (
              <Link className={tagClassName} to={item.href} key={item.href}>
                {item.label}
              </Link>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PlaceListPage;
