import PageHeader from '@/components/PageHeader/PageHeader';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import { DynamicLink } from '@/util/helpers';
import { AcademicCapIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const GridListActionsWithSharedBorders = ({
  actions,
  options = {},
  bgColorClassName = 'bg-gray-200',
  cardBgClassName = 'bg-white',
  title,
  description,
  iconBgClassName: initialIconBgClassName,
  iconTextClassName: initialIconTextClassName,
  titleClassName = 'text-lg font-medium',
  descriptionClassName = 'text-sm text-gray-500',
  divideColorClassName = 'divide-gray-200',
}) => {
  if (!actions || !actions.length) return null;
  const { showTitle, flat, threeCols = false, accentColor = false } = options || {};
  const iconBgClassName = initialIconBgClassName || (accentColor ? 'bg-accent-50' : 'bg-primary-50');
  const iconTextClassName = initialIconTextClassName || (accentColor ? 'text-accent-700' : 'text-primary-700');
  const focusWithinClassName = accentColor ? 'ring-accent-500' : 'ring-primary-500';

  const getClassNames = actionIdx => {
    if (threeCols) {
      return classNames(
        actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
        actionIdx === 1 ? 'sm:rounded-tr-lg xl:rounded-tr-none' : '',
        actionIdx === 2 ? 'xl:rounded-tr-lg' : '',
        actionIdx === actions.length - 3 ? 'xl:rounded-bl-lg' : '',
        actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg xl:rounded-bl-none' : '',
        actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
        `relative group ${cardBgClassName} p-6 focus-within:ring-2 focus-within:ring-inset ${focusWithinClassName} transition`
      );
    }

    return classNames(
      actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
      actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
      actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
      actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
      `relative group ${cardBgClassName} p-6 focus-within:ring-2 focus-within:ring-inset ${focusWithinClassName} transition`
    );
  };

  return (
    <>
      {showTitle ? <PageHeader description={description} minHeightClassName="" className="mb-4" title={title} /> : null}
      <div
        className={`rounded-lg ${bgColorClassName} overflow-hidden ${
          !flat ? 'shadow' : 'border border-gray-200'
        } divide-y ${divideColorClassName} sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 ${threeCols ? 'xl:grid-cols-3' : ''}`}
      >
        {actions.map((action, actionIdx) => {
          if (!action) return null;
          return (
            <div key={`${action.title}_${actionIdx}`} className={getClassNames(actionIdx)}>
              <div>
                <span className={classNames(iconBgClassName, iconTextClassName, 'inline-flex rounded-lg p-3 ring-4 ring-white')}>
                  {action.icon ? (
                    <SvgRenderer id={action.icon} className="h-6 w-6" />
                  ) : (
                    <AcademicCapIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </div>
              <div className="mt-8">
                <h3 className={titleClassName}>
                  <DynamicLink className="focus:outline-none" action={action}>
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </DynamicLink>
                </h3>
                <p className={`mt-2 ${descriptionClassName}`}>{action.description}</p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GridListActionsWithSharedBorders;
