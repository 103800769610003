import { string } from 'prop-types';

const SectionLandingFaq = props => {
  const { title, questions = [] } = props;
  return (
    <div className="bg-white-background">
      <div className="mx-auto max-w-7xl divide-y-2 divide-gray-200 py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="font-header text-header text-3xl font-bold leading-9 text-gray-900">{title}</h2>
        <div className="mt-6 pt-10">
          <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
            {questions.map((faq, index) => (
              <div key={`${faq.title}_${index}`}>
                <dt className="text-lg font-medium leading-6 text-gray-900">{faq.title}</dt>
                <dd className="mt-2 text-base text-gray-500">{faq.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

SectionLandingFaq.defaultProps = { className: null };

SectionLandingFaq.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingFaq;
