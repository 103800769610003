@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply __marketplaceButtonStylesSecondary;
  @apply __marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: rgb(var(--mpc-color-white-bg));
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply __marketplaceButtonStyles;
  @apply __marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  border: none;
}

.list {
  margin: 0;
}

.listPopup {
  margin: 0;
  padding: 0 1rem;
  margin: 0 -1rem;
  overflow-y: scroll;
  max-height: 300px;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
