import { Component, lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import NotFoundPage from '@/containers/NotFoundPage/NotFoundPage';
import Startup from '@/containers/Startup/Startup';

import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import IconSpinner from '@/components/IconSpinner/IconSpinner';
import NamedRedirect from '@/components/NamedRedirect/NamedRedirect';
import ProFeatureAlert from '@/components/ProFeatureAlert/ProFeatureAlert';

import { locationChanged } from './ducks/Routing.duck';

import * as log from './util/log';
import { checkProAccess, computeDynamicHref, isProOnlyRoute, routesToProTypes } from './util/helpers';
import { canonicalRoutePath } from './util/routes';
import { propTypes } from '@/util/types/propTypes';

import routeConfiguration from './routeConfiguration';

const CrmPage = lazy(() => import('./containers/CrmPage/CrmPage' /* webpackChunkName: "CrmPage" */));

let hasLoadedMarketPlaceData = false;
const { arrayOf, bool, object, func, shape, string } = PropTypes;

const canShowComponent = props => {
  const { isAuthenticated, route } = props;
  const { auth } = route;
  return !auth || isAuthenticated;
};

const canShowRoleProtectedComponent = props => {
  const { route, currentUser } = props;
  const { authRoles } = route;
  const { roles = [] } = currentUser || {};

  if (!authRoles || !authRoles.length) return true;

  return roles.findIndex(r => authRoles.includes(r)) > -1;
};

const canShowProComponent = props => {
  const { route, currentUser, marketplace } = props;
  const { proType: type } = route;

  return checkProAccess({ type, currentUser, marketplace });
};

const debouncedCallLoadData = debounce(props => {
  const { dispatch, loadData, match, location, name } = props;
  dispatch(loadData(match.params, location.search))
    .then(() => {
      // eslint-disable-next-line no-console
      // console.log(`loadData success for ${name} route`);
    })
    .catch(e => {
      log.error(e, 'load-data-failed', { routeName: name });
    });
}, 0);

const callLoadData = (props, lastUrl) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const { pathname } = location || {};
  const lastUrlSameAsPath = pathname === lastUrl && pathname === '/l';
  const shouldLoadData = typeof loadData === 'function' && canShowComponent(props) && !logoutInProgress && !lastUrlSameAsPath;
  if (!hasLoadedMarketPlaceData) {
    hasLoadedMarketPlaceData = true;
  }

  if (shouldLoadData) {
    debouncedCallLoadData({ dispatch, loadData, match, location, name });
  }
};

const setPageScrollPosition = location => {
  if (!location.hash) {
    // No hash, scroll to top & no search
    // TODO: come up with a solution to bypass this when doing pagination clicking
    // if (location.search) return;
    window.scroll({
      top: 0,
      left: 0,
    });

    const el = document.querySelector('#sidebarScrollContainer');
    if (el) el.scrollTop = 0;
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      // Found element with the given fragment identifier, scrolling
      // to that element.
      //
      // NOTE: This isn't foolproof. It works when navigating within
      // the application between pages and within a single page. It
      // also works with the initial page load. However, it doesn't
      // seem work work properly when refreshing the page, at least
      // not in Chrome.
      //
      // TODO: investigate why the scrolling fails on refresh
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

class RouteComponentRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastUrl: null,
    };
  }

  componentDidMount() {
    // Calling loadData on initial rendering (on client side).
    callLoadData(this.props);

    const {
      navigationHidden,
      setNavigationHidden,
      route: { hideNavigation },
    } = this.props;

    if ((hideNavigation && !navigationHidden) || (!hideNavigation && navigationHidden)) {
      setNavigationHidden(!navigationHidden);
    }
  }

  componentDidUpdate() {
    // Calling loadData after initial rendering (on client side).
    // This makes it possible to use loadData as default client side data loading technique.
    // However it is better to fetch data before location change to avoid "Loading data" state.
    callLoadData(this.props, this.state.lastUrl);
  }

  render() {
    const { route, match, location, staticContext, currentUser, marketplace, history } = this.props;
    const { component: RouteComponent, authPage = 'SignupPage', pro, proType } = route;

    // Check if this route required auth, and if user is authenticated
    const canShow = canShowComponent(this.props);
    if (!canShow) {
      staticContext.unauthorized = true;
      // Redirect the user to an auth page
      return <NamedRedirect name={authPage} state={{ from: `${location.pathname}${location.search}${location.hash}` }} />;
    }

    // Check if this route is a role restricted route, enforce if so
    const canShowIfRoleProtected = canShowRoleProtectedComponent(this.props);
    if (!canShowIfRoleProtected) {
      return <ProFeatureAlert history={history} currentUser={currentUser} type="role" marketplace={marketplace} />;
    }

    // Check if this route is a pro only route, enforce if so
    const isProRouteWithNoAccess = (pro || proType) && !canShowProComponent(this.props, marketplace);
    if (isProRouteWithNoAccess) {
      return (
        <ProFeatureAlert
          history={history}
          currentUser={currentUser}
          hidden={!currentUser}
          marketplace={marketplace}
          proType={proType}
        />
      );
    }

    // Return route if component can be viewed
    return <RouteComponent params={match.params} location={location} />;
  }
}

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  logoutInProgress: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  route: propTypes.route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  staticContext: object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: func.isRequired,
};

const Routes = props => {
  const {
    isAuthenticated,
    logoutInProgress,
    staticContext,
    dispatch,
    routes: _routes,
    currentUser,
    changeLocale,
    locale,
    marketplace,
    history,
    location,
  } = props;
  const [lastUrl, setLastUrl] = useState('');

  // Location Changed Manger
  useEffect(() => {
    if (!location) return;
    const url = canonicalRoutePath(routeConfiguration(), location);
    if (lastUrl !== location.pathname) {
      setLastUrl(location.pathname);
      setPageScrollPosition(location);
    }
    dispatch(locationChanged(location, url));
  }, [location]);

  const marketplaceOff = get(marketplace, 'pageConfig.advanced.marketplaceOff');
  const customNavActive = get(marketplace, 'pageConfig.advanced.customNavActive');
  const onlyShowLoginWhileUnauthenticated = get(marketplace, 'pageConfig.advanced.onlyShowLoginWhileUnauthenticated');
  const noSignupPage = get(marketplace, 'pageConfig.advanced.noSignupPage');
  const crm = get(marketplace, 'applicationConfig.settings.features.crm');
  const products = get(marketplace, 'applicationConfig.settings.features.products');
  const crmOnly = get(marketplace, 'pageConfig.crm.crmOnly');

  // Check if homepage is being loaded and marketplace is turned off

  let routes = _routes;
  const removeRoutes = names => routes.filter(r => !names.includes(r.name));

  // remove the landing page route when marketplace is turned off
  if (marketplaceOff === true) {
    routes = removeRoutes(['SearchPage']);
    // remove root about page link when logged in
    if (isAuthenticated) {
      routes = removeRoutes(['LandingPage', 'AboutPage', 'SavedPage']);
    }
  }

  if (marketplace?.microMarketplace?.id && !marketplace?.microMarketplace?.copy) {
    routes = routes.filter(r => r.microMarketplace !== false);
  }

  if (!products) {
    routes = removeRoutes([
      'ManageProductsPage',
      'ManageProductsTabPage',
      'NewProductPage',
      'EditProductPage',
      'EditProductPageNew',
    ]);
  }

  if (crm && crmOnly) {
    const pagesToKeep = [
      'LoginPage',
      'CmsBasePage',
      'CmsSettingsBasePage',
      'CmsSettingsPage',
      'CmsPage',
      'CrmPage',
      'CrmTabPage',
      'CrmDetailPage',
      'PasswordRecoveryPage',
    ];
    const keptPages = routes.filter(r => pagesToKeep.includes(r.name));
    routes = [
      {
        path: '/',
        name: 'CrmRootPage',
        hideNavigation: true,
        authRoles: ['marketplaceAdmin', 'crmUser'],
        component: props => <CrmPage {...props} />,
      },
      ...keptPages,
    ];
  }

  if (onlyShowLoginWhileUnauthenticated) {
    routes = routes.map(r => {
      if (r.path === '/login' || r.path === '/recover-password') return r;
      return { ...r, auth: true, authPage: 'LoginPage' };
    });
  }

  if (noSignupPage) {
    routes = removeRoutes(['SignupPage']);
  }

  // When custom nav is being linked we should assume an admin might link to a pro feature like forms, contacts, or calendar
  // These features should be opened up to have public access if there is also a playblock empty state
  if (customNavActive === true) {
    try {
      const customNavConfig = get(marketplace, 'pageConfig.advanced.customNavConfig');
      if (customNavConfig.customNav) {
        const customNavProRoutes = customNavConfig.customNav
          .map(i => ({ ...i, href: computeDynamicHref(i) }))
          .filter(i => isProOnlyRoute(null, null, i.href));
        if (customNavProRoutes?.length) {
          const customNavProTypes = customNavProRoutes.map(i => routesToProTypes[i.href]);
          routes = routes.map(r => {
            const hasProType = !!r.proType;
            const shouldRemoveAuth = hasProType && customNavProTypes.includes(r.proType);
            if (!shouldRemoveAuth) return r;
            return {
              ...r,
              auth: false,
            };
          });
        }
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  const [navigationHidden, setNavigationHidden] = useState(false);

  const userLocale =
    currentUser && currentUser.metadata && currentUser.metadata.localization ? currentUser.metadata.localization.locale : null;
  useEffect(() => {
    if (userLocale && userLocale !== locale) changeLocale(userLocale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocale, locale]);

  const toRouteComponent = route => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
      staticContext,
      dispatch,
      currentUser,
      setNavigationHidden,
      navigationHidden,
      marketplace,
      history,
    };

    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={matchProps => <RouteComponentRenderer {...renderProps} match={matchProps.match} location={matchProps.location} />}
      />
    );
  };

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not rerendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <ErrorBoundary>
      <Startup hideNavigation={navigationHidden}>
        <Suspense
          fallback={
            <div className="flex h-screen flex-1 flex-row items-center justify-center">
              <IconSpinner type="tailwind" />
            </div>
          }
        >
          <Switch>
            {routes.map(toRouteComponent)}
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Startup>
    </ErrorBoundary>
  );
};

Routes.defaultProps = { staticContext: {} };

Routes.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  routes: arrayOf(propTypes.route).isRequired,

  // from withRouter
  staticContext: object,

  // from connect
  dispatch: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const { currentUser } = state.user;
  const marketplace = state.Marketplace;
  return { isAuthenticated, logoutInProgress, currentUser, marketplace };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
export default compose(withRouter, connect(mapStateToProps))(Routes);
