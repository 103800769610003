// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import { format as dateFnsFormat } from 'date-fns-tz';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 * Input names for the DateRangePicker from react-dates.
 */
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const format = (dateObj, dateFormat) => {
  const locale = window.dateFnsLocale || null;
  const settings = locale ? { locale } : {};
  return dateFnsFormat(dateObj, dateFormat, settings);
};

export const getDateTimeLabel = (data, format = 'MMM D', timeFormat = 'h:mma') => {
  const { timezone, startsAt, endsAt, allDay, listingType } = data;

  const defaultTimeZone = getDefaultTimeZoneOnBrowser();
  const showInCustomerLocalTime = listingType === 'video';
  const tz = showInCustomerLocalTime ? defaultTimeZone : timezone || defaultTimeZone;

  const startsAtMoment = moment.utc(startsAt).tz(tz);
  const endsAtMoment = moment.utc(endsAt).tz(tz);

  let shortTimeZoneMaybe = showInCustomerLocalTime ? (
    <span className="text-xs text-gray-500"> ({moment().tz(defaultTimeZone).format('z')}) </span>
  ) : (
    ''
  );
  if (!showInCustomerLocalTime && defaultTimeZone !== timezone && timezone) {
    shortTimeZoneMaybe = <span className="text-xs text-gray-500"> ({moment().tz(timezone).format('z')})</span>;
  }

  const startYear = startsAtMoment.format('YYYY');
  const endYear = endsAtMoment.format('YYYY');
  const includeBothYears = startYear !== endYear;

  const startsAtDateFormat = includeBothYears ? `${format} YYYY` : format;
  const endsAtDateFormat = `${format} YYYY`;

  const formattedStartsAt = startsAtMoment.format(startsAtDateFormat);
  const formattedEndsAtWithStartsAtFormat = endsAtMoment.format(startsAtDateFormat);
  const formattedEndsAt = endsAtMoment.format(endsAtDateFormat);

  const isDifferentStartEndDate = formattedStartsAt !== formattedEndsAtWithStartsAtFormat;

  const timesMaybe = !allDay ? ` at ${startsAtMoment.format(timeFormat)} - ${endsAtMoment.format(timeFormat)}` : '';
  if (isDifferentStartEndDate) {
    return (
      <>
        {formattedStartsAt} - {formattedEndsAt}
        {timesMaybe}
        {shortTimeZoneMaybe}
      </>
    );
  }

  return (
    <>
      {formattedStartsAt}
      {timesMaybe}
      {shortTimeZoneMaybe}
    </>
  );
};

/**
 * Check that the given parameter is a Date object.
 *
 * @param {Date} object that should be a Date.
 *
 * @returns {boolean} true if given parameter is a Date object.
 */
export const isDate = d => d && Object.prototype.toString.call(d) === '[object Date]' && !Number.isNaN(d.getTime());

/**
 * Check if the given parameters represent the same Date value (timestamps are compared)
 *
 * @param {Date} first param that should be a Date and it should have same timestamp as second param.
 * @param {Date} second param that should be a Date and it should have same timestamp as second param.
 *
 * @returns {boolean} true if given parameters have the same timestamp.
 */
export const isSameDate = (a, b) => a && isDate(a) && b && isDate(b) && a.getTime() === b.getTime();

/**
 * Check if the browser's DateTimeFormat API supports time zones.
 *
 * @returns {Boolean} true if the browser returns current timezone.
 */
export const isTimeZoneSupported = () => {
  if (!Intl || typeof Intl === 'undefined' || typeof Intl.DateTimeFormat === 'undefined') {
    return false;
  }

  const dtf = new Intl.DateTimeFormat();
  if (typeof dtf === 'undefined' || typeof dtf.resolvedOptions === 'undefined') {
    return false;
  }
  return !!dtf.resolvedOptions().timeZone;
};

/**
 * Detect the default timezone of user's browser.
 * This function can only be called from client side.
 * I.e. server-side rendering doesn't make sense - it would not return user's timezone.
 *
 * @returns {String} string containing IANA timezone key (e.g. 'Europe/Helsinki')
 */
export const getDefaultTimeZoneOnBrowser = () => {
  if (typeof window === 'undefined') {
    throw new Error('Utility function: getDefaultTimeZoneOnBrowser() should be called on client-side only.');
  }

  if (isTimeZoneSupported()) {
    const dtf = new Intl.DateTimeFormat();
    const currentTimeZone = dtf.resolvedOptions().timeZone;
    if (currentTimeZone) {
      return currentTimeZone;
    }
  }

  return moment.tz.guess();
};

/**
 * Return the names of the time zones according to IANA timezone db.
 *
 * @param {RegExp} relevantZonesRegExp is pattern to filter returned time zones.
 *
 * @returns {Array} an array of relevant time zones.
 */
export const getTimeZoneNames = relevantZonesRegExp => {
  const allTimeZones = moment.tz.names();
  return relevantZonesRegExp ? allTimeZones.filter(z => relevantZonesRegExp.test(z)) : allTimeZones;
};

/**
 * Not used with time-based process...
 * Format the given date to UTC month id/string
 *
 * @param {Date} date to be formatted
 *
 * @returns {String} formatted month string
 */
export const monthIdStringInUTC = date => moment(date).utc().format('YYYY-MM');

/**
 * Format the given date
 *
 * @param {Object} intl Intl object from react-intl
 * @param {String} todayString translation for the current day
 * @param {Date} d Date to be formatted
 *
 * @returns {String} formatted date
 */
export const formatDate = (intl, todayString, d) => {
  const paramsValid = intl && d instanceof Date && typeof todayString === 'string';
  if (!paramsValid) {
    throw new Error(`Invalid params for formatDate: (${intl}, ${todayString}, ${d})`);
  }

  // By default we can use moment() directly but in tests we need to use a specific dates.
  // fakeIntl used in tests contains now() function wich returns predefined date
  const now = intl.now ? moment(intl.now()) : moment();
  const formattedTime = intl.formatTime(d);
  let formattedDate;

  if (now.isSame(d, 'day')) {
    // e.g. "Today, 9:10pm"
    formattedDate = todayString;
  } else if (now.isSame(d, 'week')) {
    // e.g. "Wed, 8:00pm"
    formattedDate = intl.formatDate(d, {
      weekday: 'short',
    });
  } else if (now.isSame(d, 'year')) {
    // e.g. "Aug 22, 7:40pm"
    formattedDate = intl.formatDate(d, {
      month: 'short',
      day: 'numeric',
    });
  } else {
    // e.g. "Jul 17 2016, 6:02pm"
    const date = intl.formatDate(d, {
      month: 'short',
      day: 'numeric',
    });
    const year = intl.formatDate(d, {
      year: 'numeric',
    });
    formattedDate = `${date} ${year}`;
  }

  return `${formattedDate}, ${formattedTime}`;
};

/**
 * Converts string given in ISO8601 format to date object.
 * This is used e.g. when when dates are parsed form urlParams
 *
 * @param {String} dateString in 'YYYY-MM-DD'format
 *
 * @returns {Date} parsed date object
 */
export const parseDateFromISO8601 = dateString => {
  return moment(dateString, 'YYYY-MM-DD').toDate();
};

/**
 * Converts date to string ISO8601 format ('YYYY-MM-DD').
 * This string is used e.g. in urlParam.
 *
 * @param {Date} date
 *
 * @returns {String} string in 'YYYY-MM-DD'format
 */
export const stringifyDateToISO8601 = date => {
  return moment(date).format('YYYY-MM-DD');
};
