import { lazy } from 'react';

// 😳 Eagarly loaded routes
import LandingPage from '@/containers/LandingPage/LandingPage';
import NotFoundPage from '@/containers/NotFoundPage/NotFoundPage';
import getPageDataLoadingAPI from '@/containers/pageDataLoadingAPI';
import PlacePage from '@/containers/PlacePage/PlacePage';
import PlayBlockPage from '@/containers/PlayBlockPage/PlayBlockPage';

import NamedRedirect from '@/components/NamedRedirect/NamedRedirect';

// 🥱 Lazy loaded routes
const pageDataLoadingAPI = getPageDataLoadingAPI();
const AboutPage = lazy(() => import('./containers/AboutPage/AboutPage'));
const AuthenticationPage = lazy(() => import('./containers/AuthenticationPage/AuthenticationPage'));
const BookingsPage = lazy(() => import('./containers/BookingsPage/BookingsPage'));
const CalendarPage = lazy(() => import('./containers/CalendarPage/CalendarPage'));
const ContactsPage = lazy(() => import('./containers/ContactsPage/ContactsPage'));
const ContactPage = lazy(() => import('./containers/ContactPage/ContactPage'));
const ComponentsPage = lazy(() => import('./containers/ComponentsPage/ComponentsPage'));
const ComponentPage = lazy(() => import('./containers/ComponentPage/ComponentPage'));
const CmsPage = lazy(() => import('./containers/CmsPage/CmsPage'));
const CmsPlatformPage = lazy(() => import('./containers/CmsPlatformPage/CmsPlatformPage'));
const CreatePage = lazy(() => import('./containers/CreatePage/CreatePage'));
const DashboardPage = lazy(() => import('./containers/DashboardPage/DashboardPage'));
const EditContactPage = lazy(() => import('./containers/EditContactPage/EditContactPage'));
const EditListingPage = lazy(() => import('./containers/EditListingPage/EditListingPage'));
const EditProductPage = lazy(() => import('./containers/EditProductPage/EditProductPage'));
const FormsPage = lazy(() => import('./containers/FormsPage/FormsPage'));
const FormPage = lazy(() => import('./containers/FormPage/FormPage'));
const FormResponsePage = lazy(() => import('./containers/FormResponsePage/FormResponsePage'));
const EditFormPage = lazy(() => import('./containers/EditFormPage/EditFormPage'));
const HelpPage = lazy(() => import('./containers/HelpPage/HelpPage'));
const PasswordRecoveryPage = lazy(() => import('./containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PoliciesPage = lazy(() => import('./containers/PoliciesPage/PoliciesPage'));
const ProfilePage = lazy(() => import('./containers/ProfilePage/ProfilePage'));
const SavedPage = lazy(() => import('./containers/SavedPage/SavedPage'));
const ListingPage = lazy(() => import('./containers/ListingPage/ListingPage'));
const ManageListingsPage = lazy(() => import('./containers/ManageListingsPage/ManageListingsPage'));
const ManageProductsPage = lazy(() => import('./containers/ManageProductsPage/ManageProductsPage'));
const MarketplacePolicyPage = lazy(() => import('./containers/MarketplacePolicyPage/MarketplacePolicyPage'));
const MyAccountPage = lazy(() => import('./containers/MyAccountPage/MyAccountPage'));
const ArticlePage = lazy(() => import('./containers/ArticlePage/ArticlePage'));
const SearchPage = lazy(() => import('./containers/SearchPage/SearchPage'));
const WebsitePage = lazy(() => import('./containers/WebsitePage/WebsitePage'));
const CrmPage = lazy(() => import('./containers/CrmPage/CrmPage'));
const ProviderOnboardingPage = lazy(() => import('./containers/ProviderOnboardingPage/ProviderOnboardingPage'));
const MessagesPage = lazy(() => import('./containers/MessagesPage/MessagesPage'));
const NewsPage = lazy(() => import('./containers/NewsPage/NewsPage'));
// const PlacePage = lazy(() => import('./containers/PlacePage/PlacePage'));
// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    // Landing Page
    {
      path: '/index.html',
      name: 'LandingPageIndexHtml',
      component: props => <LandingPage {...props} />,
    },
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    {
      path: '/discover',
      name: 'LandingPageDiscover',
      component: props => <LandingPage {...props} />,
    },

    // About Page
    {
      path: '/about',
      name: 'AboutPage',
      component: props => <AboutPage {...props} />,
    },
    {
      path: '/about',
      name: 'AboutDiscoverPage',
      component: props => <LandingPage {...props} />,
    },

    // Search
    {
      path: ['/s', '/s/:tab'],
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },

    // Listing
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      component: props => <ListingPage {...props} />,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      component: props => (
        <NamedRedirect
          name="EditListingPage"
          search={props.location && props.location.search}
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/new/:tab',
      name: 'EditListingPageNew',
      component: props => <EditListingPage {...props} params={{ ...props.params, type: 'new' }} />,
      loadData: params => pageDataLoadingAPI.EditListingPage.loadData({ ...params, type: 'new' }),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },

    // Account Profile
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: ['/u/:id', '/u/:id/:tab'],
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
    },

    // AUTH PAGES
    {
      path: '/login',
      name: 'LoginPage',
      noNav: true,
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },

    {
      path: ['/messages', '/messages/:channel'],
      name: 'MessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MessagesPage {...props} />,
    },

    // Transactions -> Deprecated
    // Redirected to bookings
    {
      path: '/transactions',
      name: 'TransactionsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="BookingsPage" {...props} params={{ type: 'orders', tab: 'transactions' }} />,
    },
    {
      path: '/transactions/:tab',
      name: 'TransactionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect name="BookingsPage" {...props} params={{ ...props.params, type: props.params.tab, tab: 'transactions' }} />
      ),
    },
    {
      path: '/transactions/:tab/:transactionId',
      name: 'TransactionsPageDetail',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect
          name="BookingsPageDetail"
          {...props}
          params={{ type: props.params.tab, tab: 'transactions', transactionId: props.params.transactionId }}
        />
      ),
    },

    // Public roster
    {
      path: '/r/:contactId',
      name: 'CalendarRosterPage',
      auth: false,
      component: props => (
        <CalendarPage
          {...props}
          isPublicContactRoster={true}
          params={{ ...props.params, tab: 'orders', isPublicContactRoster: true }}
        />
      ),
      loadData: pageDataLoadingAPI.CalendarPage.loadData,
    },

    // Calendar
    {
      path: '/calendar',
      name: 'CalendarPageBase',
      auth: true,
      authPage: 'LoginPage',
      proType: 'calendar',
      component: () => <NamedRedirect name="CalendarPage" params={{ tab: 'orders' }} />,
    },
    {
      path: '/calendar/:tab',
      name: 'CalendarPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'calendar',
      component: props => <CalendarPage {...props} />,
      loadData: pageDataLoadingAPI.CalendarPage.loadData,
    },

    // Contacts
    {
      path: '/contacts',
      name: 'ContactsPageBase',
      auth: true,
      authPage: 'LoginPage',
      proType: 'contacts',
      component: () => <NamedRedirect name="ContactsPage" params={{ tab: 'all' }} />,
    },
    {
      path: '/contacts/:tab',
      name: 'ContactsPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'contacts',
      component: props => <ContactsPage {...props} />,
    },
    {
      path: '/contacts/:tab/:id',
      name: 'ContactsDetailPage',
      auth: true,
      proType: 'contacts',
      component: props => <ContactsPage {...props} />,
    },
    {
      path: '/contact/new',
      name: 'NewContactPage',
      proType: 'contacts',
      component: () => <NamedRedirect name="EditContactPageNew" params={{ id: draftId, type: 'new' }} />,
    },
    {
      path: '/contact/:id/edit',
      name: 'EditContactPage',
      auth: true,
      proType: 'contacts',
      component: props => <EditContactPage {...props} params={{ ...props.params, type: 'edit' }} />,
    },
    {
      path: '/contact/:id/new',
      name: 'EditContactPageNew',
      auth: true,
      proType: 'contacts',
      component: props => <EditContactPage {...props} params={{ ...props.params, type: 'new' }} />,
    },
    {
      path: '/contact/:id',
      name: 'ContactPage',
      auth: true,
      proType: 'contacts',
      component: props => <NamedRedirect name="ContactPageTab" params={{ ...props.params, tab: 'tasks' }} />,
    },
    {
      path: '/contact/:id/:tab',
      name: 'ContactPageTab',
      auth: true,
      proType: 'contacts',
      component: props => <ContactPage {...props} />,
    },
    {
      path: '/contact/:id/:tab/:tabDetailId',
      name: 'ContactPageTabDetail',
      auth: true,
      proType: 'contacts',
      component: props => <ContactPage {...props} />,
    },

    // BOOKINGS
    {
      path: '/bookings',
      name: 'BookingsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="BookingsPage" params={{ type: 'sales' }} />,
    },
    // Redirect all orders routes to the my account section
    {
      path: '/bookings/orders',
      name: 'BookingsPageAllOrders',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="MyAccountPage" {...props} params={{ tab: 'bookings' }} />,
    },
    {
      path: '/bookings/orders/:tab',
      name: 'BookingsPageOrders',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect
          name="MyAccountPageType"
          {...props}
          params={{ ...props.params, type: props.params.tab, tab: 'bookings' }}
        />
      ),
    },
    {
      path: '/bookings/orders/:tab/:transactionId',
      name: 'BookingsPageDetailOrders',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect
          name="MyAccountPageId"
          {...props}
          params={{ ...props.params, type: props.params.tab, tab: 'bookings', id: props.params.transactionId }}
        />
      ),
    },
    // normal my bookings routes
    {
      path: '/bookings/:type',
      name: 'BookingsPageAll',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="BookingsPage" {...props} params={{ ...props.params, tab: 'all' }} />,
    },
    {
      path: '/bookings/:type/:tab',
      name: 'BookingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BookingsPage {...props} />,
    },
    {
      path: '/bookings/:type/:tab/:transactionId',
      name: 'BookingsPageDetail',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BookingsPage {...props} />,
    },

    // MANAGE LISTINGS
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} params={{ ...props.params, tab: '' }} />,
    },
    {
      path: '/listings/:tab',
      name: 'ManageListingsTabPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
    },

    // MANAGE Products
    {
      path: '/products',
      name: 'ManageProductsPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'products',
      component: props => <ManageProductsPage {...props} params={{ ...props.params, tab: '' }} />,
    },
    {
      path: '/products/:tab',
      name: 'ManageProductsTabPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'products',
      component: props => <ManageProductsPage {...props} />,
    },
    {
      path: '/product/new',
      name: 'NewProductPage',
      proType: 'products',
      component: () => <NamedRedirect name="EditProductPageNew" params={{ id: draftId, type: 'new' }} />,
    },
    {
      path: '/product/:id/edit',
      name: 'EditProductPage',
      auth: true,
      proType: 'products',
      component: props => <EditProductPage {...props} params={{ ...props.params, type: 'edit' }} />,
    },
    {
      path: '/product/:id/new',
      name: 'EditProductPageNew',
      auth: true,
      proType: 'products',
      component: props => <EditProductPage {...props} params={{ ...props.params, type: 'new' }} />,
    },

    // Platform CMS
    {
      path: '/cms',
      name: 'CmsPlatformPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['platformAdmin'],
      hideNavigation: true,
      component: props => <CmsPlatformPage {...props} />,
    },
    {
      path: '/cms/:tab',
      name: 'CmsPlatformTabPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['platformAdmin'],
      hideNavigation: true,
      component: props => <CmsPlatformPage {...props} />,
    },

    // Admin
    {
      path: '/admin',
      name: 'CmsBasePage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin'],
      microMarketplace: false,
      hideNavigation: true,
      whiteBg: true,
      component: () => <NamedRedirect name="CmsPage" params={{ tab: 'dashboard' }} />,
    },
    {
      path: ['/admin/settings'],
      name: 'CmsSettingsBasePage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin'],
      microMarketplace: false,
      hideNavigation: true,
      whiteBg: true,
      component: () => <NamedRedirect name="CmsSettingsPage" params={{ tab: 'settings', currentPath1: 'general' }} />,
    },
    {
      path: ['/admin/:tab/:currentPath1', '/admin/:tab/:currentPath1/:currentPath2'],
      name: 'CmsSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin'],
      microMarketplace: false,
      hideNavigation: true,
      whiteBg: true,
      component: props => <CmsPage {...props} />,
    },
    {
      path: '/admin/:tab',
      name: 'CmsPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin'],
      microMarketplace: false,
      hideNavigation: true,
      whiteBg: true,
      component: props => <CmsPage {...props} />,
    },

    {
      path: '/admin-micro',
      name: 'CmsMicroBasePage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin', 'microMarketplaceAdmin'],
      hideNavigation: true,
      microMarketplace: false,
      fullWidth: true,
      whiteBg: true,
      component: () => <CmsPage micro params={{ tab: 'micro' }} />,
    },
    {
      path: [
        '/admin-micro/:microMarketplaceId',
        '/admin-micro/:microMarketplaceId/:tab',
        '/admin-micro/:microMarketplaceId/:tab/:currentPath1',
        '/admin-micro/:microMarketplaceId/:tab/:currentPath1/:currentPath2',
      ],
      name: 'CmsMicroPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin', 'microMarketplaceAdmin'],
      hideNavigation: true,
      fullWidth: true,
      whiteBg: true,
      component: props => <CmsPage micro {...props} />,
    },

    {
      path: '/admin-cms',
      name: 'AdminCmsBasePage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin', 'marketplaceCmsAdmin'],
      hideNavigation: true,
      component: () => <NamedRedirect name="AdminCmsUsersPage" />,
    },
    {
      path: '/admin-cms/users',
      name: 'AdminCmsUsersPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin', 'marketplaceCmsAdmin'],
      hideNavigation: true,
      component: props => <CmsPage role="marketplaceCmsAdmin" {...props} params={{ tab: 'users' }} />,
    },
    {
      path: '/admin-cms/listings',
      name: 'AdminCmsListingsPage',
      auth: true,
      authPage: 'LoginPage',
      authRoles: ['marketplaceAdmin', 'marketplaceCmsAdmin'],
      hideNavigation: true,
      component: props => <CmsPage role="marketplaceCmsAdmin" {...props} params={{ tab: 'listings' }} />,
    },

    // MY ACCOUNT
    // deprecated account routes that need to be redirected to MyAccountPage
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="MyAccountPage" params={{ tab: 'settings' }} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="MyAccountPage" params={{ tab: 'payments-payouts' }} />,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="MyAccountPage" params={{ tab: 'profile' }} />,
    },
    // Current My Account Routes
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    // This specific route needs StripePayoutPageLoadData in loadData
    {
      path: '/account/payments-payouts',
      name: 'StripePayoutOnboardingBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} params={{ ...props.params, tab: 'payments-payouts' }} />,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: ['/account/messages', '/account/messages/:channel'],
      name: 'MyAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MessagesPage account {...props} />,
    },
    {
      path: '/account/:tab',
      name: 'MyAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} />,
    },
    {
      path: '/account/pro/:returnURLType',
      name: 'MyAccountProReturnPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} params={{ ...props.params, tab: 'pro' }} />,
    },
    {
      path: '/account/payments-payouts/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} params={{ ...props.params, tab: 'payments-payouts' }} />,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/:tab/:type',
      name: 'MyAccountPageType',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} />,
    },
    {
      path: '/account/:tab/:type/:id',
      name: 'MyAccountPageId',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MyAccountPage {...props} />,
    },

    // FORMS
    {
      path: '/forms',
      name: 'FormsPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'forms',
      pro: true,
      component: props => <FormsPage {...props} />,
    },
    {
      path: '/forms/templates',
      name: 'FormsTemplatesPage',
      auth: true,
      authPage: 'LoginPage',
      proType: 'forms',
      pro: true,
      component: props => <FormsPage {...props} params={{ ...props.params, tab: 'templates' }} />,
    },
    {
      path: '/forms/new',
      name: 'NewFormPage',
      proType: 'forms',
      pro: true,
      component: () => <NamedRedirect name="EditFormPageNew" params={{ id: draftId, type: 'new' }} />,
    },
    {
      path: '/forms/new/:template',
      name: 'NewFormPage',
      proType: 'forms',
      pro: true,
      component: props => (
        <NamedRedirect name="EditFormPageTemplateNew" params={{ id: draftId, type: 'new', template: props.params.template }} />
      ),
    },
    {
      path: '/forms/:id/edit',
      name: 'EditFormPage',
      auth: true,
      proType: 'forms',
      pro: true,
      component: props => <EditFormPage {...props} params={{ ...props.params, type: 'edit' }} />,
    },
    {
      path: '/forms/:id/new',
      name: 'EditFormPageNew',
      auth: true,
      proType: 'forms',
      pro: true,
      component: props => <EditFormPage {...props} params={{ ...props.params, type: 'new' }} />,
    },
    {
      path: '/forms/:id/new/:template',
      name: 'EditFormPageTemplateNew',
      auth: true,
      proType: 'forms',
      pro: true,
      component: props => <EditFormPage {...props} params={{ ...props.params, type: 'new' }} />,
    },
    {
      path: '/forms/:id',
      name: 'FormPage',
      auth: true,
      proType: 'forms',
      pro: true,
      component: props => <NamedRedirect name="FormPageTab" params={{ ...props.params, tab: 'questions' }} />,
    },
    {
      path: '/forms/:id/:tab',
      name: 'FormPageTab',
      auth: true,
      proType: 'forms',
      pro: true,
      component: props => <FormPage {...props} />,
    },
    {
      path: '/f/:id',
      name: 'FormResponsePage',
      hideNavigation: true,
      // auth: true,
      component: props => <FormResponsePage {...props} />,
    },

    // POLICIES
    {
      path: '/policies',
      name: 'PoliciesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PoliciesPage {...props} />,
    },
    {
      path: '/policies/:id',
      name: 'PoliciesDetailPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PoliciesPage {...props} />,
    },

    // Account saved/ favorite listings
    {
      path: ['/saved', '/saved/:tab'],
      name: 'SavedPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SavedPage {...props} />,
    },

    // legals
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: () => <NamedRedirect name="MarketplacePolicyPage" params={{ type: 'terms-of-service' }} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: () => <NamedRedirect name="MarketplacePolicyPage" params={{ type: 'privacy-policy' }} />,
    },
    {
      path: '/cookie-policy',
      name: 'CookiePolicyPage',
      component: () => <NamedRedirect name="MarketplacePolicyPage" params={{ type: 'cookie-policy' }} />,
    },
    {
      path: '/acceptable-use-policy',
      name: 'AcceptableUsePolicyPage',
      component: () => <NamedRedirect name="MarketplacePolicyPage" params={{ type: 'acceptable-use-policy' }} />,
    },
    {
      path: '/p/:type',
      name: 'MarketplacePolicyPage',
      component: props => <MarketplacePolicyPage {...props} />,
    },

    {
      path: '/resource/:id',
      name: 'ResourcePage',
      component: props => <ArticlePage {...props} />,
    },

    {
      path: '/news',
      name: 'NewsPage',
      component: props => <NewsPage type="news" {...props} />,
    },
    {
      path: '/news/:id',
      name: 'NewsDetailPage',
      component: props => <ArticlePage type="news" {...props} />,
    },

    // PlayBlockPage
    {
      path: '/pb/:id',
      name: 'PlayBlockPage',
      component: props => <PlayBlockPage {...props} />,
    },

    // Dashboard
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <DashboardPage {...props} />,
    },
    {
      path: '/dashboard/:tab',
      name: 'DashboardTabPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <DashboardPage {...props} />,
    },
    {
      path: '/dashboard/:tab/:returnURLType',
      name: 'DashboardProReturnTabPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <DashboardPage {...props} />,
    },
    {
      path: '/',
      name: 'DashboardPageRoot',
      component: props => <DashboardPage {...props} />,
    },

    // Website
    {
      path: '/website',
      name: 'WebsitePage',
      auth: true,
      authPage: 'LoginPage',
      hideNavigation: true,
      fullWidth: true,
      whiteBg: true,
      component: props => <WebsitePage {...props} />,
    },
    {
      path: '/website/:tab',
      name: 'WebsiteTabPage',
      auth: true,
      authPage: 'LoginPage',
      hideNavigation: true,
      fullWidth: true,
      whiteBg: true,
      component: props => <WebsitePage {...props} />,
    },

    // ComponentsPage
    {
      path: ['/components', '/components/:category'],
      name: 'ComponentsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ComponentsPage {...props} />,
    },
    {
      path: '/components/:category/:tab',
      name: 'ComponentPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ComponentPage {...props} />,
    },

    // CRM
    {
      path: '/crm',
      name: 'CrmPage',
      hideNavigation: true,
      authRoles: ['marketplaceAdmin', 'crmUser'],
      component: props => <CrmPage {...props} />,
    },
    {
      path: '/crm/:tab',
      name: 'CrmTabPage',
      hideNavigation: true,
      authRoles: ['marketplaceAdmin', 'crmUser'],
      component: props => <CrmPage {...props} />,
    },
    {
      path: '/crm/:tab/:id',
      name: 'CrmDetailPage',
      hideNavigation: true,
      authRoles: ['marketplaceAdmin', 'crmUser'],
      component: props => <CrmPage {...props} />,
    },

    {
      path: '/become-a-provider',
      name: 'ProviderOnboardingPage',
      hideNavigation: true,
      component: props => <ProviderOnboardingPage {...props} />,
    },

    {
      path: '/become-a-provider/:step',
      name: 'ProviderOnboardingPage',
      hideNavigation: true,
      auth: true,
      authPage: 'SignupPage',
      component: props => <ProviderOnboardingPage {...props} />,
    },

    // 404
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    {
      path: ['/create', '/create/:type'],
      name: 'CreatePage',
      hideNavigation: true,
      auth: true,
      authPage: 'LoginPage',
      component: props => <CreatePage {...props} />,
    },

    // help
    {
      path: '/help',
      name: 'HelpPageBase',
      component: props => <HelpPage {...props} />,
    },
    {
      path: '/help/:tab',
      name: 'HelpPage',
      component: props => <HelpPage {...props} />,
    },
    {
      path: '/help/:tab/:category/:subCategory',
      name: 'HelpPageArticle',
      component: props => <HelpPage {...props} />,
    },

    // Places 🗺
    {
      path: [
        '/places/:type/:country/:state/:county/:city/:street/:slug',
        '/places/:type/:country/:state/:county/:city/:street',
        '/places/:type/:country/:state/:county/:city',
        '/places/:type/:country/:state/:county',
        '/places/:type/:country/:state',
        '/places/:type/:country',
        '/places/:type',
        '/places',
      ],
      name: 'PlacePage',
      whiteBg: true,
      component: props => <PlacePage {...props} />,
    },
  ];
};

export default routeConfiguration;
