import * as React from 'react';
import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { bool, func, node, number, object, string } from 'prop-types';
import { usePopper } from 'react-popper';

import ButtonLight from '@/components/Button/ButtonLight';
import OutsideClickHandler from '@/components/OutsideClickHandler/OutsideClickHandler';

import FilterForm from '@/forms/FilterForm/FilterForm';

import { injectIntl, intlShape } from '@/util/reactIntl';

import * as css from './FilterPopup.module.css';

const KEY_CODE_ESCAPE = 27;

const FilterPopup = props => {
  const {
    rootClassName,
    className,
    popupClassName,
    id,
    label,
    labelMaxWidth,
    isSelected,
    children,
    initialValues,
    keepDirtyOnReinitialize,
    contentPlacementOffset,
    onSubmit,
    urlParam,
    widthClassName = 'min-w-[300px]',
    onChange,
    onClear,
    onCancel,
    right = false,
    shouldClose,
    hideButtons = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (shouldClose && isOpen) setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClose]);

  const handleSubmit = values => {
    setIsOpen(false);
    onSubmit(urlParam, values);
  };

  const handleChange = values => {
    if (onChange) {
      onChange(urlParam, values);
    }
  };

  const handleClear = () => {
    setIsOpen(false);
    if (onClear) {
      onClear();
    }

    onSubmit(urlParam, null);
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }

    onSubmit(urlParam, initialValues);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleKeyDown = e => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      toggleOpen(false);
    }
  };

  const toggleOpen = enforcedState => {
    if (enforcedState) {
      setIsOpen(enforcedState);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const popperElRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: right ? 'bottom-end' : 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
  });

  const classes = classNames(rootClassName || css.root, className);
  // const popupClasses = classNames(css.popup, { [css.isOpen]: isOpen });
  const popupSizeClasses = popupClassName || css.popupSize;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

  return (
    <div>
      <OutsideClickHandler onOutsideClick={handleBlur}>
        <div className={classes} onKeyDown={handleKeyDown} ref={setTargetElement}>
          <ButtonLight
            className={labelMaxWidthStyles}
            style={labelMaxWidthMaybe}
            inlineBlock
            noShadow
            filter
            filterOpen={isOpen}
            filterActive={isSelected}
            onClick={() => toggleOpen()}
            theme={isSelected ? 'primary' : 'filter'}
          >
            <span data-title={label} className={css.labelWrapper}>
              {label}
            </span>
          </ButtonLight>

          <div id={id} className="z-50" ref={popperElRef} style={styles.popper} {...attributes.popper}>
            <Transition
              show={isOpen}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <div
                className={`z-50 ${!right ? 'left' : 'right'}-0 ${widthClassName} mt-2 origin-top-${
                  !right ? 'left' : 'right'
                } rounded-md bg-white shadow-2xl outline-none ring-1 ring-gray-900 ring-opacity-5`}
              >
                {isOpen ? (
                  <FilterForm
                    id={`${id}.form`}
                    paddingClasses={popupSizeClasses}
                    showAsPopup
                    hideButtons={hideButtons}
                    contentPlacementOffset={contentPlacementOffset}
                    initialValues={initialValues}
                    keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    onCancel={handleCancel}
                    onClear={handleClear}
                  >
                    {children}
                  </FilterForm>
                ) : null}
              </div>
            </Transition>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

FilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  popupClassName: null,
  onChange: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  contentPlacementOffset: 0,
  liveEdit: false,
  label: null,
  labelMaxWidth: null,
};

FilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  popupClassName: string,
  id: string.isRequired,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  onChange: func,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  contentPlacementOffset: number,
  label: string.isRequired,
  labelMaxWidth: number,
  isSelected: bool.isRequired,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(FilterPopup);
