import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import get from 'lodash/get';

import SectionLandingCallToAction from '@/containers/LandingPage/SectionLandingCallToAction';
import SectionLandingFaq from '@/containers/LandingPage/SectionLandingFaq';
import SectionLandingFeature from '@/containers/LandingPage/SectionLandingFeature';
import SectionLandingFeatures from '@/containers/LandingPage/SectionLandingFeatures';
import SectionLandingLogos from '@/containers/LandingPage/SectionLandingLogos';
import SectionLandingPricing from '@/containers/LandingPage/SectionLandingPricing';

import Card from '@/components/Card/Card';
import Carousel from '@/components/Carousel/Carousel';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import GridListActionsWithSharedBorders from '@/components/GridLists/GridListActionsWithSharedBorders';
import HeroHeader from '@/components/HeroHeader/HeroHeader';
import IconSpinner from '@/components/IconSpinner/IconSpinner';
import SectionHero from '@/components/SectionHero/SectionHero';

import { playBlocks } from '@/clients';
import {
  setColorMode,
  setDarkThemeDisabledForRoute,
  setDarkThemeForcedForRoute,
  setLargeSearchBar,
  setLogo,
  setTransparentHeader,
} from '@/ducks/UI.duck';

import { camelize, getIsListingBookmarked, updateColorModeState } from '@/util/helpers';
import useMediaQuery from '@/util/useMediaQuery';

import UiBizHours from './UiBizHours';
import UiCategory from './UiCategory';
import UiContactAndSocials from './UiContactAndSocials';
import UiContactForm from './UiContactForm';
import UiLink from './UiLink';
import UiList from './UiList';
import UiLocations from './UiLocations';
import UiMarketingFeatureSelectors from './UiMarketingFeatureSelectors';
import UiMarketingFeatureSplitMedia from './UiMarketingFeatureSplitMedia';
import UiMarketingTeam from './UiMarketingTeam';
import UiMediaSection from './UiMediaSection';
import UiParagraph from './UiParagraph';
import { setUiTheme } from './UiRendererHelpers';
import UiSearchRenderer from './UiSearchRenderer';
import UiTestimonial from './UiTestimonial';
import UiTextRenderer from './UiTextRenderer';
import UiTextSection from './UiTextSection';

const HeroRenderer = params => {
  const {
    metadata,
    searchType,
    onManageTransparentHeader,
    onManageLargeSearchBar,
    history,
    serachPlaceholder,
    isSidebarLayout,
    marketplaceOff,
    fluidDefaultHeader,
  } = params;
  const { ui, title, slides = [] } = metadata;
  const { hero } = ui || {};
  const { slideType = 'full' } = hero || {};

  const renderSponsorsMaybe = (sponsors, smallMedia) => {
    return sponsors && sponsors.length
      ? props => (
          <div>
            <div className={`${smallMedia || slideType !== 'full' ? 'text-gray-500' : 'text-white'} mb-3 mt-8 text-base`}>
              Proudly sponsored by
            </div>
            <div className={`flex flex-row flex-wrap ${props.className}`}>
              {sponsors.map(sponsor => {
                const src = get(sponsor, 'image.metadata.url');
                if (!src) return null;
                return (
                  <a key={src} target="_blank" rel="noopener noreferrer mr-4" href={sponsor.website}>
                    <img alt={sponsor.title} src={src} className="h-[40px]" />
                  </a>
                );
              })}
            </div>
          </div>
        )
      : () => null;
  };

  const getMuxSrcFromVideo = video => {
    if (!video || !video.metadata || !video.metadata.playback) return null;
    const playbackId = video.metadata.playback.id;
    const videoUrl = video ? `https://stream.mux.com/${playbackId}.m3u8` : null;
    let source = {
      src: videoUrl,
      type: 'application/x-mpegURL',
    };
    return source;
  };

  const setVarsForSlide = (index, initial) => {
    const slide = slides[index] || {};
    const { image = {}, video, ui, title, description, sponsors } = slide;
    const { hero } = ui || {};
    let {
      transparentHeader = false,
      largeSearchBar = false,
      smallMedia = false,
      disableOverlay = false,
      splitAlignment,
    } = hero || {};

    const splitAlignmentReversed = splitAlignment === 'reverse';
    const rightTransparentOnly = (slideType === 'split' || slideType === 'split-container') && !splitAlignmentReversed;
    const leftTransparentOnly = (slideType === 'split' || slideType === 'split-container') && splitAlignmentReversed;
    // Force no disableOverlay when small media is checked
    if (smallMedia && !disableOverlay) disableOverlay = true;

    const params = {
      title,
      description,
      sponsorsMaybe: renderSponsorsMaybe(sponsors, smallMedia),
      videoSrc: getMuxSrcFromVideo(video),
      src: get(image, 'metadata.url'),
      imageId: get(image, 'metadata.name'),
      buttonHyperLink: slide.buttonHyperLink,
      buttonText: slide.buttonText,
      metadata: slide.metadata || {},
      ...hero,
      transparentHeader,
      rightTransparentOnly,
      leftTransparentOnly,
      largeSearchBar,
      smallMedia,
      disableOverlay,
    };

    if (index !== slideIndex && !initial) {
      setSlideIndex(index);
      setSlideParams(params);
    }
    return params;
  };

  const [slideIndex, setSlideIndex] = useState(null);
  let initialParams = null;
  if (slides && slides.length) {
    initialParams = setVarsForSlide(0, true);
  }
  const [slideParams, setSlideParams] = useState(initialParams);

  useEffect(() => {
    const { transparentHeader, rightTransparentOnly, leftTransparentOnly, largeSearchBar } = slideParams || {};
    let onManageTransparentHeaderFn = onManageTransparentHeader;
    let onManageLargeSearchBarFn = onManageLargeSearchBar;

    // manually set UI functions if they are not provided
    if (!onManageTransparentHeaderFn || !onManageLargeSearchBarFn) {
      // console.error('onManageLargeSearchBar() || onManageLargeSearchBar() missing');
      try {
        const dispatch = window.app.store.dispatch;
        if (!onManageTransparentHeaderFn) {
          onManageTransparentHeaderFn = (params, options) => {
            dispatch(setTransparentHeader(params, options));
          };
        }
        if (!onManageLargeSearchBarFn) {
          onManageLargeSearchBarFn = params => {
            dispatch(setLargeSearchBar(params));
          };
        }
      } catch (error) {
        console.warn(error, 'Error setting onManageLargeSearchBar && onManageLargeSearchBar');
        return;
      }
    }

    onManageTransparentHeaderFn(transparentHeader, { right: rightTransparentOnly, left: leftTransparentOnly });
    onManageLargeSearchBarFn(largeSearchBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideParams, slideIndex]);

  useEffect(() => {
    return () => {
      try {
        onManageTransparentHeader(false, { right: false, left: false });
        onManageLargeSearchBar(false);
      } catch {
        console.warn('Error clearing onManageLargeSearchBar && onManageLargeSearchBar');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (slides && slides.length) {
      setVarsForSlide(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  if (!slides || !slides.length || !slideParams) {
    return null;
  }

  return (
    <HeroHeader
      key={title}
      {...slideParams}
      marketplaceOff={marketplaceOff}
      slideType={slideType}
      isSidebarLayout={isSidebarLayout}
      fluidDefaultHeader={fluidDefaultHeader}
      getSlideData={setVarsForSlide}
      slides={slides}
      type={hero && hero.type}
      settings={hero}
      history={history}
      searchType={searchType}
      serachPlaceholder={serachPlaceholder}
    ></HeroHeader>
  );
};

const HeroMaybe = ({ sections, marketplace, onManageTransparentHeader, history, onManageLargeSearchBar, preview }) => {
  if (!sections || !sections.length) return null;

  let heroSection = sections.find(section => section.metadata.ui.type === 'hero');
  if (!heroSection) return null;

  // const useLocalMarketplaceHeader = true;
  // if (
  //   !preview &&
  //   useLocalMarketplaceHeader &&
  //   marketplace.id &&
  //   marketplace.pageConfig &&
  //   marketplace.pageConfig.landingPage.sections
  // ) {
  //   heroSection = marketplace.pageConfig.landingPage.sections.find(section => section.metadata.ui.type === 'hero');
  // }

  const serachPlaceholder =
    marketplace && marketplace.pageConfig && marketplace.pageConfig.general && marketplace.pageConfig.general.labels
      ? marketplace.pageConfig.general.labels.searchPlaceholder
      : null;

  const searchType =
    marketplace && marketplace.pageConfig && marketplace.pageConfig.search && marketplace.pageConfig.search.searchType
      ? marketplace.pageConfig.search.searchType
      : null;

  const isSidebarLayout = get(marketplace, 'pageConfig.advanced.navigationType') === 'sidebar';
  const marketplaceOff = get(marketplace, 'pageConfig.advanced.marketplaceOff');

  return (
    <HeroRenderer
      {...heroSection}
      isSidebarLayout={isSidebarLayout}
      onManageTransparentHeader={onManageTransparentHeader}
      history={history}
      onManageLargeSearchBar={onManageLargeSearchBar}
      serachPlaceholder={serachPlaceholder}
      searchType={searchType}
      marketplaceOff={marketplaceOff}
      fluidDefaultHeader={preview}
    />
  );
};

const renderHeroLink = params => {
  const { metadata } = params;
  const { title, buttonHref, buttonText, image, description, ui, titleClassNames } = metadata;
  const { heroLink } = ui || {};
  const { fullWidth } = heroLink || {};

  return (
    <>
      <SectionHero
        titleClassNames={titleClassNames}
        className="mx-container mb-8"
        {...metadata}
        buttonHref={buttonHref}
        buttonText={buttonText}
        title={title}
        src={image?.metadata?.url}
        fullWidthImage={fullWidth}
        imageId={image?.metadata?.name}
        description={description}
      />
    </>
  );
};

const renderSearchSection = params => {
  return <UiSearchRenderer className="mx-container mb-8" {...params} />;
};

const renderCarousel = (params, currentUserBookmarkedListings, index) => {
  const { metadata, recommendations } = params;
  const { data } = recommendations || {};
  const { title, description, ui, titleClassNames } = metadata || {};
  const { card = {}, hideHeader = false, viewAll, viewAllButtonText, viewAllButtonLink } = ui || {};
  if (!data || !data.length) return null;

  const priority = index < 2;
  return (
    <Carousel
      roundedClassName={card.borderRadius}
      titleClassNames={titleClassNames}
      subtitle={description}
      negativeMargins
      title={title}
      hideHeader={hideHeader}
      viewAll={viewAll}
      viewAllButtonText={viewAllButtonText}
      viewAllButtonLink={viewAllButtonLink}
    >
      {data.map((props, index) => {
        const isSaved = getIsListingBookmarked(currentUserBookmarkedListings, props.id);

        return (
          <Card
            {...card}
            size={card.size}
            cardType={card.type}
            textSize={card.textSize}
            withOverlay={card.withOverlay}
            hideLocation={card.hideLocation}
            hideText={card.hideText}
            alwaysShowOverlayContent={card.alwaysShowOverlayContent}
            withAvatar={card.withAvatar}
            key={`${props.id}_${index}`}
            isSaved={isSaved}
            priority={priority && index < 2}
            {...props}
          ></Card>
        );
      })}
    </Carousel>
  );
};

const renderImageFeature = (params, fullWidthMedia) => {
  const { metadata } = params;
  const {
    title,
    buttonHref,
    buttonHrefMetadata,
    buttonText,
    image,
    description,
    ui = {},
    video = {},
    category,
    titleClassNames,
  } = metadata;
  return (
    <SectionLandingFeature
      titleClassNames={titleClassNames}
      title={title}
      reverse={ui.imageFeature ? ui.imageFeature.reverse : null}
      titleSize={ui.title ? ui.title.textSize : null}
      description={description}
      buttonText={buttonText}
      fullWidthMedia={fullWidthMedia}
      buttonHref={buttonHref}
      buttonHrefMetadata={buttonHrefMetadata}
      src={image?.metadata?.url}
      imageId={image?.metadata?.name}
      category={category}
      vertical={ui.imageFeature ? ui.imageFeature.vertical : null}
      videoIcon={ui.imageFeature ? video.youtube : null}
      videoId={video && video.youtube ? video.youtube : null}
      className="mb-10"
    />
  );
};

const renderCallToAction = params => {
  const { metadata } = params;
  const {
    title,
    buttonHref,
    buttonText,
    description,
    secondaryButtonHref,
    secondaryButtonText,
    titleClassNames,
    subTitleClassNames,
  } = metadata;
  return (
    <SectionLandingCallToAction
      {...metadata}
      title={title}
      description={description}
      titleClassNames={titleClassNames}
      subTitleClassNames={subTitleClassNames}
      buttonHref={buttonHref}
      buttonText={buttonText}
      secondaryButtonHref={secondaryButtonHref}
      secondaryButtonText={secondaryButtonText}
    />
  );
};

const Grid = params => {
  let { metadata, recommendations, desktop, currentUserBookmarkedListings } = params;
  let { data } = recommendations || {};
  const { title, ui, description, titleClassNames, descriptionClassNames } = metadata || {};
  const { card = {}, grid = {} } = ui || {};
  const { size: gridSize } = grid || {};
  const { textSize, size: cardSize } = card || {};
  if (!data || !data.length) return null;

  let gridOptions = {
    gridClassName: '',
    maxCards: 20,
    headerType: 'inline',
    size: cardSize || 'grid-default',
  };

  const gapClassName = grid?.gapClassName || 'gap-6';
  switch (gridSize) {
    case '1/7':
      gridOptions = {
        ...gridOptions,
        gridClassName: 'grid-cols-2 grid-xs:grid-cols-3 grid-sm:grid-cols-5 grid-md:grid-cols-7 gap-3',
        maxCards: 12,
      };
      break;
    case '1/4':
      gridOptions = {
        ...gridOptions,
        headerType: 'block',
        gridClassName: `grid-cols-1 ${gapClassName} md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`,
        maxCards: 100,
      };
      break;
    case '1/3':
      gridOptions = {
        ...gridOptions,
        headerType: 'block',
        gridClassName: `grid-cols-1 ${gapClassName} md:grid-cols-2 lg:grid-cols-3`,
        maxCards: 100,
      };
      break;
    case '1/2':
      gridOptions = {
        ...gridOptions,
        headerType: 'block',
        gridClassName: `grid-cols-1 ${gapClassName} md:grid-cols-2`,
        maxCards: 100,
      };
      break;
    case '2/5-1/5':
    default:
      gridOptions = {
        ...gridOptions,
        firstCardClassName: 'col-span-2 row-span-2 grid-sm:col-span-3 grid-md:col-span-2',
        firstCardSize: 'grid-default',
        firstCardSizeDesktop: 'grid-square',
        gridClassName: 'grid grid-cols-2 grid-sm:grid-cols-3 grid-md:grid-cols-5 grid-flow-row gap-6',
        maxCards: 7,
        headerType: 'block',
      };
      break;
  }

  if (data.length > gridOptions.maxCards) data = data.slice(0, gridOptions.maxCards);
  return (
    <div className="px-container">
      {gridOptions.headerType === 'block' ? (
        <UiTextSection
          className="pb-4 md:pb-6"
          titleClassNames={titleClassNames}
          title={title}
          description={description}
          descriptionClassNames={descriptionClassNames}
        />
      ) : null}

      <div className={`grid ${gridOptions.gridClassName}`}>
        {gridOptions.headerType === 'inline' ? (
          <div className="grid-xs:col-span-3 grid-sm:col-span-2 col-span-2 flex flex-col justify-center">
            <div className="grid-sm:max-w-xs sm:py-2 md:pb-4">
              <UiTextRenderer text={title} classNames={titleClassNames} type="marketing-title" />
              {description ? <div className="mt-1 text-sm text-gray-500 sm:mt-2">{description}</div> : null}
            </div>
          </div>
        ) : null}

        {data.map((props, index) => {
          const isFirstCard = index === 0;
          const className = gridOptions.firstCardClassName && isFirstCard ? gridOptions.firstCardClassName : '';

          const defaultCardSize = card.size || 'auto';
          const cardSize = gridOptions.firstCardSize && isFirstCard ? gridOptions.firstCardSize : defaultCardSize;
          const cardSizeDesktop = gridOptions.firstCardSizeDesktop && isFirstCard ? gridOptions.firstCardSizeDesktop : '';

          const isSaved = getIsListingBookmarked(currentUserBookmarkedListings, props.id);
          return (
            <Card
              className={className}
              size={cardSize}
              {...card}
              cardType={card.type}
              sizeDesktop={cardSizeDesktop}
              desktop={desktop}
              textSize={textSize}
              withOverlay={card.withOverlay}
              hideLocation={card.hideLocation}
              hideText={card.hideText}
              alwaysShowOverlayContent={card.alwaysShowOverlayContent}
              withAvatar={card.withAvatar}
              key={`${props.id}_${index}`}
              isSaved={isSaved}
              {...props}
            ></Card>
          );
        })}
      </div>
    </div>
  );
};

const renderFeature = params => {
  const { metadata } = params;
  const { title, features, description, ui, category, titleClassNames } = metadata;

  const type = ui?.feature?.type;
  if (type === 'selectorGrid' || type === 'selectorList') {
    return <UiMarketingFeatureSelectors config={metadata} />;
  }

  if (type === 'splitMedia') {
    return <UiMarketingFeatureSplitMedia config={metadata} />;
  }

  return (
    <SectionLandingFeatures
      titleClassNames={titleClassNames}
      type={type}
      settings={ui && ui.feature}
      category={category}
      features={features}
      title={title}
      description={description}
      className="mx-container mb-12"
    />
  );
};

const renderMarketingTeam = (params, contentClassName) => {
  const { metadata, recommendations } = params;
  const { data } = recommendations || {};
  return <UiMarketingTeam people={data} config={metadata} className={contentClassName} />;
};

const renderGridList = (params, intl, history, marketplace, currentUser) => {
  const { metadata } = params;
  const { title, description, actions, ui } = metadata;
  return (
    <GridListActionsWithSharedBorders
      history={history}
      options={ui && ui.actionsWithSharedBorders}
      intl={intl}
      actions={actions}
      description={description}
      currentUser={currentUser}
      marketplace={marketplace}
      title={title}
    />
  );
};

const renderPricing = (params, intl, history, marketplace, currentUser) => {
  const { metadata } = params;
  const { title, pricing, description, pricingFeatures, titleClassNames } = metadata;
  return (
    <SectionLandingPricing
      titleClassNames={titleClassNames}
      history={history}
      intl={intl}
      pricing={pricing}
      pricingFeatures={pricingFeatures}
      description={description}
      currentUser={currentUser}
      marketplace={marketplace}
      title={title}
      className="mb-10"
    />
  );
};

const renderText = params => {
  const { metadata } = params;
  const { title, description, titleClassNames = {}, descriptionClassNames = {}, ...rest } = metadata;

  const alignClassName = titleClassNames.textAlign || '';
  return (
    <div className="mx-container">
      <UiTextSection
        {...rest}
        titleClassNames={titleClassNames}
        className={alignClassName}
        title={title}
        description={description}
        descriptionClassNames={descriptionClassNames}
      />
    </div>
  );
};

const renderFaq = params => {
  const { metadata } = params;
  const { title, questions } = metadata;
  return <SectionLandingFaq questions={questions} title={title} className="mb-10" />;
};

const renderLogos = params => {
  const { metadata } = params;
  const { title, logos, titleClassNames } = metadata;
  return <SectionLandingLogos logos={logos} titleClassNames={titleClassNames} title={title} className="mb-10" />;
};

const getSectionConfig = (
  params,
  desktop,
  intl,
  history,
  marketplace,
  currentUser,
  currentUserBookmarkedListings,
  preview,
  contentClassName,
  hasListings,
  type,
  index
) => {
  params.type = type;
  const { metadata } = params;
  const { ui } = metadata || {};
  const { type: uiType } = ui || {};

  switch (uiType) {
    case 'grid':
      return {
        component: Grid({ ...params, desktop, currentUserBookmarkedListings }),
      };
    case 'carousel':
      return {
        component: renderCarousel(params, currentUserBookmarkedListings, index),
      };
    case 'heroLink':
      return {
        component: renderHeroLink(params),
      };
    case 'imageFeature': {
      const isVertical = params?.metadata?.ui?.imageFeature?.vertical;
      const isFullWidth = params?.metadata?.ui?.imageFeature?.fullWidth;
      const imageFeatureFullWidth = isVertical && isFullWidth;
      return {
        component: renderImageFeature(params, imageFeatureFullWidth),
        fullWidth: imageFeatureFullWidth,
      };
    }
    case 'callToAction':
      return {
        component: renderCallToAction(params),
      };
    case 'feature': {
      const featureType = params?.metadata?.ui?.feature?.type;
      const fullWidth = ['selectorGrid', 'selectorList', 'splitMedia'].includes(featureType);
      return {
        component: renderFeature(params),
        fullWidth,
      };
    }
    case 'team':
      return {
        component: renderMarketingTeam(params, contentClassName),
      };
    case 'text':
      return {
        component: renderText(params),
      };
    case 'pricing':
      return {
        component: renderPricing(params, intl, history, marketplace, currentUser),
      };
    case 'faq':
      return {
        component: renderFaq(params),
      };
    case 'logos':
      return {
        component: renderLogos(params),
      };
    case 'gridList':
      return {
        component: renderGridList(params),
      };
    case 'search':
      return {
        component: renderSearchSection({ ...params, history }),
      };
    case 'testimonial':
      return {
        component: UiTestimonial(params, contentClassName),
      };
    case 'category':
      return {
        component: UiCategory({ ...params, preview, contentClassName }),
      };
    case 'contactAndSocials':
      return {
        component: UiContactAndSocials({ ...params, preview, contentClassName }),
      };
    case 'paragraph':
      return {
        component: UiParagraph({ ...params, preview, contentClassName }),
      };
    case 'locations':
      return {
        component: UiLocations({ ...params, preview, contentClassName }),
      };
    case 'list':
      return {
        component: UiList({ ...params, preview, contentClassName, hasListings }),
      };
    case 'media':
      return {
        fullWidth: true,
        component: UiMediaSection({ ...params, priority: index < 3 }),
      };
    case 'link':
      return {
        component: UiLink({ ...params, preview, contentClassName }),
      };
    case 'hours':
      return {
        component: UiBizHours({ ...params, preview, contentClassName }),
      };
    case 'contact':
      return {
        component: UiContactForm({ ...params, preview, contentClassName, history }),
      };
    default:
      return {
        component: null,
      };
  }
};

const getSectionClassName = ({ sectionSettings, className }) => {
  // Todo: rewrite this to scale for all class variations
  const {
    marginBottomClassName = '',
    marginTopClassName = '',
    marginTopSmClassName = '',
    paddingTopClassName = '',
    paddingBottomClassName = '',
    classNames,
  } = sectionSettings || {};

  const hasCustomSpacing =
    marginBottomClassName || marginTopClassName || marginTopSmClassName || paddingTopClassName || paddingBottomClassName;

  const customClassName = hasCustomSpacing
    ? `${
        marginBottomClassName || 'mb-16 sm:mb-20'
      } ${marginTopClassName} ${paddingTopClassName} ${paddingBottomClassName} ${marginTopSmClassName}`
    : '';

  const spacingClassName = customClassName || className;

  let extraClassNames = '';
  // loop through classnames object and add to extraClassNames
  if (classNames) {
    Object.keys(classNames).forEach(key => {
      if (classNames[key]) {
        extraClassNames += ` ${classNames[key]}`;
      }
    });
  }
  return `${spacingClassName} ${extraClassNames}`;
};

const RenderSections = ({
  sections,
  desktop,
  intl,
  history,
  marketplace,
  currentUser,
  currentUserBookmarkedListings,
  loading,
  auto,
  spacingClassName = 'mb-16 sm:mb-20',
  preview,
  contentClassName,
  hasListings,
  type,
}) => {
  if (!sections || !sections.length || (auto && loading)) {
    if (auto && !loading) return null;

    return (
      <div className="flex flex-1 flex-row items-center justify-center py-14">
        <IconSpinner type="tailwind" />
      </div>
    );
  }

  // Remove hero type, it should be rendered outside the container
  sections = sections.filter(section => section.metadata.ui.type !== 'hero');
  const hasMultipleSections = sections.length > 1;
  const className = hasMultipleSections ? spacingClassName : '';
  return sections.map((section, index) => {
    const { component, fullWidth = false } = getSectionConfig(
      section,
      desktop,
      intl,
      history,
      marketplace,
      currentUser,
      currentUserBookmarkedListings,
      preview,
      contentClassName,
      hasListings,
      type,
      index
    );

    const sectionSettings = section?.metadata?.section || {};
    const sectionClassName = getSectionClassName({ sectionSettings, className });

    const uiType = section?.metadata?.ui?.type;
    if (!component) return null;
    return (
      <div key={`${camelize(section.metadata.title)}_${index}`} className={sectionClassName}>
        <div className="relative">
          <div id={uiType} className="absolute -top-24 left-0"></div>
        </div>

        <div className={!fullWidth ? 'max-w-container mx-auto' : ''}>{component}</div>
      </div>
    );
  });
};

// Render sections that are setup with the ui builder
const UiRenderer = props => {
  const {
    data,
    marketplace,
    // history,
    onManageLargeSearchBar,
    onManageTransparentHeader,
    error,
    intl,
    currentUser,
    currentUserBookmarkedListings,
    auto = false,
    playBlockId,
    className = '',
    minHeightClassName = 'min-h-screen',
    bgColorClassName = 'bg-white-background',
    spacingClassName = 'mb-16 sm:mb-20',
    preview = false,
    contentClassName = 'px-container',
    hasListings = false,
    brandingOptions: initialBrandingOptions,
    type,
    children,
  } = props;
  let { sectionsWrapperClassName = 'mt-4 sm:mt-8' } = props;

  const marketplaceStateKey = marketplace?.key;
  const history = useHistory();
  const pathname = history?.location?.pathname;
  const [autoData, setAutoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brandingOptions, setBrandingOptions] = useState(initialBrandingOptions);

  const dispatch = useDispatch();
  const logo = initialBrandingOptions?.logo;
  useEffect(() => {
    if (logo?.svg) {
      dispatch(setLogo(logo));
    }
    return () => {
      dispatch(setLogo(null));
    };
  }, [logo, dispatch]);

  // TODO: debounce this or ensure it is not called too often
  useEffect(() => {
    if (!brandingOptions) return;
    setUiTheme({ brandingOptions });
    return () => setUiTheme({ brandingOptions, restore: true });
  }, [brandingOptions, preview, marketplaceStateKey]);

  useEffect(() => {
    if (!auto || !playBlockId) {
      if (autoData) setAutoData(null);
      return;
    }

    setLoading(true);
    playBlocks
      .view(playBlockId)
      .then(response => {
        const { sections, brandingOptions: branding } = response?.data || {};
        setAutoData(sections);
        setBrandingOptions(branding);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [auto, playBlockId]);

  const desktop = useMediaQuery('(min-width:1200px)');
  const finalData = data || autoData;

  let heroSection = finalData?.find(section => section.metadata.ui.type === 'hero');
  const forceDarkTheme = brandingOptions?.dark?.force;
  const darkDisabled = brandingOptions?.dark?.disabled;

  // If dark theme is forced, dispatch the correct theme
  useEffect(() => {
    if (forceDarkTheme) {
      const classList = document.documentElement.classList;
      if (!classList.contains('dark')) classList.add('dark');
      dispatch(setDarkThemeForcedForRoute(true));
      dispatch(setColorMode('dark'));
    }
  }, [forceDarkTheme]);

  // If dark theme is disabled for this page, remove the dark class and dispatch the action
  useEffect(() => {
    if (!darkDisabled) return;
    const classList = document.documentElement.classList;
    classList.remove('dark');
    dispatch(setDarkThemeDisabledForRoute(true));
    dispatch(setColorMode('light'));
  }, [darkDisabled]);

  const slideType = get(heroSection, 'metadata.ui.hero.slideType');
  const isHeroHidden = slideType === 'hidden';
  const resetHeader = () => {
    dispatch(setTransparentHeader(false, { right: false, left: false }));
  };

  useEffect(() => {
    if (isHeroHidden) resetHeader();
  }, [isHeroHidden]);

  // reset the transparent header everytime the path changes and there was a hero section
  useEffect(() => {
    return () => {
      try {
        // When dark theme is disabled or forced, the color mode needs to be reset
        if (forceDarkTheme || darkDisabled) {
          dispatch(setDarkThemeForcedForRoute(false));
          dispatch(setDarkThemeDisabledForRoute(false));
          // reset color mode and any custom dark theme settings
          updateColorModeState();
        }
        if (heroSection) {
          resetHeader();
        }
      } catch {}
    };
  }, [pathname]);

  const pageRoundedType = get(heroSection, 'metadata.ui.hero.pageRoundedType');
  const roundedClassName =
    !heroSection || slideType === 'hidden' || slideType === 'split' || slideType === 'stacked' || slideType === 'split-screenshot'
      ? ' '
      : pageRoundedType === 'none'
      ? 'relative -mt-6'
      : `relative -mt-6 z-5 rounded-t-xl sm:rounded-t-2xl bg-white-background`;

  const navigationType = marketplace?.pageConfig?.advanced?.navigationType;
  const isMarketingHeader = navigationType === 'customMarketingStacked';
  const noHeroSection = !heroSection || slideType === 'hidden';
  if (noHeroSection && sectionsWrapperClassName === 'mt-4 sm:mt-8' && isMarketingHeader) {
    sectionsWrapperClassName = 'mt-4';
  }

  return (
    <>
      {!isHeroHidden ? (
        <HeroMaybe
          sections={finalData}
          marketplace={marketplace}
          onManageTransparentHeader={onManageTransparentHeader}
          history={history}
          onManageLargeSearchBar={onManageLargeSearchBar}
          preview={preview}
        />
      ) : null}

      {children}

      <div className={`${className} ${roundedClassName} ${minHeightClassName} ${bgColorClassName}`}>
        {error ? (
          <div className="max-w-container mx-auto">
            <ErrorMessage
              className="m-container mt-24 max-w-lg"
              title="There was an error connecting to discover"
              description={error.userMessage}
            />
          </div>
        ) : (
          <div className={sectionsWrapperClassName}>
            <RenderSections
              sections={finalData}
              desktop={desktop}
              intl={intl}
              history={history}
              marketplace={marketplace}
              currentUser={currentUser}
              currentUserBookmarkedListings={currentUserBookmarkedListings}
              loading={loading}
              auto={auto}
              spacingClassName={spacingClassName}
              preview={preview}
              contentClassName={contentClassName}
              hasListings={hasListings}
              type={type}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UiRenderer;
