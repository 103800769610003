import { stringify } from '@/util/urlHelpers';

import { client } from './api';
import { getAccountId } from './auth';

const read = accountId => {
  const provider = 'stripe';
  // If no ID is sent grab logged in token
  if (!accountId) accountId = getAccountId();
  if (!accountId) {
    return new Promise(function (resolve, reject) {
      reject({
        error: {
          userMessage: 'No account found',
        },
      });
    });
  }

  const queryParams = {
    provider,
    account: accountId,
    limit: 1,
  };
  return client(`paymentAccount?${stringify(queryParams)}`);
};

const checkout = accountId => {
  return client(`paymentAccount/checkout/${accountId}`);
};

export { read, checkout };
