import { Component, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import IconSpinner from '@/components/IconSpinner/IconSpinner';
import NamedLink from '@/components/NamedLink/NamedLink';

import { getCurrentMarketplaceTheme } from '@/util/helpers';
import { ChevronDownIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import { CheckIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

class ButtonLight extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
  }
  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }
  render() {
    const {
      children,
      className,
      inProgress = false,
      ready,
      disabled,
      size,
      theme,
      href,
      type,
      hrefName,
      hrefNameIndex = 0,
      hrefParams,
      hrefTo,
      shadowClassName,
      extraButtonClassName,
      noShadow,
      fakeButton = false,
      inlineBlock = false,
      externalLink = false,
      fullWidthCenterText = false,
      wrapperClassName,
      replaceHref = false,
      positionClassName = 'relative',
      noFocusRing = false,
      filter = false,
      filterOpen = false,
      filterActive = false,
      filterDropdownIconHidden = false,
      filterIconOnly = false,
      marketplaceTheme: initialMarketplaceTheme,
      roundedClassName,
      linkClassName = '',
      extraFocusRingClassName = '',
      dataTest,
      innerRef,
      ...rest
    } = this.props;
    const marketplaceTheme = initialMarketplaceTheme || getCurrentMarketplaceTheme();

    const dropdownIconMaybe = filter && !filterDropdownIconHidden ? <ChevronDownIcon className="-mr-1 ml-1 h-4 w-4" /> : null;

    const _computeSizeClasses = size => {
      if (filter) {
        if (filterIconOnly) {
          return `${filterIconOnly === 'sm' ? 'p-1' : filterIconOnly === 'md' ? 'p-1.5' : 'p-2'} text-sm`;
        }
        return 'px-3 py-1 text-sm';
      }

      switch (size) {
        case 'none':
          return 'p-0';
        case 'xs-no-br-left':
          return 'px-2.5 py-1.5 text-xs leading-4';
        case '2xs':
          return 'px-2 py-1.5 text-xs leading-4';
        case 'xs':
          return 'px-2.5 py-1.5 text-xs leading-4';
        case 'sm':
          return 'px-3 py-2 text-sm leading-4';
        default:
        case 'md':
          return 'px-4 py-2 text-sm leading-5';
        case 'lg':
          return 'px-4 py-2 text-base leading-6';
        case 'lg-no-br-left':
          return 'px-4 py-2 text-base text-xs leading-6';
        case 'lg-uppercase':
          return 'px-4 py-2 text-xs sm:text-sm uppercase leading-6 truncate';
        case 'xl':
          return 'px-6 py-3 text-base leading-6';
        case '2xl':
          return 'px-8 py-3 md:py-4 md:text-lg md:px-10 text-base leading-6';
      }
    };

    const _computeRoundedClasses = size => {
      if (marketplaceTheme === 'playful') {
        return 'rounded-full';
      }

      if (filter) {
        if (filterIconOnly) return 'rounded-full';
        return 'rounded-4xl';
      }

      switch (size) {
        case 'none':
          return 'rounded-full';
        case 'xs-no-br-left':
        case 'lg-no-br-left':
          return 'rounded-r';
        case 'xs':
          return 'rounded';
        case 'sm':
        case 'lg':
        case 'lg-uppercase':
        case 'xl':
        case '2xl':
        case 'md':
        default:
          return 'rounded-md';
      }
    };

    const _computeThemeClasses = theme => {
      if (filter) {
        return `${
          filterActive
            ? 'font-semibold bg-gray-50 border-accent ring-1'
            : `${
                filterOpen
                  ? 'border-accent text-gray-800 font-semibold'
                  : 'font-normal text-gray-600 border-gray-200 hover:border-gray-700'
              } bg-white active:bg-gray-50 active:text-gray-800 active:ring-1 active:border-accent active:ring-offset-0`
        } whitespace-nowrap cursor-pointer inline-flex justify-center w-full leading-5 transition duration-150 ease-in-out border focus:outline-none ring-accent-500 ${
          filterOpen ? 'ring-1 font-semibold bg-gray-50' : ''
        }`;
      }

      switch (theme) {
        case 'transparent':
          return 'border-gray-100 text-white bg-transparent focus:ring-offset-0';
        case 'white':
        case 'filter':
          return 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50';
        case 'dark':
          return 'border-transparent text-white bg-gray-900 dark:text-gray-50';
        case 'white-light':
          return 'border-gray-200 text-gray-700 bg-white hover:bg-gray-50';
        case 'gray':
          return 'border-gray-300 bg-gray-50 hover:bg-gray-100 text-gray-700 bg-white hover:text-gray-500';
        case 'white-no-border':
          return 'border-transparent text-gray-700 bg-white hover:text-gray-500';
        case 'white-accent':
          return 'border-transparent text-accent-700 bg-white hover:text-accent-500 hover:bg-accent-50';
        case 'bg':
          return 'border-gray-300 text-gray-700 bg-transparent hover:text-gray-500';
        case 'transparent-no-border':
          return 'border-transparent text-white';
        case 'bg-underline':
          return 'border-transparent text-gray-700 bg-transparent hover:text-gray-500 underline hover:underline';
        case 'secondary':
        case 'filter-active':
          return 'text-primary-700 bg-primary-100 border-transparent hover:bg-primary-50 focus:border-primary-300 active:bg-primary-200';
        case 'accent-secondary':
          return 'text-accent-700 bg-accent-100 border-transparent hover:bg-accent-50 focus:border-accent-300 active:bg-accent-200';
        case 'pill':
          return 'border-transparent text-gray-700 bg-white hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 active:text-gray-900 active:bg-gray-100';
        case 'pill-gray':
          return 'border-transparent text-gray-700 bg-gray-50 hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 active:text-gray-900 active:bg-gray-100';
        case 'primary':
          return 'text-white bg-primary-600 border-transparent hover:bg-primary-500';
        case 'third':
          return 'text-white bg-third-600 border-transparent hover:bg-third-500';
        case 'accent':
        default:
          return 'text-white bg-accent-600 border-transparent hover:bg-accent-500';
      }
    };

    const _computeFocusRignClasses = () => {
      let focusRingClasses = 'focus:ring-2 focus:ring-offset-2';
      let focusRingColorClasses = 'focus:ring-accent-500';

      switch (theme) {
        case 'primary':
          focusRingColorClasses = 'focus:ring-primary-500';
          break;
        case 'accent':
        default:
          focusRingColorClasses = 'focus:ring-accent-500';
          break;
      }

      if (noFocusRing) {
        // focusRingClasses = 'active:ring-2 active:ring-offset-2 active:ring-accent-500';
        focusRingClasses = '';
      }
      if (filter) {
        focusRingClasses = '';
        focusRingColorClasses = '';
      }

      return `${focusRingClasses} ${focusRingColorClasses} ${extraFocusRingClassName}`;
    };

    const inlineClassName = !inlineBlock && !fullWidthCenterText ? 'inline-flex' : 'inline-block';

    let themeClass = _computeThemeClasses(theme, disabled, ready, inProgress, marketplaceTheme);
    // DISABLED THEME CLASSES
    if (disabled) {
      themeClass = twMerge(themeClass, 'cursor-not-allowed pointer-events-none');
      if (!ready && !inProgress) {
        themeClass = twMerge(themeClass, 'border-gray-200 text-white bg-gray-200');
      }
    }

    const roundedClass = roundedClassName || _computeRoundedClasses(size, marketplaceTheme);
    const sizeClass = _computeSizeClasses(size, marketplaceTheme);
    const buttonFullWidthClassNameMaybe = fullWidthCenterText ? 'w-full text-center' : '';

    const focusRingClassName = _computeFocusRignClasses();
    const buttonClassName = `${extraButtonClassName} ${inlineClassName} cursor-pointer items-center ${sizeClass} ${roundedClass} ${themeClass} border font-medium focus:outline-none ${focusRingClassName} ${buttonFullWidthClassNameMaybe} transition`;

    const shadowClass =
      shadowClassName || (theme !== 'secondary') & (theme !== 'bg') & (theme !== 'pill') ? shadowClassName || 'shadow-sm' : '';
    const cusrsorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
    const buttonWrapperFullWidthClassNameMaybe = fullWidthCenterText ? 'w-full' : '';
    const buttonWrapperClassName = `${inlineClassName} ${roundedClass} ${positionClassName} ${
      !noShadow ? shadowClass : null
    } ${cusrsorClass} ${buttonWrapperFullWidthClassNameMaybe} ${wrapperClassName}`;

    const disabledClassMaybe = disabled || inProgress ? 'pointer-events-none cursor-not-allowed' : '';
    const disabledClassMaybeNoLink = disabled || inProgress ? 'cursor-not-allowed pointer-events-none' : '';
    const spinnerMaybe = inProgress ? (
      <IconSpinner
        size="sm"
        colorTextClassName={
          theme === 'primary' || theme === 'accent' || theme === 'dark'
            ? theme === 'dark'
              ? 'text-white dark:text-black'
              : 'text-white'
            : 'text-accent'
        }
        type="tailwind"
      />
    ) : null;
    const checkMaybe = ready ? <CheckIcon className="h-8 w-8" /> : null;

    let content = children;
    if (spinnerMaybe) {
      content = (
        <>
          <div className="invisible">{children}</div>
          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">{spinnerMaybe}</div>
        </>
      );
    } else if (checkMaybe) {
      content = (
        <>
          <div className="invisible">{children}</div>
          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">{checkMaybe}</div>
        </>
      );
    }

    let buttonContent = (
      <span className={`${className} ${buttonWrapperClassName} ${disabledClassMaybeNoLink}`} {...rest}>
        <button
          ref={innerRef}
          data-test={dataTest}
          disabled={disabled || inProgress}
          type={type || 'button'}
          className={buttonClassName}
        >
          {content}
          {dropdownIconMaybe}
        </button>
      </span>
    );
    if (fakeButton) {
      buttonContent = (
        <span ref={innerRef} className={`${className} ${buttonWrapperClassName} ${disabledClassMaybeNoLink}`} {...rest}>
          <div disabled={disabled} className={buttonClassName} role="button" tabIndex="0">
            {content}
            {dropdownIconMaybe}
          </div>
        </span>
      );
    }

    const namedLink = (
      <NamedLink
        className={`${disabledClassMaybe} ${linkClassName}`}
        to={hrefTo}
        name={hrefName}
        nameIndex={hrefNameIndex}
        params={hrefParams}
      >
        {buttonContent}
      </NamedLink>
    );
    const normalLink = (
      <Link tabIndex="-1" className={`${disabledClassMaybe} ${linkClassName}`} to={href || '/'} replace={replaceHref}>
        {buttonContent}
      </Link>
    );
    const externalLinkContent = (
      <a
        ref={innerRef}
        className={`${disabledClassMaybe} ${linkClassName}`}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
      >
        {buttonContent}
      </a>
    );
    const link = hrefName ? namedLink : externalLink ? externalLinkContent : normalLink;

    return href || hrefName ? link : buttonContent;
  }
}

const { node, string, bool } = PropTypes;

ButtonLight.defaultProps = {
  className: null,
  inProgress: false,
  ready: false,
  disabled: false,
  children: null,
  theme: 'accent',
  size: 'md',
};

ButtonLight.propTypes = {
  className: string,
  theme: string,
  size: string,

  inProgress: bool,
  ready: bool,
  disabled: bool,

  children: node,
};

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => <ButtonLight innerRef={ref} {...props} />);

export const PrimaryButton = props => {
  return <ButtonLight {...props} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton = props => {
  return <ButtonLight {...props} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  return <ButtonLight {...props} />;
};
InlineTextButton.displayName = 'InlineTextButton';
