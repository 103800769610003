import { useEffect, useState } from 'react';

import startCase from 'lodash/startCase';
import useSWR from 'swr';

import NotFoundMessage from '@/containers/NotFoundPage/NotFoundMessage';

import HeroHeaderProfile from '@/components/HeroHeaderProfile/HeroHeaderProfile';
import IconSpinner from '@/components/IconSpinner';
import PageHeader from '@/components/PageHeader/PageHeader';
import PageHeaderMeta from '@/components/PageHeader/PageHeaderMeta';
import UiRenderer from '@/components/UiRenderer';

import { playBlocks } from '@/clients';

import { locationBounds } from '@/util/googleMaps';
import { formatWebsite, label } from '@/util/helpers';
import { createResourceLocatorString } from '@/util/routes';
import { types as sdkTypes } from '@/util/sdkLoader';
import { BuildingOfficeIcon, LinkIcon, MapPinIcon, PhoneIcon, StarIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

import { googlePhotosToMpcImages } from './placeHelpers';

const { LatLng } = sdkTypes;

const usePlace = ({
  type = '',
  country = '',
  state = '',
  city = '',
  slug = '',
  county = '',
  street = '',
  googleMapsLoaded = false,
}) => {
  const [data, setData] = useState<undefined | any>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!googleMapsLoaded) return;
    const el = document.createElement('div');
    const googlePlaces = new window.google.maps.places.PlacesService(el);
    setPlacesService(googlePlaces);
  }, [googleMapsLoaded]);

  useEffect(() => {
    if (!placesService) return;
    setLoading(true);

    placesService.textSearch(
      {
        query: `${startCase(slug)} ${street ? startCase(street) : ''} ${startCase(city)} ${county} ${state} ${country}`,
        type,
      },
      (results, status) => {
        if (status !== 'OK') {
          setLoading(false);
          setError(true);
          return;
        }
        console.log(results);

        const result = results?.[0];

        if (!result) {
          setLoading(false);
          setData(null);
          return;
        }

        const { place_id = '' } = result;
        placesService.getDetails(
          {
            placeId: place_id,
            fields: [
              'formatted_address',
              'geometry',
              'business_status',
              'formatted_address',
              'name',
              'website',
              'photo',
              'type',
              'url',
              'address_component',
              'adr_address',
              'formatted_phone_number',
              'international_phone_number',
              'vicinity',
              'user_ratings_total',
              'opening_hours',
            ],
          },
          (details, status) => {
            if (status !== 'OK') {
              setLoading(false);
              setError(true);
              return;
            }
            const response = {
              ...result,
              ...details,
            };
            console.log(response);
            setData(response);
            setLoading(false);
          }
        );
      }
    );
  }, [type, country, state, city, slug, street, placesService]);

  return {
    data,
    loading,
    error,
  };
};

const useRecommendations = (lat: string, lng: string, id: string) => {
  const { data: response, error } = useSWR(
    lat && lng && id ? `/playblocks/place-recommendations?lat=${lat}&lng=${lng}&id=${id}` : null,
    () =>
      playBlocks.placeRecommendations({
        lat,
        lng,
        id,
      })
  );
  const recommendations = response?.data || {};

  return {
    recommendations,
    loading: !response?.data && !error,
  };
};

const PlaceDetailPage = ({
  type,
  country,
  state,
  city,
  slug,
  county,
  street,
  googleMapsLoaded,
}: {
  type: Mpc.place['type'];
  country: string;
  state: string;
  city: string;
  slug: string;
  county: string;
  street?: string;
  googleMapsLoaded: boolean;
}) => {
  const { data, loading, error } = usePlace({
    googleMapsLoaded,
    type,
    country,
    state,
    city,
    county,
    slug,
    street,
  });
  const {
    name,
    photos,
    types,
    formatted_address,
    formatted_phone_number,
    rating,
    vicinity,
    user_ratings_total,
    website,
    url,
    geometry,
    place_id,
  } = data || {};
  const { location } = geometry || {};
  const { lat: _lat, lng: _lng } = location || {};
  const lat = _lat && _lat();
  const lng = _lng && _lng();

  const { recommendations, loading: loadingData } = useRecommendations(lat, lng, place_id);
  const { listings, providers } = recommendations || {};

  const isTypeAllowed = !!config.places.types[type];
  const is404 = !isTypeAllowed || (!loading && (error || !data));
  if (is404) {
    return (
      <div className="mt-4 px-container max-w-container mx-auto">
        <NotFoundMessage title={!isTypeAllowed ? 'Place Type Not Supported' : 'Place Not Found'} />
      </div>
    );
  }

  const card = {
    size: 'carousel-default-1/4',
  };
  const uiRendererData = [
    {
      type: 'static',
      recommendations: listings,
      metadata: {
        title: `Nearby ${label('Listings')}`,
        ui: { type: 'carousel', card },
      },
    },
    {
      type: 'static',
      recommendations: providers,
      metadata: {
        title: `Nearby ${label('Providers')}`,
        ui: { type: 'carousel', card },
      },
    },
  ];

  const coverImages = googlePhotosToMpcImages(photos);
  const coverImage = coverImages?.[0] || null;
  const typeLabel = types?.length ? startCase(types[0]) : startCase(type);
  const meta: { icon: React.ElementType; label: string; href?: string }[] = [{ icon: BuildingOfficeIcon, label: typeLabel }];
  if (vicinity || formatted_address) meta.push({ icon: MapPinIcon, href: url, label: vicinity || formatted_address });
  if (formatted_phone_number) meta.push({ icon: PhoneIcon, label: formatted_phone_number });
  if (rating) meta.push({ icon: StarIcon, label: `${rating}/5${user_ratings_total ? ` (${user_ratings_total})` : ''}` });
  if (website) meta.push({ icon: LinkIcon, href: website, label: formatWebsite(website, true) });

  let stateLabel = startCase(state);
  const cityLabel = startCase(city);
  const countyLabel = startCase(county);
  if (stateLabel?.length < 4) stateLabel = stateLabel.toUpperCase();
  const pageHeaderBreadcrumbs = [
    { title: 'Places', href: '/places' },
    { title: config.places.types[type], href: `/places/${type}` },
    { title: startCase(country).toUpperCase(), href: `/places/${type}/${country}` },
    { title: stateLabel, href: `/places/${type}/${country}/${state}` },
    { title: startCase(county), href: `/places/${type}/${country}/${state}/${county}` },
  ];
  if (cityLabel !== countyLabel) {
    pageHeaderBreadcrumbs.push({ title: startCase(city), href: `/places/${type}/${country}/${state}/${county}/${city}` });
  }
  if (street) {
    pageHeaderBreadcrumbs.push({
      title: startCase(street),
      href: `/places/${type}/${country}/${state}/${county}/${city}/${street}`,
    });
  }

  let searchUrl = '';
  if (lat && lng) {
    searchUrl = createResourceLocatorString(
      'SearchPage',
      routeConfiguration(),
      {},
      {
        address: vicinity || formatted_address || name || null,
        bounds: locationBounds(new LatLng(lat, lng), config.places.locationBoundsDistance),
      }
    );
  }

  return (
    <div className="min-h-screen mb-12">
      {loading ? (
        <div className="flex items-center mx-auto justify-center px-container max-w-container">
          <IconSpinner type="tailwind" />
        </div>
      ) : (
        <div className="max-w-container">
          <div className="px-container mb-12 space-y-8">
            <PageHeader
              titleClassName="text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl"
              title={name}
              meta={<PageHeaderMeta meta={meta} />}
              // buttonText="Save"
              secondaryButtonHref={searchUrl}
              secondaryButtonText={'View Map'}
              breadcrumbs={pageHeaderBreadcrumbs}
              breadcrumbsShowHome
            />
            {/* @ts-ignore */}
            <HeroHeaderProfile
              mulitImageClassName="px-0"
              desktop
              type={'auto'}
              coverImage={coverImage}
              coverImages={coverImages}
            />
          </div>

          {loadingData ? (
            <div className="flex items-center mx-auto justify-center px-container max-w-container">
              <IconSpinner type="tailwind" />
            </div>
          ) : (
            <UiRenderer bgColorClassName="" data={uiRendererData} />
          )}
        </div>
      )}
    </div>
  );
};

export default PlaceDetailPage;
