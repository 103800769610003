/* Generic Styles */
.svg * {
  transition-property: fill;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.svg svg {
  fill: currentColor;
}

/*
  When native colors are not disabled and we are viewing a logo,
  default any non-colored paths's to contrast with bg
*/
.svg.logo:not(.disableNativeColors):not(.textWhite) svg {
  color: rgb(var(--mpc-color-gray-900));
}

/* disableNativeColors & disableNativeColorsDark */
.svg.disableNativeColors * :not([fill='none']),
:global(.dark) .svg.disableNativeColorsDark * :not([fill='none']) {
  fill: currentColor !important;
}

/* disableNativeColorsDarkBlack */
:global(.dark) .svg.disableNativeColorsDarkBlack *,
.svg.textWhite.disableNativeColorsDarkBlack * {
  & [fill='#222'],
  & [fill='#222222'],
  & [fill='#000'],
  & [fill='#000000'],
  & [fill='#2D3748'],
  & [fill='#252F3E'] {
    fill: currentColor !important;
  }
}
