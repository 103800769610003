export const actions = {
  account: {
    signedIn: 'Account Signed In',
    signedOut: 'Account Signed Out',
    signedUp: 'Account Signed Up',
    created: 'Account Created',
  },
  products: {
    searched: 'Products Searched',
  },
  product: {
    shared: 'Product Shared',
    viewed: 'Product Viewed',
    clicked: 'Product Clicked',
    listViewed: 'Product List Viewed',
    listFiltered: 'Product List Filtered',
  },
  promotion: {
    clicked: 'Promotion Clicked',
  },
  checkout: {
    started: 'Checkout Started',
  },
};
