import { string } from 'prop-types';

import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

const SectionLandingLogos = props => {
  const { title, logos = [], titleClassNames } = props;
  return (
    <div className="bg-white-background">
      <div className="mx-auto max-w-screen-xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <UiTextRenderer
          classNames={titleClassNames}
          defaultAlignClassName="text-center"
          defaultFontFamilyClassName="font-header"
          defaultFontSizeClassName="text-base"
          defaultLineHeightClassName="leading-6"
          defaultFontColorClassName="text-gray-600 text-header"
          defaultFontWeightClassName="font-semibold"
        >
          {title}
        </UiTextRenderer>

        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          {logos.map((logo, index) => {
            return (
              <div key={`logo_${index}`} className="dark:bg-dark-white col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                <img className="max-h-12" src={logo.image.metadata.url} alt="Workcation" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

SectionLandingLogos.defaultProps = { className: null };

SectionLandingLogos.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingLogos;
