import { string } from 'prop-types';

import DynamicLink from '@/components/DynamicLink/DynamicLink';
import Img from '@/components/Img/Img';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

const SectionHero = props => {
  const {
    title,
    description,
    className,
    buttonHref,
    buttonHrefMetadata = {},
    src,
    buttonText,
    imageId,
    fullWidthImage = false,
    titleClassNames = {},
    ui,
  } = props;

  const roundedClassName = ui?.heroLink?.borderRadiusClassName || 'rounded-lg';
  const imgWrapperClassNameDefault = `relative -mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 overflow-hidden ${roundedClassName}`;
  const imgWrapperClassNameFullWidth = `absolute top-0 left-0 right-0 bottom-0 z-5 overflow-hidden ${roundedClassName}`;
  const imgWrapperClassName = !fullWidthImage ? imgWrapperClassNameDefault : imgWrapperClassNameFullWidth;

  const imgClassNameDefault =
    'absolute overflow-hidden inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20';
  const imgClassNameFullWidth = 'absolute overflow-hidden rounded-md';
  const imgClassName = !fullWidthImage ? imgClassNameDefault : imgClassNameFullWidth;
  const imgTransformationType = !fullWidthImage ? 'lg' : 'ar-16:9-lg';

  const innerStyle = !fullWidthImage ? { backgroundPosition: 'initial' } : null;

  const descriptionTextColorClassName = !fullWidthImage ? 'text-primary-200' : 'text-white';
  const imgOverlayMaybe = fullWidthImage ? (
    <div className="z-1 absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25"></div>
  ) : null;

  return (
    <div className={`bg-white ${className} ${roundedClassName} relative overflow-hidden`}>
      <div className={`grid overflow-hidden ${roundedClassName} bg-primary-700 shadow-xl lg:grid-cols-2 lg:gap-4`}>
        <div className="z-10 px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-28 xl:px-20">
          <div className="lg:self-center">
            <UiTextRenderer
              classNames={titleClassNames}
              defaultFontWeightClassName="font-bold"
              defaultFontSizeClassName="text-3xl sm:text-4xl"
              defaultFontFamilyClassName="font-header"
              defaultFontColorClassName="text-white"
            >
              <h2>{title}</h2>
            </UiTextRenderer>

            <p className={`mt-4 text-lg leading-6 ${descriptionTextColorClassName}`}>{description}</p>

            <DynamicLink
              link={{
                hyperlink: buttonHref,
                ...buttonHrefMetadata,
              }}
              as="button"
              className="mt-8 inline-flex"
              theme="white"
              size="xl"
              noFocusRing
            >
              {buttonText}
            </DynamicLink>
          </div>
        </div>

        <div className={imgWrapperClassName}>
          {imgOverlayMaybe}
          <Img
            innerStyle={innerStyle}
            id={imageId}
            key={imageId}
            transformationType={imgTransformationType}
            src={src}
            className={imgClassName}
          />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { className: null };

SectionHero.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionHero;
