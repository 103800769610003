import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import useSWR from 'swr';

import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import Page from '@/components/Page/Page';
import UiRenderer from '@/components/UiRenderer/UiRenderer';

import { playBlocks } from '@/clients';
import { isScrollingDisabled } from '@/ducks/UI.duck';

import { injectIntl, intlShape } from '@/util/reactIntl';
import { propTypes } from '@/util/types/propTypes';

export const PlayBlockPageComponent = props => {
  const { history, intl, scrollingDisabled, marketplace, currentUser, currentUserBookmarkedListings, params } = props;
  const { title: marketplaceTitle } = marketplace || {};
  const { id } = params || {};
  const { data, error } = useSWR(id ? `/api/playblocks/${id}` : null, () => playBlocks.view(id));

  const fetchError = error?.error?.userMessage;
  const { sections, title, brandingOptions, metadata } = data?.data || {};

  const siteTitle = marketplaceTitle;
  const { title: seoTitle, description: seoDescription, image: seoImage } = metadata?.seo || {};
  const pageTitle = seoTitle || title;
  const schemaTitle = `${pageTitle ? pageTitle : fetchError ? 'Error' : 'Loading...'} | ${siteTitle}`;
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      title={schemaTitle}
      description={seoDescription}
      image={seoImage}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: seoDescription,
      }}
    >
      <div className="max-w-container mx-auto w-full">
        {fetchError ? (
          <ErrorMessage
            className="mx-container max-w-2xl"
            title="There was an error fetching this page"
            description={fetchError}
          />
        ) : null}
      </div>

      {!fetchError ? (
        <UiRenderer
          key={id}
          currentUserBookmarkedListings={currentUserBookmarkedListings}
          currentUser={currentUser}
          data={sections}
          brandingOptions={brandingOptions}
          marketplace={marketplace}
          history={history}
          intl={intl}
        />
      ) : null}
    </Page>
  );
};

PlayBlockPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

PlayBlockPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserBookmarkedListings } = state.user;
  const marketplace = state.Marketplace;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    marketplace,
    currentUserBookmarkedListings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PlayBlockPage = compose(withRouter, connect(mapStateToProps), injectIntl)(PlayBlockPageComponent);

export default PlayBlockPage;
