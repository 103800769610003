import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const read = types => {
  const queryParams = { limit: 1000 };
  if (types) queryParams.type = `in:${types}`;
  return client(`categories?${stringify(queryParams)}`);
};

const create = body => {
  return client('categories', { body });
};

const update = (id, body) => {
  return client(`categories/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`categories/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000 }) => {
  return client(`categories?${stringify(params)}`);
};

export { read, list, update, create, remove };
