import { client } from './api';

const imagekitAuth = () => {
  return client('asset-service/auth/imagekit');
};

const uploadToImageKit = (file, id, imagekitConfig) => {
  const { token, expire, signature } = imagekitConfig;
  const body = new FormData();
  body.append('file', file);
  body.append('publicKey', 'public_ExoL1j6CWOi2wVQlEO8XvnOzsFA=');
  body.append('signature', signature);
  body.append('expire', expire);
  body.append('token', token);
  body.append('fileName', id);

  const config = {
    method: 'POST',
    body,
  };

  // Fetch call
  return fetch('https://upload.imagekit.io/api/v1/files/upload', config).then(async response => {
    // Parse response
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

const uploadImage = (file, id) => {
  return imagekitAuth().then(response => uploadToImageKit(file, id, response.data));
};

export { uploadImage };
