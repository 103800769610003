import { Component } from 'react';

import { getContnetThumbnail } from '@/clients/listings';

import { getImageKitTranformationsForType } from '@/util/helpers';
import { ExclamationCircleIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

class Img extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      src: null,
      errorIconMaybe: null,
    };
  }

  componentDidMount() {
    if ('IntersectionObserver' in window) {
      this.setObserver();
    } else {
      this.setPreloader();
    }
  }

  setObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setPreloader();
          this.observer.disconnect();
        }
      });
    });

    this.observer.observe(this.el);
  }

  generateImageKitUrl() {
    let { id, data, transformationType, transformations = 'tr:fo-auto', disableSmartCrop } = this.props;

    if (!id) {
      if (data?.metadata?.name) {
        id = data?.metadata.name;
      } else {
        return;
      }
    }

    let transformation = transformations;
    if (transformationType && transformations === 'tr:fo-auto') {
      transformation = getImageKitTranformationsForType(transformationType, { disableSmartCrop });
    }

    return `https://ik.imagekit.io/346d752Y/${transformation}/${id}`;
  }

  generateUrl() {
    const { provider = 'imagekit' } = this.props;
    switch (provider) {
      case 'imagekit':
      default:
        return this.generateImageKitUrl();
    }
  }

  setPreloader() {
    const { src: localSrc, id, muxThumbnailParams } = this.props;
    let src = localSrc && !id ? localSrc : this.generateUrl();

    if (muxThumbnailParams) {
      // Start thumbnail request if not started
      if (!this.state.thumbnailPromiseInProgress && !this.state.thumbnailPromiseSrc && !this.state.thumbnailPromiseError) {
        getContnetThumbnail(muxThumbnailParams)
          .then(url => {
            this.setState({
              thumbnailPromiseSrc: url,
              thumbnailPromiseInProgress: false,
              thumbnailPromiseError: false,
            });
            this.setPreloader();
          })
          .catch(() => {
            this.setState({
              thumbnailPromiseInProgress: false,
              thumbnailPromiseError: true,
            });
          });
      } else if (this.state.thumbnailPromiseSrc) {
        src = this.state.thumbnailPromiseSrc;
      }
    }

    this.preloader = new Image();

    this.preloader.onload = () => {
      if (this.props.onLoad) this.props.onLoad();

      this.setState({
        loaded: true,
        src: `url(${src})`,
        errorIconMaybe: null,
      });
    };

    this.preloader.onerror = () => {
      if (this.props.onError) this.props.onError();

      this.setState({
        loaded: true,
        errorIconMaybe: !this.props.noErrorIcon ? (
          <div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-1/4">
            <ExclamationCircleIcon className="h-full w-full text-gray-400" />
          </div>
        ) : null,
      });
    };

    this.preloader.src = src;
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
    if (this.preloader) this.preloader.onload = null;
  }

  render() {
    const backgroundSize =
      this.props.innerStyle && this.props.innerStyle.backgroundSize ? this.props.innerStyle.backgroundSize : 'cover';
    const backgroundPosition =
      this.props.innerStyle && this.props.innerStyle.backgroundPosition ? this.props.innerStyle.backgroundPosition : 'center';
    const backgroundRepeat =
      this.props.innerStyle && this.props.innerStyle.backgroundRepeat ? this.props.innerStyle.backgroundRepeat : 'no-repeat';
    const colors = ['#FFE5D3', '#CCF8FE', '#DAD7F', '#E2FBD7', '#FFF5CC'];

    let bgColor;
    switch (this.props.bgColor) {
      case 'primary':
        bgColor = 'rgb(var(--mpc-color-primary-200))';
        break;
      case 'white':
        bgColor = 'rgb(var(--mpc-color-gray-50))';
        break;
      case 'black':
        bgColor = '#000000';
        break;
      default:
        bgColor = this.props.bgColor;
        break;
    }

    const backgroundColor = !this.props.bgColorClassName
      ? bgColor || 'rgb(var(--mpc-color-gray-100))' || colors[Math.floor(Math.random() * colors.length)]
      : null;

    return (
      <div
        // Required: Relative, absolute, or fixed position
        // Required: Width & height (explicitly or via top/right/bottom/left)
        // Optional: Background color or placeholder image
        onClick={() => {
          if (this.props.onClick) this.props.onClick();
        }}
        className={`${this.props.className} ${this.props.bgColorClassName} absolute top-0 right-0 bottom-0 left-0`}
        style={{
          backgroundColor,
          ...this.props.style,
        }}
        ref={el => (this.el = el)}
      >
        {this.state.errorIconMaybe}
        <div
          className={this.props.imgClassName}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            backgroundImage: this.state.src,
            backgroundSize: backgroundSize,
            backgroundPosition: backgroundPosition,
            backgroundRepeat: backgroundRepeat,
            transition: `opacity ${this.props.duration || '350ms'} ${this.props.ease || 'cubic-bezier(0.4, 0, 0.2, 1)'}`,
            opacity: this.state.loaded ? 1 : 0,
          }}
        ></div>
        {this.props.children}
      </div>
    );
  }
}

export default Img;
