import * as css from '@/components/BottomBar/BottomBar.module.css';

const BottomNav = props => {
  const {
    children,
    noOffset = false,
    navBgColorClassName = 'bg-white-background/90 dark:bg-dark-white/90',
    navBlurClassName = 'backdrop-blur-md dark:backdrop-blur-md',
    navClassName = 'rounded-t-2xl border-t border-gray-200 sm:hidden',
    className = 'flex flex-1 flex-row px-container items-center justify-between truncate',
  } = props;
  return (
    <>
      {!noOffset ? <div className="h-[90px]"></div> : null}
      <nav className={`${css.root} noUserSelect ${navClassName} ${navBgColorClassName} ${navBlurClassName}`}>
        <div className={className}>{children}</div>
      </nav>
    </>
  );
};

export default BottomNav;
