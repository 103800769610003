import { Component } from 'react';

import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';

import ValidationError from '@/components/ValidationError/ValidationError';

import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl';

const defaultLabelClassName = 'block mb-1 text-sm font-medium leading-5 text-gray-700';
class LocationAutocompleteInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      labelClassName = defaultLabelClassName,
      inputClassName,
      className,
      defaultLocations,
      ...restProps
    } = this.props;
    const { input, label, hint, meta, valueFromForm, ...otherProps } = restProps;
    /* eslint-enable no-unused-vars */

    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
    const locationAutocompleteProps = { rootClassName, inputClassName, label, meta, ...otherProps, input: { ...input, value } };
    const labelInfo = label ? (
      <label className={`${labelClassName || defaultLabelClassName} mb-1 block`} htmlFor={input.name}>
        {label}
      </label>
    ) : null;

    return (
      <div className={className}>
        {labelInfo}
        <LocationAutocompleteInputImpl defaultLocations={defaultLocations} tailwind {...locationAutocompleteProps} />
        <ValidationError fieldMeta={meta} />
        {hint && !meta?.error ? <div className={`mt-2 mb-1 text-sm text-gray-500`}>{hint}</div> : null}
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
