import { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import Image from '@marketplace-co/react-application-element-image';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import Alert from '@/components/Alert/Alert';
import { AvatarLarge } from '@/components/Avatar/Avatar';
import Badge from '@/components/Badge/Badge';
import ButtonLight from '@/components/Button/ButtonLight';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import ImgLogo from '@/components/ImgLogo/ImgLogo';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

import { accounts, auth } from '@/clients';
import { setAuthModalOpened } from '@/ducks/UI.duck.js';
import { setCurrentUserBookmarkedAccounts, setCurrentUserBookmarkedListings } from '@/ducks/user.duck.js';

import { track } from '@/analytics';
import { formatPriceStringForDisplay } from '@/util/currency';
import {
  computeDynamicHref,
  generateImageKitUrl,
  getAccountAddress,
  getAccountLocationDetails,
  getAccountName,
  getListingTitleFromType,
  label,
  placeResultToPlacePage,
  unCamelize,
} from '@/util/helpers';
import { toast } from '@/util/toast';
import { LISTING_STATE_DRAFT } from '@/util/types/propTypes';
import { createSlug } from '@/util/urlHelpers';
import { HeartIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

import * as css from './Card.module.css';

const getRoundedClassName = borderRadius => {
  switch (borderRadius) {
    case 'sm':
      return 'rounded-sm';
    case 'md':
      return 'rounded-md';
    case 'lg':
      return 'rounded-lg';
    case 'none':
      return 'rounded-none';
    default:
      return borderRadius || 'rounded-md';
  }
};

const TagsMaybe = ({ showTags, tags, dataType }) => {
  return (showTags || dataType === 'article') && tags?.length ? (
    <div className="mb-1">
      <div className="c-gap c-gap-1">
        {tags.map(tag => (
          <span key={tag} className="text-accent inline-flex items-center text-xs font-semibold leading-4">
            {unCamelize(tag)}
          </span>
        ))}
      </div>
    </div>
  ) : null;
};

const StatusMaybe = ({ showStatus, status }) => {
  return showStatus && status ? <Badge label={status} /> : null;
};

const SaveButton = ({ id, isSaved: initialIsSaved, dataType }) => {
  if (dataType === 'account') {
    const store = window.app && window.app.store;
    const state = store.getState();
    const { currentUserBookmarkedAccounts } = state.user || {};
    initialIsSaved = currentUserBookmarkedAccounts.includes(id);
  } else if (!initialIsSaved) {
    try {
      const state = window.app.store.getState();
      const { currentUserBookmarkedListings = [] } = state.user || {};
      initialIsSaved = currentUserBookmarkedListings.includes(id);
    } catch {}
  }
  const [isSaved, setIsSaved] = useState(initialIsSaved);
  const fill = isSaved ? 'rgb(var(--mpc-color-accent-500, var(--mpc-color-primary-500)))' : 'rgba(0,0,0,0.5)';

  return (
    <button
      aria-label="Favorite"
      className="z-1 absolute top-0 right-0 transform-none cursor-pointer p-2.5 transition focus:outline-none active:scale-90 active:transform"
    >
      <HeartIcon
        aria-label="Favorite"
        onClick={() => {
          const isAuthenticated = !!auth.getToken();
          const store = window.app && window.app.store;
          const dispatch = store.dispatch;

          const saveBookmarkedListings = localBookmarkedListings => {
            const state = store.getState();
            const { currentUser, currentUserBookmarkedListings, currentUserBookmarkedAccounts } = state.user || {};
            const { bookmarkedListings: backupBookmarkedListings } = currentUser || {};

            let bookmarkedListings = currentUserBookmarkedListings || backupBookmarkedListings;
            let newBookmarkedListings = localBookmarkedListings || currentUserBookmarkedListings || bookmarkedListings || [];

            let bookmarkedAccounts = currentUserBookmarkedAccounts || [];
            const isAccount = dataType === 'account';

            if (isSaved) {
              if (isAccount) {
                bookmarkedAccounts.splice(bookmarkedAccounts.indexOf(id), 1);
              } else {
                newBookmarkedListings.splice(bookmarkedListings.indexOf(id), 1);
              }
            } else {
              if (isAccount) {
                bookmarkedAccounts.unshift(id);
              } else {
                newBookmarkedListings.unshift(id);
              }
            }

            const body = isAccount ? { bookmarkedAccounts } : { bookmarkedListings: newBookmarkedListings };
            const entityLabel = isAccount ? 'Provider' : label('Listing');
            accounts
              .update(body)
              .then(() => {
                toast(`${entityLabel} ${isSaved ? 'Removed' : 'Saved'}`, {
                  autoClose: 2500,
                  position: 'bottom-left',
                  newestOnTop: true,
                  limit: 1,
                });

                setIsSaved(!isSaved);
                if (isAccount) {
                  dispatch(setCurrentUserBookmarkedAccounts(bookmarkedAccounts));
                } else {
                  dispatch(setCurrentUserBookmarkedListings(newBookmarkedListings));
                }
                // dispatch(fetchCurrentUser());
              })
              .catch(() => {
                toast.error(`There was an error saving this ${entityLabel}`);
              });
          };

          if (!isAuthenticated) {
            dispatch(
              setAuthModalOpened(true, account => {
                const bookmarkedListings = (account && account.bookmarkedListings) || [];
                const hasListingBookmarked = bookmarkedListings.includes(id);
                if (hasListingBookmarked) return;

                saveBookmarkedListings(bookmarkedListings);
              })
            );
            return;
          }

          saveBookmarkedListings();
        }}
        fill={fill}
        className="h-7 w-7 text-white"
      />
    </button>
  );
};

const DraftWarningMaybe = ({ isDraft, title, isAdmin }) => {
  return isDraft ? (
    <div className="absolute flex h-full w-full items-center justify-center bg-gray-100">
      <Alert
        className="m-6"
        noMargins
        type="warning"
        description={
          <>
            {title} is a draft and can't be booked. &nbsp;
            {isAdmin ? (
              <span className="text-warning-700 hover:text-warning-600 font-medium underline transition duration-150 ease-in-out">
                Finish creating your listing.
              </span>
            ) : null}
          </>
        }
      />
    </div>
  ) : null;
};

const ImageMaybe = ({
  roundedClassName,
  transformationType,
  title,
  disableSmartCrop,
  images,
  image,
  useImageCarousel,
  imageCarouselLink,
  bgColorClassName,
  priority,
}) => {
  if (useImageCarousel) {
    return (
      <ImageCarousel
        hrefComponent={imageCarouselLink}
        className="!absolute inset-0 "
        aspectRatioClassName=""
        roundedClassName={roundedClassName}
        images={images}
        priority={priority}
      />
    );
  }

  return image ? (
    <Image
      className={`absolute transform-gpu ${bgColorClassName} ${roundedClassName} h-full w-full object-cover`}
      layout="fill"
      unoptimized
      // pass blurhash
      image={{
        metadata: {
          blurhash: image?.metadata?.blurhash,
        },
      }}
      src={generateImageKitUrl({ transformationType, disableSmartCrop, image })}
      alt={title}
      priority={priority}
    />
  ) : null;
};

const ImageOverlayContentMaybe = ({
  location,
  hideLocation,
  description,
  title,
  city,
  roundedClassName,
  cardType,
  withOverlay,
  alwaysShowOverlayContent,
}) => {
  const shouldShowOverlay = withOverlay || cardType === 'media-button';
  const shouldAlwaysShowOverlayContent = alwaysShowOverlayContent || cardType === 'media-button';

  const defaultOverlayContent = (
    <div className="flex flex-row items-center truncate p-4 sm:p-6">
      <div className="truncate">
        <div className="whitespace-pre-wrap text-base font-semibold leading-6 text-white sm:text-lg md:text-xl">{title}</div>
        {location && !hideLocation ? <div className="mt-1 truncate text-sm text-white sm:text-base">{city}</div> : null}
      </div>
    </div>
  );

  const max = 50;
  const shortDescription = description ? (description.length > max ? description.substring(0, max) : null) : null;
  const mediaButtonOverlayContent = (
    <div className="truncate p-4 sm:p-6">
      {description ? (
        <div className="mb-4 whitespace-normal text-center text-sm text-white">
          {shortDescription ? <>{shortDescription}...</> : description}
        </div>
      ) : null}
      <ButtonLight fullWidthCenterText size="lg-uppercase" theme="transparent">
        {title}
      </ButtonLight>
    </div>
  );

  return shouldShowOverlay ? (
    <>
      <div className={`${css.overlay} ${roundedClassName}`}></div>
      <div
        className={`${css.overlayContent} ${
          shouldAlwaysShowOverlayContent ? css.overlyContentPersistent : ''
        } absolute right-0 top-0 bottom-0 left-0 flex flex-col justify-end`}
      >
        {cardType === 'media-button' ? mediaButtonOverlayContent : defaultOverlayContent}
      </div>
    </>
  ) : null;
};

const CardLink = props => {
  const {
    children,
    externalLink,
    cardClassNames,
    contentWidthStyle,
    id,
    isDraft,
    isAdmin,
    hyperLink,
    linkType,
    hyperLinkListingIdName,
    hyperlinkQueryParams,
    metadata,
    dataType,
    onClick,
    noHref,
    manageActiveListing,
    setActiveListing = () => {},
    hideSaveButton,
    isSaved,
    title,
    returnLinkOnly,
    useImageCarousel,
    returnLinkProps,
    slug: initialSlug,
    place,
  } = props;

  // External link
  if (externalLink) {
    if (returnLinkOnly) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={externalLink} {...returnLinkProps}>
          {children}
        </a>
      );
    }

    if (useImageCarousel) {
      return (
        <div className={`${cardClassNames} group relative`} style={contentWidthStyle}>
          <a target="_blank" rel="noopener noreferrer" className="z-1 absolute inset-0" href={externalLink}>
            <div className="sr-only">Card Link</div>
          </a>
          {children}
        </div>
      );
    }

    return (
      <a target="_blank" rel="noopener noreferrer" href={externalLink} className={cardClassNames} style={contentWidthStyle}>
        {children}
      </a>
    );
  }

  const saveButtonMaybe =
    (dataType === 'listing' || dataType === 'account') && !hideSaveButton ? (
      <SaveButton id={id} dataType={dataType} isSaved={isSaved} />
    ) : null;
  const slug = createSlug(title || '');

  const onMouseEnter = () => setActiveListing(id);
  const onMouseLeave = () => setActiveListing(null);

  let href = `/l/${slug}/${id}`;
  if (isDraft) {
    if (!isAdmin) {
      href = '';
    } else {
      href = `/l/${slug}/${id}/draft/description`;
    }
  }

  if (hyperLink) {
    href = computeDynamicHref({
      hyperLink,
      type: linkType,
      listingId: hyperLinkListingIdName,
      queryParams: hyperlinkQueryParams,
      metadata,
    });
  }

  if (dataType === 'account') {
    href = `/u/${id}`;
  }

  if (dataType === 'article' && initialSlug) {
    href = `/news/${initialSlug}`;
  }

  if (dataType === 'place' && place) {
    href = placeResultToPlacePage(place);
  }

  const handleClick = e => {
    if (onClick) onClick(props, e);
    const isProduct = !hyperLink && !externalLink;
    const isPromotion = !isProduct;
    if (isPromotion) {
      track.promotion.clicked({ ...props, href });
    }

    if (isProduct) {
      track.product.clicked(props);
    }
  };

  if (noHref || !href) {
    const cursorClassName = href ? 'cursor-pointer' : '';
    if (returnLinkOnly) {
      if (onClick) {
        return <button {...returnLinkProps} onClick={handleClick}></button>;
      } else {
        return null;
      }
    }

    if (useImageCarousel) {
      return (
        <div className={`${cardClassNames} ${cursorClassName} group relative`} style={contentWidthStyle}>
          {onClick ? <div className="z-1 absolute inset-0" onClick={handleClick}></div> : null}
          {children}
        </div>
      );
    }

    return (
      <div onClick={handleClick} className={`${cardClassNames} ${cursorClassName}`} style={contentWidthStyle}>
        {children}
      </div>
    );
  }

  if (linkType === 'external') {
    if (returnLinkOnly) {
      return (
        <a target="_blank" rel="noopener noreferrer" onClick={handleClick} href={href} {...returnLinkProps}>
          {children}
        </a>
      );
    }

    return (
      <a
        onClick={handleClick}
        target="_blank"
        href={href}
        rel="noopener noreferrer"
        className={cardClassNames}
        style={contentWidthStyle}
      >
        {children}
      </a>
    );
  }

  if (returnLinkOnly) {
    return <Link onClick={handleClick} to={href} {...returnLinkProps}></Link>;
  }

  return (
    <div
      className={`${cardClassNames} group relative`}
      style={contentWidthStyle}
      onMouseEnter={manageActiveListing ? onMouseEnter : null}
      onMouseLeave={manageActiveListing ? onMouseLeave : null}
    >
      {useImageCarousel ? (
        <>
          <Link className="z-1 absolute inset-0" onClick={handleClick} to={href}></Link>
          {children}
        </>
      ) : (
        <Link onClick={handleClick} to={href}>
          {children}
        </Link>
      )}

      {saveButtonMaybe}
    </div>
  );
};

const AccountLogoOrAvatarMaybe = ({ dataType, logo, avatar, props }) => {
  return dataType === 'account' ? (
    <div className="light absolute -bottom-3 right-3 left-0 flex flex-row justify-end">
      {logo && logo.metadata && logo.metadata.url ? (
        <ImgLogo
          data={logo}
          imgProps={{ bgColorClassName: 'bg-white' }}
          className="w-3/12 overflow-hidden rounded ring-2 ring-white"
        />
      ) : avatar && avatar.metadata ? (
        <AvatarLarge className="h-12 w-12 rounded-full shadow-md ring-2 ring-white" user={props} disableProfileLink />
      ) : (
        <span className="block h-12 w-12 overflow-hidden rounded-full bg-gray-100 shadow-md ring-2 ring-white dark:bg-white">
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </div>
  ) : null;
};

const ContentMaybe = ({
  shouldHideText,
  includeDetails,
  type,
  city,
  titleTextSizeClassName,
  subtitleTextSizeClassName,
  location,
  hideLocation,
  locationOrDescription,
  bookingType,
  priceOptions,
  basePrice,
  title,
  baseCurrency,
  showStatus,
  status,
  showTags,
  tags,
  cardType,
  contentClassName = 'whitespace-normal',
  dataType,
  titleClassNames,
  descriptionClassNames,
}) => {
  if (shouldHideText) return null;

  if (cardType === 'simple') {
    return (
      <div className={contentClassName}>
        <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
          <h3>{title}</h3>
        </div>
        <p className="mt-1 text-sm italic text-gray-500">{locationOrDescription}</p>
      </div>
    );
  }

  // Detailed and default card types
  return (
    <div className={contentClassName}>
      {includeDetails ? (
        <div className="mb-1 text-sm font-semibold uppercase text-gray-800">
          <span>{getListingTitleFromType(type)}</span>{' '}
          {city ? (
            <>
              · <span>{city}</span>
            </>
          ) : null}
        </div>
      ) : null}

      <TagsMaybe dataType={dataType} showTags={showTags} tags={tags} />

      <UiTextRenderer
        classNames={titleClassNames}
        defaultFontSizeClassName={titleTextSizeClassName}
        defaultFontWeightClassName="font-semibold"
        defaultFontColorClassName="text-gray-900"
        defaultFontFamilyClassName=""
      >
        {title}
      </UiTextRenderer>

      <UiTextRenderer
        className="flex flex-row"
        classNames={descriptionClassNames}
        defaultFontSizeClassName={subtitleTextSizeClassName}
        defaultMarginTopClassName="mt-0.5"
        defaultFontWeightClassName=""
        defaultFontFamilyClassName=""
        defaultFontColorClassName="text-gray-500"
        defaultLineHeightClassName="leading-5"
      >
        {!includeDetails ? (
          !hideLocation ? (
            <div className={`flex flex-1 ${!location ? 'line-clamp-4' : 'whitespace-pre-wrap'}`}>{locationOrDescription}</div>
          ) : null
        ) : (
          <div>
            {bookingType === 'options' && priceOptions && priceOptions.length ? 'From ' : ''}
            {formatPriceStringForDisplay(basePrice, baseCurrency)}
          </div>
        )}
      </UiTextRenderer>

      <StatusMaybe showStatus={showStatus} tags={status} />
    </div>
  );
};

const getStyles = ({
  className,
  borderRadius,
  hideLocation,
  textSize,
  hideText,
  size,
  desktop,
  sizeDesktop,
  cardType,
  shadow,
  imgRoundedClassName,
  bgColor = 'bg-gray-50',
  titleClassNames,
  descriptionClassNames,
}) => {
  const shouldHideText = hideText || cardType === 'media-button';

  let transformationType = 'ar-16:9-sm',
    imgWrapperClassName = '',
    percent = 56.25,
    paddingBottom = '',
    extraClassName = '',
    containerStyles = {},
    borderClassName = 'border-r-6 border-l-6 sm:border-l-8 sm:border-r-8 border-transparent',
    cardMinWith = '280px';

  let contentWidthStyle = {
    width: `calc(100vw - ${percent}%)`,
    maxWidth: '540px',
    minWidth: cardMinWith,
  };

  const setSizeOptions = size => {
    switch (size) {
      // DEFAULT
      case 'md':
      default:
        percent = 60;
        contentWidthStyle.maxWidth = '250px';
        break;
      case 'auto':
        contentWidthStyle = {};
        break;

      // GRID TYPES
      case 'grid-fill':
      case 'grid-square':
        paddingBottom = 0;
        contentWidthStyle = { maxWidth: 'none' };
        paddingBottom = 'calc(100% - 146px)';
        if (hideLocation) paddingBottom = 'calc(100% - 190px + 22px)';
        if (textSize !== 'sm') {
          paddingBottom = 'calc(100% - 169px)';
          if (hideLocation) paddingBottom = 'calc(100% - 190px + 22px)';
        }
        if (shouldHideText) containerStyles = { height: '100%', width: '100%' };
        imgWrapperClassName = 'h-full w-full';
        transformationType = 'ar-16:9-md';
        break;
      case 'grid-default':
        percent = 56.25;
        contentWidthStyle = { maxWidth: 'none' };
        transformationType = 'ar-16:9-md';
        break;
      case 'grid-narrow':
        percent = 150;
        contentWidthStyle = {};
        transformationType = 'ar-2:3-sm';
        break;

      // Aspect ratios
      case '16-9':
        percent = 56.25;
        contentWidthStyle = {};
        transformationType = 'ar-16:9-md';
        break;
      case '1-1':
        percent = 100;
        contentWidthStyle = {};
        transformationType = 'ar-1:1-md';
        break;
      case '4-3':
        percent = 75;
        contentWidthStyle = {};
        transformationType = 'ar-4:3-md';
        break;
      case '3-4':
        percent = 125;
        contentWidthStyle = {};
        transformationType = 'ar-3:4-md';
        break;

      // CAROUSEL TYPES
      case 'carousel':
      case 'default':
        extraClassName = `md:w-1/3 ${borderClassName}`;
        contentWidthStyle = { minWidth: cardMinWith };
        break;
      case 'small':
        transformationType = 'ar-4:3-sm';
        percent = 75;
        extraClassName = `sm:w-1/3 md:w-1/4 ${borderClassName}`;
        contentWidthStyle = { minWidth: cardMinWith };
        break;
      case 'narrow':
      case 'carousel-narrow':
        transformationType = 'ar-2:3-sm';
        percent = 150;
        extraClassName = `sm:w-1/3 md:w-1/4 lg:w-1/5 ${borderClassName}`;
        contentWidthStyle = { minWidth: '230px' };
        break;
      case 'carousel-square-1/8':
        percent = 100;
        contentWidthStyle = { minWidth: '164px' };
        extraClassName = `w-7/12 sm:w-1/3 md:w-1/6 lg:w-1/8 ${borderClassName}`;
        transformationType = 'ar-1:1-sm';
        break;
      case 'carousel-square-1/5':
        percent = 100;
        contentWidthStyle = { minWidth: '175px' };
        extraClassName = `max-w-[175px] sm:max-w-none sm:w-1/3 md:w-1/5 ${borderClassName}`;
        transformationType = 'ar-1:1-md';
        break;
      case 'carousel-default-1/2':
        percent = 56.25;
        contentWidthStyle = { minWidth: '200px', maxWidth: 'none' };
        extraClassName = `${css.mobileCarouselWidth} w-full md:w-1/2 ${borderClassName}`;
        transformationType = 'ar-16:9-md';
        break;
      case 'carousel-default-1/4':
        percent = 56.25;
        contentWidthStyle = { minWidth: '200px', maxWidth: 'none' };
        extraClassName = `${css.mobileCarouselWidth} w-full sm:w-1/3 md:w-1/4 ${borderClassName}`;
        transformationType = 'ar-16:9-md';
        break;
      case 'carousel-narrow-1/4':
        transformationType = 'ar-2:3-md';
        percent = 150;
        extraClassName = `sm:w-1/3 md:w-1/4 ${borderClassName}`;
        contentWidthStyle = { minWidth: '200px' };
        break;
    }
  };

  // Size
  if (size) setSizeOptions(size);

  // Desktop Size Maybe
  if (desktop === true && sizeDesktop) {
    setSizeOptions(sizeDesktop);
  }

  const contentPaddingStyle = {
    paddingBottom: paddingBottom || `${percent}%`,
  };

  const cardClassNames = `${className} ${css.card} ${extraClassName} align-top`;
  const roundedClassName = getRoundedClassName(borderRadius);

  const titleTextSizeClassName = textSize === 'sm' ? 'text-base' : 'text-base sm:text-lg';
  const subtitleTextSizeClassName = textSize === 'sm' ? 'text-sm' : 'text-sm sm:text-base';

  const bgColorClassName = bgColor || 'bg-gray-50';
  const imgRoundedClasses = imgRoundedClassName || roundedClassName;
  const containerClassName = `${bgColorClassName} overflow-hidden ${shadow || 'shadow'} ${imgRoundedClasses} ${
    !shouldHideText ? 'mb-3' : ''
  }`;

  return {
    cardClassNames,
    roundedClassName,
    contentPaddingStyle,
    transformationType,
    imgWrapperClassName,
    percent,
    paddingBottom,
    extraClassName,
    containerStyles,
    borderClassName,
    cardMinWith,
    contentWidthStyle,
    titleTextSizeClassName,
    subtitleTextSizeClassName,
    containerClassName,
    imgRoundedClasses,
    bgColorClassName,
    titleClassNames,
    descriptionClassNames,
  };
};

export const getImageDetails = ({ assets, dataType, metadata, geoLocationImages }) => {
  if (dataType === 'account') {
    assets = metadata && metadata.coverImages;
    if (geoLocationImages && geoLocationImages.length) {
      assets = geoLocationImages;
    }
    if (!assets && metadata?.coverImage) {
      assets = [metadata.coverImage];
    }
  }
  const firstImage = assets ? assets.find(item => item.type === 'image') : null;
  const hasFirstImage = firstImage && firstImage.metadata;
  const image = hasFirstImage ? firstImage : null;
  return { images: assets, image };
};

export const parseData = props => {
  let {
    cardType,
    type,
    title,
    address = {},
    metadata,
    hyperLink,
    externalLink,
    description,
    hideText,
    geoLocation,
    useImageCarousel: inititalUseImageCarousel,
    summary,
    place_id,
  } = props;
  let includeDetails = cardType === 'detailed';
  let dataType = 'listing';
  let logo = null;
  let geoLocationImages = null;
  if (['individual', 'provider'].includes(type)) {
    dataType = 'account';
    title = getAccountName(props);
    includeDetails = false;
    address = getAccountAddress(props, { all: true });
    logo = metadata && metadata.logo;

    // Filter to specific location
    if (geoLocation) {
      const data = getAccountLocationDetails(props, geoLocation);
      if (data) {
        title = data.title || title;
        if (data.images && data.images.length) {
          geoLocationImages = data.images;
        }

        const geoLocationAddress = getAccountAddress(props, { geoLocation });
        address = get(geoLocationAddress, 'formattedAddress') || geoLocationAddress || address;
      }
    }
  } else if (hyperLink || externalLink) {
    dataType = 'playblock';
  } else if (['news'].includes(type)) {
    dataType = 'article';
    description = summary;
  } else if (place_id) {
    dataType = 'place';
  }

  const { city, country, establishment, natural_feature, state } = address || {};
  const cityItem = city || establishment || natural_feature;
  let location = cityItem || country ? `${cityItem ? `${cityItem}, ` : ''}${country}` : null;
  if (dataType === 'account' && typeof address === 'string') {
    location = address;
  }

  const locationOrDescription = location || description;
  const shouldHideText = hideText || cardType === 'media-button';

  const useImageCarousel = inititalUseImageCarousel || dataType === 'account';
  return {
    includeDetails,
    dataType,
    logo,
    title,
    city,
    state,
    location,
    locationOrDescription,
    shouldHideText,
    geoLocationImages,
    useImageCarousel,
  };
};

class Card extends Component {
  shouldComponentUpdate(nextProps) {
    const propsMatch = isEqual(nextProps, this.props);
    return !propsMatch;
  }

  render() {
    let {
      id,
      description,
      assets,
      withOverlay,
      hideLocation,
      alwaysShowOverlayContent,
      externalLink,
      cardType,
      setActiveListing,
      manageActiveListing = false,
      noHref = false,
      onClick,
      status,
      isAdmin = false,
      isSaved = false,
      metadata,
      hyperLink,
      hideSaveButton = false,
      avatar,
      slug,
      priority = false,
      place,
      // useImageCarousel: initialUseImageCarousel,
    } = this.props;
    const { linkType, hyperlinkQueryParams, hyperLinkListingIdName, disableSmartCrop } = metadata || {};

    let {
      includeDetails,
      dataType,
      logo,
      title,
      city,
      location,
      locationOrDescription,
      shouldHideText,
      geoLocationImages,
      useImageCarousel,
    } = parseData(this.props);
    const isDraft = status === LISTING_STATE_DRAFT;
    const {
      cardClassNames,
      roundedClassName,
      contentPaddingStyle,
      transformationType,
      imgWrapperClassName,
      containerStyles,
      contentWidthStyle,
      titleTextSizeClassName,
      subtitleTextSizeClassName,
      containerClassName,
      bgColorClassName,
      titleClassNames,
      descriptionClassNames,
    } = getStyles(this.props);

    const { images, image } = getImageDetails({ assets, dataType, metadata, geoLocationImages });

    const defaultCardLinkProps = {
      externalLink: externalLink,
      id: id,
      title: title,
      isDraft: isDraft,
      isAdmin: isAdmin,
      hyperLink: hyperLink,
      linkType: linkType,
      hyperLinkListingIdName: hyperLinkListingIdName,
      hyperlinkQueryParams: hyperlinkQueryParams,
      metadata: metadata,
      dataType: dataType,
      onClick: onClick,
      noHref: noHref,
      slug: slug,
      place,
    };
    return (
      <CardLink
        {...defaultCardLinkProps}
        cardClassNames={cardClassNames}
        contentWidthStyle={contentWidthStyle}
        setActiveListing={setActiveListing}
        manageActiveListing={manageActiveListing}
        hideSaveButton={hideSaveButton}
        isSaved={isSaved}
        useImageCarousel={useImageCarousel}
      >
        {/* IMAGE SECTION */}
        <div className="relative">
          <div style={containerStyles} className={containerClassName}>
            <div className={`relative ${roundedClassName} ${imgWrapperClassName}`} style={contentPaddingStyle}>
              <DraftWarningMaybe isDraft={isDraft} title={title} isAdmin={isAdmin} />
              <ImageMaybe
                useImageCarousel={useImageCarousel}
                imageCarouselLink={(returnLinkProps = {}) =>
                  CardLink({ ...defaultCardLinkProps, returnLinkOnly: true, returnLinkProps })
                }
                bgColorClassName={bgColorClassName}
                images={images}
                image={image}
                roundedClassName={roundedClassName}
                transformationType={transformationType}
                title={title}
                disableSmartCrop={disableSmartCrop}
                priority={priority}
              />

              <ImageOverlayContentMaybe
                withOverlay={withOverlay}
                location={location}
                hideLocation={hideLocation}
                description={description}
                title={title}
                city={city}
                roundedClassName={roundedClassName}
                cardType={cardType}
                alwaysShowOverlayContent={alwaysShowOverlayContent}
              />
            </div>
          </div>

          <AccountLogoOrAvatarMaybe dataType={dataType} logo={logo} avatar={avatar} props={this.props} />
        </div>

        {/* Content */}
        <ContentMaybe
          {...this.props}
          dataType={dataType}
          locationOrDescription={locationOrDescription}
          title={title}
          location={location}
          city={city}
          titleTextSizeClassName={titleTextSizeClassName}
          subtitleTextSizeClassName={subtitleTextSizeClassName}
          shouldHideText={shouldHideText}
          includeDetails={includeDetails}
          titleClassNames={titleClassNames}
          descriptionClassNames={descriptionClassNames}
        />
      </CardLink>
    );
  }
}

const { string, bool, func, object } = PropTypes;

Card.defaultProps = {
  className: null,
  setActiveListing: () => null,
};

Card.propTypes = {
  className: string,
  title: string,
  href: string,
  // If dataType is provider it will attempt to show data for a specific geoLocation in the accounts metadata
  geoLocation: object,
  hideText: bool,
  setActiveListing: func,
  // when true an image carousel will be used
  useImageCarousel: bool,
};

export default Card;
