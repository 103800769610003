import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import ListingSectionTitle from '@/containers/ListingPage/ListingSectionTitle';

import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

import { computeDynamicHref } from '@/util/helpers';
import { ChevronLeftIcon, ChevronRightIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import { scroll } from './Scroll.js';

import * as css from './Carousel.module.css';

const ViewAll = ({
  viewAllButtonLink,
  viewAllButtonText,
  className = 'text-sm leading-normal font-bold text-accent-600 hover:text-accent-500 uppercase ml-3',
}) => {
  return viewAllButtonLink?.linkType === 'external' ? (
    <a href={computeDynamicHref(viewAllButtonLink)} rel="noreferrer" target="_blank" className={className}>
      {viewAllButtonText}
    </a>
  ) : (
    <Link to={computeDynamicHref(viewAllButtonLink)} className={className}>
      {viewAllButtonText}
    </Link>
  );
};

const Buttons = ({ atStart, atEnd, viewport }) => {
  const target = viewport.current;
  const scrollToPosition = left => {
    if (left === null || left === undefined) return;
    scroll({
      left,
      behavior: 'smooth',
      target,
    });
  };

  const isAtEndOrStart = atStart || atEnd;
  const shadowClassName = isAtEndOrStart ? '' : 'shadow-sm';
  const disabledClassName = 'opacity-70 bg-gray-50 !cursor-not-allowed !ring-0';
  const activeClassName = 'opacity-100 focus:border-accent-500';
  return (
    <span className={`relative z-0 hidden sm:inline-flex ${shadowClassName} ml-1 self-end rounded-md`}>
      <button
        onClick={() => scrollToPosition(target.scrollLeft - target.offsetWidth)}
        type="button"
        className={`${
          atStart ? disabledClassName : activeClassName
        }  focus:ring-accent-500 relative inline-flex items-center rounded-l-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 transition hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 sm:p-2`}
        aria-label="Previous"
      >
        <ChevronLeftIcon className="h-5 w-5" />
      </button>

      <button
        onClick={() => scrollToPosition(target.scrollLeft + target.offsetWidth)}
        type="button"
        className={`${
          atEnd ? disabledClassName : activeClassName
        } focus:ring-accent-500 relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 transition hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 sm:p-2`}
        aria-label="Next"
      >
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </span>
  );
};

const Carousel = props => {
  const {
    className,
    children,
    title,
    negativeMargins,
    subtitle,
    carouelClassName,
    smLabel = false,
    hideButtons = false,
    hideHeader = false,
    titleClassNames = {},
    viewAll,
    viewAllButtonText = 'View All',
    viewAllButtonLink,
    roundedClassName = 'rounded-r-none sm:rounded-r-lg rounded-lg',
  } = props;
  const [isOverflown, setIsOverflown] = useState(false);
  const [viewportScrollAtEnd, setViewportScrollAtEnd] = useState(false);
  const [viewportScrollAtStart, setViewportScrollAtStart] = useState(true);

  const viewport = useRef(null);

  useEffect(() => {
    if (!children || !viewport || !viewport.current) return;
    const { clientWidth, scrollWidth } = viewport.current;
    const hasMore = scrollWidth > clientWidth;
    setIsOverflown(hasMore);
  }, [children, viewport]);

  const handleScroll = () => {
    if (!viewport?.current) return;
    const { scrollWidth, scrollLeft, offsetWidth } = viewport.current;
    const scrollRight = scrollLeft + offsetWidth;
    setViewportScrollAtStart(scrollLeft === 0);
    setViewportScrollAtEnd(scrollWidth === scrollRight);
  };

  useEffect(() => {
    if (!isOverflown || !viewport?.current) return;
    const element = viewport.current;
    const throttledOnScroll = throttle(handleScroll, 200);
    element.addEventListener('scroll', throttledOnScroll);
    return () => element.removeEventListener('scroll', throttledOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverflown, viewport]);

  return (
    <div className={`${className} ${carouelClassName || 'md:px-container px-0'}`}>
      {!hideHeader ? (
        <div className="sm:pb-6' px-container flex flex-row content-center pb-4 md:px-0">
          <div className="flex-1">
            {smLabel ? (
              <ListingSectionTitle noDefaultMargin title={title} description={subtitle} />
            ) : (
              <>
                <div className="flex flex-row items-center">
                  <div className="flex-1">
                    <UiTextRenderer
                      text={title}
                      className="whitespace-pre-wrap"
                      classNames={titleClassNames}
                      defaultFontWeightClassName="font-bold"
                      defaultFontSizeClassName="text-2xl md:text-3xl lg:text-4xl"
                      defaultFontFamilyClassName="font-header"
                      defaultFontColorClassName="text-header"
                    >
                      {viewAll && viewAllButtonLink ? (
                        <ViewAll viewAllButtonLink={viewAllButtonLink} viewAllButtonText={viewAllButtonText} />
                      ) : null}
                    </UiTextRenderer>

                    {subtitle ? <div className="mt-2 text-sm text-gray-500">{subtitle}</div> : null}
                  </div>
                </div>
              </>
            )}
          </div>
          {!hideButtons && isOverflown ? (
            <Buttons viewport={viewport} atStart={viewportScrollAtStart} atEnd={viewportScrollAtEnd} />
          ) : null}
        </div>
      ) : null}

      <div>
        <div
          className={`${css.carousel} ${
            negativeMargins ? '-ml-6px sm:-mr-8px sm:-ml-8px' : ''
          } rounded-r-none ${roundedClassName} overflow-hidden`}
        >
          <div ref={viewport} className={`${css.viewport} pl-container md:pl-0`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const { string } = PropTypes;

Carousel.defaultProps = {
  className: null,
};

Carousel.propTypes = {
  className: string,
};

export default Carousel;
