import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/bookings' : 'bookings';
};

const list = (queryParams = { limit: 20, expand: 'provider' }) => {
  const baseUrl = computeBaseUrl(queryParams);
  return client(`${baseUrl}?${stringify(queryParams)}`);
};

const read = id => {
  return client(`bookings/${id}`);
};

export { list, read };
