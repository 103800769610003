const offlinePaths = ['/api/playblocks', '/api/listing', '/api/account', '/api/categories', '/api/micro-marketplaces'];

// https://swr.vercel.app/docs/advanced/cache#localstorage-based-persistent-cache
// Our provider is cache first in localstorage for certain paths
const swrProvider = () => {
  const appChacheKey = 'mpc-app-cache';
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem(appChacheKey) || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    try {
      // These routes are supported in offline mode
      const data = Array.from(map.entries());
      const filteredData = data.filter(([key]) => offlinePaths.some(path => key.includes(path)));
      const appCache = JSON.stringify(filteredData);
      localStorage.setItem(appChacheKey, appCache);
    } catch (err) {
      console.error(err);
    }
  });

  // We still use the map for write & read for performance.
  return map;
};

export default swrProvider;
