import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const selectedTabClasses = 'border-accent-500 text-gray-900';
const nonSelectedTabClasses = 'text-gray-500 hover:border-gray-300 hover:text-gray-700';
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}
const TabsLight = (props: any) => {
  const {
    className,
    tabs,
    currentPath,
    currentPath1,
    selected,
    navClassName,
    marginClassName = '',
    tabPaddingClassName,
    nagativeMarginsMobile = false,
    noLinks,
    onChange,
    hrefSuffix,
    iconOnlyOnActive,
    spacingClassName = 'ml-8',
  } = props;

  const nav = useRef(null);

  // Scroll the active tab into view
  useEffect(() => {
    if ((!selected && !currentPath) || !tabs?.length || !nav?.current) return;
    // @ts-ignore
    const tab = nav.current.querySelector('.tab-selected');
    if (!tab) return;
    const tabRect = tab.getBoundingClientRect();
    const tabParent = tab.parentElement;
    if (!tabParent) return;
    const tabParentRect = tabParent.getBoundingClientRect();
    if (tabRect.left < tabParentRect.left) {
      tab.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    } else if (tabRect.right > tabParentRect.right) {
      tab.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }
  }, [currentPath1, selected, tabs, nav]);

  if (!tabs || !tabs.length) return null;
  const nagativeMarginsMobileClassNameMaybe = nagativeMarginsMobile ? '-mx-container sm:mx-0' : '';

  return (
    <nav
      ref={nav}
      className={`${navClassName} ${className} ${marginClassName} ${nagativeMarginsMobileClassNameMaybe} transition-all whitespace-nowrap overflow-x-auto no-scrollbar px-container flex sm:px-0`}
    >
      {tabs.map((tab: any, index: number) => {
        const isTabActive = currentPath1 === tab.slug || selected === tab.slug;
        let to = currentPath ? `/${currentPath}` : '';
        if (tab.slug) to = `${to}/${tab.slug}`;
        const classes = `${index !== 0 ? spacingClassName : ''}
        ${isTabActive ? selectedTabClasses : nonSelectedTabClasses}
         whitespace-nowrap group ${
           tabPaddingClassName || 'py-4 px-1'
         } border-b-[2.5px] border-transparent font-medium text-sm focus:outline-none transition ease-out duration-200`;

        const className = `${classes} ${isTabActive ? 'tab-selected' : ''}`;
        if (noLinks) {
          return (
            <button
              type="button"
              onClick={() => {
                if (onChange) onChange(tab.slug);
              }}
              key={tab.title}
              className={className}
            >
              {tab.icon && (!iconOnlyOnActive || isTabActive) ? (
                <tab.icon
                  className={classNames(
                    isTabActive ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
              ) : null}
              {tab.title}
            </button>
          );
        }

        const linkTo = hrefSuffix ? `${to}${hrefSuffix}` : to;
        return (
          <Link key={tab.title} to={linkTo} className={className}>
            {tab.icon && (!iconOnlyOnActive || isTabActive) ? (
              <tab.icon
                className={classNames(
                  isTabActive ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
            ) : null}

            {tab.title}
          </Link>
        );
      })}
    </nav>
  );
};

export default TabsLight;
