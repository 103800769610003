import { useEffect, useRef, useState } from 'react';

import ButtonLight from '@/components/Button/ButtonLight';
import Img from '@/components/Img/Img';
import SearchFilterInput from '@/components/SearchFilterInput/SearchFilterInput';
import UiButtonRenderer from '@/components/UiRenderer/UiButtonRenderer';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

import { track } from '@/analytics';
import { computeDynamicHref, generateImageKitUrl } from '@/util/helpers';
import { createResourceLocatorString } from '@/util/routes';
import useMediaQuery from '@/util/useMediaQuery';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

import * as css from './HeroHeader.module.css';

const defaultRotateInterval = 7;

const SearchFilterInputComponent = ({
  searchType,
  serachPlaceholder,
  history,
  className = 'max-w-2xl mt-4 sm:-mr-24',
  inputClassName = 'rounded-lg shadow-xl focus:ring-2 focus:ring-accent ring-2 ring-black dark:ring-gray-200 dark:hover:ring-gray-300 dark:focus:ring-accent ring-opacity-5 text-lg sm:text-lg transition',
}) => (
  <SearchFilterInput
    searchType={searchType}
    className={className}
    inputClassName={inputClassName}
    buttonTitle=""
    placeholder={serachPlaceholder}
    onSubmit={values => {
      let searchParams = {};
      if (searchType === 'keyword') {
        searchParams = {
          keywords: values.keywords || undefined,
        };
      } else {
        const { search, selectedPlace } = values.location || {};
        const { origin, bounds } = selectedPlace || {};
        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        // const currentSearchParams = window.searchUrlQueryParams || {};
        searchParams = {
          // ...currentSearchParams,
          ...originMaybe,
          address: search || null,
          bounds,
        };
      }

      const query = searchParams.address || searchParams.keywords;
      track.products.searched({ query, search_type: searchType });
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
    }}
  />
);

const HeaderContent = ({
  center = false,
  data,
  serachPlaceholder,
  searchType,
  history,
  searchClassName = 'z-10 mx-auto !max-w-2xl mt-6 lg:-mr-24 lg:ml-0',
  marketplaceOff,
}) => {
  const { metadata } = data || {};
  const { buttonLinkType, buttonHyperLinkListingId, buttonHyperLinkQueryParams } = metadata || {};

  const buttonsClassName = center ? 'justify-center' : 'sm:justify-center lg:justify-start';
  const shouldRenderButton = data.buttonHyperLink && data.buttonText;
  const buttonsMaybe = shouldRenderButton ? (
    <div className={`mt-8 sm:flex ${buttonsClassName}`}>
      <UiButtonRenderer
        defaultSize="2xl"
        hrefConfig={{
          ...metadata,
          hyperLink: data.buttonHyperLink,
          type: buttonLinkType,
          listingId: buttonHyperLinkListingId,
          queryParams: buttonHyperLinkQueryParams,
        }}
      >
        {data.buttonText}
      </UiButtonRenderer>
    </div>
  ) : null;

  const searchClasses = center ? 'z-10 !mx-auto !max-w-2xl mt-6' : searchClassName;
  const searchFilterInputMaybe =
    data.largeSearchBar && !marketplaceOff ? (
      <SearchFilterInputComponent
        className={searchClasses}
        serachPlaceholder={serachPlaceholder}
        searchType={searchType}
        history={history}
      />
    ) : null;

  const wrapperClassName = center ? 'text-center' : 'text-left sm:text-center lg:text-left';
  const descriptionClassName = center ? 'mx-auto' : 'sm:mx-auto';
  return (
    <div className={wrapperClassName}>
      <UiTextRenderer
        className="whitespace-pre-wrap"
        classNames={data.titleClassNames}
        defaultFontWeightClassName="font-extrabold"
        defaultFontSizeClassName="text-4xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
        defaultFontFamilyClassName="font-header"
        defaultFontColorClassName="text-header"
        defaultLetterSpacingClassName="tracking-tight"
      >
        <h1>{data.title}</h1>
      </UiTextRenderer>

      {data.description ? (
        <UiTextRenderer
          className={descriptionClassName}
          classNames={data.descriptionClassNames}
          defaultMarginTopClassName="mt-3 md:mt-5"
          defaultMaxWidthClassName="max-w-md md:max-w-3xl"
          defaultFontWeightClassName=""
          defaultFontSizeClassName="text-lg sm:text-xl"
          defaultLineHeightClassName=""
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-500"
        >
          <p>{data.description}</p>
        </UiTextRenderer>
      ) : null}

      {searchFilterInputMaybe}
      <data.sponsorsMaybe className={center ? 'justify-center' : ''} />
      {buttonsMaybe}
    </div>
  );
};

const SplitHeader = ({
  data,
  desktop,
  serachPlaceholder,
  searchType,
  history,
  fullHeightDesktop,
  videoElement,
  dotsMaybe,
  splitAlignmentReversed = false,
  marketplaceOff,
} = {}) => {
  const { transparentHeader } = data || {};
  const wrapperMinHeight = fullHeightDesktop && desktop ? (transparentHeader ? '100vh' : 'calc(100vh - 72px)') : 0;
  let mediaRoundedClassName =
    fullHeightDesktop && transparentHeader ? '' : splitAlignmentReversed ? 'lg:rounded-r-2xl' : 'lg:rounded-l-2xl';
  if (!fullHeightDesktop && transparentHeader) {
    mediaRoundedClassName = splitAlignmentReversed ? 'lg:rounded-br-2xl' : 'lg:rounded-bl-2xl';
  }

  if (data.mediaBorderRadiusClassName) {
    mediaRoundedClassName = data.mediaBorderRadiusClassName;
  }

  const mediaWrapperAlignmentClassName = splitAlignmentReversed ? 'lg:left-0' : 'lg:right-0';
  const contentWrapperAlignmentClassName = splitAlignmentReversed ? 'lg:flex lg:flex-row lg:justify-end' : '';
  const contentWrapperPaddingClassName = splitAlignmentReversed ? 'xl:pl-16' : 'xl:pr-16';

  const imageElement = (
    <Img
      bgColorClassName={transparentHeader ? 'bg-gray-200' : 'bg-white-background'}
      key={data.imageId}
      id={data.imageId}
      innerStyle={{
        backgroundSize: data.imageSize || 'cover',
      }}
      transformationType="lg"
      className={`absolute inset-0 h-full w-full overflow-hidden bg-gray-200 ${
        transparentHeader ? 'bg-gray-200' : 'bg-white-background'
      } ${mediaRoundedClassName}`}
      src={data.src}
    />
  );

  return (
    <div className="relative mt-6 lg:mt-0">
      <main
        style={{
          minHeight: wrapperMinHeight,
        }}
        className="lg:relative lg:flex lg:flex-row lg:items-center"
      >
        <div
          className={`max-w-container mx-auto w-full pt-16 pb-20 text-center lg:py-48 lg:text-left ${contentWrapperAlignmentClassName}`}
        >
          <div className={`px-4 sm:px-8 lg:w-1/2 ${contentWrapperPaddingClassName}`}>
            <HeaderContent
              marketplaceOff={marketplaceOff}
              data={data}
              serachPlaceholder={serachPlaceholder}
              history={history}
              searchType={searchType}
              searchClassName={`z-10 mx-auto !max-w-2xl mt-6 lg:ml-0 ${!splitAlignmentReversed ? 'lg:-mr-24' : ''}`}
            />
          </div>
        </div>

        <div
          className={`relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:h-full lg:w-1/2 ${mediaWrapperAlignmentClassName} ${mediaRoundedClassName} overflow-hidden`}
        >
          {data.videoSrc ? videoElement : imageElement}
        </div>
      </main>
      {dotsMaybe}
    </div>
  );
};

const SplitScreenshotHeader = ({ data, serachPlaceholder, searchType, history, marketplaceOff } = {}) => {
  const svgs = (
    <div className="hidden sm:block">
      <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
      <svg className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0" width={404} height={392} fill="none" viewBox="0 0 404 392">
        <defs>
          <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
      </svg>
    </div>
  );

  return (
    <div className="bg-white-background pt-[60px] pb-8 sm:pb-12 md:pt-0 lg:pb-12">
      <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
        <div className="max-w-container mx-auto px-4 sm:px-6 lg:grid lg:min-h-[500px] lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="lg:flex lg:flex-col lg:justify-center">
            <HeaderContent
              marketplaceOff={marketplaceOff}
              data={data}
              serachPlaceholder={serachPlaceholder}
              history={history}
              searchType={searchType}
            />
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:mt-2 lg:w-1/2">
            {svgs}
            <div className="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
              <img
                loading="eager"
                draggable={false}
                className="w-full rounded-md bg-gray-200 shadow-xl ring-1 ring-black ring-opacity-5 dark:ring-gray-200 lg:h-full lg:w-auto lg:max-w-none"
                src={generateImageKitUrl({ id: data.imageId, transformationType: 'lg' })}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StackedHeader = ({ data, serachPlaceholder, searchType, history, marketplaceOff } = {}) => {
  return (
    <div className="bg-white-background">
      <div className={`md:max-w-container relative md:mx-auto ${!data.hideMedia ? 'overflow-hidden' : ''}`}>
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          {!data.hideMedia && !data.hideMediaDots ? (
            <div className="relative h-full">
              <svg
                className="absolute right-full translate-y-1/3 translate-x-1/4 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={784} fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
              </svg>
              <svg
                className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={784} fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
              </svg>
            </div>
          ) : null}
        </div>

        <div className="relative pt-6 pb-16 sm:pb-20">
          <div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-20 sm:px-6">
            <HeaderContent
              marketplaceOff={marketplaceOff}
              center
              data={data}
              serachPlaceholder={serachPlaceholder}
              history={history}
              searchType={searchType}
            />
          </div>
        </div>

        {!data.hideMedia ? (
          <div className="relative">
            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
              <div className="flex-1" />
              <div className="w-full flex-1" />
            </div>
            <div className="mx-auto max-w-7xl px-4 pb-6 sm:px-6">
              <img
                loading="eager"
                draggable={false}
                className="relative rounded-lg bg-gray-200 shadow-lg"
                src={generateImageKitUrl({ id: data.imageId, transformationType: 'lg' })}
                alt="App screenshot"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const HeroHeader = props => {
  const {
    src,
    imageId,
    videoSrc,
    slides,
    getSlideData,
    title,
    description,
    sponsorsMaybe,
    settings = {},
    disableOverlay = false,
    smallMedia: initialSmallMedia = false,
    largeSearchBar = false,
    history,
    transparentHeader,
    buttonHyperLink,
    buttonText,
    metadata,
    serachPlaceholder,
    searchType,
    fluidDefaultHeader = false,
    slideType = 'full',
    imageSize = 'cover',
    mediaBorderRadiusClassName,
    aspectRatio: initialAspectRatio,
    split: initialSplit,
    splitAlignment: initialSplitAlignment,
    hideMedia: initialHideMedia,
    hideMediaDots: initialHideMediaDots,
    marketplaceOff,
    titleClassNames,
    descriptionClassNames,
    centerContent,
  } = props;
  const [slide, setSlide] = useState(0);
  const [data, setData] = useState({
    title,
    description,
    sponsorsMaybe,
    videoSrc,
    src,
    imageId,
    disableOverlay,
    smallMedia: initialSmallMedia,
    largeSearchBar,
    transparentHeader,
    buttonHyperLink,
    buttonText,
    metadata,
    imageSize,
    mediaBorderRadiusClassName,
    aspectRatio: initialAspectRatio,
    split: initialSplit,
    splitAlignment: initialSplitAlignment,
    hideMedia: initialHideMedia,
    hideMediaDots: initialHideMediaDots,
    titleClassNames,
    descriptionClassNames,
    centerContent,
  });

  const [videoPaused, setVideoPaused] = useState(null);

  const desktop = useMediaQuery('(min-width:1024px)');
  const { autoRotateSlides, rotateInterval } = settings;

  const isSplitHeader = slideType === 'split';
  const isSplitContainerHeader = slideType === 'split-container';
  const smallMedia = isSplitContainerHeader || data.smallMedia;

  const setSlideData = index => {
    setSlide(index);
    const params = getSlideData(index);
    setData(params);
  };

  const getDelayAmount = () => (rotateInterval || defaultRotateInterval) * 1000;
  const [delay, setDelay] = useState(getDelayAmount());
  useInterval(() => {
    // never auto play slides for now
    if (!slides || !slides.length || !autoRotateSlides || autoRotateSlides) return;
    const nextIndex = slide !== slides.length - 1 ? slide + 1 : 0;
    setSlideData(nextIndex);
  }, delay);

  const imageElement = (
    <Img
      innerStyle={{
        backgroundSize: data.imageSize || 'cover',
      }}
      bgColorClassName={smallMedia ? 'bg-white-background' : transparentHeader ? 'bg-gray-200' : 'bg-white-background'}
      key={data.imageId}
      id={data.imageId}
      transformationType="lg"
      className={`h-full w-full bg-gray-200 object-cover align-bottom ${
        smallMedia ? `${css.smallMediaMedia} ${data.transparentHeader ? 'lg:rounded-t-none' : ''} overflow-hidden rounded-xl` : ''
      }`}
      src={data.src}
    />
  );

  const videoElement = (
    <VideoPlayer
      setPaused={videoPaused}
      key={(data.videoSrc && data.videoSrc.src) || 'Video'}
      className={`${css.video} h-full w-full bg-gray-200 object-cover align-bottom ${smallMedia ? css.smallMediaMedia : ''}`}
      poster={data?.src}
      noRounded={!smallMedia}
      objectFit="cover"
      objectPosition={smallMedia ? 'bottom' : undefined}
      bgColor={smallMedia ? 'white' : 'rgb(229, 231, 235)'}
      autoplay={true}
      controls={false}
      loop={true}
      fluid={false}
      fill={true}
      playsinline={true}
      sources={[data.videoSrc]}
      muted={true}
      alertAction={null}
      alertOnAction={null}
      hideVideoPlayerOnError
      onError={() => {
        // Clear the video if there is an issue loading it
        setData({
          ...data,
          videoSrc: null,
        });
      }}
      alertTitle="There was an issue playing this video"
      alertDescription="Please try again"
    />
  );

  const overlayStyleMaybe = !data.disableOverlay && !smallMedia ? { backgroundColor: 'rgba(0,0,0, .2)' } : null;
  const textColorClassName = smallMedia ? 'text-header' : 'text-white';

  const dots = (
    <div
      className={`max-w-container px-container absolute mx-auto ${
        smallMedia ? 'bottom-6 right-6' : isSplitHeader ? 'bottom-4 right-0' : 'bottom-10 right-0'
      } left-0`}
    >
      <div className="-mr-2 flex flex-row justify-end">
        {slides.map((item, index) => {
          const isActive = slide === index;
          const className = `${
            isActive ? 'bg-primary-400' : 'bg-white-background'
          } shadow-xl h-4 w-4 mr-3 cursor-pointer  rounded-full`;
          return (
            <div
              key={index}
              onClick={() => {
                // Add a milisecond so the interval re-renders
                setDelay(delay + 1);
                setSlideData(index);
              }}
              className={className}
            ></div>
          );
        })}
      </div>
    </div>
  );

  const shouldRenderButton = data.buttonHyperLink && data.buttonText;
  const { buttonLinkType, buttonHyperLinkListingId, buttonHyperLinkQueryParams } = data.metadata || {};
  const button = (
    <div className="ms:mt-6 mt-6">
      <ButtonLight
        externalLink={buttonLinkType === 'external'}
        href={computeDynamicHref({
          ...metadata,
          hyperLink: data.buttonHyperLink,
          type: buttonLinkType,
          listingId: buttonHyperLinkListingId,
          queryParams: buttonHyperLinkQueryParams,
        })}
        size="2xl"
      >
        {data.buttonText}
      </ButtonLight>
    </div>
  );
  const buttonMaybe = shouldRenderButton ? button : null;

  const shouldRenderSlideDots = slides && slides.length > 1;
  const dotsMaybe = shouldRenderSlideDots ? dots : null;

  const searchFilterInputMaybe =
    data.largeSearchBar && !marketplaceOff ? (
      <SearchFilterInputComponent serachPlaceholder={serachPlaceholder} searchType={searchType} history={history} />
    ) : null;

  let smallMediaStyles = {
    '--find-play-hero-small-media-content-width': '33.4%',
    '--find-play-hero-small-media-media-width': '66.6%',
    '--find-play-hero-small-media-media-width-value': '0.66',
    '--find-play-hero-small-media-media-aspect-ratio': '56.25%',
  };
  const split = data.split;
  const aspectRatio = data.aspectRatio;
  const splitAlignmentReversed = data.splitAlignment === 'reverse';
  if (smallMedia) {
    if (split) {
      const splitData = split.split(',');
      const contentWidth = splitData[0];
      const mediaWidth = splitData[1];
      const mediaWidthValue = (mediaWidth * 0.01).toFixed(2);
      if (contentWidth && mediaWidth && mediaWidthValue) {
        smallMediaStyles = {
          ...smallMediaStyles,
          '--find-play-hero-small-media-content-width': `${contentWidth}%`,
          '--find-play-hero-small-media-media-width': `${mediaWidth}%`,
          '--find-play-hero-small-media-media-width-value': mediaWidthValue,
        };
      }
    }
    if (aspectRatio) {
      smallMediaStyles = {
        ...smallMediaStyles,
        '--find-play-hero-small-media-media-aspect-ratio': `${aspectRatio}%`,
      };
    }
  }

  const defaultHeader = (
    <div className={fluidDefaultHeader ? css.aspectWrapperFluid : css.aspectWrapper}>
      <div
        className={`${smallMedia ? css.smallMediaAspectPadding : css.aspectPadding} ${
          data.transparentHeader ? '' : css.nonTransparentHeader
        }`}
        style={smallMediaStyles}
      >
        <div
          className={`${
            smallMedia
              ? `px-container ${
                  data.transparentHeader ? `mt-6 lg:mt-0 ${splitAlignmentReversed ? 'lg:pl-0' : 'lg:pr-0'}` : 'sm:mt-4'
                } max-w-container relative mx-auto mb-10 ${css.smallMediaWrapper} ${
                  splitAlignmentReversed ? css.smallMediaWrapperReversed : ''
                }`
              : css.fit
          }`}
        >
          <div className={`${css.imgWrapper} pointer-events-none ${smallMedia ? `relative ${css.smallMediaImgWrapper}` : ''}`}>
            {imageElement}
            {data.videoSrc ? videoElement : null}
          </div>

          {/* Video Pause/ Play button */}
          {data.videoSrc ? (
            <div
              className={`max-w-container px-container absolute z-10 mx-auto ${
                smallMedia ? 'bottom-6 right-6 !px-6' : isSplitHeader ? 'bottom-4 right-0' : 'bottom-10 right-0'
              } left-0 ${dotsMaybe ? 'mb-8' : ''}`}
            >
              <div className="-mr-2 flex flex-row justify-end">
                <button
                  onClick={() => setVideoPaused(!videoPaused)}
                  type="button"
                  className="inline-flex items-center rounded-full border border-transparent bg-gray-900 bg-opacity-25 p-1.5 text-white transition hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-0"
                >
                  {!videoPaused ? (
                    <svg className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                    </svg>
                  ) : (
                    <svg className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          ) : null}

          <div style={overlayStyleMaybe} className={smallMedia ? css.smallMediaContentWrapper : css.fit}>
            <div
              style={!data.transparentHeader && !smallMedia ? { paddingTop: 0 } : null}
              className={`${css.contentWrapper}  ${
                data.centerContent ? 'flex flex-col items-center justify-center text-center' : ''
              } max-w-container px-container mx-auto ${
                smallMedia
                  ? `${transparentHeader ? css.smallMediaContentContainerTransparentHeader : ''} ${
                      css.smallMediaContentContainer
                    }`
                  : ''
              }`}
            >
              <UiTextRenderer
                className="whitespace-pre-wrap"
                classNames={data.titleClassNames}
                defaultFontWeightClassName="font-bold"
                defaultMaxWidthClassName="max-w-5xl"
                defaultFontSizeClassName={`text-5xl md:text-6xl ${!smallMedia ? 'lg:text-7xl xl:text-8xl' : ''}`}
                defaultFontFamilyClassName="font-header"
                defaultFontColorClassName={textColorClassName}
              >
                <h1>{data.title}</h1>
              </UiTextRenderer>

              {data.description ? (
                <UiTextRenderer
                  className="hidden sm:block"
                  classNames={data.descriptionClassNames}
                  defaultMarginTopClassName="mt-4"
                  defaultMaxWidthClassName="max-w-3xl"
                  defaultFontWeightClassName=""
                  defaultFontSizeClassName="text-lg sm:text-xl"
                  defaultLineHeightClassName="leading-7"
                  defaultFontFamilyClassName=""
                  defaultFontColorClassName={smallMedia ? 'text-gray-500' : textColorClassName}
                >
                  <p>{data.description}</p>
                </UiTextRenderer>
              ) : null}

              {searchFilterInputMaybe}
              {buttonMaybe}
              <data.sponsorsMaybe />
            </div>
            {dotsMaybe}
          </div>
        </div>
      </div>
    </div>
  );

  let header = defaultHeader;
  const headerProps = {
    splitAlignmentReversed,
    desktop,
    ...props,
    dotsMaybe,
    videoElement,
    data,
    marketplaceOff,
  };
  switch (slideType) {
    case 'split':
      header = <SplitHeader {...headerProps} />;
      break;
    case 'split-screenshot':
      header = <SplitScreenshotHeader {...headerProps} />;
      break;
    case 'stacked':
      header = <StackedHeader {...headerProps} />;
      break;
    default:
      header = defaultHeader;
      break;
  }

  return <div className={`relative w-full ${smallMedia ? 'z-10' : ''}`}>{header}</div>;
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default HeroHeader;
