/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { XMarkIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

export default function SlideOver(props) {
  const {
    children,
    isOpen,
    onClose = () => {},
    maxWidthClassName = 'max-w-2xl',
    contentClassName = 'px-4 sm:px-6',
    contentWrapperPaddingClassName = 'py-6',
    fullWidthOnMobile = false,
    zIndexClassName = 'z-50',
    hideCloseIcon = false,
  } = props;

  const open = isOpen;
  const setOpen = onClose;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className={`fixed ${zIndexClassName} inset-0 overflow-hidden`} open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-200 dark:bg-opacity-75" />
          </Transition.Child>
          <div className={`fixed inset-y-0 right-0 ${!fullWidthOnMobile ? 'pl-10' : ''} flex max-w-full`}>
            {/*enter sm:duration-700
          leavesm:duration-700 */}
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`relative w-screen ${maxWidthClassName}`}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button
                      className={`${
                        hideCloseIcon ? 'invisible' : ''
                      } rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white dark:text-gray-600`}
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className={`flex h-full flex-col ${contentWrapperPaddingClassName} overflow-y-scroll bg-white shadow-xl`}>
                  <div className={`relative flex-1 ${contentClassName}`}>{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
