import { string } from 'prop-types';

import DynamicLink from '@/components/DynamicLink/DynamicLink';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

const SectionLandingCallToAction = props => {
  const {
    title,
    description,
    buttonText,
    buttonHref,
    buttonHrefMetadata = {},
    secondaryButtonText,
    secondaryButtonHref,
    secondaryButtonHrefMetadata = {},
    titleClassNames = {},
    subTitleClassNames = {},
  } = props;

  return (
    <div className=" py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
      <h2>
        <UiTextRenderer
          text={title}
          classNames={titleClassNames}
          defaultFontWeightClassName="font-bold"
          defaultFontSizeClassName="text-3xl sm:text-4xl md:text-5xl"
          defaultFontFamilyClassName="font-header"
          defaultFontColorClassName="text-gray-900"
        />

        <UiTextRenderer
          text={description}
          classNames={subTitleClassNames}
          defaultFontWeightClassName="font-bold"
          defaultFontSizeClassName="text-3xl sm:text-4xl md:text-5xl"
          defaultFontFamilyClassName="font-header"
          defaultFontColorClassName="text-primary-600"
        />
      </h2>

      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        {buttonText ? (
          <DynamicLink
            as="button"
            link={{
              hyperlink: buttonHref,
              ...buttonHrefMetadata,
            }}
            shadowClassName="shadow"
            className="inline-flex"
            size="xl"
          >
            {buttonText}
          </DynamicLink>
        ) : null}

        {secondaryButtonText ? (
          <DynamicLink
            as="button"
            link={{
              hyperlink: secondaryButtonHref,
              ...secondaryButtonHrefMetadata,
            }}
            shadowClassName="shadow"
            className="ml-3 inline-flex"
            theme="white-no-border"
            size="xl"
          >
            {secondaryButtonText}
          </DynamicLink>
        ) : null}
      </div>
    </div>
  );
};

SectionLandingCallToAction.defaultProps = { className: null };

SectionLandingCallToAction.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingCallToAction;
