import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import useSWR from 'swr';

import { pickSearchParamsOnly } from '@/containers/SearchPage/SearchPage.helpers';

import BookingDateRangeFilter from '@/components/BookingDateRangeFilter/BookingDateRangeFilter';
import Button from '@/components/Button/Button';
import ButtonLight from '@/components/Button/ButtonLight';
import FieldTimeZoneSelect from '@/components/FieldTimeZoneSelect/FieldTimeZoneSelect';
import Form from '@/components/Form/Form';
import FormHeader from '@/components/FormHeader/FormHeader';
import KeywordFilter from '@/components/KeywordFilter/KeywordFilter';
import Modal from '@/components/Modal/Modal';
import SelectMultipleFilter from '@/components/SelectMultipleFilter/SelectMultipleFilter';
import SelectSingleFilter from '@/components/SelectSingleFilter/SelectSingleFilter';
import SlideOver from '@/components/SlideOver/SlideOver';
import SortBy from '@/components/SortBy/SortBy';

import {
  accounts as accountsClient,
  categories as categoriesClient,
  groups as groupsClient,
  tags as tagsClient,
} from '@/clients';

import * as validators from '@/util/validators';
import { getDefaultTimeZoneOnBrowser, parseDateFromISO8601, stringifyDateToISO8601 } from '@/util/dates';
import { computeCategoryTypes, getActiveListingTypes, label, unCamelize } from '@/util/helpers';
import { createResourceLocatorString } from '@/util/routes';
import { parse, stringify } from '@/util/urlHelpers';
import useMediaQuery from '@/util/useMediaQuery';
import { MagnifyingGlassIcon, XCircleIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

const FILTER_DROPDOWN_OFFSET = -14;
const splitQueryParam = queryParam => (queryParam ? queryParam.split(',') : []);

export const filters = {
  keywordFilter: { paramName: 'keywords', config: config.custom.keywordFilterConfig },
  sort: { paramName: 'sort', config: { active: true } },
  type: { paramName: 'type', config: { active: true } },
  subType: { paramName: 'subType', config: { active: true } },
  gender: { paramName: 'gender', config: { active: true } },
  tags: { paramName: 'tags', config: { active: true } },
  status: { paramName: 'status', config: { active: true } },
  provider: { paramName: 'provider', config: { active: true } },
  paymentStatus: { paramName: 'paymentStatus', config: { active: true } },
  groups: { paramName: 'groups', config: { active: true } },
  priceOptions: { paramName: 'priceOptions', config: { active: true } },
  listings: { paramName: 'listings', config: { active: true } },
  customer: { paramName: 'customer', config: { active: true } },
  view: { paramName: 'view', config: { active: true } },
  timezone: { paramName: 'timezone', config: { active: true } },
  subscription: { paramName: 'subscription', config: { active: true } },
  subscriptionStatus: { paramName: 'subscriptionStatus', config: { active: true } },
  subscriptionDates: { paramName: 'subscriptionDates', config: { active: true } },
  dates: { paramName: 'dates', config: { active: true } },
  timeslotDates: { paramName: 'timeslotDates', config: { active: true } },
  roles: { paramName: 'roles', config: { active: true } },
  categories: { paramName: 'categories', config: { active: true } },
};

export const areFiltersActive = location => {
  if (!location) return false;
  const searchInURL = parse(location.search);
  const _filters = { ...filters };
  _filters.view = {};
  const urlQueryParams = pickSearchParamsOnly(searchInURL, _filters) || {};
  return Object.keys(urlQueryParams).length > 0;
};

export const SearchFilterElement = ({
  initialSearchValue,
  handleSearchChange,
  placeholder,
  className = '',
  searchIcon = MagnifyingGlassIcon,
}) => {
  return (
    <FinalForm
      key="SearchFilterElementForm"
      onSubmit={() => {}}
      initialValues={{
        search: initialSearchValue,
      }}
      mutators={{
        setValue: (args, state, utils) => utils.changeValue(state, args[0], () => args[1]),
      }}
      formId="keywords-form"
      render={formRenderProps => {
        const { values, form, handleSubmit } = formRenderProps;

        const spy = <FormSpy onChange={handleSearchChange} subscription={{ values: true, dirty: true }} />;

        const input = (
          <div className={`${className} relative flex-grow`}>
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {searchIcon({
                className: 'h-5 w-4 text-gray-400',
              })}
            </div>

            <Field
              key="filtersSerachInput"
              name="search"
              component="input"
              type="text"
              className="focus:border-accent-500 focus:ring-accent-500 block min-h-[31px] w-full rounded-full border-gray-300 !py-1 !px-8 placeholder-gray-500 transition hover:border-gray-700 sm:text-sm"
              placeholder={placeholder}
            />

            {values.search ? (
              <div className="z-1 absolute inset-y-0 right-0 flex items-center pr-3">
                <XCircleIcon
                  onClick={() => form.mutators.setValue('search', null)}
                  className="h-4 w-4 cursor-pointer text-gray-400"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>
        );
        return (
          <Form onSubmit={handleSubmit}>
            {input}
            {spy}
          </Form>
        );
      }}
    ></FinalForm>
  );
};

export const SelectWeekdayFilter = ({ initialValues, onSubmit = () => {} }) => {
  let subTypeFilterOptions = [
    { label: 'Monday', key: '1' },
    { label: 'Tuesday', key: '2' },
    { label: 'Wednesday', key: '3' },
    { label: 'Thursday', key: '4' },
    { label: 'Friday', key: '5' },
    { label: 'Saturday', key: '6' },
    { label: 'Sunday', key: '7' },
  ];

  return (
    <SelectMultipleFilter
      showAsPopup
      key="days"
      id="days"
      name="days"
      urlParam="days"
      label="Days"
      onSubmit={onSubmit}
      liveEdit
      options={subTypeFilterOptions}
      initialValues={initialValues}
    />
  );
};

export const SelectTimeOfDayFilter = ({ initialValues, onSubmit = () => {} }) => {
  let subTypeFilterOptions = [
    { label: 'Morning', description: 'Starts before 12pm', key: 'morning' },
    { label: 'Afternoon', description: 'Starts after 12pm', key: 'afternoon' },
    { label: 'Evening', description: 'Starts after 5pm', key: 'evening' },
  ];
  return (
    <SelectMultipleFilter
      showAsPopup
      key="timeOfDay"
      id="timeOfDay"
      name="timeOfDay"
      urlParam="timeOfDay"
      label="Time of Day"
      onSubmit={onSubmit}
      liveEdit
      options={subTypeFilterOptions}
      initialValues={initialValues}
    />
  );
};

const DateFilter = ({ name = 'subscriptionDates', label, location, isDrawerActive, urlQueryParams, updateUrlLocation }) => {
  const params = parse(location.search);
  const dates = params[name];
  const rawValuesFromParams = dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
          dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
        }
      : { dates: null };
  return (
    <BookingDateRangeFilter
      id={name}
      showAsPopup={!isDrawerActive}
      label={label}
      initialValues={initialValues}
      urlParam={name}
      onSubmit={(urlParam, values) => {
        const hasDates = values && values.dates;
        const { startDate, endDate } = hasDates ? values.dates : {};
        const start = startDate ? stringifyDateToISO8601(startDate) : null;
        const end = endDate ? stringifyDateToISO8601(endDate) : null;
        const hasStartAndEndDate = start != null && end != null;
        const dateQueryParams = hasStartAndEndDate ? { [urlParam]: [start, end].join(',') } : null;
        const queryParams = dateQueryParams ? { ...urlQueryParams, ...dateQueryParams } : omit(urlQueryParams, urlParam);
        updateUrlLocation(queryParams);
      }}
    />
  );
};

const initialCategoryValues = (categoryIds, urlQueryParams, initialFilters) => {
  const results = splitQueryParam(urlQueryParams['categories'] || (initialFilters && initialFilters['categories']));
  if (!results?.length) return [];
  return results?.filter(id => categoryIds.includes(id));
};
const CategoryFilterElement = ({ category, options, showAsPopup, handleSelectMultiple, urlQueryParams, initialFilters }) => {
  const { id, label } = category;
  // const { categories, isLoading, error } = useCategories(id);
  if (!options?.length) {
    return null;
  }

  const categoryIds = options.map(({ key }) => key);
  return (
    <SelectMultipleFilter
      key={id}
      id={id}
      name="categories"
      urlParam="categories"
      label={label}
      showAsPopup={showAsPopup}
      onSubmit={handleSelectMultiple}
      liveEdit
      options={options}
      initialValues={initialCategoryValues(categoryIds, urlQueryParams, initialFilters)}
      usePortal
    />
  );
};

const handleSelectMultipleCategories = (urlParam, values, { urlQueryParams, updateUrlLocation, options }) => {
  let newValues = values?.join(',');

  try {
    const categoryIds = options.map(({ key }) => key);
    const urlValues = urlQueryParams[urlParam];
    const hasExistingValues = !!urlQueryParams[urlParam];
    const existingUrlValues = hasExistingValues ? splitQueryParam(urlValues) : [];
    const existingValuesNotMatchingOptions =
      !!existingUrlValues?.length && existingUrlValues?.filter(value => !categoryIds.includes(value));
    const existingValues = existingValuesNotMatchingOptions?.length ? existingValuesNotMatchingOptions : null;
    if (existingValues) {
      newValues = existingValues?.concat(values).join(',');
    }
  } catch (err) {
    console.error('There was an error parsing the category values', err);
  }

  const queryParams = values ? { ...urlQueryParams, [urlParam]: newValues } : omit(urlQueryParams, urlParam);
  updateUrlLocation(queryParams);
};
const useCategoryTypes = () => {
  const { data: response, error } = useSWR('/api/categories/list', () => categoriesClient.list(), {
    focusThrottleInterval: 1000,
    revalidateIfStale: false,
    // revalidateOnMount: false,
    // revalidateOnReconnect: false,
  });
  const categories = response?.data || [];
  const errorMessage = error ? error?.error?.userMessage || 'An error occurred' : null;
  const isLoading = !error && !response;
  return {
    categoryTypes: computeCategoryTypes(categories),
    isLoading,
    error: errorMessage,
  };
};
const CategoryFilters = ({ categories, showAsPopup, currentUser, urlQueryParams, initialFilters, updateUrlLocation }) => {
  const { categoryTypes } = useCategoryTypes();

  if (!categoryTypes?.length) return null;

  return categories.map(category => {
    const categories = categoryTypes.find(({ id }) => id === category.id)?.items || [];
    if (!categories?.length) return null;

    const options = categories.map(({ id, title }) => ({ label: title, key: id }));
    return (
      <CategoryFilterElement
        key={`category-${category?.id}`}
        category={category}
        options={options}
        showAsPopup={showAsPopup}
        handleSelectMultiple={(urlParam, values) =>
          handleSelectMultipleCategories(urlParam, values, { urlQueryParams, updateUrlLocation, options })
        }
        roles={currentUser?.roles}
        urlQueryParams={urlQueryParams}
        initialFilters={initialFilters}
      />
    );
  });
};
const CategoriesFiltersMaybe = ({
  filterType,
  subFilterType,
  marketplace,
  pageName,
  currentUser,
  showAsPopup,
  handleSelectMultiple,
  urlQueryParams,
  initialFilters,
  updateUrlLocation,
}) => {
  if (!marketplace || pageName !== 'CmsPage') return null;

  const isAccountCmsPage = filterType === 'accounts' && subFilterType === 'crmAccounts';
  const isListingCmsPage = filterType === 'listings';
  if (!isAccountCmsPage && !isListingCmsPage) {
    return null;
  }

  const categoriesObj = marketplace?.applicationConfig?.settings?.categoryTypes;
  if (!categoriesObj) return null;

  const accountFeatures = marketplace?.pageConfig?.accounts?.features;
  const accountCategories = accountFeatures ? accountFeatures.filter(item => item.type === 'category') : [];

  const listingFeatures = marketplace?.pageConfig?.listingPage?.features;
  const listingCategories = listingFeatures ? listingFeatures.filter(item => item.type === 'category') : [];

  const categoriesToFilter = isAccountCmsPage ? accountCategories || [] : listingCategories || [];
  if (!categoriesToFilter?.length) {
    return null;
  }

  const categoriesToDisplay = categoriesToFilter.filter(category => {
    // const included = categoriesToFilter.includes(category.id);
    const isAdminOnly = category?.roles?.marketplaceAdmin === true;
    const isMarketplaceAdmin = currentUser?.roles?.includes('marketplaceAdmin');
    const hasAccess = isAdminOnly ? isMarketplaceAdmin : true;
    return hasAccess;
  });

  if (!categoriesToDisplay?.length) {
    return null;
  }

  return (
    <CategoryFilters
      categories={categoriesToDisplay}
      showAsPopup={showAsPopup}
      handleSelectMultiple={handleSelectMultiple}
      currentUser={currentUser}
      urlQueryParams={urlQueryParams}
      initialFilters={initialFilters}
      updateUrlLocation={updateUrlLocation}
    />
  );
};

const Filters = props => {
  const {
    className,
    history,
    location,
    pageName,
    children,
    pathParams = {},
    filterType = 'listings',
    tags,
    groups,
    listings,
    accountId,
    onChange,
    filters: initialFilters,
    earlyChildren = false,
    currentUser,
    subFilterType,
    overflowXScroll = false,
    overflowXScrollClassName = '',
    thirdFilterType = '',
    fourthFilterType,
    onClearFilters = () => {
      history.push(window.location.pathname);
    },
    drawerOnMobile = false,
    drawerOpened = false,
    drawerOnClose = () => {},
    inlineKeywords = false,
    searchPlaceholder = 'Search Listings',
    searchClassName = '',
    searchIcon = MagnifyingGlassIcon,
    priceOptions = [],
    dynamicConfig = {},
    sortDisabled = false,
    userPersona = 'customer',
    marketplace,
    userRoles,
  } = props;
  const desktop = useMediaQuery('(min-width:640px)');
  const isDrawerActive = drawerOnMobile && !desktop;
  const showAsPopup = !isDrawerActive;
  const isDynamic = filterType === 'dynamic';

  const hasPreferredTimezones =
    currentUser &&
    currentUser.metadata &&
    currentUser.metadata.preferredTimezones &&
    currentUser.metadata.preferredTimezones.length;
  const preferredTimezones = hasPreferredTimezones ? currentUser.metadata.preferredTimezones : null;

  const searchInURL = location && parse(location.search);
  const urlQueryParams = searchInURL ? pickSearchParamsOnly(searchInURL, filters) || {} : initialFilters || {};
  const { sort } = searchInURL || initialFilters || {};

  const filtersActive = areFiltersActive(location);

  const initialValue = (queryParams, paramName) => {
    return queryParams[paramName] || (initialFilters && initialFilters[paramName]);
  };

  const initialValues = paramName => {
    const splitQueryParam = queryParam => (queryParam ? queryParam.split(',') : []);
    return splitQueryParam(urlQueryParams[paramName] || (initialFilters && initialFilters[paramName]));
  };

  const selectSingleFilter = ({ label, name, options }) => (
    <SelectSingleFilter
      showAsPopup
      left
      urlParam={name}
      label={label}
      className="inline-block"
      onSelect={handleSelectSingle}
      liveEdit
      options={options}
      initialValue={initialValue(urlQueryParams, name)}
    />
  );

  const updateUrlLocation = (queryParams, silent) => {
    if (onChange) onChange(queryParams);
    if (!history) return;

    let path;
    if (isDynamic) {
      path = `${location?.pathname}?${stringify(queryParams)}`;
    } else {
      path = createResourceLocatorString(pageName, routeConfiguration(), pathParams, queryParams);
    }

    if (silent) {
      window.history.replaceState(null, '', path);
    } else {
      history.push(path);
    }
  };

  const handleSelectSingle = (urlParam, values, silent) => {
    const queryParams = values ? { ...urlQueryParams, [urlParam]: values } : omit(urlQueryParams, urlParam);
    updateUrlLocation(queryParams, silent);
  };

  const handleSelectMultiple = (urlParam, values) => {
    const queryParams = values ? { ...urlQueryParams, [urlParam]: values.join(',') } : omit(urlQueryParams, urlParam);
    updateUrlLocation(queryParams);
  };

  let sortByFilterType = filterType;
  if (filterType === 'transactions' && subFilterType === 'bookings') {
    if (thirdFilterType === 'requests') {
      sortByFilterType = 'bookingRequests';
    } else {
      sortByFilterType = 'bookings';
    }
  }

  const SortByElement = () => (
    <SortBy
      left
      sort={sort}
      filterType={sortByFilterType}
      className="inline-block"
      showAsPopup
      onSelect={handleSelectSingle}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  );

  const KeywordFilterElement = () => (
    <KeywordFilter
      id={'SearchFilters.keywordFilter'}
      name="keyword"
      urlParam="keywords"
      label="Keywords"
      onSubmit={handleSelectSingle}
      showAsPopup={showAsPopup}
      initialValues={initialValue(urlQueryParams, 'keywords')}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  );
  const keywordActiveDynamic = isDynamic && dynamicConfig?.keyword;
  const keywordActive = ['listings', 'accounts', 'contacts'].includes(filterType) || keywordActiveDynamic;
  const keywordFilterElementMaybe = !inlineKeywords && keywordActive ? <KeywordFilterElement /> : null;

  const RoleFilterElement = ({ showAsPopup = true }) => (
    <SelectSingleFilter
      showAsPopup={showAsPopup}
      key="roles"
      id="roles"
      name="roles"
      urlParam="roles"
      label="Role"
      liveEdit
      options={[
        { label: 'Guest', key: 'guest' },
        { label: 'User', key: 'user' },
        { label: 'Marketplace Admin', key: 'marketplaceAdmin' },
        { label: 'Marketplace CMS Admin', key: 'marketplaceCmsAdmin' },
      ]}
      onSelect={handleSelectSingle}
      initialValue={initialValue(urlQueryParams, 'roles')}
    />
  );
  let roleFilterElementMaybe =
    filterType === 'accounts' && subFilterType === 'crmAccounts' ? <RoleFilterElement showAsPopup={!isDrawerActive} /> : null;

  const listingTypes = getActiveListingTypes({ userPersona, userRoles, action: 'create' });
  let typeFilterOptions = listingTypes;
  if (filterType === 'transactions' && subFilterType !== 'bookings') {
    typeFilterOptions = [
      { label: 'Booking', key: 'serviceBooking' },
      { label: 'Request For Booking', key: 'serviceBookingWithRequest' },
      { label: 'Subscription', key: 'subscriptionPurchase' },
    ];
  }
  if (filterType === 'accounts') {
    typeFilterOptions = [
      { label: 'Individual', key: 'individual' },
      { label: 'Provider', key: 'provider' },
    ];
  }
  const TypeFilterElement = ({ showAsPopup = true }) => (
    <SelectMultipleFilter
      showAsPopup={showAsPopup}
      key="type"
      id="type"
      name="type"
      urlParam="type"
      label="Type"
      onSubmit={handleSelectMultiple}
      liveEdit
      options={typeFilterOptions}
      initialValues={initialValues('type')}
    />
  );

  const typeActiveDynamic = isDynamic && dynamicConfig?.listingType;
  const shouldRenderTypeFilter =
    typeActiveDynamic ||
    (filterType === 'listings' && subFilterType === 'all') ||
    (filterType === 'transactions' && subFilterType !== 'bookings') ||
    filterType === 'accounts';

  let typeFilterElementMaybe = shouldRenderTypeFilter ? <TypeFilterElement showAsPopup={!isDrawerActive} /> : null;

  let subTypeFilterOptions = [
    { label: 'User Defined', key: 'userDefined' },
    { label: 'Marketplace Defined', key: 'marketplaceDefined' },
  ];
  const SubTypeFilterElement = ({ showAsPopup = true }) => (
    <SelectMultipleFilter
      showAsPopup={showAsPopup}
      key="subType"
      id="subType"
      name="subType"
      urlParam="subType"
      label="Sub Type"
      onSubmit={handleSelectMultiple}
      liveEdit
      options={subTypeFilterOptions}
      initialValues={initialValues('subType')}
    />
  );
  const subTypeFilterMaybe = ['products'].includes(filterType) && subFilterType === 'cms' ? <SubTypeFilterElement /> : null;

  //const shouldRenderSingleTypeFilter =
  //  filterType === 'transactions' && subFilterType === 'bookings' && thirdFilterType !== 'requests';
  //if (shouldRenderSingleTypeFilter) typeFilterElementMaybe = <TypeSingleFilterElement />;

  const filterOptions = {
    listings: [
      { label: 'Active', key: 'active' },
      { label: 'Draft', key: 'draft' },
      { label: 'Archived', key: 'archived' },
      { label: 'Suspended', key: 'suspended' },
    ],
    transactions: [
      { label: 'Completed', key: 'completed' },
      { label: 'Approved', key: 'approved' },
      { label: 'Pending Approval', key: 'pendingApproval' },
      { label: 'Rejected', key: 'rejected' },
    ],
    bookings: [
      { label: 'Active', key: 'active' },
      { label: 'Cancelled', key: 'cancelled' },
    ],
    products: [
      { label: 'Active', key: 'active' },
      { label: 'Draft', key: 'draft' },
      { label: 'Archived', key: 'archived' },
    ],
    transactionsPayment: [
      { label: 'Success', key: 'success' },
      { label: 'Pending', key: 'pending' },
      { label: 'Failure', key: 'failure' },
      { label: 'Draft', key: 'draft' },
    ],
  };
  const StatusFilterElement = params => {
    const { label = 'Status', id = 'status', options } = params;

    let statusFilterOptions = options || filterOptions[filterType];
    if (filterType === 'transactions' && subFilterType === 'bookings') {
      statusFilterOptions = filterOptions.bookings;
    }

    return (
      <SelectSingleFilter
        showAsPopup={showAsPopup}
        left
        urlParam={id}
        label={label}
        onSelect={handleSelectSingle}
        liveEdit
        options={statusFilterOptions}
        initialValue={initialValue(urlQueryParams, id)}
      />
    );
  };
  const statusFilterMaybe = ['listings', 'transactions', 'products'].includes(filterType) ? <StatusFilterElement /> : null;

  const paymentStatusFilterMaybe =
    ['transactions'].includes(filterType) && subFilterType === 'transactions' ? (
      <StatusFilterElement label="Payment Status" id="paymentStatus" options={filterOptions.transactionsPayment} />
    ) : null;

  const genderOptions = [
    { label: 'Female', key: 'female' },
    { label: 'Male', key: 'male' },
    { label: 'Other', key: 'other' },
  ];
  const GenderFilterElement = () => {
    return (
      <SelectSingleFilter
        showAsPopup={showAsPopup}
        left
        urlParam="gender"
        label="Gender"
        onSelect={handleSelectSingle}
        liveEdit
        options={genderOptions}
        initialValue={initialValue(urlQueryParams, 'gender')}
      />
    );
  };
  const genderFilterElementMaybe = ['contacts'].includes(filterType) ? <GenderFilterElement /> : null;

  const viewOptions = {
    'calendar-sales': [
      { label: `${label('Listings')} & Tasks`, key: '' },
      { label: 'Rostering', key: 'rostering' },
    ],
    // 'calendar-orders': [{ label: 'Tasks', key: '' }],
  };
  const shouldRenderView = false; // ['calendar-sales'].includes(filterType);
  // const viewValue = initialValue(urlQueryParams, 'view');
  const viewFilterOptions = shouldRenderView ? viewOptions[filterType] : [];
  const ViewFilterElement = shouldRenderView
    ? selectSingleFilter({
        label: 'View', // viewFilterOptions[0].label,
        name: 'view',
        options: viewFilterOptions || [],
      })
    : null;
  const viewFilterElementMaybe = shouldRenderView ? ViewFilterElement : null;

  const PriceOptionsFilterElement = () => (
    <SelectMultipleFilter
      showAsPopup={showAsPopup}
      key="priceOptions"
      id="priceOptions"
      name="priceOptions"
      urlParam="priceOptions"
      label="Price Options"
      onSubmit={handleSelectMultiple}
      liveEdit
      options={priceOptions}
      initialValues={initialValues('priceOptions')}
    />
  );
  const shouldRenderPriceOptions = ['transactions', 'bookings'].includes(filterType) && fourthFilterType !== 'orders';
  const PriceOptionsFilterElementMaybe =
    shouldRenderPriceOptions && priceOptions && priceOptions.length ? <PriceOptionsFilterElement /> : null;

  const [tagsOptions, setTagsOptions] = useState(null);
  const tagsFilterOptions = tags || tagsOptions;
  const TagsFilterElement = () => (
    <SelectMultipleFilter
      showAsPopup={showAsPopup}
      key="tags"
      id="tags"
      name="tags"
      urlParam="tags"
      label={filterType === 'resources' ? 'Categories' : 'Tags'}
      onSubmit={handleSelectMultiple}
      liveEdit
      options={tagsFilterOptions}
      initialValues={initialValues('tags')}
    />
  );

  const shouldRenderTagsGeneral = ['resources', 'contacts', 'accounts', 'products'].includes(filterType);
  const shouldRenderTagsForCalendar = false; // ['calendar-sales'].includes(filterType) && viewValue === 'rostering';
  const shouldRenderTags = shouldRenderTagsGeneral || shouldRenderTagsForCalendar;
  useEffect(() => {
    if (!shouldRenderTags || (!accountId && !['resources', 'accounts', 'products'].includes(filterType))) return;

    const queryParams = { sort: 'desc:createdAt', type: 'userDefined', owner: accountId, limit: 100 };
    if (filterType === 'resources') {
      delete queryParams.owner;
      queryParams.type = 'marketplaceDefinedArticle';
    }
    if (filterType === 'accounts') {
      delete queryParams.owner;
      queryParams.type = 'marketplaceDefinedAccounts';
    }
    if (filterType === 'products' && subFilterType === 'cms') {
      delete queryParams.owner;
      queryParams.type = 'marketplaceDefined';
    }

    tagsClient.list(queryParams).then(response => {
      setTagsOptions(response.data.map(tag => ({ label: unCamelize(tag.slug), key: tag.slug })));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRenderTags, accountId]);
  const tagsFilterElementMaybe = shouldRenderTags && tagsFilterOptions && tagsFilterOptions.length ? <TagsFilterElement /> : null;

  const [groupsOptions, setGroupsOptions] = useState(null);
  const groupFilterOptions = groups || groupsOptions;
  const GroupsFilterElement = () => (
    <SelectMultipleFilter
      showAsPopup={showAsPopup}
      key="groups"
      id="groups"
      name="groups"
      urlParam="groups"
      label="Groups"
      onSubmit={handleSelectMultiple}
      liveEdit
      options={groupFilterOptions}
      initialValues={initialValues('groups')}
    />
  );

  const shouldRenderGroupsGeneral = ['contacts'].includes(filterType);
  const shouldRenderGroupsForCalendar = false; // ['calendar-sales'].includes(filterType) && viewValue === 'rostering';
  const shouldRenderGroups = shouldRenderGroupsGeneral || shouldRenderGroupsForCalendar;
  useEffect(() => {
    if (!shouldRenderGroups || !accountId) return;
    groupsClient.list({ sort: 'desc:createdAt', owner: accountId, limit: 50 }).then(response => {
      setGroupsOptions(response.data.map(group => ({ label: group.title, key: group.id })));
    });
  }, [shouldRenderGroups, accountId]);
  const groupsFilterElementMaybe =
    shouldRenderGroups && groupFilterOptions && groupFilterOptions.length ? <GroupsFilterElement /> : null;

  const ListingsFilterElement = () => (
    <SelectMultipleFilter
      showAsPopup={!isDrawerActive}
      key="listings"
      id="listings"
      name="listings"
      urlParam="listings"
      label={label('Listings')}
      onSubmit={handleSelectMultiple}
      liveEdit
      options={listings}
      initialValues={initialValues('listings')}
    />
  );

  const shouldRenderListingsFilterForTransaction = ['transactions'].includes(filterType) && fourthFilterType !== 'orders';
  const listingsFilterElementMaybe =
    (['calendar-sales'].includes(filterType) || shouldRenderListingsFilterForTransaction) && listings && listings.length ? (
      <ListingsFilterElement />
    ) : null;

  const sortByElementMaye =
    filterType !== 'timezone' && filterType !== 'calendar-orders' && filterType !== 'calendar-sales' && !sortDisabled ? (
      <SortByElement />
    ) : null;

  const defaultTimezoneOption = {
    label: getDefaultTimeZoneOnBrowser(),
    key: '',
  };
  const [timezoneOptions, setTimezoneOptions] = useState([defaultTimezoneOption]);
  useEffect(() => {
    if (!hasPreferredTimezones) return;
    setTimezoneOptions([defaultTimezoneOption, ...preferredTimezones.map(tz => ({ label: tz, key: tz }))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredTimezones]);

  const TimezoneFilterElement = () => {
    return (
      <SelectSingleFilter
        showAsPopup
        left
        urlParam="timezone"
        label="Timezone"
        className="inline-block"
        onSelect={handleSelectSingle}
        liveEdit
        options={timezoneOptions}
        onOtherClick={() => setTimezoneModalOpened(true)}
        initialValue={initialValue(urlQueryParams, 'timezone')}
      />
    );
  };

  const [timezoneModalOpened, setTimezoneModalOpened] = useState(false);
  const TimezoneModalElement = () => {
    return (
      <Modal
        id="TimezoneModalElement"
        theme="tailwind"
        tailwindCloseButton
        isOpen={timezoneModalOpened}
        onClose={() => setTimezoneModalOpened(false)}
      >
        {timezoneModalOpened ? (
          <FinalForm
            {...props}
            onSubmit={values => {
              const { timezone } = values;
              const timezoneAlreadyPreferred = hasPreferredTimezones && preferredTimezones.includes(timezone);
              if (timezoneAlreadyPreferred) {
                setTimezoneModalOpened(false);
                return;
              }

              const existingMetadata = currentUser.metadata || {};
              const newPreferredTimezones = [...(preferredTimezones || []), timezone];
              const body = {
                metadata: {
                  ...existingMetadata,
                  preferredTimezones: newPreferredTimezones,
                },
              };

              accountsClient
                .update(body)
                .then(() => {
                  setTimezoneOptions([defaultTimezoneOption, ...newPreferredTimezones.map(tz => ({ label: tz, key: tz }))]);
                  handleSelectSingle('timezone', timezone);
                  setTimezoneModalOpened(false);
                })
                .catch(err => {
                  console.log(err);
                });
            }}
            render={fieldRenderProps => {
              const { handleSubmit, invalid, pristine, submitting } = fieldRenderProps;
              const submitDisabled = invalid || pristine || submitting;
              return (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                >
                  <FormHeader
                    title="Add Timezones"
                    description="You may add additional timezone filters below. They will be saved for reuse in the future."
                  ></FormHeader>
                  <FieldTimeZoneSelect
                    label="Select Timezone"
                    className="mb-12 max-w-xs"
                    id="timezone"
                    name="timezone"
                    validate={validators.required('This field is required')}
                  />

                  <Button className="w-52 max-w-xs" type="submit" disabled={submitDisabled}>
                    Add Timezone
                  </Button>
                </Form>
              );
            }}
          />
        ) : null}
      </Modal>
    );
  };
  const shouldRenderTimezone = false; // ['calendar-sales', 'calendar-orders'].includes(filterType);
  const timeZoneFilterElementMaybe = shouldRenderTimezone ? <TimezoneFilterElement /> : null;
  const timeZoneModalFilterElementMaybe = shouldRenderTimezone ? <TimezoneModalElement /> : null;

  const subscriptionOptions = [
    { label: 'Free', key: 'free' },
    { label: 'Pro', key: 'pro' },
  ];
  const SubscriptionFilterElement = () => {
    return (
      <SelectSingleFilter
        showAsPopup
        left
        urlParam="subscription"
        label="Subscription"
        className="inline-block"
        onSelect={handleSelectSingle}
        liveEdit
        options={subscriptionOptions}
        initialValue={initialValue(urlQueryParams, 'subscription')}
      />
    );
  };
  const subscriptionFilterElementMaybe =
    ['accounts'].includes(filterType) && subFilterType !== 'crm' ? <SubscriptionFilterElement /> : null;

  const subscriptionStatusOptions = [
    { label: 'Trial', key: 'trialing' },
    { label: 'Intent to Cancel', key: 'intentToCancel' },
    { label: 'Cancelled', key: 'cancelled' },
  ];
  const SubscriptionStatusFilterElement = () => {
    return (
      <SelectSingleFilter
        showAsPopup
        left
        urlParam="subscriptionStatus"
        label="Subscription Status"
        className="inline-block"
        onSelect={handleSelectSingle}
        liveEdit
        options={subscriptionStatusOptions}
        initialValue={initialValue(urlQueryParams, 'subscriptionStatus')}
      />
    );
  };
  const subscriptionStatusFilterElementMaybe =
    ['accounts'].includes(filterType) && subFilterType !== 'crm' ? <SubscriptionStatusFilterElement /> : null;

  const dateProps = {
    location,
    isDrawerActive,
    urlQueryParams,
    updateUrlLocation,
  };
  const subscriptionDatesFilterElementMaybe =
    ['accounts'].includes(filterType) && subFilterType !== 'crm' ? (
      <DateFilter name="subscriptionDates" label="Subscription Valid Until" {...dateProps} />
    ) : null;
  const timeslotDatesFilterElementMaybe =
    ['transactions'].includes(filterType) && subFilterType === 'bookings' && thirdFilterType !== 'requests' ? (
      <DateFilter label="Timeslot" name="timeslotDates" {...dateProps} />
    ) : null;

  const datesFilterElementLabel = subFilterType === 'bookings' ? 'Booking Date' : 'Date';
  const datesFilterElementMaybe = ['transactions'].includes(filterType) ? (
    <DateFilter label={datesFilterElementLabel} name="dates" {...dateProps} />
  ) : null;

  const clearButtons = (
    <ButtonLight onClick={onClearFilters} theme="bg-underline" size="xs" noShadow>
      Clear filters
    </ButtonLight>
  );
  const clearButtonMaybe = filtersActive ? clearButtons : null;

  const processSearchOnChange = value => handleSelectSingle('keywords', value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchOnChange = useCallback(debounce(processSearchOnChange, 500), []);
  const shouldRenderSearchFilterElement =
    inlineKeywords &&
    ['resources', 'contacts', 'groups', 'policies', 'forms', 'transactions', 'listings', 'accounts', 'products'].includes(
      filterType
    );
  const searchFilterElementMaybe = shouldRenderSearchFilterElement ? (
    <SearchFilterElement
      key="SearchFilterElement_"
      className={searchClassName}
      searchIcon={searchIcon}
      placeholder={searchPlaceholder}
      initialSearchValue={initialValue(urlQueryParams, 'keywords')}
      handleSearchChange={formState => {
        if (formState.dirty) {
          handleSearchOnChange(formState.values && formState.values.search);
        }
      }}
    />
  ) : null;

  const sortWrpperClassName = overflowXScroll
    ? `flex flex-row items-center space-x-2 overflow-x-scroll no-scrollbar ${overflowXScrollClassName}`
    : 'flex flex-row items-center c-gap c-gap-3 flex-wrap';

  const mainFilters = (
    <>
      {datesFilterElementMaybe}
      {typeFilterElementMaybe}
      {statusFilterMaybe}
      {paymentStatusFilterMaybe}
      {keywordFilterElementMaybe}
      {genderFilterElementMaybe}
      {tagsFilterElementMaybe}
      {groupsFilterElementMaybe}
      {listingsFilterElementMaybe}
      {timeZoneFilterElementMaybe}
      {timeZoneModalFilterElementMaybe}
      {timeslotDatesFilterElementMaybe}
      {PriceOptionsFilterElementMaybe}
      {subTypeFilterMaybe}
      <CategoriesFiltersMaybe
        key={filterType}
        currentUser={currentUser}
        filterType={filterType}
        subFilterType={subFilterType}
        pageName={pageName}
        marketplace={marketplace}
        showAsPopup={showAsPopup}
        handleSelectMultiple={handleSelectMultiple}
        urlQueryParams={urlQueryParams}
        initialFilters={initialFilters}
        updateUrlLocation={updateUrlLocation}
      />
      {roleFilterElementMaybe}
      {subscriptionFilterElementMaybe}
      {subscriptionStatusFilterElementMaybe}
      {subscriptionDatesFilterElementMaybe}
    </>
  );

  const stackedFilters = (
    <div className={`${className} c-gap-wrapper`}>
      <div className={sortWrpperClassName}>
        {searchFilterElementMaybe}
        {sortByElementMaye}
        {viewFilterElementMaybe}

        {earlyChildren ? children : null}

        {mainFilters}

        {!earlyChildren ? children : null}

        {clearButtonMaybe}
      </div>
    </div>
  );

  let content = stackedFilters;

  if (drawerOnMobile && !desktop) {
    content = (
      <>
        {shouldRenderSearchFilterElement ? <div className={className}>{searchFilterElementMaybe}</div> : null}
        {children ? children : null}

        <SlideOver
          isOpen={drawerOpened}
          id="filterDrawer"
          tailwindCloseButton
          type="slideOver"
          closeOnOutslideClick
          slideOverMaxWidthClassName="max-w-2xl"
          onClose={drawerOnClose}
        >
          <div className="flex flex-row items-center justify-between border-b border-gray-200 pb-4">
            <span className="text-xl font-semibold leading-6 text-gray-900">Filters</span>

            <ButtonLight className={!filtersActive ? 'invisible' : ''} noShadow theme="bg-underline" onClick={onClearFilters}>
              Clear all
            </ButtonLight>
          </div>

          <div className="pb-32">{drawerOpened ? <>{mainFilters}</> : null}</div>

          <div className="fixed bottom-0 right-0 left-10 p-4">
            <ButtonLight onClick={drawerOnClose} fullWidthCenterText size="xl">
              Show Results
            </ButtonLight>
          </div>
        </SlideOver>
      </>
    );
  }

  return content;
};

export default Filters;
