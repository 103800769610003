import { getMarketplace } from '@/util/helpers';

export const getTextClasses = ({
  classNames = {},
  className = '',
  defaultFontWeightClassName = 'font-bold',
  defaultFontSizeClassName = 'text-2xl md:text-3xl lg:text-4xl',
  defaultFontFamilyClassName = 'font-header',
  defaultFontColorClassName = 'text-header',
  defaultLineHeightClassName = '',
  defaultAlignClassName = '',
  defaultLetterSpacingClassName = '',
  defaultMaxWidthClassName = '',
  defaultMarginBottomClassName = '',
  defaultMarginTopClassName = '',
  type,
}) => {
  const marketplace = getMarketplace();
  if (type) {
    switch (type) {
      case 'profile': {
        const mpClasses = marketplace?.branding?.typography?.profile?.title;
        defaultFontWeightClassName = mpClasses?.fontWeight || 'font-semibold';
        defaultFontSizeClassName = mpClasses?.fontSize || 'text-lg';
        defaultFontFamilyClassName = mpClasses?.fontFamily || '';
        defaultFontColorClassName = mpClasses?.color || 'text-gray-900';
        break;
      }
      case 'sidebar':
        defaultFontWeightClassName = 'font-medium';
        defaultFontSizeClassName = 'text-sm';
        defaultFontFamilyClassName = '';
        defaultFontColorClassName = 'text-gray-500';
        break;
      case 'marketing-title':
        defaultFontWeightClassName = 'font-bold';
        defaultFontFamilyClassName = 'font-header';
        defaultFontSizeClassName = 'text-2xl md:text-3xl lg:text-4xl';
        defaultFontColorClassName = 'text-header';
        break;
      case 'marketing-description':
        defaultFontWeightClassName = '';
        defaultFontFamilyClassName = '';
        defaultFontSizeClassName = 'text-xl';
        defaultFontColorClassName = 'text-gray-500';
        break;
      default:
        break;
    }
  }

  const fontWeightClassName = classNames.fontWeight || defaultFontWeightClassName;
  const fontSizeClassName = classNames.fontSize || defaultFontSizeClassName;
  const fontFamilyClassName = classNames.fontFamily || defaultFontFamilyClassName;
  const fontColorClassName = classNames.color || defaultFontColorClassName;
  const fontLineHeightClassName = classNames.lineHeight || defaultLineHeightClassName;
  const alignClassName = classNames.textAlign || defaultAlignClassName;
  const letterSpacingClassName = classNames.letterSpacing || defaultLetterSpacingClassName;
  const maxWidthClassName = classNames?.maxWidth || defaultMaxWidthClassName;
  const marginBottomClassName = classNames?.marginBottom || defaultMarginBottomClassName;
  const marginTopClassName = classNames?.marginTop || defaultMarginTopClassName;

  let mxAutoClassName = '';
  if (maxWidthClassName && alignClassName === 'text-center') {
    mxAutoClassName = 'mx-auto';
  }
  return `whitespace-pre-line ${className} ${fontFamilyClassName} ${fontColorClassName} ${fontWeightClassName} ${fontSizeClassName} ${fontLineHeightClassName} ${alignClassName} ${letterSpacingClassName} ${maxWidthClassName} ${mxAutoClassName} ${marginBottomClassName} ${marginTopClassName}`;
};

const UiTextRenderer = ({ classNames = {}, text = '', children, ...rest }) => {
  if (classNames.hidden) return null;

  return (
    <div className={getTextClasses({ classNames: classNames, ...rest })}>
      {text}
      {children}
    </div>
  );
};

export default UiTextRenderer;
