import { RadioGroup } from '@headlessui/react';
import { useState } from 'react';

import Dialog from '@/components/Dialog/Dialog';

import { updateColorModeState } from '@/util/helpers';
import { ComputerDesktopIcon, MoonIcon, SunIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const settings = [
  {
    name: 'System',
    key: '',
    // description: 'Use my system theme',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Light',
    key: 'light',
    // description: 'Use light theme regardless of my system settings',
    icon: SunIcon,
  },
  {
    name: 'Dark',
    key: 'dark',
    // description: 'Use dark theme regardless of my system settings',
    icon: MoonIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ThemeToggleButton = ({ open, setOpen }) => {
  const initialColorMode = window.localStorage.getItem('color-mode');
  const initialColorModeSetting = initialColorMode && settings.find(i => i.key === initialColorMode);
  const initialSetting = initialColorModeSetting || settings[0];
  const [selected, setSelected] = useState(initialSetting);

  return (
    <Dialog maxWidthClassName="max-w-lg" id="themeChangeModal" open={open} setOpen={setOpen}>
      <div className="mb-5 text-lg font-medium text-gray-900">Color Theme Preference</div>
      <RadioGroup
        value={selected}
        onChange={newTheme => {
          let colorMode = newTheme.key;
          setSelected(newTheme);

          // This is the only place in the app color-mode is changed in localStarge
          if (!colorMode) {
            // remove the color mode if system is selected
            window.localStorage.removeItem('color-mode');
          } else {
            window.localStorage.setItem('color-mode', colorMode);
          }

          // This fn will fetch the proper dark or light value based on the color-mode value and update redux stores accordingly
          updateColorModeState();
        }}
      >
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="-space-y-px rounded-md bg-white">
          {settings.map((setting, settingIdx) => (
            <RadioGroup.Option
              key={setting.name}
              value={setting}
              className={({ checked }) =>
                classNames(
                  settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'border-accent-200 bg-accent-50 dark:border-accent-700 dark:bg-accent-900 z-10' : 'border-gray-200',
                  'relative flex cursor-pointer border p-4 focus:outline-none'
                )
              }
            >
              {({ checked }) => (
                <>
                  {setting.icon({
                    className: `mr-3  h-6 w-6 ${checked ? 'text-accent' : 'text-gray-400'}`,
                  })}

                  <div className=" flex flex-col">
                    <div className="flex flex-row items-center space-x-2">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? 'text-accent-900 dark:text-accent-100' : 'text-gray-900',
                          'block text-sm font-medium'
                        )}
                      >
                        {setting.name}
                      </RadioGroup.Label>
                    </div>

                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-accent-700 dark:text-accent-300' : 'text-gray-500', 'block text-sm')}
                    >
                      {setting.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </Dialog>
  );
};

export default ThemeToggleButton;
