import { track } from '@/analytics/track';

import { getProductFromResults } from './product.js';

export const started = ({ lineItems, transaction, paymentGateway }) => {
  if (!transaction || !paymentGateway || !lineItems || !lineItems.length) return;
  const props = {
    order_id: transaction.id,
    value: transaction.billedAmount,
    revenue: transaction.totalAmount,
    tax: 0,
    discount: transaction.totalDiscount,
    currency: transaction.baseCurrency,
    is_tax_included: transaction.isTaxIncluded,
    invoice_code: transaction.invoiceCode,
    created_at: transaction.createdAt,
    provider: transaction.provider,
    customer: transaction.customer,
    platform_fee: transaction.paymentInfo && transaction.paymentInfo.platformFee,
    transaction_type: transaction.type,
    listing: transaction.listing,
    checkout_method: paymentGateway.chargingMethod,
    checkout_id: paymentGateway.checkout.id,
    products: lineItems.map(item => ({
      ...getProductFromResults(item),
      seats: item.seats,
      price_type: item.priceType,
      customers: item.customers,
      product_id: item.listing,
      order_id: item.id,
    })),
  };

  track('checkout.started', { ...props });
};
