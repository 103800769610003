import get from 'lodash/get';

import TopbarContainer from '@/containers/TopbarContainer/TopbarContainer';

import AuthModal from '@/components/AuthModal/AuthModal';
import BottomBar from '@/components/BottomBar/BottomBar';
import CookieConsent from '@/components/CookieConsent/CookieConsent';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import Footer from '@/components/Footer/Footer';
import IconSpinner from '@/components/IconSpinner/IconSpinner';

import { getProviderNavItems } from '@/util/helpers';
import { matchPathname, pathByRouteName } from '@/util/routes';
import useMediaQuery from '@/util/useMediaQuery';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

import SidebarLayout from './SidebarLayout';

function StartupContainer(props) {
  const {
    children,
    location,
    isAuthenticated,
    currentUser,
    history,
    onLogout,
    scrollingDisabled,
    marketplace,
    hideNavigation,
    authModalOpened,
    authModalSuccessAction,
    onSetAuthModalOpened,
    intl,
    preview = false,
  } = props;

  const pathname = window.location.pathname;
  // TODO: Ensure this is not causing bad perf
  const routes = routeConfiguration();
  const matches = matchPathname(pathname, routes);
  const route = matches?.[0]?.route;
  const { fullWidth, whiteBg } = route || {};

  const slugs =
    routes.map(r => {
      if (typeof r.path === 'string') {
        return r.path.split('/')[1];
      } else {
        return r.path[0].split('/')[1];
      }
    }) || [];

  const path = location && location.pathname.slice(1).split('/');

  // eslint-disable-next-line no-unused-vars
  let [currentPath, currentPath1, , currentPath3, currentPath4] = path;
  // when rendering in preview mode, the path is not available. Force the pb UI
  if (preview) {
    currentPath = 'pb';
  }

  const desktop = useMediaQuery('(min-width:768px)');
  const isUnknownPath = !slugs.includes(currentPath) || preview;

  const marketplaceOff = get(marketplace, 'pageConfig.advanced.marketplaceOff');
  const disableCookieMessage = get(marketplace, 'pageConfig.advanced.disableCookieMessage');
  const whiteLabel = get(marketplace, 'pageConfig.advanced.whiteLabel');
  const customNavActive = get(marketplace, 'pageConfig.advanced.customNavActive');
  const customNavConfig = get(marketplace, 'pageConfig.advanced.customNavConfig');
  const crm = get(marketplace, 'applicationConfig.settings.features.crm');
  const crmOnly = get(marketplace, 'pageConfig.crm.crmOnly');
  const containerSize = get(marketplace, 'branding.layout.container');

  const isRootPath = currentPath === '';
  const isRootDashboardPage = isRootPath && marketplaceOff && isAuthenticated;
  const isDiscoverAboutPage = marketplaceOff && currentPath === 'about' && isAuthenticated;
  const isRootPlayBlockPage = isRootPath && !isRootDashboardPage;

  // Stacked header container classes
  const isLargeContainerPath = ['pb', 'about', 'discover'].indexOf(currentPath) > -1 || isRootPlayBlockPage || isUnknownPath;
  let wrapperClassName = isLargeContainerPath && containerSize !== 'sm' ? '' : 'smContainer';
  let containerClasses = 'max-w-container mx-auto';
  const isFullWidthPath =
    [
      's',
      'admin',
      'help',
      'calendar',
      'cms',
      'discover',
      'resource',
      'crm',
      'u',
      'pb',
      'about',
      'become-a-provider',
      'messages',
      'admin-cms',
    ].indexOf(currentPath) > -1 ||
    fullWidth ||
    isRootPlayBlockPage ||
    isDiscoverAboutPage ||
    isUnknownPath ||
    currentPath1 === 'messages' ||
    (crm && crmOnly);
  // full width
  if (isFullWidthPath) containerClasses = '';

  const isListingEditOrCreate = currentPath === 'l' && ['edit', 'new', 'draft'].includes(currentPath3);
  const isListingProfilePage = currentPath === 'l' && !isListingEditOrCreate;
  const isAccountProfilePage = currentPath === 'u';
  const isProfilePage = isListingProfilePage || isAccountProfilePage;

  // Fixed footer offset
  const footerFixedActive = currentPath === 'marketplace' && currentPath1 === 'listings';
  const footerFixedPaddingMaybe = footerFixedActive ? <div style={{ paddingBottom: '91px' }}></div> : null;

  const marketplaceIsLoading = !marketplace || !marketplace.id || marketplace.marketplaceError;
  const scrollDisabledClassNameMaybe = scrollingDisabled ? 'fixed w-screen left-0 right-0 h-screen overflow-hidden' : '';

  const hideNavOnMobileOnly = !desktop && (['messages'].includes(currentPath) || currentPath1 === 'messages');

  const accountNavType = marketplace?.pageConfig?.accounts?.profile?.nav?.type;
  const hideNavOnAccountProfile = isAccountProfilePage && accountNavType;

  const listingNavType = marketplace?.pageConfig?.listingPage?.profile?.navigationType;
  const hideNavOnListingProfile = isListingProfilePage && listingNavType;

  // Should Hide All Nav
  let shouldHideNavigation = hideNavigation || hideNavOnMobileOnly || hideNavOnAccountProfile || hideNavOnListingProfile;

  const isBecomeAProviderStepOne = currentPath === 'become-a-provider' && !currentPath1;
  // Footer Settings
  const hideFooterPaths = [
    's',
    'calendar',
    'listings',
    'forms',
    'bookings',
    'contacts',
    'policies',
    'saved',
    'account',
    'dashboard',
    'products',
    'product',
    'messages',
  ];
  let shouldHideFooter =
    (shouldHideNavigation && currentPath !== 'news' && !isBecomeAProviderStepOne) ||
    isRootDashboardPage ||
    isListingEditOrCreate ||
    hideFooterPaths.indexOf(currentPath) > -1;

  if (whiteLabel && isProfilePage) {
    shouldHideNavigation = true;
    shouldHideFooter = false;
  }

  const footerMaybe = !shouldHideFooter ? <Footer marketplace={marketplace} /> : null;
  const providerNavItems = getProviderNavItems({ currentUser, currentPath, isAuthenticated, marketplaceOff });
  const navConfig = get(marketplace, 'pageConfig.advanced.navConfig');

  // Bottom Bar
  const isCustomNavType = marketplace?.pageConfig?.advanced?.navigationType === 'customMarketingStacked';
  const hideBottomBarOnMobile = isCustomNavType;

  const bottomBarMaybe =
    !desktop && !shouldHideNavigation && !hideBottomBarOnMobile ? (
      <>
        <BottomBar
          navConfig={navConfig}
          providerNavItems={providerNavItems}
          customNavActive={customNavActive}
          customNavConfig={customNavConfig}
          marketplaceOff={marketplaceOff}
          currentPath={currentPath}
          onLogout={onLogout}
          history={history}
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
        />
        <div style={{ paddingBottom: '100px' }}></div>
      </>
    ) : null;

  // The marketplace data should be resloved by the time this component renders - this is a legacy backup
  const loadingSkeletonState = (
    <div className="flex min-h-screen flex-col justify-between">
      <div className="item-center my-12 flex flex-row justify-center">
        <IconSpinner className="h-14 w-14" />
      </div>
    </div>
  );

  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const loadingErrorState = (
    <div className="flex-center fixed top-0 left-0 bottom-0 right-0 flex h-screen w-screen items-center justify-center bg-white">
      <ErrorMessage
        className="max-w-lg"
        title="There was an error loading this marketplace"
        description={marketplace && marketplace.marketplaceError ? marketplace.marketplaceError.userMessage : ''}
      />
    </div>
  );

  // Loading State
  const marketplaceLoadingMaybe = marketplaceIsLoading ? (
    <div>{marketplace && marketplace.marketplaceError ? loadingErrorState : loadingSkeletonState}</div>
  ) : null;

  // Main Content

  const shouldRenderWhiteBg =
    (currentPath === '' && !isRootDashboardPage) ||
    currentPath === 'about' ||
    (currentPath === 'l' && !isListingEditOrCreate) ||
    currentPath === 'u' ||
    currentPath === 's' ||
    currentPath === 'resource' ||
    currentPath === 'news' ||
    currentPath === 'pb' ||
    currentPath === 'become-a-provider' ||
    whiteBg ||
    isUnknownPath;

  const bgColor = shouldRenderWhiteBg ? 'bg-white-background' : 'bg-background'; // shouldRenderGrayBg ? 'bg-gray-100' : 'bg-white';
  const htmlClassList = document.documentElement.classList;
  if (shouldRenderWhiteBg) {
    htmlClassList.remove('bg-background');
    htmlClassList.add('bg-white-background');
  } else {
    htmlClassList.remove('bg-white-background');
    htmlClassList.add('bg-background');
  }

  const containerWrapperClassName = `block min-h-screen ${scrollDisabledClassNameMaybe} ${wrapperClassName} ${bgColor}`;
  const alwaysShowTopBarShadow = !shouldRenderWhiteBg && currentPath !== 'help';
  const isSidebarNavigationType = get(marketplace, 'pageConfig.advanced.navigationType') === 'sidebar';

  const shouldRenderSearchBar = !marketplaceOff && (currentPath === 's' || currentPath === '' || currentPath === 'about');
  const hideTopbarOnMobile = !isCustomNavType && !desktop;
  const shouldHideTopBar = hideTopbarOnMobile && !shouldRenderSearchBar;

  // Top Nav
  const topbarContainerMaybe =
    !shouldHideNavigation && !shouldHideTopBar ? (
      <TopbarContainer
        isUnknownPath={isUnknownPath}
        shouldRenderSearchBar={shouldRenderSearchBar}
        providerNavItems={providerNavItems}
        currentPath4={currentPath4}
        isFullWidthPath={isFullWidthPath}
        alwaysShowShadow={alwaysShowTopBarShadow}
        desktop={desktop}
        className={scrollingDisabled ? 'z-0' : ''}
        currentPath={currentPath}
        isDiscoverAboutPage={isDiscoverAboutPage}
        isSidebarNavigationType={isSidebarNavigationType}
      />
    ) : null;

  const containerMarginTopClassName =
    (currentPath || isRootDashboardPage) &&
    !isDiscoverAboutPage &&
    currentPath !== 'discover' &&
    !scrollingDisabled !== 's' &&
    !shouldHideNavigation &&
    !['resounce', 'news', 'help', 'r', 'messages', 's'].includes(currentPath) &&
    currentPath1 !== 'messages' &&
    !['s', 'about', 'pb'].includes(currentPath) &&
    !isProfilePage &&
    !isUnknownPath
      ? 'mt-4 sm:mt-6 pb-6'
      : '';

  const containerClassName = `${containerClasses} ${containerMarginTopClassName}`;
  const containerStyles = { minHeight: 'calc(100vh - 72px - 232px - 4rem)' };
  const stackedLayout = (
    <div className={containerWrapperClassName}>
      {topbarContainerMaybe}
      <main style={containerStyles} className={containerClassName}>
        {children}
      </main>

      {footerMaybe}
      {bottomBarMaybe}
      {footerFixedPaddingMaybe}

      <AuthModal
        onSuccess={authModalSuccessAction}
        intl={intl}
        isOpen={authModalOpened}
        onClose={() => onSetAuthModalOpened(false)}
      />
    </div>
  );

  const sidebarLayout = (
    <SidebarLayout
      navConfig={navConfig}
      providerNavItems={providerNavItems}
      isRootDashboardPage={isRootDashboardPage}
      marketplaceOff={marketplaceOff}
      isProfilePage={isProfilePage}
      handleLogout={handleLogout}
      shouldHideNavigation={shouldHideNavigation}
      currentPath={currentPath}
      currentUser={currentUser}
      marketplace={marketplace}
      isAuthenticated={isAuthenticated}
      bgColor={bgColor}
      containerMarginTopClassName={containerMarginTopClassName}
    >
      {children}
      {footerMaybe}

      <AuthModal
        onSuccess={authModalSuccessAction}
        intl={intl}
        isOpen={authModalOpened}
        onClose={() => onSetAuthModalOpened(false)}
      />
    </SidebarLayout>
  );

  const isAboutPageWithMarketplaceOn = currentPath === 'about' && !marketplaceOff;

  const restrictSidebarLayoutOn = ['p', 'help', 'about', 's', 'discover', 'pb', 'account', 'saved', 'news'];
  const shouldRestrictSidebarLayout =
    !isAuthenticated ||
    isProfilePage ||
    restrictSidebarLayoutOn.includes(currentPath) ||
    isRootPlayBlockPage ||
    isUnknownPath ||
    isAboutPageWithMarketplaceOn;

  let isSidebarLayout = desktop && isSidebarNavigationType && !shouldRestrictSidebarLayout;

  if (marketplaceOff && !isAuthenticated && isListingEditOrCreate) {
    isSidebarLayout = false;
  }

  const appContent = isSidebarLayout ? sidebarLayout : stackedLayout;
  const appContentMaybe = !marketplaceIsLoading ? (
    <>
      {appContent}
      {!disableCookieMessage ? <CookieConsent /> : null}
    </>
  ) : null;

  return (
    <>
      {marketplaceLoadingMaybe}
      {appContentMaybe}
    </>
  );
}

export default StartupContainer;
