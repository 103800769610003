declare global {
  interface Array<T> {
    move(from: number, to: number): Array<T>;
  }
}

Array.prototype.move = function (from, to) {
  return this.splice(to, 0, this.splice(from, 1)[0]);
};

export {};
