import { client } from './api';

export const localStorageKey = '__playkit_token__';
export const localStorageRefreshKey = '__playkit_refresh_token__';
export const localStorageSendbirdAccessTokenKey = '__mpc_sb_access_token__';
export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const getlocalStorageKey = isRefreshToken => {
  return isRefreshToken ? localStorageRefreshKey : localStorageKey;
};

const getToken = isRefreshToken => {
  return window.localStorage.getItem(getlocalStorageKey(isRefreshToken));
};

const setToken = (token, isRefreshToken) => {
  return window.localStorage.setItem(getlocalStorageKey(isRefreshToken), token);
};

const setSendbirdToken = token => {
  if (!token) return;
  return window.localStorage.setItem(localStorageSendbirdAccessTokenKey, token);
};

const getSendbirdToken = () => {
  return window.localStorage.getItem(localStorageSendbirdAccessTokenKey);
};

const removeSendbirdToken = () => {
  return window.localStorage.removeItem(localStorageSendbirdAccessTokenKey);
};

const removeToken = isRefreshToken => {
  return window.localStorage.removeItem(getlocalStorageKey(isRefreshToken));
};

const getAndDecodeToken = () => {
  const token = getToken();
  if (!token) return;
  return parseJwt(token);
};

const getAccountId = () => {
  const tokenData = getAndDecodeToken();
  return tokenData ? tokenData.account : null;
};

const login = body => {
  return client('auth/login', { body }).then(response => {
    setToken(response.data.authData.token);
    setToken(response.data.authData.refreshToken, true);
    setSendbirdToken(response.data.authData?.servicesCredentials?.sendbird?.accessToken);
    return response;
  });
};

const logout = () => {
  return client('auth/logout', { method: 'POST' })
    .then(() => {
      removeSendbirdToken();
      removeToken(true);
      return removeToken();
    })
    .catch(() => {
      removeSendbirdToken();
      removeToken();
      removeToken(true);
    });
};

const requestOtp = input => {
  return client(`auth/otp?input=${input}`);
};

const refreshSession = () => {
  const refreshToken = getToken(true);
  const body = { refreshToken };
  return client('auth/refresh', { body });
};

const isAuthenticated = ({ async = true } = {}) => {
  const token = getToken();
  const auth = token !== undefined && token !== null;

  if (!async) {
    return auth;
  }

  if (async) {
    return new Promise(function (resolve) {
      resolve(auth);
    });
  }
};

export {
  getToken,
  setToken,
  removeToken,
  login,
  logout,
  requestOtp,
  refreshSession,
  isAuthenticated,
  getAndDecodeToken,
  getAccountId,
  getSendbirdToken,
  setSendbirdToken,
};
