import { uuidv4 } from '@/util/helpers';

export const googlePhotosToMpcImages = (photos: any) => {
  return photos?.length
    ? photos.map((photo: any) => ({
        id: uuidv4(),
        type: 'image',
        metadata: {
          src: photo.getUrl(),
          height: photo.height,
          width: photo.width,
        },
      }))
    : null;
};
