import { parse } from '@/util/urlHelpers';

import config from '@/config';

import { searchListings } from './SearchPage.duck';

const RESULT_PAGE_SIZE = 24;

export const SearchPageLoadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
  });
};
