import { useState } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';

import LogoContainer from '@/containers/LogoContainer/LogoContainer';

import NamedLink from '@/components/NamedLink/NamedLink';
import TopbarDesktop from '@/components/TopbarDesktop/TopbarDesktop';

import TopbarSearchForm from '@/forms/TopbarSearchForm/TopbarSearchForm';

import { track } from '@/analytics';
import { withViewport } from '@/util/contextHelpers';
import { generateImageKitUrl, getCurrentMarketplaceTheme, getSearchPagePathParams } from '@/util/helpers';
import { FormattedMessage, injectIntl, intlShape } from '@/util/reactIntl';
import { createResourceLocatorString } from '@/util/routes';
import { propTypes } from '@/util/types/propTypes';
import { parse } from '@/util/urlHelpers';
import useMediaQuery from '@/util/useMediaQuery';
import useScrollTrigger from '@/util/useScrollTrigger';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

import * as css from './Topbar.module.css';

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

// https://addyosmani.com/blog/preload-hero-images/
const PreloadDiscoverHeroImageMaybe = ({ discoverSlides, isDiscoverPage, discoverSlideType }) => {
  if (!isDiscoverPage || !discoverSlides?.length || discoverSlideType === 'hidden') return null;
  const discoverSlidesFirstImageId = get(discoverSlides, '[0].image.metadata.name');
  if (!discoverSlidesFirstImageId) return null;
  const url = generateImageKitUrl({ id: discoverSlidesFirstImageId, transformationType: 'lg' });
  return <link rel="preload" as="image" href={url} />;
};

const TopbarComponent = props => {
  const {
    className,
    rootClassName,
    mobileRootClassName,
    mobileClassName,
    isAuthenticated,
    marketplace,
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentPage,
    currentPath4,
    currentPath,
    notificationCount,
    intl,
    location,
    showGenericError,
    onLogout,
    history,
    largeDiscoverSearchActive,
    desktop,
    alwaysShowShadow = false,
    isFullWidthPath,
    listing,
    providerNavItems,
    isDiscoverAboutPage,
    isSidebarNavigationType,
    shouldRenderSearchBar,
    isUnknownPath,
  } = props;
  let { transparent } = props;
  const desktopLarge = useMediaQuery('(min-width:1024px)');
  const [isMobileSearchFieldFocused, setIsMobileSearchFieldFocused] = useState(false);

  const marketplaceOff = get(marketplace, 'pageConfig.advanced.marketplaceOff');
  const whiteLabel = get(marketplace, 'pageConfig.advanced.whiteLabel');
  const customNavActive = get(marketplace, 'pageConfig.advanced.customNavActive');
  const customNavConfig = get(marketplace, 'pageConfig.advanced.customNavConfig');
  const navConfig = get(marketplace, 'pageConfig.advanced.navConfig');
  const theme = getCurrentMarketplaceTheme(marketplace);
  const absolute = marketplaceOff && !desktop;

  let scrolled = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
    threshold: 5,
  });

  const handleSubmit = values => {
    const { history } = props;
    let searchParams = {};
    const searchType = values.keywords || values.keywords === '' ? 'keyword' : 'location';
    const { pathParams, pathIndex } = getSearchPagePathParams();

    if (searchType === 'keyword') {
      searchParams = {
        keywords: values.keywords || undefined,
      };
    } else {
      const { search, selectedPlace } = values.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const currentSearchParams = currentPath === 's' ? window.searchUrlQueryParams || {} : {};
      searchParams = {
        ...currentSearchParams,
        ...originMaybe,
        address: search || null,
        bounds,
      };
    }

    const query = searchParams.address || searchParams.keywords;
    track.products.searched({ query, search_type: searchType });
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), pathParams, searchParams, '', pathIndex));
  };

  const handleLogout = () => {
    onLogout().then(() => {
      const path = '/';

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const { address, origin, bounds, keywords } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Only render current search if full place object is available in the URL params
  const locationFieldsPresent = config.sortSearchByDistance ? address && origin && bounds : address && bounds;
  const initialSearchFormValues = {
    keywords,
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const flexContainer =
    (isFullWidthPath &&
      currentPath !== '' &&
      !['help', 'u', 'resource', 'about', 'pb'].includes(currentPath) &&
      !isUnknownPath) ||
    currentPath === 's';
  let bgClasses = 'bg-white-background';
  const isDiscoverPage = !currentPath || isDiscoverAboutPage;

  // Get discover slider so we can set transparent and large search params early
  const discoverSlides = get(marketplace, 'pageConfig.landingPage.sections[0].metadata.slides');
  const discoverSlideType = get(marketplace, 'pageConfig.landingPage.sections[0].metadata.ui.hero.slideType');

  // Force all slides to have a transparent header if the first slide does
  // This prevents a flash on content at different color
  const discoverFirstSlideTransparent = get(discoverSlides, '[0].ui.hero.transparentHeader') && isDiscoverPage;
  if (discoverFirstSlideTransparent && !transparent) {
    transparent = discoverFirstSlideTransparent;
  }

  const isTransparentSupportedPage = isDiscoverPage || ['about', 'pb'].includes(currentPath) || isUnknownPath;
  let transparentActive =
    (transparent && !scrolled && isTransparentSupportedPage) ||
    (currentPath === 'help' && !scrolled) ||
    (transparent && absolute);

  if (transparentActive && transparent === 'right') transparentActive = desktopLarge ? 'right' : false;
  if (transparentActive && transparent === 'left') transparentActive = desktopLarge ? 'left' : false;

  if (transparentActive) {
    bgClasses = css.transparent;
  }
  let shouldRenderAbsoluteHeaderOffset =
    !isTransparentSupportedPage || (!transparent && isTransparentSupportedPage) ? true : false;

  if (currentPath === 'help') {
    shouldRenderAbsoluteHeaderOffset = false;
  }

  const serachPlaceholder =
    marketplace && marketplace.pageConfig && marketplace.pageConfig.general && marketplace.pageConfig.general.labels
      ? marketplace.pageConfig.general.labels.searchPlaceholder
      : null;

  const searchType =
    marketplace && marketplace.pageConfig && marketplace.pageConfig.search && marketplace.pageConfig.search.searchType
      ? marketplace.pageConfig.search.searchType
      : null;

  // Disable shadow on search mobile
  const shadowClassName = currentPath === 's' ? 'sm:shadow' : 'shadow';
  const shadowClasses =
    scrolled && !absolute
      ? `${shadowClassName} bg-white-background dark:backdrop-blur-md dark:bg-dark-white/90 dark:shadow-gray-100/50`
      : alwaysShowShadow
      ? 'shadow-sm dark:bg-dark-white/90 dark:shadow-gray-100/50'
      : '';
  const classes = classNames(rootClassName || css.root, className, css.fixed, bgClasses, shadowClasses, 'transition-all');

  if (window && window.document) {
    const body = window.document.body;
    const bodyHassScolledAttr = body.hasAttribute('scrolled');

    if (!scrolled && bodyHassScolledAttr) {
      body.removeAttribute('scrolled');
    }

    if (scrolled && !bodyHassScolledAttr) {
      body.setAttribute('scrolled', '');
    }
  }

  const hiddenOnMobile = false; //marketplaceOff || (currentPath !== '' && currentPath !== 's');

  const topbarWrapperClassName = currentPath === 'help' ? 'max-w-4xl mx-auto' : 'max-w-container mx-auto';
  const maybeHiddenOnClassName = hiddenOnMobile ? 'hidden sm:block' : null;

  const discoverFirstSlideSearchBar =
    get(discoverSlides, '[0].ui.hero.largeSearchBar') &&
    ((discoverSlides && discoverSlides.length === 1) || largeDiscoverSearchActive === undefined);

  const onlyShowSearchOnScrollSupportedPath = !currentPath || ['about', 'pb'].includes(currentPath) || isUnknownPath;
  const shouldOnlyShowSearchOnScroll =
    onlyShowSearchOnScrollSupportedPath && (largeDiscoverSearchActive || discoverFirstSlideSearchBar);

  const hideSearchBarOnScrollInputClassNames = `${
    shouldOnlyShowSearchOnScroll && !scrolled ? 'opacity-0 pointer-events-none' : ''
  } transition-opacity`;

  const mobileLogo = (
    <NamedLink
      className={`mr-3 flex flex-col items-center justify-center ${
        transparentActive ? '!text-white' : 'text-primary dark:text-white'
      }`}
      name="LandingPage"
      title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
    >
      <LogoContainer className={`h-8 ${transparentActive ? '!text-white' : 'text-primary dark:text-white'}`} format="desktop" />
    </NamedLink>
  );
  return (
    <>
      <PreloadDiscoverHeroImageMaybe
        isDiscoverPage={isDiscoverPage}
        discoverSlides={discoverSlides}
        discoverSlideType={discoverSlideType}
      />

      <div className={`${classes} ${absolute ? '!absolute' : ''} ${maybeHiddenOnClassName}`}>
        {/* MOBILE */}
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <div className="px-container flex flex-1 flex-row py-2">
            {shouldRenderSearchBar ? (
              <>
                {currentPath !== 's' && !isMobileSearchFieldFocused ? mobileLogo : null}
                <TopbarSearchForm
                  inputClassName="ml-0 flex-1 flex"
                  maxWidth={currentPath === 's' ? '500px' : '390px'}
                  className={`flex flex-1 ${shouldOnlyShowSearchOnScroll ? hideSearchBarOnScrollInputClassNames : ''}`}
                  onSubmit={handleSubmit}
                  placeholder={serachPlaceholder}
                  initialValues={initialSearchFormValues}
                  searchType={searchType}
                  currentPath={currentPath}
                  setIsMobileSearchFieldFocused={value => setIsMobileSearchFieldFocused(value)}
                />
              </>
            ) : (
              mobileLogo
            )}
          </div>
        </div>

        <div className={`${css.desktop} ${flexContainer ? '' : topbarWrapperClassName}`}>
          <TopbarDesktop
            isSidebarNavigationType={isSidebarNavigationType}
            providerNavItems={providerNavItems}
            isDiscoverAboutPage={isDiscoverAboutPage}
            listing={listing}
            theme={theme}
            whiteLabel={whiteLabel}
            shouldOnlyShowSearchOnScroll={shouldOnlyShowSearchOnScroll}
            hideSearchBarOnScrollClassNames={hideSearchBarOnScrollInputClassNames}
            hideSearch={!shouldRenderSearchBar}
            serachPlaceholder={serachPlaceholder}
            className={''}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            currentPath={currentPath}
            currentPath4={currentPath4}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            marketplaceOff={marketplaceOff}
            searchType={searchType}
            transparentActive={transparentActive}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={handleLogout}
            onSearchSubmit={handleSubmit}
            customNavActive={customNavActive}
            customNavConfig={customNavConfig}
            navConfig={navConfig}
          />
        </div>

        <GenericError show={showGenericError} />
      </div>

      {shouldRenderAbsoluteHeaderOffset ? <div className={`${css.absoluteHeaderOffset} ${maybeHiddenOnClassName}`}></div> : null}
    </>
  );
};

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
