import { useEffect, useState } from 'react';

import { Form as FinalForm } from 'react-final-form';

import LogoContainer from '@/containers/LogoContainer/LogoContainer';

import ButtonLight from '@/components/Button/ButtonLight';
import Dialog from '@/components/Dialog/Dialog';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';
import NamedLink from '@/components/NamedLink/NamedLink';
import SlideOver from '@/components/SlideOver/SlideOver';
import TabsLight from '@/components/TabsLight/TabsLight';

import { LoginFormFields } from '@/forms/LoginForm/LoginForm';
import { PoliciesText, SignupFormFields } from '@/forms/SignupForm/SignupForm';

import { login, signup, submitOtp } from '@/ducks/Auth.duck';

import * as validators from '@/util/validators';
import { FormattedMessage } from '@/util/reactIntl';
import { composeValidators, required } from '@/util/validators';

const GuestFormFields = ({ formId }) => {
  return (
    <div className="space-y-6">
      <FieldTextInput
        type="text"
        id={`${formId}.name`}
        name="name"
        autoComplete="name"
        label="Name"
        placeholder=""
        validate={validators.required('Please enter your name')}
      />

      <FieldTextInput
        type="email"
        id={`${formId}.email`}
        name="email"
        autoComplete="email"
        label="Email Address"
        placeholder=""
        hint="We use your email to send you an email confirmation."
        hintLocation="bottom"
        validate={validators.composeValidators(
          validators.required('Email is required'),
          validators.emailFormatValid('A valid email is required')
        )}
      />
    </div>
  );
};

const onHandleAuthenticateAccount = params => {
  const dispatch = window.app && window.app.store.dispatch;
  const { type, values, view, otpRequestId, otpRequestEmail } = params;
  const { name, email, password, otp, firstName, lastName } = values;

  if (view === 'verify') {
    return dispatch(submitOtp(otpRequestEmail, otp, otpRequestId));
  }

  if (type === 'create') {
    return dispatch(signup({ name, email, password, firstName, lastName }));
  } else {
    return dispatch(login(email, password, true));
  }
};

const AuthModal = props => {
  const {
    className = '',
    intl,
    onSuccess,
    isOpen,
    onClose,
    showTabs = false,
    withGuestCheckout = false,
    onGuestCheckoutContinue = () => {},
    type = 'dialog',
    description = 'Please signup or login to continue',
  } = props;

  // authenticate or verify
  const [view, setView] = useState('auth');
  const [authenticationTab, setAuthenticationTab] = useState('create');
  const [authRequestInProgress, setAuthRequestInProgress] = useState(false);
  const [authRequestError, setAuthRequestError] = useState(null);
  const [otpRequestId, setOtpRequestId] = useState(null);
  const [otpRequestEmail, setOtpRequestEmail] = useState(null);
  const [delayedIsOpen, setDelayedIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen !== delayedIsOpen) {
      setTimeout(() => setDelayedIsOpen(isOpen), 300);
    }

    if (!isOpen && authRequestError) {
      setAuthRequestError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const verifyContent = (
    <div>
      <div className="mb-6">
        <h1 className="font-header mt-6 text-3xl font-bold">Check your inbox to verify your email</h1>
        <p className="mt-2 text-sm leading-5 text-gray-600">
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email: otpRequestEmail }} />
        </p>
      </div>

      <FieldTextInput
        id="one-time-code"
        type="text"
        autoComplete="one-time-code"
        name="otp"
        hideErrorMessage
        label="One time password code"
        placeholder="Enter the code sent to your email"
        minLength="6"
        maxLength="6"
        validate={composeValidators(required('Please enter a one time code'))}
      />
    </div>
  );

  const isLogin = authenticationTab === 'login';
  const showEmailVerification = view === 'verify';
  const ModalComponent = type === 'dialog' ? Dialog : SlideOver;
  const contentPaddingClassName = type === 'dialog' ? '' : 'px-4 sm:px-6 pt-6 mb-20 pb-8';

  return (
    <ModalComponent
      id="authModal"
      type="slideOver"
      theme="tailwind"
      tailwindCloseButton
      className={className}
      isOpen={isOpen}
      open={isOpen}
      setOpen={onClose}
      contentClassName=""
      contentWrapperPaddingClassName=""
      maxWidthClassName={type === 'dialog' ? 'max-w-xl' : 'max-w-md'}
      zIndexClassName="z-[5001]"
      onClose={onClose}
    >
      {isOpen || delayedIsOpen ? (
        <div className="flex flex-1 flex-col">
          <FinalForm
            {...props}
            formId="authForm"
            onSubmit={values => {
              setAuthRequestInProgress(true);
              return onHandleAuthenticateAccount({ view, type: authenticationTab, values, otpRequestId, otpRequestEmail })
                .then(data => {
                  setAuthRequestInProgress(false);
                  if (authenticationTab === 'create') {
                    if (view === 'auth') {
                      // otp
                      setOtpRequestEmail(values.email);
                      setOtpRequestId(data.id);
                      setView('verify');
                    } else {
                      // otp was verified.. Call success
                      onClose();
                      onSuccess(data);
                    }
                  } else {
                    // if login, call success without OTP flow
                    onClose();
                    onSuccess(data);
                  }
                })
                .catch(err => {
                  setAuthRequestInProgress(false);
                  setAuthRequestError((err && err.userMessage) || 'Unknown Error');
                  throw err;
                });
            }}
            render={fieldRenderProps => {
              const { form, values, handleSubmit, invalid, pristine, submitting } = fieldRenderProps;
              const submitDisabled = invalid || pristine || submitting;

              let authContent;
              switch (authenticationTab) {
                case 'create':
                  authContent = <SignupFormFields values={values} formId="signup" intl={intl} />;
                  break;
                case 'guest':
                  authContent = <GuestFormFields values={values} formId="signup" intl={intl} />;
                  break;
                default:
                  authContent = <LoginFormFields formId="login" intl={intl} />;
                  break;
              }

              const tabs = [
                { title: 'Sign Up', slug: 'create' },
                { title: 'Login', slug: 'login' },
              ];
              if (withGuestCheckout) {
                tabs.push({ title: 'Continue as Guest', slug: 'guest' });
              }

              return (
                <form
                  className="flex flex-1 flex-col"
                  onSubmit={e => {
                    e.preventDefault();

                    if (authenticationTab === 'guest') {
                      onGuestCheckoutContinue({
                        name: values.name,
                        email: values.email,
                      });
                    } else {
                      handleSubmit(e)
                        .then(() => {
                          form.reset();
                        })
                        .catch(() => {
                          // There was an error, do not reset the form
                        });
                    }
                  }}
                >
                  <div className={`flex flex-1 flex-col ${contentPaddingClassName}`}>
                    {/* HEADER */}
                    <div className="mb-8">
                      {type !== 'dialog' ? <LogoContainer className="text-primary mb-6 h-12 w-auto" /> : null}

                      <div className={`space-y-2 ${showEmailVerification ? 'hidden' : ''}`}>
                        {/* HEADER */}
                        <div className={showTabs ? 'hidden' : ''}>
                          <p className="mb-6 text-sm leading-5 text-gray-600">
                            {isLogin ? (
                              <>
                                <span className="text-gray-500">Don't have an account?</span>&nbsp;
                                <button
                                  className="text-accent-600 hover:text-accent-500 font-medium transition duration-150 ease-in-out
                            focus:underline focus:outline-none"
                                  onClick={e => {
                                    e.preventDefault();
                                    setAuthenticationTab('create');
                                  }}
                                >
                                  Sign Up
                                </button>
                              </>
                            ) : (
                              <>
                                <span className="text-gray-500">Already have account?</span>&nbsp;
                                <button
                                  tabIndex="-1"
                                  type="button"
                                  className="text-accent-600 hover:text-accent-500 font-medium transition duration-150 ease-in-out
                            focus:underline focus:outline-none"
                                  onClick={e => {
                                    e.preventDefault();
                                    setAuthenticationTab('login');
                                  }}
                                >
                                  Log In
                                </button>
                              </>
                            )}
                          </p>

                          <h2 className="font-header text-3xl font-bold">
                            {isLogin ? 'Log in to your account' : 'Sign up for an account'}
                          </h2>
                          {description ? <p className="mt-2 text-sm leading-5 text-gray-600">{description}</p> : null}
                        </div>

                        {/* TABS */}
                        {showTabs ? (
                          <div>
                            <TabsLight
                              nagativeMarginsMobile
                              marginClassName="m-0"
                              className="mb-4 border-b border-gray-200"
                              currentPath=""
                              selected={authenticationTab}
                              onChange={tab => {
                                console.log(tab);
                                setAuthenticationTab(tab);
                              }}
                              noLinks
                              tabs={tabs}
                            />
                            <p className="mt-2 text-sm leading-5 text-gray-600">{description}</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {view === 'auth' ? authContent : null}
                    {view === 'verify' ? verifyContent : null}

                    {authRequestError ? (
                      <ErrorMessage
                        className="mt-6"
                        title="There was an error making your request"
                        description={authRequestError}
                      />
                    ) : null}

                    <div className="mt-12">
                      {view === 'auth' && authenticationTab === 'create' ? <PoliciesText /> : null}
                      {view === 'auth' && authenticationTab === 'login' ? (
                        <div className="flex flex-row">
                          <div className="flex-1"></div>
                          <p className="mb-2 text-xs text-gray-500">
                            <NamedLink
                              target="_blank"
                              name="PasswordRecoveryPage"
                              className="hover:text-accent text-gray-600 hover:underline"
                            >
                              Forgot Password?
                            </NamedLink>
                          </p>
                        </div>
                      ) : null}

                      <ButtonLight
                        fullWidthCenterText
                        size="2xl"
                        type="submit"
                        inProgress={authRequestInProgress}
                        disabled={submitDisabled}
                        ready={false}
                      >
                        {view === 'auth' ? (
                          authenticationTab === 'create' ? (
                            <FormattedMessage id="SignupForm.signUp" />
                          ) : authenticationTab === 'guest' ? (
                            'Continue as Guest'
                          ) : (
                            'Login'
                          )
                        ) : (
                          'Verify'
                        )}
                      </ButtonLight>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      ) : null}
    </ModalComponent>
  );
};

export default AuthModal;
