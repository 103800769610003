import React from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import PropTypes from "prop-types"; // ES6
import Media from "@marketplace-co/react-application-element-media";

const defaultClassNames = {
  titleTextClasses:
    "font-display text-3xl tracking-tight text-gray-900 sm:text-4xl",
  descriptionTextClasses: "text-lg tracking-tight text-gray-700",
  bg600: "bg-primary-600",
  text600: "text-primary-600",
  mediaAspectRatio: "aspect-[844/428]",
};

function Feature({ feature, isActive, className, classNames, ...props }) {
  return (
    <div
      className={clsx(className, { "opacity-75 hover:opacity-100": !isActive })}
      {...props}
    >
      <div
        className={clsx("w-9 rounded-lg", {
          [classNames.bg600]: isActive,
          "bg-gray-500": !isActive,
        })}
      >
        <feature.icon className="h-9 w-9" />
      </div>
      <h3
        className={clsx("mt-6 text-sm font-medium outline-none", {
          [classNames.text600]: isActive,
          "text-gray-600": !isActive,
        })}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-gray-900">
        {feature.summary}
      </p>
      <p className="mt-4 text-sm text-gray-600">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile({ features, classNames }) {
  return (
    <div className="-mx-4 mt-20 space-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features?.map((feature) => (
        <div key={feature.name}>
          <Feature
            classNames={classNames}
            feature={feature}
            className="mx-auto max-w-2xl"
            isActive
          />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-gray-200 sm:-inset-x-6" />
            <div
              className={`relative mx-auto ${classNames.mediaAspectRatio} w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-gray-900/5 ring-1 ring-gray-500/10`}
            >
              <Media
                image={feature.image} // deprecated, use media instead
                {...feature.media}
                imageProps={{
                  layout: "fill",
                  sizes: "52.75rem",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop({ features, classNames }) {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {features?.map((feature, featureIndex) => (
              <Feature
                classNames={classNames}
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative outline-none"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-gray-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features?.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.name}
                  className={clsx(
                    "px-5 transition duration-500 ease-in-out outline-none",
                    {
                      "opacity-60": featureIndex !== selectedIndex,
                    }
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div
                    className={`relative ${classNames.mediaAspectRatio} w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-gray-900/5 ring-1 ring-gray-500/10`}
                  >
                    <Media
                      image={feature.image} // deprecated, use media instead
                      {...feature.media}
                      imageProps={{
                        layout: "fill",
                        sizes: "52.75rem",
                      }}
                    />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-gray-900/10" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
}

export default function MarketingFeatureSelectorGrid({
  className,
  classNames: _classNames,
  features,
  title,
  description,
}) {
  if (!features?.length) return null;

  const classNames = {
    ...defaultClassNames,
    ..._classNames,
  };

  return (
    <section
      id="secondary-features"
      aria-labelledby="secondary-features-title"
      className={className}
    >
      <div className="mx-auto max-w-container px-container">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className={classNames.titleTextClasses}>{title}</h2>
          <p className={`mt-5 ${classNames.descriptionTextClasses}`}>
            {description}
          </p>
        </div>
        <FeaturesMobile classNames={classNames} features={features} />
        <FeaturesDesktop classNames={classNames} features={features} />
      </div>
    </section>
  );
}
MarketingFeatureSelectorGrid.propTypes = {
  /**
   * class applied to the wrapper, this will overwrite the default classes
   */
  className: PropTypes.string,
  /**
   * Title of the section
   */
  title: PropTypes.string.isRequired,
  /**
   * Description of the section
   */
  description: PropTypes.string.isRequired,
  /**
   * Features to display
   */
  features: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ).isRequired,

  /**
   * Control the class names of the markup in various states
   */
  classNames: PropTypes.shape({
    titleTextClasses: PropTypes.string,
    descriptionTextClasses: PropTypes.string,
    bg600: PropTypes.string,
    text600: PropTypes.string,
  }),
};
MarketingFeatureSelectorGrid.defaultProps = {
  className: "pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32",
  classNames: defaultClassNames,
  features: [],
};
