import { types as sdkTypes } from '@/util/sdkLoader';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

const placeOrigin = place => {
  if (place && place.geometry && place.geometry.location) {
    return new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
  }
  return null;
};

const placeBounds = place => {
  if (place && place.geometry && place.geometry.viewport) {
    const ne = place.geometry.viewport.getNorthEast();
    const sw = place.geometry.viewport.getSouthWest();
    return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
  }
  return null;
};

const getAddressParts = parts => {
  const getKeyName = name => {
    switch (name) {
      case 'administrative_area_level_1':
        return 'state';
      case 'administrative_area_level_2':
        return 'county';
      case 'sublocality':
        return 'sublocality';
      case 'postal_code':
        return 'postCode';
      case 'locality':
        return 'city';
      case 'postal_town':
        return 'town';
      case 'route':
        return 'streetName';
      case 'street_number':
        return 'streetNumber';
      case 'postal_code_suffix':
        return 'postalCodeSuffix';
      case 'natural_feature':
        return 'naturalFeature';
      default:
        return name;
    }
  };

  return parts.reduce((seed, { long_name, types, short_name }) => {
    types.forEach(t => {
      const key = getKeyName(t);

      // Set short codes
      if (key === 'state') seed['stateCode'] = short_name;
      if (key === 'country') seed['countryCode'] = short_name;

      seed[key] = long_name;
    });

    const { streetName = '', streetNumber = '' } = seed;
    return {
      ...seed,
      street: streetNumber ? `${streetNumber} ${streetName}` : null,
    };
  }, {});
};

/**
 * Get a detailed place object
 *
 * @param {String} placeId - ID for a place received from the
 * autocomplete service
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 *
 * @return {Promise<util.propTypes.place>} Promise that
 * resolves to the detailed place, rejects if the request failed
 */
export const getPlaceDetails = (placeId, sessionToken) =>
  new Promise((resolve, reject) => {
    const serviceStatus = window.google.maps.places.PlacesServiceStatus;
    const el = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(el);
    const fields = ['address_component', 'formatted_address', 'geometry', 'place_id', 'name', 'types'];
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getDetails({ placeId, fields, ...sessionTokenMaybe }, (place, status) => {
      if (status !== serviceStatus.OK) {
        reject(new Error(`Could not get details for place id "${placeId}", error status was "${status}"`));
      } else {
        const addressParts = getAddressParts(place.address_components);
        if (!addressParts.city && addressParts.town) addressParts.city = addressParts.town;
        resolve({
          name: place.name,
          address: place.formatted_address,
          formattedAddress: place.formatted_address,
          origin: placeOrigin(place),
          bounds: placeBounds(place),
          types: place.types,
          placeId,
          ...addressParts,
        });
      }
    });
  });

const predictionSuccessful = status => {
  const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
  return status === OK || status === ZERO_RESULTS;
};

/**
 * Get place predictions for the given search
 *
 * @param {String} search - place name or address to search
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 * @param {Object} searchConfigurations - defines the search configurations that can be used with
 * the autocomplete service. Used to restrict search to specific country (or countries).
 *
 * @return {Promise<{ search, predictions[] }>} - Promise of an object
 * with the original search query and an array of
 * `google.maps.places.AutocompletePrediction` objects
 */
export const getPlacePredictions = (search, sessionToken, searchConfigurations) =>
  new Promise((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getPlacePredictions({ input: search, ...sessionTokenMaybe, ...searchConfigurations }, (predictions, status) => {
      if (!predictionSuccessful(status)) {
        reject(new Error(`Prediction service status not OK: ${status}`));
      } else {
        const results = {
          search,
          predictions: predictions || [],
        };
        resolve(results);
      }
    });
  });

/**
 * Calculate a bounding box in the given location
 *
 * @param {latlng} center - center of the bounding box
 * @param {distance} distance - distance in meters from the center to
 * the sides of the bounding box
 *
 * @return {LatLngBounds} bounding box around the given location
 *
 */
export const locationBounds = (latlng, distance) => {
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};
