import { Menu, Transition } from '@headlessui/react';
import { Component } from 'react';

import { arrayOf, func, number, shape, string } from 'prop-types';

import * as css from '@/components/FilterPopup/FilterPopup.module.css';
import ButtonLight from '@/components/Button/ButtonLight';

import { CheckIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(urlParam, option);
  }

  render() {
    const { className, urlParam, label, options, initialValue, left = false } = this.props;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
    const isSelected = initialValue ? true : false;
    return (
      <div className={`${className} relative z-20 inline-block text-left`}>
        <Menu>
          {({ open }) => (
            <>
              <span className="rounded-4xl shadow-sm">
                <Menu.Button
                  as={ButtonLight}
                  inlineBlock
                  noShadow
                  filter
                  filterOpen={open}
                  filterActive={isSelected}
                  theme={isSelected ? 'primary' : 'filter'}
                  // className={`${
                  //   isSelected
                  //     ? 'border-accent bg-gray-50 font-bold ring-1'
                  //     : `${
                  //         open ? 'border-accent' : 'focus:text-bold border-gray-300 hover:border-gray-700'
                  //       } active:border-accent bg-white text-gray-700 active:bg-gray-50 active:text-gray-800 active:ring-1 active:ring-offset-0`
                  // } rounded-4xl ring-accent-500 inline-flex w-full cursor-pointer justify-center whitespace-nowrap border px-3 py-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none ${
                  //   open ? 'bg-gray-50 font-bold ring-1' : ''
                  // }`}
                >
                  <span data-title={menuLabel} className={css.labelWrapper}>
                    {menuLabel}
                  </span>
                </Menu.Button>
              </span>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className={`absolute z-10 ${left ? 'left' : 'right'}-0 mt-2 w-56 origin-top-${
                    left ? 'left' : 'right'
                  } divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                >
                  <div className="py-1">
                    {options.map(option => {
                      const selected = initialValue === option.key;
                      return (
                        <Menu.Item key={option.key}>
                          {({ active }) => (
                            <li
                              onClick={() => this.selectOption(urlParam, option.key)}
                              className={`${
                                active ? 'bg-accent-600 text-white' : 'text-gray-900'
                              } relative flex w-full cursor-pointer justify-between py-2 pl-8 pr-4 text-left text-sm leading-5`}
                            >
                              <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                {option.longLabel || option.label}
                              </span>
                              <span
                                className={`${
                                  (selected && !active) || (!selected && active)
                                    ? 'text-accent-600'
                                    : active
                                    ? 'text-white'
                                    : 'text-transparent'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <CheckIcon className="h-5 w-5" />
                              </span>
                            </li>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  }
}

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
