import { stringify } from '@/util/urlHelpers';

import config from '@/config';

import { client } from './api';

const create = body => {
  return client('marketplaces', { body });
};

const update = (id, body) => {
  return client(`marketplaces/${id}`, { body, method: 'PUT' });
};

const updateServiceCredentials = (id, body) => {
  return client(`marketplaces/${id}/services-credentials`, { body, method: 'PUT' });
};

const list = params => {
  return client(`marketplaces?${stringify(params)}`);
};

const read = () => {
  let key = !config.local && !config.test ? window.location.hostname : config.sdk.marketplaceId;
  key = config.sdk.marketplaceId;
  if (window.location.hostname.includes('.')) key = window.location.hostname;
  return client(`marketplaces/${encodeURIComponent(key)}`);
};

const updateDomain = (id, body) => {
  return client(`marketplaces/${id}/setup/domain`, { body });
};

const setupStripe = (id, body) => {
  return client(`marketplaces/${id}/setup/stripe`, { body });
};

const setupSendbird = (id, body) => {
  return client(`marketplaces/${id}/setup/sendbird`, { body });
};

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/microMarketplaces' : 'micro-marketplaces';
};
const micro = {
  _list: async params => client(`micro-marketplaces?${stringify(params)}`),
  list: async queryParams => {
    const baseUrl = computeBaseUrl(queryParams);
    return client(`${baseUrl}?${stringify(queryParams)}`);
  },
  create: async body => client('micro-marketplaces', { body }),
  update: async (id, body) => client(`micro-marketplaces/${id}`, { body, method: 'PUT' }),
  read: async key => {
    const queryParams = {
      expand:
        'filters.custom.filters.listing.categories.values,filters.custom.filters.provider.categories.values,filters.providers',
    };
    const baseUrl = computeBaseUrl(queryParams);
    return client(`${baseUrl}/${encodeURIComponent(key)}?${stringify(queryParams)}`);
  },

  updateServiceCredentials: async (id, body) => {
    return client(`micro-marketplaces/${id}/services-credentials`, { body, method: 'PUT' });
  },
};

export { micro, read, create, update, updateDomain, setupStripe, list, setupSendbird, updateServiceCredentials };
