import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { string } from 'prop-types';

import LogoContainer from '@/containers/LogoContainer/LogoContainer';

import ExternalLink from '@/components/ExternalLink/ExternalLink';

import { computeDynamicHref } from '@/util/helpers';
import { injectIntl, intlShape } from '@/util/reactIntl';

import config from '@/config';

const year = new Date().getFullYear();

export const renderSocialIcon = (key, _className) => {
  const className = _className || 'h-6 w-6';
  switch (key) {
    case 'facebook':
      return (
        <svg className={className} fill="currentColor" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'instagram-outline':
      return (
        <svg className={className} fill="currentColor" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'instagram':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1024 1024">
          <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7z" />
          <path d="M911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9c-.1 26.6-21.4 47.9-47.9 47.9z" />
        </svg>
      );
    case 'twitter':
      return (
        <svg className={className} fill="currentColor" viewBox="0 0 24 24">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      );
    case 'youtube':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={className} viewBox="0 0 24 24">
          <path d="M18.352 4.647c-3.006-.206-9.702-.205-12.704 0-3.25.221-3.633 2.185-3.657 7.353.024 5.159.403 7.13 3.657 7.353 3.003.205 9.698.206 12.704 0 3.25-.222 3.633-2.185 3.657-7.353-.024-5.159-.404-7.13-3.657-7.353Zm-8.854 10.69V8.663l6.673 3.33-6.673 3.342Z" />
        </svg>
      );
    case 'youtube-outline':
      return (
        <svg className={className} fill="currentColor" viewBox="0 0 48 48">
          <path
            clipRule="evenodd"
            d="m7.98985 6.56575s5.51015-.56575 16.01015-.56575 16.0217.56647 16.0217.56647c3.3017.23943 6.4778 3.06653 7.0105 6.33583 0 0 .9678 3.5977.9678 11.0977s-.9707 11.1173-.9707 11.1173c-.5685 3.2489-3.6992 6.0851-7.0132 6.3061 0 0-6.0161.5766-16.0161.5766s-16.0277-.5774-16.0277-.5774c-3.29841-.2334-6.47183-3.0556-7.004522-6.3249 0 0-.967778-3.5977-.967778-11.0977s.970707-11.1173.970707-11.1173c.568463-3.24895 3.709413-6.09233 7.019143-6.31695zm-3.53877 9.58555c-.28047 2.1287-.45108 4.7418-.45108 7.8487 0 3.1071.17009 5.7166.44964 7.839.15847 1.2032.46607 2.6154.46607 2.6154.22949 1.4084 1.85968 2.8735 3.43798 2.9865.16133.0154 2.91591.2143 4.09161.2759 3.3781.1769 7.2513.2832 11.5547.2832 4.3035 0 8.1744-.1062 11.5489-.2828 1.1744-.0615 3.9246-.2602 4.0855-.2756 1.5794-.107 3.2021-1.5694 3.4548-3.0137 0 0 .3006-1.3717.4597-2.5792.2805-2.1287.4511-4.7418.4511-7.8487 0-3.1071-.1701-5.7166-.4496-7.839-.1585-1.2032-.4661-2.6154-.4661-2.6154-.2298-1.4103-1.8658-2.8818-3.3519-2.9896-.2591-.0248-2.8496-.2181-3.9941-.2783-3.2967-.1734-7.2151-.2777-11.7383-.2777s-8.4395.1042-11.7326.2774c-1.1431.0601-4.00671.2792-4.00671.2792-1.46392.0993-3.09727 1.5719-3.34984 3.0155 0 0-.30066 1.3717-.45977 2.5792zm16.31252 2.1276c-.9573-.5331-1.7466-.0717-1.7493 1.0381l-.0228 9.4036c-.0027 1.108.7783 1.5706 1.7443 1.0291l8.531-4.7814c.9599-.538.9682-1.4153.0047-1.9518z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );
    case 'linkedin':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M17.833 2H6.167A4.167 4.167 0 0 0 2 6.167v11.666C2 20.134 3.866 22 6.167 22h11.666A4.167 4.167 0 0 0 22 17.833V6.167C22 3.866 20.135 2 17.833 2ZM8.667 17.833h-2.5V8.667h2.5v9.166ZM7.417 7.61a1.465 1.465 0 0 1-1.459-1.47c0-.812.654-1.47 1.459-1.47s1.458.658 1.458 1.47c0 .812-.652 1.47-1.458 1.47Zm11.25 10.223h-2.5v-4.67c0-2.806-3.334-2.594-3.334 0v4.67h-2.5V8.667h2.5v1.47c1.164-2.155 5.834-2.314 5.834 2.064v5.632Z" />
        </svg>
      );
    case 'github':
      return (
        <svg className={className} fill="currentColor" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      );
    default:
      break;
  }
};

const FooterLink = ({ link, className = 'text-base leading-6 text-gray-500 hover:text-gray-900' }) => {
  const { linkType, label } = link;
  const href = computeDynamicHref(link);
  if (linkType === 'external') {
    return (
      <ExternalLink href={href} className={className} title={label}>
        {label}
      </ExternalLink>
    );
  }
  return (
    <Link to={href} className={className} title={label}>
      {label}
    </Link>
  );
};

const FooterMarketingSimple = ({ copyrightText, social, links }) => {
  return (
    <footer>
      <div className="max-w-container px-container mx-auto">
        <div className="py-16">
          {/* Logo */}
          <div className="flex flex-row items-center justify-center">
            <Link to="/">
              <LogoContainer className="mx-auto h-10 w-auto" />
            </Link>
          </div>

          {/* Nav Links */}
          <nav className="mt-10 text-sm" aria-label="quick links">
            <ul className="-my-1 flex justify-center space-x-6">
              {links.map((link, index) => (
                <li key={`link_${index}`}>
                  <FooterLink link={link} className="rounded-lg px-2 py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900" />
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {/* Bottom footer */}
        <div className="flex flex-col items-center border-t border-gray-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          {/* Icons */}
          <div className="flex space-x-6">
            {social
              ? social.map((data, index) => {
                  const mlClassName = index === 0 ? '' : 'ml-6';
                  return (
                    <a
                      key={`social_${index}`}
                      href={data.hyperLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-gray-400 hover:text-gray-600 ${mlClassName}`}
                    >
                      <span className="sr-only capitalize">{data.key}</span>
                      {renderSocialIcon(data.key)}
                    </a>
                  );
                })
              : null}
          </div>
          {/* Copyright Text */}
          <p className="mt-6 text-sm text-gray-500 sm:mt-0">
            &copy; {year} {copyrightText}
          </p>
        </div>
      </div>
    </footer>
  );
};

const Footer = props => {
  const { rootClassName, className, marketplace = {} } = props;
  const classes = classNames(rootClassName || 'w-full', className);

  const marketplaceFooterConfig = marketplace?.pageConfig?.general?.footer;
  const marketplaceFooterConfigHasRequiredData =
    marketplaceFooterConfig?.copyrightText && marketplaceFooterConfig?.social?.length && marketplaceFooterConfig?.links?.length
      ? true
      : false;

  const footerConfig = marketplaceFooterConfigHasRequiredData ? marketplaceFooterConfig : config.custom.footerConfigDefault;
  const { links, social, copyrightText, type } = footerConfig || {};

  let footerComponent = (
    <div className={classes}>
      <div className="mx-container mt-12 border-t border-gray-200">
        <div className="mx-auto max-w-screen-xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            {links.map((link, index) => (
              <div key={`link_${index}`} className="px-5 py-2">
                <FooterLink link={link} />
              </div>
            ))}
          </nav>

          <div className="mt-8 flex justify-center">
            {social.map((data, index) => {
              const mlClassName = index === 0 ? '' : 'ml-6';
              return (
                <a
                  key={`social_${index}`}
                  href={data.hyperLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-gray-400 hover:text-gray-500 ${mlClassName}`}
                >
                  <span className="sr-only capitalize">{data.key}</span>
                  {renderSocialIcon(data.key)}
                </a>
              );
            })}
          </div>

          <div className="mt-8">
            <p className="text-center text-base leading-6 text-gray-400">
              &copy; {year} {copyrightText} ·{' '}
              <Link className="hover:text-primary text-gray-400 hover:underline" to="/privacy-policy">
                Privacy
              </Link>{' '}
              ·{' '}
              <Link className="hover:text-primary text-gray-400 hover:underline" to="/terms-of-service">
                Terms
              </Link>{' '}
              ·{' '}
              <Link className="hover:text-primary text-gray-400 hover:underline" to="/cookie-policy">
                Cookies
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  if (type === 'marketingSimple') {
    footerComponent = <FooterMarketingSimple copyrightText={copyrightText} social={social} links={links} />;
  }

  return (
    <>
      {footerComponent}
      {marketplace?.microMarketplace?.id ? (
        <div className="mb-8 mt-1 text-center px-container text-gray-700 text-sm">
          Powered by{' '}
          <a className="hover:text-primary" href={`http://${marketplace.customDomain}`} target="_blank" rel="noopener noreferrer">
            {marketplace?.parentMarketplace?.title}
          </a>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
