@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: rgb(var(--mpc-color-white-bg));
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: currentColor;
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  top: var(--LocationAutocompleteInput_inputHeight);
  left: 0;
  background-color: white;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  z-index: calc(var(--zIndexPopup) + 1);
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  display: none;
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left var(--LocationAutocompleteInput_sidePaddingDesktop);
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  @apply __marketplaceBodyFontStyles;

  margin: 0;
}

.currentLocation {
  @apply __marketplaceH4FontStyles;
}

.currentLocationIcon {
  margin-right: 10px;
}
