import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/contacts' : 'contacts';
};

const create = body => {
  return client('contacts', { body });
};

const read = (id, params = { expand: 'groups,identifiedAccount' }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}/${id}?${stringify(params)}`);
};

const update = (id, body) => {
  return client(`contacts/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`contacts/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000, expand: 'groups' }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}?${stringify(params)}`);
};

export { read, list, update, create, remove };
