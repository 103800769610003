import { Menu, Transition } from '@headlessui/react';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

import { ChevronDownIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import { CheckIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const PortalMaybe = ({ children, usePortal }) => {
  if (usePortal) {
    return <Portal>{children}</Portal>;
  }
  return children;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuButton = props => {
  const {
    className = '',
    label,
    left = false,
    initialValue = '',
    buttonPaddingClassName = 'px-4 py-2',
    placement,
    customContent,
    options = [],
    noCheckmark = false,
    onSelect = () => {},
    usePortal = false,
    buttonClassName,
    noFilterLabelWrapper = false,
    extraButtonClassName = '',
    widthClassName = 'w-56',
    ringOffsetClassName = 'ring-offset-2',
    offset = [0, 8],
    filter = false,
    filterIsActive = false,
    type = 'select',
  } = props;

  // https://github.com/tailwindlabs/headlessui/issues/154
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placement ? placement : !left ? 'bottom-end' : 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset } }],
  });

  const getButtonClasses = ({ open }) => {
    const buttonClasses =
      buttonClassName ||
      `border-gray-300 ${buttonPaddingClassName} bg-white text-gray-700 hover:text-gray-500 active:bg-gray-50 active:text-gray-800 whitespace-nowrap cursor-pointer inline-flex justify-center w-full text-sm font-medium leading-5 transition border rounded-md focus:outline-none ${ringOffsetClassName} ring-accent-500`;
    const nonFilterClasses = `${buttonClasses} ring-accent-500 ${open ? `ring-2 ${ringOffsetClassName}` : 'focus:ring-2'}`;

    const filterClasses = `${
      filterIsActive
        ? 'font-bold bg-gray-50 border-accent ring-1'
        : `${
            open ? 'border-accent' : 'border-gray-300 hover:border-gray-700 focus:text-bold'
          } bg-white text-gray-700 active:bg-gray-50 active:text-gray-800 active:ring-1 active:border-accent active:ring-offset-0`
    } ${extraButtonClassName} whitespace-nowrap cursor-pointer inline-flex justify-center w-full px-3 py-1 text-sm font-medium leading-5 transition duration-150 ease-in-out border rounded-4xl focus:outline-none ring-accent-500 ${
      open ? 'ring-1 font-bold bg-gray-50' : ''
    }`;

    return filter ? filterClasses : nonFilterClasses;
  };

  const getButtonWrapperClasses = () => {
    if (filter) return 'rounded-4xl';
    return !buttonClassName ? 'rounded-md shadow-sm' : '';
  };

  const menuContent = filter ? (
    <div className="flex flex-1 flex-row items-center">
      {noFilterLabelWrapper ? (
        label
      ) : (
        <span data-title={label} className="labelWrapper">
          {label}
        </span>
      )}

      <ChevronDownIcon className="-mr-1 ml-1 h-4 w-4" />
    </div>
  ) : (
    label
  );

  const selectionContent = (
    <div className="py-1">
      {options &&
        options.map(option => {
          const selected = initialValue === option.key || (option.key === '' && initialValue === null);
          return (
            <Menu.Item key={option.key}>
              {({ active }) => (
                <li
                  onClick={() => {
                    onSelect(option);
                  }}
                  className={`${active ? 'bg-accent-600 text-white' : 'text-gray-900'} flex w-full justify-between py-2 ${
                    !noCheckmark ? 'pl-8 pr-4' : 'px-4'
                  } relative cursor-pointer text-left text-sm leading-5 focus:outline-none`}
                >
                  <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>{option.label}</span>
                  <span
                    className={`${
                      (selected && !active) || (!selected && active)
                        ? 'text-accent-600'
                        : active
                        ? 'text-white'
                        : 'text-transparent'
                    } absolute inset-y-0 left-0 flex items-center pl-1.5 ${noCheckmark ? 'hidden' : ''}`}
                  >
                    <CheckIcon className="h-5 w-5" />
                  </span>
                </li>
              )}
            </Menu.Item>
          );
        })}
    </div>
  );

  const dropdownContent = (
    <div className="py-1">
      {options &&
        options.map(option => {
          const { onClick } = option;

          const content = (
            <>
              {option.icon ? option.icon({ className: 'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' }) : null}
              {option.label}
            </>
          );

          const className = active => {
            return classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'group flex items-center px-4 py-2 text-sm w-full focus:outline-none'
            );
          };

          return (
            <Menu.Item key={option.key}>
              {({ active }) =>
                onClick ? (
                  <button onClick={onClick} className={className(active)}>
                    {content}
                  </button>
                ) : option.target === '_blank' ? (
                  <a href={option.href} target="_blank" rel="noreferrer" className={className(active)}>
                    {content}
                  </a>
                ) : (
                  <Link to={option.href} className={className(active)}>
                    {content}
                  </Link>
                )
              }
            </Menu.Item>
          );
        })}
    </div>
  );
  const content = customContent ? customContent : type === 'dropdown' ? dropdownContent : selectionContent;

  return (
    <div className={`${className} relative`}>
      <Menu>
        {({ open }) => (
          <>
            <span ref={setTargetElement} className={getButtonWrapperClasses()}>
              <Menu.Button as="button" className={getButtonClasses({ open })}>
                {menuContent}
              </Menu.Button>
            </span>

            <PortalMaybe usePortal={usePortal}>
              <div className="z-50" ref={popperElRef} style={styles.popper} {...attributes.popper}>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                >
                  <Menu.Items
                    static
                    className={`z-10 ${left ? 'left' : 'right'}-0 ${widthClassName} mt-2 origin-top-${
                      left ? 'left' : 'right'
                    } divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-2xl outline-none`}
                  >
                    {content}
                  </Menu.Items>
                </Transition>
              </div>
            </PortalMaybe>
          </>
        )}
      </Menu>
    </div>
  );
};

export default MenuButton;
