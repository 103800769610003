import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useSWR from 'swr';

import IconSpinner from '@/components/IconSpinner';
import UiRenderer from '@/components/UiRenderer';

import { client } from '@/clients';

import config from '@/config';

import { googlePhotosToMpcImages } from './placeHelpers';

const useNearbyPlaces = ({ googleMapsLoaded = false, type = '' }) => {
  // @ts-ignore
  const currentUser = useSelector(state => state?.user?.currentUser);
  const [places, setPlaces] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  useEffect(() => {
    if (!googleMapsLoaded) return;
    const el = document.createElement('div');
    const googlePlaces = new window.google.maps.places.PlacesService(el);
    setPlacesService(googlePlaces);
  }, [googleMapsLoaded]);

  // @ts-ignore
  const { data: ipData } = useSWR('/api/ip', () => client('geolocation', { prefix: '/platform-services' }));
  const { ll, city, region: state, country } = ipData?.data || {};
  const [lat, lng] = ll || [];

  useEffect(() => {
    if (!lat && !lng && !placesService) return;
    const location = new google.maps.LatLng(lat, lng);

    const request = {
      location,
      radius: 20000,
    };
    if (type) {
      // @ts-ignore
      request.type = type;
    }

    placesService?.nearbySearch(request, (results, status) => {
      if (status !== 'OK') {
        setLoading(false);
        setError(true);
        return;
      }
      if (!results?.length) {
        // @ts-ignore
        setPlaces([]);
        return;
      }
      const formattedResults = results.map(result => ({
        ...result,
        place: {
          ...result,
          stateCode: state,
          countryCode: country,
          city,
          county: country !== 'US' ? `City of ${city}` : `${city} County`,
        },
        title: result.name,
        description: result.vicinity,
        assets: googlePhotosToMpcImages(result.photos),
      }));

      console.log(formattedResults);
      // @ts-ignore
      setPlaces(formattedResults);
    });
  }, [lat, lng, placesService, type]);

  return {
    state,
    country,
    currentUser,
    city,
    places,
    error,
    loading: !ipData || !googleMapsLoaded || (!places && !error),
  };
};

const PlaceListNearby = ({
  className = '',
  googleMapsLoaded = false,
  type,
}: {
  className: string;
  googleMapsLoaded: boolean;
  type: Mpc.place['type'];
}) => {
  const { places, loading, city } = useNearbyPlaces({
    googleMapsLoaded,
    type,
  });
  const title = `Nearby ${config.places.types[type]}${city ? ` in ${city}` : ''}`;
  const card = { size: 'grid-default', hideSaveButton: true };
  const uiRendererData = [
    {
      type: 'static',
      recommendations: { data: places },
      metadata: {
        title,
        titleClassNames: { hidden: true },
        ui: {
          type: 'grid',
          grid: { size: '1/4' },
          card,
        },
      },
    },
  ];

  return (
    <div className={className}>
      {loading ? (
        <div className="flex flex-row items-center justify-center">
          <IconSpinner />
        </div>
      ) : // @ts-ignore
      places?.length ? (
        <div className="-mx-container">
          <div className="px-container mb-4 text-xl font-semibold">{title}</div>
          <UiRenderer sectionsWrapperClassName="" minHeightClassName="" bgColorClassName="" data={uiRendererData} />
        </div>
      ) : null}
    </div>
  );
};

export default PlaceListNearby;
