import classNames from 'classnames';
import { func, node, object, oneOfType, string } from 'prop-types';
import { Field } from 'react-final-form';

import ValidationError from '@/components/ValidationError/ValidationError';

import * as css from './FieldSelect.module.css';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.value;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    selectContainerClassName,
    selectWrapperClassName,
    selectPaddingClassName = 'pl-3 pr-10 py-2',
    borderClassName,
    hint,
    noShadow = false,
    hintLocation = 'top',
    maxWidthClassName = 'max-w-lg sm:max-w-xs',
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const trailwindSelectClasses = `block w-full ${selectPaddingClassName} text-base ${
    borderClassName || 'border-gray-300'
  } focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md`;
  const tailwindInputErrorClasses =
    'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 pr-10';

  const selectClasses = classNames(trailwindSelectClasses, selectClassName, {
    [css.selectSuccess]: input.value && valid,
    [css.selectError]: hasError,
    [tailwindInputErrorClasses]: hasError,
  });

  const { onChange: inputOnChange, ...restOfInput } = input;
  const selectProps = {
    className: selectClasses,
    id,
    onChange: handleChange(onChange, inputOnChange),
    ...restOfInput,
    ...rest,
  };

  const classes = classNames(rootClassName, className);
  return (
    <div className={classes}>
      {label ? (
        <div className="mb-1 block text-sm font-medium leading-5 text-gray-700" htmlFor={id}>
          {label}
        </div>
      ) : null}

      {hint && hintLocation === 'top' ? <div className="mb-2 text-sm text-gray-500">{hint}</div> : null}

      <div className={`${selectContainerClassName} mt-1 sm:col-span-2 sm:mt-0`}>
        <div className={`${selectWrapperClassName} ${maxWidthClassName} rounded-md ${!noShadow ? 'shadow-sm' : ''}`}>
          <select {...selectProps}>{children}</select>
        </div>
      </div>

      {hint && hintLocation === 'bottom' && !hasError ? <div className="mt-2 mb-1 text-sm text-gray-500">{hint}</div> : null}

      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: oneOfType([string, object]),

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
