import { twMerge } from 'tailwind-merge';

import SectionImages from '@/containers/ListingPage/SectionImages';

import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import Img from '@/components/Img/Img';

const HeroImages = ({ isPreview, video, images, autoPlayVideo, type, className }) => {
  const numberOfImages = images?.length || 1;
  const headers = {
    1: '1',
    2: '3:1x2-1',
    3: '3:1x2-2',
    4: '3:1x2-2',
    5: '4:1-1-2-1',
  };
  let headerType = headers[numberOfImages] || '1';

  if (numberOfImages > 5) {
    headerType = headers[5];
  }
  if (type && type !== 'auto') headerType = type;
  return (
    <div className={twMerge('max-w-container px-container mx-auto flex w-full flex-row items-center justify-center', className)}>
      <SectionImages
        className="flex-1 overflow-hidden rounded-lg"
        title={''}
        headerType={headerType}
        assets={images}
        isPreview={isPreview}
        // hidePhotosButton
        autoplayVideoTrailer={autoPlayVideo}
        initialVideoTrailer={video}
        rootForImageClassName={isPreview ? '!max-h-[250px] !min-h-[250px]' : '!max-h-[420px]'}
      />
    </div>
  );
};

const HeroHeaderProfile = ({
  children,
  coverImage,
  coverImages,
  video,
  desktop,
  autoPlayVideo,
  className,
  mulitImageClassName,
  type,
}) => {
  if (!coverImage || !coverImages) return null;

  const numberOfImages = type === '1' ? 1 : coverImages?.length;
  if (numberOfImages > 1 || video) {
    if (desktop) {
      return (
        children || (
          <HeroImages
            className={mulitImageClassName}
            type={type}
            images={coverImages}
            autoPlayVideo={autoPlayVideo}
            video={video}
          />
        )
      );
    }

    return (
      <ImageCarousel
        video={video}
        autoPlayVideo={autoPlayVideo}
        aspectRatioClassName="relative md:aspect-h-1 md:aspect-w-4"
        roundedClassName="rounded-none"
        containPortraitImages={desktop}
        useImageAspectRatio={!desktop}
        className={className}
        key={`ImageCarousel_${desktop ? 'desktop' : 'mobile'}`}
        transformationType={!desktop ? 'md' : 'ar-4:1-lg'}
        contentExtraClassName="!bottom-6"
        images={coverImages}
        clickableDots
        priority
      />
    );
  }

  // h-32 w-full object-cover lg:h-48
  // aspect-h-1 aspect-w-2 md:aspect-h-1 md:aspect-w-4
  // relative h-32 lg:h-48 xl:h-64 w-full object-cover bg-gray-50 overflow-hidden
  const { height, width } = coverImage?.metadata || {};
  const aspectRatio = (height / width) * 100;
  const isLandscape = width > height;
  let backgroundSize = 'cover';
  if (!isLandscape) backgroundSize = 'contain';
  return (
    <div className="">
      <div
        style={!desktop ? { height: `${aspectRatio}vw`, maxHeight: '69vh', minHeight: '20vh' } : null}
        className={`md:aspect-h-1 md:aspect-w-4 relative w-full overflow-hidden bg-gray-50 object-cover`}
      >
        {coverImage && coverImage.metadata ? (
          <Img
            innerStyle={{ backgroundSize }}
            className="bg-gray-100"
            transformationType={!desktop ? 'md' : 'ar-2:1-lg'}
            id={coverImage ? coverImage.metadata.name : null}
            key={`_${coverImage && coverImage.id}`}
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default HeroHeaderProfile;
