/* eslint-disable prefer-const */
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isMapsLibLoaded } from '@/components/Map/GoogleMap';

import PlaceDetailPage from './PlaceDetailPage';
import PlaceListPage from './PlaceListPage';

const PlacePage = () => {
  let {
    type,
    country,
    state,
    city,
    slug,
    county,
    street,
  }: {
    type?: Mpc.place['type'];
    country?: string;
    state?: string;
    city?: string;
    county?: string;
    slug?: string;
    street?: string;
  } = useParams();
  // @ts-ignore
  const googleMapsLoaded = useSelector(state => state?.UI?.googleMapsLoaded) || isMapsLibLoaded();

  // Detail Page
  if (!slug && street) {
    slug = street;
    street = '';
  }
  if (type && country && state && city && county && slug) {
    return (
      <PlaceDetailPage
        googleMapsLoaded={googleMapsLoaded}
        type={type}
        country={country}
        state={state}
        city={city}
        county={county}
        street={street}
        slug={slug}
      />
    );
  }

  return (
    <PlaceListPage googleMapsLoaded={googleMapsLoaded} type={type} country={country} state={state} city={city} county={county} />
  );
};

export default PlacePage;
