import { Component } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { FormattedMessage } from '@/util/reactIntl';
import { MegaphoneIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  onAcceptCookies() {
    this.saveCookieConsent();
    this.setState({ show: false });
  }

  saveCookieConsent() {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = 'euCookiesAccepted=1; path=/; expires=' + expirationDate.toGMTString();
  }

  render() {
    // Server side doesn't know about cookie consent
    if (!this.state.show) return null;

    const cookieLink = (
      <Link to="/cookie-policy" className="border-b border-white text-white">
        <FormattedMessage id="CookieConsent.cookieLink" />
      </Link>
    );

    return (
      <div className="z-100 fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
        <div className="mx-auto max-w-screen-xl px-2 sm:px-6 lg:px-8">
          <div className="bg-primary-600 rounded-lg p-2 shadow-lg sm:p-3">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="bg-primary-800 flex rounded-lg p-2">
                  <MegaphoneIcon className="h-6 w-6 text-white" />
                </span>
                <p className="ml-2 text-sm font-medium text-white sm:ml-3 sm:text-base">
                  <FormattedMessage id="CookieConsent.message" values={{ cookieLink }} />
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <div className="rounded-md shadow-sm">
                  <button
                    onClick={this.onAcceptCookies}
                    className="text-primary-600 hover:text-primary-500 focus:shadow-outline flex w-full items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none sm:w-auto"
                  >
                    <FormattedMessage id="CookieConsent.continue" />
                  </button>
                </div>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3">
                <div className="-mr-1 flex p-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { string } = PropTypes;

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,
};

export default CookieConsent;
