import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const create = body => {
  return client('articles', { body });
};

const read = (id, { platformRequest } = {}) => {
  return client(`articles/${id}`, { platformRequest });
};

const update = (id, body) => {
  return client(`articles/${id}`, { body, method: 'PUT' });
};

const list = (params = { limit: 1000 }, { platformRequest = false } = {}) => {
  return client(`articles?${stringify(params)}`, { platformRequest });
};

export { read, list, update, create };
