import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconLogo from './IconLogo';

const Logo = props => {
  const { className, format, svg, ...rest } = props;
  const mobileClasses = classNames(className);

  // If you want to use image instead of svg as a logo you can use the following code.
  // Also, remember to import the image as LogoImage here.
  // <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />

  return (
    <IconLogo
      className={`${format === 'desktop' ? className : mobileClasses} transition-all`}
      format={format}
      svg={svg}
      {...rest}
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
