import { useCallback, useEffect, useRef, useState } from "react";
import {
  requestIdleCallback,
  cancelIdleCallback,
} from "./request-idle-callback";

const hasIntersectionObserver = typeof IntersectionObserver === "function";
export function useIntersection({ rootRef, rootMargin, disabled }) {
  const isDisabled = disabled || !hasIntersectionObserver;
  const unobserve = useRef();
  const [visible, setVisible] = useState(false);
  const [element, setElement] = useState(null);
  useEffect(() => {
    if (hasIntersectionObserver) {
      if (unobserve.current) {
        unobserve.current();
        unobserve.current = undefined;
      }
      if (isDisabled || visible) return;
      if (element && element.tagName) {
        unobserve.current = observe(
          element,
          (isVisible) => isVisible && setVisible(isVisible),
          {
            root:
              rootRef === null || rootRef === void 0 ? void 0 : rootRef.current,
            rootMargin,
          }
        );
      }
      return () => {
        var _a;
        (_a = unobserve.current) === null || _a === void 0
          ? void 0
          : _a.call(unobserve);
        unobserve.current = undefined;
      };
    } else {
      if (!visible) {
        const idleCallback = requestIdleCallback(() => setVisible(true));
        return () => cancelIdleCallback(idleCallback);
      }
    }
  }, [element, isDisabled, rootMargin, rootRef, visible]);
  const resetVisible = useCallback(() => {
    setVisible(false);
  }, []);
  return [setElement, visible, resetVisible];
}
function observe(element, callback, options) {
  const { id, observer, elements } = createObserver(options);
  elements.set(element, callback);
  observer.observe(element);
  return function unobserve() {
    elements.delete(element);
    observer.unobserve(element);
    // Destroy observer when there's nothing left to watch:
    if (elements.size === 0) {
      observer.disconnect();
      observers.delete(id);
      const index = idList.findIndex(
        (obj) => obj.root === id.root && obj.margin === id.margin
      );
      if (index > -1) {
        idList.splice(index, 1);
      }
    }
  };
}
const observers = new Map();
const idList = [];
function createObserver(options) {
  const id = {
    root: options.root || null,
    margin: options.rootMargin || "",
  };
  const existing = idList.find(
    (obj) => obj.root === id.root && obj.margin === id.margin
  );
  let instance;
  if (existing) {
    instance = observers.get(existing);
    if (instance) {
      return instance;
    }
  }
  const elements = new Map();
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const callback = elements.get(entry.target);
      const isVisible = entry.isIntersecting || entry.intersectionRatio > 0;
      if (callback && isVisible) {
        callback(isVisible);
      }
    });
  }, options);
  instance = {
    id,
    observer,
    elements,
  };
  idList.push(id);
  observers.set(id, instance);
  return instance;
}
