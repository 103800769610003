import { useEffect, useState } from 'react';

import Img from '@marketplace-co/react-application-element-image';
import get from 'lodash/get';
import { useSwipeable } from 'react-swipeable';

import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

import { generateImageKitUrl } from '@/util/helpers';
import { ChevronLeftIcon, ChevronRightIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import ImageCarouselDots from './ImageCarouselDots';

const Image = ({ data, className = '', transformationType, backgroundSize = 'cover', priority }) => {
  const { metadata } = data || {};
  const { name, blurhash } = metadata || {};
  const imageAltText = '';
  return (
    <Img
      objectPosition={backgroundSize}
      className={`bg-gray-50 ${className}`}
      key={name}
      layout="fill"
      unoptimized
      image={{ metadata: { blurhash } }}
      src={generateImageKitUrl({ transformationType, id: name })}
      alt={imageAltText}
      priority={priority}
    />
  );
};

const Video = ({ video, setAspectRatio, setVideoError, poster, autoPlay }) => {
  return (
    <VideoPlayer
      key={video?.videoSrc?.src || 'Video'}
      noRounded
      className={`video-js-ImageCarousel !absolute mb-4 h-full w-full bg-black object-cover align-bottom`}
      objectFit={'cover'}
      autoplay={autoPlay}
      poster={poster}
      controls={!autoPlay}
      loop={true}
      fluid={false}
      fill={true}
      playsinline={autoPlay}
      video={video}
      muted={autoPlay}
      alertAction={null}
      alertOnAction={null}
      hideVideoPlayerOnError
      onLoadedWidthHeight={data => {
        if (!data?.width) return;
        const { width, height } = data;
        const aspectRatio = (height / width) * 100;
        setAspectRatio(aspectRatio);
      }}
      onError={() => {
        setVideoError(true);
      }}
      alertTitle="There was an issue playing this video"
      alertDescription="Please try again"
      showPauseButton={autoPlay}
    />
  );
};

const ImageDots = ({ images, imageIndex, clickable, setImageIndex, contentExtraClassName }) => {
  if (images.length <= 1) return null;

  const dots = images.map((i, index) => (
    <div
      onClick={() => {
        if (!clickable) return;
        setImageIndex(index);
      }}
      key={i.id}
      className={`dot ${clickable ? 'cursor-pointer' : ''} ${imageIndex === index ? 'active-dot opacity-100' : 'opacity-60'}`}
    ></div>
  ));

  return (
    <div
      className={`z-1 absolute bottom-0 left-0 right-0 mx-auto flex max-w-[150px] flex-row justify-center overflow-hidden pb-3 ${
        !clickable ? 'pointer-events-none' : ''
      } ${contentExtraClassName}`}
    >
      <ImageCarouselDots dots={dots} numDotsToShow={4} dotWidth={10} />
    </div>
  );
};

const ImageButtons = ({ setImageIndex, imageIndex, images, contentExtraClassName }) => {
  const isLastImage = imageIndex === images.length - 1;
  const buttonClassName = `hidden sm:inline-flex opacity-0 group-hover:opacity-100 inline-flex items-center p-1.5 border-transparent
  rounded-full text-gray-900 bg-white bg-opacity-90 z-10 hover:bg-opacity-100 hover:border-gray-100 focus:outline-none transition shadow-lg transform hover:scale-105 active:scale-100`;
  return (
    <>
      {imageIndex > 0 ? (
        <div className={`absolute top-0 bottom-0 left-3 z-10 m-auto h-[36px] w-[36px] ${contentExtraClassName}`}>
          <button onClick={() => setImageIndex(imageIndex - 1)} type="button" className={buttonClassName}>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      ) : null}
      {!isLastImage ? (
        <div className={`absolute top-0 bottom-0 right-3 z-10 m-auto h-[36px] w-[36px] ${contentExtraClassName}`}>
          <button onClick={() => setImageIndex(imageIndex + 1)} type="button" className={buttonClassName}>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      ) : null}
    </>
  );
};

const PreloadNextImage = ({ images, imageIndex }) => {
  const nextImage = images[imageIndex + 1];
  const src = generateImageKitUrl({ id: get(nextImage, 'metadata.name'), transformationType: 'md' });
  const isLastImage = images.length === imageIndex + 1;

  useEffect(() => {
    if (isLastImage) return;
    setTimeout(() => {
      const img = new window.Image();
      img.src = src;
    }, 100);
  }, [src, isLastImage]);

  return null;
};

const ImageHref = ({ component }) => {
  if (!component) return null;
  return component({ className: 'absolute inset-0 h-full w-full z-1' });
};

const ImageCarousel = ({
  images = [],
  className = '',
  roundedClassName = 'rounded-xl',
  aspectRatioClassName = 'aspect-w-3 aspect-h-2',
  transformationType = 'md',
  hrefComponent = () => null,
  noDots = false,
  clickableDots = false,
  imageClassName = '',
  contentExtraClassName = '',
  useImageAspectRatio,
  containPortraitImages,
  video,
  autoPlayVideo,
  priority = false,
}) => {
  const initialImage = images?.length && images[0];
  const [videoAspectRatio, setVideoAspectRatio] = useState(56);
  const [videoError, setVideoError] = useState(false);
  const [image, setImage] = useState(initialImage);
  const [imageIndex, setImageIndex] = useState(0);

  const handlers = useSwipeable({
    onSwiped: eventData => {
      const dir = eventData.dir;
      const isLastImage = images.length === imageIndex + 1;
      if (dir === 'Left' && !isLastImage) {
        setImageIndex(imageIndex + 1);
      } else if (dir === 'Right' && imageIndex !== 0) {
        setImageIndex(imageIndex - 1);
      }
    },
  });

  useEffect(() => {
    if (images && images[imageIndex]) {
      setImage(images[imageIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex]);

  const videoActive = imageIndex === 0 && video && !videoError;

  let style = null;
  const { height, width } = image?.metadata || {};
  let aspectRatio = (height / width) * 100;
  if (videoActive) aspectRatio = videoAspectRatio;
  const isLandscape = width > height;
  if (useImageAspectRatio) {
    style = { height: `${aspectRatio}vw`, maxHeight: '65vh', minHeight: '20vh' };
  }

  let backgroundSize = 'cover';
  if (containPortraitImages && !isLandscape) {
    backgroundSize = 'contain';
    transformationType = 'ar-1:1-lg';
  }

  if (!images || !images.length) return null;
  return (
    <div
      style={style}
      className={` relative overflow-hidden transition-all ${roundedClassName} ${className} ${aspectRatioClassName}`}
    >
      <div className="group inset-0" {...handlers}>
        <ImageHref component={hrefComponent} />

        {videoActive ? (
          <Video
            setVideoError={setVideoError}
            aspectRatio={videoAspectRatio}
            setAspectRatio={setVideoAspectRatio}
            backgroundSize={backgroundSize}
            className={imageClassName}
            video={video}
            poster={generateImageKitUrl({ id: images[0]?.metadata?.name, transformation: 'lg' })}
            autoPlay={autoPlayVideo}
          />
        ) : (
          <Image
            backgroundSize={backgroundSize}
            className={imageClassName}
            transformationType={transformationType}
            data={image}
            priority={priority}
          />
        )}

        {!noDots ? (
          <ImageDots
            contentExtraClassName={contentExtraClassName}
            clickable={clickableDots}
            images={images}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
          />
        ) : null}

        <ImageButtons
          contentExtraClassName={contentExtraClassName}
          images={images}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
        />
      </div>

      <PreloadNextImage key={`PreloadNextImage_${imageIndex}`} images={images} imageIndex={imageIndex} />
    </div>
  );
};

export default ImageCarousel;
