@import '../../styles/customMediaQueries.css';

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    min-height: 400px;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.rootForImage.firstImage.video {
  position: relative;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.viewPhotos {
  position: absolute;
  bottom: 19px;
  right: 24px;
  cursor: pointer;
  transition: var(--transitionStyleButton);
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}
