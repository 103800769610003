import { Link } from 'react-router-dom';

import ButtonLight from '@/components/Button/ButtonLight';
import MenuButton from '@/components/MenuButton/MenuButton';

import { BackButton } from '@/util/helpers';
import { ChevronLeftIcon, EllipsisVerticalIcon, HomeIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import { FunnelIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const breadcrumbSvg = (
  <svg
    className="mx-1 h-5 w-5 flex-shrink-0 text-gray-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
);
export const Breadcrumbs = ({
  className = 'mb-1',
  breadcrumbs,
  breadcrumbsShowHome = true,
  breadcrumbsTextClassName,
  breadcrumbsLinkClassName,
  backOnly,
}) => {
  if (!breadcrumbs || !breadcrumbs.length) return null;

  return (
    <div className={className}>
      <nav className={backOnly ? '' : 'sm:hidden'}>
        <BackButton fallbackPath={breadcrumbs[0].href}>
          <button
            type="button"
            className={`flex items-center text-sm font-medium leading-5 ${breadcrumbsLinkClassName} duration-150 ease-in-out`}
          >
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" />
            Back
          </button>
        </BackButton>
      </nav>

      <nav className={backOnly ? 'hidden' : 'hidden items-center truncate text-sm font-medium leading-5 sm:flex'}>
        {breadcrumbsShowHome ? (
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            </Link>
          </div>
        ) : null}

        {breadcrumbs.map((b, index) => {
          return (
            <div className="flex items-center truncate" key={index}>
              {!index && breadcrumbsShowHome ? breadcrumbSvg : null}
              {b.href ? (
                <Link to={b.href} className={breadcrumbsLinkClassName}>
                  {b.title}
                </Link>
              ) : (
                <div className={`${breadcrumbsTextClassName} truncate `}>{b.title}</div>
              )}
              {index !== breadcrumbs.length - 1 ? breadcrumbSvg : null}
            </div>
          );
        })}
      </nav>
    </div>
  );
};

const PageHeader = props => {
  const {
    // Main Props
    className = '',
    title,
    type = 'default',
    titleClassName = 'text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate',
    description,
    descriptionClassName = 'mt-2 max-w-4xl text-sm text-gray-500',
    // Main Button
    buttonText,
    buttonHref,
    buttonExternalLink = false,
    buttonTheme = 'accent',
    buttonOnClick = () => {},
    buttonIcon,
    buttonIconClassName = 'text-gray-500',
    buttonDisabled = false,
    buttonTextClassName = '',
    buttonProps = {},
    // Secondary Button
    secondaryButtonText,
    secondaryButtonTheme = 'white',
    secondaryButtonOnClick = () => {},
    secondaryButtonExternalLink = false,
    secondaryButtonHref,
    secondaryButtonIcon,
    secondaryButtonIconClassName = 'text-gray-500',
    secondaryButtonTextClassName = '',
    secondaryButtonClassName = '',
    secondaryButtonProps = {},
    // Third Button
    thirdButtonText,
    thirdButtonTheme = 'white',
    thirdButtonOnClick = () => {},
    thirdButtonExternalLink = false,
    thirdButtonHref,
    thirdButtonIcon,
    thirdButtonIconClassName = 'text-gray-500',
    thirdButtonTextClassName = '',
    thirdButtonProps = {},
    wrapperClassName = '',
    buttonWrapperClassName = '',
    // Extra Props
    noLineBreak = false,
    dropdownOptions,
    history,
    breadcrumbs,
    children,
    lateChildren = false,
    meta = null,
    breadcrumbsShowHome = false,
    breadcrumbClassName = 'mb-1',
    breadcrumbsTextClassName = 'text-gray-500',
    breadcrumbsLinkClassName = 'text-gray-500 hover:text-gray-700 transition',
    showFunnelIcon = false,
    filterIconOnClick = () => {},
    filtersActive = false,
    minHeightClassName = 'min-h-[38px]',
  } = props;
  const wrapperClasses = wrapperClassName
    ? wrapperClassName
    : !noLineBreak
    ? 'sm:flex sm:items-center sm:justify-between'
    : 'flex items-center justify-between';
  const buttonWrapperClasses = buttonWrapperClassName
    ? buttonWrapperClassName
    : !noLineBreak
    ? 'mt-4 flex sm:mt-0 sm:ml-4'
    : 'flex ml-4';
  const renderDropdown = dropdownOptions && dropdownOptions.length;

  let titleClasses, descriptionClasses;
  switch (type) {
    case 'slideOver':
    case 'sm':
      titleClasses = 'text-lg font-medium text-gray-900';
      descriptionClasses = 'mt-1 text-sm text-gray-500';
      break;
    case 'default':
    default:
      titleClasses = titleClassName;
      descriptionClasses = descriptionClassName;
      break;
  }

  return (
    <div className={`${className} ${wrapperClasses} ${minHeightClassName}`}>
      <div className="min-w-0 flex-1">
        <Breadcrumbs
          className={breadcrumbClassName}
          breadcrumbs={breadcrumbs}
          breadcrumbsShowHome={breadcrumbsShowHome}
          breadcrumbsTextClassName={breadcrumbsTextClassName}
          breadcrumbsLinkClassName={breadcrumbsLinkClassName}
        />

        <h2 className={titleClasses}>{title}</h2>

        {meta}

        {description ? <p className={descriptionClasses}>{description}</p> : null}
      </div>

      {buttonText || renderDropdown || secondaryButtonText || secondaryButtonIcon ? (
        <div className={`${buttonWrapperClasses} space-x-3`}>
          {!lateChildren ? children : null}

          {thirdButtonText || thirdButtonIcon ? (
            <ButtonLight
              theme={thirdButtonTheme}
              onClick={thirdButtonOnClick}
              externalLink={thirdButtonExternalLink}
              href={thirdButtonHref}
              {...thirdButtonProps}
            >
              {thirdButtonIcon
                ? thirdButtonIcon({
                    className: `-ml-1 ${thirdButtonText ? 'mr-2' : '-mr-1'} h-5 w-5 ${thirdButtonIconClassName}`,
                  })
                : null}
              <span className={thirdButtonTextClassName}>{thirdButtonText}</span>
            </ButtonLight>
          ) : null}

          {showFunnelIcon ? (
            <ButtonLight
              theme={secondaryButtonTheme}
              onClick={filterIconOnClick}
              externalLink={secondaryButtonExternalLink}
              href={secondaryButtonHref}
              className="rounded-full sm:hidden"
              filter={true}
              filterActive={filtersActive}
              filterDropdownIconHidden={true}
              filterIconOnly={true}
            >
              <FunnelIcon className="!ml-0 !mr-0 h-5 w-5" />
            </ButtonLight>
          ) : null}

          {secondaryButtonText || secondaryButtonIcon ? (
            <ButtonLight
              theme={secondaryButtonTheme}
              onClick={secondaryButtonOnClick}
              externalLink={secondaryButtonExternalLink}
              href={secondaryButtonHref}
              className={secondaryButtonClassName}
              {...secondaryButtonProps}
            >
              {secondaryButtonIcon
                ? secondaryButtonIcon({
                    className: `-ml-1 ${secondaryButtonText ? 'mr-2' : '-mr-1'} h-5 w-5 ${secondaryButtonIconClassName}`,
                  })
                : null}
              <span className={secondaryButtonTextClassName}>{secondaryButtonText}</span>
            </ButtonLight>
          ) : null}

          {buttonText ? (
            <ButtonLight
              disabled={buttonDisabled}
              theme={buttonTheme}
              onClick={buttonOnClick}
              externalLink={buttonExternalLink}
              href={buttonHref}
              {...buttonProps}
            >
              {buttonIcon ? buttonIcon({ className: `-ml-1 mr-2 h-5 w-5 ${buttonIconClassName}` }) : null}
              <span className={buttonTextClassName}>{buttonText}</span>
            </ButtonLight>
          ) : null}

          {renderDropdown ? (
            <MenuButton
              onSelect={data => {
                const { href, onClick } = data || {};
                if (onClick) return onClick();
                if (!href || !history) return;
                history.push(href);
              }}
              type="dropdown"
              noCheckmark
              options={dropdownOptions}
              label={<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />}
              className="flex flex-col items-center justify-center"
              buttonClassName="shadow-sm border-gray-300 border bg-white -my-2 p-2 rounded-full flex items-center text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500 transition"
            />
          ) : null}

          {lateChildren ? children : null}
        </div>
      ) : null}
    </div>
  );
};

export default PageHeader;
