import ButtonLight from '@/components/Button/ButtonLight';

const ListingSectionTitle = props => {
  const { className, icon, title, description, buttonText, buttonAction, noDefaultMargin = false } = props;

  return (
    <div className={`${className} ${!noDefaultMargin ? 'mb-4' : ''}`}>
      <div className="block flex-row sm:flex">
        <div className="flex-1">
          <div className="flex flex-row">
            {icon ? <div className="mr-4">{icon}</div> : null}
            <div className="text-xl font-semibold leading-6 text-gray-900">{title}</div>
          </div>

          {description ? <div className="mt-1 text-sm leading-5 text-gray-500">{description}</div> : null}
        </div>

        {buttonText && buttonAction ? (
          <div className="mt-2 sm:mt-0 sm:ml-2 sm:flex sm:flex-col sm:items-center sm:justify-center">
            <ButtonLight onClick={buttonAction} theme="white">
              {buttonText}
            </ButtonLight>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingSectionTitle;
