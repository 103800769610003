import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import moment from 'moment';
import WebFont from 'webfontloader';

import {
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  FolderIcon,
  GlobeAltIcon,
  HomeIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  ScaleIcon,
  ShoppingBagIcon,
  UsersIcon,
} from '@tmpc/ui/dist/utils/icons/24/outline';

import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import { setColorMode } from '@/ducks/UI.duck';

import { types as sdkTypes } from '@/util/sdkLoader';

import config from '@/config';

import { slugify } from './urlHelpers';

const { LatLng } = sdkTypes;

export const newListingPath = '/l/draft/00000000-0000-0000-0000-000000000000/new/description';

export const getAccountName = (a, options = {}) => {
  if (!a) return '';
  // When customer is true, never return the displayName
  const { customer = false } = options;

  const { type, name, metadata, firstName, lastName } = a;
  const { displayName } = metadata || {};
  if (type === 'provider' && displayName && !customer) return displayName;

  if (!name && firstName && lastName) return `${firstName} ${lastName}`;

  return name;
};

export const getMarketplace = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  return Marketplace;
};
export const capitalize = str => {
  if (!str) return '';
  return str.toLowerCase().replace(/\b\w/g, function (m) {
    return m.toUpperCase();
  });
};

export const getCurrentUser = () => {
  return window.app.store.getState()?.user?.currentUser || {};
};

export const label = str => {
  const marketplace = getMarketplace();
  const isCapitalized = str[0] !== str[0].toLowerCase();
  const isPlural = str[str.length - 1] === 's';
  const key = isPlural ? str.slice(0, -1) : str;
  const lookupKey = key.toLowerCase();

  const defaultLabels = {
    listing: 'Listing',
    website: 'Website',
  };

  const marketplaceLabel = get(marketplace, `pageConfig.general.labels.${lookupKey}`);
  const defaultLabel = defaultLabels[lookupKey];
  let label = marketplaceLabel || defaultLabel || str;
  const usedInitialString = !marketplaceLabel && !defaultLabel;
  if (isPlural && !usedInitialString) label = label + 's';
  if (isCapitalized) {
    label = capitalize(label);
  } else {
    label = label.toLowerCase();
  }

  return label;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const camelize = str => {
  if (!str) return '';

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '');
};

export const unCamelize = __str => {
  if (!__str) return;
  const str = JSON.parse(JSON.stringify(__str));
  const newString = str
    .match(/([A-Z]?[^A-Z]*)/g)
    .slice(0, -1)
    .join(' ');
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const statusColors = {
  default: 'gray',
  approved: 'success',
  success: 'success',
  draft: 'warning',
  trial: 'warning',
  failure: 'error',
  pending: 'warning',
  pendingApproval: 'warning',
  rejected: 'error',
  completed: 'success',
  active: 'success',
  private: 'warning',
  public: 'success',
  free: 'gray',
  pro: 'primary',
  suspended: 'error',
  archived: 'purple',
  doesNotExist: 'warning',
};

export const getVideoDuration = secs => {
  if (!secs) return;
  const duration = moment.duration(secs, 'seconds');
  if (secs < 60) return secs.toFixed(0) + ' Secs';
  return `${duration.minutes()} Mins`;
};

export const getTransactionTitle = ({ data, tab, type, withLinks = false, entityClassName = '' }) => {
  if (!data || !data.id) return;
  const { provider, listing, customer, type: transactionType } = data;
  const requestToBookActive = transactionType === 'serviceBookingWithRequest';
  const isSubscription = transactionType === 'subscriptionPurchase' || transactionType === 'subscriptionPayment';
  const isTrial = get(data, 'billingSystemInfo.isTrial') || get(data, 'billingSystemInfo.trial');
  const { bookingType } = listing || {};
  const isDonation = bookingType === 'donation';

  const customerIsExpanded = customer && typeof customer === 'object';
  const customerName = customerIsExpanded ? customer.name : 'Unknown';
  const customerId = customerIsExpanded ? customer.id : customer;

  const listingIsExpanded = typeof listing === 'object';
  const listingName = listing && listingIsExpanded ? listing.title : 'Unknown Listing';
  const listingId = listingIsExpanded ? listing.id : data.listing || (listing && listing.id);

  const providerIsExpanded = typeof data.provider === 'object';
  const providerName = data.provider && providerIsExpanded ? getAccountName(data.provider) : 'Unknown';
  const providerId = providerIsExpanded ? data.provider.id : data.provider || (provider && provider.id);

  const generateHref = (title, href) => (
    <a target="_blank" rel="noopener noreferrer" className="hover:text-primary text-gray-500" href={href}>
      {title}
    </a>
  );
  const customerNameLinkOrString = withLinks ? (
    generateHref(customerName, `/u/${customerId}`)
  ) : (
    <span className={entityClassName}>{customerName}</span>
  );
  const listingNameLinkOrString = withLinks ? (
    generateHref(listingName, `/l/${listingIsExpanded ? listing.slug : camelize(listingName)}/${listingId}`)
  ) : (
    <span className={entityClassName}>{listingName}</span>
  );
  const providerNameLinkOrString = withLinks ? (
    generateHref(providerName, `/u/${providerId}`)
  ) : (
    <span className={entityClassName}>{providerName}</span>
  );
  const isAdminTab = tab === 'admin';

  const paymentOrRequestLabel = requestToBookActive ? 'Request' : isTrial ? 'Trial' : isDonation ? 'Donation' : 'Payment';
  const bookingOrRequestLabel = requestToBookActive ? 'Request' : 'Booking';

  if (tab === 'sales' || isAdminTab) {
    if (isSubscription) {
      return (
        <>
          {paymentOrRequestLabel} from {customerNameLinkOrString} for <span className={entityClassName}>Pro Subscrption</span>
        </>
      );
    }
    if (type === 'bookings')
      return (
        <>
          {bookingOrRequestLabel} from {customerNameLinkOrString} for {listingNameLinkOrString}
          {isAdminTab ? <> by {providerNameLinkOrString}</> : ''}
        </>
      );
    return (
      <>
        {paymentOrRequestLabel} from {customerNameLinkOrString} for {listingNameLinkOrString}
        {isAdminTab ? <> by {providerNameLinkOrString}</> : ''}
      </>
    );
  }

  if (isSubscription) {
    return (
      <>
        {paymentOrRequestLabel} for <span className={entityClassName}>Pro Subscrption</span>
      </>
    );
  }

  if (type === 'bookings')
    return (
      <>
        {bookingOrRequestLabel} for {listingNameLinkOrString} with {providerNameLinkOrString}
      </>
    );
  return (
    <>
      {paymentOrRequestLabel} to {providerNameLinkOrString} for {listingNameLinkOrString}
    </>
  );
};

export const getFetchPaginaionParams = ({ pagination = {}, perPage = 10, page = 1 }) => {
  const { perPage: limit = perPage } = pagination;
  return {
    limit,
    offset: (Number(page) - 1) * limit,
  };
};

export const getSetPaginationParams = ({ perPage, totalItems, page }) => {
  const totalPages = Math.ceil(totalItems / perPage);
  return {
    perPage,
    page,
    totalItems,
    totalPages,
  };
};

export const computeDynamicHref = ({
  hyperLink,
  hyperlink,
  type,
  listingId,
  queryParams,
  hyperlinkQueryParams,
  metadata,
  linkType,
  hyperLinkPlayBlockId,
  hyperLinkArticleId,
}) => {
  queryParams = queryParams || hyperlinkQueryParams;
  hyperLink = hyperLink || hyperlink;
  if (!hyperLink) return '';
  type = type || linkType;

  if (type === 'none') return '';

  if (type === 'external') return hyperLink;
  const { hyperLinkAccountId } = metadata || {};
  let link = hyperLink;

  if (type === 'phone') {
    link = `tel:${hyperLink}`;
  }

  // Listing Maybe
  if (hyperLink === '/l' && listingId) {
    link = `${hyperLink}/${listingId}`;
  }

  // Account Profile Maybe
  if (hyperLink === '/u' && hyperLinkAccountId) {
    link = `${hyperLink}/${hyperLinkAccountId}`;
  }

  // PlayBlock Page Maybe
  if (!hyperLinkPlayBlockId && metadata && metadata.hyperLinkPlayBlockId) {
    hyperLinkPlayBlockId = metadata.hyperLinkPlayBlockId;
  }
  if (hyperLink === '/pb' && hyperLinkPlayBlockId) {
    // Check if it is a slug
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const isId = re.test(hyperLinkPlayBlockId);
    if (!isId) {
      link = `/${hyperLinkPlayBlockId}`;
    } else {
      link = `${hyperLink}/${hyperLinkPlayBlockId}`;
    }
  }

  if (hyperLink === '/crm/announcements/' && hyperLinkArticleId) {
    link = `${hyperLink}${hyperLinkArticleId}`;
  }

  if (queryParams) {
    link = `${link}?${queryParams}`;
  }

  return link;
};

export const DynamicLink = ({ action, children, className = '' }) => {
  let { type, linkType } = action || {};
  type = type || linkType;
  const href = computeDynamicHref(action);

  if (type === 'external' || type === 'email' || type === 'phone') {
    const target = type === 'external' ? '_blank' : '_self';
    return (
      <a target={target} rel="noreferrer" href={href} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link to={href || '/'} className={className}>
      {children}
    </Link>
  );
};

export const computeCategories = ({ categories, categoryType, marketplace }) => {
  let cats = categories.filter(i => i.type === categoryType).sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
  const order = marketplace?.applicationConfig?.settings?.categoryTypesOrder?.[categoryType] || [];
  if (cats?.length && order?.length) {
    cats = cats
      .map(i => ({
        ...i,
        index: order.findIndex(id => i.id === id),
      }))
      .sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
  }
  return cats;
};

export const computeCategoryTypes = categories => {
  const state = window.app && window.app.store.getState();
  const marketplace = state.Marketplace || {};
  const { applicationConfig } = marketplace || {};
  const { settings } = applicationConfig || {};
  const { categoryTypes, categoryTypesOrder = {} } = settings || {};

  if (!categories) return [];
  const catTypes = categories.map(item => item.type);
  let filteredCatTypes = [...new Set(catTypes)];
  filteredCatTypes = filteredCatTypes.map(item => {
    const order = categoryTypesOrder[item] || [];
    let items = categories.filter(cat => cat.type === item).sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

    // sort by order if defined
    if (order?.length && items?.length) {
      items = items.map(i => {
        return {
          ...i,
          index: order.findIndex(id => i.id === id),
        };
      });
      items = items.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
    }

    return {
      id: item,
      order: categoryTypesOrder[item] || [],
      title: categoryTypes[item] || unCamelize(item),
      items,
    };
  });
  return filteredCatTypes.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
};

export const isAvailabilityNotAllowed = (type, subType) => type === 'fundraiser' || (type === 'video' && subType === 'recorded');

export const getImageKitTranformationsForType = (type, options) => {
  const { disableSmartCrop } = options || {};
  const focusParam = disableSmartCrop ? 'fo-center' : 'fo-auto';
  switch (type) {
    case 'facebook-seo':
      return `tr:w-1200,h-630,${focusParam}`;
    case 'webclip':
      return `tr:w-180,h-180,dpr-2`;
    case '192x192':
      return `tr:w-192,h-192`;
    case '512x512':
      return `tr:w-512,h-512`;
    case 'twitter-seo':
      return `tr:w-600,h-314,${focusParam}`;
    case 'invoice-logo':
      return 'tr:h-40,f:jpg,dpr-2';
    case '1184-1376':
      return `tr:w-1184,h-1376,dpr-2,${focusParam}`;
    case 'lg':
      return `tr:dpr-2,w-1000,${focusParam}`;
    case 'avatar':
      return `tr:dpr-2,w-100,h-100,${focusParam}`;
    case 'md':
      return `tr:dpr-2,w-500,${focusParam}`;
    case 'sm':
      return `tr:dpr-2,w-250,${focusParam}`;
    case 'ar-1:1-sm':
      return `tr:dpr-2,ar-1-1,w-250,${focusParam}`;
    case 'ar-1:1-md':
      return `tr:dpr-2,ar-1-1,w-500,${focusParam}`;
    case 'ar-1:1-lg':
      return `tr:dpr-2,ar-1-1,w-1000,${focusParam}`;
    case 'ar-4:1-lg':
      return `tr:dpr-2,ar-4-1,w-1000,${focusParam}`;
    case 'ar-3:2-lg':
      return `tr:dpr-2,ar-3-2,w-1000,${focusParam}`;
    case 'ar-2:1-lg':
      return `tr:dpr-2,ar-2-1,w-1000,${focusParam}`;
    case 'ar-2:3-sm':
      return `tr:dpr-2,ar-2-3,w-250,${focusParam}`;
    case 'ar-2:3-md':
      return `tr:dpr-2,ar-2-3,w-500,${focusParam}`;
    case 'ar-3:4-sm':
      return `tr:dpr-2,ar-3-4,w-250,${focusParam}`;
    case 'ar-3:4-md':
      return `tr:dpr-2,ar-3-4,w-500,${focusParam}`;
    case 'ar-4:3-md':
      return `tr:dpr-2,ar-4-3,w-500,${focusParam}`;
    case 'ar-4:3-sm':
      return `tr:dpr-2,ar-4-3,w-250,${focusParam}`;
    case 'ar-16:9-sm':
      return `tr:dpr-2,ar-16-9,w-250,${focusParam}`;
    case 'ar-16:9-md':
      return `tr:dpr-2,ar-16-9,w-500,${focusParam}`;
    case 'ar-16:9-lg':
      return `tr:dpr-2,ar-16-9,w-1000,${focusParam}`;
    case 'hero-1/3-col-1-row':
      return `tr:dpr-2,w-373,h-549,${focusParam}`;
    case 'hero-2/3-col-1-row':
      return `tr:dpr-2,w-754,h-549,${focusParam}`;
    case 'hero-1/3-col-1/2-row':
      return `tr:dpr-2,w-373,h-270,${focusParam}`;
    case 'hero-2/4-col-1-row':
      return `tr:dpr-2,w-564,h-549,${focusParam}`;
    case 'hero-1/4-col-1/2-row':
      return `tr:dpr-2,w-278,h-270,${focusParam}`;
    case 'hero-1/4-col-1-row':
    default:
      return `tr:dpr-2,w-278,h-489,${focusParam}`;
  }
};

export const generateImageKitUrl = ({
  id,
  image,
  transformationType,
  transformations = 'tr:fo-auto',
  disableSmartCrop = false,
}) => {
  id = id || image?.metadata?.name;
  if (!id) {
    return image?.metadata?.src;
  }

  let transformation = transformations;
  if (transformationType && transformations === 'tr:fo-auto') {
    transformation = getImageKitTranformationsForType(transformationType, { disableSmartCrop });
  }
  return `https://ik.imagekit.io/346d752Y${transformation ? `/${transformation}` : ''}/${id}`;
};

export const historyGoBack = ({ fallback = '/', history: hookHistory } = {}) => {
  const browserHistory = createBrowserHistory();
  // Get app history, browser history is not reliable
  const appHistory = window.app.store.getState()?.Routing?.history || {};

  // If app history is available, use it. Else use the loaded brower history
  const history = hookHistory || browserHistory;

  // Check if there is a previous location in our app to go back to
  if (appHistory?.length <= 1) return history.push(fallback);

  if (browserHistory.length > 2) {
    // Go back to previous location by calling browser back
    return history.goBack();
  }

  history.push(appHistory[1]?.pathname);
};

// Back button component
export const BackButton = props => {
  const handleClick = () => historyGoBack({ fallback: props.fallbackPath || '/' });
  return cloneElement(props.children, { onClick: handleClick });
};

export const getAccountLocationDetails = (data, geoLocation) => {
  if (!data) return null;

  const defaultAddress = data.address;
  // attempt to look for a specific address at supplied geoLocation
  if (geoLocation) {
    const lookupLatLng = `${geoLocation.lat},${geoLocation.lon || geoLocation.lng}`;
    const isTargetLocation = location => {
      if (!location) return false;
      const otherLatLng = `${location.lat},${location.lon || location.lng}`;
      return lookupLatLng === otherLatLng;
    };

    if (isTargetLocation(defaultAddress && defaultAddress.location)) {
      return { address: defaultAddress };
    }

    const locations = get(data, 'metadata.locations');
    if (locations && locations.length) {
      return locations.find(i => isTargetLocation(get(i, 'address.selectedPlace.origin'))) || null;
    }
    return null;
  }

  return null;
};

// Look for any address attached to an account, checks account.address and account.metadata.locations.[0].address
export const getAccountAddress = (
  data,
  { geoLocation, all = false, allPattern = ['city', 'stateCode'], allPatternSeparator = ', ', allPatternLocationSeparator = '\n' }
) => {
  if (!data) return null;
  const defaultAddress = data.address;

  // attempt to look for a specific address at supplied geoLocation
  if (geoLocation) {
    const accountLocationDetails = getAccountLocationDetails(data, geoLocation)?.address;
    if (accountLocationDetails && accountLocationDetails.selectedPlace) {
      return getLocationObject(accountLocationDetails.selectedPlace);
    } else if (accountLocationDetails) {
      return accountLocationDetails;
    }
    return null;
  }

  // Gather all addresses
  // optionally return a string if allPattern is passed
  if (all) {
    let addresses = [];
    // if (defaultAddress) addresses.push(defaultAddress);

    const locations = get(data, 'metadata.locations') || [];
    let extraLocationsWithAdresses;
    try {
      extraLocationsWithAdresses =
        locations
          .filter(i => !!get(i, 'address.selectedPlace'))
          .map(location => getLocationObject(location.address.selectedPlace)) || null;
    } catch (error) {
      console.error('ERROR', error);
    }
    if (extraLocationsWithAdresses && extraLocationsWithAdresses.length) {
      addresses = [...addresses, ...extraLocationsWithAdresses];
    }

    if (!addresses.length) return null;

    if (allPattern) {
      const addressStrings = addresses.map(address => allPattern.map(key => address[key]).join(allPatternSeparator));
      const uniqAddressStrings = [...new Set(addressStrings)];
      return uniqAddressStrings.join(allPatternLocationSeparator);
    }

    return addresses;
  }

  if (defaultAddress) return defaultAddress;

  const address = get(data.metadata, 'locations.[0].address.selectedPlace');
  if (address) {
    return getLocationObject(address);
  }

  return null;
};

export const getLocationObject = place => {
  const keysToInclude = [
    'formattedAddress',
    'city',
    'state',
    'street',
    'country',
    'postCode',
    'stateCode',
    'countryCode',
    'county',
    'establishment',
    'natural_feature',
    'naturalFeature',
    'bounds',
  ];
  let obj = {};
  for (const key in place) {
    if (place.hasOwnProperty(key)) {
      const element = place[key];
      if (keysToInclude.includes(key)) obj[key] = element;
    }
  }
  return {
    ...obj,
    captureMode: 'userProvided',
    location: {
      lat: place.origin.lat,
      lng: place.origin.lng,
    },
  };
};

export const getLocalLocationObject = address => {
  if (!address) return null;
  const locationFieldsPresent = address && address.location && address.location.lat;
  const { location = {}, city, street, stateCode, countryCode, formattedAddress } = address || {};
  const addressParts = [street, city, stateCode, countryCode];
  const addressString = formattedAddress || addressParts.join(', ');
  const { lat, lng } = location || {};
  const origin = new LatLng(lat, lng);
  return locationFieldsPresent
    ? {
        search: addressString,
        selectedPlace: { ...address, address: addressString, origin },
      }
    : null;
};

export const getDateOfBirthString = dateOfBirth => {
  if (typeof dateOfBirth.getMonth === 'function') {
    return `${dateOfBirth.getFullYear()}-${dateOfBirth.getMonth() + 1}-${dateOfBirth.getDate()}`;
  } else if (dateOfBirth.year) {
    return `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`;
  }
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

export const isProAccount = currentUser => {
  if (!currentUser) {
    try {
      currentUser = window.app.store.getState().user.currentUser;
    } catch {}
  }
  if (!currentUser) return false;
  const { _subscriptionPlan, _marketplaceSubscription, roles } = currentUser || {};
  const { status } = _marketplaceSubscription || {};

  if (roles && roles.includes('marketplaceAdmin')) {
    return true;
  }

  //return true;
  return _subscriptionPlan === 'pro' && status !== 'cancelled';
};

export const hasRoleAccess = (authRoles = []) => {
  if (!authRoles || !authRoles.length) return true;

  let currentUser;
  try {
    currentUser = window.app.store.getState().user.currentUser;
  } catch {}
  if (!currentUser) return false;

  const { roles = [] } = currentUser || {};
  return roles.findIndex(r => authRoles.includes(r)) > -1;
};

export const isMarketplaceOrPlatformAdmin = () => {
  return hasRoleAccess(['marketplaceAdmin', 'platformAdmin']);
};

export const isProAccountTrialing = currentUser => {
  if (!currentUser) {
    try {
      currentUser = window.app.store.getState().user.currentUser;
    } catch {}
  }
  if (!currentUser) return false;
  const { _subscriptionPlan, _marketplaceSubscription } = currentUser || {};
  const { status } = _marketplaceSubscription || {};
  return _subscriptionPlan === 'pro' && status === 'trialing';
};

export const proAccountTrialDaysLeft = currentUser => {
  if (!currentUser || !isProAccountTrialing(currentUser)) return null;
  const { _marketplaceSubscription } = currentUser || {};
  const { validTill } = _marketplaceSubscription || {};
  if (!validTill) return null;
  const validTillMoment = moment(validTill);
  const todayMoment = moment();
  const days = validTillMoment.diff(todayMoment, 'days');
  let daysText = 'days';
  if (days === 1) daysText = 'day';
  return `${days} ${daysText}`;
};

export const isProEnabledMarketplace = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  const { pageConfig } = Marketplace || {};
  const { general } = pageConfig || {};
  const { platformPricingType } = general || {};
  const isProEnabledMarketplace = platformPricingType === 'paid';
  return isProEnabledMarketplace;
};

export const isSuperhostEnabledMarketplace = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  return get(Marketplace, 'pageConfig.accounts.verification.superhost') === true;
};

export const getProSubscriptionPlanId = currentUser => {
  const { _marketplaceSubscription } = currentUser || {};
  const { planId } = _marketplaceSubscription || {};

  if (!isProAccount(currentUser)) return null;

  return planId;
};

export const getProSubscriptionId = currentUser => {
  const { _marketplaceSubscription } = currentUser || {};
  const { subscriptionId } = _marketplaceSubscription || {};
  return subscriptionId;
};

export const getProSubscriptionTrialDays = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  const trialDays = get(Marketplace, 'applicationConfig.marketplaceSubscription.planSettings.pro.trialDays');
  return trialDays;
};

export const isTrialEnabledMarketplace = () => {
  return getProSubscriptionTrialDays() > 0;
};

export const routesToProTypes = {
  '/contacts': 'contacts',
  '/forms': 'forms',
  '/calendar/sales': 'calendar',
};

export const isProOnlyRoute = (type, marketplace, href) => {
  let data = marketplace;
  if (!marketplace) {
    const state = window.app && window.app.store.getState();
    data = state && state.Marketplace;
  }
  if (!data) return false;
  if (href && !type) {
    type = routesToProTypes[href];
  }
  if (!type) return false;

  const featureAccess = get(data, 'applicationConfig.marketplaceSubscription.planSettings.pro.featureAccess');
  return featureAccess && featureAccess[type] && featureAccess[type].hasAccess === true;
};

export const checkProAccess = ({ type, currentUser, marketplace }) => {
  if (!currentUser) return false;
  // For now all non pro marketplaces get pro features for free
  if (!isProEnabledMarketplace()) return true;

  const isPro = isProAccount(currentUser);
  const isProRoute = isProOnlyRoute(type, marketplace);

  switch (type) {
    case 'forms':
    case 'form':
      return isPro;
    default:
      return isProRoute ? isPro : true;
  }
};

export const getMarketplaceCurrency = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  const { pageConfig } = Marketplace || {};
  const { general } = pageConfig || {};
  const { currency } = general || {};
  return currency || 'AUD';
};

export const fetchIpGeolocation = () => {
  const createdAt = +new Date();
  return fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=${config.abstractapi.apiKey}`)
    .then(response => response.json())
    .then(data => {
      const ipData = { ...data, createdAt };
      localStorage.setItem('ipGeolocation', JSON.stringify(ipData));
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

export const getIpGeolocation = () => {
  let ipGeolocation = localStorage.getItem('ipGeolocation');
  if (ipGeolocation) {
    return JSON.parse(ipGeolocation);
  } else {
    return {};
  }
};

export const getIpGeolocationAdressMaybe = () => {
  const data = getIpGeolocation();
  if (!data) return undefined;
  const {
    city,
    country,
    country_code: countryCode,
    postal_code: postCode,
    region: state,
    region_iso_code: stateCode,
    latitude,
    longitude,
  } = data || {};

  return {
    formattedAddress: `${city}, ${state}, ${country}`,
    city,
    state,
    country,
    postCode,
    stateCode,
    countryCode,
    captureMode: 'ipLocation',
    location: {
      lat: latitude,
      lng: longitude,
    },
  };
};

export const getDateQueryParam = dates => {
  if (!dates) return;
  const splitDates = dates.split(',');
  const date1 = splitDates[0];
  const date2 = splitDates[1];
  if (date1 === date2) {
    return `between:${date1}T00:00:00,${date2}T23:59:59`;
  }

  return `between:${dates}`;
};

export const getLocalCache = key => {
  if (!key) return null;

  // Check session cache
  if (window[key]) return window[key];

  // Check local storage cache
  const localStorageData = window.localStorage.getItem(key);
  if (localStorageData) return JSON.parse(localStorageData);

  return null;
};

export const cacheLocal = (data, key) => {
  if (!data) return;
  window[key] = data;
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const getIsListingBookmarked = (data, id) => {
  const isUserObj = data && data.id;
  const bookmarkedListings = isUserObj ? data && data.bookmarkedListings : data;
  if (!bookmarkedListings || !bookmarkedListings.length || !id) return false;
  return bookmarkedListings.includes(id);
};

export const parseRRule = ({ recurrenceRule, startsAtMoment, RRule }) => {
  const initialRule = RRule.fromString(recurrenceRule);
  const rruleOptions = initialRule.origOptions;
  if (rruleOptions.dtstart) delete rruleOptions.dtstart;

  const year = startsAtMoment.format('YYYY');
  const month = startsAtMoment.format('M') - 1;
  const day = startsAtMoment.format('D');
  const hour = startsAtMoment.format('HH');
  const min = startsAtMoment.format('m');
  return new RRule({
    dtstart: new Date(Date.UTC(year, month, day, hour, min)),
    ...rruleOptions,
  });
};

export const getCustomListingTypes = (marketplace, all = true) => {
  let customListingTypes = get(marketplace, 'pageConfig.listingPage.customTypes') || [];

  if (customListingTypes && customListingTypes && !all) {
    customListingTypes = customListingTypes.filter(t => t.status === 'active');
  }

  if (customListingTypes && customListingTypes.length) {
    customListingTypes = customListingTypes.map(t => ({
      ...t,
      key: t.id,
      label: t.label || t.title,
      icon: t.icon ? props => <SvgRenderer id={t.icon} {...props} /> : null,
    }));
  }
  return customListingTypes || [];
};

export const getActiveDefaultListingTypes = marketplace => {
  let listingTypes = config.custom.listingTypes;

  const supportedListingTypes = get(marketplace, 'pageConfig.listingPage.supportedListingTypes') || [];
  const customListingTypes = getCustomListingTypes(marketplace);
  // Only show supported listing types
  if (supportedListingTypes && supportedListingTypes.length) {
    listingTypes = listingTypes.filter(item => supportedListingTypes.indexOf(item.key) > -1);
  } else if (customListingTypes && customListingTypes.length) {
    // only return no listing types if a custom listing type is present
    return [];
  }

  return listingTypes || [];
};

const parseListingConfigForProviderUserPersona = item => {
  if (!item) return null;

  if (!item?.useSeperateAdminTitle) {
    return item;
  }

  return {
    ...item,
    label: item.adminLabel || item.label,
    title: item.adminTitle || item.title,
    titlePlural: item.adminTitlePlural || item.titlePlural,
  };
};

const getAccountRole = roles => {
  const ROLES = {
    MARKETPLACE_CMS_ADMIN: 'marketplaceCmsAdmin',
    MARKETPLACE_ADMIN: 'marketplaceAdmin',
    PLATFORM_ADMIN: 'platformAdmin',
    USER: 'user',
    GUEST: 'guest',
  };
  if (!roles) {
    return ROLES.GUEST;
  }
  if (roles.includes(ROLES.PLATFORM_ADMIN)) {
    return ROLES.PLATFORM_ADMIN;
  }
  if (roles.includes(ROLES.MARKETPLACE_ADMIN)) {
    return ROLES.MARKETPLACE_ADMIN;
  }
  if (roles.includes(ROLES.MARKETPLACE_CMS_ADMIN)) {
    return ROLES.MARKETPLACE_CMS_ADMIN;
  }
  return ROLES.USER;
};

export const getActiveListingTypes = ({ userPersona = 'customer', userRoles = [], action = 'create' } = {}) => {
  const marketplace = getMarketplace();
  const activeListingTypes = getActiveDefaultListingTypes(marketplace);
  let customListingTypes = getCustomListingTypes(marketplace);

  if (userRoles?.length && customListingTypes?.length) {
    const role = getAccountRole(userRoles);
    customListingTypes =
      customListingTypes.filter(t => {
        const disabledAccessForAction = t?.accessRoles?.[action]?.disabled;
        if (!disabledAccessForAction) return true;
        return !disabledAccessForAction[role];
      }) || [];
  }
  const listingTypes = [...activeListingTypes, ...customListingTypes];

  if (userPersona === 'provider') {
    return listingTypes.map(parseListingConfigForProviderUserPersona);
  }

  return listingTypes;
};

export const getAllListingTypes = ({ userPersona = 'customer' } = {}) => {
  const marketplace = getMarketplace();
  const defaultListingTypes = config.custom.listingTypes;
  const customListingTypes = getCustomListingTypes(marketplace, true);
  const listingTypes = [...defaultListingTypes, ...customListingTypes];

  if (userPersona === 'provider') {
    return listingTypes.map(parseListingConfigForProviderUserPersona);
  }

  return listingTypes;
};

export const getListingTitleFromType = (type, { userPersona = 'customer' } = {}) => {
  const types = getAllListingTypes({ userPersona });
  const typeObj = types.find(t => t.id === type) || {};
  return typeObj.title || type;
};

export const getListingTypeLabel = (l, { userPersona = 'customer' } = {}) => {
  const { type, subType } = l || {};
  if (!type) return null;

  let typeLabel = type;
  const isVideoType = type === 'video' && subType;

  const isConferenceType = isVideoType && subType === 'conference';
  if (isConferenceType) typeLabel = 'Conference';

  const isLiveStream = isVideoType && subType === 'live';
  if (isLiveStream) typeLabel = 'Live stream';

  const isVideoRecording = isVideoType && subType === 'recorded';
  if (isVideoRecording) typeLabel = 'Prerecorded videos';

  if (typeLabel === type) {
    return getListingTitleFromType(type, { userPersona });
  }

  return typeLabel;
};

export const getLocationLabel = l => {
  const { address } = l;
  const { city, country, establishment, natural_feature, stateCode } = address || {};
  let listingLocation = city || establishment || natural_feature || country || <span className="text-gray-500">-</span>;
  if ((city || establishment || natural_feature) && stateCode) {
    listingLocation = `${listingLocation}, ${stateCode}`;
  }

  return listingLocation;
};

export const getListingTypeAndLocationLabel = (l, { typeClassName = 'capitalize', userPersona = 'customer' } = {}) => {
  const { type, subType, address } = l;
  const { city, country, establishment, natural_feature } = address || {};
  const listingLocation = city || establishment || natural_feature || country || 'Unknown location';

  const isAtLocation = !city && (establishment || natural_feature);
  const isVideoType = type === 'video' && subType;
  const inOrFor = isVideoType ? 'for' : isAtLocation ? 'at' : 'in';
  return (
    <span>
      <span className={typeClassName}>{getListingTypeLabel(l, { userPersona })}</span> {inOrFor} {listingLocation}
    </span>
  );
};

export const getStripeConnectAccountType = marketplace => {
  return get(marketplace, 'applicationConfig.payment.metadata.stripe.defaultConnectType') || 'standard';
};

export const getStripeConnectFeatures = marketplace => {
  const { applicationConfig } = marketplace || {};
  const { payment } = applicationConfig || {};
  const { platformFee = 0 } = payment || {};

  const stripeConnectAccountType = getStripeConnectAccountType(marketplace);
  const isExpressDefaultPaymentType = stripeConnectAccountType === 'express';

  const featuresExpress = [
    {
      name: 'Transaction Fees',
      description: <>{platformFee}% fee per transaction</>,
      icon: ScaleIcon,
    },
  ];

  const featuresStandard = [
    {
      name: 'Use your own stripe account',
      description: 'Take advantage of all the features stripe has to offer. Manage payments & refunds directly from stripe.com.',
      icon: GlobeAltIcon,
    },
    {
      name: 'Transaction Fees',
      description: (
        <>
          {platformFee}% platform fee +{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary text-gray-600 underline hover:underline"
            href="https://stripe.com/pricing"
          >
            Stripe Transaction fee's
          </a>{' '}
          taken per transaction
        </>
      ),
      icon: ScaleIcon,
    },
  ];

  return isExpressDefaultPaymentType ? featuresExpress : featuresStandard;
};

export const getProviderNavItems = ({ currentUser = {}, currentPath, isAuthenticated, marketplaceOff } = {}) => {
  const isProMarketplace = isProEnabledMarketplace();
  const marketplace = getMarketplace();
  const products = get(marketplace, 'applicationConfig.settings.features.products');

  const formsFeature = {
    title: 'Forms',
    icon: DocumentTextIcon,
    path: '/forms',
    current: currentPath === 'forms',
  };
  const potentialProFeatures = [
    {
      title: 'Contacts',
      icon: UsersIcon,
      path: '/contacts/all',
      proType: 'contacts',
      current: currentPath === 'contacts',
    },
    {
      title: 'Calendar',
      icon: CalendarIcon,
      path: '/calendar/sales',
      proType: 'calendar',
      current: currentPath === 'calendar',
    },
  ];

  if (products) {
    potentialProFeatures.push({
      title: label('Products'),
      icon: ShoppingBagIcon,
      path: '/products',
      proType: 'products',
      current: currentPath === 'products',
    });
  }

  let freeFeatures = [];
  const proFeatures = [];

  if (isProMarketplace) {
    proFeatures.push({
      ...formsFeature,
      pro: true,
    });
    for (const feature of potentialProFeatures) {
      if (isProOnlyRoute(feature.proType)) {
        proFeatures.push({
          ...feature,
          pro: true,
        });
      } else {
        freeFeatures.push(feature);
      }
    }
  } else {
    freeFeatures = [...potentialProFeatures, formsFeature];
  }

  const messagesMaybe = isMessengerSetup()
    ? [
        {
          title: label('Messages'),
          icon: ChatBubbleOvalLeftEllipsisIcon,
          path: '/messages',
          badge: currentUser?._notifications?.unreadMessages,
          current: currentPath === 'messages',
        },
      ]
    : [];

  return [
    {
      title: 'Dashboard',
      icon: HomeIcon,
      path: marketplaceOff ? '/' : '/dashboard',
      current: currentPath === 'dashboard' || (isAuthenticated && marketplaceOff && currentPath === ''),
    },
    {
      title: label('Listings'),
      icon: FolderIcon,
      path: '/listings',
      current: currentPath === 'listings',
    },
    {
      title: 'Sales',
      icon: RectangleStackIcon,
      path: '/bookings/sales',
      current: currentPath === 'bookings',
    },
    ...messagesMaybe,
    {
      title: label('Website'),
      icon: GlobeAltIcon,
      path: '/website',
      current: currentPath === 'website',
    },
    {
      title: 'Components',
      icon: RectangleGroupIcon,
      path: '/components',
      current: currentPath === 'components',
    },
    ...proFeatures,
    ...freeFeatures,
    {
      title: 'Policies',
      icon: ClipboardDocumentCheckIcon,
      path: '/policies',
      current: currentPath === 'policies',
    },
  ];
};

export const isRequestToBookActive = marketplace => !!get(marketplace, 'pageConfig.listingPage.requestsForBookingAllowed');

export const downloadBlob = (blob, filename) => {
  let link = document.createElement('a');
  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isVerifiedListingsOnly = marketplace => {
  const marketplaceVerificationTypes = get(marketplace, 'applicationConfig.listing.displayCondition.verificationTypes');
  return marketplaceVerificationTypes && marketplaceVerificationTypes.includes('verified');
};

export const isSidebarNavigationType = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  return get(Marketplace, 'pageConfig.advanced.navigationType') === 'sidebar';
};

export const isCrm = () => {
  const state = window.app && window.app.store.getState();
  const { Marketplace } = state || {};
  // TODO: lock this down so it checks url too
  return get(Marketplace, 'applicationConfig.settings.features.crm');
};

export const getMarketplaceAllCurrenciesAllowed = () => {
  const marketplace = getMarketplace();
  const { pageConfig } = marketplace || {};
  const { general } = pageConfig || {};
  const { currencyAllowAll } = general || {};
  return currencyAllowAll || false;
};

export const getStripeFeeFromPaymentInfo = (paymentInfo = {}) => {
  const { chargingMethod = 'destination', feeDetails } = paymentInfo || {};
  const hasFeeDetails = feeDetails && feeDetails.length;
  if (chargingMethod !== 'direct' || !hasFeeDetails) return 0;

  const stripeFeeDetails = feeDetails.find(fee => fee.type === 'stripe_fee');
  const { amount = 0 } = stripeFeeDetails || {};
  return Number(amount) / 100;
};

export const getPlatformFeeFromPaymentInfo = (paymentInfo = {}) => {
  const { platformFee = 0 } = paymentInfo || {};
  return Number(platformFee) || 0;
};

export const getTransactionFeesFromPaymentInfo = (paymentInfo = {}) => {
  const platformFee = getPlatformFeeFromPaymentInfo(paymentInfo);
  const stripeFee = getStripeFeeFromPaymentInfo(paymentInfo);
  return stripeFee + platformFee || 0;
};

export const getProfitFromTransaction = transaction => {
  const { type, billedAmount = 0, paymentInfo } = transaction || {};

  const fees = getTransactionFeesFromPaymentInfo(paymentInfo) || 0;
  const profit = billedAmount - fees;

  const isSubscription = type === 'subscriptionPurchase' || type === 'subscriptionPayment';
  return !isSubscription && profit > 0 ? profit : 0;
};

export function openLatLng(lat, long) {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf('iPhone') !== -1 ||
    navigator.platform.indexOf('iPad') !== -1 ||
    navigator.platform.indexOf('iPod') !== -1
  )
    window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  /* else use Google */ else window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
}

export const computeTags = tags => {
  if (!tags || !tags.length) return [];
  return tags.map(tag => ({ label: unCamelize(tag), value: tag }));
};

export const getSearchDataType = () => {
  const marketplace = getMarketplace();
  let currentPathname;
  try {
    currentPathname = window.app.store.getState().Routing?.currentLocation.pathname;
  } catch {}
  const configSearchDataType = get(marketplace, 'pageConfig.search.searchDataType');
  const isProviderSearch = configSearchDataType === 'providers' || currentPathname === '/s/providers';
  return isProviderSearch ? 'providers' : 'listings';
};

export const getSearchPagePathParams = () => {
  const marketplace = getMarketplace();
  const searchDataType = getSearchDataType();
  const configSearchDataType = get(marketplace, 'pageConfig.search.searchDataType');
  let pathParams = {},
    pathIndex = 0;
  if (searchDataType !== 'listings' && configSearchDataType !== 'providers') {
    pathParams = { tab: searchDataType };
    pathIndex = 1;
  }
  return {
    pathParams,
    pathIndex,
  };
};

export const isGuestCheckoutDisabled = () => {
  const marketplace = getMarketplace();
  return get(marketplace, 'pageConfig.listingPage.guestCheckoutDisabled') === true;
};

export const getAccountPersona = currentUser => {
  currentUser = currentUser?.id ? currentUser : getCurrentUser();
  const isAuthenticated = !!currentUser?.id;
  const { type } = currentUser || {};
  let persona = isAuthenticated ? 'individual' : 'unauthenticated';
  if (type === 'provider') {
    persona = 'provider';
  }
  if (isProAccount(currentUser)) {
    persona = 'pro';
  }
  return persona;
};

export const isMessengerSetup = () => {
  const marketplace = getMarketplace();
  const isConfigured = get(marketplace, 'servicesCredentials.sendBird.appId');
  if (!isConfigured) return false;

  const isMessengerDisabled = get(marketplace, 'pageConfig.messenger.disabled');
  if (isMessengerDisabled) return false;

  return true;
};

export const getMessengerPermission = action => {
  const marketplace = getMarketplace();
  const isConfigured = get(marketplace, 'servicesCredentials.sendBird.appId');
  if (!isConfigured) return false;

  const isMessengerDisabled = get(marketplace, 'pageConfig.messenger.disabled');
  if (isMessengerDisabled) return false;

  const currentUser = getCurrentUser();
  const isAuthenticated = !!currentUser?.id;
  if (!isAuthenticated && action === 'create') {
    return false;
  }

  const persona = getAccountPersona(currentUser);
  const isDisabled = get(marketplace, `pageConfig.messenger.account.${persona}.message.disabled.${action}`);
  return !isDisabled;
};

// https://www.joshwcomeau.com/react/dark-mode/#a-first-pass
// returns light or dark based on local storage value
export const getColorMode = () => {
  const persistedColorPreference = window.localStorage.getItem('color-mode');
  const hasPersistedPreference = persistedColorPreference && typeof persistedColorPreference === 'string';
  if (hasPersistedPreference) {
    return persistedColorPreference;
  }
  const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
  if (!supportMatchMedia) return 'light';

  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  const hasMediaQueryPreference = typeof mql.matches === 'boolean';
  if (hasMediaQueryPreference) {
    return mql.matches ? 'dark' : 'light';
  }
  return 'light';
};

export const handleThemeChange = newColorMode => {
  if (!newColorMode) {
    window.localStorage.removeItem('color-mode');
  } else {
    window.localStorage.setItem('color-mode', newColorMode);
  }
  updateColorModeState();
};

// Sync color mode value to redux store
export const updateColorModeState = () => {
  const colorMode = getColorMode();

  const root = document.documentElement;

  const darkForced = root.classList.contains('dark-forced');
  const darkDisabled = root.classList.contains('dark-disabled');
  // When dark is forced or disabled, ignore any changes to state as they would have been set on marketplace load in Marketplace.duck.js
  if (darkForced || darkDisabled) {
    return;
  }

  if (colorMode === 'dark') {
    root.classList.add('dark');
  } else {
    root.classList.remove('dark');
  }

  try {
    window.app.store.dispatch(setColorMode(getColorMode()));
  } catch (error) {
    console.error('There was an error syncing color mode to the redux store', error);
  }
};

export const getListingAvailabilityLabels = () => {
  const marketplace = getMarketplace();
  const marketplaceLabels = get(marketplace, `pageConfig.general.labels`);
  const {
    timeslotsDescription = 'I am listing multiple time slots on different days and or times. You can create more timeslots in your calendar after you setup an initial timeslot below.',
    eventsDescription = 'I am listing an event on a single date and time.',
  } = marketplaceLabels || {};
  return { eventsDescription, timeslotsDescription };
};

export const getSearchPredictionForPlace = place => {
  const { types, name, address } = place || {};
  if (name && types?.includes('establishment')) {
    return `${name}, ${address}`;
  }
  return address;
};

export const getMediaProps = (media, { colorMode } = {}) => {
  const {
    image,
    video,
    videoActive,
    backgroundVideoActive,
    backgroundVideo,
    lottieBackgroundVideo,
    backgroundVideoType,
    classNames,
    backgroundVideoClassNames,
    dark: darkAssets,
    useDarkModeAssets = false,
    ...rest
  } = media || {};
  let mediaBackgroundVideo = null;
  if (backgroundVideoActive) {
    mediaBackgroundVideo = backgroundVideo;
    if (backgroundVideoType === 'lottiefile') {
      mediaBackgroundVideo = lottieBackgroundVideo;
    }
  }

  const mediaVideo = videoActive ? video : null;

  if (!image && !mediaVideo && !mediaBackgroundVideo) {
    return null;
  }

  let dynamicAssets = {
    image,
    backgroundVideo: mediaBackgroundVideo,
  };
  if (useDarkModeAssets && darkAssets?.image && colorMode === 'dark') {
    dynamicAssets = {
      ...dynamicAssets,
      ...darkAssets,
    };
  }

  return {
    classNames,
    backgroundVideoClassNames,
    video: mediaVideo,
    ...dynamicAssets,
    ...rest,
  };
};

export const hexToRgb = hex => {
  if (hex.includes('#')) {
    hex = hex.replace('#', '');
  }

  const color = `#${hex}`,
    components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (!components) {
    return null;
  }
  return {
    r: parseInt(components[1], 16),
    g: parseInt(components[2], 16),
    b: parseInt(components[3], 16),
  };
};

function getPathFromUrl(url) {
  return url.split('?')[0];
}
export const formatWebsite = (website, removeQueryParams) => {
  let formatted = website ? website.replace(/^https?:\/\//i, '').replace(/\/$/, '') : '';
  if (removeQueryParams && formatted) {
    formatted = getPathFromUrl(formatted);
  }
  return formatted;
};

export const placeResultToEstablishmentPage = place => {
  const placeId = place?.placeId;
  const establishment = getMarketplace()?.applicationConfig?.playblock?.establishments?.[placeId];
  if (establishment?.slug) {
    return `/${establishment?.slug}`;
  }

  return null;
};

const placeResultToLocationUrl = place => {
  const { countryCode, stateCode, city, name, county, streetName, streetNumber, vicinity, sublocality } = place || {};
  let street = `${streetNumber ? `${streetNumber}-` : ''}${kebabCase(streetName)}`;
  if (!streetName && vicinity) street = kebabCase(vicinity.split(',')?.[0]);
  const slug = kebabCase(name);
  const country = countryCode.toLowerCase();
  const state = kebabCase(stateCode).toLowerCase();
  let countyLabel = kebabCase(county);
  if (!countyLabel && sublocality) countyLabel = kebabCase(sublocality);
  const cityLabel = kebabCase(city);
  if (!country || !state || !countyLabel || !cityLabel || !slug) return null;
  return `${country}/${state}/${countyLabel}/${cityLabel}${street ? `/${street}` : ''}/${slug}`;
};

export const placeResultToPlacePage = (place, force = false) => {
  const { types } = place || {};
  const placeKeys = Object.keys(config.places.types);
  let placeTypeSlug;
  if (types?.length) placeTypeSlug = types?.find(t => placeKeys.includes(t));
  if (force && types?.length && !placeTypeSlug) {
    placeTypeSlug = types[0];
  }
  if (!placeTypeSlug) return null;
  const placePath = placeResultToLocationUrl(place);
  if (!placePath) return null;
  return `/places/${placeTypeSlug}/${placePath}`;
};

export const getMicroMarketplaceId = () => {
  const urlParts = window.location.pathname.split('/');
  const page = urlParts[1];
  const marketplaceId = urlParts[2];
  if (page === 'admin-micro' && marketplaceId) return marketplaceId;
  return getMarketplace()?.microMarketplace?.id;
};

export const addMicroMarketplaceIdMaybe = body => {
  const microMarketplaceId = getMicroMarketplaceId();
  if (!microMarketplaceId) return body;
  body.microMarketplace = microMarketplaceId;
  return body;
};

export const getMarketplaceDomains = marketplace => {
  if (!marketplace) return [];
  const { customDomain, customDomains = [] } = marketplace;

  let domains = [`${marketplace.domain}.${config.defaultHostname}`];
  if (customDomain) domains.push(customDomain);
  if (customDomains?.length) {
    domains = [...domains, ...customDomains];
  }
  if (config.local) {
    domains.unshift(window.location.host);
  }

  return domains;
};

export const getCurrentMarketplaceDomain = marketplace => {
  if (!marketplace) return null;
  const domains = getMarketplaceDomains(marketplace);
  return domains.find(domain => window.location.host === domain || window.location.hostname === domain) || domains[0];
};

export const getCurrentMarketplaceTheme = marketplace => {
  let mp = marketplace || getMarketplace();

  if (mp.microMarketplace?.branding?.components?.useCustomComponentSettings) {
    mp = mp.microMarketplace;
  }

  return get(mp, 'branding.theme') || 'simple';
};

export const loadMarketplaceFonts = (marketplace = {}) => {
  const { branding } = marketplace || {};
  const { font } = branding || {};
  const { primary: fontPrimary, header: fontHeader, third: fontThird } = font || {};

  const root = document.documentElement.style;
  if (!fontPrimary) root.removeProperty('--mpc-font-family');
  if (!fontHeader) root.removeProperty('--mpc-font-family-header');
  if (!fontThird) root.removeProperty('--mpc-font-family-third');
  if (!fontPrimary && !fontHeader && !fontThird) return;

  let googleFonts = config.custom.fonts;
  const typekitId = get(marketplace, 'servicesCredentials.adobeFonts.id');
  const webFontConfig = {
    classes: false,
    active: () => {
      if (fontPrimary) root.setProperty('--mpc-font-family', fontPrimary);
      if (fontHeader) {
        root.setProperty('--mpc-font-family-header', fontHeader);
      } else {
        root.setProperty('--mpc-font-family-header', fontPrimary || '');
      }
      if (fontThird) {
        root.setProperty('--mpc-font-family-third', fontThird);
      } else {
        root.setProperty('--mpc-font-family-third', fontPrimary || '');
      }
    },
  };

  const mpcFonts = ['Canada Sanders', 'Druk Wide', 'Suisse Intl'];
  const isGoogleFontFamily = name => name && !mpcFonts.includes(name) && googleFonts.findIndex(f => f.key === name) > -1;
  const googleConfig = [];
  let customConfig = [];
  const fonts = [
    {
      id: 'primary',
      name: fontPrimary,
      active: !!fontPrimary,
      google: isGoogleFontFamily(fontPrimary),
      custom: mpcFonts.includes(fontPrimary),
    },
    {
      id: 'secondary',
      name: fontHeader,
      active: !!fontHeader,
      google: isGoogleFontFamily(fontHeader),
      custom: mpcFonts.includes(fontHeader),
    },
    {
      id: 'third',
      name: fontThird,
      active: !!fontThird,
      google: isGoogleFontFamily(fontThird),
      custom: mpcFonts.includes(fontThird),
    },
  ];

  for (const font of fonts) {
    if (font.active && font.google) {
      googleConfig.push(`${font.name}:300,400,500,600,700,800&display=swap`);
    }

    if (font.active && font.custom) {
      customConfig.push(font.name);
    }
  }
  if (googleConfig && googleConfig.length) {
    webFontConfig.google = { families: googleConfig };
  }

  if (customConfig?.length) {
    const urls = customConfig.map(f => `/static/fonts/${slugify(f)}/${slugify(f)}.css`);
    webFontConfig.custom = {
      families: customConfig,
      urls,
    };
  }

  const hasTypeKitFontActive = fonts.findIndex(font => font.active && !font.google) > -1;
  if (typekitId && hasTypeKitFontActive) {
    webFontConfig.typekit = { id: typekitId };
  }

  WebFont.load(webFontConfig);
};

export const getHasMicroMarketplaceAccess = () => {
  return get(getMarketplace(), 'applicationConfig.settings.features.microMarketplaces');
};
