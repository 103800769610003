import { useEffect, useState } from 'react';

import CategoryList from '@/components/CategoryList/CategoryList';
import TabsLight from '@/components/TabsLight/TabsLight';

import UiTextRenderer from './UiTextRenderer';

const UiCategorySection = ({
  titleClassNames,
  title,
  params,
  categories,
  description,
  className = 'overflow-hidden',
  contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
  type,
}) => {
  return (
    <section className={className}>
      <div className={contentClassName}>
        <div className="mb-4">
          <UiTextRenderer
            type={type}
            classNames={titleClassNames}
            defaultFontWeightClassName="font-semibold"
            defaultFontSizeClassName="text-lg"
            defaultFontFamilyClassName=""
            defaultFontColorClassName="text-gray-900"
          >
            {title}
          </UiTextRenderer>
          {description ? <div className="mt-1 text-base leading-5 text-gray-500">{description}</div> : null}
        </div>

        <CategoryList {...params} contentClassName={contentClassName} data={categories} />
      </div>
    </section>
  );
};

const UiCategory = params => {
  const { categories: initialCategories, metadata = {}, contentClassName, type } = params || {};
  const { category: categoryParams } = metadata?.ui || {};
  const { multipleStyle, amount, containerLayout } = categoryParams || {};

  const [categories, setCategories] = useState([]);
  const [tab, setTab] = useState(categories?.[0]?.category);

  useEffect(() => {
    let categories = initialCategories;

    //  Check if categories array is an array of objects
    //  Flatten the array if so and only keep one category per type
    if (amount === 'multiple') {
      categories = categories.filter(c => c?.items?.length) || [];

      if (!categories?.length) return;

      categories = categories.map(c => {
        const firstItem = c?.items[0] || null;
        let obj = { ...c, type: c.category };
        if (!['seperated', 'tabs'].includes(multipleStyle)) {
          obj = {
            ...obj,
            ...firstItem,
          };
        }
        return obj;
      });
      setTab(categories?.[0]?.category);
    }

    setCategories(categories);
  }, [initialCategories, amount, multipleStyle]);

  if (!categories?.length) return null;
  const Header = ({ className = contentClassName }) => {
    return (
      <>
        <UiTextRenderer
          classNames={metadata?.titleClassNames}
          className={className}
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {metadata?.title}
        </UiTextRenderer>
        {metadata?.description ? (
          <UiTextRenderer
            classNames={metadata?.descriptionClassNames}
            className="mt-1"
            defaultFontWeightClassName=""
            defaultFontFamilyClassName=""
            defaultFontSizeClassName="text-base"
            defaultLineHeightClassName="leading-5"
            defaultFontColorClassName="text-gray-500"
          >
            {metadata?.description}
          </UiTextRenderer>
        ) : null}
      </>
    );
  };

  if (amount === 'multiple') {
    if (multipleStyle === 'seperated') {
      return (
        <section className={metadata?.className || ''}>
          <div className="mb-6">
            <Header />
          </div>

          <div className="grid gap-x-6 gap-y-12 sm:grid-cols-2">
            {categories.map(c => {
              return (
                <UiCategorySection
                  className="overflow-hidden"
                  key={c.category}
                  {...metadata}
                  titleClassNames={c?.metadata?.titleClassNames}
                  title={c.title || metadata?.title}
                  categories={c.items}
                  params={categoryParams}
                  contentClassName={contentClassName}
                />
              );
            })}
          </div>
        </section>
      );
    }

    if (multipleStyle === 'tabs') {
      return (
        <section className={metadata?.className || ''}>
          <div className={containerLayout === 'row' ? `xl:grid xl:grid-cols-2 xl:gap-x-6 ${contentClassName}` : ''}>
            <div className={containerLayout === 'row' ? 'xl:flex xl:flex-col xl:justify-center' : ''}>
              <Header className={containerLayout === 'row' ? '' : contentClassName} />
            </div>

            <div className={containerLayout === 'row' ? '' : contentClassName}>
              <TabsLight
                nagativeMarginsMobile
                className="  mb-6"
                currentPath=""
                selected={tab}
                noLinks
                onChange={t => setTab(t)}
                tabs={categories.map(c => ({
                  title: c.title,
                  slug: c.category,
                }))}
              />

              <CategoryList
                {...categoryParams}
                contentClassName={contentClassName}
                data={categories.find(c => c.category === tab)?.items || []}
              />
            </div>
          </div>
        </section>
      );
    }
  }

  return <UiCategorySection contentClassName={contentClassName} {...metadata} categories={categories} params={categoryParams} />;
};

export default UiCategory;
