import { uuidv4 } from '@/util/helpers';

// https://github.com/mattkrick/sanitize-svg
const sanitizeSVG = async (svg, attributesToAdd = {}, options = {}) => {
  const { removeFills = false, returnElement = false } = options || {};

  try {
    const svgText = svg;
    const div = window.document.createElement('div');
    div.innerHTML = svgText;
    const svgEl = div.firstElementChild;
    const attributes = Array.from(svgEl.attributes).map(({ name }) => name);
    const hasScriptAttr = !!attributes.find(attr => attr.startsWith('on'));
    const scripts = svgEl.getElementsByTagName('script');

    svgEl.removeAttribute('width');
    svgEl.removeAttribute('height');
    svgEl.removeAttribute('script');

    for (const key in attributesToAdd) {
      const value = attributesToAdd[key];
      const attr = key;
      svgEl.setAttribute(attr, value);
    }

    // Color
    if (removeFills) {
      svgEl.setAttribute('fill', 'currentColor');
      const paths = svgEl.querySelectorAll('path');
      for (const path of paths) {
        path.removeAttribute('fill');
      }
    } else {
      const paths = svgEl.querySelectorAll('path');
      for (const path of paths) {
        if (path?.getAttribute('fill')?.includes('url(#')) {
          const gradientId = path.getAttribute('fill').replace('url(#', '').replace(')', '');
          const gradient = svgEl.querySelector(`#${gradientId}`);
          const newId = uuidv4();
          gradient.setAttribute('id', newId);
          path.setAttribute('fill', `url(#${newId})`);
        }
      }
    }

    const sanitizedSvgText = svgEl.outerHTML;

    if (returnElement) {
      return svgEl;
    }

    return scripts.length === 0 && !hasScriptAttr ? sanitizedSvgText : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default sanitizeSVG;
