import toPairs from 'lodash/toPairs';
import moment from 'moment';

import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 256;

const isNonEmptyString = val => {
  return typeof val === 'string' && val.trim().length > 0;
};

/**
 * Validator functions and helpers for Final Forms
 */

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

export const required = message => value => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : message;
  }
  return VALID;
};

export const requiredStringNoTrim = message => value => {
  return typeof value === 'string' && value.length > 0 ? VALID : message;
};

export const validSlug = (message, slugs) => value => {
  if (!value) return message;

  if (slugs && slugs.length && slugs.includes(value)) {
    return 'This slug us already in use';
  }

  const SLUG_RE = /^[a-zA-Z0-9_-]*$/;
  if (!SLUG_RE.test(value)) {
    return message;
  }

  return VALID;
};

export const requiredTrueBoolean = message => value => {
  return value === true ? VALID : message;
};

export const requiredFieldArrayCheckbox = message => value => {
  if (!value) {
    return message;
  }

  const entries = toPairs(value);
  const hasSelectedValues = entries.filter(e => !!e[1]).length > 0;
  return hasSelectedValues ? VALID : message;
};

export const minLength = (message, minimumLength) => value => {
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length >= minimumLength ? VALID : message;
};

export const maxLength = (message, maximumLength) => value => {
  if (!value) {
    return VALID;
  }
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length <= maximumLength ? VALID : message;
};

export const nonEmptyArray = message => value => {
  return value && Array.isArray(value) && value.length > 0 ? VALID : message;
};

export const autocompleteSearchRequired = message => value => {
  return value && value.search ? VALID : message;
};

export const autocompletePlaceSelected = message => value => {
  const selectedPlace = value?.selectedPlace;
  const hasAddress = selectedPlace?.address;
  const latAndLng = selectedPlace?.origin?.lat && selectedPlace?.origin?.lng;

  const selectedPlaceIsValid = !!(hasAddress && latAndLng);
  return selectedPlaceIsValid ? VALID : message;
};

export const bookingDateRequired =
  (initialInValidDateMessage, options = {}) =>
  value => {
    let dateIsValid = value && value.date instanceof Date;
    let inValidDateMessage = initialInValidDateMessage;

    if (options && options.start && dateIsValid) {
      dateIsValid = moment(value.date).isAfter(moment(options.start));
      if (!dateIsValid) {
        inValidDateMessage = 'Please select a date after than the start date';
      }
    }

    return !dateIsValid ? inValidDateMessage : VALID;
  };

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = (message, notRequired) => value => {
  if (notRequired && !value) return VALID;
  return value && EMAIL_RE.test(value) ? VALID : message;
};

export const socialUsernameValid = (message, notRequired) => value => {
  if (notRequired && !value) return VALID;
  return value && !value.includes('http://') && !value.includes('https://') && !value.includes('.com') ? VALID : message;
};

export const isEmailValid = value => value && EMAIL_RE.test(value);

export const moneySubUnitAmountAtLeast = (message, minValue) => value => {
  return value instanceof Money && value.amount >= minValue ? VALID : message;
};

const parseNum = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

export const ageAtLeast = (message, minYears) => value => {
  if (!value) return;

  if (typeof value.getMonth === 'function') {
    value = {
      day: value.getDate(),
      month: value.getMonth() + 1,
      year: value.getFullYear(),
    };
  }

  const { year, month, day } = value;
  const dayNum = parseNum(day);
  const monthNum = parseNum(month);
  const yearNum = parseNum(year);

  // day, month, and year needs to be numbers
  if (dayNum !== null && monthNum !== null && yearNum !== null) {
    const now = moment();
    const age = new Date(yearNum, monthNum - 1, dayNum);
    const ageInYears = now.diff(moment(age), 'years', true);

    return age && age instanceof Date && ageInYears >= minYears ? VALID : message;
  }
  return message;
};

const isValidURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const validURL = (message, notRequired) => value => {
  if (notRequired && !value) return VALID;

  if (typeof value === 'undefined' || value === null) {
    return message;
  }

  const disallowedChars = /[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/;
  const protocolTokens = value.split(':');
  const includesProtocol = protocolTokens.length > 1;
  const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);

  const invalidCharacters = !!value.match(disallowedChars);
  const invalidProtocol = !(usesHttpProtocol || !includesProtocol);
  const isNotValid = !isValidURL(value);

  // Stripe checks against example.com
  const isExampleDotCom = !!value.match(/^(https?:\/\/example\.com|example\.com)/);
  const isLocalhost = !!value.match(/^(https?:\/\/localhost($|:|\/)|localhost($|:|\/))/);

  return invalidCharacters || invalidProtocol || isExampleDotCom || isLocalhost || isNotValid ? message : VALID;
};

export const composeValidators =
  (...validators) =>
  value =>
    validators.reduce((error, validator) => error || validator(value), VALID);
