import { string } from 'prop-types';

import MarketplaceSubscriptionDetails from '@/components/MarketplaceSubscriptionDetails/MarketplaceSubscriptionDetails';

const SectionLandingPricing = props => {
  const { className, intl, history, title, description, marketplace, currentUser, titleClassNames } = props;
  return (
    <MarketplaceSubscriptionDetails
      title={title}
      titleClassNames={titleClassNames}
      intl={intl}
      description={description}
      className={className}
      currentUser={currentUser}
      marketplace={marketplace}
      history={history}
    />
  );
};

SectionLandingPricing.defaultProps = { className: null };

SectionLandingPricing.propTypes = {
  className: string,
  title: string,
  href: string,
  description: string,
};

export default SectionLandingPricing;
