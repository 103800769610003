import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const create = body => {
  return client('groups', { body });
};

const read = id => {
  return client(`groups/${id}`);
};

const update = (id, body) => {
  return client(`groups/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`groups/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000 }) => {
  return client(`groups?${stringify(params)}`);
};

export { read, list, update, create, remove };
