import { track } from '@/analytics/track';

export const getProductFromResults = (result, index = null) => {
  const obj = {
    name: result.title,
    image_url: result.assets && result.assets.length ? result.assets[0].metadata.url : undefined,
    price: result.basePrice || result.totalAmount,
    currency: result.baseCurrency,
    product_id: result.id,
    url: `${window.location.hostname}/l/${result.slug}/${result.id}`,
    variant: result.type,
    sub_variant: result.subType,
    slug: result.slug,
    provider: result.provider,
  };
  if (index !== null) obj.position = index + 1;

  return obj;
};

const getProductsFromSearchResults = results => {
  return results.map((l, index) => getProductFromResults(l, index));
};

export const listViewed = ({ hits, ...rest }) => {
  const products = getProductsFromSearchResults(hits);
  track('product.listViewed', { products, ...rest });
};

export const listFiltered = ({ hits, filters, sorts, ...rest }) => {
  const products = getProductsFromSearchResults(hits);
  track('product.listFiltered', { products, filters, sorts, ...rest });
};

export const clicked = props => {
  const product = getProductFromResults(props, props.index);
  track('product.clicked', { ...product });
};

export const viewed = props => {
  const product = getProductFromResults(props);
  track('product.viewed', { ...product });
};

export const shared = ({ share_via, ...props }) => {
  const product = getProductFromResults(props);
  track('product.shared', { ...product, share_via });
};
