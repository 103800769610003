import WebProfiles from '@/components/WebProfiles/WebProfiles';

import UiTextRenderer from './UiTextRenderer';

const UiContactAndSocials = ({
  titleClassNames,
  className = '',
  metadata,
  hidePhoneNumber,
  hideEmail,
  type,
  columns = false,
  contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
}) => {
  const {
    showContactEmail,
    title = 'Contact Information',
    contactEmail,
    email,
    showContactPhoneNumber,
    phone,
    hasWebProfile,
    webProfiles,
    website,
  } = metadata || {};

  const renderPhone = !hidePhoneNumber && showContactPhoneNumber && phone;
  const renderEmail = !hideEmail && showContactEmail;
  const renderSocials = hasWebProfile || website;
  if (!renderPhone && !renderEmail && !renderSocials) {
    return null;
  }

  return (
    <section className={className}>
      <div className={contentClassName}>
        <UiTextRenderer
          classNames={titleClassNames}
          className="mb-4"
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {title}
        </UiTextRenderer>

        <WebProfiles
          showContactEmail={renderEmail}
          contactEmail={contactEmail}
          email={email}
          showContactPhoneNumber={renderPhone}
          phone={phone}
          inline={!columns}
          hasWebProfile={hasWebProfile}
          website={website}
          profiles={webProfiles}
        />
      </div>
    </section>
  );
};

export default UiContactAndSocials;
