import { useSelector } from 'react-redux';

import Media from '@marketplace-co/react-application-element-media';
import set from 'lodash/set';

import { getMediaProps } from '@/util/helpers';

const UiMediaSection = params => {
  const { media, classNames, useImageAspectRatio } = params?.metadata || {};
  let {
    aspectRatio = 'aspect-w-16 aspect-h-9',
    shadow = '',
    maxWidth = '',
    borderRadius = '',
    paddingX = 'px-container',
    bgColor = 'bg-inherit',
  } = classNames || {};
  const colorMode = useSelector(state => state?.UI?.colorMode);
  let mediaStyle = {};
  if (useImageAspectRatio) {
    const { width, height } = media?.image?.metadata || {};
    if (width && height) {
      const ar = (height / width) * 100;
      mediaStyle = { paddingBottom: `${ar}%` };
      aspectRatio = '';
    }
  }
  if (params.priority) {
    set(media, 'imageProps.priority', true);
  }
  const mediaProps = getMediaProps(media, { colorMode });
  const wrapperClassName = `${paddingX} mx-auto ${maxWidth}`;
  const mediaClassNameDefaults = 'relative flex flex-col items-center justify-center overflow-hidden';
  const mediaClassName = `${mediaClassNameDefaults} ${aspectRatio} ${shadow} ${borderRadius} ${bgColor} transform-gpu`;
  return (
    <div className={wrapperClassName}>
      <div style={mediaStyle} className={mediaClassName}>
        {media?.image ? <Media {...mediaProps} /> : null}
      </div>
    </div>
  );
};

export default UiMediaSection;
