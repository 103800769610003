import { Component } from 'react';

import classNames from 'classnames';
import { bool, func, object, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';

import ExpandingTextarea from '@/components/ExpandingTextarea/ExpandingTextarea';
import FieldSelect from '@/components/FieldSelect/FieldSelect';
import ValidationError from '@/components/ValidationError/ValidationError';

import { formatPriceString } from '@/util/currency';
import { getMarketplaceAllCurrenciesAllowed, getMarketplaceCurrency } from '@/util/helpers';
import { ExclamationCircleIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

import config from '@/config';

const CONTENT_MAX_LENGTH = 5000;

class FieldTextInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      isUncontrolled,
      inputRef,
      currency,
      currencyName,
      hint,
      allowCurrencySelection = false,
      hideErrorMessage = false,
      hideErrorStyle = false,
      inputClassName = '',
      showCurrenySymbol = false,
      noMinTextAreaHeight = false,
      optional = false,
      currencySymbol,
      children,
      inputWrapperClassName,
      roundedClassName,
      childrenBeforeInput = false,
      hideCurrency = false,
      textSize = 'sm',
      validateOnType,
      hintLocation = 'top',
      hintTextClassName = 'text-sm',
      maxLength,
      labelClassName = 'block text-sm font-medium leading-5 text-gray-700',
      twoColumns = false,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    const dataTest = rest && rest['data-test'];
    const validationErrorDataTest = dataTest ? `${dataTest}-validation-error` : `${id}-validation-error`;

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const marketplaceCurrency = getMarketplaceCurrency();
    const allCurrenciesAllowed = getMarketplaceAllCurrenciesAllowed();

    const { invalid, touched, error, pristine } = meta;
    const isTextarea = input.type === 'textarea';
    const isCurrency = input.type === 'currency';
    if (isCurrency) {
      input.type = 'text';
    }

    const errorText = customErrorText || error;
    const touchedValidator = validateOnType ? touched || !pristine : touched;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touchedValidator && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const roundedClasses = roundedClassName || 'rounded-md';

    const textSizeClassName = textSize === 'sm' ? 'sm:text-sm' : '';
    const tailwindInputClasses = `shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full bg-white border-gray-300 ${textSizeClassName}`;
    const tailwindInputErrorClasses =
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red pr-10';
    const tailwindInputCurrencyClasses = 'pl-7 pr-12';
    const inputClasses =
      inputRootClass ||
      classNames(tailwindInputClasses, inputClassName, roundedClasses, {
        [tailwindInputErrorClasses]: hasError && !hideErrorStyle,
        [tailwindInputCurrencyClasses]: isCurrency || showCurrenySymbol,
      });
    let inputProps = isTextarea
      ? {
          className: inputClasses,
          id,
          rows: 1,
          maxLength: maxLength || CONTENT_MAX_LENGTH,
          ...refMaybe,
          ...inputWithoutType,
          ...rest,
        }
      : isUncontrolled
      ? {
          className: inputClasses,
          id,
          type,
          defaultValue,
          ...refMaybe,
          ...inputWithoutValue,
          ...rest,
        }
      : { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };

    const currencyInfo = (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span className="text-gray-500 sm:text-sm sm:leading-5" id="price-currency">
          {currency || marketplaceCurrency}
        </span>
      </div>
    );
    const currencySymbolIcon = (
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-gray-500 sm:text-sm sm:leading-5">{currencySymbol || config.currencyConfig.symbol}</span>
      </div>
    );
    const currencySelector = (
      <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center">
        <FieldSelect
          name={currencyName || 'baseCurrency'}
          className="h-full"
          noShadow
          selectContainerClassName="h-full"
          selectWrapperClassName="h-full"
          borderClassName="border-transparent"
          selectPaddingClassName=" "
          selectClassName="cursor-pointer focus:ring-primary-500 focus:border-primary-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
        >
          {config.custom.currencies.map(l => {
            return (
              <option disabled={allCurrenciesAllowed ? false : marketplaceCurrency !== l.key} key={l.key} value={l.key}>
                {l.key}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    );

    const classes = classNames(rootClassName, className);
    const inputWrapperClasses = `${label ? 'mt-1' : ''} relative ${roundedClasses} ${inputWrapperClassName}`;
    const labelMaybe = label ? (
      <div className="flex justify-between">
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
        {optional ? (
          <span className="text-sm text-gray-500" id="email-optional">
            Optional
          </span>
        ) : null}
      </div>
    ) : null;
    const inputField = isTextarea ? (
      <ExpandingTextarea style={!noMinTextAreaHeight ? { minHeight: '78px' } : {}} {...inputProps} />
    ) : (
      <input {...inputProps} />
    );

    const errorIcon =
      hasError && !hideErrorStyle ? (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="text-error-500 h-5 w-5" />
        </div>
      ) : (
        ''
      );

    const customInput = (
      <div className={inputWrapperClasses}>
        {isCurrency || showCurrenySymbol ? currencySymbolIcon : null}
        {childrenBeforeInput ? children : null}
        {inputField}
        {isCurrency && !hideCurrency ? (allowCurrencySelection ? currencySelector : currencyInfo) : null}
        {!childrenBeforeInput ? children : null}
        {errorIcon}
      </div>
    );

    const topHintMaybe =
      hint && hintLocation === 'top' ? <div className={`${hintTextClassName} mb-2 mt-1 text-gray-500`}>{hint}</div> : null;
    const bottomHintMaybe =
      hint && hintLocation === 'bottom' && !hasError ? (
        <div className={`${hintTextClassName} mt-2 mb-1 text-gray-500`}>{hint}</div>
      ) : null;

    const errorMessageMaybe = !hideErrorMessage ? (
      <ValidationError dataTest={validationErrorDataTest} fieldMeta={fieldMeta} />
    ) : null;

    if (twoColumns) {
      return (
        <div className={classes}>
          <div className="lg:flex lg:items-start">
            <div className="lg:flex-shrink-0 lg:w-2/5 lg:mr-4 mb-1 lg:mb-0 sm:mt-px sm:pt-2">
              {labelMaybe}
              {hint ? <p className={`${hintTextClassName} mt-1 text-gray-500 max-w-lg`}>{hint}</p> : null}
            </div>
            <div className="lg:flex-grow">
              {customInput}
              {errorMessageMaybe}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={classes}>
        {labelMaybe}
        {topHintMaybe}
        {customInput}
        {bottomHintMaybe}
        {errorMessageMaybe}
      </div>
    );
  }
}

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  // onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: oneOfType([string, object]),

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

function addHttpMaybe(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
}

class FieldTextInput extends Component {
  constructor(props) {
    super(props);

    const formatPrice = value => (value ? formatPriceString(value) : '');
    const isCurrency = this.props.type === 'currency';

    const formatUrl = value => (value ? addHttpMaybe(value) : '');
    const isUrl = this.props.type === 'url';

    this.format = isCurrency ? formatPrice : isUrl ? formatUrl : undefined;
  }

  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    // if (this.props.onUnmount) {
    //   this.props.onUnmount();
    // }
  }

  render() {
    return (
      <Field
        formatOnBlur={this.props.type === 'currency' || this.props.type === 'url'}
        format={this.format}
        component={FieldTextInputComponent}
        type="text"
        {...this.props}
      />
    );
  }
}

export default FieldTextInput;
