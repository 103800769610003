import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/tasks' : 'tasks';
};

const create = body => {
  if (body.id) {
    const id = body.id;
    delete body.id;
    return update(id, body);
  }
  return client('tasks', { body });
};

const read = id => {
  return client(`tasks/${id}`);
};

const update = (id, body) => {
  return client(`tasks/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`tasks/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000 }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}?${stringify(params)}`);
};

export { read, list, update, create, remove };
