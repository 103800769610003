/* eslint no-underscore-dangle: ["error", { "allow": ["_sdkType"] }] */

/**
   UUID type
   @constructor
   @param {string} uuid - UUID represented as string
 */
export class UUID {
  constructor(uuid) {
    this._sdkType = this.constructor._sdkType;
    this.uuid = uuid;
  }
}
UUID._sdkType = 'UUID';

export class LatLng {
  constructor(lat, lng) {
    this._sdkType = this.constructor._sdkType;
    this.lat = lat;
    this.lng = lng;
  }
}
LatLng._sdkType = 'LatLng';

export class LatLngBounds {
  constructor(ne, sw) {
    this._sdkType = this.constructor._sdkType;
    this.ne = ne;
    this.sw = sw;
  }
}
LatLngBounds._sdkType = 'LatLngBounds';

/**
   Money type to represent money
   - `amount`: The money amount in `minor` unit. In most cases, the minor unit means cents.
               However, in currencies without cents, e.g. Japanese Yen, the `amount` value
               is the number of Yens.
   - `currency`: ISO 4217 currency code
   Examples:
   ```
   new Money(5000, "USD") // $50
   new Money(150, "EUR")  // 1.5€
   new Money(2500, "JPY") // ¥2500
   ```
*/
export class Money {
  constructor(amount, currency) {
    this._sdkType = this.constructor._sdkType;
    this.amount = amount;
    this.currency = currency;
  }
}
Money._sdkType = 'Money';

/**
  Type to represent arbitrary precision decimal value.
  It's recommended to use a library such as decimal.js to make decimal
  calculations.
*/
export class BigDecimal {
  constructor(value) {
    this._sdkType = this.constructor._sdkType;
    this.value = value;
  }
}
BigDecimal._sdkType = 'BigDecimal';
