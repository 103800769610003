import Img from '@/components/Img/Img';
import ImgLogo from '@/components/ImgLogo/ImgLogo';

import UiTextRenderer from './UiTextRenderer';

const UiTestimonial = params => {
  const { title: quote, author: authorName, authorMetadata, image, authorAvatar, titleClassNames } = params?.metadata || {};
  if (!quote) return null;

  return (
    <section className="overflow-hidden py-12 md:py-20 lg:py-24">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <defs>
            <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          {image ? <ImgLogo className="mx-auto w-24" data={image} /> : null}

          <blockquote className="mt-10">
            <UiTextRenderer
              classNames={titleClassNames}
              className="mx-auto max-w-3xl text-center"
              defaultFontWeightClassName="font-medium"
              defaultFontSizeClassName="text-2xl"
              defaultFontFamilyClassName="font-header"
              defaultFontColorClassName="text-gray-900"
              defaultAlignClassName="leading-9"
              defaultLineHeightClassName
            >
              <p>&ldquo;{quote}&rdquo;</p>
            </UiTextRenderer>

            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  {authorAvatar ? (
                    <div className="relative mx-auto h-10 w-10 overflow-hidden rounded-full">
                      <Img data={authorAvatar} transformationType="sm" className="rounded-full" />
                    </div>
                  ) : null}
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">{authorName}</div>

                  <svg className="text-accent-600 mx-1 hidden h-5 w-5 md:block" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>
                  <div className="text-base font-medium text-gray-500">{authorMetadata}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default UiTestimonial;
