@import '../../styles/customMediaQueries.css';

.aspectWrapper {
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  left: 50%;
  right: 50%;
}

.aspectWrapperFluid {
  position: relative;
  width: 100%;
}

/* Small Media Setting */
.smallMediaAspectPadding {
  --find-play-hero-small-media-content-width: 33.4%;
  --find-play-hero-small-media-media-width: 66.6%;
  --find-play-hero-small-media-media-width-value: 0.66;
  --find-play-hero-small-media-media-aspect-ratio: 56.25%;
}

.smallMediaImgWrapper {
  width: var(--find-play-hero-small-media-media-width) !important;
  padding-bottom: calc(
    var(--find-play-hero-small-media-media-aspect-ratio) * var(--find-play-hero-small-media-media-width-value)
  ) !important;
}
.smallMediaMedia {
  position: absolute !important;
}
.smallMediaContentContainer {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.smallMediaContentContainer.smallMediaContentContainerTransparentHeader {
  padding-top: 70px !important;
}
.smallMediaContentWrapper {
  width: var(--find-play-hero-small-media-content-width) !important;
  align-self: center;
}
.smallMediaWrapper {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: flex-start !important;
}

@media (min-width: 1024px) {
  .smallMediaWrapper.smallMediaWrapperReversed {
    flex-direction: row !important;
  }
  .smallMediaWrapper.smallMediaWrapperReversed .smallMediaContentWrapper {
    padding-left: var(--find-play-container-padding) !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 1250px) {
  .smallMediaAspectPadding {
    --find-play-hero-small-media-content-width: 50%;
    --find-play-hero-small-media-media-width: 50%;
    --find-play-hero-small-media-media-width-value: 0.5;
  }
}
@media (max-width: 1024px) {
  .smallMediaWrapper {
    display: flex !important;
    position: relative !important;
    flex-direction: column-reverse !important;
  }
  .smallMediaImgWrapper {
    display: block !important;
    position: relative !important;
    width: 100% !important;
    right: 0 !important;
    padding-bottom: var(--find-play-hero-small-media-media-aspect-ratio) !important;
    margin-top: 25px !important;
  }
  .smallMediaContentWrapper {
    position: relative !important;
    width: 100% !important;
  }
  .smallMediaAspectPadding {
    padding: 0 !important;
  }
  .smallMediaContentContainer {
    padding-top: 70px !important;
    padding-right: 0 !important;
  }
}

.aspectPadding {
  padding-top: 133.333%;
}
/* .aspectPadding.nonTransparentHeader {
  padding-top: calc(133.333% - var(--topbarHeight, 60px));
} */

@media (min-width: 744px) {
  .aspectPadding {
    padding-top: 56.25%;
  }
  .aspectPadding.nonTransparentHeader {
    padding-top: calc(56.25% - var(--topbarHeightDesktop, 72px));
  }
}

@media (min-width: 1128px) {
  .aspectPadding {
    padding-top: 37.5%;
    padding-top: max(550px, min(85vh, 56.25%));
  }
  .aspectPadding.nonTransparentHeader {
    padding-top: calc(max(550px, min(85vh, 56.25%)) - var(--topbarHeightDesktop, 72px));
  }
}

.imgWrapper {
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
  width: 100%;
  min-height: 1px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.fit {
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 1760px;
  padding-top: var(--topbarHeight);
  padding-bottom: 1.5rem;
  position: relative;
  margin: 0px auto;

  @media (--viewportSmall) {
    padding-top: var(--topbarHeightDesktop);
  }
}

.video {
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  background: black;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
