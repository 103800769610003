import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import ButtonLight from '@/components/Button/ButtonLight';
import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';
import Form from '@/components/Form/Form';
import NamedLink from '@/components/NamedLink/NamedLink';

import * as validators from '@/util/validators';
import { FormattedMessage, injectIntl, intlShape } from '@/util/reactIntl';

import * as css from './LoginForm.module.css';

export function LoginFormFields(params) {
  const { formId, intl } = params;

  // email
  const emailLabel = intl.formatMessage({
    id: 'LoginForm.emailLabel',
  });
  const emailRequiredMessage = intl.formatMessage({
    id: 'LoginForm.emailRequired',
  });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'LoginForm.emailInvalid',
  });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  // password
  const passwordLabel = intl.formatMessage({
    id: 'LoginForm.passwordLabel',
  });
  // const passwordPlaceholder = intl.formatMessage({
  //   id: 'LoginForm.passwordPlaceholder',
  // });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'LoginForm.passwordRequired',
  });
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

  return (
    <>
      <FieldTextInput
        type="email"
        id={formId ? `${formId}.email` : 'email'}
        name="email"
        autoComplete="email"
        label={emailLabel}
        data-test="email-input"
        validate={validators.composeValidators(emailRequired, emailValid)}
      />
      <FieldTextInput
        className={css.password}
        type="password"
        id={formId ? `${formId}.password` : 'password'}
        name="password"
        data-test="password-input"
        autoComplete="current-password"
        label={passwordLabel}
        // placeholder={passwordPlaceholder}
        validate={passwordRequired}
      />
    </>
  );
}

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, formId, handleSubmit, inProgress, intl, invalid } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <LoginFormFields intl={intl} formId={formId} />
          </div>

          <div className="mt-14">
            <div className="flex flex-row">
              <div className="flex-1"></div>
              <p className="mb-2 text-xs text-gray-500">
                <NamedLink name="PasswordRecoveryPage" className="hover:text-accent text-gray-600 hover:underline">
                  Forgot Password?
                </NamedLink>
              </p>
            </div>

            <ButtonLight
              data-test="login-submit"
              type="submit"
              size="2xl"
              fullWidthCenterText
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="LoginForm.logIn" />
            </ButtonLight>
          </div>

          {/* <div className={css.bottomWrapper}>
            <p className={`${css.bottomWrapperText} mb-2`}>
              <span className="m-0 text-gray-500 text-sm">
                <FormattedMessage id="LoginForm.forgotPasswordInfo" values={{ passwordRecoveryLink }} />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
          </div> */}
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
