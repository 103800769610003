/**
 * Export loadData calls from ducks modules of different containers
 */
import { CalendarPageLoadData } from './CalendarPage/CalendarPageLoadData';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { SearchPageLoadData } from './SearchPage/SearchPageLoadData.js';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    CalendarPage: {
      loadData: CalendarPageLoadData,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoadData,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
