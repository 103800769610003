import { createRoot } from 'react-dom/client';

import { authInfo } from '@/ducks/Auth.duck';
import { getMarketplaceInfo } from '@/ducks/Marketplace.duck';
import { fetchCurrentUser } from '@/ducks/user.duck';

import preflight from '@/util/appPreflight';
import { error as logError } from '@/util/log';

import { App } from './app';

// 🚀
(async () => {
  try {
    // App boot up requirements
    // 🕸 💾 📡 ℹ️ 🎨 💿
    const { store, state } = preflight();

    // ✈️ Get marketplace data (blocking request)
    if (!state?.Marketplace?.id) await store.dispatch(getMarketplaceInfo(true));

    // 🔐 Get the users auth data (blocking request)
    if (!state?.Auth?.authInfoLoaded) await store.dispatch(authInfo());

    // 🙋‍♂️ Fetch user data, do this async and start rendering the app
    const currentUser = store.dispatch(fetchCurrentUser()) as unknown as Mpc.account;

    // 🔋 Render the <App> to the #root div located in index.html
    createRoot(document.getElementById('root') as HTMLElement).render(
      <App store={store} initialCurrentUserLocale={currentUser?.metadata?.localization?.locale} />
    );
  } catch (error: any) {
    // TODO: Redirect to static .html page for unknown errors?
    logError(error, 'Render failed');
  }
})();
