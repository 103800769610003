import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { usePopper } from 'react-popper';

import Badge from '@/components/Badge/Badge';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import {
  computeDynamicHref,
  isProAccount,
  isProAccountTrialing,
  isProEnabledMarketplace,
  isProOnlyRoute,
  proAccountTrialDaysLeft,
} from '@/util/helpers';
import { ChevronDownIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import { ShieldCheckIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const ExternalOrInternalLink = ({ linkType, href, className, children, onClick = () => {} }) => {
  if (linkType === 'external') {
    return (
      <a onClick={onClick} target="_blank" href={href} rel="noopener noreferrer" className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link onClick={onClick} to={href} className={className}>
      {children}
    </Link>
  );
};

const getCallsToAction = config => {
  const { footerLinkActive, footerLinkActive2, footerLinkA, footerLinkB } = config || {};
  const getCallToAction = (active, data) =>
    active
      ? [
          {
            name: data.title,
            href: computeDynamicHref(data),
            icon: props => <SvgRenderer {...props} id={data.icon} />,
            linkType: data.linkType,
          },
        ]
      : [];
  const footerLinkAMaybe = getCallToAction(footerLinkActive, footerLinkA);
  const footerLinkBMaybe = getCallToAction(footerLinkActive2, footerLinkB);
  return [...footerLinkAMaybe, ...footerLinkBMaybe];
};

const getItems = (items, isProMarketplace) => {
  return items.map(i => {
    const href = computeDynamicHref(i);
    const pro = isProMarketplace && isProOnlyRoute(null, null, href);
    return {
      ...i,
      icon: props => <SvgRenderer {...props} id={i.icon} />,
      href,
      pro,
    };
  });
};

const closeMenu = () => {
  function eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
  const actionButton = document.querySelector('.customNavMenuButtonAction');
  eventFire(actionButton, 'click');
  actionButton.blur();
};

export const CustomNavContent = props => {
  const { config, currentUser, isAuthenticated, onClose } = props;
  const { customNav } = config || {};

  const isProMarketplace = isProEnabledMarketplace();
  const items = getItems(customNav, isProMarketplace);
  const callsToAction = getCallsToAction(config);

  const isPro = isProAccount(currentUser);
  const isTrial = isProAccountTrialing(currentUser);
  const trialDaysLeft = isTrial ? proAccountTrialDaysLeft(currentUser) : null;
  const proButtonTheme = isPro ? 'white' : 'primary';
  const proButtonText = isPro ? (isTrial ? `Pro Trial - ${trialDaysLeft} left` : 'Pro Subscription Active') : 'Go Pro';

  const hasCallToActions = callsToAction && callsToAction.length;
  const proSectionMaybe =
    isProMarketplace && isAuthenticated ? (
      <div className={`px-5 ${hasCallToActions ? 'pt-1' : 'pt-5'} bg-gray-50 pb-5 sm:px-8`}>
        <Link
          className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
          onClick={onClose || closeMenu}
          to="/account/pro"
          theme={proButtonTheme}
        >
          <ShieldCheckIcon className="h-6 w-6 flex-shrink-0 text-gray-400" />
          <span className="ml-3">{proButtonText}</span>
        </Link>
      </div>
    ) : null;

  return (
    <div className="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 dark:ring-gray-200">
      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
        {items.map(item =>
          (item.auth === true && !isAuthenticated) || (item.noAuth === true && isAuthenticated) ? null : (
            <ExternalOrInternalLink
              {...item}
              key={item.title}
              href={item.href}
              onClick={onClose || closeMenu}
              className="relative -m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
            >
              <item.icon className="text-accent-600 h-6 w-6 flex-shrink-0" aria-hidden="true" />
              <div className="ml-4">
                <p className="text-base font-medium text-gray-900">{item.title}</p>
                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
              </div>

              {item.pro ? <Badge className="absolute top-3 right-3" label="Pro" /> : null}
            </ExternalOrInternalLink>
          )
        )}
      </div>

      {callsToAction && callsToAction.length ? (
        <div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
          {callsToAction.map(item => (
            <div key={item.name} className="flow-root">
              <ExternalOrInternalLink
                {...item}
                href={item.href}
                onClick={onClose || closeMenu}
                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
              >
                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="ml-3">{item.name}</span>
              </ExternalOrInternalLink>
            </div>
          ))}
        </div>
      ) : null}

      {proSectionMaybe}
    </div>
  );
};

const TransitionMaybe = ({ children, dynamicAlign, open }) => {
  if (!dynamicAlign) {
    return (
      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        {children}
      </Transition>
    );
  }
  return children;
};

const CustomNavMenuButton = props => {
  const { config, isAuthenticated, transparentActive, currentUser, dynamicAlign = false } = props;
  const { customNavTitle, hideNavDropdown } = config || {};

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
  });
  const popperAttributesMaybe = dynamicAlign ? attributes.popper : {};

  if (hideNavDropdown) return null;
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              !transparentActive
                ? `${open ? 'text-gray-900' : 'text-gray-500'} ring-offset-2 hover:text-gray-900`
                : 'text-white ring-offset-1 ring-offset-transparent',
              `customNavMenuButtonAction ${
                open ? 'ring-2' : ''
              } ring-accent-500 group inline-flex items-center rounded-md text-base font-medium transition focus:outline-none active:ring-2`
            )}
          >
            <span>{customNavTitle}</span>
            <ChevronDownIcon
              className={classNames(
                !transparentActive
                  ? `${open ? 'rotate-180 text-gray-600' : 'text-gray-400'} group-hover:text-gray-500`
                  : 'text-white',
                'ml-2 h-5 w-5 transform transition-transform'
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <TransitionMaybe dynamicAlign={dynamicAlign} open={open}>
            <Popover.Panel
              static={!dynamicAlign}
              ref={setPopperElement}
              style={dynamicAlign ? styles.popper : null}
              {...popperAttributesMaybe}
              className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform rounded-lg px-2 shadow-lg sm:px-0"
            >
              <CustomNavContent config={config} currentUser={currentUser} isAuthenticated={isAuthenticated} />
            </Popover.Panel>
          </TransitionMaybe>
        </>
      )}
    </Popover>
  );
};

export default CustomNavMenuButton;
