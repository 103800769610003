@import '../../styles/customMediaQueries.css';

.root {
  @apply __marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply __marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply __marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply __marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply __marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply __marketplaceModalHelperText;
}
