@import '../../styles/customMediaQueries.css';

.carousel {
  display: flex;
  flex-direction: row;
}

.viewport {
  white-space: nowrap;
  width: 100%;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scroll-padding: var(--find-play-container-padding);

  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;

  @media (--viewportMedium) {
    scroll-padding: 0;
  }
}

@media (max-width: 600px) {
  .viewport > div:last-child {
    margin-right: var(--find-play-container-padding);
  }
}

.viewport::-webkit-scrollbar {
  display: none;
}

.viewport > * {
  display: inline-block;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
