import { setMarketplaceConfig, setMpcColorPalette, setProperty } from '@/ducks/Marketplace.duck';

import { getMarketplace } from '@/util/helpers';

// prettier-ignore
const rmDarkProps = (key, root) => [...Array(10)].map((_, i) => root.removeProperty(`--mpc-custom-color-${key}-${i ? i * 100 : 50}`));

export const setUiTheme = ({ brandingOptions, restore = false, removeExistingDarkMode = false }) => {
  if (!brandingOptions) return;
  const root = document.documentElement.style;

  if (restore) {
    try {
      root.removeProperty(`--mpc-custom-color-bg`);
      root.removeProperty(`--mpc-custom-color-white-bg`);
      root.removeProperty(`--mpc-custom-color-dark-bg`);
      root.removeProperty(`--mpc-custom-color-dark-white`);

      const marketplace = getMarketplace();
      const { primary, accent } = marketplace?.branding?.colorModes?.dark || {};

      if (!primary) rmDarkProps('dark-primary', root);
      if (!accent) rmDarkProps('dark-accent', root);

      setMarketplaceConfig(marketplace, true);
    } catch (err) {
      console.error('Error resetting theme', err);
    }

    return;
  }

  const { colors, dark, colorModes } = brandingOptions || {};
  const { primary, accent, bg } = colors || {};
  const { primary: primaryDark, accent: accentDark, bg: bgDark, white: whiteDark } = dark || colorModes?.dark || {};

  if (bg) {
    setProperty({ key: 'bg', hex: bg });
    setProperty({ key: 'white-bg', hex: bg });
  }

  if (bgDark) {
    setProperty({ key: 'dark-bg', hex: bgDark });
    if (!whiteDark) {
      setProperty({ key: 'dark-white', hex: bgDark });
    }
  }

  if (whiteDark) setProperty({ key: 'dark-white', hex: whiteDark });
  if (primary?.length) setMpcColorPalette({ key: 'primary', colors: primary });
  if (accent?.length) setMpcColorPalette({ key: 'accent', colors: accent });

  if (primaryDark?.length) {
    setMpcColorPalette({ key: 'dark-primary', colors: primaryDark });
  } else if (removeExistingDarkMode) {
    rmDarkProps('dark-primary', root);
  }

  if (accentDark?.length) {
    setMpcColorPalette({ key: 'dark-accent', colors: accentDark });
  } else if (removeExistingDarkMode) {
    rmDarkProps('dark-accent', root);
  }
};
