// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const SET_TRANSPARENT_HEADER = 'app/UI/SET_TRANSPARENT_HEADER';
export const SET_AUTH_MODAL_OPENED = 'app/UI/SET_AUTH_MODAL_OPENED';
export const SET_LARGE_SEARCH_BAR = 'app/UI/SET_LARGE_SEARCH_BAR';
export const SET_COLOR_MODE = 'app/UI/SET_COLOR_MODE';
export const SET_LOGO = 'app/UI/SET_LOGO';
export const SET_DARK_THEME_FORCED_FOR_ROUTE = 'app/UI/SET_DARK_THEME_FORCED_FOR_ROUTE';
export const SET_DARK_THEME_DISABLED_FOR_ROUTE = 'app/UI/SET_DARK_THEME_DISABLED_FOR_ROUTE';
export const SET_GOOGLE_MAPS_LOADED = 'app/UI/SET_GOOGLE_MAPS_LOADED';

// ================ Reducer ================ //

const colorMode = window.getInitialColorMode && window.getInitialColorMode();
const initialState = {
  authModalOpened: false,
  authModalSuccessAction: () => {},
  transparentHeader: false,
  darkThemeForcedForRoute: false,
  darkThemeDisabledForRoute: false,
  // The active color mode, will default to
  colorMode: colorMode || 'light',
  // theme is just color mode without a default value, this allows us to know if the user has set a color mode
  theme: colorMode,
  disableScrollRequests: [],
  largeDiscoverSearchActive: undefined,
  logo: undefined,
  googleMapsLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [...disableScrollRequests, { componentId, disableScrolling }];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case SET_COLOR_MODE: {
      const persistedColorPreference = window.localStorage.getItem('color-mode');
      const hasPersistedPreference = persistedColorPreference && typeof persistedColorPreference === 'string';
      return {
        ...state,
        colorMode: payload,
        theme: hasPersistedPreference ? payload : '',
      };
    }

    case SET_DARK_THEME_FORCED_FOR_ROUTE: {
      return {
        ...state,
        darkThemeForcedForRoute: payload,
      };
    }

    case SET_GOOGLE_MAPS_LOADED: {
      return {
        ...state,
        googleMapsLoaded: true,
      };
    }

    case SET_DARK_THEME_DISABLED_FOR_ROUTE: {
      return {
        ...state,
        darkThemeDisabledForRoute: payload,
      };
    }

    case SET_LOGO: {
      return {
        ...state,
        logo: payload,
      };
    }

    case SET_TRANSPARENT_HEADER: {
      return {
        ...state,
        transparentHeader: payload,
      };
    }

    case SET_AUTH_MODAL_OPENED: {
      return {
        ...state,
        authModalOpened: payload.value,
        authModalSuccessAction: payload.authModalSuccessAction,
      };
    }

    case SET_LARGE_SEARCH_BAR: {
      return {
        ...state,
        largeDiscoverSearchActive: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const manageTransparentHeader = value => ({
  type: SET_TRANSPARENT_HEADER,
  payload: value,
});

export const manageAuthModalOpened = (value, authModalSuccessAction) => ({
  type: SET_AUTH_MODAL_OPENED,
  payload: { value, authModalSuccessAction },
});

export const manageLargeSearchBar = value => ({
  type: SET_LARGE_SEARCH_BAR,
  payload: value,
});

export const manageColorMode = value => ({
  type: SET_COLOR_MODE,
  payload: value,
});

export const manageLogo = value => ({
  type: SET_LOGO,
  payload: value,
});

export const manageDarkThemeForcedForRoute = value => ({
  type: SET_DARK_THEME_FORCED_FOR_ROUTE,
  payload: value,
});

export const manageDarkThemeDisabledForRoute = value => ({
  type: SET_DARK_THEME_DISABLED_FOR_ROUTE,
  payload: value,
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.UI;
  return disableScrollRequests.some(r => r.disableScrolling);
};

// ================ Thunks ================ //

export const setTransparentHeader =
  (transparent, { right, left } = {}) =>
  dispatch => {
    let value = transparent;
    if (transparent && right) value = 'right';
    if (transparent && left) value = 'left';

    dispatch(manageTransparentHeader(value));
  };

export const setLargeSearchBar = val => dispatch => {
  dispatch(manageLargeSearchBar(val));
};

export const setColorMode = val => dispatch => {
  dispatch(manageColorMode(val));
};

export const setLogo = val => dispatch => {
  dispatch(manageLogo(val));
};

export const setDarkThemeForcedForRoute = val => dispatch => {
  dispatch(manageDarkThemeForcedForRoute(val));
};

export const setDarkThemeDisabledForRoute = val => dispatch => {
  dispatch(manageDarkThemeDisabledForRoute(val));
};

export const setAuthModalOpened =
  (opened, authModalSuccessAction = () => {}) =>
  dispatch => {
    dispatch(manageAuthModalOpened(opened, authModalSuccessAction));
  };
