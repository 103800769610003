import { currencyConfiguration } from './util/currency-config';

import * as custom from './marketplace-custom-config';

const { hostname, port } = window?.location || {};
const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';
const localCi = hostname === 'localhost' && process.env.CI; // localhost is running in CI mode
const cypressCi = process.env.CI && port === '8811'; // port 8811 is assumed to be cypress running on our CI
const test = process.env.REACT_APP_ENV === 'test' || process.env.NODE_ENV === 'test' || cypressCi || localCi;
const devServer = process.env.REACT_APP_ENV_SERVER === 'development' || process.env.REACT_APP_ENV_SERVER === 'local';
const version = process.env.REACT_APP_PLAYKIT_RELEASE_VERSION || 'v0.0.1';
const versionTimestamp = process.env.REACT_APP_PLAYKIT_RELEASE_DATE || +new Date();
const local = false; // (hostname === 'localhost' && !process.env.CI) || process.env.REACT_APP_ENV_SERVER === 'local';

// This "ID", is a fallback for when the new marketplace ID
// The true marketplace ID is not known until the API responds
const marketplaceId = '00000000-0000-0000-0000-000000000000';
const localStorageKey = '__playkit_token__';
const roles = [
  {
    label: 'User',
    id: 'user',
  },
  {
    label: 'Marketplace Admin',
    id: 'marketplaceAdmin',
  },
  {
    label: 'Marketplace CMS Admin',
    id: 'marketplaceCmsAdmin',
  },
];

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en';
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 0,
};

// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'preauth-unit-time-booking/release-1';

// TODO: remove `bookingUnitType`, this is not used for anything.
// It is an old sharetribe value
const bookingUnitType = 'line-item/units';

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 90;

// SDK Conifg | MPC API Config
const sdkClientId = 'mpc-frontend';
const sdkBaseUrl = process.env.REACT_APP_MPC_API_BASE_URL;

// This is required due to the API, there is no need to keep these in ENV as they are not applicable to the actual requested marketplace
// They are only applicable to the initial API request that allow us to gather data on the marketplace being viewed
// It should be removed when this API issue is closed: https://github.com/playkit-io/marketplace-backend/issues/164
export const sdkConfig = {
  production: {
    // marketplaceId: 'a73a2dea-2fd7-41e0-b5ed-7c3e94d4c621',
    // apiKey: 'ApiKey_xjh2223989skhjdsh439898923x',
    marketplaceId: 'afaa1705-c446-4c00-a94e-e3f6e13d80d3',
    apiKey: 'APIKey_RD7Q29P-TDTMV3W-H0MS8XA-E5YJ7MF',
  },
  development: {
    marketplaceId: 'afaa1705-c446-4c00-a94e-e3f6e13d80d3',
    apiKey: 'APIKey_RD7Q29P-TDTMV3W-H0MS8XA-E5YJ7MF',
  },
  local: {
    marketplaceId: process.env.REACT_APP_LOCAL_MPC_API_MARKETPLACE_ID,
    apiKey: process.env.REACT_APP_LOCAL_MPC_API_KEY,
  },
};
const envSdkConfig = devServer || test ? (local ? sdkConfig.local : sdkConfig.development) : sdkConfig.production;

// This value is a placeholder until the API loads
const sdkMarketplaceId = envSdkConfig.marketplaceId;
// This value is a placeholder until the API loads
const sdkApiKey = envSdkConfig.apiKey;

const currency = 'AUD'; // Default to AUD, this can be changed in settings
// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 0;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'AU';
const addressRegion = 'Sydney';
const postalCode = '2000';
const streetAddress = '';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = hostname === 'localhost' ? 'http://localhost:3000' : `https://${hostname}`;

// Site title is needed in meta tags (bots and social media sharing reads those)
// This should be set dynamically
const siteTitle = '';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = null;
// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = null;
// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = null;
// Facebook counts shares with app or page associated by th is id.
// TODO: Allow this to be dynamically set per marketplace in config. https://developers.facebook.com/docs/sharing/webmasters#basic
const facebookAppId = null;

const places = {
  types: {
    accounting: 'Accounting Offices',
    airport: 'Airports',
    amusement_park: 'Amusement Parks',
    aquarium: 'Aquariums',
    art_gallery: 'Art Galleries',
    atm: "ATM's",
    bakery: 'Bakeries',
    bank: 'Banks',
    bar: 'Bars',
    beauty_salon: 'Beauty Salons',
    bicycle_store: 'Bicycle Stores',
    book_store: 'Book Stores',
    bowling_alley: 'Bowling Alleys',
    bus_station: 'Bus Stations',
    cafe: 'Cafes',
    campground: 'Campgrounds',
    car_dealer: 'Car Dealers',
    car_rental: 'Car Rentals',
    car_repair: 'Car Repairs',
    car_wash: 'Car Washes',
    casino: 'Casinos',
    cemetery: 'Cemetery',
    church: 'Churches',
    city_hall: 'City Halls',
    clothing_store: 'Clothing Stores',
    convenience_store: 'Convenience Stores',
    courthouse: 'Courthouses',
    dentist: 'Dentists',
    department_store: 'Department Stores',
    doctor: 'Doctors',
    drugstore: 'Drugstores',
    electrician: 'Electricians',
    electronics_store: 'Electronics Stores',
    embassy: 'Embassies',
    fire_station: 'Fire Stations',
    florist: 'Florists',
    funeral_home: 'Funeral Homes',
    furniture_store: 'Furniture Stores',
    gas_station: 'Gas Stations',
    gym: 'Gyms',
    hair_care: 'Hair Care',
    hardware_store: 'Hardware Stores',
    hindu_temple: 'Hindu Temples',
    home_goods_store: 'Home Goods Stores',
    hospital: 'Hospitals',
    insurance_agency: 'Insurance Agencies',
    jewelry_store: 'Jewelry Store',
    laundry: 'Laundry Stores',
    lawyer: 'Lawyers',
    library: 'Libraries',
    light_rail_station: 'Light Rail Stations',
    liquor_store: 'Liquor Stores',
    local_government_office: 'Local Government Offices',
    locksmith: 'Locksmiths',
    lodging: 'Lodging',
    meal_delivery: 'Meal Delivery',
    meal_takeaway: 'Meal Takeaway',
    mosque: 'Mosques',
    movie_rental: 'Movie Rentals',
    movie_theater: 'Movie Theaters',
    moving_company: 'Moving Companies',
    museum: 'Museums',
    night_club: 'Night Clubs',
    painter: 'Painters',
    park: 'Parks',
    parking: 'Parking',
    pet_store: 'Pet Stores',
    pharmacy: 'Pharmacies',
    physiotherapist: 'Physiotherapists',
    plumber: 'Plumbers',
    police: 'Police Offices',
    post_office: 'Post Offices',
    primary_school: 'Primary Schools',
    real_estate_agency: 'Real Estate Agencies',
    restaurant: 'Restaurants',
    roofing_contractor: 'Roofing Contractors',
    rv_park: 'Rv Parks',
    school: 'Schools',
    secondary_school: 'Secondary Schools',
    shoe_store: 'Shoe Stores',
    shopping_mall: 'Shopping Malls',
    spa: 'Spas',
    stadium: 'Stadiums',
    storage: 'Storage',
    store: 'Stores',
    subway_station: 'Subway Stations',
    supermarket: 'Supermarkets',
    synagogue: 'Synagogues',
    taxi_stand: 'Taxi Stands',
    tourist_attraction: 'Tourist Attractions',
    train_station: 'Train Stations',
    transit_station: 'Transit Stations',
    travel_agency: 'Travel Agencies',
    university: 'Universities',
    veterinary_care: 'Veterinary Care',
    zoo: 'Zoos',
    // Table 2
    point_of_interest: 'Points of Interest',
    establishment: 'Establishments',
  },
  // Distance in meters for calculating the bounding box around the location
  locationBoundsDistance: 100000,
};

const maps = {
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: true,

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 1500,
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: false,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 13,

    // Color of the circle on the Map component.
    circleColor: '#c0392b',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 16,
    anchorY: 32,
  },
};

const testUser = {
  email: '',
  password: '',
};
if (test) {
  testUser.email = process.env.CYPRESS_TEST_USER_EMAIL || 'playkit-test-user@mailinator.com';
  testUser.password = process.env.CYPRESS_TEST_USER_PASSWORD || '123456789';
}

const defaultHostname = local ? 'localhost' : !devServer ? 'playkit.app' : 'play-lab.tech';

// NOTE: only expose configuration that should be visible in the client side, don't add any server secrets in this file.
const config = {
  localStorageKey,
  marketplaceId,
  testUser,
  test,
  env,
  local,
  dev,
  devServer,
  locale,
  bookingProcessAlias,
  bookingUnitType,
  dayCountAvailableForBooking,
  i18n,
  version,
  versionTimestamp,
  sdk: {
    clientId: sdkClientId,
    baseUrl: sdkBaseUrl,
    marketplaceId: sdkMarketplaceId,
    apiKey: sdkApiKey,
  },
  roles,
  sortSearchByDistance,
  currency,
  currencyConfig,
  listingMinimumPriceSubUnits,
  stripe: {},
  canonicalRootURL,
  defaultHostname,
  hostname,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  siteTitle,
  siteFacebookPage,
  siteInstagramPage,
  siteTwitterHandle,
  facebookAppId,
  sentryDsn,
  maps,
  places,
  abstractapi: {
    apiKey: '9433e725fb834cae906f1e1e8d3d2c70',
  },
  custom,
};

export default config;
