import Img from '@/components/Img/Img';

const ImgLogo = ({
  data,
  portraitClassName = '',
  landcapeClassName = '',
  transformationType = 'md',
  className = 'w-16',
  imgProps = {},
}) => {
  if (!data) return null;
  const { name: id, width, height } = data.metadata || {};
  const aspectRatio = (height / width) * 100;

  const isLandscape = width > height;
  return (
    <div className={`${className} ${isLandscape ? landcapeClassName : portraitClassName}`}>
      <div style={{ paddingBottom: aspectRatio + '%' }} className="relative w-full">
        <Img key={id} transformationType={transformationType} id={id} {...imgProps} />
      </div>
    </div>
  );
};

export default ImgLogo;
