import { ComponentType } from 'react';

import useIcons from '@/hooks/useIcons';

const getHeroIconName = (name = '') => {
  if (!name) return '';
  if (name.includes('Icon')) return name.replace('Icon', '');
  // @ts-ignore
  const iconName = heroiconsV1toV2[name] || name;
  return iconName;
};

const LoadingIcon = (props: { className?: string }) => (
  <span className={`${props?.className} bg-gray-50 rounded-full opacity-25`} />
);
// In the future this may be a seperate style from the loading icon
const EmptyIcon = LoadingIcon;

const heroiconsV1toV2 = {
  Adjustments: 'AdjustmentsVertical',
  Annotation: 'ChatBubbleBottomCenterText',
  Archive: 'ArchiveBox',
  ArrowCircleDown: 'ArrowDownCircle',
  ArrowCircleLeft: 'ArrowLeftCircle',
  ArrowCircleRight: 'ArrowRightCircle',
  ArrowCircleUp: 'ArrowUpCircle',
  ArrowNarrowDown: 'ArrowLongDown',
  ArrowNarrowLeft: 'ArrowLongLeft',
  ArrowNarrowRight: 'ArrowLongRight',
  ArrowNarrowUp: 'ArrowLongUp',
  ArrowsExpand: 'ArrowsPointingOut',
  ArrowSmDown: 'ArrowSmallDown',
  ArrowSmLeft: 'ArrowSmallLeft',
  ArrowSmRight: 'ArrowSmallRight',
  ArrowSmUp: 'ArrowSmallUp',
  BadgeCheck: 'CheckBadge',
  Ban: 'NoSymbol',
  BookmarkAlt: 'BookmarkSquare',
  Cash: 'Banknotes',
  ChartSquareBar: 'ChartBarSquare',
  ChatAlt2: 'ChatBubbleLeftRight',
  ChatAlt: 'ChatBubbleLeftEllipsis',
  Chat: 'ChatBubbleOvalLeftEllipsis',
  Chip: 'CpuChip',
  ClipboardCheck: 'ClipboardDocumentCheck',
  ClipboardCopy: 'ClipboardDocument',
  ClipboardList: 'ClipboardDocumentList',
  CloudDownload: 'CloudArrowDown',
  CloudUpload: 'CloudArrowUp',
  Code: 'CodeBracket',
  Collection: 'RectangleStack',
  ColorSwatch: 'Swatch',
  CursorClick: 'CursorArrowRays',
  Database: 'CircleStack',
  DesktopComputer: 'ComputerDesktop',
  DeviceMobile: 'DevicePhoneMobile',
  DocumentAdd: 'DocumentPlus',
  DocumentDownload: 'DocumentArrowDown',
  DocumentRemove: 'DocumentMinus',
  DocumentReport: 'DocumentChartBar',
  DocumentSearch: 'DocumentMagnifyingGlass',
  DotsCircleHorizontal: 'EllipsisHorizontalCircle',
  DotsHorizontal: 'EllipsisHorizontal',
  DotsVertical: 'EllipsisVertical',
  Download: 'ArrowDownTray',
  Duplicate: 'Square2Stack',
  EmojiHappy: 'FaceSmile',
  EmojiSad: 'FaceFrown',
  Exclamation: 'ExclamationTriangle',
  ExternalLink: 'ArrowTopRightOnSquare',
  EyeOff: 'EyeSlash',
  FastForward: 'Forward',
  Filter: 'Funnel',
  FolderAdd: 'FolderPlus',
  FolderDownload: 'FolderArrowDown',
  FolderRemove: 'FolderMinus',
  Globe: 'GlobeAmericas',
  Hand: 'HandRaised',
  InboxIn: 'InboxArrowDown',
  Library: 'BuildingLibrary',
  LightningBolt: 'Bolt',
  LocationMarker: 'MapPin',
  Login: 'ArrowLeftOnRectangle',
  Logout: 'ArrowRightOnRectangle',
  Mail: 'Envelope',
  MailOpen: 'EnvelopeOpen',
  MenuAlt1: 'Bars3CenterLeft',
  MenuAlt2: 'Bars3BottomLeft',
  MenuAlt3: 'Bars3BottomRight',
  MenuAlt4: 'Bars2',
  Menu: 'Bars3',
  MinusSm: 'MinusSmall',
  MusicNote: 'MusicalNote',
  OfficeBuilding: 'BuildingOffice',
  PencilAlt: 'PencilSquare',
  PhoneIncoming: 'PhoneArrowDownLeft',
  PhoneMissedCall: 'PhoneXMark',
  PhoneOutgoing: 'PhoneArrowUpRight',
  Photograph: 'Photo',
  PlusSm: 'PlusSmall',
  Puzzle: 'PuzzlePiece',
  Qrcode: 'QrCode',
  ReceiptTax: 'ReceiptPercent',
  Refresh: 'ArrowPath',
  Reply: 'ArrowUturnLeft',
  Rewind: 'Backward',
  SaveAs: 'ArrowDownOnSquareStack',
  Save: 'ArrowDownOnSquare',
  SearchCircle: 'MagnifyingGlassCircle',
  Search: 'MagnifyingGlass',
  Selector: 'ChevronUpDown',
  SortAscending: 'BarsArrowUp',
  SortDescending: 'BarsArrowDown',
  Speakerphone: 'Megaphone',
  StatusOffline: 'SignalSlash',
  StatusOnline: 'Signal',
  Support: 'Lifebuoy',
  SwitchHorizontal: 'ArrowsRightLeft',
  SwitchVertical: 'ArrowsUpDown',
  Table: 'TableCells',
  Template: 'RectangleGroup',
  Terminal: 'CommandLine',
  ThumbDown: 'HandThumbDown',
  ThumbUp: 'HandThumbUp',
  Translate: 'Language',
  TrendingDown: 'ArrowTrendingDown',
  TrendingUp: 'ArrowTrendingUp',
  Upload: 'ArrowUpTray',
  UserAdd: 'UserPlus',
  UserRemove: 'UserMinus',
  ViewBoards: 'ViewColumns',
  ViewGridAdd: 'SquaresPlus',
  ViewGrid: 'Squares2x2',
  ViewList: 'Bars4',
  VolumeOff: 'SpeakerXMark',
  VolumeUp: 'SpeakerWave',
  X: 'XMark',
  ZoomIn: 'MagnifyingGlassPlus',
  ZoomOut: 'MagnifyingGlassMinus',
};

const getIcon = ({
  icons,
  iconName: initialIconName,
  defaultIconName: initialDefaultIconName,
  loading = false,
}: {
  defaultIconName: string;
  icons: {
    [key: string]: ComponentType;
  };
  iconName: string;
  loading: boolean;
}): ComponentType => {
  if (!initialIconName && !initialDefaultIconName) return EmptyIcon;
  const iconName = getHeroIconName(initialIconName);
  const Icon = icons[`${iconName}Icon`];
  const DefaultIcon = icons[getHeroIconName(initialDefaultIconName) + 'Icon'] || LoadingIcon;
  const IconDefault = loading ? LoadingIcon : DefaultIcon;
  return Icon || IconDefault;
};

const DynamicIcon = ({ defaultIconName = 'QuestionMarkCircle', iconName = '', ...rest }) => {
  const icons = useIcons();
  const loading = !icons;
  const Icon = getIcon({
    icons,
    iconName,
    defaultIconName,
    loading,
  });
  return <Icon {...rest} />;
};

export default DynamicIcon;
