import { bool, func, number, object, string } from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import {
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CircleStackIcon,
  CogIcon,
  GlobeAltIcon,
  HeartIcon,
  LifebuoyIcon,
  StarIcon,
  UserGroupIcon,
} from '@tmpc/ui/dist/utils/icons/24/outline';

import LogoContainer from '@/containers/LogoContainer/LogoContainer';

import Avatar from '@/components/Avatar/Avatar';
import Badge from '@/components/Badge/Badge';
import ButtonLight from '@/components/Button/ButtonLight';
import CustomNavMenuButton, { CustomNavContent } from '@/components/CustomNavMenuButton/CustomNavMenuButton';
import Menu from '@/components/Menu/Menu';
import MenuContent from '@/components/MenuContent/MenuContent';
import MenuLabel from '@/components/MenuLabel/MenuLabel';
import ThemeToggleButton from '@/components/ThemeToggle/ThemeToggleButton';
import ThemeToggleDialog from '@/components/ThemeToggle/ThemeToggleDialog';

import TopbarSearchForm from '@/forms/TopbarSearchForm/TopbarSearchForm';

import { auth } from '@/clients';

import {
  getMarketplace,
  getProSubscriptionTrialDays,
  isCrm,
  isProAccount,
  isProAccountTrialing,
  isProEnabledMarketplace,
  isTrialEnabledMarketplace,
  label,
  proAccountTrialDaysLeft,
} from '@/util/helpers';
import { intlShape } from '@/util/reactIntl';
import { propTypes } from '@/util/types/propTypes';

import * as css from './TopbarDesktop.module.css';

export const AnimtedDot = ({ positionClassName = 'top-0 right-0', className = 'h-3 w-3', ringClassName = 'ring-2' }) => {
  return (
    <span className={`pointer-events-none absolute ${positionClassName} -mt-1 -mr-1 flex ${className}`}>
      <span className={`relative inline-flex ${className} bg-accent-500 rounded-full ${ringClassName} ring-white`}></span>
      <span className="bg-accent-400 absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
    </span>
  );
};

const MessagingIconMaybe = ({ navConfig, currentUser, isAuthenticated, customNavActive }) => {
  const { showMessagingDesktop } = navConfig || {};
  if (!isAuthenticated || !showMessagingDesktop) return null;

  const unreadMessages = currentUser?._notifications?.unreadMessages;
  const href = currentUser?.type === 'provider' ? '/messages' : '/account/messages';
  return (
    <div>
      <div className={`flex ${customNavActive ? '-ml-5' : 'ml-2'} -mr-1`}>
        <Link
          to={href}
          className={`focus:ring-accent-500 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <span className="sr-only">View messages</span>
          <div className="relative">
            <ChatBubbleLeftEllipsisIcon className="h-6 w-6" aria-hidden="true" />
            {unreadMessages ? <AnimtedDot positionClassName="top-1 right-1" ringClassName="ring-1" className="h-2 w-2" /> : null}
          </div>
        </Link>
      </div>
    </div>
  );
};

const renderListItem = props => {
  const {
    title,
    icon,
    path,
    authenticated,
    closeListingsMenu,
    pro,
    isAuthenticated,
    isButton = false,
    onClick,
    textClassName = '',
    current,
    badge,
    id,
  } = props;
  if (authenticated && !isAuthenticated) return null;

  const itemClassName = `group flex items-center text-base px-4 py-3 sm:py-2 sm:text-sm ${
    current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
  } font-medium focus:outline-none focus:bg-gray-100 focus:text-gray-900`;
  const menuIconClass = `${
    current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'
  } mr-3 h-6 w-6 group-focus:text-gray-500`;

  const content = (
    <>
      {icon ? icon({ className: menuIconClass }) : null}
      <div className={`flex flex-1 ${textClassName}`}>{title}</div>
      {pro ? <Badge label="pro" color="primary" /> : null}
      {badge ? <Badge label={badge} color="accent" /> : null}
      <ChevronRightIcon className="inline h-5 w-5 text-gray-700 sm:hidden" />
    </>
  );

  const addListingButtonMaybe =
    path === '/listings' ? (
      <ButtonLight
        onClick={() => closeListingsMenu()}
        href="/l/new"
        positionClassName="absolute"
        className="absolute top-2 right-10 z-10 sm:right-4 sm:h-6"
        size="xs"
      >
        New {label('Listing')}
      </ButtonLight>
    ) : null;

  const viewWebsiteButtonMaybe =
    path === '/website' ? (
      <ButtonLight
        onClick={() => closeListingsMenu()}
        href={`/u/${auth.getAccountId()}`}
        positionClassName="absolute"
        className="absolute top-2 right-10 z-10 sm:right-4 sm:h-6"
        size="xs"
      >
        View
      </ButtonLight>
    ) : null;

  if (isButton) {
    return (
      <div key={`_${path}_${title}`} className="relative">
        <button id={id} className={`${itemClassName} w-full`} role="menuitem" onClick={onClick}>
          {content}
        </button>
        {addListingButtonMaybe}
        {viewWebsiteButtonMaybe}
      </div>
    );
  }
  return (
    <div key={`_${path}_${title}`} className="relative">
      <Link id={id} to={path} className={itemClassName} role="menuitem" onClick={closeListingsMenu}>
        {content}
      </Link>
      {addListingButtonMaybe}
      {viewWebsiteButtonMaybe}
    </div>
  );
};

export const ProfileMenu = props => {
  const {
    onLogout,
    currentUser,
    className = ' ',
    isAuthenticated,
    contentClassName,
    fullWidth,
    avatar,
    labelClassName,
    withBackdrop,
    contentWrapperClassName,
    avatarSizeClassName = 'h-8 w-8',
    noRing = false,
    transparentActive = false,
    profileMenuContent,
    roundedClassName = 'sm:rounded-full',
    hideSignedInAs = false,
    menuContentMarginTopClassName = 'mt-2',
    children,
    marketplaceOff = false,
    showChangeTheme = false,
    isMobile = false,
  } = props;
  const [themeModalOpen, setThemeModalOpen] = useState(false);

  const profileMenuRef = createRef();
  const closeProfileMenu = () => {
    profileMenuRef.current.toggleOpen();
  };

  const avatarComponent = avatar || (
    <>
      {isAuthenticated ? (
        <Avatar className={avatarSizeClassName} user={currentUser} disableProfileLink />
      ) : (
        <span className={`${avatarSizeClassName} block overflow-hidden rounded-full bg-gray-100`}>
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </>
  );

  const profileMenuLabelClassName = labelClassName || '';
  const profileMenuContentClassName = contentClassName || 'rounded-md ring-1 ring-black ring-opacity-5 dark:ring-gray-200';
  const profileMenuContentWrapperClassName = contentWrapperClassName || 'rounded-md bg-white shadow-lg';
  const ringOffsetClassName = !transparentActive ? 'focus:ring-offset-2' : '';
  const ringClassName = noRing ? '' : `focus:ring-2 focus:ring-accent-500 ${ringOffsetClassName}`;

  const isPro = isProAccount(currentUser);
  const isMarketplaceAdmin = currentUser && currentUser.roles && currentUser.roles.includes('marketplaceAdmin');
  const isMicroMarketplaceAdmin = currentUser?.roles?.includes('microMarketplaceAdmin');
  const isMarketplaceCmsAdmin = currentUser && currentUser.roles && currentUser.roles.includes('marketplaceCmsAdmin');
  const isPlatformAdmin = currentUser && currentUser.roles && currentUser.roles.includes('platformAdmin');
  const isCrmUser = currentUser && currentUser.roles && currentUser.roles.includes('crmUser');
  const crm = isCrm();
  const shouldRenderDialog = !isMobile || (isMobile && themeModalOpen);
  const marketplace = getMarketplace();
  const microMarketplaceId = marketplace?.microMarketplace?.id;

  const adminConsoleMenuMaybe =
    !microMarketplaceId && isMarketplaceAdmin
      ? [
          {
            title: 'Admin Console',
            icon: CircleStackIcon,
            path: '/admin',
          },
        ]
      : [];

  const microMarketplaceAdminMenuMaybe = isMicroMarketplaceAdmin
    ? [
        {
          title: microMarketplaceId ? 'Marketplace Admin' : 'Micro Marketplaces',
          icon: CircleStackIcon,
          path: microMarketplaceId ? `/admin-micro/${microMarketplaceId}` : '/admin-micro',
        },
      ]
    : [];

  const adminCmsConsoleMenuMaybe = isMarketplaceCmsAdmin
    ? [
        {
          title: 'CMS',
          icon: CircleStackIcon,
          path: '/admin-cms',
        },
      ]
    : [];
  const crmMaybe =
    crm && (isMarketplaceAdmin || isCrmUser)
      ? [
          {
            title: 'CRM',
            icon: UserGroupIcon,
            path: '/crm',
          },
        ]
      : [];
  const platformConsoleMenuMaybe = isPlatformAdmin
    ? [
        {
          title: 'Platform Console',
          icon: StarIcon,
          path: '/cms',
        },
      ]
    : [];

  const savedMenuMaybe = !marketplaceOff
    ? [
        {
          title: 'Saved',
          icon: HeartIcon,
          path: '/saved',
        },
      ]
    : [];

  const minWidthClassName = profileMenuContent || css.profileMenuContent;

  const changeThemeItem = {
    title: 'Change Theme',
    textClassName: 'font-normal',
    isButton: true,
    icon: ThemeToggleButton,
    onClick: () => {
      setThemeModalOpen(true);
      closeProfileMenu();
    },
  };

  const dark = marketplace?.branding?.colorModes?.dark;

  const navConfig = marketplace?.pageConfig?.advanced?.navConfig;
  const { account } = navConfig || {};
  const { hidden } = account || {};
  const { roster: navAccountRosterHidden, website: navAccountWebsiteHidden } = hidden || {};

  const darkDisabled = dark?.disabled;
  const darkForced = dark?.force;
  const darkNotDisabledOrForced = !darkDisabled && !darkForced;
  const changeThemeMenuItemMaybe = darkNotDisabledOrForced && (showChangeTheme || !isAuthenticated) ? [changeThemeItem] : [];

  const myRosterMaybe = !navAccountRosterHidden
    ? [
        {
          title: 'My Roster',
          icon: CalendarIcon,
          path: '/calendar/orders',
        },
      ]
    : [];

  const myWebsiteMaybe = !navAccountWebsiteHidden
    ? [
        {
          title: `My ${label('Website')}`,
          icon: GlobeAltIcon,
          path: '/website',
        },
      ]
    : [];

  return (
    <>
      <Menu
        withBackdrop={withBackdrop}
        className={className}
        rootClassName={`focus:outline-none relative ${avatarSizeClassName}`}
        fullWidth={fullWidth}
        useArrow={false}
        ref={profileMenuRef}
      >
        <MenuLabel
          id="profileMenuButton"
          ariaLabel="Open Profile Menu"
          rootClassName=" "
          className={`${profileMenuLabelClassName} ${roundedClassName} focus:outline-none ${ringClassName} transition`}
        >
          {avatarComponent}
          {currentUser?._notifications?.unreadMessages && currentUser.type !== 'provider' ? <AnimtedDot /> : null}
        </MenuLabel>

        <MenuContent className={`${minWidthClassName} ${profileMenuContentWrapperClassName} ${menuContentMarginTopClassName}`}>
          <div
            className={`${minWidthClassName} ${profileMenuContentClassName} divide-y divide-gray-100`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {/* Logged in info */}
            {isAuthenticated && !hideSignedInAs ? (
              <>
                <div className="px-4 py-3">
                  <div className="flex flex-row">
                    <div className="flex-1 truncate">
                      <p className="flex-1 text-sm">Signed in as</p>
                      <p className="truncate text-sm font-medium text-gray-900">{currentUser && currentUser.email}</p>
                    </div>

                    <div className="flex flex-row items-start space-x-1">
                      {isPro ? (
                        <Link className="inline-flex" to="/account/pro" onClick={closeProfileMenu}>
                          <Badge color="primary" label="Pro" />
                        </Link>
                      ) : null}

                      {darkNotDisabledOrForced ? (
                        <ThemeToggleButton
                          onClick={() => {
                            closeProfileMenu();
                            setThemeModalOpen(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ) : children ? (
              <div className="cursor-pointer" onClick={closeProfileMenu}>
                {children}
              </div>
            ) : null}

            {/* Account Options */}
            <div className="py-1">
              {isAuthenticated ? (
                <>
                  {[
                    ...savedMenuMaybe,
                    {
                      title: 'Messages',
                      icon: ChatBubbleOvalLeftEllipsisIcon,
                      badge: currentUser?._notifications?.unreadMessages,
                      path: '/account/messages',
                    },
                    {
                      title: 'My Account',
                      icon: CogIcon,
                      path: '/account/settings',
                    },
                    ...myWebsiteMaybe,
                    ...myRosterMaybe,
                  ].map(props => renderListItem({ ...props, closeListingsMenu: closeProfileMenu, isAuthenticated }))}
                </>
              ) : (
                <>
                  {[
                    {
                      title: 'Sign Up',
                      path: '/signup',
                    },
                    {
                      title: 'Log In',
                      path: '/login',
                      textClassName: 'font-normal',
                    },
                  ].map(props => renderListItem({ ...props, closeListingsMenu: closeProfileMenu, isAuthenticated }))}
                </>
              )}
            </div>

            {isAuthenticated &&
            (isMarketplaceAdmin ||
              isMicroMarketplaceAdmin ||
              isPlatformAdmin ||
              isMarketplaceCmsAdmin ||
              changeThemeMenuItemMaybe?.length) ? (
              <div className="py-1">
                {[
                  ...adminConsoleMenuMaybe,
                  ...microMarketplaceAdminMenuMaybe,
                  ...adminCmsConsoleMenuMaybe,
                  ...crmMaybe,
                  ...platformConsoleMenuMaybe,
                  ...changeThemeMenuItemMaybe,
                ].map(props => renderListItem({ ...props, closeListingsMenu: closeProfileMenu, isAuthenticated }))}
              </div>
            ) : null}

            {isAuthenticated ? (
              <>
                <div className="py-1">
                  {[
                    {
                      title: 'Help & Support',
                      textClassName: 'ml-1 font-normal',
                      path: '/help',
                    },
                    {
                      id: 'LogOutMenuItem',
                      title: 'Log out',
                      textClassName: 'ml-1 font-normal',
                      isButton: true,
                      onClick: e => {
                        onLogout(e);
                        closeProfileMenu();
                      },
                    },
                  ].map(props => renderListItem({ ...props, closeListingsMenu: closeProfileMenu, isAuthenticated }))}
                </div>
              </>
            ) : (
              <div className="py-1">
                {[
                  {
                    icon: LifebuoyIcon,
                    title: 'Help & Support',
                    textClassName: 'font-normal',
                    path: '/help',
                  },
                  ...changeThemeMenuItemMaybe,
                ].map(props => renderListItem({ ...props, closeListingsMenu: closeProfileMenu, isAuthenticated }))}
              </div>
            )}
          </div>
        </MenuContent>
      </Menu>
      {shouldRenderDialog ? <ThemeToggleDialog open={themeModalOpen} setOpen={setThemeModalOpen} /> : null}
    </>
  );
};

export const MyListingsMenu = props => {
  const {
    withBackdrop,
    fullWidth,
    className,
    menuLabel,
    isAuthenticated,
    verticalOffset = 10,
    contentClassName,
    contentWrapperClassName,
    labelClassName,
    currentUser,
    customNavActive,
    customNavConfig,
    providerNavItems,
  } = props;

  const listingMenuRef = createRef();
  const closeListingsMenu = () => {
    listingMenuRef.current.toggleOpen();
  };

  const menuContentClassName = contentClassName || 'rounded-md bg-white ring-1 ring-black ring-opacity-5 dark:ring-gray-200';
  const menuContentWrapperClassName = contentWrapperClassName || 'rounded-md bg-white shadow-lg';

  const isProMarketplace = isProEnabledMarketplace();
  const isTrialSupported = isProMarketplace ? isTrialEnabledMarketplace() : false;
  const trialDays = isTrialSupported ? getProSubscriptionTrialDays() : 0;
  const isPro = isProAccount(currentUser);
  const isTrial = isProAccountTrialing(currentUser);
  const trialDaysLeft = isTrial ? proAccountTrialDaysLeft(currentUser) : null;
  const proButtonTheme = isPro ? 'white' : 'primary';
  const proButtonText = isPro
    ? isTrial
      ? `Pro Trial - ${trialDaysLeft} left`
      : 'Pro Subscription Active'
    : isTrialSupported
    ? `Start ${trialDays} Day Pro Trial`
    : 'Go Pro';
  const proButtonHref = '/account/pro';
  const proButtonOnClick = closeListingsMenu;
  const shouldRenderProSection = isProMarketplace;
  const proSectionMaybe = shouldRenderProSection ? (
    <div className="px-4 py-3 sm:py-2">
      <ButtonLight noShadow onClick={proButtonOnClick} href={proButtonHref} size="xs" theme={proButtonTheme} fullWidthCenterText>
        {proButtonText}
      </ButtonLight>
    </div>
  ) : null;

  let content = (
    <div
      className={`${css.profileMenuContent} ${menuContentClassName} divide-y divide-gray-100`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="py-1">
        {providerNavItems.map(props => renderListItem({ ...props, closeListingsMenu, isAuthenticated }))}
      </div>

      {proSectionMaybe}
    </div>
  );

  if (customNavActive && customNavConfig) {
    content = (
      <CustomNavContent
        onClose={closeListingsMenu}
        config={customNavConfig}
        currentUser={currentUser}
        isAuthenticated={isAuthenticated}
      />
    );
  }

  return (
    <Menu
      rootClassName="focus:outline-none relative"
      withBackdrop={withBackdrop}
      fullWidth={fullWidth}
      useArrow={false}
      ref={listingMenuRef}
      className={className}
    >
      <MenuLabel className={`${css.profileMenuLabel} ${!labelClassName ? 'ml-2' : labelClassName} focus:outline-none`}>
        {menuLabel}
        {currentUser?._notifications?.unreadMessages ? <AnimtedDot /> : null}
      </MenuLabel>

      <MenuContent verticalOffset={verticalOffset} className={`${css.profileMenuContent} ${menuContentWrapperClassName}`}>
        {content}
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    rootClassName,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    hideSearch,
    transparentActive,
    serachPlaceholder,
    shouldOnlyShowSearchOnScroll,
    hideSearchBarOnScrollClassNames,
    searchType,
    currentPath,
    currentPath4,
    marketplaceOff = false,
    whiteLabel = false,
    customNavActive,
    customNavConfig,
    providerNavItems,
    listing,
    isDiscoverAboutPage,
    isSidebarNavigationType,
    navConfig = {},
    theme,
  } = props;
  const [mounted, setMounted] = useState(false);
  const transparentActiveAndIsNotRightOnly = transparentActive && transparentActive !== 'right';
  const transparentActiveAndIsNotLeftOnly = transparentActive && transparentActive !== 'left';

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  let {
    discoverTitle,
    aboutTitle,
    aboutTitleHidden,
    unauthenticatedAvatarHidden,
    authenticatedStackedActions,
    unauthenticatedStackedActions,
    becomeAProviderMode,
    becomeAProviderModeConfig,
  } = navConfig || {};
  const { disableAdminLabel } = becomeAProviderModeConfig || {};

  const isCurrentUserAProvider = currentUser?.type === 'provider';
  if (becomeAProviderMode && isCurrentUserAProvider && disableAdminLabel) {
    becomeAProviderMode = false;
  }

  const customNavActiveClassName = customNavActive ? 'relative' : '';
  const classes = classNames(rootClassName || css.root, className, customNavActiveClassName);
  const search =
    !hideSearch && !marketplaceOff ? (
      <TopbarSearchForm
        maxWidth={currentPath === 's' ? '500px' : '320px'}
        placeholder={serachPlaceholder}
        className={`${css.searchLink} ml-5 ${shouldOnlyShowSearchOnScroll ? hideSearchBarOnScrollClassNames : ''}`}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        transparentActive={transparentActiveAndIsNotRightOnly}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        searchType={searchType}
      />
    ) : (
      <div className="flex-1"></div>
    );

  const isAccountProfile = currentPath === 'u';
  const isRosterView = currentPath === 'r';
  const isFormView = currentPath === 'f';
  const isListingProfile = currentPath === 'l' && !currentPath4;
  const isWhiteLabelPath = isAccountProfile || isListingProfile || isRosterView || isFormView;
  const whiteLabelPathActive = whiteLabel && isWhiteLabelPath;
  // const showCreateButtonOnCustomNavAlways = customNavActive && customNavConfig && customNavConfig.createListingWithAuth === true;
  // const hideCreateButtonOnCustomNavWhileUnauthenticated =
  //   !isAuthenticated && customNavActive && customNavConfig && customNavConfig.hideCreateListingWhileLoggedOut === true;

  const showCreateButtonWhileUnauthenticated = !isAuthenticated && !whiteLabelPathActive && !unauthenticatedStackedActions;
  const showCreateButtonWhileAuthenticated = isAuthenticated && authenticatedStackedActions === 'newListing';
  const showCreateButton = showCreateButtonWhileUnauthenticated || showCreateButtonWhileAuthenticated;
  const createButtonMaybe = showCreateButton ? (
    <ButtonLight href="/l/new" size="sm">
      <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      New Listing
    </ButtonLight>
  ) : null;

  const showLoginSingupButtonsWhileUnauthenticated =
    !isAuthenticated && !whiteLabelPathActive && unauthenticatedStackedActions === 'login-signup';
  const loginSingupButtonsMaybe = showLoginSingupButtonsWhileUnauthenticated ? (
    <div className="flex items-center">
      <ButtonLight theme="white" className="mr-2" href="/login" size="sm">
        Login
      </ButtonLight>

      <ButtonLight href="/signup" size="sm">
        Sign Up
      </ButtonLight>
    </div>
  ) : null;

  const shouldHideProfileMenu = unauthenticatedAvatarHidden && !isAuthenticated;
  const profileMenu = (
    <div className="z-10 flex items-center space-x-5">
      {createButtonMaybe}
      {loginSingupButtonsMaybe}
      {!shouldHideProfileMenu ? (
        <ProfileMenu
          transparentActive={transparentActiveAndIsNotLeftOnly}
          isAuthenticated={authenticatedOnClientSide}
          onLogout={onLogout}
          useArrow
          marketplaceOff={marketplaceOff}
          currentUser={currentUser}
        ></ProfileMenu>
      ) : null}
    </div>
  );

  const shouldHideLogo = whiteLabelPathActive;
  const logo = !shouldHideLogo ? (
    <Link className="z-10" to="/">
      <div className="sr-only">Home</div>
      <LogoContainer
        format="desktop"
        className={`block h-10 w-auto ${transparentActiveAndIsNotRightOnly ? 'text-white' : 'text-primary dark:text-white'}`}
      />
    </Link>
  ) : isListingProfile ? (
    <Avatar className="z-10 h-10 w-10" user={listing && listing.provider} />
  ) : null;

  const menuLinkClassNames = !transparentActiveAndIsNotLeftOnly
    ? 'text-gray-700 hover:bg-gray-50'
    : 'text-white hover:bg-white hover:bg-opacity-15';

  const listingsMenuLinkClassNames = !transparentActiveAndIsNotLeftOnly
    ? `${menuLinkClassNames} border-gray-300 focus:ring-offset-2`
    : `${menuLinkClassNames} border-gray-100 border-opacity-50 hover:border-opacity-100 focus:border-accent-500 hover:bg-white hover:bg-opacity-15 focus:bg-white focus:bg-opacity-15`;

  const navLinkClassName = !transparentActiveAndIsNotLeftOnly
    ? `${menuLinkClassNames} active:border-gray-300`
    : `${menuLinkClassNames} active:border-gray-100`;

  const providerNavCurrentPage = providerNavItems && providerNavItems.find(i => !!i.current);
  const currentPageTitle =
    providerNavCurrentPage && providerNavCurrentPage.title ? providerNavCurrentPage.title : `My ${label('Listings')}`;

  const roundedClassName = theme === 'playful' ? 'rounded-full' : 'rounded-md';
  const myListingsMenu = (
    <MyListingsMenu
      {...props}
      menuLabel={
        <div
          tabIndex="0"
          className={`${listingsMenuLinkClassNames} inline-flex items-center border px-3 py-2 text-sm font-medium leading-4 ${roundedClassName} focus:ring-accent-500 transition focus:outline-none focus:ring-2`}
        >
          {currentPageTitle}
          <ChevronDownIcon className={`-mr-1 h-4 w-4 ${transparentActiveAndIsNotLeftOnly ? 'text-white' : 'text-gray-700'}`} />
        </div>
      }
    />
  );

  const myListingsMenuMaybe = isAuthenticated && !customNavActive && !becomeAProviderMode ? myListingsMenu : null;
  const customNavMaybe = customNavActive ? (
    <CustomNavMenuButton
      dynamicAlign={!marketplaceOff}
      currentUser={currentUser}
      transparentActive={transparentActive}
      isAuthenticated={isAuthenticated}
      config={customNavConfig}
    />
  ) : null;

  const firstLinkTitle = !marketplaceOff ? 'Discover' : 'About';
  const firstLinkPath = !marketplaceOff ? '/' : isAuthenticated ? '/about' : '/';

  // deprecated
  // const { customDiscoverTitle, customAboutTitle } = customNavConfig || {};
  const discoverLabel = discoverTitle || firstLinkTitle;
  const aboutLabel = aboutTitle || 'About';

  const defaultNavLinkClassName = `${navLinkClassName} border border-transparent px-3 py-2 font-medium text-sm leading-4 rounded-md transition`;
  const customNavClassName = `text-base font-medium ${!transparentActive ? 'text-gray-500 hover:text-gray-900' : 'text-white'}`;

  const shouldHideFirstLink =
    whiteLabelPathActive || (marketplaceOff && (isDiscoverAboutPage || !currentPath) && isSidebarNavigationType);

  const providerOnboardingStep = currentUser?.metadata?.providerOnboardingStep;
  const providerOnboardingUrl = `/become-a-provider${providerOnboardingStep ? `/${providerOnboardingStep}` : ''}`;
  const navLinks = (
    <div
      className={
        customNavActive && marketplaceOff
          ? 'absolute inset-0 z-0 flex flex-1 items-center justify-center'
          : !shouldHideProfileMenu || unauthenticatedStackedActions
          ? customNavActive
            ? 'mr-6'
            : becomeAProviderMode
            ? 'mr-3'
            : 'mr-5'
          : ''
      }
    >
      <nav className={`flex flex-row items-center ${customNavActive ? 'space-x-10' : 'space-x-2'}`}>
        {/* Only show when white label path is not active */}
        {!shouldHideFirstLink ? (
          <Link to={firstLinkPath} className={customNavActive ? customNavClassName : defaultNavLinkClassName}>
            {discoverLabel}
          </Link>
        ) : null}

        {/* Hide the extra about link when marketplace is off */}
        {!marketplaceOff && !aboutTitleHidden ? (
          <Link to="/about" className={`${customNavActive ? customNavClassName : defaultNavLinkClassName} ml-3`}>
            {aboutLabel}
          </Link>
        ) : null}

        {customNavMaybe}

        {becomeAProviderMode ? (
          currentUser?.type === 'provider' ? (
            <Link to="/dashboard" className={`${customNavActive ? customNavClassName : defaultNavLinkClassName} ml-3`}>
              {becomeAProviderModeConfig?.adminLabel || 'Provider Admin'}
            </Link>
          ) : (
            <Link to={providerOnboardingUrl} className={`${customNavActive ? customNavClassName : defaultNavLinkClassName} ml-3`}>
              {becomeAProviderModeConfig?.becomeLabel || 'Become A Provider'}
            </Link>
          )
        ) : null}

        {myListingsMenuMaybe}

        <MessagingIconMaybe
          customNavActive={customNavActive}
          navConfig={navConfig}
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
        />
      </nav>
    </div>
  );

  return (
    <nav className={classes}>
      {logo}
      {search}
      {navLinks}
      {profileMenu}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
