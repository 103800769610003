/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import LogoContainer from '@/containers/LogoContainer/LogoContainer';

import Avatar from '@/components/Avatar/Avatar';
import ButtonLight from '@/components/Button/ButtonLight';
import { ProfileMenu } from '@/components/TopbarDesktop/TopbarDesktop';

import {
  getAccountName,
  getProSubscriptionTrialDays,
  isProAccount,
  isProAccountTrialing,
  isProEnabledMarketplace,
  isTrialEnabledMarketplace,
  proAccountTrialDaysLeft,
} from '@/util/helpers';
import {
  ArrowLeftOnRectangleIcon,
  ChevronUpDownIcon,
  InformationCircleIcon,
  LifebuoyIcon,
  Bars3Icon as MenuIcon,
  RectangleGroupIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@tmpc/ui/dist/utils/icons/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SidebarLayout({
  children,
  currentPath,
  currentUser,
  isAuthenticated,
  shouldHideNavigation,
  handleLogout,
  bgColor,
  isProfilePage,
  marketplaceOff,
  isRootDashboardPage,
  providerNavItems,
  navConfig,
  containerMarginTopClassName,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const maybeCloseDrawer = () => {
    if (!sidebarOpen) return;
    setSidebarOpen(false);
  };

  // const whiteLabel = get(marketplace, 'pageConfig.advanced.whiteLabel');

  let navigation = providerNavItems;

  const firstLinkTitle = !marketplaceOff ? 'Discover' : 'About';
  const firstLinkPath = !marketplaceOff ? '/' : isAuthenticated ? '/about' : '/';
  const firstLinkCurrent = !marketplaceOff ? currentPath === '' : isAuthenticated ? currentPath === 'about' : currentPath === '';

  const { discoverTitle, aboutTitle, aboutTitleHidden } = navConfig || {};
  const discoverLabel = discoverTitle || firstLinkTitle;
  const aboutLabel = aboutTitle || 'About';

  let secondaryNavigation = [{ title: discoverLabel, path: firstLinkPath, icon: RectangleGroupIcon, current: firstLinkCurrent }];
  if (!marketplaceOff && !aboutTitleHidden) {
    secondaryNavigation.push({
      title: aboutLabel,
      path: '/about',
      icon: InformationCircleIcon,
      current: currentPath === 'about',
    });
  }

  const hideSidebar = currentPath === 'admin' || isProfilePage;

  const Nav = ({ className = 'mt-3', isAuthenticated }) => {
    const isProMarketplace = isProEnabledMarketplace();
    const isTrialSupported = isProMarketplace ? isTrialEnabledMarketplace() : false;
    const trialDays = isTrialSupported ? getProSubscriptionTrialDays() : 0;
    const isPro = isProAccount(currentUser);
    const isTrial = isProAccountTrialing(currentUser);
    const trialDaysLeft = isTrial ? proAccountTrialDaysLeft(currentUser) : null;
    const proButtonTheme = isPro ? 'white' : 'primary';
    const proButtonText = isPro
      ? isTrial
        ? `Pro Trial - ${trialDaysLeft} left`
        : 'Pro Subscription Active'
      : isTrialSupported
      ? `Start ${trialDays} Day Pro Trial`
      : 'Go Pro';
    const proButtonHref = '/account/pro';
    const proButtonOnClick = maybeCloseDrawer;
    const shouldRenderProSection = isProMarketplace;
    const proSectionMaybe = shouldRenderProSection ? (
      <div className="px-3 pt-2">
        <ButtonLight
          noShadow
          onClick={proButtonOnClick}
          href={proButtonHref}
          size="xs"
          theme={proButtonTheme}
          fullWidthCenterText
        >
          {proButtonText}
        </ButtonLight>
      </div>
    ) : null;

    if (!isAuthenticated) {
      const loggedOutNavigation = [
        {
          title: 'Sign Up',
          path: '/signup',
          icon: UserPlusIcon,
        },
        {
          title: 'Log In',
          path: '/login',
          icon: ArrowLeftOnRectangleIcon,
          textClassName: 'font-normal',
        },
      ];
      navigation = loggedOutNavigation;

      if (secondaryNavigation.findIndex(n => n.path === '/help') === -1) {
        secondaryNavigation.push({
          title: 'Help & Support',
          path: '/help',
          icon: LifebuoyIcon,
          current: currentPath === 'help',
        });
      }
    }

    return (
      <nav className={`${className} flex flex-1 flex-col divide-y divide-gray-100`}>
        <div className="space-y-1 px-2">
          {navigation.map(item => (
            <Link
              key={item.title}
              to={item.path}
              onClick={maybeCloseDrawer}
              className={classNames(
                item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group flex items-center rounded-md px-2 py-2 text-base font-medium md:text-sm'
              )}
            >
              <item.icon
                className={classNames(
                  item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                  'mr-3 h-6 w-6 flex-shrink-0'
                )}
                aria-hidden="true"
              />

              <span className="flex-1">{item.title}</span>

              {item.pro ? (
                <span
                  className={classNames(
                    item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                    'ml-3 inline-block rounded-full py-0.5 px-3 text-xs font-medium'
                  )}
                >
                  Pro
                </span>
              ) : null}

              {item.badge ? (
                <span
                  className={classNames(
                    item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                    'ml-3 inline-block rounded-full py-0.5 px-3 text-xs font-medium'
                  )}
                >
                  {item.badge}
                </span>
              ) : null}
            </Link>
          ))}

          {proSectionMaybe}
        </div>

        <div className="mt-6 pt-6">
          <div className="space-y-1 px-2">
            {secondaryNavigation.map(item => (
              <Link
                key={item.title}
                to={item.path}
                onClick={maybeCloseDrawer}
                className={classNames(
                  item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center rounded-md px-2 py-2 text-base font-medium md:text-sm'
                )}
              >
                <item.icon
                  className={classNames(
                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 h-6 w-6 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </nav>
    );
  };

  const avatar = (
    <div className="relative inline-block w-full text-left">
      <div className="group w-full rounded-md bg-white px-2 py-2 text-left text-sm font-medium text-gray-700 transition hover:bg-gray-50">
        <span className="flex w-full items-center justify-between">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            <Avatar
              className="bg-accent-200 h-8 w-8 flex-shrink-0 rounded-full text-xs"
              initialsClassName="text-xs"
              user={currentUser}
              disableProfileLink
            />
            <span className="flex min-w-0 flex-1 flex-col truncate">
              <span className="truncate text-sm font-medium text-gray-900">{getAccountName(currentUser)}</span>
            </span>
          </span>
          <ChevronUpDownIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        </span>
      </div>
    </div>
  );

  let containerClassName =
    (currentPath === '' && !isRootDashboardPage) ||
    shouldHideNavigation ||
    currentPath === 'resource' ||
    !containerMarginTopClassName
      ? ''
      : 'py-4 sm:py-6';
  if (isProfilePage) {
    containerClassName = 'smContainer py-6 max-w-container mx-auto';
  }
  if (currentPath === 'calendar') containerClassName = 'pt-4 sm:pt-6';

  return (
    <div className={`flex h-screen overflow-hidden ${bgColor}`}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-40 flex md:hidden" open={sidebarOpen} onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:bg-gray-200/75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <Link to="/">
                    <LogoContainer className="text-primary h-8 w-auto dark:text-white" format="desktop" />
                  </Link>
                </div>

                <Nav className="mt-5" isAuthenticated={isAuthenticated} />
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}

      {!hideSidebar && !shouldHideNavigation ? (
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex w-64 flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div
              className={`flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4 ${
                currentPath !== 'messages' ? 'rounded-r-2xl' : ''
              }`}
            >
              {/* LOGO */}
              <div className="flex flex-shrink-0 items-center px-4">
                <Link to="/">
                  <LogoContainer className="text-primary h-8 w-auto dark:text-white" format="desktop" />
                </Link>
              </div>

              {isAuthenticated ? (
                <div className="mx-2 mt-3">
                  <ProfileMenu
                    marketplaceOff={marketplaceOff}
                    avatarSizeClassName=""
                    noRing
                    profileMenuContent=" "
                    labelClassName="w-full"
                    withBackdrop
                    onLogout={handleLogout}
                    hideSignedInAs
                    menuContentMarginTopClassName="mt-0"
                    contentWrapperClassName="rounded-md bg-white ring-1 ring-black dark:ring-gray-200 ring-opacity-5 top-0 w-[239px]"
                    avatar={avatar}
                    fullWidth
                    showChangeTheme
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                  >
                    {avatar}
                  </ProfileMenu>
                </div>
              ) : (
                <div className="mt-2"></div>
              )}

              <Nav isAuthenticated={isAuthenticated} />
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="z-10 flex items-center justify-between bg-white py-1 pr-4 pl-1 shadow-sm sm:py-3 sm:pl-3 sm:pr-6 md:hidden">
          <button
            className="focus:ring-primary-500 -ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          <ProfileMenu
            marketplaceOff={marketplaceOff}
            onLogout={handleLogout}
            roundedClassName="rounded-full"
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
          />
        </div>

        <main id="sidebarScrollContainer" className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
          <div className={containerClassName}>{children}</div>
        </main>
      </div>
    </div>
  );
}
