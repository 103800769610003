.transparentHeader::before {
  background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0)) !important;
  content: '' !important;
  height: 140% !important;
  left: 0px !important;
  pointer-events: none !important;
  position: absolute !important;
  top: 0px !important;
  width: 100% !important;
  z-index: -1 !important;
  -webkit-transition: 150ms opacity ease !important;
  -moz-transition: 150ms opacity ease !important;
  transition: 150ms opacity ease !important;
  opacity: 0.4 !important;
}
