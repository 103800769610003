import * as accounts from '@/clients/accounts.js';
import { currentUserShowSuccess } from '@/ducks/user.duck';

import { storableError } from '@/util/errors';
import { getLocationObject } from '@/util/helpers';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = error => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => dispatch => {
  dispatch(saveContactDetailsRequest());

  const {
    email,
    phoneNumber,
    dateOfBirth,
    gender,
    metadata,
    address,
    name,
    firstName,
    lastName,
    type,
    visibility,
    avatar,
    shouldRenderMultipleTextInputs,
    categories,
  } = params;
  const body = { avatar, type, visibility, email, phone: phoneNumber, metadata, categories };

  // Add names
  if (name) body.name = name.trim();
  if (shouldRenderMultipleTextInputs) {
    if (firstName) body.firstName = firstName;
    if (lastName) body.lastName = lastName;
  }

  if (dateOfBirth) {
    if (typeof dateOfBirth.getMonth === 'function') {
      body.dateOfBirth = `${dateOfBirth.getFullYear()}-${dateOfBirth.getMonth() + 1}-${dateOfBirth.getDate()}`;
    } else if (dateOfBirth.year) {
      body.dateOfBirth = `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`;
    }
  }
  if (gender) body.gender = gender;
  if (address && address.selectedPlace) {
    body.address = getLocationObject(address.selectedPlace);
  } else if (!address || !address.selectedPlace) {
    body.address = null;
  }

  return (
    accounts
      .update(body)
      .then(() => {
        return accounts.read().then(user => {
          // identify({ ...user.data, force: true });
          dispatch(currentUserShowSuccess(user.data));
          dispatch(saveContactDetailsSuccess());
        });
      })
      // error action dispatched in requestSaveEmail
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      })
  );
};
