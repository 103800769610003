import { Link } from 'react-router-dom';

import Avatar from '@/components/Avatar/Avatar';
import BottomNav from '@/components/BottomNav/BottomNav';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';
import { AnimtedDot, MyListingsMenu, ProfileMenu } from '@/components/TopbarDesktop/TopbarDesktop';

import { label } from '@/util/helpers';
import { pathByRouteName } from '@/util/routes';
import { PlusCircleIcon } from '@tmpc/ui/dist/utils/icons/20/solid';
import {
  ChatBubbleLeftEllipsisIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from '@tmpc/ui/dist/utils/icons/24/outline';

import config from '@/config';
import routeConfiguration from '@/routeConfiguration';

const BottomBar = props => {
  const {
    currentUser,
    providerNavItems,
    isAuthenticated,
    history,
    onLogout,
    currentPath,
    marketplaceOff,
    customNavActive,
    customNavConfig,
    navConfig,
  } = props;

  const iconClassName = 'h-6 w-6 noFill mb-1 text-current';
  const iconDefaultColorClassName = 'text-gray-900';

  const isProfileSelected = currentPath === 'account';
  const isMyListingsSelected = currentPath === 'listings';

  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const renderIconItem = ({ title, icon, href, isMenu, menu, selected, id }) => {
    const contents = (
      <>
        <div className={`flex flex-row justify-center ${!selected ? iconDefaultColorClassName : 'text-accent'}`}>{icon}</div>
        <div className={`text-sm font-semibold ${!selected ? 'text-gray-900 ' : 'text-accent'}`}>{title}</div>
      </>
    );

    const wrapperClassName = 'flex flex-col flex-1 text-center';

    if (href) {
      return (
        <Link id={id} className={wrapperClassName} to={href}>
          {contents}
        </Link>
      );
    }

    if (isMenu && menu) {
      return menu;
    }

    return <div className={wrapperClassName}>{contents}</div>;
  };

  const customNavTitle = customNavActive && customNavConfig ? customNavConfig.customNavTitle : null;
  const customNavIcon =
    customNavActive && customNavConfig && customNavConfig.mobileIcon
      ? props => <SvgRenderer {...props} id={customNavConfig.mobileIcon} />
      : null;
  let myListingsTitle = customNavTitle || label('Listings');
  const myListingsIcon = customNavIcon || PlusCircleIcon;

  const firstLinkTitle = !marketplaceOff ? 'Discover' : 'About';
  const firstLinkPath = !marketplaceOff ? '/' : isAuthenticated ? '/about' : '/';
  const firstLinkCurrent = !marketplaceOff ? currentPath === '' : isAuthenticated ? currentPath === 'about' : currentPath === '';

  const { hideNavDropdown } = customNavConfig || {};
  const {
    discoverTitle,
    showDiscoverMobileIcon,
    discvoerMobileIcon,
    showAboutMobileIcon,
    aboutMobileIcon,
    aboutTitle,
    unauthenticatedStackedActions,
    aboutTitleHidden,
    becomeAProviderMode,
    becomeAProviderModeConfig,
    showMessagingMobile,
  } = navConfig || {};

  const discoverLabel = discoverTitle || firstLinkTitle;
  const aboutLabel = aboutTitle || 'About';

  const customDiscoverIcon =
    showDiscoverMobileIcon && discvoerMobileIcon ? <SvgRenderer className={iconClassName} id={discvoerMobileIcon} /> : null;
  const discoverIcon = customDiscoverIcon || <MagnifyingGlassIcon className={iconClassName} />;

  const customAboutIcon =
    showAboutMobileIcon && aboutMobileIcon ? <SvgRenderer className={iconClassName} id={aboutMobileIcon} /> : null;
  const aboutIcon = customAboutIcon || <InformationCircleIcon className={iconClassName} />;

  const shouldRenderMessagingMenu = isAuthenticated && showMessagingMobile;
  const shouldRenderAboutMenu = !shouldRenderMessagingMenu && !marketplaceOff && !aboutTitleHidden;
  const showCreateButtonWhileUnauthenticated = !isAuthenticated && !unauthenticatedStackedActions;

  const isCurrentUserAProvider = currentUser?.type === 'provider';
  const { becomeLabel = 'Become A Provider', adminLabel = 'Provider Admin' } = becomeAProviderModeConfig || {};
  const providerOnboardingStep = currentUser?.metadata?.providerOnboardingStep;
  const providerOnboardingUrl = `/become-a-provider${providerOnboardingStep ? `/${providerOnboardingStep}` : ''}`;

  const shouldRenderMyListingsMenuWhenBecomeAProviderModeIsOn = !becomeAProviderMode || isCurrentUserAProvider;
  if (becomeAProviderMode && isCurrentUserAProvider) {
    myListingsTitle = adminLabel;
  }

  const customNavWithHideNavDropdownOff = customNavActive && !hideNavDropdown;
  const shouldRenderMyListingsMenu =
    (isAuthenticated && !customNavActive && shouldRenderMyListingsMenuWhenBecomeAProviderModeIsOn) ||
    customNavWithHideNavDropdownOff;

  return (
    <BottomNav
      navBlurClassName=""
      navBgColorClassName="bg-white-background/[.98] dark:bg-dark-white/[.98]"
      noOffset
      className="px-container flex flex-1 flex-row"
    >
      {renderIconItem({
        icon: marketplaceOff ? <InformationCircleIcon className={iconClassName} /> : discoverIcon,
        title: discoverLabel,
        href: firstLinkPath,
        selected: firstLinkCurrent,
      })}

      {shouldRenderAboutMenu
        ? renderIconItem({
            icon: aboutIcon,
            title: aboutLabel,
            href: '/about',
            selected: currentPath === 'about',
          })
        : null}

      {!isCurrentUserAProvider && becomeAProviderMode
        ? renderIconItem({
            icon: <PlusCircleIcon className="mb-1 h-6 w-6 text-current" />,
            title: becomeLabel,
            href: providerOnboardingUrl,
            selected: currentPath === 'become-a-provider',
          })
        : null}

      {shouldRenderMyListingsMenu
        ? renderIconItem({
            icon: <PlusCircleIcon className="mb-1 h-6 w-6 text-current" />,
            title: label('Listings'),
            isMenu: true,
            menu: (
              <MyListingsMenu
                withBackdrop
                providerNavItems={providerNavItems}
                className="flex flex-1"
                contentClassName="rounded-t-xl bg-white shadow-lg py-2"
                contentWrapperClassName="rounded-t-xl"
                labelClassName="flex flex-row items-center justify-center flex-1"
                menuLabel={
                  <>
                    <div className="flex flex-1 flex-col text-center">
                      <div
                        className={`flex flex-row justify-center ${
                          isMyListingsSelected ? 'text-accent' : iconDefaultColorClassName
                        } `}
                      >
                        {myListingsIcon({ className: 'h-6 w-6 mb-1 text-current' })}
                      </div>
                      <div className={`text-sm font-semibold ${isMyListingsSelected ? 'text-accent' : 'text-gray-700'}`}>
                        {myListingsTitle}
                      </div>
                    </div>
                  </>
                }
                fullWidth
                customNavActive={customNavActive}
                customNavConfig={customNavConfig}
                isAuthenticated={isAuthenticated}
                currentUser={currentUser}
              />
            ),
          })
        : showCreateButtonWhileUnauthenticated
        ? renderIconItem({
            icon: <PlusCircleIcon className="mb-1 h-6 w-6 text-current" />,
            title: 'Add Listing',
            href: '/l/new',
          })
        : null}

      {shouldRenderMessagingMenu
        ? renderIconItem({
            icon: (
              <div className="relative">
                <ChatBubbleLeftEllipsisIcon className={iconClassName} />
                {currentUser?._notifications?.unreadMessages ? (
                  <AnimtedDot positionClassName="top-0.5 right-0.5" ringClassName="ring-0" className="h-2 w-2" />
                ) : null}
              </div>
            ),
            title: 'Messaging',
            href: currentUser?.type === 'provider' ? '/messages' : '/account/messages',
            selected: currentPath === 'messages',
          })
        : null}

      {!isAuthenticated ? (
        renderIconItem({
          id: 'mobileLoginButton',
          icon: <UserIcon className={iconClassName} />,
          title: 'Login',
          href: '/login',
        })
      ) : (
        <>
          {/* {renderIconItem({
            icon: <Heart className={iconClassName} />,
            title: "Fav's",
            href: '/',
          })} */}
          {renderIconItem({
            isMenu: true,
            menu: (
              <ProfileMenu
                avatarSizeClassName=""
                noRing
                marketplaceOff={marketplaceOff}
                onLogout={handleLogout}
                withBackdrop
                className="flex flex-1"
                labelClassName="flex flex-row flex-1 text-center"
                contentClassName="rounded-t-xl bg-white shadow-lg py-2"
                contentWrapperClassName="rounded-t-xl"
                isMobile
                avatar={
                  <>
                    <div className="flex w-full flex-1 flex-col text-center">
                      <div className="flex flex-row justify-center">
                        <Avatar
                          className="mb-1 h-6 w-6 text-xs"
                          initialsClassName="text-xs"
                          user={currentUser}
                          disableProfileLink
                        />
                      </div>
                      <div className={`text-sm font-semibold ${isProfileSelected ? 'text-accent' : 'text-gray-700'}`}>
                        Profile
                      </div>
                    </div>
                  </>
                }
                fullWidth
                isAuthenticated={isAuthenticated}
                currentUser={currentUser}
              />
            ),
          })}
        </>
      )}
    </BottomNav>
  );
};

export default BottomBar;
