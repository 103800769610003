import { Link } from 'react-router-dom';

import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import UiTextRenderer from './UiTextRenderer';

const Wrapper = ({ children, href, internal, className }) => {
  if (internal) {
    return (
      <Link to={href} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <a target="_blank" rel="noreferrer" href={href} className={className}>
      {children}
    </a>
  );
};

const UiLink = params => {
  const {
    title: linkTitle,
    className,
    icon,
    url,
    featureHyperlink,
    contentClassName = 'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
    preview,
    urlFeature,
    urlFeatureValue,
    type,
    internal,
    textClassName = '',
  } = params || {};
  const { titleClassNames, title } = params?.metadata || {};

  let href = featureHyperlink && urlFeature ? urlFeatureValue : url;
  if (preview) href = 'http://google.com';
  if (!href) return null;

  return (
    <section className={className}>
      <div className={contentClassName}>
        <UiTextRenderer
          classNames={titleClassNames}
          className="mb-4"
          type={type}
          defaultFontWeightClassName="font-semibold"
          defaultFontSizeClassName="text-lg"
          defaultFontFamilyClassName=""
          defaultFontColorClassName="text-gray-900"
        >
          {title}
        </UiTextRenderer>

        <div>
          <Wrapper internal={internal} href={href} className={`group flex flex-row items-center space-x-2 ${textClassName}`}>
            <SvgRenderer id={icon} className="h-5 w-5 text-gray-700" />
            <span className="group-hover:underline">{linkTitle}</span>
          </Wrapper>
        </div>
      </div>
    </section>
  );
};

export default UiLink;
