import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import ButtonLight from '@/components/Button/ButtonLight';
import FieldCheckbox from '@/components/FieldCheckbox/FieldCheckbox';
import FieldSelect from '@/components/FieldSelect/FieldSelect';
import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';
import Form from '@/components/Form/Form';

import * as validators from '@/util/validators';
import { getMarketplace, label } from '@/util/helpers';
import { FormattedMessage, injectIntl, intlShape } from '@/util/reactIntl';
import { composeValidators, requiredTrueBoolean } from '@/util/validators';

import * as css from './SignupForm.module.css';

export function SignupFormFields(params) {
  const { formId, intl, values } = params;
  const marketplace = getMarketplace();

  // email
  const emailLabel = intl.formatMessage({
    id: 'SignupForm.emailLabel',
  });
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  // password
  const passwordLabel = intl.formatMessage({
    id: 'SignupForm.passwordLabel',
  });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(passwordMinLengthMessage, validators.PASSWORD_MIN_LENGTH);
  const passwordMaxLength = validators.maxLength(passwordMaxLengthMessage, validators.PASSWORD_MAX_LENGTH);
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(passwordRequired, passwordMinLength, passwordMaxLength);

  // Render single or multi name input based on marketplace settings
  // The API supports name, first_name and last_name
  const shouldRenderMultipleTextInputs = get(marketplace, 'pageConfig.general.seperateAccountNameInputs');
  const shouldRenderSignupType = get(marketplace, 'pageConfig.authentication.signUpType');

  const singleNameInput = (
    <FieldTextInput
      type="text"
      id={formId ? `${formId}.name` : 'name'}
      name="name"
      autoComplete="name"
      label="Name"
      // placeholder="Enter your name"
      validate={validators.required('Please enter your name')}
    />
  );
  const multiNameInput = (
    <div>
      <FieldTextInput
        type="text"
        className="mb-4 flex-1"
        id={formId ? `${formId}.firstName` : 'firstName'}
        name="firstName"
        autoComplete="given-name"
        label="First Name"
        // placeholder="Enter your first name"
        validate={validators.required('Please enter your first name')}
      />

      <FieldTextInput
        type="text"
        className="flex-1"
        id={formId ? `${formId}.lastName` : 'lastName'}
        name="lastName"
        autoComplete="family-name"
        label="Last Name"
        // placeholder="Enter your last name"
        validate={validators.required('Please enter your last name')}
      />
    </div>
  );
  const nameInput = shouldRenderMultipleTextInputs ? multiNameInput : singleNameInput;
  const typeInputMaybe = shouldRenderSignupType ? (
    <FieldSelect
      id="type"
      hint={
        values.type !== 'provider'
          ? "I'm creating this account as an individual."
          : `I'm creating this account to host my own ${label('listings')}.`
      }
      hintLocation="bottom"
      label="Account Type"
      name="type"
      className="mb-4"
      maxWidthClassName="max-w-xl"
    >
      <option value="individual">Individual</option>
      <option value="provider">Provider</option>
    </FieldSelect>
  ) : null;

  return (
    <>
      {typeInputMaybe}
      {nameInput}

      <FieldTextInput
        className="mt-6"
        type="email"
        id={formId ? `${formId}.email` : 'email'}
        name="email"
        autoComplete="email"
        label={emailLabel}
        // placeholder={emailPlaceholder}
        validate={validators.composeValidators(emailRequired, emailValid)}
      />

      <FieldTextInput
        validateOnType
        className="mt-6"
        type="password"
        id={formId ? `${formId}.password` : 'password'}
        name="password"
        autoComplete="new-password"
        label={passwordLabel}
        // placeholder={passwordPlaceholder}
        validate={passwordValidators}
      />
    </>
  );
}

export const PoliciesText = () => {
  const forcePoliciesCheckbox = get(getMarketplace(), 'pageConfig.authentication.forcePoliciesCheckbox');
  if (forcePoliciesCheckbox) {
    return (
      <FieldCheckbox
        className="mb-2 self-end"
        boolean
        id="termsSignup"
        name="termsSignup"
        validate={composeValidators(requiredTrueBoolean('Please agree to the required policies.'))}
        label={
          <p className="mb-2 text-xs text-gray-500">
            Creating an account means you're okay with our{' '}
            <a target="_blank" href="/p/terms-of-service" className="hover:text-accent text-gray-600 hover:underline">
              Terms of Service
            </a>
            ,{' '}
            <a target="_blank" href="/p/privacy-policy" className="hover:text-accent text-gray-600 hover:underline">
              Privacy Policy
            </a>
            ,{' '}
            <a target="_blank" href="/p/cookie-policy" className="hover:text-accent text-gray-600 hover:underline">
              Cookie Policy
            </a>
            , and{' '}
            <a target="_blank" href="/p/acceptable-use-policy" className="hover:text-accent text-gray-600 hover:underline">
              Acceptable Use Policy
            </a>
            .
          </p>
        }
      />
    );
  }

  return (
    <p className="mb-2 text-xs text-gray-500">
      Creating an account means you're okay with our{' '}
      <a target="_blank" href="/p/terms-of-service" className="hover:text-accent text-gray-600 hover:underline">
        Terms of Service
      </a>
      ,{' '}
      <a target="_blank" href="/p/privacy-policy" className="hover:text-accent text-gray-600 hover:underline">
        Privacy Policy
      </a>
      ,{' '}
      <a target="_blank" href="/p/cookie-policy" className="hover:text-accent text-gray-600 hover:underline">
        Cookie Policy
      </a>
      , and{' '}
      <a target="_blank" href="/p/acceptable-use-policy" className="hover:text-accent text-gray-600 hover:underline">
        Acceptable Use Policy
      </a>
      .
    </p>
  );
};

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, values, className, formId, handleSubmit, inProgress, invalid, intl } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <SignupFormFields values={values} formId={formId} intl={intl} />
          </div>

          <div className="mt-14">
            <PoliciesText />
            <ButtonLight type="submit" size="2xl" fullWidthCenterText inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </ButtonLight>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
