import { getCalendarData } from '@/ducks/Calendar.duck';

import { parse } from '@/util/urlHelpers';

const RESULT_PAGE_SIZE = 15;

export const CalendarPageLoadData = (params, search) => {
  const queryParams = parse(search);
  const { page = 1, ...rest } = queryParams;
  const { tab = 'orders', contactId } = params;

  return getCalendarData({
    ...rest,
    ...queryParams,
    contactId,
    tab,
    page,
    perPage: RESULT_PAGE_SIZE,
  });
};
