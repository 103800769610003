@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1500;
  margin: 0 !important;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;
    background-color: rgb(var(--mpc-color-white-bg));
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      /* justify-content: center; */
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply __marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;
    box-shadow: none;

    @media (--viewportMedium) {
      flex-basis: 850px;
      min-height: auto;
      height: auto;
    }
  }

  & .containerTailwind {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(var(--mpc-color-white-bg));
    border-radius: var(--borderRadiusLg);
    border-bottom: none;
    min-height: 100vh;
    height: 100%;
    @media (--viewportMedium) {
      flex-basis: 850px;
      min-height: auto;
      height: auto;
      flex-grow: 0;
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
}

@media (max-width: 650px) {
  .isOpen .container {
    box-shadow: none;
  }
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  @apply __marketplaceModalCloseStyles;
}

.closeText {
  @apply __marketplaceModalCloseText;
}

.closeIcon {
  @apply __marketplaceModalCloseIcon;
}

.closeLight {
  color: var(--matterColorAnti);
  fill: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: rgb(var(--mpc-color-white));
    fill: rgb(var(--mpc-color-white));
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
