import isEmpty from 'lodash/isEmpty';

import ButtonLight from '@/components/Button/ButtonLight';
import { labelPrefixLookup as socialLabelPrefixLookup } from '@/components/FieldSocialInput/FieldSocialInput';
import { renderSocialIcon } from '@/components/Footer/Footer';

import { EnvelopeIcon, GlobeAmericasIcon, PhoneIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

const WebProfiles = ({
  profiles,
  website,
  inline = true,
  showContactEmail,
  contactEmail,
  email,
  showContactPhoneNumber,
  phone,
  showFullWebsite = false,
}) => {
  inline = true;
  const renderPhone = showContactPhoneNumber && phone;
  const renderEmail = showContactEmail;
  const renderWebsite = website;
  const renderSocials = profiles && !isEmpty(profiles);
  if (!renderPhone && !renderEmail && !renderSocials && !renderWebsite) {
    return null;
  }

  const ItemsWrapper = ({ children }) => {
    if (inline) return children;
    return <div className="c-gap c-gap-2 flex flex-row flex-wrap items-center">{children}</div>;
  };

  const items = profiles && Object.entries(profiles);
  const Button = ({ children, icon = GlobeAmericasIcon, href = website }) => {
    return (
      <div className="flex flex-row">
        <ButtonLight
          href={href}
          externalLink
          roundedClassName="rounded-full"
          className="!flex truncate"
          extraButtonClassName="!flex truncate"
          linkClassName={`flex ${
            !inline ? 'mb-2' : ''
          } truncate rounded-full focus-within:ring-offset-2 focus-within:ring-2 focus-within:ring-accent transition`}
          noShadow
          size="2xs"
          theme="white-light"
        >
          {icon({
            className: `-ml-0.5 ${children ? 'mr-1' : '-mr-0.5'} h-5 w-5 text-gray-500 flex-shrink-0`,
          })}
          <span className="truncate">{children}</span>
        </ButtonLight>
      </div>
    );
  };

  const formattedEmail = contactEmail || email;
  const formattedWebsite = website ? website.replace(/^https?:\/\//i, '').replace(/\/$/, '') : '';
  return (
    <div className={inline ? 'row c-gap c-gap-2 flex flex-wrap' : ''}>
      {showContactEmail ? (
        <Button href={`mailto:${formattedEmail}`} icon={EnvelopeIcon}>
          {formattedEmail}
        </Button>
      ) : null}

      {showContactPhoneNumber && phone ? (
        <Button icon={PhoneIcon} href={`tel:${phone}`}>
          {phone}
        </Button>
      ) : null}

      {website ? <Button href={website}>{showFullWebsite && formattedWebsite}</Button> : null}

      {items?.length ? (
        <ItemsWrapper>
          {items.map((item, index) => {
            const type = item[0];
            const to = `${socialLabelPrefixLookup[type]}${item[1]}`;
            const iconClassName = 'h-5 w-5';
            if (!item[1]) return null;
            return (
              <a
                title={to}
                key={`${index}_web_profile`}
                // className="self-start text-xs text-gray-400 hover:text-primary"
                className="focus:ring-accent-500 inline-flex items-center rounded-full border border-gray-200 bg-white p-1.5 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                href={`https://${to}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {renderSocialIcon(type, iconClassName)}
              </a>
            );
          })}
        </ItemsWrapper>
      ) : null}
    </div>
  );
};

export default WebProfiles;
