import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/forms' : 'forms';
};

const create = body => {
  return client('forms', { body });
};

const read = id => {
  return client(`forms/${id}`);
};

const update = (id, body) => {
  return client(`forms/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`forms/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000 }) => {
  return client(`forms?${stringify(params)}`);
};

const responses = (id, params = { limit: 1000 }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}/${id}/responses?${stringify(params)}`);
};

const respond = (id, body) => {
  return client(`forms/${id}/responses`, { body, method: 'POST' });
};

export { read, list, update, create, remove, responses, respond };
