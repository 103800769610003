import React from "react";
import PropTypes from "prop-types";
import Media from "@marketplace-co/react-application-element-media";
import { getClassNames, mergeDeep } from "@marketplace-co/react-utils-helpers";

const apectRatioClassNames = {
  image: "",
  "16/9": "aspect-[16/9]",
  "8/3": "aspect-[8/3]",
  "7/5": "aspect-[7/5]",
  "6/5": "aspect-[6/5]",
  "5/7": "aspect-[5/7]",
  "5/6": "aspect-[5/6]",
  "5/4": "aspect-[5/4]",
  "4/3": "aspect-[4/3]",
  "4/5": "aspect-[4/5]",
  "9/16": "aspect-[9/16]",
  "3/8": "aspect-[3/8]",
  "3/4": "aspect-[3/4]",
  "3/2": "aspect-[3/2]",
  "2/5": "aspect-[2/5]",
  "2/3": "aspect-[2/3]",
  "1/1": "aspect-[1/1]",
  "1/2": "aspect-[1/2]",
  "1/3": "aspect-[1/3]",
  "1/4": "aspect-[1/4]",
};

const splitRatioClassNames = {
  "2/3": {
    grid: {
      cols: "grid-cols-1 lg:grid-cols-3",
    },
    content: {
      col: "col-span-1 lg:col-span-2",
    },
    media: {
      col: "col-span-1",
    },
  },
  "1/2": {
    grid: {
      cols: "lg:grid-cols-2",
    },
    media: {
      col: "col-span-1",
    },
  },
  "1/3": {
    grid: {
      cols: "grid-cols-1 lg:grid-cols-3",
    },
    media: {
      col: "lg:col-span-2",
    },
  },
};

const defaultMediaAspectRatio = "16/9";
const defaultMediaAspectRatioClassName =
  defaultMediaAspectRatio[defaultMediaAspectRatio];
const defaultClassNames = {
  grid: {
    general: "grid mx-auto",
    gapX: "gap-x-8",
    gapY: "gap-y-16",
    cols: "grid-cols-1 lg:grid-cols-3",
  },
  content: {
    general: "flex flex-col lg:pb-6",
    order: "",
    col: "col-span-1",
  },
  media: {
    display: "relative",
    col: "lg:col-span-2",
  },
  mediaContent: {
    general: "relative z-20 overflow-hidden transform-gpu",
    borderRadius: "rounded-xl",
    bgColor: "bg-gray-200",
    aspectRatio: defaultMediaAspectRatioClassName,
    shadow: "shadow-xl shadow-black/5 ring-1 ring-gray-900/5",
  },
};

const getRequiredClassNames = ({
  reverseDesktop,
  reverseMobile,
  splitRatio,
  mediaAspectRatio,
}) => {
  let requiredClassNames = {
    content: {},
    mediaContent: {},
  };

  if (splitRatio) {
    requiredClassNames = mergeDeep(
      requiredClassNames,
      splitRatioClassNames[splitRatio]
    );
  }

  if (reverseDesktop || reverseMobile) {
    const reverseMobileClassName = reverseMobile ? "order-2" : "";
    const reverseDesktopClassName = reverseDesktop
      ? "lg:order-2"
      : "lg:order-none";
    requiredClassNames.content.order = `${reverseMobileClassName} ${reverseDesktopClassName}`;
  }

  if (mediaAspectRatio) {
    requiredClassNames.mediaContent.aspectRatio =
      apectRatioClassNames[mediaAspectRatio] ||
      defaultMediaAspectRatioClassName;
  }
  return requiredClassNames;
};

const getMediaContentStyles = ({ mediaAspectRatio, media }) => {
  const isImageAspectRatio = mediaAspectRatio === "image";
  if (!isImageAspectRatio) return {};

  const { width, height } = media?.image?.metadata || {};
  if (!width || !height) return {};

  const aspectRatio = (height / width) * 100;
  return {
    paddingBottom: `${aspectRatio}%`,
  };
};

const MarketingSectionFeatureSplitMedia = ({
  classNames,
  className,
  children,
  media,
  mediaAspectRatio,
  splitRatio,
  reverseDesktop,
  reverseMobile,
}) => {
  const cn = getClassNames({
    classNames,
    defaultClassNames,
    requiredClassNames: getRequiredClassNames({
      reverseDesktop,
      reverseMobile,
      splitRatio,
      mediaAspectRatio,
    }),
    flatten: true,
  });

  const mediaContentStyles = getMediaContentStyles({
    mediaAspectRatio,
    media,
  });

  return (
    <div className={className}>
      <div className={cn.grid}>
        <div className={cn.content}>{children}</div>
        <div className={cn.media}>
          <div className={cn.mediaContent} style={mediaContentStyles}>
            {media && <Media {...media} />}
          </div>
        </div>
      </div>
    </div>
  );
};

MarketingSectionFeatureSplitMedia.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.object,
  children: PropTypes.node,
  media: PropTypes.object,

  /*
   * The ratio of the split from left to right, refers to the width of the content on desktop, the media will take up the rest of the space.
   */
  splitRatio: PropTypes.oneOf(["1/3", "1/2", "2/3"]),

  reverseDesktop: PropTypes.bool,
  reverseMobile: PropTypes.bool,

  /*
   * The aspect ratio of the media.
   */
  mediaAspectRatio: PropTypes.oneOf(Object.keys(apectRatioClassNames)),
};
MarketingSectionFeatureSplitMedia.defaultProps = {
  className: "relative mx-auto max-w-container px-container",
  classNames: defaultClassNames,
  media: {},
  reverseDesktop: false,
  reverseMobile: false,
  splitRatio: "1/3",
  mediaAspectRatio: defaultMediaAspectRatio,
};
export default MarketingSectionFeatureSplitMedia;
