import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as css from './IconSpinner.module.css';

const IconSpinner = ({
  rootClassName = '',
  className = '',
  colorTextClassName = 'text-accent',
  heightClassName = 'h-8 w-8',
  white = false,
  size = 'default',
  type = 'default',
}): JSX.Element => {
  const classes = classNames(rootClassName || css.root, className);

  const styles: {
    stroke?: string;
    color?: string;
    height?: number;
    width?: number;
  } = white ? { stroke: 'white', color: 'white' } : {};
  if (size === 'sm') {
    styles.height = 20;
    styles.width = 20;
  }

  if (type === 'tailwind') {
    return (
      <svg
        style={styles}
        className={`animate-spin ${className} ${colorTextClassName} ${heightClassName}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }

  return (
    <svg className={classes} style={styles} viewBox="0 0 30 30" preserveAspectRatio="xMidYMid" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="12" fill="none" strokeLinecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 15 15;180 15 15;720 15 15"
          keyTimes="0;0.5;1"
          dur="0.9s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dasharray"
          calcMode="linear"
          values="9 56;46 14;9 56"
          keyTimes="0;0.5;1"
          dur="0.9s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

IconSpinner.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSpinner.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSpinner;
