const baseUrl = "https://ik.imagekit.io/346d752Y";

export const imageBuilder = ({
  name,
  transformation,
  type,
  disableSmartCrop,
  dpr,
} = {}) => {
  if (!name) return "";

  if (type) {
    transformation = getTransformationType({ type, disableSmartCrop, dpr });
  }

  if (!transformation) return "";

  return `${baseUrl}/${transformation}/${name}`;
};

const transformationTypes = {
  sm: "w-250",
  md: "w-500",
  lg: "w-1000",
};
export const getTransformationType = ({
  type,
  disableSmartCrop = false,
  dpr = 2,
}) => {
  const focusParam = disableSmartCrop ? "fo-center" : "fo-auto";
  const dprParam = dpr ? `dpr-${dpr}` : "dpr-1";
  const transformation = transformationTypes[type];
  return `tr:${transformation},${focusParam},${dprParam}`;
};
