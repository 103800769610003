import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Header } from '@tmpc/ui';
import { useSWRConfig } from 'swr';

import Logo from '@/containers/LogoContainer/LogoContainer';

import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

import { playBlocks } from '@/clients';

import { computeDynamicHref, handleThemeChange } from '@/util/helpers';

import * as css from './TopbarMarketing.module.css';

const getLinkParams = (link = {}) => {
  return {
    href: computeDynamicHref(link),
    external: link?.linkType === 'external',
  };
};

const getPlayBlockIdsFromLinks = ({ links, secondaryLink, cta }) => {
  const playBlockIds = [];

  if (links?.length) {
    links.forEach(link => {
      const { hyperLink, hyperLinkPlayBlockId } = link?.link || {};
      if (hyperLink === '/pb' && hyperLinkPlayBlockId) {
        playBlockIds.push(hyperLinkPlayBlockId);
      }
    });
  }

  if (secondaryLink?.link?.hyperLink === '/pb' && secondaryLink?.link?.hyperLinkPlayBlockId) {
    playBlockIds.push(secondaryLink?.link?.hyperLinkPlayBlockId);
  }

  if (cta?.link?.hyperLink === '/pb' && cta?.link?.hyperLinkPlayBlockId) {
    playBlockIds.push(cta?.link?.hyperLinkPlayBlockId);
  }

  return playBlockIds;
};

export const TopbarMarketing = ({ config, className = 'py-10', marketplaceTheme, transparent, hideThemeToggle = false }) => {
  const { cta, links: _links, secondaryLink: _secondaryLink, settings } = config || {};
  const { colorTheme = 'primary', breakpoint = 'md' } = settings || {};
  const { mutate, cache } = useSWRConfig();
  const theme = useSelector(state => state?.UI?.theme);

  // prefetch playblocks if they are in the main
  useEffect(() => {
    const initialDelay = 1000;
    const delay = 400;
    const playblockIds = getPlayBlockIdsFromLinks({ links: _links, secondaryLink: _secondaryLink, cta });

    if (!playblockIds?.length || window?.app?.TopbarMarketingPrefetched) return;
    window.app.TopbarMarketingPrefetched = true;

    const run = (t, d) => {
      const path = playblockIds.reverse()[t - 1];
      const key = `/api/playblocks/${path}`;
      const existingCache = cache.get(key);
      if (!existingCache) {
        mutate(`/api/playblocks/${path}`, () => playBlocks.view(path), {
          populateCache: true,
        });
      }
      t > 1 && setTimeout(run, d, --t, d);
    };
    setTimeout(() => run(playblockIds.length, delay), initialDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = item => {
    const { itemsActive, items = [] } = item || {};
    if (!itemsActive || !items?.length) return undefined;

    return items.map(subItem => {
      const { description, icon } = subItem || {};
      return {
        label: subItem?.label || '',
        ...getLinkParams(subItem?.link),
        description,
        icon: icon ? props => <SvgRenderer {...props} id={icon} /> : null,
      };
    });
  };
  const links = _links?.length
    ? _links.map(item => {
        const { label, flyoutMenuType, link } = item || {};
        return {
          label: label || '',
          ...getLinkParams(link),
          items: getItems(item),
          flyoutMenuType,
        };
      })
    : [
        {
          label: 'Marketplaces',
          href: '/marketplaces',
        },
        {
          label: 'Company',
          href: '/company',
        },
      ];

  let secondaryLink = null;
  if (_secondaryLink?.label && _secondaryLink?.link) {
    secondaryLink = {
      label: _secondaryLink.label,
      ...getLinkParams(_secondaryLink.link),
    };
  }

  const transparentClassName = transparent ? `${css.transparentHeader} dark absolute top-0 right-0 left-0 z-10` : '';
  const props = {
    Logo,
    cta: {
      label: cta?.label || 'Get started',
      ...getLinkParams(cta?.link),
    },
    secondaryLink,
    links,
    mpcTheme: marketplaceTheme,
    hideThemeToggle,
    theme,
    colorTheme,
    breakpoint,
    onThemeChange: handleThemeChange,
  };

  return <Header {...props} className={`${className} ${transparentClassName}`} />;
};
