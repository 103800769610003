import { Link } from 'react-router-dom';

import { camelize, statusColors } from '@/util/helpers';

const InternalOrExternalLink = ({ to, external }) => {
  if (external) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        target="_blank"
        href={to}
        rel="noreferrer"
        className="focus:ring-accent absolute inset-0 rounded-full transition focus:ring-2 focus:ring-offset-2"
      ></a>
    );
  }

  return <Link to={to} className="focus:ring-accent absolute inset-0 rounded-full transition focus:ring-2 focus:ring-offset-2" />;
};

const Badge = props => {
  const {
    className,
    label = '',
    color: localColor,
    style,
    roundedClassName = 'rounded-full',
    dot = false,
    selfStart = false,
    href,
    externalHref = false,
    onClick = () => {},
    tabindex = '-1',
  } = props;
  const color = localColor || statusColors[camelize(label)] || 'gray';
  // bg-green-100 text-green-800 text-green-400
  // bg-orange-100 text-orange-800 text-orange-400
  // bg-primary-100 text-primary-800 text-primary-400
  // bg-warning-100 text-warning-800 text-warning-400
  // bg-purple-100 text-purple-800 text-purple-400
  // bg-accent-100 text-accent-800 text-accent-400
  // bg-success-100 text-success-800 text-success-400
  // bg-error-100 text-error-800 text-error-400
  // bg-warning-100 text-warning-800 text-warning-400
  return (
    <span
      tabIndex={tabindex}
      data-key="badge"
      onClick={onClick}
      style={style}
      className={`${className} ${href ? 'relative' : ''} inline-flex items-center ${
        selfStart ? 'self-start' : ''
      } px-2.5 py-0.5 ${roundedClassName} text-xs font-medium bg-${color}-100 text-${color}-800 capitalize`}
    >
      {dot ? (
        <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${color}-400`} fill="currentColor" viewBox="0 0 8 8">
          <circle cx="4" cy="4" r="3" />
        </svg>
      ) : null}

      {label}

      {href ? <InternalOrExternalLink to={href} external={externalHref} /> : null}
    </span>
  );
};

export default Badge;
