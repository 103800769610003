import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const list = (params = { limit: 200, type: 'marketplaceDefined' }) => {
  return client(`tags?${stringify(params)}`);
};

const create = body => {
  return client('tags', { body });
};

export { list, create };
