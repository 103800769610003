import PropTypes from 'prop-types';

import { ExclamationTriangleIcon } from '@tmpc/ui/dist/utils/icons/20/solid';

const ErrorMessage = props => {
  const { className, description, title, action, onAction } = props;
  const dataTest = props['data-test'] || 'error-message';

  return (
    <div data-test={dataTest} className={`${className} break-word bg-error-50 dark:bg-error-900 mb-6 rounded-md p-4`}>
      <div className="flex break-words">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-red-400" />
        </div>

        <div className="ml-3">
          <div className="text-error-800 dark:text-error-100 text-base font-semibold leading-6">{title}</div>

          {description ? (
            <div className="text-error-700 dark:text-error-200 mt-1 break-words text-sm">
              <div>{description}</div>
            </div>
          ) : null}

          {action ? (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  onClick={onAction}
                  className={`dark:text-error-100 rounded-md px-2 py-1.5 text-sm font-medium leading-5 text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50`}
                >
                  {action}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const { string } = PropTypes;

ErrorMessage.propTypes = {
  /** The title of the error message. */
  title: string,
  /** The className applied to the parent wrapper. */
  className: string,
};

export default ErrorMessage;
