import classNames from 'classnames';
import { bool, oneOfType, string } from 'prop-types';

import Img from '@/components/Img/Img';
import NamedLink from '@/components/NamedLink/NamedLink';

import { ensureCurrentUser, ensureUser, userAbbreviatedName } from '@/util/data';
import { generateImageKitUrl, getAccountName } from '@/util/helpers';
import { injectIntl, intlShape } from '@/util/reactIntl';
import { types as sdkTypes } from '@/util/sdkLoader';
import { propTypes } from '@/util/types/propTypes';

import * as css from './Avatar.module.css';

const { UUID } = sdkTypes;

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

export const AvatarComponent = props => {
  const { rootClassName, className, initialsClassName, user, disableProfileLink, imgClassName, useNativeImg } = props;
  const classes = classNames(rootClassName || css.root, className, 'relative overflow-hidden');
  const userIsCurrentUser = user && user.type === 'individual';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const defaultUserAbbreviatedName = '';

  const displayName = getAccountName(avatarUser);
  const abbreviatedName = userAbbreviatedName(displayName, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };

  if (typeof avatarUser.id === 'string') {
    avatarUser.id = new UUID(avatarUser.id);
  }

  const linkProps = avatarUser.id ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } } : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.avatar && avatarUser.avatar.id;
  if (hasProfileImage) {
    avatarUser.avatar = {
      ...avatarUser.avatar,
      ...avatarUser.avatar.metadata,
    };
  }
  const profileLinkEnabled = !disableProfileLink;

  const classForInitials = initialsClassName || css.initials;

  if (hasProfileImage && profileLinkEnabled) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <Img
          src={avatarUser.avatar.url}
          imgClassName={`rounded-full overflow-hidden`}
          className={`${css.avatarImage} overflow-hidden rounded-full`}
        />
      </NamedLink>
    );
  } else if (hasProfileImage) {
    const src = generateImageKitUrl({ id: avatarUser.avatar.name, transformationType: 'avatar' });
    return (
      <div {...rootProps}>
        {!useNativeImg ? (
          <Img
            imgClassName={`rounded-full overflow-hidden ${imgClassName}`}
            key={avatarUser.avatar.url}
            src={src}
            className={`${css.avatarImage} overflow-hidden rounded-full`}
          />
        ) : (
          <img
            alt="avatar"
            // imgClassName={`rounded-full ${imgClassName}`}
            key={avatarUser.avatar.url}
            src={src}
            className={`${css.avatarImage} overflow-hidden rounded-full`}
          />
        )}
      </div>
    );
  } else if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <span className={classForInitials}>{abbreviatedName}</span>
      </NamedLink>
    );
  } else {
    // Placeholder avatar (initials)
    return (
      <div {...rootProps}>
        <span className={classForInitials}>{abbreviatedName}</span>
      </div>
    );
  }
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  initialsClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  initialsClassName: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarMedium = props => <Avatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />;
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => <Avatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />;
AvatarLarge.displayName = 'AvatarLarge';
