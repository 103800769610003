import { useHistory } from 'react-router-dom';

import ButtonLight from '@/components/Button/ButtonLight';

import { auth } from '@/clients';
import { setAuthModalOpened } from '@/ducks/UI.duck';

import { computeDynamicHref } from '@/util/helpers';
import { matchPathname } from '@/util/routes';

import routes from '@/routeConfiguration';

const UiButtonRenderer = ({ defaultSize = 'md', hrefConfig, children }) => {
  const history = useHistory();

  const href = computeDynamicHref(hrefConfig);
  const size = defaultSize;

  const buttonProps = {
    href,
    size,
  };

  const matches = matchPathname(href, routes());
  const hrefRequiresAuth = matches?.[0]?.route?.auth === true;

  if (hrefRequiresAuth) {
    const isAuthenticated = auth.isAuthenticated({ async: false });
    if (!isAuthenticated) {
      buttonProps.href = undefined;
      buttonProps.onClick = () => {
        const dispatch = window.app?.store?.dispatch;
        if (!dispatch) {
          console.error('no dispatch fn found');
          return;
        }

        dispatch(
          setAuthModalOpened(true, account => {
            console.log(account);
            history.push(href);
          })
        );
      };
    }
  }

  return <ButtonLight {...buttonProps}>{children}</ButtonLight>;
};

export default UiButtonRenderer;
