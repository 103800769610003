import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, svg, ...rest } = props;

  const defaultSvgViewbox = '0 0 512 512';
  const defaultSvgPath = (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M256 0C114.837 0 0 114.837 0 256s114.837 256 256 256 256-114.837 256-256S397.163 0 256 0z"
    />
  );

  const createSvgDesktop = (path, viewbox) => (
    <svg
      dangerouslySetInnerHTML={viewbox ? { __html: path } : null}
      className={className}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox={!viewbox ? defaultSvgViewbox : viewbox}
    >
      {!viewbox ? defaultSvgPath : null}
    </svg>
  );

  const createSvgMobile = (path, viewbox) => (
    <svg
      dangerouslySetInnerHTML={viewbox ? { __html: path } : null}
      width="80"
      height="30"
      className={className}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox={!viewbox ? defaultSvgViewbox : viewbox}
    >
      {!viewbox ? defaultSvgPath : null}
    </svg>
  );

  let svgDesktop = createSvgDesktop();
  let svgMobile = createSvgMobile();
  // Custom SVG
  if (svg) {
    svgDesktop = createSvgDesktop(svg.content, svg.viewbox);
    svgMobile = createSvgMobile(svg.content, svg.viewbox);
  }

  if (format === 'desktop') return svgDesktop;
  return svgMobile;
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
