import DynamicLink from '@/components/DynamicLink/DynamicLink';
import UiTextRenderer from '@/components/UiRenderer/UiTextRenderer';

const UiTextSection = props => {
  const {
    className = '',
    defaultAlignClassName = '',
    defaultDescriptionMarginTopClassName = 'mt-5',
    title,
    description,
    titleClassNames = {},
    descriptionClassNames = {},
    category,
    buttonText,
    buttonHref,
    colorTheme = 'primary',
  } = props;
  const isTitleHidden = titleClassNames?.hidden === true;
  if (isTitleHidden && !description) return null;

  const alignClassName = titleClassNames.textAlign || defaultAlignClassName;
  const descriptionMarginTopClassName = descriptionClassNames?.marginTop || defaultDescriptionMarginTopClassName;
  return (
    <div className={`${className} ${alignClassName}`}>
      {/* mb-2 text-base font-semibold uppercase tracking-wider text-primary-600 */}
      {/* text-primary-500, text-accent-500, text-third-500 */}
      {category ? <div className={`mb-4 text-base font-semibold leading-7 text-${colorTheme}-500`}>{category}</div> : null}

      <UiTextRenderer
        text={title}
        defaultAlignClassName={defaultAlignClassName}
        type="marketing-title"
        classNames={titleClassNames}
      />

      {description ? (
        <UiTextRenderer
          text={description}
          defaultAlignClassName={alignClassName}
          type="marketing-description"
          className={descriptionMarginTopClassName}
          classNames={descriptionClassNames}
        />
      ) : null}

      {buttonText ? (
        <DynamicLink
          as="button"
          colorTheme={colorTheme}
          link={buttonHref}
          shadowClassName="shadow"
          className="mt-6 inline-flex"
          size="xl"
        >
          {buttonText}
        </DynamicLink>
      ) : null}
    </div>
  );
};

export default UiTextSection;
