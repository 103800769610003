import { track } from '@/analytics/track';

export const clicked = data => {
  const { metadata, title, id, href, size, type } = data;
  const { linkType } = metadata || {};
  const props = {
    name: title,
    promotion_id: id,
    promotion_type: type,
    link_href: href,
  };
  if (linkType) props.link_type = linkType;
  if (size) props.card_size = size;

  track('promotion.clicked', { ...props });
};
