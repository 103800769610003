// ================ Action types ================ //

export const LOCATION_CHANGED = 'app/Routing/LOCATION_CHANGED';

// ================ Reducer ================ //

const initialState = {
  currentLocation: null,
  currentCanonicalUrl: null,
  history: [],
};

export default function routingReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_CHANGED: {
      const existingHistory = state.history || [];
      const history = [...existingHistory];
      if (!history.some(item => item.key === payload.location?.key)) {
        history.push(payload.location);
      }
      return {
        ...state,
        currentLocation: payload.location,
        currentCanonicalUrl: payload.canonicalUrl,
        history,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const locationChanged = (location, canonicalUrl) => ({
  type: LOCATION_CHANGED,
  payload: { location, canonicalUrl },
});
