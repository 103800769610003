import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { SWRConfig } from 'swr';

import swrProvider from '@/clients/swr/swrProvider';

import { IntlProvider } from '@/util/reactIntl';

import { useLocaleConfig } from '@/locales';
import routeConfiguration from '@/routeConfiguration';
import Routes from '@/Routes';

export const App = ({ store, initialCurrentUserLocale }: { store: any; initialCurrentUserLocale?: string }) => {
  const [locale, setLocale] = useState(initialCurrentUserLocale || 'en');
  const { localeMessages } = useLocaleConfig(locale);

  return (
    // @ts-ignore
    <IntlProvider key={locale} locale={locale} messages={localeMessages} textComponent="span">
      <Provider store={store}>
        <SWRConfig value={{ provider: swrProvider }}>
          <HelmetProvider>
            <BrowserRouter>
              <Routes key={locale} changeLocale={setLocale} locale={locale} routes={routeConfiguration()} />
            </BrowserRouter>
          </HelmetProvider>
        </SWRConfig>
      </Provider>
    </IntlProvider>
  );
};

App.propTypes = { store: PropTypes.any.isRequired };
