import { Menu, Transition } from '@headlessui/react';
import { useRef, useState } from 'react';

import { arrayOf, func, number, shape, string } from 'prop-types';
import { usePopper } from 'react-popper';

import * as css from '@/components/FilterPopup/FilterPopup.module.css';
import ButtonLight from '@/components/Button/ButtonLight';

import { FormattedMessage } from '@/util/reactIntl';
import { CheckIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const SelectSingleFilterPopup = props => {
  const {
    className,
    urlParam,
    label,
    options,
    initialValue,
    left = false,
    onOtherClick,
    hideClearButton = false,
    onSelect,
  } = props;

  // https://github.com/tailwindlabs/headlessui/issues/154
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: !left ? 'bottom-end' : 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  });

  const selectOption = (urlParam, option) => {
    onSelect(urlParam, option);
  };

  // resolve menu label text and class
  const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
  const filterIsActive = initialValue !== null && initialValue !== undefined;
  return (
    <div className={`${className} relative z-10 inline-block text-left`}>
      <Menu>
        {({ open }) => (
          <>
            <span ref={setTargetElement} className="rounded-4xl">
              <Menu.Button
                as={ButtonLight}
                inlineBlock
                noShadow
                filter
                filterOpen={open}
                filterActive={filterIsActive}
                theme={filterIsActive ? 'primary' : 'filter'}
              >
                <span data-title={menuLabel} className={css.labelWrapper}>
                  {menuLabel}
                </span>
              </Menu.Button>
            </span>

            <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  static
                  className={`z-10 ${left ? 'left' : 'right'}-0 mt-2 w-56 origin-top-${
                    left ? 'left' : 'right'
                  } divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-2xl outline-none`}
                >
                  <div className="py-1">
                    {options.map(option => {
                      const selected = initialValue === option.key || (option.key === '' && initialValue === null);
                      return (
                        <Menu.Item key={option.key}>
                          {({ active }) => (
                            <li
                              onClick={() => selectOption(urlParam, option.key)}
                              className={`${
                                active ? 'bg-accent-600 text-white' : 'text-gray-900'
                              } relative flex w-full cursor-pointer justify-between py-2 pl-8 pr-4 text-left text-sm leading-5 focus:outline-none`}
                            >
                              <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                {option.label}
                              </span>
                              <span
                                className={`${
                                  (selected && !active) || (!selected && active)
                                    ? 'text-accent-600'
                                    : active
                                    ? 'text-white'
                                    : 'text-transparent'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <CheckIcon className="h-5 w-5" />
                              </span>
                            </li>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>

                  {onOtherClick ? (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-500'
                            } flex w-full justify-between px-4 py-2 text-left text-sm leading-5 focus:outline-none`}
                            onClick={onOtherClick}
                          >
                            Other
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ) : null}

                  {filterIsActive && !hideClearButton ? (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-500'
                            } flex w-full justify-between px-4 py-2 text-left text-sm leading-5 focus:outline-none`}
                            onClick={() => selectOption(urlParam, null)}
                          >
                            <FormattedMessage id={'SelectSingleFilter.popupClear'} />
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ) : null}
                </Menu.Items>
              </Transition>
            </div>
          </>
        )}
      </Menu>
    </div>
  );
};

SelectSingleFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SelectSingleFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SelectSingleFilterPopup;
