import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const s3Signature = params => {
  return client(`asset-service/auth/s3?${stringify(params)}`);
};

const video = body => {
  return client('asset-service/video', { body });
};

export { s3Signature, video };
