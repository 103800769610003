import { Field, Form as FinalForm } from 'react-final-form';

import FieldTextInput from '@/components/FieldTextInput/FieldTextInput';
import Form from '@/components/Form/Form';
import LocationAutocompleteInput from '@/components/LocationAutocompleteInput/LocationAutocompleteInput';

import { MagnifyingGlassIcon } from '@tmpc/ui/dist/utils/icons/24/outline';

import * as css from './SearchFilterInput.module.css';

const identity = v => v;

const SearchFilterInput = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        },
      }}
      render={formRenderProps => {
        const { className, values, inputClassName, placeholder, searchType } = formRenderProps;
        const handleSearchOnChange = location => {
          if (!location || !location.selectedPlace) return;
          props.onSubmit({ location });
        };

        const searchKeyword = (keywords, clear) => {
          if (!keywords && !clear) return;
          props.onSubmit({ keywords });
        };

        const keywordSearch = (
          <div className={`z-1 relative flex w-full flex-1 flex-row`}>
            <FieldTextInput
              name="keywords"
              type="search"
              className="z-1 h-full flex-1"
              placeholder={placeholder ? placeholder : 'Search'}
              inputWrapperClassName="h-full !shadow-none !border-none"
              inputClassName={`${inputClassName} h-full focus:z-1 pr-14 !border-none`}
              textSize="base"
              hideErrorMessage
            >
              {/* {values.keywords ? (
                <div className="absolute inset-y-0 right-14 flex items-center">
                  <XCircle
                    onClick={e => {
                      form.mutators.setValue('keywords', '');
                      searchKeyword('', true);
                    }}
                    className="h-6 text-gray-500 hover:text-primary cursor-pointer w-6"
                  />
                </div>
              ) : null} */}

              <div className="z-1 absolute inset-y-0 right-0 flex items-center pr-3">
                <button
                  type="submit"
                  className="z-1 bg-accent-600 hover:bg-accent-700 focus:ring-accent-500 inline-flex cursor-pointer items-center rounded-full border border-transparent p-2 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </FieldTextInput>
          </div>
        );

        const locationSearch = (
          <Field
            name="location"
            format={identity}
            render={({ input, meta }) => {
              const { onChange, ...restInput } = input;
              const searchOnChange = value => {
                onChange(value);
                handleSearchOnChange(value);
              };
              const searchInput = { ...restInput, onChange: searchOnChange };
              return (
                <LocationAutocompleteInput
                  tailwind
                  checkPlayblockEstablishments
                  types={props.types}
                  useDefaultPredictions={!props.hidePredictions}
                  rootClassName="h-full z-10 relative flex flex-1"
                  className={css.locationAddress}
                  inputClassName={`${css.locationAutocompleteInput} ${inputClassName} h-full pl-12 border-0`}
                  iconClassName="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center text-gray-800"
                  predictionsClassName={`${css.predictionsRoot} shadow-lg`}
                  placeholder={placeholder ? placeholder : 'Enter a location'}
                  input={searchInput}
                  meta={meta}
                />
              );
            }}
          />
        );

        const preventFormSubmit = e => {
          e.preventDefault();
          if (searchType !== 'keyword') return;
          searchKeyword(values.keywords);
        };

        return (
          <Form className={`flex flex-row ${className} ${css.contentWrapper}`} onSubmit={preventFormSubmit}>
            {searchType !== 'keyword' ? locationSearch : keywordSearch}
          </Form>
        );
      }}
    />
  );
};

export default SearchFilterInput;
