import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const computeBaseUrl = (queryParams = {}) => {
  return queryParams.expand ? 'gateway/products' : 'products';
};

const create = body => {
  return client('products', { body });
};

const read = id => {
  return client(`products/${id}`);
};

const update = (id, body) => {
  return client(`products/${id}`, { body, method: 'PUT' });
};

const remove = id => {
  return client(`products/${id}`, { method: 'DELETE' });
};

const list = (params = { limit: 1000 }) => {
  const baseUrl = computeBaseUrl(params);
  return client(`${baseUrl}?${stringify(params)}`);
};

const listVariants = (productId, params = { limit: 1000 }) => {
  return client(`products/${productId}/variants?${stringify(params)}`);
};

export { listVariants, read, list, update, create, remove };
