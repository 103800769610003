import { connect } from 'react-redux';

import { object, string } from 'prop-types';
import { compose } from 'redux';

import Logo from '@/components/Logo/Logo';
import SvgRenderer from '@/components/SvgRenderer/SvgRenderer';

// Check for dynamic logo
export const LogoContainerComponent = props => {
  const { marketplace, format, className, logo, disableNativeColors, customLogo } = props;
  const _logo = marketplace?.microMarketplace?.branding?.logo || marketplace?.branding?.logo;
  const { svg, svgLogo } = _logo || {};

  let svgRendererLogo = logo;
  if (svgLogo?.svg) svgRendererLogo = svgLogo;
  if (customLogo?.svg) svgRendererLogo = customLogo;

  if (svgRendererLogo) {
    return <SvgRenderer disableNativeColors={disableNativeColors} logo={svgRendererLogo} type="logo" className={className} />;
  }

  return <Logo svg={svg} format={format} className={className} />;
};

LogoContainerComponent.defaultProps = {
  marketplace: {},
  format: 'desktop',
  className: null,
};

LogoContainerComponent.propTypes = {
  marketplace: object,
  format: string,
  className: string,
};

const mapStateToProps = state => {
  const marketplace = state.Marketplace;
  const { logo } = state.UI;

  return {
    marketplace,
    logo,
  };
};

const LogoContainer = compose(connect(mapStateToProps))(LogoContainerComponent);

export default LogoContainer;
