import PropTypes from 'prop-types';

/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the final-form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const ValidationError = props => {
  const { fieldMeta, dataTest = 'validation-error' } = props;
  const { touched, error } = fieldMeta;
  if (typeof error !== 'string') return null;
  return touched && error ? (
    <div data-test={dataTest} className="text-error-600 mt-2 text-sm">
      {error}
    </div>
  ) : null;
};

ValidationError.defaultProps = { rootClassName: null, className: null };

const { shape, bool, string } = PropTypes;

ValidationError.propTypes = {
  rootClassName: string,
  className: string,
  fieldMeta: shape({
    touched: bool.isRequired,
    error: string,
  }).isRequired,
};

export default ValidationError;
