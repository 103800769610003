import { login, requestOtp } from '@/clients/auth.js';
import { loginSuccess } from '@/ducks/Auth.duck';
import { fetchCurrentUser } from '@/ducks/user.duck';

import { storableError } from '@/util/errors';
import { toast } from '@/util/toast';

// ================ Action types ================ //

export const RECOVERY_REQUEST = 'app/PasswordRecoveryPage/RECOVERY_REQUEST';
export const RECOVERY_SUCCESS = 'app/PasswordRecoveryPage/RECOVERY_SUCCESS';
export const RECOVERY_ERROR = 'app/PasswordRecoveryPage/RECOVERY_ERROR';
export const RETYPE_EMAIL = 'app/PasswordRecoveryPage/RETYPE_EMAIL';
export const CLEAR_RECOVERY_ERROR = 'app/PasswordRecoveryPage/CLEAR_RECOVERY_ERROR';
export const OTP_ERROR = 'app/PasswordRecoveryPage/OTP_ERROR';
export const OTP_SUCCESS = 'app/PasswordRecoveryPage/OTP_SUCCESS';
export const OTP_REQUEST = 'app/PasswordRecoveryPage/OTP_REQUEST';

// ================ Reducer ================ //

const initialState = {
  initialEmail: null,
  submittedEmail: null,
  recoveryError: null,
  recoveryInProgress: false,
  otpInProgress: false,
  passwordRequested: false,
  requestId: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RECOVERY_REQUEST:
      return {
        ...state,
        submittedEmail: null,
        recoveryInProgress: true,
        recoveryError: null,
      };
    case RECOVERY_SUCCESS:
      return {
        ...state,
        requestId: payload.id,
        submittedEmail: payload.email,
        initialEmail: payload.email,
        recoveryInProgress: false,
        passwordRequested: true,
      };
    case RECOVERY_ERROR:
      return {
        ...state,
        recoveryInProgress: false,
        recoveryError: payload.error,
        initialEmail: payload.email,
      };
    case RETYPE_EMAIL:
      return {
        ...state,
        initialEmail: state.submittedEmail,
        submittedEmail: null,
        passwordRequested: false,
      };
    case CLEAR_RECOVERY_ERROR:
      return { ...state, recoveryError: null };
    case OTP_REQUEST:
      return {
        ...state,
        otpInProgress: true,
      };
    case OTP_ERROR:
      toast.error(payload.errorMessage);
      return {
        ...state,
        otpInProgress: false,
      };
    case OTP_SUCCESS:
      return {
        ...state,
        otpInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const passwordRecoveryRequest = () => ({ type: RECOVERY_REQUEST });
export const passwordRecoverySuccess = (email, id) => ({
  type: RECOVERY_SUCCESS,
  payload: { email, id },
});
export const passwordRecoveryError = (error, email) => ({
  type: RECOVERY_ERROR,
  payload: { error, email },
  error: true,
});
export const retypePasswordRecoveryEmail = () => ({ type: RETYPE_EMAIL });
export const clearPasswordRecoveryError = () => ({ type: CLEAR_RECOVERY_ERROR });

export const passwordOtpRequest = () => ({ type: OTP_REQUEST });
export const passwordOtpSuccess = () => ({ type: OTP_SUCCESS });
export const passwordOtpError = error => ({
  type: OTP_ERROR,
  payload: { errorMessage: error.error.userMessage },
  error: true,
});

// ================ Thunks ================ //

export const recoverPassword = email => dispatch => {
  dispatch(passwordRecoveryRequest());

  return requestOtp(email)
    .then(response => dispatch(passwordRecoverySuccess(email, response.data.id)))
    .catch(e => dispatch(passwordRecoveryError(storableError(e), email)));
};

export const recoverPasswordOtp = (input, otp, requestId) => dispatch => {
  dispatch(passwordOtpRequest());

  const body = {
    input,
    otp,
    requestId,
  };
  return login(body)
    .then(response => {
      dispatch(passwordOtpSuccess());
      dispatch(loginSuccess());
      return dispatch(fetchCurrentUser(response));
    })
    .catch(err => dispatch(passwordOtpError(err)));
};
