import { Link } from 'react-router-dom';

import ButtonLight from '@/components/Button/ButtonLight';

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@tmpc/ui/dist/utils/icons/20/solid';

const Alert = props => {
  const {
    className,
    noMargins,
    title,
    type = 'success',
    description,
    action,
    actionIcon,
    largeAction = false,
    actionInProgress = false,
    onAction = () => {},
    actionHref,
    showCloseIcon,
    handleCloseClicked,
    inlineActionText,
    inlineActionHref = '',
    titleClassName = 'text-sm font-medium',
    inlineActionHoverClassName = '',
    action2,
    action2Href,
    buttonTheme = 'white',
  } = props;

  const isWhiteBg = ['warning-white', 'white', 'success-white', 'info-white'].includes(type);
  let color = 'success';
  if (type === 'warning') color = 'warning';
  if (type === 'error') color = 'error';
  if (type === 'info') color = 'blue';
  if (isWhiteBg || ['info-gray', 'warning-gray'].includes(type)) color = 'gray';

  const bgClassName = isWhiteBg ? 'bg-white' : `bg-${color}-50 dark:bg-${color}-900`;

  const renderIcon = (type, className) => {
    switch (type) {
      case 'warning':
        return <ExclamationTriangleIcon className={className} />;
      case 'warning-gray':
      case 'warning-white':
        return <ExclamationTriangleIcon className="text-warning-400 h-5 w-5" />;
      case 'error':
        return <XCircleIcon className={className} />;
      case 'info':
      case 'info-white':
      case 'info-gray':
        return <InformationCircleIcon className={className} />;
      case 'success-white':
        return <CheckCircleIcon className="text-primary-400 h-5 w-5" />;
      default:
      case 'success':
        return <CheckCircleIcon className={className} />;
    }
  };
  // dark:bg-error-900 dark:text-error-100 dark:text-error-200 bg-error-50 text-error-400 text-error-800 text-error-700 hover:bg-error-100 focus:bg-error-100 focus:ring-offset-error-50 focus:ring-error-600
  // dark:bg-warning-900 dark:text-warning-100 dark:text-warning-200 bg-warning-50 text-warning-400 text-warning-800 text-warning-700 hover:bg-warning-100 focus:bg-warning-100 focus:ring-offset-warning-50 focus:ring-warning-600
  // dark:bg-success-900 dark:text-success-100 dark:text-success-200 bg-success-50 text-success-400 text-success-800 text-success-700 hover:bg-success-100 focus:bg-success-100 focus:ring-offset-success-50 focus:ring-success-600
  // TODO: Allow custom definition of detail/info color
  // dark:bg-blue-900 dark:text-blue-100 dark:text-blue-200 bg-blue-50 text-blue-400 text-blue-800 text-blue-700 hover:bg-blue-100 focus:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600
  // dark:bg-gray-900 dark:text-gray-100 dark:text-gray-200 bg-gray-50 text-gray-400 text-gray-800 text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-gray-50 focus:ring-gray-600
  // dark:bg-primary-900 dark:text-primary-100 dark:text-primary-200 bg-primary-50 text-primary-400 text-primary-800 text-primary-700 hover:bg-primary-100 focus:bg-primary-100 focus:ring-offset-primary-50 focus:ring-primary-600
  // dark:bg-accent-900 dark:text-accent-100 dark:text-accent-200 bg-accent-50 text-accent-400 text-accent-800 text-accent-700 hover:bg-accent-100 focus:bg-accent-100 focus:ring-offset-accent-50 focus:ring-accent-600

  return (
    <div
      className={`${className} ${!noMargins ? 'mb-8' : ''} ${bgClassName} break-word  relative overflow-hidden rounded-md p-4`}
    >
      <div className="flex break-words">
        <div className="flex-shrink-0">{renderIcon(type, `h-5 w-5 text-${color}-400`)}</div>

        <div
          className={`ml-3 ${(!description || !title) && !action && !inlineActionText ? 'flex items-center' : ''} ${
            inlineActionText || largeAction ? 'flex-1 items-center md:flex md:justify-between' : ''
          }`}
        >
          <div>
            <div className={`${titleClassName} text-${color}-800 ${bgClassName !== 'bg-white' ? `dark:text-${color}-100` : ''}`}>
              {title}
            </div>
            {description ? (
              <div
                className={`${title ? 'mt-2' : ''} text-sm text-${color}-700 ${
                  bgClassName !== 'bg-white' ? `dark:text-${color}-200` : ''
                }`}
              >
                <div>{description}</div>
              </div>
            ) : null}
          </div>

          {inlineActionText ? (
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <Link
                to={inlineActionHref}
                className={`whitespace-nowrap font-medium text-${color}-700 ${
                  inlineActionHoverClassName || `hover:text-${color}-600`
                }`}
              >
                {inlineActionText} <span aria-hidden="true">&rarr;</span>
              </Link>
            </p>
          ) : null}

          {action ? (
            <div className={`${largeAction ? 'mt-4 flex-shrink-0 md:mt-0 md:ml-3' : 'mt-4'}`}>
              {!largeAction ? (
                <div className="-mx-2 -my-1.5 flex">
                  {actionHref ? (
                    <Link
                      to={actionHref}
                      className={`rounded-md px-2 py-1.5 text-sm font-medium text-${color}-800 hover:bg-${color}-100 focus:outline-none focus:bg-${color}-100 transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
                    >
                      {action}
                    </Link>
                  ) : (
                    <button
                      onClick={onAction}
                      className={`rounded-md px-2 py-1.5 text-sm font-medium text-${color}-800 hover:bg-${color}-100 focus:outline-none focus:bg-${color}-100 transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
                    >
                      {actionIcon ? actionIcon({ className: `h-4 w-4 mr-2 -ml-1` }) : null}
                      {action}
                    </button>
                  )}

                  {action2 ? (
                    <Link
                      to={action2Href}
                      className={`ml-3 rounded-md px-2 py-1.5 text-sm font-medium text-${color}-800 hover:bg-${color}-100 focus:outline-none focus:bg-${color}-100 transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
                    >
                      {action2}
                    </Link>
                  ) : null}
                </div>
              ) : (
                <ButtonLight className="flex-shrink-0" onClick={onAction} inProgress={actionInProgress} theme={buttonTheme}>
                  {action}
                  {actionIcon ? actionIcon({ className: `h-4 w-4 ml-2 -mr-1` }) : null}
                </ButtonLight>
              )}
            </div>
          ) : null}
        </div>
      </div>

      {showCloseIcon ? (
        <XMarkIcon
          onClick={handleCloseClicked}
          className={`text- absolute top-2 right-2 h-4 w-4 cursor-pointer text-${color}-800`}
        />
      ) : null}
    </div>
  );
};

export default Alert;
