import { useEffect, useState } from 'react';

import dateFnsEnUsLocale from 'date-fns/locale/en-US';
import difference from 'lodash/difference';
import { locale as momentLocale } from 'moment';

import defaultMessages from './translations/en.json';

window.dateFnsLocale = dateFnsEnUsLocale;

export const useLocaleConfig = locale => {
  const [localeMessages, setLocaleMessages] = useState(defaultMessages);
  useEffect(() => {
    getLocaleMessages(locale)
      .then(messages => setLocaleMessages(messages))
      .catch(err => {
        setLocaleMessages(defaultMessages);
        console.error("Couldn't load locale messages - falling back to default", err);
      });
    setDateLocale(locale);
  }, [locale]);

  return {
    localeMessages,
  };
};

const setDateLocale = async locale => {
  let importFn = null;

  switch (locale) {
    case 'en-AU':
      importFn = () => import('./en-AU');
      break;
    case 'es':
      importFn = () => import('./es');
      break;
    case 'en':
    default:
      window.dateFnsLocale = dateFnsEnUsLocale;
      return momentLocale(locale);
  }

  const locales = await importFn();
  const { dateFnsLocale } = locales || {};
  window.dateFnsLocale = dateFnsLocale || dateFnsEnUsLocale;
  // The moment locale should have been loaded in the import file
  momentLocale(locale);
};

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);
  const missingKeys = difference(sourceKeys, targetKeys);
  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

export const getLocaleMessages = locale => {
  // We support en or en-AU for now
  let importFn = null;
  switch (locale) {
    case 'en-AU':
      importFn = () => import('./translations/en-AU.json');
      break;
    default:
      locale = 'en';
      return new Promise(function (resolve) {
        resolve(defaultMessages);
      });
  }

  return importFn().then(data => {
    const messagesInLocale = data?.default || data;
    const messages = addMissingTranslations(defaultMessages, messagesInLocale);
    const localeMessages = messages;
    return localeMessages;
  });
};
