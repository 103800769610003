import MpcMarketingSectionTeam from '@marketplace-co/react-marketing-section-team';

import { getTextClasses } from '@/components/UiRenderer/UiTextRenderer';

import { generateImageKitUrl } from '@/util/helpers';

const UiMarketingTeam = ({ config, people, className = 'px-container' }) => {
  const { ui, description, title, titleClassNames = {}, descriptionClassNames = {} } = config || {};

  const classNames = {};
  classNames.titleTextClasses = getTextClasses({ classNames: titleClassNames });
  classNames.descriptionTextClasses = getTextClasses({ classNames: descriptionClassNames, type: 'marketing-description' });

  const props = {
    title,
    description,
    type: ui?.team?.type,
    classNames,
    people:
      people?.map(person => {
        const { title, description, metadata, assets } = person;
        return {
          title,
          subTitle: metadata?.subTitle,
          description,
          image: generateImageKitUrl({
            id: assets?.[0]?.metadata?.name,
            transformationType: 'ar-2:3-md',
          }),
        };
      }) || [],
  };

  return <MpcMarketingSectionTeam {...props} className={className} />;
};

export default UiMarketingTeam;
