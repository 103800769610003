import { Component } from 'react';

import { generateImageKitUrl } from '@/util/helpers';

import DynamicIcon from './DynamicIcon';
import sanitizeSVG from './SvgSanitizer';

import * as css from './SvgRenderer.module.css';

const getWrapperClassNames = ({ settings, type, className, forceDisableNativeColors }) => {
  let { disableNativeColors, disableNativeColorsDark, disableNativeColorsDarkBlack } = settings || {};
  if (forceDisableNativeColors) {
    disableNativeColors = true;
  }
  const isLogo = type === 'logo';
  const disableNativeColorsClassName = isLogo && disableNativeColors ? css.disableNativeColors : '';
  const disableNativeColorsDarkClassName = isLogo && disableNativeColorsDark ? css.disableNativeColorsDark : '';
  const disableNativeColorsDarkBlackClassName = isLogo && disableNativeColorsDarkBlack ? css.disableNativeColorsDarkBlack : '';
  const typeClassName = isLogo ? css.logo : '';
  const textWhiteClassNameMaybe = className?.includes(' text-white') || className?.includes('!text-white') ? css.textWhite : '';
  return `${typeClassName} ${disableNativeColorsClassName} ${disableNativeColorsDarkClassName} ${disableNativeColorsDarkBlackClassName} ${textWhiteClassNameMaybe}`;
};

class SvgRenderer extends Component {
  constructor(props) {
    super(props);
    // TODO: CHECK CACHE IN CONSTRUCTOR TOO
    this.state = { icon: null, icons: {} };
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.checkForRemoteSrc();
  }

  componentDidUpdate(prevProps) {
    if (this.getId() !== this.getId(prevProps)) {
      this.checkForRemoteSrc();
    }
    // Force re-render
    if (this.props.className !== prevProps.className && this.state.icon) {
      this.getData();
    }
  }

  checkForRemoteSrc() {
    const src = this.getRemoteSrc();
    if (src && this.isComponentMounted) this.getData(src);
  }

  getId(props) {
    const _props = props || this.props || {};
    return _props.id || _props.svg?.metadata?.name || _props.logo?.svg?.metadata?.name;
  }

  getSettings() {
    return this.props.settings || this.props.logo?.settings || {};
  }

  getRemoteSrc() {
    const id = this.getId();
    return id && this.isRemoteUrl() ? generateImageKitUrl({ id, transformations: '' }) : null;
  }

  isRemoteUrl() {
    return this.getId()?.includes('.svg');
  }

  setIcon(text) {
    if (!this.isComponentMounted) return;

    const attributes = {
      class: this.props.className,
    };
    return sanitizeSVG(text, attributes, {
      removeFills: this.props.type !== 'logo',
    }).then(icon => this.setState({ icon }));
  }

  cacheUrl() {
    return `/icons/${this.getId()}.svg`;
  }

  async cacheResponse(text) {
    const cacheStorage = await caches.open('static');
    await cacheStorage.put(this.cacheUrl(), new Response(text, { headers: { 'Content-Type': 'image/svg+xml' } }));
  }

  async getCachedData() {
    const cacheStorage = await caches.open('static');
    const cachedResponse = await cacheStorage.match(this.cacheUrl());
    if (!cachedResponse || !cachedResponse.ok) return false;
    return await cachedResponse.text();
  }

  async fetchSvg(src) {
    const request = await fetch(src);
    return await request.text();
  }

  async getData(src) {
    const cachedData = await this.getCachedData();
    if (cachedData) {
      return this.setIcon(cachedData);
    }

    const data = await this.fetchSvg(src);
    this.setIcon(data);
    return this.cacheResponse(data);
  }

  render() {
    const { className, defaultIcon = 'QuestionMarkCircle', type, logo, disableNativeColors, ...rest } = this.props;
    const id = this.getId();
    const settings = this.getSettings();
    const { icon } = this.state;
    const classes = getWrapperClassNames({ settings, type, className, forceDisableNativeColors: disableNativeColors });
    const { svgContent, svgViewbox, legacy } = logo || {};

    // If a svgcontent or viewbox is passed we assume they are already sanitized
    if (svgContent && svgViewbox) {
      return (
        <span className={`${css.svg} ${!legacy ? classes : ''}`} style={{ display: 'inherit' }}>
          <svg
            dangerouslySetInnerHTML={{ __html: svgContent }}
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={svgViewbox}
          ></svg>
        </span>
      );
    }

    if (!this.isRemoteUrl()) return <DynamicIcon className={className} {...rest} defaultIconName={defaultIcon} iconName={id} />;

    return icon ? (
      <span className={`${css.svg} ${classes}`} style={{ display: 'inherit' }} dangerouslySetInnerHTML={{ __html: icon }} />
    ) : (
      <span className={`flex ${className}`}></span>
    );
  }
}

export default SvgRenderer;
