import React, { useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";

import Image from "@marketplace-co/react-application-element-image";
import Video from "@marketplace-co/react-application-element-video";

const VideoPlayer = lazy(() =>
  import("@marketplace-co/react-application-element-video-player")
);
const LottiePlayer = lazy(() => import("./lottie/LottiePlayer"));

const PlayIcon = ({ className }) => {
  return (
    <svg className={className} fill="currentColor" viewBox="0 0 84 84">
      <circle opacity="0.9" cx={42} cy={42} r={42} fill="black" />
      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
    </svg>
  );
};

const ApplicationElementMedia = ({
  image,
  imageProps = {},
  video,
  videoProps = {},
  backgroundVideo,
  backgroundVideoProps = {},
  // eslint-disable-next-line no-unused-vars
  showVideoOnHoverOnly,
  videoActive: initialVideoActive,
  playIconClassName,
  customPlayIcon,
  children,
  classNames,
  backgroundVideoClassNames,
  ...rest
}) => {
  const [backgroundVideoError, setBackgroundVideoError] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [videoActive, setVideoActive] = useState(initialVideoActive);

  let playButtonMaybe = null;
  const hasVideo = video && !videoError;
  const hasActiveVideo = videoActive && video && !videoError;

  if (hasVideo && !videoActive) {
    playButtonMaybe = (
      <button
        onClick={(e) => {
          e.preventDefault();
          setVideoActive(true);
        }}
      >
        {customPlayIcon || <PlayIcon className={playIconClassName} />}
      </button>
    );
  }

  const hasContentToRender = children || playButtonMaybe;
  const content = hasContentToRender ? (
    <div className="absolute inset-0 m-auto flex flex-col items-center justify-center z-10">
      {children ? <div>{children}</div> : null}
      {playButtonMaybe}
    </div>
  ) : null;

  const imageComponent = (
    <Image classNames={classNames} image={image} {...imageProps} {...rest} />
  );

  let media = null;
  if (!hasActiveVideo && backgroundVideo && !backgroundVideoError) {
    if (backgroundVideo?.type === "lottiefile") {
      media = (
        <Suspense fallback={imageComponent}>
          <LottiePlayer
            video={backgroundVideo}
            {...backgroundVideoProps}
            {...rest}
            onError={() => {
              media = imageComponent;
              setBackgroundVideoError(true);
            }}
          />
        </Suspense>
      );
    } else {
      let videoClassNames = {
        ...classNames,
        ...backgroundVideoClassNames,
      };
      if (backgroundVideoProps?.classNames) {
        videoClassNames = {
          ...videoClassNames,
          ...backgroundVideoProps.classNames,
        };
      }

      media = (
        <Video
          video={backgroundVideo}
          hidePausePlayButton={hasVideo}
          playsinline
          image={image}
          {...backgroundVideoProps}
          {...rest}
          classNames={videoClassNames}
          onError={() => {
            media = imageComponent;
            setBackgroundVideoError(true);
          }}
        />
      );
    }
  }

  if (!media && hasActiveVideo) {
    media = (
      <Suspense fallback={imageComponent}>
        <VideoPlayer
          video={video}
          fluid={false}
          fill={true}
          {...videoProps}
          {...rest}
          autoplay
          onError={() => setVideoError(true)}
        />
      </Suspense>
    );
  }

  if (!media && (image || imageProps?.src)) {
    media = imageComponent;
  }

  if (!media) return null;

  return (
    <>
      {content}
      {media}
    </>
  );
};

ApplicationElementMedia.propTypes = {
  /**
   * Show the video when hovering only
   */
  showVideoOnHoverOnly: PropTypes.bool,
  videoActive: PropTypes.bool,
  playIconClassName: PropTypes.string,
  /**
   * Class names to apply to all media
   */
  classNames: PropTypes.object,
  /**
   * Apply any background video specific class names, these will overwrite any classNames for the background video
   */
  backgroundVideoClassNames: PropTypes.object,

  image: PropTypes.object,
  imageProps: PropTypes.object,
  video: PropTypes.object,
  videoProps: PropTypes.object,
  backgroundVideo: PropTypes.object,
  backgroundVideoProps: PropTypes.object,
  customPlayIcon: PropTypes.element,
  children: PropTypes.node,
};

ApplicationElementMedia.defaultProps = {
  showVideoOnHoverOnly: false,
  videoActive: false,
  playIconClassName: "h-20 w-20 text-white",
  classNames: {},
  backgroundVideoClassNames: {},
};

PlayIcon.propTypes = {
  className: PropTypes.string,
};

export default ApplicationElementMedia;
