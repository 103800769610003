import { stringify } from '@/util/urlHelpers';

import { client } from './api';

const getOnboardingUrl = (redirectUrl, hasStripeAccount = false, accessGrantMethod = 'OAuth', type = 'standard') => {
  const queryParams = {
    provider: 'stripe',
    type,
    redirectUri: redirectUrl || 'http://localhost:3000/account/payments?status=success',
    accessGrantMethod,
  };

  if (accessGrantMethod === 'connectOnboarding') {
    queryParams.refreshUri = window.location.href; //`${config.canonicalRootURL}/account/payments-payouts/refresh`;
  } else if (accessGrantMethod === 'OAuth') {
    queryParams.hasStripeAccount = hasStripeAccount;
  }

  return client(`paymentService/stripe/onboarding/session?${stringify(queryParams)}`);
};

const getDashboardUrl = () => {
  return client('paymentService/stripe/dashboard-session');
};

const balance = () => {
  return client('paymentService/stripe/balance');
};

const processOnboardingResponse = body => {
  return client('paymentService/stripe/onboarding/processResponse', { body });
};

export { getOnboardingUrl, balance, getDashboardUrl, processOnboardingResponse };
